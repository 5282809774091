import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';

@Component({
  selector: 'app-doctor-register',
  templateUrl: './doctor-register.component.html',
  styleUrls: ['./doctor-register.component.css']
})
export class DoctorRegisterComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  message: string;
  authToken = localStorage.getItem('auth');
  passwordErr = "";
  messageErr = "";
  clinicID = this.authService.getTokenData().clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private colorService: ColorServiceService,
    private http: HttpClient,
    private authService: AuthService) { }



  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [
        Validators.email, 
        Validators.required
      ]],
      phone: ['', [
        Validators.required
      ]],

      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      designation: ['', Validators.required],
      isAdmin: '',

    });
  }

  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  get email() { return this.form.get('email') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get designation() { return this.form.get('designation') };
  get phone() { return this.form.get('phone') };
  get image() { return this.form.get('image') };
  get isAdmin() { return this.form.get('isAdmin') };

  onSubmit() {

    var t = false;
    if(this.form.get('isAdmin').value == 'true'){
      t = true;
    }
    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }

    console.log('Form valid');

    const request = {
      email: this.form.get('email').value,
      userName: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      designation: this.form.get('designation').value,
      phone: this.form.get('phone').value,
      isAdmin: t,
    }

      this.http.post(environment.DOCREGISTER_END_POINT, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log("Register success");
          if (res.status == "success") {
            alert('Eläinlääkäri rekisteröity onnistuneesti! Uuden eläinlääkärin sähköpostiin on lähetetty aktivointilinkki.');
            this.router.navigate(['/'+this.clinicID+'/clinic-settings']);
          } else {
            alert(res.message);
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }

    checkMobileNumeber() {
      var phone = this.form.get('phone').value;
      var phoneErr;
      if(phone[0] != "+"){
        phoneErr = true;
      }

      else if(phone.length < 5) {
        phoneErr = true;
      }

      else if(phone.length > 17) {
        phoneErr = true;
      }
      else if(phone.substring(0,5) == "+3580"){
          phoneErr = true;
      }
      else {
        phoneErr = false;
      }

      if(phoneErr){
        alert('Puhelinnumerossa vaaditaan +358 alku');
        return;
      }

      this.onSubmit();
    }
}
