import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import { AlertService } from '../service/alert.service';

const helper = new JwtHelperService;

@Component({
  selector: 'app-visit-detail',
  templateUrl: './visit-detail.component.html',
  styleUrls: ['./visit-detail.component.css']
})
export class VisitDetailComponent implements OnInit,AfterViewInit {

  currentUserId='';
  visitID: string = this.route.snapshot.params.id;
  visits: any[];
  pets: any[];
  date="";
  diagnosis="";
  petsName="";
  authToken = localStorage.getItem("auth");
  regimen = "";
  finalDiagosis: any = [];
  diffDiagnosis: any = [];
  consultationTime;
  queTime;
  anamnesis: any = {};
  alert = "";
  alertErr = "";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private alertService: AlertService,
    private authService: AuthService) { }


  ngOnInit() {
    this.myId();
  }
  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  myId(){

    var token = localStorage.getItem('auth');

    if(token == null){
      this.logOutt();
      this.authService.doLogOut();
    }
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);
    this.currentUserId = decodedToken.result.id;


    var activeuser = localStorage.getItem('ACTIVE_USER');


    console.log('totta',decodedToken.result.designation)
    if(decodedToken.result.designation == 'patient'){
      console.log('totta')
      this.authService.doLogOut();
      this.logOutt();

    }
    this.fetchVisitData();
  }

  logOutt() {
    console.log("Request to logout", this.currentUserId);
    let req = {
      userID: this.currentUserId
    };
  
    this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log('vastaus',res);
        if(res.status == 200){
          
          //this.router.navigate(['login']);
          console.log("log out success");
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
      this.authService.doLogOut();
  
  }


  fetchVisitData(){

    var req = {
      visitID:this.visitID
    }

    this.http.post(environment.FIND_ONE_END_POINT, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
        this.visits = res.message;
        this.anamnesis = res.message[0].anamnesis;
        this.petsName = res.message[0]['petName'];
        this.regimen = res.message[0]['regimen'];
        this.finalDiagosis = res.message[0]['diagnosisFinal'];
        this.diffDiagnosis = res.message[0]['diagnosisDiff'] ;
        this.consultationTime = res.message[0]['consultationTime']  / 60000;
        this.queTime = res.message[0]['queuedTime']  / 60000;
        this.queTime = parseInt(this.queTime);
        this.consultationTime = parseInt(this.consultationTime);
        console.log(this.visits)
        this.textAreas(res.message[0]);
        }
        else{
          this.visits = [];
          alert('Käyntiä ei löytynyt');
          return;
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }
  printPage(){
    window.print();
  }

  textAreas(msg){

    (<HTMLTextAreaElement>document.getElementById('vetPublicNotes')).value = msg.vetPublicNotes;
    (<HTMLTextAreaElement>document.getElementById('vetPrivateNotes')).value = msg.vetPrivateNotes;

    this.autoTextarea('vetPublicNotes');
    this.autoTextarea('vetPrivateNotes');
  }

  autoTextarea(id){
    var textarea = document.getElementById(id);
    var heightLimit = 500; /* Maximum height: 200px */

      textarea.style.height = ""; /* Reset the height*/
      textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
      textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important')
  }

  savePublicNotes(){

    var req = {
      visitID: this.visitID,
      note: (<HTMLTextAreaElement>document.getElementById('vetPublicNotes')).value

    }
    this.http.put(environment.PUBLIC_NOTE, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.alert = "Muistiinpanojen päivittäminen onnistui";
          this.alertService.waitAlert5000(this.alert,(err,result) => {
            this.alert = result;
          })
        }
        else{
          this.alertErr = "Muistiinpanojen päivittäminen epäonnistui";
          this.alertService.waitAlert5000(this.alertErr,(err,result) => {
            this.alertErr = result;
          })
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }
  
  savePrivateNotes(){
    var req = {
      visitID: this.visitID,
      note: (<HTMLTextAreaElement>document.getElementById('vetPrivateNotes')).value

    }
    this.http.put(environment.PRIVATE_NOTE, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.alert = "Muistiinpanojen päivittäminen onnistui";
          this.alertService.waitAlert5000(this.alert,(err,result) => {
            this.alert = result;
          })
        }
        else{
          this.alertErr = "Muistiinpanojen päivittäminen epäonnistui";
          this.alertService.waitAlert5000(this.alertErr,(err,result) => {
            this.alertErr = result;
          })
          return;
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }




}
