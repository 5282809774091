import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment,env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-moderator-login',
  templateUrl: './moderator-login.component.html',
  styleUrls: ['./moderator-login.component.css']
})
export class ModeratorLoginComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  user = {
    userName: '',
    password: '',
    loginError: false
  };
  errMessage = false;
  lockErr = false;

  loginForm: FormGroup;
  botErr = false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };


  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private authService: AuthService) { }

    ngOnInit() {
      this.createForm();
      this.addRecaptchaScript();
      localStorage.removeItem('colors');
      
    }

    renderReCaptch() {
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : env_data.google_site_key, //TUOT= 6LfXmE8aAAAAAPAi2nun0eTRgSOYm7uSDvrJl9zX, MUSTI_DEV = 6LcPEDUaAAAAAP3y86cC2MHX0dKoJ2dhqc707-Q9
        'callback': (response) => {
            this.sendGoogleVerification(response);
        }
      });
    }
  
    createForm(){
      this.loginForm = this.fb.group({
        userName: ['', [
   
          Validators.required,
          Validators.email, 
  
        ]],
        password:  ['',[
          Validators.required,
          Validators.minLength(5),
  
        ]]
      })
    }
    addRecaptchaScript() {
      window['grecaptchaCallback'] = () => {
        this.renderReCaptch();
      }
   
      (function(d, s, id, obj){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { obj.renderReCaptch(); return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'recaptcha-jssdk', this));
   
    }

  get userName() { return this.loginForm.get('userName') };
  get password() { return this.loginForm.get('password') };
  sendGoogleVerification(response) {
    var password = this.loginForm.get('password').value;
    var userName = this.loginForm.get('userName').value;

    if(password == "" || userName == "") {
      return;
    }
    if(password.length < 4) {
      return;
    }
    if(userName.lenght < 4 ) {
      return;
    }
    if(!userName.includes('@')) {
      return;
    }

    var req = {
      response:response,
      userName: userName,
      password: password,
    }

    var elm = document.getElementById('login-form');
    elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block";
    this.errMessage=false;
    this.lockErr = false;

    this.http.post(environment.GOOGLE_ADMIN_LOGIN_VERIFICATION, req, this.httpOptions).subscribe(
      (res: any) => { 
        console.log(res);

        if(res.status == 200) {
          if (res.status == "success") {
            
          } else if(res.status == "failed"){
            

            if(res.message == "Käyttäjätili lukittu") {
              this.lockErr = true;
              elm.classList.remove("reg-form-success");
              document.getElementById('loader').style.display="none";
  
              this.loginForm.get('password').setValue("");
            }
            else {
            this.errMessage = true;
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";

            this.loginForm.get('password').setValue("");
            }
          }

          else if(res.status == 200) {
            localStorage.setItem('TEMP_ADMIN_AUTH', res.token);
            this.zone.run(() => {
              this.router.navigate(['/admin-sms-validation/'+res.token]);
             });

          }
        }
        if(res.status == 400){
          this.botErr == true;
        }
        if(res.status == "failed"){

            
        
            if(res.message == "Käyttäjätili lukittu") {
              this.lockErr = true;
              elm.classList.remove("reg-form-success");
              document.getElementById('loader').style.display="none";
  
              this.loginForm.get('password').setValue("");
            }
            else {
            this.errMessage = true;
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";

            this.loginForm.get('password').setValue("");
            }
          
        }
      
      }, (err: any) => {
          
        console.log("Error while login :: ", err);
      })

  }

}
