import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeGender'
})
export class ChangeGenderPipe implements PipeTransform {

  transform(gender: any, args?: any): any {
    if(gender == "Male" || gender == "male" || gender == "uros" || gender == "Uros"){
      return "Uros";
    }
    else if(gender == "Female" || gender == "female" || gender == "naaras" || gender == "Naaras") {
      return "Naaras";
    }
  }

}
