<app-not-auth-header></app-not-auth-header>
<div class="wrapperi">
  <div class="container">
	<div class="wrap">
    <div class="col-12 m-0 row">
			<div class="col-3"></div>
			<div class="col-12 col-md-6 p-0 mt-4">
			  <div class="col-12 text-center p-0">
				<h4 class="header-text mt-4">SMS varmenne</h4>
				<p class="desc-text">Syötä saamasi numerokoodi alla olevaan laatikkoon. <span class="desc-text-bold"></span></p>

			  </div>
			</div>
			<div class="col-3"></div>
	
		  </div>

  <div class="col-12 row mt-4">
    <div class="col-3"></div>
    <div class="col-12 col-md-6">
      <h3 class="header3 text-center">Syötä koodisi</h3>
      <div class="col-md-12 mx-auto row">
        <input  type="number" min="0" inputmode="decimal" (keydown.enter)="setStatus()" pattern="[0-9]*" id="sms" class="form-control">
      </div>

    </div>
    <div class="col-3"></div>
  </div>
  <div class="col-12 row mt-4">
    <div class="col-3"></div>
    <div class="col-6 text-center pb-4">
      <button class="btn btn-veteva-secondary login-btn" (click)="setStatus()">Varmenna</button>
    </div>
    <div class="col-3"></div>
  </div>
  
 


    </div>
    </div>

    </div>
