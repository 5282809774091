  <app-not-auth-header></app-not-auth-header>


  <div class="wrapperi">
    <div class="container h-75">
      <div class="wrap">
  
        <div class="col-12 m-0 row">
          <div class="col-3"></div>
          <div class="col-12 col-md-6 p-0 mt-4">
            <div class="col-12 text-center p-0">
              <h4 class="header-text mt-4">Vaihda salasanasi!</h4>
              <p class="desc-text">Syötä haluamasi salasana. <span class="desc-text-bold">Saat onnistumisilmoituksen onnistuneen salasanan vaihdon yhteydessä.</span></p>
              <div class="alert alert-success" *ngIf="successMessage != '' ">
                <p class="text-center">{{successMessage}}</p>
                <div class="d-flex justify-content-center">
                  <button routerLink="/{{clinicID}}/login" class=" btn-veteva-secondary-edit"><i class="fas fa-sign-in-alt me-2"></i>Kirjaudu sisään tästä</button>
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="errMessage != '' ">
                <p class="text-center">{{errMessage}}</p>
              </div>
            </div>
          </div>
          <div class="col-3"></div>
  
        </div>
  
        <div class="col-12 p-0 row m-0">
          <div class="col-3"></div>
          <div class="col-12 col-md-6 p-0">
            <div class="col-12 p-0 ">
              <div class="col-12 login-form-bg">
                <div id="loader" class="loader-container mt-4 mb-4" style="display:none">
                  <div class="loader mx-auto"></div>
                  <p class="body2b-b text-center mt-4 mb-4">Pieni hetki salansanaasi uusitaan...</p>
                </div>
                <form class="flex-row " id="new-pwd-form" [formGroup]="form" (ngSubmit)="changePassword()">
                <div class="col-12 row m-0 pl-0 pr-0">
  
                  <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  class="form-control" formControlName="password" type="password" placeholder="Salasana">
                  </div>
                </div>
                <div class="col-12 row m-0 pl-0 pr-0">
  
                  <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  class="form-control" formControlName="password2" type="password" placeholder="Salasana uudelleen">
                  </div>
                </div>
                <div class=" mx-auto mt-4">
                  <div class="text-center" >
                    <button  [disabled]="form.invalid" class="btn btn-veteva-secondary"   >Uusi salasana</button>
                  </div>
                </div>
              </form>
            
          </div>
            </div>
          </div>
          <div class="col-3"></div>
  
        </div>
  
        <div class="d-flex justify-content-center flex-row ">
          <div class="link-container">
  
          </div>
        </div>
        </div>
      </div>
    </div>
