import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pformateDate'
})
export class PformateDatePipe implements PipeTransform {

  transform(date: any, args?: any): any {

    if(date != undefined) {
      date = date.substring(0,10);
      date = new Date(date);
      var date2 =  date.getMonth() + 1
      if (!isNaN(date.getTime())) {
          return date =   date.getDate() +'.'+ date2  +'.' + date.getFullYear();
      }
    }
  }

}
