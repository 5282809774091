import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _userLoggedIn: boolean = false;
  private _userName: string = null;
  private invalidLogin: boolean = false;
  private authToken;
  private color;
  private isAdmin: boolean = false;
  

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    withCredentials: true,
  };


  constructor(private http: HttpClient, private router: Router,private zone: NgZone) {
    var loggedIn = localStorage.getItem('loggedIn');
    if (loggedIn != null) {
      this._userLoggedIn = loggedIn == 'true' ? true : false;
    }
    var userName = localStorage.getItem('userName');
    if (userName != null) {
      this._userName = userName;
    }
    var userName = localStorage.getItem('userName');
    if (userName != null) {
      this._userName = userName;
    }
    this.getTokenData();
  }

  validateLogin() {
    console.log('testataan');
    
     this.http.get(environment.CURRENT_USER_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        console.log('validate loigin ',res);
        if (res.status == 200) {
          //this.router.navigate(['/'+this.getTokenData().clinicID+'/home']);
          
        } else {
          //this.doLogOut(true);
        }
      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
  }

  validateClinic(clinicID) {
    
     this.http.get(environment.VALIDATE_CLINIC+'/'+clinicID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          if(res.message == true){
            console.log('clinic validated')
          }
          else {
            alert('Määrittämäsi klinikka on väärä');
            return;
          }
        }
      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
  }

  doDocLogin(user){

    localStorage.setItem('userName', user);
    localStorage.setItem('loggedIn', "true");
    this._userLoggedIn = true;
    this._userName = user.email;
  }
  doLogin(user) {
    var result = user.email;

    localStorage.setItem('userName', result);
    localStorage.setItem('loggedIn', "true");
    this._userLoggedIn = true;
    this._userName = user.email;

  }
  auth(token,clinicID) {
    localStorage.setItem('userName','TEST');
    localStorage.setItem('loggedIn', "true");
    localStorage.setItem('auth', JSON.stringify(token));
    localStorage.removeItem('TEMP_AUTH');
    this._userLoggedIn = true;
    this.zone.run(() => {
      this.router.navigate(['/'+clinicID+'/home']);
     });


  }
  docAuth(token,clinicID,colors) {
    localStorage.setItem('userName','TEST');
    localStorage.setItem('loggedIn', "true");
    localStorage.setItem('colors', JSON.stringify(colors));
    localStorage.setItem('auth', JSON.stringify(token));
    this._userLoggedIn = true;
    this.zone.run(() => {
      this.router.navigate(['/'+clinicID+'/doctorhome']);
     });
  }

  adminAuth(token) {
    localStorage.setItem('userName','TEST');
    localStorage.setItem('loggedIn', "true");
    localStorage.setItem('auth', JSON.stringify(token));
    this._userLoggedIn = true;
    this.zone.run(() => {
      this.router.navigate(['/admin-home']);
     });
  }

  doLogOut(redirectToLogin?: boolean) {
    console.log("Doing logout :: ", redirectToLogin);
    //this.http.get(environment.LOGOUT_END_POINT, this.httpOptions).subscribe();
    var clinicID = this.getTokenData().clinicID;
    this.http.get(environment.LOGOUT_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        var mode = this.getTokenData().isModerator;
        
        if(mode == true){
          this.router.navigate(['/moderator-login'])
          localStorage.clear();
        }
        else{

          if(this._userLoggedIn == true){
            if(clinicID){
              this.router.navigate(['/'+clinicID+'/login'])
            }
            console.log(clinicID)
            this._userLoggedIn = false;
            this._userName = null;
            localStorage.clear();
          }
        }
        if (redirectToLogin == true) {
          if(clinicID){
            this.router.navigate(['/'+clinicID+'/login'])
          }
          //console.log("Redirecting to login page");
          localStorage.clear();
        }

      }, (err: any) => {
        console.log("current user error :: ", err);
      }
    ) 
        
  }


  isUserLoggedIn(): boolean {
    return this._userLoggedIn;
  }

  getUsername(): string {
    return this._userName;
  }

  isDocAdmin(){
    const decodedToken = helper.decodeToken(localStorage.getItem('auth'));
    return decodedToken.result.isAdmin;
  }

  getTokenData(){
    var token = localStorage.getItem('auth');
    if(token){
    var decodedToken = helper.decodeToken(token).result;
    this.authToken = decodedToken;
    return this.authToken;
    }
    else{
      return 1;
    }
      
  }

  getClinicData(){
    var token = localStorage.getItem('colors');
    if(token){
    var ls = JSON.parse(token)
    this.color = ls;
    return this.color;
    }
    else{
      return 1;
    }
      
  }
  



}
