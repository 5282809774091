<app-docheader></app-docheader>

<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Palauterekisteri ({{feedbacks.length}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3 mb-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>


      <p class="mb-0">Palautteita: <strong>{{feedbacks.length}} kpl</strong></p>
      <p class="mb-0">Keskiarvo: <strong>{{averageNPS}}</strong></p>
      <p class="">NPS: <strong>{{NPSScore}}</strong></p>

      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr class="w-100">
              <th scope="col">PVM</th>
              <th scope="col">NPS</th>
              <th scope="col">Palaute</th>
              <th scope="col">Lupa kontaktoida</th>
              <th scope="col">Asiakas</th>
            </tr>
          </thead>
          <tbody>
            <tr class="w-100" *ngFor="let feedback of feedbacks" >
              <td><strong><span *ngIf="feedback.createdDate">{{feedback.createdDate | pformateDate}}</span><span *ngIf="!feedback.createdDate">Ei leimattu</span></strong></td>
              <td>{{feedback.feedbackNPS}}</td>
              <td>{{feedback.feedbackText}} </td>
              <td><span *ngIf="feedback.acceptedContact">Kyllä</span><span *ngIf="!feedback.acceptedContact">Ei</span></td>
              <td><a *ngIf="feedback.userID" class="btn btn-veteva-secondary" routerLink="/vet/user-detail/{{feedback.userID}}">Käyttäjä</a><span *ngIf="!feedback.userID">Ei leimattu</span></td>

              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
            </tr>

          </tbody>
        </table>
      </div>
