<div class=" chat-window-container h-100">
  <app-header></app-header>
  <script src="//sdk.twilio.com/js/video/releases/2.8.0/twilio-video.min.js"></script>
    <div class="row m-0 three-sec-wrap row-container" id="chat-wrap">
  
      <!--Section one :: started-->
      <div class="col-md-8 col-lg-4 link-container section-one color-white korkeus p-0 section sidebar-sec-one " id="mySidenav">
        <div class="bg-white col-12 d-inline-flex">
          <div class="bg-white ms-2">
            <i class="fa fa-times fa-icon close-fa burger-left closeNav" (click)="closeNav()" aria-hidden="true"></i>

          </div>
  
          <div class="tab tab-container ps-0 pe-0 pe-md-2 ps-md-2">
            <button class="tablinks koti active body1b-b"  (click)="openCity($event, 'kotielaimet')">Lemmikit</button>
            <button class="tablinks body1b-b" routerLink="/{{clinicID}}/chathistory">Chat historia</button>
          </div>
      </div>

        <div id="asetukset" class="tabcontent">
          <h3>Asetukset</h3>
          <p>Laitetaan tähän asetukset</p>
        </div>
        <!-- Tab content -->
        <div style="display: block;"  id="kotielaimet" class="tabcontent">
          <div class="mt-4 row m-0 col-12 d-flex ps-0 pe-0 ps-md-2 justify-content-center">
          <div class="col-12 col-lg-12 col-xl-11 ps-0 pe-0 ps-md-2 pe-md-2">
          <ul *ngIf="pets.length != 0;else no_pets_content" class="list-group" id="menu">
            <div *ngFor="let pet of pets" class="full-wrap mb-3">
                <li class="list-group-item list-wrap-item pe-0 list-group-item-link m-0 pt-4 pb-4">
                  <div class="list-wrap-closed col-12 row pe-0">
                    <div class="col-10 pe-0 row">
                      <div class="col-2 col-md-1 p-0  img-cont" *ngIf="pet.image != null;else standard_image">
                        <img class="small-pic-pet mt-2" src="{{pet.image}}" alt="lemmikkisi">
                      </div>
                      <ng-template #standard_image>
                        <div class="col-2">
                          <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">

                        </div>
                      </ng-template>
                      <div class=" col-8 ps-4 pe-0  d-flex flex-column">
                        <div class="p-0">
                          <p class=" mb-0 petname-text body2b-b"> {{ pet.name }}</p>
                        </div>
                        <div class=" p-0">
                          <p class=" mb-0 body2bb">{{pet.breed}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-2 p-0 d-flex justify-content-end ">
                      <i class="mt-2 fas fa-angle-down black-icon  expand-icon "  id="expand-icon-{{pet._id}}" (click)="openExpandBox($event)"></i>
                      <i class="mt-2 fas fa-angle-up black-icon  close-icon  poista" style="display: none;" id="close-icon-{{pet._id}}" (click)="closeExpandBox($event)"></i>
                    </div>
                   
                  </div>
                  <div class="col-12 text-center row m-0">
                    <div class="col-2"></div>
                    <div class="col-8 d-flex justify-content-center">
                      <button class="mt-4 btn btn-veteva-secondary" (click)="closeNav()" id="start-consultation-first-{{pet._id}}" data-bs-toggle="modal" data-bs-target="#check-modal"><i class="fas fa-arrow-right me-2"></i>Aloita chat</button>
                    </div>
                    <div class="col-2"></div>
                  </div>
              </li>
              <ul class="list-group list-group-flush list" id="{{pet._id}}" >
                <li id="info-screen-visits-{{pet._id}}" (click)="[openInfoScreenActiveUserData($event, pet), closeNav($event, 'mySidenav')]" class="list-group-item list-group-item-link list-group-show pointer">
                 <div class="col-12 row">
                   <div class="col-2"></div>
                   <div class="col-10 d-inline-flex">
                    <i class="fa fa-2x fa-info-circle veteva-icon-main"></i>
                    <p class="ms-2 mt-1 body2lg">Tiedot</p>
                    <div class="ms-auto wrapper">
                     <i  class= "fa fa-angle-right fa-2x veteva-icon-main pointer"></i>
                   </div>
                   </div>
                 </div>
                </li>
                <li id="info-screen-visits-{{pet._id}}" (click)="[openInfoScreenVisits(pet._id), closeNav($event, 'mySidenav')]" class="list-group-item list-group-item-link list-group-show pointer">
                  <div class="col-12 row">
                    <div class="col-2"></div>
                    <div class="col-10 d-inline-flex">
                    <i class="fa fa-2x fa-calendar veteva-icon-main" aria-hidden="true"></i>
                    <p id="info-screen-visits-{{pet._id}}"  class="ms-2 mt-1 body2lg">Käynnit</p>
                  <div class="ms-auto wrapper">
                    <i id="info-screen-visits-{{pet._id}}"  class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                  </div>
                  </div>
                  </div>
                 </li>
<!--                  <li class="list-group-item list-group-item-link list-group-show">
                  <div class="">
                        <button class="btn btn-veteva-secondary" id="start-consultation-second-{{pet._id}}" data-bs-toggle="modal" data-bs-target="#check-modal">Aloita chat</button>
                      </div>
                 </li> -->
              </ul>
            </div>
          </ul>
        </div>
        <ng-template #no_pets_content>
          <div class="alert alert-warning">
            <p>Sinulla ei ole yhtään lemmikkiä. Ole hyvä ja lisää itsellesi lemmikki aloittaaksesi chatin</p>
          </div>
        

        </ng-template>
      </div>
  
            <div class="row col-12 mb-4 mt-3">
              <div class="mx-auto text-center">
                <a  routerLink="/{{clinicID}}/add-pet">+ Lisää lemmikki</a>
                
              </div>

            </div>

      </div>
      <div class="text-center">
        <button (click)="[closeFunc(),clearInstructions()]" data-bs-toggle="modal" data-bs-target="#instructions-modal" class="btn btn-veteva-secondary-edit mb-2 no-print m-3" type="button" ><i class="far fa-question-circle pe-2"></i>Ohje eläinlääkäriin</button>

      </div>
      </div>
      
      <!--Section one :: END-->
  
      <!--Section two :: started-->
      <div class="col-md-12 col-lg-8 section-two color-white korkeus h-100 p-0 section main-container" id="section-two-container">
  
        <!-- header container : start-->
        <div class="header-container container-fluid">
          <div class="row col-12 m-0">            
            <div class="col-1 col-md-0 me-md-4 p-0 mt-2">
              <div class="avatar-icon-chat mt-1 sideBar-avatar">
                <div class="img-container" *ngIf="!(currentActiveDoctorName === DR_ASSISTANT_NAME ||currentActiveDoctorName === HELPER_NAME)">
                  <div *ngIf="this.activeConsultantData.wantedImage">
                    <img src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                  </div>
              </div>
              <div class="img-container" *ngIf="currentActiveDoctorName == DR_ASSISTANT_NAME">
                <img src="./../../assets/images/wellness_some.png">
              </div>
              <div class="img-container" *ngIf="currentActiveDoctorName == HELPER_NAME">
                <img src="./../../assets/images/wellness_some.png">
              </div>
                
              </div>
  
            </div>
            <div class="col-8 col-md-9 ms-4 ms-md-0">
              <div class="container-for-dr-name d-flex flex-column mt-2" *ngIf="currentActiveDoctorName !==  HELPER_NAME;else assistant_text_template ">
                <p class="body1-w mb-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}<span class="body3-b" *ngIf="globalMessages[currentActiveDoctorName]?.typing">  kirjoittaa...</span></p>
                <p class="body3-w mb-0">{{this.activeConsultantData['designation']}}</p>
              </div>
              <ng-template class="p-2" #assistant_text_template>
                <p class="body1-w mb-0 mt-2">
                  <span *ngIf="currentActiveDoctorName == HELPER_NAME">{{this.clinicName}}</span>
                </p>
                <p class="body3-w">
                  <span *ngIf="currentActiveDoctorName == HELPER_NAME">Autan sinut alkuun</span>
                  <span *ngIf="currentActiveDoctorName == DR_ASSISTANT_NAME">Kyselen esitiedot</span>
                  </p>
              </ng-template>
              <ng-template class="p2">
                <p #helper_text class="header-text">{{HELPER_NAME}}<span class="text-small-typing" *ngIf="globalMessages[currentActiveDoctorName]?.typing">kirjoittaa...</span></p>
              </ng-template>
            </div>
            <div class="col-3 col-md-2 p-0">
              <div class="col-12 row m-0 p-0">
                <div class="col-6 p-0">
                  <i *ngIf="roomData" class="fas fa-video video-icon-header white-icon mt-3 blinking" (click)="openVideoBox()"></i>
                  <i *ngIf="!roomData" class="fas fa-video video-icon-header veteva-icon-main mt-3" ></i>

                </div>
                <div class="col-6 p-0 text-end">
                  <i (click)="openNav()" class="fas fa-ellipsis-v burger burger-left sidebar-burger"></i>

                </div>
              </div>
            </div>


            
          </div>
        </div>
        <!-- header container : end-->
  
  
        <div id="chat-window" class=" chat-window-main-container w-100 col-md-12 ps-1 pe-1" [ngClass]="{'chat-window-main-container-no-replybox': !showReplyBox}">
  
  
  

          <div class="chat-messages-container pb-4 m-0" id="main-chat-container" #chatMessageContainer (scroll)="onScroll()">
            

            <div id="docs-helping" class="close-bubbles" *ngIf="activeDoctors.length != 0">
            <div class=" no-padding start-row starter-message ps-0" *ngIf=" this.currentActiveDoctorName == HELPER_NAME">
              <div class="row m-0">
                <div class="col-6 col-md-5 col-lg-4 col-xl-2 ms-2 mt-1 p-0">
                <p class = "body3b mb-1">Autan sinut alkuun</p>
                <div class="text-message text-message-left helper-message whitespace-preline">
                  <p class="body2 m-0">Eläinlääkärimme <br> palvelemassa sinua: </p>
                </div>
                </div>
              </div>
            </div>
          </div>

            <div class=" no-padding start-row starter-message" *ngIf=" this.currentActiveDoctorName == HELPER_NAME">

            <div id="active-docs" class="close-bubbles" *ngIf="activeDoctors.length != 0; else no_doctors_content">
              <div class="" *ngFor="let activeDoctor of activeDoctors">

                  <div class="row col-md-12 ps-0 m-0">
                    <div class=" col-6 col-md-5 col-lg-4 col-xl-2 p-0 ms-2 mt-1">
                    <div class="text-message text-message-left helper-message whitespace-preline">
                      <div class="profile-cont col p-0" *ngIf="activeDoctor.image != null;else standard_image">
                        <img class="profile-cont mt-1" src="{{fileURL}}/{{activeDoctor.image.key}}" alt="{{activeDoctor.name}}">
                      </div>
                      <ng-template #standard_image>
                        <img class="small-pic-doc mt-1" src="../../assets/images/wellness_some.png" alt="{{activeDoctor.name}}">
                      </ng-template>
                      <p class="body2b-b mb-0 mt-2">{{activeDoctor.name}} </p>
                      <p class="body2">{{activeDoctor.designation}} </p>
    
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <ng-template #no_doctors_content>
              <div class=" col-md-12 m-0 close-bubbles">
                <p class = "body3b mb-1">Autan sinut alkuun</p>
                <div class="row ps-0  ">
                  <div class=" col-6 col-md-5 col-lg-4 col-xl-3 p-0 ms-2 mt-1">
                  <div class="text-message text-message-left helper-message whitespace-preline">
                    <div class="alert alert-warning m-0">
                      <p class="body2b-b">Eikä! Yhtään eläinlääkäriä ei ole paikalla</p>
                    </div>

                  </div>
                  </div>
                </div>
              </div>
            </ng-template>
            </div>

   
               <div id="open-hours" class="col-md-12 no-padding close-bubbles" *ngIf="this.currentActiveDoctorName == HELPER_NAME ">
                  <div class="row m-0">
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">Autan sinut alkuun</p>
                      <div class="text-message text-message-left helper-message whitespace-preline">
                      <p class="body2" >Klinikkamme aukioloajat ovat seuraavat:</p>
  
                      <div >
                        <div class="col" *ngFor="let open of clinicOpen">
                          <div class="d-flex justify-content-between" *ngIf="open.idx == todayIdx">
                              <p class=" body214bb  me-4 mb-1">Tänään</p>
                              <p class=" mb-1  body214bb ">{{open.start}} - {{open.end}}</p>
                          </div>
                        </div>
                        
                        <p class="veteva-main-link pointer text-center" id="show-open-hours" style="display: block;" (click)="openOpenHours()">Näytä lisää</p>
                        <p class="veteva-main-link pointer text-center" id="close-open-hours" (click)="closeOpenHours()" style="display: none;">Sulje</p>
                        <div style="display: none;" id="open-hour-list">
                          <div class="col" *ngFor="let open of clinicOpen">
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 1">
                                <p class=" body214bb  me-4 mb-1">Maanantai</p>
                                <p class=" mb-1  body214bb ">{{open.start}} - {{open.end}}</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 2">
                              <p class=" body214bb me-4 mb-1">Tiistai</p>
                              <p class="mb-1 body214bb ">{{open.start}} - {{open.end}}</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 3">
                              <p class=" body214bb me-4 mb-1" >Keskiviikko</p>
                              <p class=" body214bb mb-1">{{open.start}} - {{open.end}}</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 4">
                              <p class=" body214bb me-4 mb-1">Torstai</p>
                              <p class=" body214bb mb-1">{{open.start}} - {{open.end}}</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 5">
                              <p class=" body214bb me-4 mb-1">Perjantai</p>
                              <p class=" body214bb mb-1">{{open.start}} - {{open.end}}</p>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 6">
                              <p class=" body214bb me-4 mb-1">Lauantai</p>
                              <p class=" body214bb mb-1">{{open.start}} - {{open.end}}</p>
                            </div >
                            <div class="d-flex justify-content-between" *ngIf="open.idx == 0">
                              <p class=" body214bb me-4 mb-1 ">Sunnuntai</p>
                              <p class="  body214bb mb-1 ">{{open.start}} - {{open.end}}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div id="clinic-price" class="col-md-12 no-padding close-bubbles" *ngIf="this.currentActiveDoctorName == HELPER_NAME && this.clinicStatus == true">
                  <div class="row m-0">
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">Autan sinut alkuun</p>
                      <div class="text-message text-message-left helper-message whitespace-preline">
                      <p class="body2" >Eläinlääkäripalvelun hinta nyt:</p>
  
                      <div >
                          <div class="col" *ngFor="let p of feePackages">
                            <div class="d-flex justify-content-between">
                                <p class=" body214bb  me-4 mb-1">{{p.name}}</p>
                                <p class=" mb-1  body214bb ">{{p.price}}€</p>
                            </div>
                            
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>

  
                <div id="start-create-pet" class="close-bubbles col-md-12 no-padding start-row" *ngIf=" this.currentActiveDoctorName == HELPER_NAME && this.pets.length <= 0;else has_pets ">
                    <div class="row m-0">
<!--                       <div class="p-0">
                        <div class="avatar-icon-chat mt-4">
                          <img src="./../../assets/images/wellness_some.png">
                          
                        </div>
                      </div> -->
                      <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">Autan sinut alkuun</p>
                      <div class="text-message text-message-left helper-message whitespace-preline">
                        <p class="body1">Aloittaaksesi sinun tulee lisätä lemmikki: </p>
    
                        <div class="">
                            <button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/add-pet">Lisää lemmikki</button>
                          </div>
                      </div>
                        <br />
                      </div>
                    </div>
                  </div>
            
  
            <ng-template #has_pets>
  
  
              <!-- ALKAA -->
              <div id="pet-added" class="col-md-12 no-padding close-bubbles" *ngIf="this.currentActiveDoctorName == HELPER_NAME">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/wellness_some.png">
                      
                    </div>
                  </div> -->
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">Autan sinut alkuun</p>
                    <div class="text-message text-message-left helper-message whitespace-preline">
                      <p class="body2">Hienoa! Olet lisännyt itsellesi jo lemmikin! Pyydämme sinua täyttämään esitietokyselyn, jotta eläinlääkäri voi palvella teitä paremmin.</p>
  
                      <div class="">
                          <button class="btn btn-veteva-secondary" data-bs-toggle="modal" data-bs-target="#check-modal"><i class="fas fa-arrow-right me-2"></i>Aloita chat</button>
                        </div>
                    </div>
                      <br />
                    </div>
                  </div>
                </div>
              <!-- LOPPUU -->
  
  
            </ng-template>
            
  
  
            <div class="row m-0 message-container" *ngFor="let message of currentActiveMessageList">
  
              <!-- ALOITUS LÄÄKÄRIT -->
  
  
              <!-- ALOITUS AUKIOLO -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_101' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div>
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body2b mb-1">Eläinlääkärimme palvelemasssa sinua</p>
                    <div class="text-message text-message-left whitespace-preline">
                      <ul *ngIf="activeDoctors != []" class="list-group" id="menu">
                        <div class="message-button-group" *ngFor="let activeDoctor of activeDoctors">
  
                              <li class="list-group-item list-group-item-link pt-3">
                                <div class="list-wrap-closed d-inline-flex ">
                                  <div class="img-cont col-1" *ngIf="activeDoctor.image != null;else standard_image">
                                    <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                  </div>
                                  <ng-template #standard_image>
                                    <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                  </ng-template>
                                  <p class="body2b-b"> {{ activeDoctor.name }}</p>
                                  <p class="body2"> {{ activeDoctor.designation }}</p>
                                </div>
                            </li>
                          </div>
                        </ul>
                      
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
  
                  <div class="col-sm-1 col-2 p-0">
                    <div class="avatar-icon-sm">
                      <img src="./../../assets/images/docter_color.png">
                    </div>
                  </div>
                </div>
              </div>
  
  
              <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_1' ">
                <div class="col-3"><hr class="hr-chat"></div>
                <div class="col-6"><p class="mt-1 mb-0 body2">Viestisi ovat luottamuksellisia</p></div>
                <div class="col-3"><hr class="hr-chat"></div>
              </div>
  
  
  
  
              <div class="col-md-12 " *ngIf=" message.template == 'TEMPLATE_2' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
                      <div class="col-12 ps-0 pe-0 ps-md-2 pe-md-2">
                        <div class="alert alert-danger" *ngIf="discountErr">
                          <p class="body2b text-center">Alennuskoodisi on virheellinen tai se on umpeutunut</p>
                        </div>
                        <div id="discount-wrap" class="mb-3" *ngIf="!discountSuccess">
                          <p class="body2 mb-1">Onko sinulla alekoodi?</p>
                          <div class="col-12 p-0 m-0 row">
                            <div class="col-12 col-md-8 p-0">
                              <input [disabled]="message.oldMessage" class="form-control" id="eelik-{{message.id}}">
                            </div>
                            <div class="col-12 col-md-4 p-0 text-start text-md-end">
                              <button [disabled]="message.oldMessage" class="btn btn-veteva-secondary mt-3 mt-md-0" (click)="fetchDiscountCode()">Lunasta</button>
                            </div>
                          </div>
                        </div>
                        <div class="alert alert-success" *ngIf="discountSuccess">
                          <p class="body2b text-center">Alekoodi "{{discountCode['code']}}" (- {{discountCode['discount_percent'] * 100 }}%) lunastaminen onnistui. Päivitetyt hinnat ovat nyt näkyvissä.</p>
                        </div>
                      
                      </div>

                      <p class="body2">Valitse konsultaatiovaihtoehto (klikkaa):</p>
                        <ul class="list-group" id="menu">
                          <div *ngFor="let p of feePackages" id="package-{{p._id}}" class="full-wrap mb-3 p-0 col-12 ">
                              <button [disabled]="message.oldMessage" (click)=" payConsultationFee(message,p)" class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2">
                                <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                  <div class="col-9 pe-3 ps-3">
                                    <p class="ms-2 body214bb text-start">{{p.name}}</p>
                                  </div>
                                  <div class="col-2 p-0">
<!--                                     <i class="fas fa-info-circle product-info-icon float-right expand-icon" (click)="openProductInfoModal($event,p)"></i> -->
                                    <div class="col-12 pe-0 ps-0">
                                      <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 " [ngClass]="{'selected-button':  message.selectedPackage?.id === p.id }">
                                        <div class="col-12">
                                        <p *ngIf="!discountCode['discount_percent']" class="body214bb text-end">{{p.price}}€</p>
                                        </div>
                                        <div class="col-12">
                                        <p *ngIf="discountCode['discount_percent']" class="body2 text-end"><span class="old-price">{{p.price}}€</span> {{(p.price * (1-discountCode.discount_percent)).toFixed(2)}}€</p>
                                      </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
<!--                                 <div class="col-12 pe-0 ps-0">
                                  <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 " [ngClass]="{'selected-button':  message.selectedPackage?.id === p.id }">
                                    <div class="col-12">
                                    <p *ngIf="!discountCode['discount_percent']" class="body2 text-end">{{p.price}}€</p>
                                    </div>
                                    <div class="col-12">
                                    <p *ngIf="discountCode['discount_percent']" class="body2 text-end"><span class="old-price">{{p.price}}€</span> {{(p.price * (1-discountCode.discount_percent)).toFixed(2)}}€</p>
                                  </div>
                                  </div>
                              </div> -->
                              
                              
                            </button>
                        </div>
                        </ul>
                        <ul class="list-group" id = "bambora-checkout-btn" *ngIf="message.oldMessage != true && bamboraPaymentButtonVisible == true">
                          <div *ngFor="let method of bamboraPaymentMethods" class="full-wrap mb-3">
                              <button class="w-100 full-wrap list-group-item list-group-item-link pt-3 ps-1 pe-1" id="{{method.selected_value}}-{{message.id}}" (click)="makeBamboraPayment(method.selected_value)">
                                <div class="list-wrap-closed d-inline-flex col-12 p-0">
                                  <img src="{{method.img}}" class="icon-image"> 
                                  <p class="body2 mt-md-2 body2">{{method.name}}</p>
                                </div>
                              
                            </button>
                          </div>
                        </ul>
                        
                    </div>
                    <div class="col-1 col-lg-5 col-xl-6"></div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  
                </div>
          
              </div>
  
              <!-- TEMPLATE 2 ANSWER OSTOKSEN YHTEENVETO -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_2' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-6 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="d-flex justify-content-end">
                    <div class="text-message text-message-right me-2 pe-2">
                      <p class="body2-w">Ostokseni:</p>
                      <ul class="list-group p-list">
                      <li class="list-group-item  package-list-item list-group-item-link pt-3 col-12 ps-0 pe-4 ">
                        <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                          <div class="col-9 pe-3 ps-3">
                            <p class="ms-2 body214bb text-start">{{message.answer['name']}}</p>
                          </div>
                          <div class="col-2 ">
                            <div class="col-12 pe-0 ps-0">
                              <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 ">
                                <div class="col-12 p-0">
                                <p class="body214bb text-end">{{message.answer['price']}}€</p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                    
                      
                         </li>
                      </ul>
                      </div>
                    </div>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
  
                </div>
              </div>
  
<!--                 <div class="col-md-12 " (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_3' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="p-0">
                    <div class="avatar-icon-chat">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div>
  
                  <div class="col-10 col-md-5 p-0 m-2">
                    <p class = "doctor-name-text ms-2 pb-2"style="color: black;">{{this.currentActiveDoctorName}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body3"> {{message.actMessage | newlineHtml}}</p>
    
                    </div>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
   -->

   
  
              <!-- TEMPLATE 4 ELÄIMEN VALITSEMINEN -->
              <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_21' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Millä lemmikillä haluat etäeläinlääkärille?</p>
  
                      <ul *ngIf="pets!= [];else no_pets" class="list-group" id="menu">
                        <div class="p-list" *ngFor="let pet of pets">
    
<!--                               <button [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,pet._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                              [ngClass]="{selectedButton: message.answer == pet._id }" class="list-group-item list-group-item-link ps-0 pe-0 ps-md-2 pe-md-2 pt-3 mb-2 active-doctors-wrap ">
                                <div class="list-wrap-closed d-inline-flex pe-0 ps-0 me-md-0 ps-md-0 pe-md-2 col-7 ">
                                  <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                                    <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="lemmikkisi">
                                  </div>
                                  <ng-template #standard_image>
                                    <img class="small-pic-doc mt-1 me-1 ms-1 " src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                  </ng-template>
                                  <div class="d-flex flex-column p-0">
                                    <div class="doc-name pe-0 ps-1 ps-md-2 pe-md-2 col">
                                      <p class=" doc-show-text body1"> {{ pet.name }}</p>
                                    </div>
                                    <div class="doc-designation pe-0 ps-1 ps-md-2 pe-md-2 col">
                                      <p class=" doc-show-text body3">{{ pet.breed }}</p>
                                    </div>
                                  </div>
                                </div>
                              </button> -->

                              <button [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,pet._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                              [ngClass]="{selectedButton: message.answer == pet._id }" class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2 mb-2">
                                <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                  <div class="col-12 pe-1 ps-1 row m-0">
                                    <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                      <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                                        <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="eläinlääkäri">
                                      </div>
                                      <ng-template #standard_image>
                                        <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                      </ng-template>
                                    </div>
                                    <div class="col-9 ps-0 ps-md-2 text-start">
                                      <div class="d-flex flex-column">
                                        <div class="doc-name">
                                          <p class="ms-2 doc-show-text body214bb"> {{ pet.name }}</p>
                                        </div>
                                        <div class="doc-designation">
                                          <p class="ms-2 doc-show-text body2"> {{ pet.breed }}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
      
                                </div>
                              
                              
                            </button>
                          </div>
                        </ul>

                        
                        <ng-template #no_pets>
                          <div class="alert alert-danger">
                            <p>Sinun tulee lisätä lemmikki ennen kuin voit mennä eteenpäin</p>
                          </div>
                        </ng-template>
    
                    </div>
                    <p class="text-message-time-left mt-2 m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE 4 ANSWER PET CHOISE -->
              <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_21' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="text-message text-message-right me-md-2">
                      <p class="body2-w">Valitsemani lemmikki:</p>
                      <div  *ngFor="let pet of pets">
                        <div *ngIf="pet._id == message.answer">
                          <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                            <div class="list-wrap-closed d-inline-flex ">
                              <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                                <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="lemmikkisi">
                              </div>
                              <ng-template #standard_image>
                                <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                              </ng-template>
                              <div class="d-flex flex-column">
                                <div class="doc-name">
                                  <p class="ms-2 doc-show-text body214bb"> {{ pet.name }}</p>
                                </div>
                                <div class="doc-designation">
                                  <p class="ms-2 doc-show-text body2"> {{ pet.breed }}</p>
                                </div>
                              </div>
                            </div>
                        </li>
                        </div>
                      </div>
  
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>
              </div>
  

              <!-- TEMPLATE 5 WEIGHT -->
              <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_5' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Syötä lemmikkisi paino / kg:</p>
                          <div class="list-wrap-closed weight-row p-0 ps-md-2 pe-md-2">
                              <div class="doc-name col-12 ps-0 ps-md-2 pe-md-2">
                                <input type="number" min="0" inputmode="decimal" pattern="[0-9]*" class="form-control weight-input pt-2 pb-2 w-100" placeholder="Kirjoita paino tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"><br>
                              </div>
                              <div class="col-4 p-0 ps-md-2 pe-md-2">
                                <button class="btn btn-veteva-secondary " [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valmis</button>
                              </div>
                          </div>   
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE 5 ANSWER WEIGHT -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_5' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right me-2">
                        <p class="body2-w">{{message.answer}} kg</p>
                        <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                    </div>
                  </div>
  
<!--                   <div class="p-0 d-flex align-items-end">
                    <div class="avatar-icon-chat">
                      <img src="./../../assets/images/chat2Vet_some.png">
                    </div>
                  </div> -->
                </div>
              </div>
  
             
              <!-- TEMPLATE 6 REASON FOR VISIT -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_6' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Valitse listalta vaivaa parhaiten kuvaava vaihtoehto</p>
                      
<!--                         <input list="reasons-arr-data" required class="message-select p-2""> -->
                          <select  id="reasons-arr-data" class="p-2 mb-2 me-4 w-100"[(ngModel)]="message.answer" [disabled]="message.oldMessage">
                          <option disabled="true"></option> 
                          <option *ngFor="let syy of reasonsArr" >{{syy.reason}}</option>
                        </select> 

                      
                        <button class="btn btn-veteva-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valitse</button>
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE 6 ANSWER REASON FOR VISIT -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_6' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  
              <!-- TEMPLATE 7 BEFORE? -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_7' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Onko vaivaa ollut aikaisemmin?</p>
                      
                      <div class="d-flex justify-content-between">
                      <button class="btn btn-veteva-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Kyllä') && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>
  
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei') && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                      </div>
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE 7 ANSWER FOR BEFORE? -->
              <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_7' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                    </div>
                  </div>
  
<!--                   <div class="p-0 d-flex align-items-end">
                    <div class="avatar-icon-chat">
                      <img src="./../../assets/images/chat2Vet_some.png">
                    </div>
                  </div> -->
                </div>
              </div>
  
              <!-- TEMPLATE 8 WHEN STARTED -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_8'" [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="col-0 p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Koska vaiva alkoi?</p>
                      
  
                        <input type="date" min='1899-01-01' placeholder="dd-mm-yyyy" max='2000-13-13' id="birthdate" class="form-control eeli" max="{{today}}" [(ngModel)]="message.answer" [readonly]="message.oldMessage" required><br>
                      
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valitse</button>
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE ANSWER 8 WHEN STARTED? -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_8' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer | pformateDate}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                    </div>
                  </div>
  
<!--                   <div class="p-0 d-flex align-items-end">
                    <div class="avatar-icon-chat">
                      <img src="./../../assets/images/chat2Vet_some.png">
                    </div>
                  </div> -->
                </div>
              </div>
  
  
              <!-- TEMPLATE 9 TELL MORE -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_9' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                    <div class="text-message text-message-left whitespace-preline">
                      <p class="body2">Kerro lisää vaivasta</p>
                      
                        <textarea type="text" class="form-control more-symptom-txt whitespace-preline" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                        <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valmis</button>
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE ANSWER 9 TELL MORE -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_9' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w break-word">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>

                </div>
              </div>
  
               <!-- TEMPLATE 10 CRONICAL ILLS -->
               <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_10' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Onko lemmikilläsi kroonisia sairauksia?</p>
                      
                      <div class="d-flex justify-content-between">
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Kyllä') && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>
  
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei') && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                      </div>
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p> 
                    </div>
                </div>
              </div>
  
              <!-- TEMPLATE 10 ANSWER CHRONICAL ILLS  -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_10' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9">
                    <div class="d-flex justify-content-end">
                      <div class="text-message text-message-right me-2">
                        <p class="body2-w">{{message.answer}}</p>
                        <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
  
              <!-- TEMPLATE 11 Lääkitys -->
              <div class="col-md-12 pb-2 no-padding"  *ngIf=" message.template == 'TEMPLATE_11' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
     
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
    
                      <p class="body2">Onko lemmikilläsi joku lääkitys?</p>                   
                      <textarea type="text" class="form-control more-symptom-txt" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei ole lääkitystä') && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Ei ole lääkitystä' }">Ei ole lääkitystä</button><br><br>
                      <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Lähetä</button>
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>  
                    </div>
                </div>
              </div>
              
    
              <!-- TEMPLATE 11 ANSWER CHRONICAL ILLS  -->
              <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_11' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9">
                    <div class="d-flex justify-content-end">
                      <div class="text-message text-message-right me-2">
                        <p class="body2-w">{{message.answer}}</p>
                        <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                    </div>
                  </div>
  
                </div>
              </div>
  
  
              <!-- TEMPLATE 12 Basic -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_12' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
    
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
                      <p class="body3">{{message.actMessage | newlineHtml}}</p>
                        <br />
                      </div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
    
                    </div>
                </div>
              </div>
  
  
              <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_13' ">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right whitespace-preline me-2">
                          <p class="body2-w mb-1 break-word">{{message.actMessage | newlineHtml}}</p>
                        
                        <p class="text-message-time text-end">{{message.createdDate | chatTimeIndicator}}</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 m-0 ">
              </div>
              </div>

              <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_33' ">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right whitespace-preline me-2">
                        <div class="image-container">
                          <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                          <div class="overlay-image">
                            <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon" title="Lähennä kuvaa">
                              <i class="fas fa-search-plus"></i>
                            </a>
                          </div>
                        </div>

                        
                        <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 m-0 ">
              </div>
              </div>

              <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_43' ">
                <div class="row m-0">
                  <div class="col-md-7 col-3">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right whitespace-preline me-2">
                        <video class="kuvaviesti" controls  alt="">
                          <source src="{{message.actMessage.location}}#t=0.001" >
                        </video>

                        
                        <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 m-0 ">
              </div>
              </div>

  
              <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_14' ">
                <div class="col-md-12 p-0">
                <div class="row m-0">
                  <div class="p-0 mt-4 col-0-nset">
                    <div class="avatar-icon-chat">
                      <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                      
                    </div>
                  </div>
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                    <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                    <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left-doctor whitespace-preline">

                      <div [innerHtml]="message.actMessage" class="body2 break-word text-container"></div>

                    </div>
                  </div>
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                </div>
              </div>
                <div class="col-12 p-0 m-0 row ps-4">

                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

                </div>
              </div>

              <!--Veterian sent image-->
              <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_34' ">
                <div class="col-md-12 p-0">
                <div class="row m-0">
                  <div class="p-0 mt-4 col-0-nset">
                    <div class="avatar-icon-chat">
                      <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    </div>
                  </div>
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                    <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                    <div class="d-flex justify-content-start p-0">
                      <div class="text-message text-message-left-doctor whitespace-preline">
                        <div class="image-container">
                          <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                          <div class="overlay-image">
                            <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon" title="Lähennä kuvaa">
                              <i class="fas fa-search-plus"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                </div>
              </div>
                <div class="col-12 p-0 m-0 row ps-4">
                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
              </div>

              <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_44' ">
                <div class="col-md-12 p-0">
                <div class="row m-0">
                  <div class="p-0 mt-4 col-0-nset">
                    <div class="avatar-icon-chat">
                      <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                      
                    </div>
                  </div>
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                    <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                    <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left-doctor whitespace-preline">
                      <div >
                        <video class="kuvaviesti" controls alt="">
                          <source src="{{message.actMessage.location}}#t=0.001" >
                        </video>
                    </div>
                    </div>
                  </div>
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                </div>
              </div>
                <div class="col-12 p-0 m-0 row ps-4">

                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

                </div>
              </div>

              <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_54' ">
                <div class="col-md-12 p-0">
                <div class="row m-0">
                  <div class="p-0 mt-4 col-0-nset">
                    <div class="avatar-icon-chat">
                      <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                      
                    </div>
                  </div>
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                    <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                    <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left-doctor whitespace-preline">
                      <div class="d-flex justify-content-center">
                        <i *ngIf="message.actMessage.mimetype == 'application/pdf'" class="fas fa-file-pdf att-icon pdf-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word att-icon word-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel att-icon xls-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'text/plain'" class="fas fa-file-alt att-icon icon-txt"></i>
                    </div>
                    <br>
                    <p class="body3-b">{{message.actMessage.originalname}}</p>
                    <div class="text-center">
                      <button class="btn btn-veteva-secondary" (click)="this.fileUploaderService.downloadAttachment(message.actMessage)"><i class="fas fa-download me-2"></i>Lataa</button>

                    </div>
                    <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                </div>
              </div>
                <div class="col-12 p-0 m-0 row ps-4">

                  

                </div>
              </div>

              

<!--               <div class="col-12 no-padding" *ngIf="globalMessages[currentActiveDoctorName]?.typing">
                <div class="col-md-12 p-0">
                <div class="row m-0 ">
                  <div class="p-0 mt-4">
                    <div class="avatar-icon-chat">
                      <img src="{{this.activeConsultantData['wantedImage']}}">
                      
                    </div>
                  </div>
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                    <div class="text-message text-message-left-doctor whitespace-preline">

                      <div class="body1 break-word">Kirjottaa..</div>
                    </div>
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                </div>
              </div>
              <div class="col-12 p-0 m-0 row ps-4">

                <p class="text-message-time-left mt-2 ms-4">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
              </div> -->

                

  
              <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_15' ">
                <div class="col-3"><hr class="hr-chat"></div>
                <div class="col-6"><p class="mt-1 mb-0 body2">Eläinlääkäri lopetti keskustelun</p></div>
                <div class="col-3"><hr class="hr-chat"></div>
              </div>

              <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_16' ">
                <div class="col-3"><hr class="hr-chat"></div>
                <div class="col-6"><p class="mt-1 mb-0 body2b-b">Eläinlääkäri siirsi sinut takaisin jonoon</p></div>
                <div class="col-3"><hr class="hr-chat"></div>
              </div>
  
                <!-- TEMPLATE 10 CRONICAL ILLS -->
                <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_19' " [ngClass]="{'disabled-input': message.oldMessage}">
                  <div class="row m-0">
              
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                      <p class = "body3b mb-1">Palautekysely</p>
                      <div class="text-message text-message-left whitespace-preline">
    
                        <p class="body2b-b">Kuinka onnistuimme tänään?</p>
                        <div class="d-flex col-12 m-0 p-0 row mb-2">
                          <div class="d-flex col-4 justify-content-start">
                            <i class="far fa-frown sad-smile fa-2x"></i>
                          </div>
                          <div class="d-flex col-4 p-0 justify-content-center">
                            <i class="far fa-meh meh-smile fa-2x"></i>
                          </div>
                          <div class=" col-4 p-0 d-flex justify-content-end">
                            <i class="far fa-smile fa-2x"></i>
                          </div>
                        </div>  
                        <p class="body2"><i class="gray-italic">Antamani arvosana <span *ngIf="customerNPSRange != -1;else feedback_added">{{customerNPSRange}}/10</span> <ng-template #feedback_added><span>5/10</span></ng-template> (liu'uta)</i></p>
                                    
                        <div id="feedback-container" class="feedback-container">
                          <form [formGroup]="addFeedback"
                            (ngSubmit)="AddFeedBack()">
                            <div class="form-group">
                              <div class="slidecontainer">
                                <input 
                                  (ngModelChange)="changeNpsRangeValue()"
                                  type="range" 
                                  min="0" 
                                  formControlName="npsRange"
                                  max="10"
                                  value="5"
                                  class="slider" 
                                  id="npsRange-{{message.id}}">
                              </div>
                            </div>
                            <div class="form-group">
                              <p class="body2">Kerro hieman sanallisesti. </p>
                              <textarea 
                                type="text" 
                                class="form-control"
                                formControlName="npsText"
                                placeholder="Kirjoita vapaamuotoinen palautteesi tähän..."
                                id="npsText-{{message.id}}" ></textarea>
                            </div>
                            <div class="form-group col-12 row mt-2">
                              <div class="col-10">
                                <p class="body2">Minuun saa olla olla yhteydessä palautteen tiimoilta.</p>
                              </div>
                              <div class="col-2 mt-3">
                                <div class="squaredThree">
                                  <input id="acceptedContact-{{message.id}}" type="checkbox" class="unique-class" value="checked"/>
                                  <label for="acceptedContact-{{message.id}}" class="sq-lable"></label>
                                </div>  
                              </div>
                            </div>
                            <div class="col-12 ps-0">
                              <button class="btn btn-veteva-secondary mb-3" [disabled]="addFeedback.invalid">Lähetä</button>
                            </div>          
                          <div class="col-12 ps-0 mb-3">
                            <a class="pt-4 pointer veteva-main-link" (click)="changeSessionStatus()">En anna palautetta</a>
                          </div>
                        </form>
                      </div>
                      <div id="after-feedback-container" class="redirect-info">
                        <div class="alert alert-success">
                          <p>Kiitos, Palautteen antaminen onnistui! Sinut ohjataan automaattisesti aloitusnäkymään 10 sekunnin päästä.</p>
                          <button class="btn btn-veteva-secondary" (click)="changeSessionStatus()">Takaisin alkuun</button>
                        </div>
                      </div>
      
                      </div>
                        <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                  </div>
              </div>

              <!-- TEMPLATE 20 Credit usage? -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_20' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                    <div class="text-message text-message-left whitespace-preline pb-4">
  
                      <p class="body2">{{message.actMessage}}</p>
                      
                      
                      <button class="btn btn-veteva-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == true }">Käytetään maksettua käyntiä</button>
  
                      <button class="btn btn-veteva-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message, false) && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == false }">Haluan ostaa uuden käynnin</button> <br>
                        <p class="text-message-time-left mt-2 pb-4">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                    </div>
                </div>
              </div>

            <!-- TEMPLATE 20 ANSWER Which credit to use  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_20' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <div class="col p-0">
                    <div *ngIf="message.answer == true;else not_true">
                      <p class="body2-w">Käytetään maksettua käyntiä</p>
                    </div>
                    <ng-template #not_true>
                      <p class="body2-w">Haluan ostaa uuden käynnin</p>
                    </ng-template>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                  </div>
                  </div>
                </div>
  
              </div>
            </div>

           <!-- TEMPLATE 23 Lemmikki kastroitu -->
           <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_23' " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
<!--                 <div class="p-0">
                <div class="avatar-icon-chat mt-4">
                  <img src="./../../assets/images/chat2Vet_some.png">
                  
                </div>
              </div> -->

              <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                <div class="text-message text-message-left whitespace-preline">

                  <p class="body2">Onko lemmikki kastroitu tai steriloitu?</p>
                  
                  <div class="d-flex justify-content-between">
                  <button class="btn btn-veteva-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                  [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                  <button class="btn btn-veteva-secondary mt-0" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                  </div>

                </div>
                <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>

                </div>
            </div>
          </div>

        <!-- TEMPLATE 21 ANSWER agreement  -->
        <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_23' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
          <div class="row m-0">
            <div class="col-md-7 col-3">
              <p class="d-none">To take some space</p>
            </div>
            <div class="col-md-5 col-9 p-0">
              <div class="d-flex justify-content-end p-0">
              <div class="text-message text-message-right me-2">
                <div *ngIf="message.answer == true;else not_true">
                  <p class="body2-w">Kyllä</p>
                </div>
                <ng-template #not_true>
                  <p class="body2-w">Ei</p>
                </ng-template>
                <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
              </div>
              </div>
            </div>

<!--               <div class="p-0 d-flex align-items-end">
              <div class="avatar-icon-chat">
                <img src="./../../assets/images/chat2Vet_some.png">
              </div>
            </div> -->
          </div>
        </div>

              <!-- TEMPLATE 21 Agreement with another doctor? -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_4' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">Olitteko sopineet ajasta eläinlääkärin kanssa?</p>
                      
                      
                      <button class="btn btn-veteva-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == true }">Kyllä olimme</button>
  
                      <button class="btn btn-veteva-secondary mt-3 mt-md-3 mt-xl-0" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                        [ngClass]="{selectedButton: message.answer == false }">Emme ole sopineet</button>
                      
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
  
                    </div>
                </div>
              </div>

            <!-- TEMPLATE 21 ANSWER agreement  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_4' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <div *ngIf="message.answer == true;else not_true">
                      <p class="body2-w">Kyllä olimme</p>
                    </div>
                    <ng-template #not_true>
                      <p class="body2-w">Emme ole sopineet</p>
                    </ng-template>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
                </div>
              </div>
            </div>

              <!-- TEMPLATE 22 Agreement with another doctor? -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_22' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
  
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left whitespace-preline">
  
                      <p class="body2">{{message.actMessage}}</p>
                      
                      
                      <ul *ngIf="activeDoctors.length != 0;else no_doctors" class="list-group" id="menu">
                        <div class="p-list" *ngFor="let doc of activeDoctors">
        
<!--                               <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                                <div class="list-wrap-closed d-inline-flex ">
                                  <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                                    <img class="small-pic-doc mt-1" src="{{doc.image}}" alt="eläinlääkäri">
                                  </div>
                                  <ng-template #standard_image>
                                    <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                  </ng-template>
                                  <div class="d-flex flex-column">
                                    <div class="doc-name">
                                      <p class="ms-2 doc-show-text body1"> {{ doc.name }}</p>
                                    </div>
                                    <div class="doc-designation">
                                      <p class="ms-2 doc-show-text body3"> {{ doc.designation }}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="pull-right">
                                  <button class="pick-product-btn pull-right" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,doc._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                                  [ngClass]="{selectedButton: message.answer == doc._id }">Valitse</button>
                                </div>
                            </li> -->

                            <button class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,doc._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                            [ngClass]="{selectedButton: message.answer == doc._id }">
                              <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                <div class="col-12 pe-1 ps-1 row m-0">
                                  <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                    <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                                      <img class="small-pic-doc mt-1" src="{{fileURL}}/{{doc.image.key}}" alt="eläinlääkäri">
                                    </div>
                                    <ng-template #standard_image>
                                      <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                    </ng-template>
                                  </div>
                                  <div class="col-9 ps-0 ps-md-2 text-start">
                                    <div class="d-flex flex-column">
                                      <div class="doc-name">
                                        <p class="ms-2 doc-show-text body214bb"> {{ doc.name }}</p>
                                      </div>
                                      <div class="doc-designation">
                                        <p class="ms-2 doc-show-text body2"> {{ doc.designation }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
    
                              </div>
                            
                            
                          </button>


                          </div>
                        </ul>
                        <ng-template #no_doctors>
                          <div class="alert alert-warning">
                            <p>Eikä! Valitettavasti yhtään eläinlääkäriä ei ole paikalla. Asiasta ilmoitettu johtavalle eläinlääkärille. Voit jatkaa huolettomasti eteenpäin kyselyssä.  
                            </p>
                            <button class="btn btn-veteva-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                            [ngClass]="{selectedButton: message.answer == false }">OK</button>
                          </div>
                        </ng-template>
    
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>

            <!-- TEMPLATE 22 ANSWER doctor  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_22' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">Valitsemasi eläinlääkäri</p>
                    <div  *ngFor="let doc of activeDoctors">
                      <div *ngIf="doc._id == message.answer">
                        <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                          <div class="list-wrap-closed d-inline-flex ">
                            <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                              <img class="small-pic-doc mt-1" src="{{fileURL}}/{{doc.image.key}}" alt="lemmikkisi">
                            </div>
                            <ng-template #standard_image>
                              <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                            </ng-template>
                            <div class="d-flex flex-column">
                              <div class="doc-name">
                                <p class="ms-2 doc-show-text body1"> {{ doc.name }}</p>
                              </div>
                              <div class="doc-designation">
                                <p class="ms-2 doc-show-text body3"> {{ doc.designation }}</p>
                              </div>
                            </div>
                          </div>
                      </li>
                      </div>
                    </div>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
  
              </div>
            </div>
              
  
              <!-- TEMPLATE 17 Basic -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
    
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left break-word w-100">
                      <p class="body2">Esitietokysely on nyt suoritettu. Alla yhteenveto vastaamistasi asoista.</p>
                      
                      <table class= "table-clinic-times">
                          
                        <tr scope="row" class="sumup-row pb-2">
                          <td class="clinic-bought-content body3 pt-2">Kastroitu</td>
                          <td class="clinic-bought-content-right pt-2 body3b"><span *ngIf="message.actMessage['cast']">Kyllä</span><span *ngIf="message.actMessage['cast'] == false">Ei</span></td>
                        </tr>
                        <tr class="sumup-row pb-2">
                          <td class="clinic-bought-content body3">Lemmikki</td>
                          <div *ngFor="let pet of pets">
                            <div *ngIf="pet._id == message.actMessage['pet']">
                              <td class="clinic-bought-content-right body3b">{{pet.name}}</td>
                            </div>
                          </div>
                        </tr>
                        <tr class="sumup-row pb-4">
                          <td class="clinic-bought-content body3">Paino</td>
                          <td class="clinic-bought-content-right body3b">{{message.actMessage['weight']}}</td>
                        </tr>
                        <tr class="sumup-row pb-4">
                          <td class="clinic-bought-content body3">Käyntisyy</td>
                          <td class="clinic-bought-content-right body3b">{{message.actMessage['reason']}}</td>
                        </tr>
                        <tr class="sumup-row pb-4">
                          <td class="clinic-bought-content body3">Vaivaa aikaisemmin</td>
                          <td class="clinic-bought-content-right body3b">{{message.actMessage['before']}}</td>
                        </tr>
                        <tr class="sumup-row pb-4">
                          <td class="clinic-bought-content body3">Vaiva alkoi</td>
                          <td class="clinic-bought-content-right body3b">{{message.actMessage['started'] | pformateDate}}</td>
                        </tr>
                        <tr class="sumup-row pb-4">
                          <td class="clinic-bought-content body3">Lisäkertomus</td>
                          <td class="clinic-bought-content-right body3b table-word-wrap break-word">{{message.actMessage['addInfo']}}</td>
                        </tr>
                        <tr class="sumup-row pb-2">
                          <td class="clinic-bought-content body3 pb-2">Krooniset sairaudet</td>
                          <td class="clinic-bought-content-right pb-2 body3b">{{message.actMessage['chronical']}}</td>
                        </tr>
                        <tr>
                          <td class="clinic-bought-content body3 pb-2">Lääkitys</td>
                          <td class="clinic-bought-content-right pb-2 body3b break-word">{{message.actMessage['medicine']}}</td>
                        </tr>
                      
                      </table>
                                            
                      </div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>
              <!-- TEMPLATE 17 Basic -->
              <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
                <div class="row m-0">
<!--                   <div class="p-0">
                    <div class="avatar-icon-chat mt-4">
                      <img src="./../../assets/images/chat2Vet_some.png">
                      
                    </div>
                  </div> -->
    
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                    <div class="text-message text-message-left break-word w-100">
                      <p class="body2">Eläinlääkäri ottaa sinut tutkimushuoneeseen tuota pikaa.</p>
                                            
                      </div>
                      <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                </div>
              </div>

              
            
            </div>
          </div>


                    <!-- INFOSCREEN PET INFOLLE -->
                    <div style="display: none;" id="active-user-data-info" class="info-screen">
                      <button class="btn btn-veteva-secondary-edit mb-2 no-print m-3" type="button" (click)="CloseInfoScreen($event,'active-user-data-info')"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
                      <h4 class="text-center header3">Lemmikin "{{infoToPetScreen['name']}}" tiedot</h4>
                    <table class="table w-50 active-pet-table mx-auto">
          
                      <tbody>
                        <tr>
          
                          <td class="text-start">Nimi</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['name'] }}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Eläinlaji</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['species'] | changeSpecies }}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Rotu</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['breed'] }}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Sukupuoli</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['sex'] | changeGender}}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Syntymäpäivä</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['birthDate']  | pformateDate}}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Mikrosiru</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['microCard'] }}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Rekisterinumero</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['registerNumber'] }}</p>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-start">Lisätiedot</td>
                          <th class="text-end" scope="row">
                            <p class="m-0">{{ infoToPetScreen['addInfo'] }}</p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center col-12">
                        <button class="btn btn-veteva-secondary" id="edit-{{infoToPetScreen['_id']}}" routerLink="/{{clinicID}}/pets/edit-pet/{{infoToPetScreen['_id']}}">Muokkaa lemmikkiä</button>
                    </div>
                    <br>
                    <br>
                    </div>

                    <div id="visit-data-info" style="display: none;" class="info-screen container-fluid">
                      <button class="btn btn-veteva-secondary-edit mb-2 no-print m-3" type="button" (click)="CloseInfoScreen($event,'visit-data-info')"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>

                      <div *ngIf="this.visitHistory.length <= 0;else has_visits_content">
                        <div class="alert alert-warning">
                          <p class="text-center">Lemmikistä ei ole käyntitietoja</p>
                        </div>
                      </div>
                      <ng-template #has_visits_content>
                        <h4 class="text-center header3">Käynnit</h4>
                        <div class="col-12 col-md-12 d-inline-flex">
                          <div class="col-md-2 d-flex no-mobile"></div>
                          <div class="col-12 col-md-12 col-lg-8">
                          <ul class="list-group w-100" id="menu">
                              <div *ngFor="let visit of visitHistory" class="full-wrap pt-2 pb-2 mb-3">
                                  <div class="d-inline-flex col-md-12 ">
                                      <div class=" d-none d-md-block body1 col-md-2 d-flex mt-2">
                                          <p>Käynti</p>
                                      </div>
                                      <div class="col-md-6"></div>
                                      <div class="body1 col-md-4 mt-2 ">
                                          <p class="text-end text-md-start mb-0 body2b-b">Tiedot</p>
                                      </div>
                                  </div>
                                  <li class="list-group-item list-group-item-link d-inline-flex col-12 col-md-12 ps-0 pe-0 pt-0">
                                      <div class="col-2 col-md-1 p-0 d-flex"></div>
                                    <div class="list-wrap-closed d-inline-flex row col-10 col-md-10 p-0">
                                      <div class="img-cont col-2 col-md-2 p-0">
                                        <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="käynti">
                                      </div>
                                      <div class="d-flex col-8 col-md-7 p-0 d-flex flex-column">
                                          <div class="p-0">
                                              <p class=" mb-0 body2b-b ms-2 text-start">{{visit.createdDate | pformateDate}}</p>
                                          </div>
                                          <div class="p-0">
                                              <p class="text-start ms-2 body2">{{visit.regimen | truncate : 100 : false }}</p>
                                          </div>
                                      </div>
                                      <div class="col-1 col-md-3">
                                        <i class="fa fa-plus  expand-icon  mt-2 me-lg-3 me-xl-0"  id="expand-icon-{{visit._id}}" (click)="openExpandBox($event)"></i>
                                        <i class="fa fa-minus  close-icon  mt-2 me-lg-3 me-xl-0  poista" style="display: none;" id="close-icon-{{visit._id}}" (click)="closeExpandBox($event)"></i>
                                      </div>
                                    </div>
                                </li>
                                <ul class="list-group list-group-flush list" id="{{visit._id}}" >
                                  <li class="list-group-item list-group-item-link list-group-show">
                                   <div class=" d-inline-flex">
                                     <p class="ms-2 mt-1 body2b-b mb-0"><strong>Lopulliset diagnoosit:</strong></p>
                                   </div>
                                   <div class="final-diagnosis-wrap body2 ms-2 mb-0" *ngFor="let final of visit.diagnosisFinal">
                                     <p>{{final}},</p>
                                   </div>
                                  </li>
                                  <li class="list-group-item list-group-item-link list-group-show">
                                  <div class=" d-inline-flex">
                                    <p class="ms-2 mt-1 body2b-b mb-0"><strong>Vaihtoehtoiset diagnoosit:</strong></p>
                                  </div>
                                  <div class="diff-diagnosis-wrap body2 ms-2 mb-0" *ngFor="let diff of visit.diagnosisDiff">
                                    <p>{{diff}}, </p>
                                  </div>
                                   </li>
                                   <li class="list-group-item list-group-item-link list-group-show">
                                    <div class=" d-inline-flex">
                                      <p class="ms-2 mt-1 body2 mb-0"><strong>Hoito-ohje:</strong></p>
                                    </div>
                                    <div class="diff-diagnosis-wrap ms-2 mb-0">
                                      <p class="body2">{{visit.regimen}} </p>
                                    </div>
                                     </li>
                                   <li class="list-group-item list-group-item-link list-group-show">
                                    <div class=" d-inline-flex">
                                      <p class="ms-2 mt-1 body2b-b mb-0"><strong>Eläinlääkäri:</strong></p>
                                    </div>
                                    <div class="diff-diagnosis-wrap body2 ms-2 mb-0">
                                      <p>{{visit.doctorFullName}} </p>
                                    </div>
                                     </li>
                                   <li class="list-group-item list-group-item-link list-group-show">
                                    <div class="expanded-wrap">
                                          <button class="btn btn-veteva-secondary" routerLink="{{clinicID}}/user/visit-detail/{{visit._id}}">Käynti</button>
                                        </div>
                                   </li>
                                </ul>
                              </div>
                            </ul>
                          </div>
                          <div class="col-md-2 no-mobile d-flex"></div>
                          </div>
                      </ng-template>
            
            
                    </div>
  
          </div><!-- chat message main container :: END -->


  
          <!-- Reply Box -->
            <div *ngIf="!(this.currentActiveDoctorName === DR_ASSISTANT_NAME || this.currentActiveDoctorName === HELPER_NAME)">
            <div class="row reply me-0 ms-0 mt-4 col-12 p-0 pt-1 pb-1 ps-2" *ngIf="showReplyBox">
              <div class="col-1 p-0 ps-md-2 me-3 me-md-0 text-center mt-2">
                <div class="image-icon-wrapper mt-1">
                  <div class="btn-group dropup">
                    <label type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" class="pull-right "><i class="fas fa-plus veteva-icon-main" aria-hidden="true" for="input"></i></label>
      
                    <ul class="dropdown-menu">
                      <li class="dropdown-item"><label class="pointer" for="files"><i class="fas fa-camera me-2"></i>Kuvatiedosto</label></li>
                      <li class="dropdown-item"><label class="pointer" for="videos"><i class="fas fa-video me-2"></i>Videotiedosto</label></li>
                    </ul>
                  </div>
                  <input style="display:none" (change)="handleFiles($event)" type="file" accept=".jpg, .jpeg, .png" id="files"/>
                  <input style="display:none" (change)="handleVideos($event)" type="file" accept="video/mp4,video/x-m4v,video/*" id="videos"/>
                </div>
              </div>
              <div class="col-8 ps-0 reply-main ps-0 pe-0 mt-2">
                <textarea class="send-message-input" autocomplete="off" rows="1"  id="comment" [(ngModel)]="replyMessage" placeholder="Kirjoita tähän..."
                (keydown)="[UserTypingMessage(),autoTextarea($event) ]" (keyup.enter)="OnSendReply($event)"></textarea>
              </div>
              <div class="col-2 col-md-2 ps-2 pe-0 mt-1 mt-md-0">
                <!-- <button class="send-btn" (click)="OnSendReply($event)">Lähetä</button> -->
                <div class="send-btn-container pointer float-end text-center mt-1" (click)="OnSendReply($event)">
                  <i class="fas fa-arrow-up white-icon send-icon"></i>
                </div>
                <!-- <i class="fa fa-send fa-2x" aria-hidden="true" (click)="OnSendReply($event)"></i> -->
              </div>
    
          </div>
        </div>
        <p style="display:none">Kuva:</p>
        <canvas style="display:none" id="canvas" width=700 height=700></canvas>
          <!-- Reply Box End -->
  
  
        </div> <!-- chat windeow main container :: END -->

      
      <!--Section two :: END-->
  
  </div>
  <div id="video-cover" style="display: none;">
    <div class="col-12 row me-0 ms-0" id="preview-window">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="col-12" *ngFor="let pet of pets">
          <h1 *ngIf="pet.active == true" class="header3 text-center pt-4">Näyttääkö {{pet.name}} lemmikki hyvältä?</h1>
          <p class="body2bb text-center">Annathan selaimen luvan käyttää kameraa ja mikrofonia</p>
  
        </div>
        <div class="col-12">
          <div id="local-media" class="text-center video-preview-container">
          </div>
        </div>
        <div class="col-12 row me-0 ms-0">
          <div class="col-2"></div>
          <div class="col-12 col-md-8">
            <div class="text-center">
              <button class="btn btn-veteva-secondary" (click)="joinToVideoChat()">Kyllä, liityn keskusteluun</button>
 
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="local-media-sm-container" id="local-media-sm">
    </div>
    <div class="change-camera-container" *ngIf="videoAiring || !videoToken" (click)="changeCamera()">
      <div class="change-camera-bg">
        <i class="p-3 fas fa-retweet white-icon change-camera-icon"></i>
      </div>
    </div>
    <div class="col-12 p-0">
      <div id="remote-media-div" class=" text-center">
      </div>
      <div class="bottom-navbar col-12 p-0 row m-0">
        <div class="col-3 "></div>
        <div class="col-12 col-md-6 p-0">
          <div class="col-12 row m-0 p-0">
            <div class="col-3 text-center video-btn pt-2" (click)="muteAudioVideo()" *ngIf="!muted">
              <i class="fas fa-microphone-slash video-icon"></i><br>
              <p>Mykistä</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="unmuteAudioVideo()" *ngIf="muted">
              <i class="fas fa-microphone-slash video-icon exit-icon"></i><br>
              <p>Avaa mikrofoni</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="detachCameraVideo()" *ngIf="videoShow">
              <i class="fas fa-video video-icon"></i><br>
              <p class="body2bb">Sulje</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="attachCameraVideo()" *ngIf="!videoShow">
              <i class="fas fa-video video-icon exit-icon"></i><br>
              <p class="body2bb">Avaa</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="closeVideoBox()">
              <i class="fas fa-comment video-icon"></i><br>
              <p class="body2bb">Chat</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="acceptCloseVideoConsultation()">
              <i class="fas fa-times video-icon exit-icon"></i><br>
              <p class="body2bb">Poistu</p>
            </div>
          </div>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>

  <div id="image-enlarger" style="display: none;" (click)="closeImageEnlarger()">
    <div class="col-12" (click)="closeImageEnlarger()">
      <div class="text-end">
        <p class="header3M-w pointer mt-2 mt-md-0 me-4">Sulje ikkuna<i class="fas fa-times ms-2"></i></p>
      </div>
    </div>
    <div class="image-container-enlarger text-center">
      <img class="kuvaviesti" src="{{fileURL}}/{{enlargerImageKey}}" alt="">
  </div>

  </div>



  <button style="display: none;" id="open-buy-modal" data-bs-toggle="modal" data-bs-target="#buyModal"></button>
  <div class="modal fade" id="buyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <h5 class="header3-b text-center pt-4 pb-4">Olet ostamassa käyntiä</h5>
        <div class="modal-body">
          <div class="col-12 row">
            <div class="col-8">
              <div class="col">
                <p class="body3light mb-1">Tuote</p>
              </div>
              <div class="col">
                <p class="body2b-b mb-0">{{buyPackage}}</p>
              </div>
              <div class="col pb-2 pb-md-0">
                <p class="body3">{{packageDescription}}</p>
              </div>
            </div>
            <div class="col-2">
              <div class="col p-0">
                <p class="body3light mb-1">Käyntejä</p>
              </div>
              <div class="col p-0">
                <p class="body2b-b">{{packageCredit}}</p>
              </div>
            </div>
            <div class="col-2">
              <div class="col">
                <p class="body3light mb-1 p-0">Hinta</p>
                <div class="col p-0">
                  <p *ngIf="!discountCode['discount_percent']" class="body2b-b">{{packagePrice}}€</p>
                  <p *ngIf="discountCode['discount_percent']" class="body2b-b"><span class="old-price">{{packagePrice}}€</span> {{(packagePrice * (1-discountCode['discount_percent'])).toFixed(2)}}€</p>
                </div>
              </div>
            </div>
            <br>
        </div>
        <div class="modal-footer">
          <a style="display: none;" class="pointer veteva-main-link" id="close-shop-terms-link" (click)="closeShopTerms()">Sulje</a>
          <div class="col-12 col-md-12 accept-text" style="display: none;" id="shop-terms">
            <iframe src="https://veteva.vet/toimitus-ja-maksuehdot" width="100%" height="100%"></iframe>
          </div>
          <div class="col-md-12 mx-auto row">
            <div class="col-1 p-0 squaredThree mt-1">
              <input type="checkbox" class="unique-class"   id="terms-check" value="checked">
              <label for="terms-check" class="sq-lable"></label>              
            </div>
            <div class="col-10">
              <p class="body2 "><strong>Olen lukenut palvelun <a class="pointer veteva-main-link" (click)="openShopTerms()">toimitus- ja maksuehdot</a> ja hyväksyn ne.</strong>
            </div>
          </div>
          <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 pb-4">
            <div class="col-12 text-center">
              <div *ngIf="this.bamboraPaymentUrl =='';else url_found_content">
                <p>Pieni hetki linkkiä ladataan...</p>
              </div>
              <ng-template #url_found_content>
                <button (click)="sendToPay()" id="myBamboraPaymentBtn" class="btn btn-veteva-secondary">Maksa</button>
              </ng-template>            
            </div>
            <div class="col-12 text-center mt-4">
              <a data-bs-dismiss="modal" class="veteva-main-link">Peruuta</a>
            </div>
          </div>

        </div>
        </div>
      </div>
    </div>
  </div>

  <button id="openModal" #openModal [hidden]="true" 
  data-bs-toggle="modal" data-bs-target="#login-modal"></button>
    <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <h5 class="text-center header3-b pt-4">Lisää lemmikki</h5>

          <div class="modal-body">
            <p class="body2">Sinulla ei ole vielä yhtään lemmikkiä! Lisääthän itsellesi lemmikin, jonka kanssa haluat asioida eläinlääkärissä.</p>
          </div>
          <div class="col-12 row pb-4">
            <div class="col-12 text-center">
              <button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/add-pet" data-bs-dismiss="modal">Lisää lemmikki</button>
            </div>
            <div class="col-12 text-center pt-4 pb-4">
              <a class="pointer veteva-main-link" data-bs-dismiss="modal">Peruuta</a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <button id="start-consultation-modal" #startConsultationModal [hidden]="true" 
    data-bs-toggle="modal" data-bs-target="#check-modal"></button>
      <div class="modal fade" id="check-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div id="loader" class="loader-container" style="display:none">
                <div class="loader mx-auto"></div>
                </div>
              <h5 class="text-center header3-b pt-4 pb-3">Näin käynti sujuu mahdollisimman mukavasti:</h5>
            <div id="check-modal-body">
            <!-- ELÄIN VIERESSÄ -->
            <div class="col-md-12 mx-auto row">
              <div class="col-1 p-0">
                <i class="far fa-check-circle veteva-icon-main"></i>
              </div>
              <div class="col-10">
                <p class="body2"><strong>Lemmikkini on vieressäni</strong>
              </div>
            </div>
            <!-- ELÄIN VIERESSÄ -->
            <div class="col-md-12 mx-auto row">
              <div class="col-1 p-0">
                <i class="far fa-check-circle veteva-icon-main"></i>
              </div>
              <div class="col-10">
                <p class="body2 "><strong>Olen valmiudessa ottamaan valokuvia tai olen kuvannut vaivan etukäteen</strong>
              </div>
            </div>
            <!-- ELÄIN VIERESSÄ -->
            <div class="col-md-12 mx-auto row">
              <div class="col-1 p-0">
                <i class="far fa-check-circle veteva-icon-main"></i>
              </div>
              <div class="col-10">
                <p class="body2 "><strong>Olen valmis keskustelemaan eläinlääkärin kanssa chatissa keskimäärin 10-40 min</strong>
              </div>
            </div>
            <!-- ELÄIN VIERESSÄ -->
            <div class="col-md-12 mx-auto row">
              <div class="col-1 p-0">
                <i class="far fa-check-circle veteva-icon-main"></i>
              </div>
              <div class="col-10">
                <p class="body2 " data-bs-toggle="modal" data-bs-target="#no-resept"><strong>Ymmärrän, etten voi saada lääkereseptiä</strong> <i class="ms-3 far fa-question-circle"></i></p>
              </div>
            </div>
            <div class="col-12 row pb-4 mt-3">
              <div class="col-12 text-center ">
                <button class="btn btn-veteva-secondary" (click)="checkPetData()"><i class="fas fa-arrow-right me-2"></i>Aloita</button>
              </div>
              <div class="col-12 text-center mt-3 pointer">
                <a  id="check-close-btn" class="veteva-main-link" data-bs-dismiss="modal">Sulje</a>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>

      <button id="open-data-error-modal" style="display: none;" 
    data-bs-toggle="modal" data-bs-target="#data-error-modal"></button>
      <div class="modal fade" id="data-error-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div id="loader" class="loader-container" style="display:none">
                <div class="loader mx-auto"></div>
                </div>
              <h5 class="text-center header3-b pt-4 pb-3">Pahoittelumme, mutta lemmikiltäsi puuttuu tietoja. Tarvitsemme oheiset tiedot, jotta eläinlääkäri osaa palvella lemmikkiäsi paremmin!</h5>
            <div>
            <!-- ELÄIN VIERESSÄ -->
            <div *ngFor="let petErr of petDataErr">
              <div  *ngFor="let err of petErr.petErrors" class="col-md-12 mx-auto row">
                <div class="col-1 p-0">
                  <i class="fas error-icon fa-exclamation-circle"></i>
                </div>
                <div class="col-10">
                  <p class="body2">{{err}}</p>
                </div>
              </div>
           </div>
            <div *ngFor="let petErr of petDataErr" class="col-12 row pb-4 mt-3">
              <div class="col-12 text-center ">
                <button class="btn btn-veteva-secondary" (click)="routeToEdit(petErr.petID)" >Muokkaa lemmikkiä "{{petErr.petName}}"</button>
              </div>
              <div class="col-12 text-center mt-3 pointer">
                <a  id="close-err-data" class="veteva-main-link pointer" data-bs-dismiss="modal">Sulje</a>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>

      <button id="open-user-data-error-modal" style="display: none;" 
      data-bs-toggle="modal" data-bs-target="#user-data-error-modal"></button>
        <div class="modal fade" id="user-data-error-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div id="loader" class="loader-container" style="display:none">
                  <div class="loader mx-auto"></div>
                  </div>
                <h5 class="text-center header3-b pt-4 pb-3">Pahoittelumme, mutta sinulta puuttuu tietoja. Tarvitsemme oheiset tiedot, jotta eläinlääkäri osaa palvella sinua ja lemmikkiäsi paremmin!</h5>
              <div>
              <!-- ELÄIN VIERESSÄ -->
                <div  *ngFor="let err of userDetailErr" class="col-md-12 mx-auto row">
                  <div class="col-1 p-0">
                    <i class="fas error-icon fa-exclamation-circle"></i>
                  </div>
                  <div class="col-10">
                    <p class="body2">{{err}}</p>
                  </div>
             </div>
             <div class="text-center mt-4">
              <button class="btn btn-veteva-secondary mb-4" (click)="routeToEditUser()" routerLink="/{{clinicID}}/users/edit-details/{{currentUserId}}"><i class="fas fa-arrow-right me-2"></i>Muokkaa tietojasi</button><br>
              <a  id="close-err-data" class="veteva-main-link mt-4 pt-4 pb-4 pointer" data-bs-dismiss="modal">Sulje</a>
             </div>
            </div>
          </div>
          </div>
          </div>
        </div>

      

    <!-- TUOTEINFO MODAALI -->
    <button id="open-product-info" [hidden]="true" 
    data-bs-toggle="modal" data-bs-target="#product-info-modal"></button>
    <div class="modal fade" id="product-info-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<!--       <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{currentProduct.name}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              
            </button>
          </div>
          <div class="modal-body">
            <p>Tämä tuote oikeuttaa sinut: <strong>{{currentProduct.productCredit}} käyntiin</strong></p>
            <br>
            <p>{{currentProduct.productDescription}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Sulje</button>
          </div>
        </div>
      </div> -->

      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title header3" id="exampleModalLabel">Tuotetiedot</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12 row">
              <div class="col-8">
                <div class="col">
                  <p class="body3light mb-1">Tuote</p>
                </div>
                <div class="col">
                  <p class="body2b-b mb-0">{{currentProduct.name}}</p>
                </div>
                <div class="col pb-2 pb-md-0">
                  <p class="body3">{{currentProduct.productDescription}}</p>
                </div>
              </div>
              <div class="col-2">
                <div class="col p-0">
                  <p class="body3light mb-1">Käyntejä</p>
                </div>
                <div class="col p-0">
                  <p class="body2b-b">{{currentProduct.productCredit}}</p>
                </div>
              </div>
              <div class="col-2">
                <div class="col">
                  <p class="body3light mb-1 p-0">Hinta</p>
                  <div class="col p-0">
                    <p class="body2b-b">{{currentProduct.price}}</p>
                  </div>
                </div>
              </div>
              <br>
          </div>
          <div class="modal-footer">
            <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
              <div class="col-6 text-center">
                <button class="btn btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Peruuta</button>
              </div>
              <div class="col-6 text-center">
           </div>
            </div>
  
          </div>
          </div>
        </div>
      </div>
    </div>

        <!-- Poista eläin MODAALI -->
        <div class="modal fade" id="delete-pet-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title header3" id="exampleModalLongTitle">Lemmikin poistaminen</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  
                </button>
              </div>
              <div class="modal-body">
                <p class="body2b">Haluatko varmasti poistaa lemmikin?</p>
                <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                  <div class="col-6 text-center">
                    <button class="btn btn-veteva-secondary-delete" (click)="deletePet(infoToPetScreen['_id'])">Poista lemmikki</button>
                  </div>
                  <div class="col-6 text-center">
                    <button id="delete-pet-modal-close" class="btn btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Peruuta</button>
               </div>
                </div>
              </div>
            </div>
          </div>
        </div>

          <!-- Liity videokeskusteluun moodalli-->
          <button id="open-video-modal-btn" [hidden]="true" 
          data-bs-toggle="modal" data-bs-target="#open-video-modal"></button>
          <div class="modal fade" id="open-video-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title header3" id="exampleModalLongTitle">Liity videokeskusteluun</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    
                  </button>
                </div>
                <div class="modal-body">
                  <p class="body2b">Liity videokeskusteluun</p>
<!--                   <div id="remote-media-div">
                  </div> -->
                </div>
              </div>
            </div>
          </div>

      <!-- Poista eläin MODAALI -->
      <div class="modal fade" id="no-resept" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title header3" id="exampleModalLongTitle">Reseptit</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <h3 class="header3 mb-4 text-center">Etäeläinlääkärit eivät suomen lainsäädännöstä johtuen voi määrätä reseptilääkkeitä.</h3>
              <p class="body2b-b">No mitä hyötyä tästä sitten on?</p>
              <p class="body2b">Hoidettavuus etäeläinlääkärin avulla on pääosin erinomaista!
                Olemme hoitaneet &gt;12 000 etäpotilasta, joista 70% kokonaan etänä, ilman klinikkakäyntiä.
                Etäpalveluun hakeutuvien potilaiden vaivoista hoidamme yleisimmät vaivat etänä
                itsehoitolääkkeiden, reseptivapaiden lääkkeiden sekä lemmikille aiemmin määrättyjen lääkkeiden
                avulla.</p>
              <p class="body2b">Etähoito voi osittain korvata klinikkahoitoa, mutta ei koskaan täysin.
                Jos vaiva ei hoidu etänä, vaan klinikkakäynti on kuitenkin tarpeen, on etäkäynnistä aina hyötyä.
                Päivystykseen ohjaus voi pelastaa lemmikin hengen ja etäkäynti täten olla järkevä
                ensihoidonarvio.
                Jos klinikalle tulee hakeutua muutaman viikon sisällä, saa asiakas etäeläinlääkäriltä tärkeää tietoa
                siitä, voiko tilannetta seurata kotioloissa, mikä vaiva voisi olla kyseessä ja millaisiin tutkimuksiin
                heidän tulisi missäkin tilanteessa ja millä aikavälillä hakeutua.</p>
              <p class="body2b">Ihmisten terveydenhuollossa lääkäri saa määrätä potilaalle reseptilääkkeitä etänä, tutkimatta
                potilasta fyysisesti. Etälääkärit voivat määrätä lääkkeitä varsin sairaille tai iäkkäille ja siten
                potentiaalisesti monivaivaisille potilaille, tai ihan pikkuvauvoille.</p>
              <p class="body2b">
                Eläinlääkäri saa määrätä reseptilääkkeitä vain eläimelle, jonka on itse tutkinut fyysisesti viimeisen
                vuoden sisällä, kun kyseessä on sama eläin, sama vaiva ja sama lääke.
                Veteva Oy:n eläinlääkärit neuvottelevat asiasta viranomaisten kanssa ja toivovat muutamia lievennystä
                lainsäädäntöön.</p>
                <p class="body2b">Lisätietoja mm:</p>
                <ul>
                  <li>
                    <p class="body2b">387/2014 Laki eläinten lääkitsemisestä <a  class="veteva-main-link" href="https://www.finlex.fi/fi/laki/alkup/2014/20140387">Lue lisää tästä</a></p>
                  </li>
                  <li>
                    <p class="body2b">Lääkelaki 10.4.1987/395 <a  class="veteva-main-link" href="https://www.finlex.fi/fi/laki/ajantasa/1987/19870395">Lue lisää tästä</a></p>
                  </li>
                  <li>
                    <p class="body2b">Maa- ja Metsätalousministeriön asetus lääkkeiden käytöstä ja luovutuksesta
                      eläinlääkinnässä  <a class="veteva-main-link" href="https://mmm.fi/documents/1410837/1817140/ Laakkeiden_luovutus_.pdf/a7ff23f1-83f0-4a3e-9bf5-51babbfc837a">Lue lisää tästä</a></p>
                  </li>
                </ul>
              <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                <div class="col-6 text-center">
                  <button id="delete-pet-modal-close" class="btn btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        <button id="clinic-closed-btn"  [hidden]="true" 
        data-bs-toggle="modal" data-bs-target="#clinic-closed-modal"></button>
          <div class="modal fade" id="clinic-closed-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div id="loader" class="loader-container" style="display:none">
                    <div class="loader mx-auto"></div>
                    </div>
                  <h5 class="text-center header3 pt-4 pb-4">Valitettavasti klinikkamme on kiinni alla aukioloaikamme:</h5>
                <div id="check-modal-body">

                  <div class="d-flex justify-content-center">
                    <table class= "table-clinic-times">
                    <div class="" *ngFor="let open of clinicOpen">
                      <tr *ngIf="open.idx == 1">
                        <td class="clinic-open-day-modal body2 pt-2">Maanantai</td>
                        <td class="clinic-open-time-modal pt-2 body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 2">
                        <td class="clinic-open-day-modal body2">Tiistai</td>
                        <td class="clinic-open-time-modal body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 3">
                        <td class="clinic-open-day-modal body2">Keskiviikko</td>
                        <td class="clinic-open-time-modal body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 4">
                        <td class="clinic-open-day-modal body2">Torstai</td>
                        <td class="clinic-open-time-modal body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 5">
                        <td class="clinic-open-day-modal body2">Perjantai</td>
                        <td class="clinic-open-time-modal body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 6">
                        <td class="clinic-open-day-modal body2">Lauantai</td>
                        <td class="clinic-open-time-modal body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                      <tr *ngIf="open.idx == 0">
                        <td class="clinic-open-day-modal body2 pb-2">Sunnuntai</td>
                        <td class="clinic-open-time-modal pb-2 body1 text-center">{{open.start}} - {{open.end}}</td>
                      </tr>
                    </div>
                    </table>
                  </div>


                <div class="col-12 text-center pt-4 pb-4">
                    <button class="btn btn-veteva-secondary"  id="check-close-btn" data-bs-dismiss="modal">Sulje</button>
                  </div>
              </div>
            </div>
            </div>
            </div>
          </div>

      <!-- Ohje modaali -->
      <button id="open-instructions-modal" style="display: none;"
      data-bs-toggle="modal" data-bs-target="#instructions-modal"></button>

      <div class="modal fade" id="instructions-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title header3" id="exampleModalLongTitle">Ohje</h5>
              <button type="button" id="instructions-close-btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <div id="start-screen-instructions" class="instruction-show">
                <h3 class="header3 mb-4 text-center">Aloita chat</h3>
                <div class="text-center instruction-image">
                  <img src="../../assets/images/start_screen.png">

                </div>
                <p class="body2 text-center">Avaa seuraava näkymä painamalla "Aloita chat" -painiketta.</p>
                
              </div>
              <div id="payment-screen-instructions" class="instruction-hidden">
                <h3 class="header3 mb-4 text-center">Maksa konsultaatio</h3>
                <div class="text-center instruction-image">
                  <img src="../../assets/images/payment_screen.png">

                </div>
                <p class="body2 text-center">Valitse haluamasi maksutapa yli 10 eri vaihtoehdosta. Tämän jälkeen sinut ohjataan maksunvälittäjän sivulle ja onnistuneen maksun jälkeen pääset esitietokyselyn pariin.</p>
                
              </div>
              <div id="anamnesis-screen-instructions" class="instruction-hidden">
                <h3 class="header3 mb-4 text-center">Syötä esitiedot</h3>
                <div class="text-center instruction-image">
                  <img src="../../assets/images/anamnesis_screen.png">

                </div>
                <p class="body2 text-center">Esitietokyselyn jälkeen eläinlääkäri avaa sinulle chat-näkymän kanssaan. Esitietokyselyn täyttämisessä kestää noin 7min.</p>
                
              </div>
              <div id="chat-screen-instructions" class="instruction-hidden">
                <h3 class="header3 mb-4 text-center">Chattaile eläinlääkärin kanssa.</h3>
                <div class="text-center instruction-image">
                  <img src="../../assets/images/chat_screen.png">

                </div>
                <p class="body2 text-center">Chatin jäleen saat palautekyselyn sekä hoito-ohjeen sähköpostiisi.</p>
                
              </div>
              <div class="col-12 row">
                <div class="col-6 ">
                  <button class="btn btn-veteva-secondary" id="instruction-previous-btn" (click)="instructionsPrevious()">Edellinen</button>
                </div>
                <div class="col-6 col-6 d-flex justify-content-end">
                  <button class="btn btn-veteva-secondary" id="instruction-next-btn" (click)="instructionsNext()">Seuraava</button>

                </div>
              </div>
              
              <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4 mt-4">
                <div class="col-12 text-center">
                  <a class="pointer veteva-main-link" (click)="closeInstructionModal()" routerLink="/{{clinicID}}/question-answer">Onko ongelmia? Lue UKK:sta ratkaisu.</a>
              </div>
              <div class="col-12 text-center mt-4">
                <a class="pointer veteva-main-link" routerLink="{{clinicID}}/home" (click)="refreshPage('e')">Päivitä ikkuna</a>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>



                      <!-- Poista eläin MODAALI -->
                      <button id="multiple-windows-modal" style="display: none;"
                      data-bs-toggle="modal" data-bs-target="#tab-modal"></button>
                      <div class="modal fade" id="tab-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title header3" id="exampleModalLongTitle">Toimia vaaditaan</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                
                              </button>
                            </div>
                            <div class="modal-body">
                              <h3 class="header3 mb-4 text-center">Sulje tämä välilehti</h3>
                              <p class="body2b">Sinulla on tällä hetkellä useita välilehtiä käytössä. Etäeläinlääkärin toiminnan varmistamiseksi
                                ja tietoturvallisuutesi vuoksi pyydämme sinua sulkemaan muut välilehdet. Kun olet sulkenut muut välilehdet paina "Päivitä ikkuna" -painiketta. Näin varmistamme tietoturvallisen etäeläinlääkäri käynnin.
                              </p>
                              <div class="col-12 mt-4 text-center">
                                <button class="btn btn-veteva-secondary" (click)="closeWindow()" id="check-close-btn" data-bs-dismiss="modal">Päivitä ikkuna</button>
                              </div>
                              <div class=" col-12 mt-4 text-center">
                                <button class="btn btn-veteva-secondary-delete" (click)="logOut()" data-bs-dismiss="modal">Kirjaudu ulos</button>
                              </div>
                          </div>
                        </div>
                        </div>
                        </div>
                        <!-- Poista eläin MODAALI -->
                      <button id="conn-err" [hidden]="true" 
                      data-bs-toggle="modal" data-bs-target="#con-err-modal"></button>
                      <div class="modal fade" id="con-err-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title header3" id="exampleModalLongTitle">Toimia vaaditaan</h5>
                              <button type="button" id="close-conn-err-modal" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                
                              </button>
                            </div>
                            <div class="modal-body">
                              <h3 class="header3 mb-4 text-center">Yhteys on katkennut</h3>
                              <p class="body2b">Yhteytesi on katkennut.
                                Eläinlääkäri odottelee sinua chatissa. Paina ”Chat”-painiketta jatkaaksesi keskustelua.
                              </p>
                            <div class="col-12  pb-4">
                              <div class="col-12 text-center">
                                <button class="btn btn-veteva-secondary" (click)="connectDisconnectedConnection()" id="conn-err-connect" data-bs-dismiss="modal">Yhdistä</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                      

          
  </div>

  <!-- Liity videokeskusteluun moodalli-->
  <button id="close-video-modal-btn" [hidden]="true" 
  data-bs-toggle="modal" data-bs-target="#close-video-modal"></button>
  <div class="modal fade" id="close-video-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title header3" id="exampleModalLongTitle">Haluatko varmasti poistua videokeskustelusta?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
          </button>
        </div>
        <div class="modal-body">
          <p class="body2b">Videokeskustelu suljetaan pysyvästi. Voit kuitenkin jatkaa viestittelyä eläinlääkärin kanssa tämän jälkeen.</p>
          
          <div class="text-center">
            <button class="btn btn-veteva-secondary mt-4" (click)="customerDisconnect()">Sulje videokeskustelu</button> <br>
            <button class="btn btn btn-veteva-secondary-edit mt-4" id="close-video-close-modal" data-bs-dismiss="modal">Peruuta</button>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Ladataan kuvaa modaaali-->
  <button id="attachment-loader-btn" style="display: none;"
  data-bs-toggle="modal" data-bs-target="#attachment-loader-modal"></button>
  <div class="modal fade" id="attachment-loader-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title header3" id="exampleModalLongTitle">Ladataan...</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
          </button>
        </div>
        <div class="modal-body">
          <div  class="loader-container">
            <div class="loader mx-auto"></div>
            <p class="text-center">Pieni hetki tiedostoa ladataan...</p>
            </div>

          <button class="btn btn-veteva-secondary-gray mt-4" id="close-sending-modal" data-bs-dismiss="modal">Peruuta</button>
        </div>
      </div>
    </div>
  </div>

<div class="d-flex justify-content-center">
  <div class="toast d-none" role="alert" id="toaster" style="position: absolute; top:20px" aria-live="assertive" aria-atomic="true" data-delay="2000">
    <div class="toast-header">
      <img src="../../assets/images/chat2Vet_some.png" width="20" class="rounded me-2" alt="...">
      <strong class="me-auto">Musti ja Mirri Etäeläinlääkäri</strong>
      <button type="button" class="ms-2 mb-1 close" data-bs-dismiss="toast" aria-label="Close">
        
      </button>
    </div>
    <div class="toast-body">
      <p class="body2bb" id="toast-text"></p>
    </div>
  </div>
</div>