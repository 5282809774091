import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit,AfterViewInit {
currentUserId='';
  patientID: string = this.route.snapshot.params.id;
  users: any[];
  pets: any[];
  visits: any[];
  date="";
  diagnosis="";
  petsName="";
  userFirstName = "";
  userLastName=""
  userPhone = "";
  userEmail = "";
  credit = 0;
  authToken = localStorage.getItem("auth");
  mail: FormGroup;
  error = false;
  clinicID = this.authService.getTokenData().clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

    ngOnInit() {
      this.myId();
      this.mails();

    }

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }

    mails(){
      this.mail = this.fb.group({
        mail: ['', Validators.required],
      });
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      console.log('iidee',decodedToken.result.id);
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
        this.logOutt();
  
      }
      this.fetchUserData();
      this.fetchPetData();
      this.fetchRegisterDataForUser();
    }
  
    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('vastaus',res);
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }
  
  
    fetchUserData(){
  
      var req = {
        patientID:this.patientID
      }
  
      this.http.post(environment.DOC_PMS_USER_DATA_END_POINT, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200) {
            this.users = res.message[0];

              this.userFirstName = res.message[0][0].firstName;
              this.userLastName = res.message[0][0].lastName;
              this.userPhone = res.message[0][0].phone;
              this.userEmail = res.message[0][0].email;
              this.credit = res.message[0][0].consultationCredit;

              if(this.credit == null || this.credit == undefined){
                this.credit = 0;
              }

              var creditStr = this.credit.toString();

              (<HTMLInputElement>document.getElementById('credit-field')).value = creditStr;
          }
          else {
            this.users = [];
          }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }
    editCredit(){

      var credit = (<HTMLInputElement>document.getElementById('credit-field')).value

      var creditInt = parseInt(credit);

      if(creditInt % 1 != 0){
        alert('Syöttämäsi luku ei ole kokonaisluku');
        return;
      }

      var req = {
        userID:this.patientID,
        credit: creditInt
      }
  
      this.http.post(environment.DOC_EDIT_USER_CREDIT, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200) {
            this.fetchUserData();
            document.getElementById('close-edit-credit').click();
          }
          else {
            alert('Päivitys epäonnistui');
          }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })

    }

    fetchPetData(){
  
      var req = {
        patientID:this.patientID
      }
  
      this.http.post(environment.DOC_PMS_PET_DATA_END_POINT, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200) {
            this.pets = res.message;
            
       }
       else {
         this.pets = [];
       }
        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }
    
    fetchRegisterDataForUser(){
      var req = {
        patientID:this.patientID
      }

    this.http.post(environment.FETCH_VISIT_FOR_USER_END_POINT,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if(res.status == 200 ) {
          this.visits = res.message;
          //PÄIVÄJÄRJESTYKSEEN 
          this.visits = this.visits.sort(function(a,b){
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });
          this.colorService.waitStyleColor();
        }
        else {
          this.visits = [];
          this.colorService.waitStyleColor();
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }
  routeToVisit(e){
    var visitID = e.target.id;

    this.router.navigate(['/vet/visit-detail/'+visitID])
  }



  get emailText() { return this.mail.get('mail') }
  sendEmailToPatient(){

    if(this.mail.get('mail').value == "") {
      alert('Kenttiä tyhjänä! Täytä pyydetyt kentät');
      return;
    }

    const request = {
      userName: this.userEmail,
      emailText: this.mail.get('mail').value,
    }
    document.getElementById('loader').style.display = "block";
    document.getElementById('email-send-modal').style.display = "none";
    this.http.post(environment.DOC_SEND_EMAIL_TO_USER, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {

          var elm = document.getElementById('close-modal-email');
          elm.click();

          document.getElementById('loader').style.display = "none";
          document.getElementById('email-send-modal').style.display = "block";

          this.mail.get('mail').setValue("");
          this.mail.get('header').setValue("");
          
          

        } else {
          document.getElementById('loader').style.display = "none";
          document.getElementById('email-send-modal').style.display = "block";

          this.error = true;

        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  

  }
  

}
