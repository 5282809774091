
<app-admin-header></app-admin-header>



<div class="wrapperi pb-4 mb-4">
<div class="container pb-4">
	<div class="wrap pb-4">
	<div class="d-flex flex-row justify-content-center">
		<div class="col-12  flex-wrap mt-4">
      <div class="col-12 row p-0 m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 pl-2 pl-md-3">
          <h4 class="header3 mt-4">Rekisteröi klinikka</h4>
          <p class="body2b mt-4 mb-4">Täytä alla oleva lomake. </p>
          <div *ngIf="passwordErr != ''">
            <div class="alert alert-warning">
              <p>{{passwordErr}}</p>
            </div>
          </div>
          <div *ngIf="messageErr">
            <div class="alert alert-warning">
              <p>{{messageErr}}</p>
            </div>
          </div>
        </div>
    <div class="col-3"></div>
    </div>
		</div>
  </div>
  <div style="display: none;" id="activation-alert" class="alert alert-success text-center">
    <p>Hienoa! Rekisteröityminen onnistui! Sait sähköpostiisi aktivointilinkin, jonka avulla pystyt aktivoimaan käyttäjätilisi.</p>
    <button class="btn btn-secondary" routerLink="/login">Kirjaudu sisään</button>
  </div>

  <div id="loader" class="loader-container" style="display:none">
    <div class="loader mx-auto"></div>
  </div>
  <div class="col-12 row p-0 m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0">
  <div style="display: block;" id="register-form" class="d-flex flex-row justify-content-center pl-2">
		<form class="flex-row add-pet-form mt-4" [formGroup]="form"
    (ngSubmit)="checkMobileNumeber()">
    <!-- Klinikan nimi -->
    <h1>Klinikan tiedot</h1>
    <div class="form-group">
        <div class=" col-12">
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="text" placeholder="Klinikan nimi" formControlName="clinicName" id="clinicName" class="form-control" [class.invalid-input]="clinicName.errors && clinicName.touched" required>
            <div class="my-alerts" *ngIf="clinicName.invalid && clinicName.touched">
                <p class="mt-2" *ngIf="clinicName.errors.required">clinicName on pakollinen</p>
                <p *ngIf="clinicName.errors.clinicName"> Vaaditaan kunnollinen clinicName</p>
            </div>
            </div>
        </div>
    </div>
    <!-- Klinikan ID -->
    <div class="form-group">
        <div class=" col-12">
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="number" placeholder="Klinikan ID" formControlName="clinicID" id="clinicID" class="form-control" [class.invalid-input]="clinicID.errors && clinicID.touched" required>
            <div class="my-alerts" *ngIf="clinicID.invalid && clinicID.touched">
                <p class="mt-2" *ngIf="clinicID.errors.required">clinicID on pakollinen</p>
                <p *ngIf="clinicID.errors.clinicID"> Vaaditaan kunnollinen clinicID</p>
            </div>
            </div>
        </div>
    </div>
    <!-- KKlinikan logo -->
      <div class="form-group">
          <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input (change)="gotFile($event)" type="file" accept="image/png,image/jpeg, image/jpg" placeholder="Klinikan logon url" id="logoUrl" class="form-control" required>
              </div>
          </div>
      </div>
        <!-- Klinikan pääväri -->
          <div class="form-group">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                <input  type="color" placeholder="Klinikan logon url" formControlName="mainColor" id="mainColor" class="form-control" [class.invalid-input]="mainColor.errors && mainColor.touched" required>
                <div class="my-alerts" *ngIf="mainColor.invalid && mainColor.touched">
                    <p class="mt-2" *ngIf="mainColor.errors.required">mainColor on pakollinen</p>
                    <p *ngIf="mainColor.errors.mainColor"> Vaaditaan kunnollinen mainColor</p>
                </div>
                </div>
            </div>
        </div>
      <!-- Klinikan toissijainen väri -->
      <div class="form-group">
          <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input  type="color" placeholder="Klinikan logon url" formControlName="secondaryColor" id="secondaryColor" class="form-control" [class.invalid-input]="secondaryColor.errors && secondaryColor.touched" required>
              <div class="my-alerts" *ngIf="secondaryColor.invalid && secondaryColor.touched">
                  <p class="mt-2" *ngIf="secondaryColor.errors.required">secondaryColor on pakollinen</p>
                  <p *ngIf="secondaryColor.errors.secondaryColor"> Vaaditaan kunnollinen mainColor</p>
              </div>
              </div>
          </div>
      </div>
      <!-- Sähköpostiosoite -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
            <div class="my-alerts" *ngIf="email.invalid && email.touched">
              <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
              <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Puhelinnumero -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input  type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
            <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
              <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
              <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
            </div>
          </div>
        </div>
      </div>
        <!-- OVT -->
        <div class="form-group">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 p-0">
                <input  type="text" placeholder="OVT-tunnus" formControlName="ovt" id="ovt" class="form-control" [class.invalid-input]="ovt.errors && ovt.touched" required>
                <div class="my-alerts" *ngIf="ovt.invalid && ovt.touched">
                    <p class="mt-2" *ngIf="ovt.errors.required">OVT on pakollinen</p>
                    <p *ngIf="ovt.errors.ovt"> Vaaditaan määrätty OVT</p>
                </div>
                </div>
            </div>
        </div>
        <!-- Operaattori -->
        <div class="form-group">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 p-0">
                <input  type="text" placeholder="Operaattori" formControlName="operator" id="operator" class="form-control" [class.invalid-input]="operator.errors && operator.touched" required>
                <div class="my-alerts" *ngIf="operator.invalid && operator.touched">
                    <p class="mt-2" *ngIf="operator.errors.required">operator on pakollinen</p>
                    <p *ngIf="operator.errors.operator"> Vaaditaan määrätty operator</p>
                </div>
                </div>
            </div>
        </div>
        <h1>Ensimmäisen eläinlääkärin tiedot</h1>
      <!-- Etunimi -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input  type="text" placeholder="Etunimi" formControlName="firstName" id="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
            <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
              <p class="mt-2" *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
              <p *ngIf="firstName.errors.firstName"> Vaaditaan määrätty etunimi</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Sukunimi -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input  type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
            <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
              <p class="mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
              <p *ngIf="lastName.errors.lastName"> Vaaditaan määrätty sukunimi</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Sähköpostiosoite -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="email" placeholder="Eläinlääkärin sähköpostiosoite" formControlName="docEmail" id="docEmail" class="form-control" [class.invalid-input]="docEmail.errors && docEmail.touched" required>
            <div class="my-alerts" *ngIf="docEmail.invalid && docEmail.touched">
              <p class="mt-2" *ngIf="docEmail.errors.required">Email on pakollinen</p>
              <p *ngIf="docEmail.errors.docEmail"> Vaaditaan kunnollinen docEmail</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Puhelinnumero -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input  type="tel" placeholder="Puhelinnumero" formControlName="docPhone" id="docPhone" class="form-control" [class.invalid-input]="docPhone.errors && docPhone.touched" required>
            <div class="my-alerts" *ngIf="docPhone.invalid && docPhone.touched">
              <p class="mt-2" *ngIf="docPhone.errors.required">Puhelinnumero on pakollinen</p>
              <p *ngIf="docPhone.errors.docPhone"> Vaaditaan määrätty puhelinnumero</p>
            </div>
          </div>
        </div>
      </div>
        <!-- Titteli -->
        <div class="form-group">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 p-0">
                <input  type="text" placeholder="Titteli" formControlName="designation" id="designation" class="form-control" [class.invalid-input]="designation.errors && designation.touched" required>
                <div class="my-alerts" *ngIf="designation.invalid && designation.touched">
                    <p class="mt-2" *ngIf="designation.errors.required">Titteli on pakollinen</p>
                    <p *ngIf="designation.errors.designation"> Vaaditaan määrätty Titteli</p>
                </div>
                </div>
            </div>
        </div>



      
            
            <button [disabled]="form.invalid" class=" login-btn btn-veteva-secondary">Rekisteröi klinikka</button>

		  </form>
  </div>
  <div class="col-3"></div>
    </div>

    </div>
  </div>