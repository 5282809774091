import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  messageErr: string;
  messageSuccess: string;
  passwordErr:string="";
  chaptaQuestion = "";
  chaptaImage = "";
  chaptaID ="";
  shortPwd
  emailTrue = false;
  phoneTrue = false;
  lastNameTrue = false;
  firstNameTrue = false;
  passwordTrue = false;
  password2True = false;
  termsTrue = false;
  clinicID: string = this.route.snapshot.params.clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID)
    }

  ngOnInit() {
    this.setColors();
    this.form = this.fb.group({
      email: ['', [
        Validators.email, 
        Validators.required,
        Validators.maxLength(40)
      ]],
      phone: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],

      firstName: ['',
      Validators.required],
      lastName: ['', 
      Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40),
      ]],
      password2: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40),
      ]],
      privacy: ['', Validators.required],
    });
  }

  get email() { return this.form.get('email') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get phone() { return this.form.get('phone') };
  get password() { return this.form.get('password') };
  get password2() { return this.form.get('password2') };
/*   get chapta() { return this.form.get('chapta') }; */
  get privacy() { return this.form.get('privacy') };

/*   imageCheck(){
    this.http.get(environment.FETCH_RANDOM_IMAGE, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        console.log(res)
        this.chaptaQuestion = res[0].question;
        this.chaptaImage = res[0].image;
        this.chaptaID = res[0]._id
      
    }, (err: any) => {
      console.log('JL_Virhe decrease ', err);
    })
  } */

/*   confirmAnswer(){
    var chapta= this.form.get('chapta').value;
    var req = {
      objID: this.chaptaID,
      answer: chapta
    }
    this.http.post(environment.CONFIRM_CHAPTA_ANSWER,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if(res.status == 200){
          this.onSubmit()
        }
        else{
          alert('Laskit kuvan esineet väärin, sinut ohjataan etusivulle');
        }
    }, (err: any) => {
      console.log('JL_Virhe decrease ', err);
    })
  } */

  setColors(){
    var ls = localStorage.getItem('colors');
    if(ls){
      this.colorService.styleUser();
    }
    else{
    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
    }
  }
 
  emailValidate(){
  
      var emailValue = (<HTMLInputElement>document.getElementById('email')).value;
      var email = document.getElementById('email');
      if(emailValue.length > 4 && emailValue.includes("@")){
        email.classList.add('form-control-success');
        this.emailTrue = true;
        this.validateForm();
      }
      else{
        email.classList.remove('form-control-success');
        this.emailTrue = false;
        this.validateForm();
      }
  }

  passwordValidate(){
  
    var passwordValue = (<HTMLInputElement>document.getElementById('password')).value;
    var password = document.getElementById('password');
    if(passwordValue.length > 7){
      password.classList.add('form-control-success');
      this.passwordTrue = true;
      this.validateForm();
    }
    else{
      password.classList.remove('form-control-success');
      this.passwordTrue = false;
      this.validateForm();
    }
  }

  password2Validate(){
  
    var password2Value = (<HTMLInputElement>document.getElementById('password2')).value;
    var password2 = document.getElementById('password2');
    if(password2Value.length > 7){
      password2.classList.add('form-control-success');
      this.password2True = true;
      this.validateForm();
    }
    else{
      password2.classList.remove('form-control-success');
      this.password2True = false;
      this.validateForm();
    }
  }

  phoneValidate(){
  
    var phoneValue = (<HTMLInputElement>document.getElementById('phone')).value;
    var phone = document.getElementById('phone');
    if(phoneValue.length > 6){
      phone.classList.add('form-control-success');
      this.phoneTrue = true;
      this.validateForm();
    }
    else{
      phone.classList.remove('form-control-success');
      this.phoneTrue = false;
      this.validateForm();
    }
  }

  firstNameValidate(){
  
    var firstNameValue = (<HTMLInputElement>document.getElementById('firstName')).value;
    var firstName = document.getElementById('firstName');
    if(firstNameValue.length > 1){
      firstName.classList.add('form-control-success');
      this.firstNameTrue = true;
      this.validateForm();
    }
    else{
      firstName.classList.remove('form-control-success');
      this.firstNameTrue = false;
      this.validateForm();
    }
  }

  lastNameValidate(){
  
    var lastNameValue = (<HTMLInputElement>document.getElementById('lastName')).value;
    var lastName = document.getElementById('lastName');
    if(lastNameValue.length > 2){
      lastName.classList.add('form-control-success');
      this.lastNameTrue = true;
      this.validateForm();
    }
    else{
      lastName.classList.remove('form-control-success');
      this.lastNameTrue = false;
      this.validateForm();
    }
  }


  validateForm(){
    if(this.passwordTrue && this.emailTrue){
/*       var password = document.getElementById('password');
      var but = document.getElementById('recaptcha');
      var userName = document.getElementById('userName');
      password.classList.replace('form-control-success','form-control-ready');
      userName.classList.replace('form-control-success','form-control-ready');
      but.classList.add('login-btn-ready'); */

    }
  }
  onSubmit() {
/*     var password = this.form.get('password').value;
    var password2 =  this.form.get('password2').value; */



    var email = this.form.get('email').value;
    var userName = this.form.get('email').value;
    var firstName = this.form.get('firstName').value;
    var lastName = this.form.get('lastName').value;
    var phone = this.form.get('phone').value;
    var password = this.form.get('password').value;
    var password2= this.form.get('password2').value;

    var safeArr = [];
    safeArr.push(email, firstName, lastName, phone, password, password2);

    console.log(safeArr,'wppp');

    for (let i = 0; i < safeArr.length; i++) {
      const str = safeArr[i];
      if(str.includes(' ') || str.includes('<')){
        console.log('form is not valid')
        console.log(str, 'sisälsi')
        return;
        
      }
      
    }

    console.log('Submitting');
    if (!this.form.valid) {
      return;
    }
    if(this.password.value != this.password2.value){
      window.scrollTo(0,0);
      this.passwordErr = "Salasanat eivät täsmää"
      return;
    }

    var str = this.password.value;
    if(str.length < 8) {
      window.scrollTo(0,0);
      this.passwordErr = "Salasana on liian lyhyt"
    } 

    console.log('Form valid');
    var formAc = document.getElementById('register-form');
    formAc.classList.add("reg-form-success");
    document.getElementById('loader').style.display="block";

    this.passwordErr="";
    this.messageErr="";

    const request = {
      email: this.form.get('email').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      phone: this.form.get('phone').value,
      password: this.form.get('password').value,
      clinicID: this.clinicID,
    }

       this.http.post(environment.REGISTER_END_POINT, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == "success") {
            this.messageSuccess = res.messgage;
            document.getElementById('loader').style.display="none";
            document.getElementById('register-form').style.display="block";

            var actAlert = document.getElementById('activation-alert');
            actAlert.style.display = "block";
            //window.location.replace("/home");
          } else {
            this.messageErr = res.message;
            document.getElementById('loader').style.display="none";
            formAc.classList.remove("reg-form-success");

            //loginError = true;
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    } 

    listenPWD(){
      var pwd = this.form.get('password').value;
      if(pwd.length < 8){
        this.shortPwd = true;
      }
      else {
        this.shortPwd = false;
      }
    }


}