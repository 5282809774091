
<div class="container-fluid chat-window-container h-100">
  <app-docheader></app-docheader>
  <div class="row m-0 row-container">
    <!--Section one :: started-->
    <div class="col-md-8 col-lg-4 section-one color-white korkeus p-0 section sidebar-sec-one sidenav" id="mySidenav">

        <div class="bg-white">
          <div class="inline-flex bg-white">
            <i class="fa fa-times fa-icon close-fa burger-left closeNav" (click)="closeNav()" aria-hidden="true"></i>
  
          </div>

          </div>

          <p class="header3 text-center mt-4">Minun potilaani</p>
          <p class="text-center body2">Valitse potilas</p>
          <div class="content-row profile-container">
          <div class="col-md-10 mx-auto" *ngIf="noDocs == true">
          </div>
          

        <div class="profile-lists p-4 ms-4 me-4" >
          <div class="alert alert-warning" *ngIf="!this.selectedVisitor['name']">
            <p class="text-center">Valitse potilas nähdäksesi viestit</p>
          </div>

          <select (change)="fetchUserVisitDetails()" id="user-select" class="form-control">
            <option></option>
            <option *ngFor="let visitor of visitors" value="{{visitor.owner}}">{{visitor.firstName}} {{visitor.lastName}}</option>
          </select>
          <select *ngIf="this.userVisits.length > 0" (change)="fetchUserMessagesByDate()" id="user-date-select" class="form-control mt-3">
            <option *ngIf="this.userVisits.length > 1"></option>
            <option *ngFor="let visit of userVisits" title="Käyntipäivä: {{visit.createdDate | pformateDate}}; Lemmikin nimi: {{visit.petName}}; Lemmikin rotu: {{visit.petBreed}}" value="{{visit._id}}">"{{visit.petName}}" {{visit.petBreed}} ({{visit.createdDate | pformateDate}})</option>
          </select>
          <div *ngIf="this.selectedVisit" class="">
            <p class="header3 text-center mt-4">Tiedot</p>
            <p *ngIf="this.selectedVisit['doctorFullName'] != undefined" class="body2b m-0">Eläinlääkäri: <span class="body2">{{this.selectedVisit['doctorFullName']}}</span></p>
            <p *ngIf="this.selectedVisit['anamnesis']" class="body2b m-0">Käyntisyy: <span class="body2">{{this.selectedVisit['anamnesis'].reason}}</span></p>
            <p *ngIf="this.selectedVisit['diagnosisFinal'] != ''" class="body2b m-0">Lopullinen diagnoosi: <span class="body2">{{this.selectedVisit['diagnosisFinal']}}</span></p>
            <p *ngIf="this.selectedVisit['createdDate'] != ''" class="body2b m-0">Käyntipäivämäärä: <span class="body2">{{this.selectedVisit['createdDate'] | pformateDate}}</span></p>

          </div>
        </div> 
      </div> 
    </div>
    <!--Section one :: END-->

    <!--Section two :: started-->
    <div class="col-md-12 col-lg-8 section-two color-white korkeus h-100 p-0 section main-container">
  
      <!-- header container : start-->
      <div class="header-container">
        <div class="row col-12 m-0">
          <i class="fa fa-bars fa-icon burger burger-left sidebar-burger" (click)="openNav($event)" aria-hidden="true"></i>
          <div class="col-1 col-md-0 me-md-4 p-0 mt-2 d-none d-md-block">
            <div class="avatar-icon-chat mt-1 sideBar-avatar">
                <div class="img-container">
                  <img src="./../../assets/images/chat2Vet_some.png">
                </div>
            </div>
          </div>
          <div class="col-6 col-md-10 p-0">
            <div class="container-for-dr-name d-flex flex-column mt-2">
              <p class="body1-w mb-0">{{this.selectedVisitor['name']}}</p>
              <p class="body3-w">{{this.selectedVisitor['petName']}} {{this.selectedVisitor['petBreed']}}</p>
            </div>
            <ng-template class="p-2" #assistant_text_template>
              <p class="body1-w mb-0 mt-2">
                <span class="text-small-typing" *ngIf="globalMessages[currentActiveDoctorName]?.typing">kirjoittaa...</span>
              </p>
              <p class="body3-w">
                <span *ngIf="currentActiveDoctorName == DR_ASSISTANT_NAME">Kyselen esitiedot</span>
                </p>
            </ng-template>
          </div>

          
        </div>
      </div>
      <!-- header container : end-->


      <div class=" chat-window-main-container w-100 col-md-12" [ngClass]="{'chat-window-main-container-no-replybox': !showReplyBox}">


        <div class="chat-messages-container pb-4 m-0" id="main-chat-container" #chatMessageContainer (scroll)="onScroll()">



          <div class="row m-0 message-container" *ngFor="let message of currentActiveMessageList">
  
            <!-- ALOITUS LÄÄKÄRIT -->


            <!-- ALOITUS AUKIOLO -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_101' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body2b mb-1">Eläinlääkärimme palvelemassa sinua</p>
                  <div class="text-message text-message-left whitespace-preline">
                    <ul *ngIf="activeDoctors != []" class="list-group" id="menu">
                      <div class="message-button-group" *ngFor="let activeDoctor of activeDoctors">

                            <li class="list-group-item list-group-item-link pt-3">
                              <div class="list-wrap-closed d-inline-flex ">
                                <div class="img-cont" *ngIf="activeDoctor.image != null;else standard_image">
                                  <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                </div>
                                <ng-template #standard_image>
                                  <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                </ng-template>
                                <p class="body2b-b"> {{ activeDoctor.name }}</p>
                                <p class="body2"> {{ activeDoctor.designation }}</p>
                              </div>
                          </li>
                        </div>
                      </ul>
                    
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>

                <div class="col-sm-1 col-2 p-0">
                  <div class="avatar-icon-sm">
                    <img src="./../../assets/images/docter_color.png">
                  </div>
                </div>
              </div>
            </div>


            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_1' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b">Viestisi ovat luottamuksellisia</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>


            <!-- TEMPLATE 2 ANSWER OSTOKSEN YHTEENVETO -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_2' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-6 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-end">
                  <div class="text-message text-message-right me-2 pe-2">
                    <p class="body2-w">Ostokseni:</p>
                    <ul class="list-group p-list">
                    <li class="list-group-item  package-list-item list-group-item-link pt-3 col-12 ps-0 pe-4 ">
                      <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                        <div class="col-9 pe-3 ps-3">
                          <p class="ms-2 body214bb text-start">{{message.answer['name']}}</p>
                        </div>
                        <div class="col-2 ">
                          <div class="col-12 pe-0 ps-0">
                            <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 ">
                              <div class="col-12 p-0">
                              <p class="body214bb text-end">{{message.answer['price']}}€</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                  
                    
                       </li>
                    </ul>
                    </div>
                  </div>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>

              </div>
            </div>

<!--                 <div class="col-md-12 " (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_3' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="p-0">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-5 p-0 m-2">
                  <p class = "doctor-name-text ms-2 pb-2"style="color: black;">{{this.currentActiveDoctorName}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body3"> {{message.actMessage | newlineHtml}}</p>
  
                  </div>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>
 -->

 

            <!-- TEMPLATE 4 ELÄIMEN VALITSEMINEN -->
            <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_21' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Millä lemmikillä haluat etäeläinlääkärille?</p>
                     <ul class="list-group" id="menu">
                      <div class="p-list">
  

                            <button [disabled]="message.oldMessage" 
                             class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2 mb-2">
                              <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                <div class="col-12 pe-1 ps-1 row m-0">
                                  <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                    <div class="img-cont" *ngIf="selectedVisit['image'];else standard_image">
                                      <img class="small-pic-doc mt-1" src="{{selectedVisit['image']}}" alt="eläinlääkäri">
                                    </div>
                                    <ng-template #standard_image>
                                      <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                    </ng-template>
                                  </div>
                                  <div class="col-9 ps-0 ps-md-2 text-start">
                                    <div class="d-flex flex-column">
                                      <div class="doc-name">
                                        <p class="ms-2 doc-show-text body214bb"> {{ selectedVisit['petName'] }}</p>
                                      </div>
                                      <div class="doc-designation">
                                        <p class="ms-2 doc-show-text body2"> {{ selectedVisit['petBreed'] }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
    
                              </div>
                            
                            
                          </button>
                        </div>
                      </ul> 

                      
                      <ng-template #no_pets>
                        <div class="alert alert-danger">
                          <p>Sinun tulee lisätä lemmikki ennen kuin voit mennä eteenpäin</p>
                        </div>
                      </ng-template>
  
                  </div>
                  <p class="text-message-time-left mt-2 m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 4 ANSWER PET CHOISE -->
            <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_21' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="text-message text-message-right me-md-2">
                    <p class="body2-w">Valitsemani lemmikki:</p>
                        <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                          <div class="list-wrap-closed d-inline-flex ">
                            <div class="img-cont" *ngIf="selectedVisit['image'] != null;else standard_image">
                              <img class="small-pic-doc mt-1" src="{{selectedVisit['image']}}" alt="lemmikkisi">
                            </div>
                            <ng-template #standard_image>
                              <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                            </ng-template>
                            <div class="d-flex flex-column">
                              <div class="doc-name">
                                <p class="ms-2 doc-show-text body214bb"> {{ selectedVisit['petName'] }}</p>
                              </div>
                              <div class="doc-designation">
                                <p class="ms-2 doc-show-text body2"> {{ selectedVisit['petBreed'] }}</p>
                              </div>
                            </div>
                          </div>
                      </li>

                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
              </div>
            </div>


            <!-- TEMPLATE 5 WEIGHT -->
            <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_5' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body1">Syötä lemmikkisi paino / kg:</p>
                        <div class="list-wrap-closed weight-row p-0 ps-md-2 pe-md-2">
                            <div class="doc-name col-12 ps-0 ps-md-2 pe-md-2">
                              <input type="number" min="0" inputmode="decimal" pattern="[0-9]*" class="form-control weight-input pt-2 pb-2 w-100" placeholder="Kirjoita paino tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"><br>
                            </div>
                            <div class="col-4 p-0 ps-md-2 pe-md-2">
                              <button class="btn btn-secondary " [disabled]="message.oldMessage" >Valmis</button>
                            </div>
                        </div>   
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 5 ANSWER WEIGHT -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_5' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}} kg</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>

           
            <!-- TEMPLATE 6 REASON FOR VISIT -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_6' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Valitse listalta vaivaa parhaiten kuvaava vaihtoehto</p>
                    
<!--                         <input list="reasons-arr-data" required class="message-select p-2""> -->
                        <select  id="reasons-arr-data" class="p-2 mb-2 me-4 w-100"[(ngModel)]="message.answer" [disabled]="message.oldMessage">
                        <option disabled="true"></option> 
                      </select> 

                    
                      <button class="btn btn-secondary mt-3" [disabled]="message.oldMessage" >Valitse</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 6 ANSWER REASON FOR VISIT -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_6' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- TEMPLATE 7 BEFORE? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_7' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Onko vaivaa ollut aikaisemmin?</p>
                    
                    <div class="d-flex justify-content-between">
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" 
                    [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                    <button class="btn btn-secondary" [disabled]="message.oldMessage" 
                      [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                    </div>
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 7 ANSWER FOR BEFORE? -->
            <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_7' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>

            <!-- TEMPLATE 8 WHEN STARTED -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_8'" [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="col-0 p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Koska vaiva alkoi?</p>
                    

                      <input type="date" min='1899-01-01' placeholder="dd-mm-yyyy" max='2000-13-13' id="birthdate" class="form-control eeli"  [(ngModel)]="message.answer" [readonly]="message.oldMessage" required><br>
                    
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" >Valitse</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE ANSWER 8 WHEN STARTED? -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_8' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer | pformateDate}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>


            <!-- TEMPLATE 9 TELL MORE -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_9' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">
                    <p class="body2">Kerro lisää vaivasta</p>
                    
                      <textarea type="text" class="form-control more-symptom-txt whitespace-preline" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                      <button class="btn btn-secondary" [disabled]="message.oldMessage" >Valmis</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE ANSWER 9 TELL MORE -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_9' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w break-word">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>

              </div>
            </div>

             <!-- TEMPLATE 10 CRONICAL ILLS -->
             <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_10' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Onko lemmikilläsi kroonisia sairauksia?</p>
                    
                    <div class="d-flex justify-content-between">
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" 
                    [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                    <button class="btn btn-secondary" [disabled]="message.oldMessage" 
                      [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                    </div>
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p> 
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 10 ANSWER CHRONICAL ILLS  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_10' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9">
                  <div class="d-flex justify-content-end">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- TEMPLATE 11 Lääkitys -->
            <div class="col-md-12 pb-2 no-padding"  *ngIf=" message.template == 'TEMPLATE_11' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
   
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
  
                    <p class="body2">Onko lemmikilläsi joku lääkitys?</p>                   
                    <textarea type="text" class="form-control more-symptom-txt" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" 
                    [ngClass]="{selectedButton: message.answer == 'Ei ole lääkitystä' }">Ei ole lääkitystä</button><br><br>
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" >Lähetä</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>  
                  </div>
              </div>
            </div>
            
  
            <!-- TEMPLATE 11 ANSWER CHRONICAL ILLS  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_11' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9">
                  <div class="d-flex justify-content-end">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <!-- TEMPLATE 12 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_12' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
                    <p class="body3">{{message.actMessage | newlineHtml}}</p>
                      <br />
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
  
                  </div>
              </div>
            </div>


            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_13' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                        <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                      </div>
                      <ng-template #teksti_viesti>
                        <p class="body2-w mb-1 break-word">{{message.actMessage | newlineHtml}}</p>
                      </ng-template>
                      <p class="text-message-time text-end">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>
            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_33' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">

                      
                      <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>

            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_43' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <video class="kuvaviesti" controls alt="">
                        <source src="{{fileURL}}/{{message.actMessage.key}}">  
                      </video>

                      
                      <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_14' ">
              <div class="col-md-12 p-0">
              <div class="row m-0 ">
                <div class="p-0 mt-4">
                  <div class="avatar-icon-chat">
                    <img src="../../assets/images/wellness_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri </p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                      <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                  </div>
                  <ng-template #teksti_viesti>
                    <div [innerHtml]="message.actMessage" class="body2 break-word"></div>
                  </ng-template>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
            <div class="col-12 p-0 m-0 row ps-4">

              <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

            </div>
            </div>
            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_34' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <div>
                      
                    </div>
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div >
                      <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                  </div>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_44' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div >
                      <video class="kuvaviesti" controls alt="">
                        <source src="{{fileURL}}/{{message.actMessage.key}}">  
                      </video>
                  </div>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_54' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div class="d-flex justify-content-center">
                      <i *ngIf="message.actMessage.mimetype == 'application/pdf'" class="fas fa-file-pdf att-icon pdf-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word att-icon word-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel att-icon xls-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'text/plain'" class="fas fa-file-alt att-icon icon-txt"></i>
                  </div>
                  <br>
                  <p class="body3-b">{{message.actMessage.originalname}}</p>
                  <div class="text-center">
                    <button class="btn btn-veteva-secondary" (click)="this.fileUploaderService.downloadAttachment(message.actMessage)"><i class="fas fa-download me-2"></i>Lataa</button>

                  </div>
                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                

              </div>
            </div>

<!--               <div class="col-12 no-padding" *ngIf="globalMessages[currentActiveDoctorName]?.typing">
              <div class="col-md-12 p-0">
              <div class="row m-0 ">
                <div class="p-0 mt-4">
                  <div class="avatar-icon-chat">
                    <img src="{{this.activeConsultantData['wantedImage']}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="text-message text-message-left-doctor whitespace-preline">

                    <div class="body1 break-word">Kirjottaa..</div>
                  </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
            <div class="col-12 p-0 m-0 row ps-4">

              <p class="text-message-time-left mt-2 ms-4">{{message.createdDate | chatTimeIndicator}}</p>

            </div>
            </div> -->

              


            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_15' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b-b">Eläinlääkäri lopetti keskustelun</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>

            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_16' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b-b">Eläinlääkäri siirsi sinut takaisin jonoon</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>



            <!-- TEMPLATE 20 Credit usage? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_20' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">{{message.actMessage}}</p>
                    
                    
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" 
                    [ngClass]="{selectedButton: message.answer == true }">Käytetään maksettua käyntiä</button>

                    <button class="btn btn-secondary mt-3" [disabled]="message.oldMessage" 
                      [ngClass]="{selectedButton: message.answer == false }">Haluan ostaa uuden käynnin</button>
  
                  </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

          <!-- TEMPLATE 20 ANSWER Which credit to use  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_20' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9">
                <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <div class="col p-0">
                  <div *ngIf="message.answer == true;else not_true">
                    <p class="body2-w">Käytetään maksettua käyntiä</p>
                  </div>
                  <ng-template #not_true>
                    <p class="body2-w">Haluan ostaa uuden käynnin</p>
                  </ng-template>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
                </div>
                </div>
              </div>

            </div>
          </div>

         <!-- TEMPLATE 23 Lemmikki kastroitu -->
         <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_23' " [ngClass]="{'disabled-input': message.oldMessage}">
          <div class="row m-0">
<!--                 <div class="p-0">
              <div class="avatar-icon-chat mt-4">
                <img src="./../../assets/images/chat2Vet_some.png">
                
              </div>
            </div> -->

            <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
              <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
              <div class="text-message text-message-left whitespace-preline">

                <p class="body2">Onko lemmikki kastroitu tai steriloitu?</p>
                
                <div class="d-flex justify-content-between">
                <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" 
                [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                <button class="btn btn-secondary mt-0" [disabled]="message.oldMessage" 
                  [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                </div>

              </div>
              <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
          </div>
        </div>

      <!-- TEMPLATE 21 ANSWER agreement  -->
      <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_23' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
        <div class="row m-0">
          <div class="col-md-7 col-3">
            <p class="d-none">To take some space</p>
          </div>
          <div class="col-md-5 col-9 p-0">
            <div class="d-flex justify-content-end p-0">
            <div class="text-message text-message-right me-2">
              <div *ngIf="message.answer == true;else not_true">
                <p class="body2-w">Kyllä</p>
              </div>
              <ng-template #not_true>
                <p class="body2-w">Ei</p>
              </ng-template>
              <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
            </div>
            </div>
          </div>

<!--               <div class="p-0 d-flex align-items-end">
            <div class="avatar-icon-chat">
              <img src="./../../assets/images/chat2Vet_some.png">
            </div>
          </div> -->
        </div>
      </div>

            <!-- TEMPLATE 21 Agreement with another doctor? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_4' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Olitteko sopineet ajasta eläinlääkärin kanssa?</p>
                    
                    
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" 
                    [ngClass]="{selectedButton: message.answer == true }">Kyllä olimme</button>

                    <button class="btn btn-secondary mt-2 mt-md-0" [disabled]="message.oldMessage" 
                      [ngClass]="{selectedButton: message.answer == false }">Emme ole sopineet</button>
                    
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>

                  </div>
              </div>
            </div>

          <!-- TEMPLATE 21 ANSWER agreement  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_4' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9 p-0">
                <div class="d-flex justify-content-end p-0">
                <div class="text-message text-message-right me-2">
                  <div *ngIf="message.answer == true;else not_true">
                    <p class="body2-w">Kyllä olimme</p>
                  </div>
                  <ng-template #not_true>
                    <p class="body2-w">Emme ole sopineet</p>
                  </ng-template>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
              </div>
              </div>
            </div>
          </div>

            <!-- TEMPLATE 22 Agreement with another doctor? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_22' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">{{message.actMessage}}</p>
                    
                    
                    <ul *ngIf="activeDoctors.length != 0;else no_doctors" class="list-group" id="menu">
                      <div class="p-list" *ngFor="let doc of activeDoctors">
      
<!--                               <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                              <div class="list-wrap-closed d-inline-flex ">
                                <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                                  <img class="small-pic-doc mt-1" src="{{doc.image}}" alt="eläinlääkäri">
                                </div>
                                <ng-template #standard_image>
                                  <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                </ng-template>
                                <div class="d-flex flex-column">
                                  <div class="doc-name">
                                    <p class="ms-2 doc-show-text body1"> {{ doc.name }}</p>
                                  </div>
                                  <div class="doc-designation">
                                    <p class="ms-2 doc-show-text body3"> {{ doc.designation }}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="pull-right">
                                <button class="pick-product-btn pull-right" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,doc._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                                [ngClass]="{selectedButton: message.answer == doc._id }">Valitse</button>
                              </div>
                          </li> -->

                          <button class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2" [disabled]="message.oldMessage"
                          [ngClass]="{selectedButton: message.answer == doc._id }">
                            <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                              <div class="col-12 pe-1 ps-1 row m-0">
                                <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                  <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                                    <img class="small-pic-doc mt-1" src="{{doc.image}}" alt="eläinlääkäri">
                                  </div>
                                  <ng-template #standard_image>
                                    <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                  </ng-template>
                                </div>
                                <div class="col-9 ps-0 ps-md-2 text-start">
                                  <div class="d-flex flex-column">
                                    <div class="doc-name">
                                      <p class="ms-2 doc-show-text body214bb"> {{ doc.name }}</p>
                                    </div>
                                    <div class="doc-designation">
                                      <p class="ms-2 doc-show-text body2"> {{ doc.designation }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
  
                            </div>
                          
                          
                        </button>


                        </div>
                      </ul>
                      <ng-template #no_doctors>
                        <div class="alert alert-warning">
                          <p>Eikä! Valitettavasti yhtään eläinlääkäriä ei ole paikalla. Asiasta ilmoitettu johtavalle eläinlääkärille. Voit jatkaa huolettomasti eteenpäin kyselyssä.  
                          </p>
                        </div>
                      </ng-template>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

          <!-- TEMPLATE 22 ANSWER doctor  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_22' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9 p-0">
                <div class="text-message text-message-right me-2">
                  <p class="body2-w">Valitsemasi eläinlääkäri</p>
                  <div  *ngFor="let doc of activeDoctors">
                    <div *ngIf="doc._id == message.answer">
                      <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                        <div class="list-wrap-closed d-inline-flex ">
                          <div class="img-cont" *ngIf="doc.image != null;else standard_image">
                            <img class="small-pic-doc mt-1" src="{{doc.image}}" alt="lemmikkisi">
                          </div>
                          <ng-template #standard_image>
                            <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                          </ng-template>
                          <div class="d-flex flex-column">
                            <div class="doc-name">
                              <p class="ms-2 doc-show-text body1"> {{ doc.name }}</p>
                            </div>
                            <div class="doc-designation">
                              <p class="ms-2 doc-show-text body3"> {{ doc.designation }}</p>
                            </div>
                          </div>
                        </div>
                    </li>
                    </div>
                  </div>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
              </div>

            </div>
          </div>
            

            <!-- TEMPLATE 17 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left break-word w-100">
                    <p class="body2">Esitietokysely on nyt suoritettu. Alla yhteenveto vastaamistasi asoista.</p>
                    
                    <table class= "table-clinic-times">
                        
                      <tr scope="row" class="sumup-row pb-2">
                        <td class="clinic-bought-content body3 pt-2">Kastroitu</td>
                        <td class="clinic-bought-content-right pt-2 body3b"><span *ngIf="message.actMessage['cast']">Kyllä</span><span *ngIf="message.actMessage['cast'] == false">Ei</span></td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Paino</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['weight']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Käyntisyy</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['reason']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Vaivaa aikaisemmin</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['before']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Vaiva alkoi</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['started'] | pformateDate}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Lisäkertomus</td>
                        <td class="clinic-bought-content-right body3b table-word-wrap break-word">{{message.actMessage['addInfo']}}</td>
                      </tr>
                      <tr class="sumup-row pb-2">
                        <td class="clinic-bought-content body3 pb-2">Krooniset sairaudet</td>
                        <td class="clinic-bought-content-right pb-2 body3b">{{message.actMessage['chronical']}}</td>
                      </tr>
                      <tr>
                        <td class="clinic-bought-content body3 pb-2">Lääkitys</td>
                        <td class="clinic-bought-content-right pb-2 body3b break-word">{{message.actMessage['medicine']}}</td>
                      </tr>
                    
                    </table>
                                          
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>
            <!-- TEMPLATE 17 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left break-word w-100">
                    <p class="body2">Eläinlääkäri ottaa sinut tutkimushuoneeseen tuota pikaa.</p>
                                          
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            
          
          </div>
        </div>

        </div><!-- chat message main container :: END -->






    </div>
    <!--Section two :: END-->

    <!--Section two :: started-->


</div>
