<app-admin-header></app-admin-header>

<div class="header-container-page pb-4">

    <h2 class=" pt-4 header-text-page">Tervetuloa</h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="pl-4 pb-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    
    </div>
    <h3 class="text-center mt-4">Mitä tehdään?</h3>
    <div class="col-12 row ms-0 mr-0">
        <div class="col-md-2"></div>
        <div class="col-12 col-md-8">
            <div class="col-12 row mt-4 me-0 ms-0">
                <div routerLink="/admin/add-clinic" class="col-12 col-md-3 pointer mt-2">
                    <div class="col-12 section-wrapper">
                        <div class="text-center pt-4">
                            <i class="fas fa-plus-circle link-icon"></i><br>
                            <p class="link-text pt-4">Lisää klinikka</p>
                        </div>
                    </div>
                </div>
                <div routerLink="/admin/clinics" class="col-12 col-md-3 pointer mt-2">
                    <div class="col-12 section-wrapper">
                        <div class="text-center pt-4">
                            <i class="fas fa-clinic-medical link-icon"></i><br>
                            <p class="link-text pt-4">Kaikki klinikat</p>
                        </div>
                    </div>
                </div>
                <div routerLink="/admin/payments" class="col-12 col-md-3 pointer mt-2">
                    <div class="col-12 section-wrapper">
                        <div class="text-center pt-4">
                            <i class="fas fa-money-check-alt link-icon"></i><br>
                            <p class="link-text pt-4">Maksut</p>
                        </div>
                    </div>
                </div>
                <div routerLink="/admin/global-settings" class="col-12 col-md-3 pointer mt-2">
                    <div class="col-12 section-wrapper">
                        <div class="text-center pt-4">
                            <i class="fas fa-sliders-h link-icon"></i><br>
                            <p class="link-text pt-4">Asetukset</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>

