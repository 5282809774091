<app-header></app-header>

<div class="header-page-container">

<h2 class=" pt-4 header-text">Profiili </h2>



<div class="row col-md-12 m-0">
<div class="col-md-1 d-flex"></div>
<div class="tab col-md-10 d-flex justify-content-center">
    <div class="col-md-2 no-mobile"></div>
    <button class="tablinks koti active col-md-2 body1b-b "  (click)="openCity($event, 'tiedot')">Tiedot</button>
    <button class="tablinks col-md-2 body1b-b " (click)="openCity($event,'rekisteri')">Käynnit</button>
    <button class="tablinks col-md-2 body1b-b " (click)="openCity($event,'ostokset')">Ostokset</button>
    <div class="col-md-2 no-mobile"></div>
  </div>
  <div class="col-md-1 d-flex"></div>
</div>

</div>
<div class="col-12 row m-0">
  <div class="col-3"></div>
  <div class="col-12 col-lg-6 p-0">
<div class="col-12 row me-0 mt-2" *ngIf="editSuccess">
  <div class="col-3"></div>
  <div class="col-12 col-md-6">
    <div class="alert alert-success">
      <p class=" body2b-b"> Tietojen päivittäminen onnistui!</p>
    </div>
  </div>
  <div class="col-3"></div>
</div>

<div class="col-12 row me-0 mt-2" *ngIf="errorMessage != ''">
  <div class="col-3"></div>
  <div class="col-12 col-md-6">
    <div class="alert alert-warning">
      <p class=" body2b-b">{{errorMessage}}</p>
    </div>
  </div>
  <div class="col-2"></div>
</div>

<div class="col-12 row me-0 mt-2" *ngIf="phoneErr">
  <div class="col-3"></div>
  <div class="col-12 col-md-6">
    <div class="alert alert-warning">
      <p class="body2">Puhelinnumero tulee sisältää suuntanumeron. Puhelinnumeron tulee olla yli 5 merkkiä pitkä ja pienempi kuin 17 merkkiä. Neljäs merkki ei saa olla 0.</p>
    </div>
  </div>
  <div class="col-3"></div>
</div>
        <!-- TAB CONTENT TIEDOT -->
        <div style="display: block;" id="tiedot" class="tabcontent">
            <div class="col-md-12 row m-0 p-0">
                    <form class="w-100"[formGroup]="form"
                    (ngSubmit)="onSubmit()">

                    <div class="col-12 p-0 mt-4 ">

                      <!-- Etunimi -->
                      <div class="form-group">
                        <div class="row col-12 m-0 p-0">
                          <div class="form-group mb-2 col-md-6 p-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Etunimi</label>
                          </div>
                          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                            <input type="text" placeholder="Etunimi" formControlName="firstName" id="firstName"
                            class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
                            <div class="my-alerts"
                              *ngIf="firstName.invalid && firstName.touched">
                              <p *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Sukunimi -->
                      <div class="form-group">
                        <div class="row col-12 m-0 p-0">
                          <div class="form-group mb-2 col-md-6 p-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Sukunimi</label>
                          </div>
                          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                            <input type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName"
                            class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
                            <div class="my-alerts"
                              *ngIf="lastName.invalid && lastName.touched">
                              <p *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Sukunimi -->
                      <div class="form-group">
                        <div class="row col-12 m-0 p-0">
                          <div class="form-group mb-2 col-md-6 p-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Katuosoite</label>
                          </div>
                          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                            <input type="text" placeholder="Sukunimi" formControlName="addressStreet" id="addressStreet"
                            class="form-control" [class.invalid-input]="addressStreet.errors && addressStreet.touched" required>
                            <div class="my-alerts"
                              *ngIf="addressStreet.invalid && addressStreet.touched">
                              <p *ngIf="addressStreet.errors.required">Sukunimi on pakollinen</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Postinumerot -->
                      <div class="form-group">
                        <div class="row col-12 m-0 p-0">
                          <div class="form-group mb-2 col-4 ps-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Postinumero</label>
                            <input type="text" placeholder="Postinumero" formControlName="addressZip" id="addressZip"
                            class="form-control" [class.invalid-input]="addressZip.errors && addressZip.touched" required>
                          </div>
                          <div class="form-group mb-2 col-8 p-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Postitoimipaikka</label>
                            <input type="text" placeholder="Postitoimipaikka" formControlName="addressCity" id="addressCity"
                            class="form-control" [class.invalid-input]="addressCity.errors && addressCity.touched" required>
                          </div>
                          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                          </div>
                        </div>
                      </div>

                      <!-- Puhelinnumero -->
                      <div class="form-group">
                        <div class="row col-12 m-0 p-0">
                          <div class="form-group mb-2 col-md-6 p-0">
                            <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Puhelinnumero</label>
                          </div>
                          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                            <input (change)="checkMobileNumeber()" type="tel" placeholder="Sukunimi" formControlName="phone" id="phone"
                            class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                            <div class="my-alerts"
                              *ngIf="phone.invalid && phone.touched">
                              <p *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p data-bs-toggle="modal" (click)="checkMobileNumeber()" data-bs-target="#edit-safe-modal" class="btn login-btn btn-veteva-secondary"
                      >Tallenna muutokset</p>
                </div>
              </form>
                </div>
            </div>

        <!-- TAB CONTENT KÄYNNIT  -->
        <div id="rekisteri" class="tabcontent ">


            <ul *ngIf="visits.length != 0;else no_visits_content" class="list-group mt-2" id="menu">
              <div *ngFor="let visit of visits" class="full-wrap mb-3">
                  <li class="list-group-item ps-0 list-wrap-item pe-0 list-group-item-link m-0">
                    <div class="list-wrap-closed col-12 row pe-0">
                      <div class="col-12 pe-0 row m-0">
                          <img class="col-2 col-md-1 small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                        
                        <div class=" col-10 pe-0  d-flex flex-column">
                          <div class="p-0">
                            
                            <p class=" mb-0 petname-text body2b-b"> {{ visit.petName }}</p>
                          </div>
                          <div class=" p-0">
                            <p *ngIf="visit.anamnesis" class=" body2bb">{{visit.anamnesis.reason}}</p>
                          </div>
                          <div class="col-12 p-0 row m-0">
                            <div class="col-7 p-0">
                              <p class="doc-name-text">{{visit.doctorFullName}}</p>
                            </div>
                            <div class="col-5 row m-0 p-0 pointer" routerLink="/{{clinicID}}/user/visit-detail/{{visit._id}}">
                              <div class="col-10 text-end ">
                                <p class="date-text">{{visit.createdDate | pformateDate}}</p>
                              </div>
                              <div class="col-2 p-0">
                                <i class="fas fa-angle-right icon-arrow"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                     
                    </div>
                    <div class="col-12 text-center justify-content-center row m-0">
                    </div>
                </li>

                </div>
            </ul>
        <ng-template #no_visits_content>
          <div class="col-12 col-md-10 mx-auto p-0 text-center">
              <div class="alert alert-warning">
                <p class="text-center">Et ole vielä käynyt eläinlääkärin palvelussa. Haluatko eläinlääkäriin?</p>
                <button class="btn btn-veteva-secondary mx-auto" routerLink="/{{clinicID}}/home">Eläinlääkäriin</button>
              </div>
            </div>
        

        </ng-template>
        </div>

        <!-- TAB CONTENT OSTOKSET  -->
        <div id="ostokset" class="tabcontent">

            <ul *ngIf="payments.length != 0;else no_payments_content" class="list-group" id="menu">
              <div *ngFor="let payment of payments" class="full-wrap mb-3">
                  <li class="list-group-item ps-0 list-wrap-item pe-0 list-group-item-link m-0">
                    <div class="list-wrap-closed col-12 row pe-0">
                      <div class="col-12 pe-0 row m-0">
                        <div class="col-1 me-3">
                          <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">

                        </div>                        
                        <div class=" col-10 pe-0  d-flex flex-column">
                          <div class="p-0">
                            
                            <p class=" mb-0 petname-text body2b-b"> {{ payment.itemPackage }}</p>
                          </div>
                          <div class=" p-0">
                            <p *ngIf="payment.productDesc" class=" body2bb">{{payment.productDesc}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 row p-0 m-0">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="mb-0 doc-name-text">Päivämäärä</p>
                        </div>
                      </div>
                      <div class="col-12 p-0 row m-0">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="body2b-b">{{payment.create_time | pformateDate}}</p>
                        </div>
                        <div class="col-4 p-0">
                          <p></p>
                        </div>
                      </div>
                      <div class="col-12 p-0 row m-0">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="mb-0 doc-name-text">Käyntipäivä</p>
                        </div>
                        <div class="col-4 p-0 text-end">
                          <p class="mb-0 doc-name-text text-end">Summa</p>
                        </div>
                      </div>
                      <div class="col-12 row p-0 m-0">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="body2b-b">{{payment.create_time | pformateDate}}</p>
                        </div>
                        <div class="col-4 p-0 text-end">
                          <p class="body2b-b">{{payment.total}} €</p>
                        </div>
                      </div>
                      <div class="col-12 row m-0" *ngIf="payment.discountCode">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="mb-0 doc-name-text">Alekoodi</p>
                        </div>
                        <div class="col-4 p-0 text-end">
                          <p class="mb-0 doc-name-text">Alennus</p>
                        </div>
                      </div>
                      <div class="col-12 row m-0" *ngIf="payment.discountCode">
                        <div class="col-1"></div>
                        <div class="col-7 p-0">
                          <p class="body2b-b">{{payment.discountCode}}</p>
                        </div>
                        <div class="col-4 p-0 text-end">
                          <p class="body2b-b">{{payment.discountPercent * 100}} %</p>
                        </div>
                      </div>
                      <div class="col-12 row m-0">
                        <div class="col-1"></div>
                        <div class="payment-success pointer" data-bs-target="#payment-status" data-bs-toggle="modal" *ngIf="payment.payment_status == 'OK'">
                          <p class=" mb-0 ">Maksettu  <span class="ms-2 fas fa-info-circle"></span></p>
                          
                      </div>
                      <div class="payment-sending pointer" data-bs-target="#payment-status" data-bs-toggle="modal" *ngIf="payment.payment_status == 'sending'">
                          <p class=" mb-0">Varattu  <span class="fas fa-info-circle"></span></p>
                      </div>
                      <div class="payment-failed pointer" data-bs-target="#payment-status" data-bs-toggle="modal" *ngIf="payment.payment_status == 'FAILED'">
                          <p class=" mb-0 ">Keskeytetty  <span class="ms-2 fas fa-info-circle"></span></p>
                      </div>
                      </div>
                    </div>
                    <div class="col-12 text-center justify-content-center row m-0">
                    </div>
                </li>

                </div>
            </ul>
            </div>
            <ng-template #no_payments_content>
              <div class="col-12 col-md-10 mx-auto">
                    <div class="alert alert-warning text-center">
                      <p class="text-center">Et ole vielä käynyt eläinlääkärin palvelussa. Haluatko eläinlääkäriin?</p>
                      <button class="btn btn-veteva-secondary mx-auto" routerLink="/{{clinicID}}/home">Eläinlääkäriin</button>
                    </div>
                  </div>
              
      
              </ng-template>

    
        <div class="modal fade" id="payment-status" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <h2 class="text-center header3 mt-4">Maksustatukset</h2><br>
                  <p class="text-start body2"><strong>Maksettu</strong> -status kertoo, että olet onnistuneesti maksanut eläin ja maksu on onnistuneesti veloitettu tililtäsi.</p>
                  <p class="text-start body2"><strong>Keskeytetty</strong> -status kertoo, että maksu on jostain syystä epäonnistunut. Tämän ilmestyessä rahoja ei sinulta ole veloitettu.</p>
                </div>
                <div class="text-center mt-4 mb-4">
                  <button class="btn btn-veteva-secondary" data-bs-dismiss="modal">Sulje</button>
                </div>
              </div>
            </div>
          </div>






                <!-- Varmista muokkaaminen MODAALI -->
                <div *ngIf="!phoneErr" class="modal fade" id="edit-safe-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <h2 class="text-center header3 mt-4">Muokkaa</h2><br>
                      <div class="modal-body">
                          <div id="loader" class="loader-container" style="display: none;">
                            <div class="loader mx-auto"></div>
                            <p class="text-center body2b-b mt-4 mb-4">Pieni hetki tietojasi muokataan...</p>
                          </div>
                          <div id="check-modal-data">
                            <p class="body2 text-center">Haluatko varmasti muokata tietojasi?</p>
                            <div class="col-12 text-center">
                              <button class="btn btn-veteva-secondary"
                              [disabled]="form.invalid" (click)="onSubmit()">Tallenna</button>                
                            </div>
                            <div class="col-12 text-center mt-4">
                              <a id="close-edit-modal" class="pointer" data-bs-dismiss="modal">Peruuta</a>
                            </div>
                       </div>
                        </div>
          
                      </div>
                    </div>
                  </div>