<app-docheader></app-docheader>

<div class="header-container-page">

  <h2 class=" pt-4 header-text-page">Profiili </h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="ps-4 ps-md-0 pe-4 pe-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
      <button class="tablinks koti active col-6 col-md-2 body1b-b "  (click)="openCity($event, 'details')">Omat tiedot</button>
      <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'visits')">Käynnit</button>

    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>
  <div style="display: block;" id="details" class="tabcontent">
    <div class="col-12 row">

      <div class="col-12 col-md-9">
        <button class="btn btn-veteva-secondary-edit mt-3 mb-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
      </div>
      <div class="col-12 col-md-3 btn-wrap text-end mt-3">
        <div class="dropdown">
          <button class="btn btn-veteva-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            Asetukset
          </button>
        
          <ul *ngIf="isAdmin" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item pointer" data-bs-toggle="modal" data-bs-target="#new-password">Uusi salasana</a></li>
            
    
          </ul>
        </div>
      </div>
    
    </div>

    <div class="col-12 m-0 row">
      <div class="col-4"></div>
      <div class="col-12 col-md-4 p-0 mt-4">
        <div *ngIf="!doctor.image ;else doc_image_content" class="col-5 text-center mx-auto white-bg p-0">
          <img class="w-100" src="../../assets/images/chat2Vet_some.png">
          <div class="wraps">
            <label for="filesd" class="btn btn-veteva-secondary">Lisää kuva</label>
            <input style="display:none" (change)="gotFile($event)" type="file" accept=".jpg, .jpeg, .png" id="filesd"/>
  
          </div>
        </div>
        <ng-template #doc_image_content>
          <div  class="col-5 text-center mx-auto white-bg p-0">
            <img class="w-100" src="{{imageRoute}}/{{doctor.image.key}}">
            <label for="filesChange" class="btn btn-veteva-secondary mt-4">Vaihda kuva</label>
            <input style="display:none" (change)="changePicture($event)" type="file" accept=".jpg, .jpeg, .png" id="filesChange"/>
          </div>
        </ng-template>
      </div>
      <div class="col-4"></div>

    </div>
    <div id="loader" class="loader-container" style="display: none;">
      <div class="loader mx-auto"></div>
      <p class="text-center body2b-b mt-4 mb-4">Pieni tietojasi päivitetään...</p>
      </div>
      
  
      <div class="col-12 row p-0 m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0">
          <div class="addpet-form" id="add-pet-form">
            <form class="flex-row add-pet-form mt-4 mb-4" [formGroup]="form"
            (ngSubmit)="checkMobileNumeber()">
              <!-- Etunimi -->
              <div class="form-group">
                <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  type="text" placeholder="Etunimi" formControlName="firstName" id="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
                    <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
                      <p class="mt-2" *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
                      <p *ngIf="firstName.errors.firstName"> Vaaditaan määrätty etunimi</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sukunimi -->
              <div class="form-group">
                <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
                    <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
                      <p class="mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
                      <p *ngIf="lastName.errors.lastName"> Vaaditaan määrätty sukunimi</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Sähköpostiosoite -->
              <div class="form-group">
                <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                    <input  type="email" placeholder="Eläinlääkärin sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                    <div class="my-alerts" *ngIf="email.invalid && email.touched">
                      <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
                      <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Puhelinnumero -->
              <div class="form-group">
                <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                    <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
                      <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                      <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
                    </div>
                  </div>
                </div>
              </div>
                <!-- Titteli -->
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                        <input  type="text" placeholder="Titteli" formControlName="designation" id="designation" class="form-control" [class.invalid-input]="designation.errors && designation.touched" required>
                        <div class="my-alerts" *ngIf="designation.invalid && designation.touched">
                            <p class="mt-2" *ngIf="designation.errors.required">Titteli on pakollinen</p>
                            <p *ngIf="designation.errors.designation"> Vaaditaan määrätty Titteli</p>
                        </div>
                        </div>
                    </div>
                </div>
                <button [disabled]="form.invalid" class=" login-btn btn-veteva-secondary">Muokkaa</button>
            </form>
          </div>
        </div>
        <div class="col-3"></div>
      </div>

  </div>
  <div id="visits" class="tabcontent container">
    <h4>Käyntitiedot:</h4>
    <div *ngIf="visits.length != 0; else no_visits_content">
    <div class="table-container">
      <table class="table table-striped ">
        <thead class="veteva-table-header">
          <tr>
            <tr>
              <th scope="col">PVM</th>
              <th scope="col">Kutsumanimi</th>
              <th scope="col">Laji</th>
              <th class="no-mobile" scope="col">Rotu</th>
              <th class="no-mobile" scope="col">Käyntisyy</th>
              <th class="no-mobile" scope="col">Omistaja</th>
              <th class="no-mobile" scope="col">Puh</th>
              <th scope="col">Koko käynti</th>
          </tr>
        </thead>
        <tbody>

            <tr *ngFor="let visit of visits" >
              <td id ="date">{{visit.createdDate | pformateDate}}</td>
              <td><a routerLink="/vet/pet-detail/{{visit.petsID}}">{{visit.petName}}</a></td>
              <td>{{visit.petsSpecies }}</td>
              <td class="no-mobile">{{visit.petBreed}}</td>
              <td class="no-mobile"> <span *ngIf="visit.anamnesis && visit.anamnesis.reason && visit.anamnesis != null;else no_reason">{{visit.anamnesis.reason}}</span>
              <ng-template #no_reason>
                <span>Ei leimattu</span>
              </ng-template></td>
              <td class="no-mobile"><a routerLink="/vet/user-detail/{{visit.owner}}">{{visit.firstName}} {{visit.lastName}}</a></td>
              <td class="no-mobile"><a href="tel:{{visit.usersPhone}}">{{visit.usersPhone}}</a></td>
              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
              <td><button class="btn btn-veteva-secondary" id="{{visit._id}}" routerLink="/{{clinicID}}/vet/visit-detail/{{visit._id}}">Käynti</button></td>
            </tr>
        </tbody>
      
      </table>
    </div>
  </div>
  
  <ng-template #no_visits_content>
    <div class=" col-12 alert alert-warning">
      <p class="text-center">Käyntejä ei löytynyt kyseiseltä käyttäjältä</p>
    </div>
  </ng-template>

  </div>
  
<div class="container">


    <button id="openModal" [hidden]="true" 
    data-bs-toggle="modal" data-bs-target="#new-password"></button>
      <div class="modal fade" id="new-password" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title header3" id="exampleModalLongTitle">Uusi salasana</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <p class="body1">Uusitaanko salasana käyttäjälle {{doctorFirstName}} ? </p>
              <div class="input-wrap">
                <div class="col-12 mt-3 text-center">
                  <button class="btn btn-success" (click)="renewPassword()">Uusi salasana</button>
                </div>
              </div>
            </div>
            <div class="col-12 row pb-4">
              <div class="col-6">
                <button class="btn btn-secondary" id="close-renew-password" data-bs-dismiss="modal">Peruuta</button>
              </div>
              <div class="col-6 text-center">
              </div>
            </div>
          </div>
        </div>
      </div>

<!--       <h4>Eläinlääkäritiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped ">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Nimi</th>
              <th class="no-mobile" scope="col">Titteli</th>
              <th class="no-mobile" scope="col">Sähköposti</th>
              <th class="no-mobile" scope="col">Puhelin</th>
              <th scope="col">Kirjautunut</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doctor of doctors" >
              <td>{{doctor.createdDate | pformateDate}}</td>
              <td>{{doctor.firstName}} {{doctor.lastName}}</td>
              <td class="no-mobile">{{doctor.designation}}</td>
              <td class="no-mobile">{{doctor.email}}</td>
              <td class="no-mobile">{{doctor.phone}}</td>
              <td > <span *ngIf="doctor.loggedIn == true">Kyllä</span><span *ngIf="doctor.loggedIn == false">Ei</span></td>

            </tr>

          </tbody>
        </table>
      </div> -->
