import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-clinic-settings',
  templateUrl: './clinic-settings.component.html',
  styleUrls: ['./clinic-settings.component.css']
})
export class ClinicSettingsComponent implements OnInit, AfterViewInit{

  currentUserName: string = "";


  currentUserId='';
  messageSuccess="";
  messageErr="";
  reasonsArr= [];
  openID = "";
  authToken = localStorage.getItem('auth');
  reason: FormGroup;
  form: FormGroup;
  clinicForm: FormGroup;
  clinicID = this.authService.getTokenData().clinicID;
  sClinic = [];
  
  addProduct: FormGroup;
  question: FormGroup;
  editQuestion: FormGroup;
  addCode: FormGroup;
  editCode: FormGroup;
  addOrganization: FormGroup;
  editProductForm:FormGroup;
  reasonForm: FormGroup;
  feePackages: any = [];
  discounts: any = [];
  codesArr: any = [];
  SuccessMessage = false;
  ErrMessage = false;
  isAdmin = false;
  roomData: any;
  videoToken: any;
  currentCode:any;
  currentReason: any;
  currentProduct:any;
  currentQuestion:any;
  questions:any = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
  ngAfterViewInit(){
    this.colorService.setVetColors();
  }


  ngOnInit() {

    this.fetchQuestions()
    
    this.question =this.fb.group({
      question: ['', Validators.required],
      q_text: ['', Validators.required],
      q_category:['', Validators.required],
    });


    this.form = this.fb.group({
      monStart: ['', Validators.required],
      monEnd: ['', Validators.required],
      tueStart: ['', Validators.required],
      tueEnd: ['', Validators.required],
      wedStart: ['', Validators.required],
      wedEnd: ['', Validators.required],
      thuStart: ['', Validators.required],
      thuEnd: ['', Validators.required],
      friStart: ['', Validators.required],
      friEnd: ['', Validators.required],
      satStart: ['', Validators.required],
      satEnd: ['', Validators.required],
      sunStart: ['', Validators.required],
      sunEnd: ['', Validators.required],
    });

    this.clinicForm = this.fb.group({
      clinicName: ['',
      Validators.required],
      clinicsID: ['',
      Validators.required],
      email: ['', [
        Validators.email, 
        Validators.required,
        Validators.maxLength(100)
      ]],
      phone: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],
      ovt: ['', 
      Validators.required],
      operator: ['', 
      Validators.required],
      mainColor: ['', 
      Validators.required],
      secondaryColor: ['', 
      Validators.required],
      logoUrl: ['', 
      Validators.required], })

    this.addCode = this.fb.group({
      code: ['', Validators.required],
      valid_until: ['', Validators.required],
      times_used: "",
      discount_percent: ['', Validators.required],
      max_usage: "",
    });

    this.editCode = this.fb.group({
      e_valid_until: ['',],
      e_discount_percent: [''],
      e_max_usage: ""
    });

    this.reason = this.fb.group({
      reason: ['', Validators.required]
    });

    this.reasonForm = this.fb.group({
      e_reason: ['', Validators.required]
    });

    this.addProduct =this.fb.group({
      productName: ['', Validators.required],
      productPrice: ['', Validators.required],
      productDescription:['', Validators.required],
      productCredit: ['', Validators.required],
      productStart: ['', Validators.required],
      productEnd: ['', Validators.required],
      chatOnly: ['', Validators.required],
    });


    this.editQuestion =this.fb.group({
      editQuestions: ['', Validators.required],
      editText: ['', Validators.required],
      editCategory:['', Validators.required],
    });

    this.editProductForm =this.fb.group({
      e_productName: ['', Validators.required],
      e_productPrice: ['', Validators.required],
      e_productDescription:['', Validators.required],
      e_productCredit: ['', Validators.required],
      e_productStart: ['', Validators.required],
      e_productEnd: ['', Validators.required],
      e_chatOnly: ['', Validators.required],
    });

    this.addOrganization =this.fb.group({
      organizationName: ['', Validators.required],
      organizationID: ['', Validators.required],
      organizationProductID: ['', Validators.required],
      organizationMemberCode: ['', Validators.required],
    });
    this.editQuestion =this.fb.group({
      editQuestions: ['', Validators.required],
      editText: ['', Validators.required],
      editCategory:['', Validators.required],
    });
    this.myId()
    
  }
  get monStart() { return this.form.get('monStart') };
  get monEnd() { return this.form.get('monEnd') };
  get tueStart() { return this.form.get('tueStart') };
  get tueEnd() { return this.form.get('tueEnd') };
  get wedStart() { return this.form.get('wedStart') };
  get wedEnd() { return this.form.get('wedEnd') };
  get thuStart() { return this.form.get('thuStart') };
  get thuEnd() { return this.form.get('thuEnd') };
  get friStart() { return this.form.get('friStart') };
  get friEnd() { return this.form.get('friEnd') };
  get satStart() { return this.form.get('satStart') };
  get satEnd() { return this.form.get('satEnd') };
  get sunStart() { return this.form.get('sunStart') };
  get sunEnd() { return this.form.get('sunEnd') };


  get code() { return this.addCode.get('code') };
  get valid_until() { return this.addCode.get('valid_until') };
  get times_used() { return this.addCode.get('times_used') };
  get discount_percent() { return this.addCode.get('discount_percent') };
  get max_usage() { return this.addCode.get('max_usage') };

  get email() { return this.clinicForm.get('email') };
  get phone() { return this.clinicForm.get('phone') };
  get clinicName() { return this.clinicForm.get('clinicName') };
  get clinicsID() { return this.clinicForm.get('clinicsID') };
  get ovt() { return this.clinicForm.get('ovt') };
  get operator() { return this.clinicForm.get('operator') };
  get mainColor() { return this.clinicForm.get('mainColor') };
  get secondaryColor() { return this.clinicForm.get('secondaryColor') };
  get logoUrl() { return this.clinicForm.get('logoUrl') };

  get editQuestions() { return this.editQuestion.get('editQuestions') };
  get editText() { return this.editQuestion.get('editText') };
  get editCategory() { return this.editQuestion.get('editCategory') };




  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    this.currentUserId = decodedToken.result.id;
    this.currentUserName = decodedToken.result.email;
    this.isAdmin = decodedToken.result.isAdmin;

    if(this.isAdmin){
      this.getTimes();
      this.getReasons();
      this.fetchCodes();
      this.fetchClinicDetails();
      this.fetchQuestions();
    }
    
    this.fetchConsultationPackages()
  }

  fetchClinicDetails(){



    this.http.get(environment.DOCTOR_CLINIC, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.sClinic = res.message;
          var c  = res.message[0];
          console.log(c);
          this.clinicForm = this.fb.group({
            clinicName: [c.clinicName, Validators.required],
            phone: [c.phone, Validators.required],
            email: [c.email, Validators.required],
            ovt: [c.ovt, Validators.required],
            operator: [c.operator, Validators.required],
            logoUrl: [c.logoUrl, Validators.required],
            mainColor: [c.mainColor, Validators.required],
            secondaryColor: [c.secondaryColor, Validators.required],
            clinicsID: [c.clinicID, Validators.required],
          })


        }
        else {
          
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })

  }

  onSubmitClinic(){
    var req = {
      clinicName: this.clinicForm.get('clinicName').value,
      phone: this.clinicForm.get('phone').value,
      email: this.clinicForm.get('email').value,
      ovt: this.clinicForm.get('ovt').value,
      operator: this.clinicForm.get('operator').value,
      logoUrl: this.clinicForm.get('logoUrl').value,
      mainColor: this.clinicForm.get('mainColor').value,
      secondaryColor: this.clinicForm.get('secondaryColor').value,
    }

    this.http.put(environment.DOCTOR_CLINIC, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Register success",res);
        if(res.status == 200 ){
          alert('Päivittäminen onnistui');
        }
        else {  
          alert('Päivittäminen epäonnistui')
        }
  
      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      }
    )
    
  }





  getTimes(){
    var userData = {
      data:this.currentUserId
    }
    this.http.post(environment.FIND_CLINIC_TIMES,userData, this.httpOptions).subscribe(
    (res: any) => {
      if (res.status == 200) {
        this.openID = res.answer._id;

        var opens = res.answer.opens;

        for (let i = 0; i < opens.length; i++) {
          const open = opens[i];

          switch (open.idx) {
            case 0:
            var sunStart = open.start;
            var sunEnd = open.end;
              break;
            case 1:
              var monStart = open.start;
              var monEnd = open.end;
            break;
            case 2:
              var tueStart = open.start;
              var tueEnd = open.end;
            break;
            case 3:
              var wedStart = open.start;
              var wedEnd = open.end;
            break;
            case 4:
              var thuStart = open.start;
              var thuEnd = open.end;
            break;
            case 5:
              var friStart = open.start;
              var friEnd = open.end;
            break;
            case 6:
              var satStart = open.start;
              var satEnd = open.end;
            break;
          
            default:
              break;
          }
          
        }

       this.form = this.fb.group({
        sunStart: [sunStart, Validators.required],
        sunEnd: [sunEnd, Validators.required],
        monStart: [monStart, Validators.required],
        monEnd: [monEnd, Validators.required],
        tueStart: [tueStart, Validators.required],
        tueEnd: [tueEnd, Validators.required],
        wedStart: [wedStart, Validators.required],
        wedEnd: [wedEnd, Validators.required],
        thuStart: [thuStart, Validators.required],
        thuEnd: [thuEnd, Validators.required],
        friStart: [friStart, Validators.required],
        friEnd: [friEnd, Validators.required],
        satStart: [satStart, Validators.required],
        satEnd: [satEnd, Validators.required],
        });

        this.colorService.setVetColors();

      }
    }, (err: any) => {

      console.log("Error while login :: ", err);
    }
  )

  }

  submitNewQuestion(){

    const req = {
      question: this.question.get('question').value,
      text: this.question.get('q_text').value,
      category: this.question.get('q_category').value,
    }

      this.http.post(environment.QUESTION, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            this.messageSuccess="Kysymyksen lisääminen onnistui";
            this.messageErr="";

            this.question.reset();
            this.fetchQuestions();

            this.SuccessMessage = true;
            this.waitAlert();
            

          } else {
            this.messageErr = "Alekoodin lisääminen epäonnistui"
            this.ErrMessage = true;
            this.waitAlert();
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )
  }

  submitEditQuestion(){

    const req = {
      question: this.editQuestion.get('editQuestions').value,
      text: this.editQuestion.get('editText').value,
      category: this.editQuestion.get('editCategory').value,
      questionID: this.currentQuestion._id,
    }

      this.http.put(environment.QUESTION, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            this.messageSuccess="Alekoodin lisääminen onnistui";
            this.messageErr="";

            this.editQuestion.reset();
            this.fetchQuestions();
            

            this.SuccessMessage = true;
            this.waitAlert();
            

          } else {
            this.messageErr = "Alekoodin lisääminen epäonnistui"
            this.ErrMessage = true;
            this.waitAlert();
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )
  }

  fetchQuestions(){

      this.http.get(environment.QUESTIONS, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            this.questions = res.message;

            this.question.reset();
            this.colorService.waitStyleColor();

          } else {
            this.messageErr = "Hakeminen epäonnistui"
            this.ErrMessage = true;
            this.waitAlert();
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )
  }

  deleteQuestion(question){

    this.http.delete(environment.QUESTION+'/'+question._id, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 200) {
          alert('Kysymyksen poistaminen onnistui');
          this.fetchQuestions();

        } else {
          this.messageErr = "Hakeminen epäonnistui"
          this.ErrMessage = true;
          this.waitAlert();
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )
}
  addNewCode() {

    var validUntil;
    var maxUsage;


    if(this.addCode.get('valid_until').value == "" && this.addCode.get('max_usage').value == ""){
      alert('Käyttömäärä ja voimassaoloaika eivät molemmat voi olla tyhjiä. Ole hyvä ja korjaa');
      return;
    }

    if(this.addCode.get('max_usage').value == "" || this.addCode.get('max_usage').value == null || this.addCode.get('max_usage').value == undefined) {
      maxUsage = "unlimited";
    }
    else {
      maxUsage = this.addCode.get('max_usage').value;
    }

    if(this.addCode.get('discount_percent').value < 1 || this.addCode.get('discount_percent').value > 99) {
      alert('Minimi on 1 ja maksimi on 99');
      return;
    }


    const req = {
      code: this.addCode.get('code').value,
      valid_until: this.addCode.get('valid_until').value,
      times_used: this.addCode.get('times_used').value,
      discount_percent: (this.addCode.get('discount_percent').value / 100).toFixed(4),
      max_usage: maxUsage,
    }
      this.http.post(environment.ADD_DISCOUNT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            this.messageSuccess="Alekoodin lisääminen onnistui";
            this.messageErr="";

            this.addCode.reset();
            this.fetchCodes();

            this.SuccessMessage = true;
            this.waitAlert();
            this.colorService.waitStyleColor()
            

          } else {
            this.messageErr = "Alekoodin lisääminen epäonnistui"
            this.ErrMessage = true;
            this.waitAlert();
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

  }

  fetchCodes(){

    this.http.get(environment.FETCH_CODES, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200) {
      this.codesArr = res.message
      var d = new Date()
      for (let i = 0; i < this.codesArr.length; i++) {
        const discount = this.codesArr[i];

        if(discount.max_usage != "unlimited") {

          if(discount.max_usage <= discount.times_used){
            this.waitStyle(discount);
          }
        }

        var dd = new Date (discount.valid_until);
        dd.setDate(dd.getDate() + 1);

        if(d > dd){
          this.waitStyle(discount);
        }

        
      }

      }
      else {
        this.codesArr = [];
      }
    }, (err: any) => {

      console.log("Error while login :: ", err);
    })

    }

    waitStyle(item) {
      var that = this;

      console.log('discount-'+item._id);
  
      setTimeout(() =>{
        document.getElementById('discount-'+item._id).classList.add('table-danger');
        


      },500)
  
    }

    waitStyleColor() {
      var that = this;

  
      setTimeout(() =>{
        this.colorService.setVetColors();


      },1000)
  
    }

    generateCode(length){
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      this.addCode.get('code').setValue(result);

    }

    removeCode(d){

      var req = {
        discountID: d._id
      }

      this.http.post(environment.REMOVE_DISCOUNT_CODE,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
        this.fetchCodes();
  
        }
        else {
          this.codesArr = [];
        }
      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  
        }

  alertDiscount(){
    if(this.addCode.get('discount_percent').value < 1 || this.addCode.get('discount_percent').value > 99) {
      alert('Minimi on 1 ja maksimi on 99 valintasi on: '+this.addCode.get('discount_percent').value);
      return;
    }
  }
  

  alertEditDiscount(){
    if(this.editCode.get('e_discount_percent').value < 1 || this.editCode.get('e_discount_percent').value > 99) {
      alert('Minimi on 1 ja maksimi on 99 valintasi on: '+this.editCode.get('e_discount_percent').value);
      return;
    }
  }
    editTimes(){

      var monday = {
        start: this.form.get('monStart').value,
        end: this.form.get('monEnd').value,
        idx: 1
      }

      var tuesday = {
        start: this.form.get('tueStart').value,
        end: this.form.get('tueEnd').value,
        idx: 2
      }
      var wednesday = {
        start: this.form.get('wedStart').value,
        end: this.form.get('wedEnd').value,
        idx: 3
      }
      var thursday = {
        start: this.form.get('thuStart').value,
        end: this.form.get('thuEnd').value,
        idx: 4
      }
      var friday = {
        start: this.form.get('friStart').value,
        end: this.form.get('friEnd').value,
        idx: 5
      }
      var saturday = {
        start: this.form.get('satStart').value,
        end: this.form.get('satEnd').value, 
        idx: 6
      }
      var sunday = {
        start: this.form.get('sunStart').value,
        end: this.form.get('sunEnd').value,
        idx: 0
      }
      var arr = [monday,tuesday,wednesday,thursday,friday,saturday,sunday]


      var data = {
        opens: arr,

        openID: this.openID  
      }

      this.http.post(environment.CHANGE_CLINIC_TIMES,data, this.httpOptions).subscribe(
        (res: any) => {
          if (res == true) {
            this.messageSuccess="";
            this.messageErr="";
            this.messageSuccess="Päivittäminen onnistui"
            window.scrollTo(0,0)
            alert('success');
    
          } else {
            this.messageSuccess="";
            this.messageErr="";
            this.messageErr="Päivittäminen epäonnistui, olehyvä ja yritä uudelleen"
            window.scrollTo(0,0)
          }
        }, (err: any) => {
    
          console.log("Error while login :: ", err);
        }
      )
    }

    getReasons(){

      this.http.get(environment.FIND_CLINIC_REASONS, this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200){
          this.reasonsArr = res.message;
          console.log(this.reasonsArr)
          this.waitStyleColor();
        }
        else {
          alert('Käyntisyiden hakemisessa tapahtui virhe')
        }

      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  
    }


    get cameReason() { return this.reason.get('reason') };

    addReason() {

          if (!this.reason.valid) {
            return;
          }
         console.log('Form valid');
      
          const request = {
            cameReason: this.reason.get('reason').value,
          }
            this.http.post(environment.ADD_REASON_END_POINT, request, this.httpOptions).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status == "success") {
                  this.messageSuccess="";
                  this.messageErr="";

                  this.reason.reset();
                  this.getReasons();

                  this.messageSuccess = "Käyntisyyn lisääminen onnistui."

                  this.SuccessMessage = true;
                  this.waitAlert();
                  

                } else {
                  this.messageErr = "Käyntisyyn lisääminen onnistui."

                  this.ErrMessage = true;

                  this.waitAlert();
                  

                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
      
          
        }
    deleteReason(syy){



      var req = {
        reasonID: syy
      }
      console.log(syy);
      this.http.post(environment.REMOVE_REASON, req,this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.messageSuccess="";
            this.messageErr="";
            this.getReasons()
            

          } else {
            this.messageSuccess="";
            this.messageErr="";
            this.messageErr="Päivittäminen epäonnistui, ole hyvä ja yritä uudelleen"
            window.scrollTo(0,0)
            //loginError = true;
          }
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 

    }

    fetchConsultationPackages(){
  
      this.http.get(environment.DOC_FETCH_CONSULTATION_PACKAGES, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res, 'Konsultaatio paketit ovat.:::');
        if(res.status == 200){
          this.feePackages = res.message;
        }
        
        console.log(res);
      }, (err: any) => {
        console.log("Error occured while finding designation for the user : ListData",err);
      }) 
  
    }

    deletePackage(e){
      var packageID = e.target.id;


      var req = {
        ID: packageID
      }
      this.http.post(environment.REMOVE_CONSULTATION_PACKAGE, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res, 'Konsultaatio paketit ovat.:::');
          if(res.status == 200){
            this.feePackages = res.message;
            this.fetchConsultationPackages();
          }
          
          console.log(res);
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 
    }

    get productName() { return this.addProduct.get('productName') };
    get productPrice() { return this.addProduct.get('productPrice') };
    get productCredit() { return this.addProduct.get('productCredit') };
    get productDescription() { return this.addProduct.get('productDescription') };
    get productStart() { return this.addProduct.get('productStart') };
    get productEnd() { return this.addProduct.get('productEnd') };
    get chatOnly() { return this.addProduct.get('chatOnly') };


    addNewProduct(){

      var monday = {
        start: this.form.get('monStart').value,
        end: this.form.get('monEnd').value,
        idx: 1
      }

      var tuesday = {
        start: this.form.get('tueStart').value,
        end: this.form.get('tueEnd').value,
        idx: 2
      }
      var wednesday = {
        start: this.form.get('wedStart').value,
        end: this.form.get('wedEnd').value,
        idx: 3
      }
      var thursday = {
        start: this.form.get('thuStart').value,
        end: this.form.get('thuEnd').value,
        idx: 4
      }
      var friday = {
        start: this.form.get('friStart').value,
        end: this.form.get('friEnd').value,
        idx: 5
      }
      var saturday = {
        start: this.form.get('satStart').value,
        end: this.form.get('satEnd').value, 
        idx: 6
      }
      var sunday = {
        start: this.form.get('sunStart').value,
        end: this.form.get('sunEnd').value,
        idx: 0
      }

      var days = [monday,tuesday,wednesday,thursday,friday,saturday,sunday];

      var prMon = <HTMLInputElement>document.getElementById('prMon');
      var prTue = <HTMLInputElement>document.getElementById('prTue');
      var prWed = <HTMLInputElement>document.getElementById('prWed');
      var prThu = <HTMLInputElement>document.getElementById('prThu');
      var prFri = <HTMLInputElement>document.getElementById('prFri');
      var prSat = <HTMLInputElement>document.getElementById('prSat');
      var prSun = <HTMLInputElement>document.getElementById('prSun');

      if(prMon.checked || prTue.checked || prWed.checked || prThu.checked || prFri.checked || prSat.checked || prSun.checked ){

        var arr = [];
        if(prMon.checked){
          arr.push(1);
        }
        if(prTue.checked){
          arr.push(2);
        }
        if(prWed.checked){
          arr.push(3);
        }
        if(prThu.checked){
          arr.push(4);
        }
        if(prFri.checked){
          arr.push(5);
        }
        if(prSat.checked){
          arr.push(6);
        }
        if(prSun.checked){
          arr.push(0);
        }

        //Tarkastetaan onko syötetty oikeat päivät

        for (let i = 0; i < arr.length; i++) {
          const checked = arr[i];

          for (let j = 0; j < days.length; j++) {
            const day = days[j];

            if(checked == day.idx){
              if(this.addProduct.get('productStart').value < day.start) {
              alert('Tuotteen aukiolo ei voi olla pienempi kuin klinikan aukioloaika. Klinikka aukeaa: '+day.start+" ja merkitsit tuotteen alkamiseksi " +this.addProduct.get('productStart').value );
              return;
              }
              else if(this.addProduct.get('productEnd').value > day.end) {
                alert('Tuotteen aukiolo ei voi olla suurempi kuin klinikan aukioloaika. Klinikka sulkeutuu: '+day.end+" ja merkitsit tuotteen sulkeutumiseksi "+ this.addProduct.get('productEnd').value);
                return;
              }
            } 
            
          }
          
        }

        //Tarkastetaan että alku aika on pienempi kuin loppu

        if(this.addProduct.get('productEnd').value < this.addProduct.get('productStart').value) {
          alert('Aloitusaika ei voi olla suurempi kuin lopetusaika');
          return;
        }

        //Check that chat only is valid
        var is_chat_only_true = true;
        if(this.editProductForm.get('e_chatOnly').value == 'false'){
          is_chat_only_true = false;
        }
      
      var req = {
        productName: this.addProduct.get('productName').value,
        productPrice: this.addProduct.get('productPrice').value,
        productCredit: this.addProduct.get('productCredit').value,
        productDescription: this.addProduct.get('productDescription').value,
        productStart: this.addProduct.get('productStart').value,
        productEnd: this.addProduct.get('productEnd').value,
        daysOpen: arr,
        chatOnly: is_chat_only_true,
      }

      this.http.post(environment.ADD_CONSULTATION_PACKAGE, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res, 'Konsultaatio paketit ovat.:::');
          if(res.status == 200){
            this.feePackages = res.message;
            this.addProduct.reset();
            var modal = document.getElementById('addPackage');
            this.fetchConsultationPackages();
            modal.scrollTo(0,0);
            this.colorService.waitStyleColor()

            this.messageSuccess = "Tuotteen lisääminen onnistui."

            this.SuccessMessage = true;
            this.waitAlert();

          }
          else if (res.status == 201) {
            this.messageErr == res.message;
            this.ErrMessage == true;
            this.waitAlert();
          }
          
          console.log(res);
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 
      }
      else {
        alert('Valitse päivät jolloin tuotteet toimivat');
        return;
      }
    }

    waitAlert() {
      var that = this;
  
      setTimeout(() =>{
        that.SuccessMessage = false;
        that.messageSuccess = "";
        that.messageErr = "";
        that.ErrMessage = false;
      },5000)
  
    }


    get organizationName() { return this.addOrganization.get('organizationName') };
    get organizationID() { return this.addOrganization.get('organizationID') };
    get organizationProductID() { return this.addOrganization.get('organizationProductID') };
    get organizationMemberCode() { return this.addOrganization.get('organizationMemberCode') };
    addNewOrganization(){

      var req = {
        organizationName:this.addOrganization.get('organizationName').value,
        organizationID:this.addOrganization.get('organizationID').value,
        organizationProductID:this.addOrganization.get('organizationProductID').value,
        organizationMemberCode:this.addOrganization.get('organizationMemberCode').value

      }

      this.http.post(environment.ADD_NEW_ORGANIZATION, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res, 'Konsultaatio paketit ovat.:::');
          if(res.status == 200){
            this.feePackages = res.message;
          }
          
          console.log(res);
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 
    }



        sendIntegration()  {


          this.http.get(environment.SEND_TEST_EMAIL, this.httpOptions).subscribe(
            (res: any) => {
              
            }, (err: any) => {
        
              console.log("Error while login :: ", err);
            })
        }

        get Editvalid_until() { return this.editCode.get('e_valid_until') };
        get Editdiscount_percent() { return this.editCode.get('e_discount_percent') };
        get Editmax_usage() { return this.editCode.get('e_max_usage') };

        editDiscountCode(discount){
          this.currentCode = discount;
          console.log(this.currentCode)
          this.Editvalid_until.setValue(discount.valid_until);
          this.Editdiscount_percent.setValue(discount.discount_percent * 100);
          this.Editmax_usage.setValue(discount.max_usage);
          document.getElementById('edit-code-btn').click();
        }

        editThisQuestion(question){
          this.currentQuestion = question;
          console.log(question)
          this.editQuestion.get('editQuestions').setValue(question.question)
          this.editQuestion.get('editCategory').setValue(question.category);
          this.editQuestion.get('editText').setValue(question.text);
          document.getElementById('open-edit-question').click();
        }

        editSelectedDiscountCode(){

          var maxUsage;

          if(this.editCode.get('e_valid_until').value == "" && this.editCode.get('e_max_usage').value == ""){
            alert('Käyttömäärä ja voimassaoloaika eivät molemmat voi olla tyhjiä. Ole hyvä ja korjaa');
            return;
          } 
          if(this.editCode.get('e_max_usage').value == "" || this.editCode.get('e_max_usage').value == null || this.editCode.get('e_max_usage').value == undefined) {
            maxUsage = "unlimited";
          }
          else {
            maxUsage = this.editCode.get('e_max_usage').value;
          }
      
          if(this.editCode.get('e_discount_percent').value < 1 || this.editCode.get('e_discount_percent').value > 99) {
            alert('Minimi on 1 ja maksimi on 99');
            return;
          }

          const req = {
            codeID: this.currentCode['_id'],
            valid_until: this.editCode.get('e_valid_until').value,
            discount_percent: (this.editCode.get('e_discount_percent').value / 100).toFixed(4),
            max_usage: maxUsage,
          }

          this.http.post(environment.EDIT_DISCOUNT_CODE, req, this.httpOptions).subscribe(
            (res: any) => {
              console.log(res);
              if (res.status == 200) {
                this.messageSuccess="Alekoodin muokkaaminen onnistui";
                this.messageErr="";
    
                this.addCode.reset();
                this.fetchCodes();
    
                this.SuccessMessage = true;
                this.waitAlert();
                
    
              } else {
                this.messageErr = "Alekoodin lisääminen epäonnistui"
                this.ErrMessage = true;
                this.waitAlert();
              }
            }, (err: any) => {
    
              console.log("Error while login :: ", err);
            }
          )

        }


        get e_reason() { return this.reasonForm.get('e_reason') };

        editReason(reason){
          this.currentReason = reason;
          console.log(this.currentReason);
          console.log(reason['reason'])
          this.reasonForm.get('e_reason').setValue(reason['reason']);
          document.getElementById('edit-reason-btn').click();
        }

        editSelectedReason(){
          const req = {
            reasonID: this.currentReason['_id'],
            reason: this.reasonForm.get('e_reason').value
          }

            this.http.post(environment.EDIT_REASON, req, this.httpOptions).subscribe(
              (res: any) => {
                if (res.status == 200) {
                  this.messageSuccess="Käyntisyyn muokkaaminen onnistui";
                  this.messageErr="";
      
                  this.reasonForm.reset();
                  this.getReasons();
      
                  this.SuccessMessage = true;
                  this.waitAlert();
                  
      
                } else {
                  this.messageErr = "Käyntisyyn muokkaaminen epäonnistui: "+ res.message;
                  this.ErrMessage = true;
                  this.waitAlert();
                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
        }

        get e_productName() { return this.editProductForm.get('e_productName') };
        get e_productPrice() { return this.editProductForm.get('e_productPrice') };
        get e_productCredit() { return this.editProductForm.get('e_productCredit') };
        get e_productDescription() { return this.editProductForm.get('e_productDescription') };
        get e_productStart() { return this.editProductForm.get('e_productStart') };
        get e_productEnd() { return this.editProductForm.get('e_productEnd') };
        get e_chatOnly() { return this.editProductForm.get('e_chatOnly') };

        editProduct(product){
          this.currentProduct = product;
          console.log(this.currentProduct);
          this.editProductForm.get('e_productName').setValue(product['name']);
          this.editProductForm.get('e_productPrice').setValue(product['price']);
          this.editProductForm.get('e_productCredit').setValue(product['productCredit']);
          this.editProductForm.get('e_productDescription').setValue(product['productDescription']);
          this.editProductForm.get('e_productStart').setValue(product['productStart']);
          this.editProductForm.get('e_productEnd').setValue(product['productEnd']);

            //Change boolean to string
            console.log(product)
            if(product['chatOnly'] == true){
              this.editProductForm.get('e_chatOnly').setValue('true');
            }
            else{
              this.editProductForm.get('e_chatOnly').setValue('false');
            }
          
          if(product.productDays){

            for (let i = 0; i < product.productDays.length; i++) {
              const day = product.productDays[i];

              console.log(day)

              switch (day) {
                case 1:
                  var e = <HTMLInputElement>document.getElementById('e_prMon');
                  e.checked = true;
                  break;

                case 2:
                  var e = <HTMLInputElement>document.getElementById('e_prTue');
                  e.checked = true;
                  break;

                case 3:
                  var e = <HTMLInputElement>document.getElementById('e_prWed');
                  e.checked = true;
                  break;

                case 4:
                  var e = <HTMLInputElement>document.getElementById('e_prThu');
                  e.checked = true;
                  break;

                case 5:
                  var e = <HTMLInputElement>document.getElementById('e_prFri');
                  e.checked = true;
                  break;

                case 6:
                  var e = <HTMLInputElement>document.getElementById('e_prSat');
                  e.checked = true;
                  break;
                  
                case 0:
                  var e = <HTMLInputElement>document.getElementById('e_prSun');
                  e.checked = true;
                  break;

              
                default:
                  break;
              }
            }
            
          }
          document.getElementById('edit-product-btn').click();
        }

        sendEditProduct(){

          var monday = {
            start: this.form.get('monStart').value,
            end: this.form.get('monEnd').value,
            idx: 1
          }
    
          var tuesday = {
            start: this.form.get('tueStart').value,
            end: this.form.get('tueEnd').value,
            idx: 2
          }
          var wednesday = {
            start: this.form.get('wedStart').value,
            end: this.form.get('wedEnd').value,
            idx: 3
          }
          var thursday = {
            start: this.form.get('thuStart').value,
            end: this.form.get('thuEnd').value,
            idx: 4
          }
          var friday = {
            start: this.form.get('friStart').value,
            end: this.form.get('friEnd').value,
            idx: 5
          }
          var saturday = {
            start: this.form.get('satStart').value,
            end: this.form.get('satEnd').value, 
            idx: 6
          }
          var sunday = {
            start: this.form.get('sunStart').value,
            end: this.form.get('sunEnd').value,
            idx: 0
          }
    
          var days = [monday,tuesday,wednesday,thursday,friday,saturday,sunday];
    
          var prMon = <HTMLInputElement>document.getElementById('e_prMon');
          var prTue = <HTMLInputElement>document.getElementById('e_prTue');
          var prWed = <HTMLInputElement>document.getElementById('e_prWed');
          var prThu = <HTMLInputElement>document.getElementById('e_prThu');
          var prFri = <HTMLInputElement>document.getElementById('e_prFri');
          var prSat = <HTMLInputElement>document.getElementById('e_prSat');
          var prSun = <HTMLInputElement>document.getElementById('e_prSun');
    
          if(prMon.checked || prTue.checked || prWed.checked || prThu.checked || prFri.checked || prSat.checked || prSun.checked ){
    
            var arr = [];
            if(prMon.checked){
              arr.push(1);
            }
            if(prTue.checked){
              arr.push(2);
            }
            if(prWed.checked){
              arr.push(3);
            }
            if(prThu.checked){
              arr.push(4);
            }
            if(prFri.checked){
              arr.push(5);
            }
            if(prSat.checked){
              arr.push(6);
            }
            if(prSun.checked){
              arr.push(0);
            }
    
            //Tarkastetaan onko syötetty oikeat päivät
    
            for (let i = 0; i < arr.length; i++) {
              const checked = arr[i];
    
              for (let j = 0; j < days.length; j++) {
                const day = days[j];
    
                if(checked == day.idx){
                  if(this.editProductForm.get('e_productStart').value < day.start) {
                  alert('Tuotteen aukiolo ei voi olla pienempi kuin klinikan aukioloaika. Klinikka aukeaa: '+day.start+" ja merkitsit tuotteen alkamiseksi " +this.editProductForm.get('e_productStart').value );
                  return;
                  }
                  else if(this.editProductForm.get('e_productEnd').value > day.end) {
                    alert('Tuotteen aukiolo ei voi olla suurempi kuin klinikan aukioloaika. Klinikka sulkeutuu: '+day.end+" ja merkitsit tuotteen sulkeutumiseksi "+ this.editProductForm.get('e_productEnd').value);
                    return;
                  }
                } 
                
              }
              
            }
    
            //Tarkastetaan että alku aika on pienempi kuin loppu
    
            if(this.editProductForm.get('e_productEnd').value < this.editProductForm.get('e_productStart').value) {
              alert('Aloitusaika ei voi olla suurempi kuin lopetusaika');
              return;
            }

            //Check that chat only is valid
            var is_chat_only_true = true;
            if(this.editProductForm.get('e_chatOnly').value == 'false'){
              is_chat_only_true = false;
            }

          const req = {
            _id: this.currentProduct['_id'],
            productName: this.editProductForm.get('e_productName').value,
            productPrice: this.editProductForm.get('e_productPrice').value,
            productCredit: this.editProductForm.get('e_productCredit').value,
            productDescription: this.editProductForm.get('e_productDescription').value,
            productStart: this.editProductForm.get('e_productStart').value,
            productEnd: this.editProductForm.get('e_productEnd').value,
            chatOnly: is_chat_only_true,
            daysOpen: arr,
          }

          console.log('lähtevä ', req)


          this.http.post(environment.EDIT_PRODUCT, req, this.httpOptions).subscribe(
            (res: any) => {
              if (res.status == 200) {
                this.messageSuccess="Tuotteen muokkaaminen onnistui!";
                this.messageErr="";
    
                this.editProductForm.reset();
                this.fetchConsultationPackages();

                window.scrollTo(0,0);
    
                this.SuccessMessage = true;
                this.waitAlert();
                
    
              } else {
                this.messageErr = "Käyntisyyn muokkaaminen epäonnistui: "+ res.message;
                this.ErrMessage = true;
                this.waitAlert();
              }
            }, (err: any) => {
    
              console.log("Error while login :: ", err);
            }
          )


          }
        }

        openCity(evt, cityName) {
          // Declare all variables
          var i, tabcontent, tablinks;
        
        
          // Get all elements with class="tabcontent" and hide them
          tabcontent = document.getElementsByClassName("tabcontent");
          for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
          }
        
          // Get all elements with class="tablinks" and remove the class "active"
          tablinks = document.getElementsByClassName("tablinks");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
          }
        
          // Show the current tab, and add an "active" class to the button that opened the tab
          document.getElementById(cityName).style.display = "block";
          evt.currentTarget.className += " active";
        
        
          }

          


    


}
