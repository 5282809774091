import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import * as XLSX from 'xlsx';
import 'xlsx';

const helper = new JwtHelperService;

@Component({
  selector: 'app-products-register',
  templateUrl: './products-register.component.html',
  styleUrls: ['./products-register.component.css']
})
export class ProductsRegisterComponent implements OnInit, AfterViewInit {

  currentUserId=this.authService.getTokenData().userID;
  products: any[];
  pets: any[];
  visits: any[];
  productsLen = 0;
  authToken = localStorage.getItem("auth");
  allUsers = [];
  clinicID = this.authService.getTokenData().clinicID;
  copied = false;
  doctors = [];
  selectedDocs = [];
  
  currentLink;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.myId();
  }

  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  myId(){
  
    var token = localStorage.getItem('auth');

    if(token == null){
      this.authService.doLogOut();
    }
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);


    var activeuser = localStorage.getItem('ACTIVE_USER');


    console.log('totta',decodedToken.result.designation)
    if(decodedToken.result.designation == 'patient'){
      console.log('totta')
      this.authService.doLogOut();

    }
    this.fetchAllProductData();
    this.fetchDoctorData();
  }

  fetchDoctorData(){
  

    this.http.get(environment.DOC_FETCH_ALL_DOCTORS_DATA_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        this.doctors = res.message;

        //PÄIVÄJÄRJESTYKSEEN 
        this.doctors = this.doctors.sort(function(a,b){
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
        });

        this.colorService.waitStyleColor();

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }


  fetchAllProductData(){
  

    this.http.get(environment.DOC_FETCH_CONSULTATION_PACKAGES,this.httpOptions).subscribe(
      (res: any) => {
        this.products = res.message;
        this.allUsers = res.message;

        //PÄIVÄMÄÄRÄN FORMATOINTI
        this.colorService.waitStyleColor();

        this.productsLen = this.products.length;

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

  generatePaymentLink(data){

    this.currentLink = "https://app.vetsy.vet/#/"+this.clinicID+"/public-payment/"+data._id+"/"+this.currentUserId;
    
    (<HTMLTextAreaElement>document.getElementById('link-input')).value = this.currentLink;

    console.log(this.currentLink);

    document.getElementById('get-link').click();

  }

  copyPaymentLink(){
    var text = this.currentLink;
    navigator.clipboard.writeText(text).then(function() {
      this.copied = true;
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      alert('Selaimesi ei tue toiminta.')
    });
  }

  generateExel(){

      const ws = XLSX.utils.table_to_sheet(document.getElementById('doc-table'));
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Links')
      XLSX.writeFile(wb, 'VETSY_PUBLIC_PAYMENT_LINKS.xlsx')
    
  }

  addDocToList(data){
    console.log(data);

    for(var i = 0; i < this.selectedDocs.length; i++){
      var item = this.selectedDocs[i];

      if(data.userID == item.userID){
        alert('ELL on jo lisätty listaan. Ole hyvä ja tarkista lista uudelleen.');
        return;
      }
    }
    this.selectedDocs.push(data);
  }

  removeItemFormDiffArr(term){
    for(var i = 0; i < this.selectedDocs.length; i++){
      if(this.selectedDocs[i] === term) {
        this.selectedDocs.splice(i,1); 
        i-1;
      }
    }
  }

}
