<app-admin-header></app-admin-header>

<div class="container">
  <button class="btn btn-veteva-secondary btn-veteva-edit btn-veteva-admin-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
  <h2>Klinikat:</h2>

      <p >Klinikoita yhteensä: <strong>{{clinics.length}}</strong></p>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Klinikka</th>
              <th scope="col">KlinikkaID</th>
              <th class="no-mobile" scope="col">Sähköpostiosoite</th>
              <th class="no-mobile" scope="col">Puh</th>
              <th scope="col">Tiedot</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let clinic of clinics" id="clinic-row-{{clinic._id}}">
              <td> <strong><span *ngIf="clinic.createdDate">{{clinic.createdDate | pformateDate}}</span><span *ngIf="!clinic.createdDate">Ei leimattu</span></strong></td>
              <td>{{clinic.clinicName}}</td>
              <td>{{clinic.clinicID}}</td>
              <td class="no-mobile">{{clinic.email}}</td>
              <td class="no-mobile">{{clinic.phone}}</td>
              <td> <button class="btn btn-veteva-secondary btn-veteva-admin" routerLink="/admin/clinic/{{clinic.clinicID}}/{{clinic._id}}" >Tiedot</button></td>
            </tr>

          </tbody>
        </table>
      </div>

</div>

        <ng-template #no_admin>
          <div class="alert alert-warning">
            <p class="text-center">Tämä sisältö on vain pääkäyttäjälle</p>
          </div>
        </ng-template>