<app-docheader></app-docheader>

<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">{{this.userFirstName}} {{this.userLastName}}</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>


<div class="container">
  <div class="heading-wrap row mt-4 mb-4">
    <div class="col-12 col-md-9">
      <button class="btn btn-veteva-secondary-edit mb-3 mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
      <button class="btn btn-veteva-secondary ms-4" type="button" routerLink="/{{clinicID}}/doctor-add-pet/{{patientID}}"><i class="fas fa-plus-circle pe-2"></i>Lisää uusi lemmikki</button>

      <div class="dropdown show">
        <a class="btn btn-veteva-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Asetukset
        </a>
      
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item pointer" data-bs-toggle="modal" data-bs-target="#edit-credit">Muuta käyntejä</a>
          <a class="dropdown-item pointer" data-bs-toggle="modal" data-bs-target="#sendEmail">Lähetä sähköposti</a>

        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 btn-wrap text-end">
    </div>
  </div>
  <div class="table-container">
    <h5 class="mb-3">Henkilötiedot:</h5>
    <p class="mb-0"><i class="fas fa-phone me-2"></i><a href="tel:{{userPhone}}">{{userPhone}}</a></p>
    <p class="mb-0"><i class="far fa-envelope me-2"></i><a href="mailto:{{userEmail}}">{{userEmail}}</a></p>
    <p ><i class="fas fa-wallet me-2"></i>Käyntejä: {{credit}}</p>
      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr>
            <th scope="col">Luotu</th>
            <th scope="col">Osoite</th>
            <th scope="col">Postinumero</th>
            <th scope="col">Postitoimipaikka</th>
          </tr>
        </thead>
        <tbody>

           <tr *ngFor="let user of users" >
              <td ><strong>{{user.createdDate | pformateDate}}</strong></td>
              <td>{{user.addressStreet}}</td>
              <td>{{user.addressZip}}</td>
              <td>{{user.addressCity}}</td>

            </tr> 

        </tbody>
      </table>
    </div>
      <br>
      <h4>Lemmikkitiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Nimi</th>
              <th scope="col">Sukupuoli</th>
              <th scope="col">Syntymäpäivä</th>
              <th scope="col">Laji</th>
              <th scope="col">Rotu</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pet of pets" >
              <td><strong>{{pet.createdDate | pformateDate}}</strong></td>
              <td><a routerLink="/vet/pet-detail/{{pet._id}}">{{pet.name}}</a></td>
              <td>{{pet.sex | changeGender}}</td>
              <td>{{pet.birthDate | pformateDate}}</td>
              <td>{{pet.species | changeSpecies}}</td>
              <td>{{pet.breed}}</td>
              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
            </tr>

          </tbody>
        </table>
      </div>

      <h4>Käyntitiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <tr>
                <th scope="col">Aikaleima</th>
                <th scope="col">Lääkäri</th>
                <th scope="col">Kutsumanimi</th>
                <th scope="col">Laji</th>
                <th scope="col">Rotu</th>
                <th scope="col">Omistaja</th>
                <th scope="col">Puh</th>
                <th scope="col">Koko käynti</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let visit of visits" >
              <td > <strong>{{visit.createdDate | pformateDate}}</strong></td>
              <td><a routerLink="/vet/doctor-detail/{{visit.doctorID}}">{{visit.doctorFullName}}</a></td>
              <td><a routerLink="/vet/pet-detail/{{visit.petsID}}">{{visit.petName}}</a></td>
              <td>{{visit.petsSpecies | changeSpecies}}</td>
              <td>{{visit.petBreed}}</td>
              <td><a routerLink="/vet/user-detail/{{visit.owner}}">{{visit.firstName}} {{visit.lastName}}</a></td>
              <td><a href="tel:{{visit.usersPhone}}">{{visit.usersPhone}}</a></td>
              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
              <td><button class="btn btn-veteva-secondary" id="{{visit._id}}" (click)="routeToVisit($event)">Käynti</button></td>
            </tr>

          </tbody>
        </table>
    </div>
    </div>

    <button id="openModal" [hidden]="true" 
    data-bs-toggle="modal" data-bs-target="#edit-credit"></button>
      <div class="modal fade" id="edit-credit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title header3" id="exampleModalLongTitle">Muokkaa käyntejä</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                ''
              </button>
            </div>
            <div class="modal-body">
              <p class="body1">Syötä kokonaislukuna käyttäjälle "{{userFirstName}}" käyntejä </p>
              <div class="input-wrap">
                <div class="col-12">
                  <p><strong>Käynnit</strong></p>
                </div>
                <div class="col-12">
                  <input class="form-control" id="credit-field" type="number">
                </div>
                <div class="col-12 mt-3">
                  <button class="btn btn-success" (click)="editCredit()">Muuta käyntejä</button>
                </div>
              </div>
            </div>
            <div class="col-12 row pb-4">
              <div class="col-6">
                <button class="btn btn-veteva-secondary" id="close-edit-credit" data-bs-dismiss="modal">Peruuta</button>
              </div>
              <div class="col-6 text-center">
              </div>
            </div>
          </div>
        </div>
      </div>

            <!-- MODAL LÄHETÄ SPOSTI -->
            <div class="modal fade" id="sendEmail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h3 class="modal-title header3" id="exampleModalLabel">Lähetä sähköposti</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      ''
                    </button>
                  </div>
                  <div id="loader" class="loader-container mt-4 mb-4" style="display:none">
                    <div class="loader mx-auto"></div>
                    <p class="body2b-b text-center mt-4 mb-4">Onnistuneen lähetyksen jälkeen popup sulkeutuu...</p>
                  </div>
                  <div class="alert alert-warning mt-3 mb-3" *ngIf="error" style="display: none;" >
                    <p class="body2b-b text-center">Virhe lähettäessä sähköpostia</p>
                  </div>
                  <div class="modal-body" id="email-send-modal">
              
                    <form [formGroup]="mail"
                      (ngSubmit)="sendEmailToPatient()">
      
                      <div class="form-group">
                          <label for="mail">Viesti</label>
                              <textarea type="text"
                                  placeholder="Kirjoita tähän..."
                                  formControlName="mail"
                                  id="mail"
                                  class="form-control"
                                  required></textarea>
                          
                      </div>
      
                      <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                        <div class="col-6 text-center">
                          <p class="btn btn-secondary-gray" id="close-modal-email" data-bs-dismiss="modal">Peruuta</p>
                        </div>
                        <div class="col-6 text-center">
                            <button class="btn btn-secondary" [disabled]="mail.invalid">Lähetä</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

