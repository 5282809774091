// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//Testi

/* const BASE_HOST = "localhost"
const BASE_PORT = "3000";
const BASE_URL = "http://" + BASE_HOST + ":" + BASE_PORT; */

const BASE_HOST = window.location.hostname; 
const BASE_URL = "";

/**
 * VETSY: 6Le07DYeAAAAAA_Svgrdcc5zaxqOw5IgswJeS5xm
 * TEST: 6LeCT_wjAAAAAKVhOYYji04CU6s-xUuBBvhLqcTe
 */
export const env_data = {
  google_site_key: "6Le07DYeAAAAAA_Svgrdcc5zaxqOw5IgswJeS5xm",
  urli: 222
};

export const environment = {
/*   production: false,
  WS_END_POINT: "ws://" + BASE_HOST + ":" + BASE_PORT, */

  production: true,
  WS_END_POINT: "wss://" + BASE_HOST,

  //-----------------------------------------------------------------------------------------
  //  USERS
  //-----------------------------------------------------------------------------------------

  CURRENT_USER_END_POINT: BASE_URL + "/api/users/currentUser",
  LOGIN_END_POINT: BASE_URL + "/api/users/login",
  REGISTER_END_POINT: BASE_URL + "/api/users/register",
  LOGOUT_END_POINT: BASE_URL + "/api/users/logout",
  CLOSE_DOC_CONSULTATION_VISIT: BASE_URL + '/api/users/visitClose',
  DECREASE_CONSULTATION_CREDIT: BASE_URL + '/api/users/decConCredit',
  CHECK_CREDIT_END_POINT: BASE_URL + '/api/users/getCredit',
  CHECK_QUEUE_END_POINT: BASE_URL + '/api/users/getQueueStatus',
  ALL_IN_QUEUE_END_POINT: BASE_URL + '/api/users/queue',
  PATIENT_TAKEN: BASE_URL + '/api/users/changeQueStatus',
  ID_TO_USERNAME: BASE_URL + '/api/users/changeIdToUserName',
  DOC_ID_TO_USERNAME: BASE_URL + '/api/users/docChangeIdToUserName',
  VALIDATE_USER_END_POINT:BASE_URL + '/api/users/validate',
  DOC_FETCH_ACTIVE_USERS_PET:BASE_URL + '/api/users/docFetchUsersPetData',
  DOC_FETCH_ACTIVE_USER_DATA:BASE_URL + '/api/users/docFetchFullUserData',
  FORGOT_PASSWORD_END_POINT: BASE_URL + '/api/users/forgotPassword',
  RESET_PASSWORD_END_POINT: BASE_URL + '/api/users/resetPassword',
  CHANGE_ON_RESET_PASSWORD_END_POINT:BASE_URL + '/api/users/changePasswordOnReset',
  SET_LOG_OUT_END_POINT:BASE_URL + '/api/users/eeli',
  BACK_TO_QUEUE_END_POINT:BASE_URL + '/api/users/userBackToQueue',
  DOC_LIST_DATA_END_POINT:BASE_URL + '/api/users/listData',
  SEND_EMAIL_TO_USER: BASE_URL + '/api/users/docSendEmail',
  SEND_EMAIL_TO_ADMIN:BASE_URL + '/api/users/docSendEmailAdmin',
  FIND_ONE_USER_END_POINT:BASE_URL + '/api/users/editableUserData',
  EDIT_USER_END_POINT:BASE_URL + '/api/users/editUser',
  DOC_PMS_USER_DATA_END_POINT:BASE_URL + '/api/users/docPMSUserData',
  DOC_FETCH_ALL_USER_DATA_END_POINT:BASE_URL + '/api/users/fetchAllUsersData',
  UPDATE_CONSULTATION_STATUS:BASE_URL + '/api/users/updateConsultationStatus',
  FIND_USER_SESSION_STATUS:BASE_URL + '/api/users/findMySessionStatus',
  USER_CHANGE_USER_SESSION_STATUS_TO_NORMAL:BASE_URL + '/api/users/userChangeSessionStatusToNormal',
  DOC_CHANGE_USER_STATUS_NORMAL:BASE_URL + '/api/users/DocUserChangeSessionStatusToNormal',
  FETCH_MY_ORGANIZATION:BASE_URL+ "/api/users/fetchMyOrganization",
  ACTIVATE_USER_AFTER_REGISTER:BASE_URL+ "/api/users/activateAfterRegister",
  DOC_CHANGE_USER_STATUS_TO_PAID:BASE_URL+ "/api/users/changeUserStatusToPaid",
  ACCEPT_MUSTI_TERMS:BASE_URL+ "/api/users/acceptMustiTerms",
  GOOGLE_PATIENT_LOGIN_VERIFICATION:BASE_URL+ "/api/users/googleLoginCheckupUser",
  ACCEPT_MUSTI_TERMS_POPUP:BASE_URL+ "/api/users/acceptMustiTermsDuring",
  DOC_EDIT_USER_CREDIT:BASE_URL+ "/api/users/docEditUserCredit",
  DOC_FETCH_MEANING_USER_DATA:BASE_URL+ "/api/users/decFetchMeaningData",
  CHECK_PAID_CUSTOMERS:BASE_URL+ "/api/users/checkPaidCustomers",
  DOC_SEND_EMAIL_TO_USER:BASE_URL+ "/api/users/docSendEmailToUser",
  ADD_ROOM_DETAILS:BASE_URL+ "/api/users/addRoomDetails",
  SEARCH_USERS:BASE_URL+ "/api/users/searchUsers",
  DOCTOR_REGISTER_USER:BASE_URL+ "/api/users/doctor/register",

  ADMIN_FETCH_USER_DETAILS:BASE_URL+ "/api/users/admin/user",

  //-----------------------------------------------------------------------------------------
  //  MESSAGES
  //-----------------------------------------------------------------------------------------

  MESSAGE_LIST_END_POINT: BASE_URL + "/api/messages/messageList",
  MESSAGE_LIST_BY_USER_END_POINT: BASE_URL + "/api/messages/messageListByUser",
  ANSWER_SELECTED_END_POINT: BASE_URL + "/api/messages/answerSelected",
  CONSULATATION_PACKAGE_PURCHASED_END_POINT: BASE_URL + "/api/messages/consultationPacagePurchased",
  DOCTOR_PICKED_USER: BASE_URL + '/api/messages/doctorPicked',

  DOC_MESSAGE_LIST_END_POINT: BASE_URL + "/api/messages/docMessageList",
  DOC_MESSAGE_LIST_BY_USER_END_POINT: BASE_URL + "/api/messages/docMessageListByUser",
  DOC_ANSWER_SELECTED_END_POINT: BASE_URL + "/api/messages/docAnswerSelected",
  DOC_LOAD_SINGLE_MESSAGE_LIST: BASE_URL + "/api/messages/docSingleMessageList",

  GET_PAYMET_METHODS: BASE_URL + '/api/messages/get-merchant-payment-methods',
  GET_PAYMET: BASE_URL + '/api/messages/get-payment',
  GET_PUBLIC_PAYMENT: BASE_URL + '/api/messages/get-public-payment',
  MAKE_BAMBORA_PAYMENT: BASE_URL + '/api/messages/makePayment',
  GET_PAYMET_FOR_ORGANIZATION: BASE_URL + '/api/messages/getPaymentForOrganization',
  DOC_MESSAGE_LIST_END_POINT_BY_DATE: BASE_URL + '/api/messages/docFetchMessagesByDate',
  USER_MESSAGE_LIST_END_POINT_BY_DATE: BASE_URL + '/api/messages/userFetchMessagesByDate',

  //-----------------------------------------------------------------------------------------
  //  DOCTORS
  //-----------------------------------------------------------------------------------------

  DOCLOGIN_END_POINT: BASE_URL + "/api/doctors/login",
  DOCREGISTER_END_POINT: BASE_URL + '/api/doctors/register',
  DOC_GET_QUEUE_STATUS_END_POINT: BASE_URL + '/api/users/docGetQueueStatus',
  DOC_GET_CREDIT_END_POINT: BASE_URL + '/api/users/docGetCredit',
  VALIDATE_DOCTOR_END_POINT: BASE_URL + '/api/doctors/docValidate',
  DOC_FIND_ALL_DOCTORS: BASE_URL + '/api/doctors/docFindAllDoctors',
  DOC_SET_LOG_OUT_END_POINT: BASE_URL + '/api/doctors/docDoLogOut',
  SEND_HELP_MAIL: BASE_URL + '/api/doctors/sendHelp',
  DOC_IMAGE_END_POINT:BASE_URL + '/api/doctors/fetchImage',
  FETCH_CONSULTANT_DATA:BASE_URL + '/api/doctors/fetchConsultantData',
  DOC_FETCH_ALL_DOCTORS_DATA_END_POINT:BASE_URL + '/api/doctors/findDoctorDataForPMS',
  DOC_PMS_DOCTOR_DATA_END_POINT:BASE_URL + '/api/doctors/findOneDoctorDataForPMS',
  FIND_ALL_ACTIVE_DOCTORS:BASE_URL + '/api/doctors/findAllActiveDoctors',
  PATIENT_LIST_DATA_END_POINT: BASE_URL + '/api/doctors/patientListData',
  VALIDATE_DOC_ACTIVATION_TOKEN: BASE_URL + '/api/doctors/validateActivationToken',
  ADD_PWD_FOR_DOCTOR: BASE_URL + '/api/doctors/setPasswordForDoc',
  DOC_VALIDATE_SMS: BASE_URL + '/api/doctors/validateDocLoginSMS',
  RENEW_DOCTOR_PASSWORD: BASE_URL + '/api/doctors/renewDoctorPassword',
  ADD_ROOM_DETAILS_DOCTOR: BASE_URL + '/api/doctors/addRoomForDoctor',
  CHECK_DOC_VIDEO_ROOM: BASE_URL + '/api/doctors/checkDoctorVideoRoom',
  VALIDATE_DOC_TEMP_AUTH_TOKEN: BASE_URL + '/api/doctors/validateDocTempAuth',
  SEND_TEST_EMAIL: BASE_URL + '/api/doctors/sendTestEmail',
  CHANGE_ASSISTANT_NAME_TO_ID: BASE_URL + '/api/doctors/assistantToId',
  SEND_SMS: BASE_URL + '/api/doctors/sendQueueSMS',
  GOOGLE_DOCTOR_LOGIN_VERIFICATION:BASE_URL+ "/api/doctors/googleLoginCheckupDoc",
  DOCTOR_PROFILE_IMAGE: BASE_URL + '/api/doctors/doctor/image',
  UPDATE_DOCTOR: BASE_URL + '/api/doctors/doctor/update',


  //-----------------------------------------------------------------------------------------
  //  PETS
  //-----------------------------------------------------------------------------------------

  ADDPET_END_POINT: BASE_URL + "/api/pets/addPet",
  FIND_PET_END_POINT: BASE_URL+ "/api/pets/findPets",
  DOC_FIND_PET_END_POINT: BASE_URL+ "/api/pets/findPets",
  FIND_ONE_PET_END_POINT: BASE_URL+ "/api/pets/findOnePet",
  EDIT_PET_END_POINT: BASE_URL+ "/api/pets/editPet",
  DOC_SET_PET_STATUS_FALSE_END_POINT:BASE_URL+ "/api/pets/docChangePetActiveStatusFalse",
  DOC_FETCH_ACTIVE_PETS:BASE_URL+ "/api/pets/DocAllActivePets",
  DELETE_ONE_PET_END_POINT:BASE_URL+ "/api/pets/deletePet",
  DOC_PMS_PET_DATA_END_POINT:BASE_URL+ "/api/pets/docFetchDataForPMS",
  DOC_PMS_PET_DATA_FOR_PET_END_POINT:BASE_URL+ "/api/pets/docFetchPetDataForPet",
  DOC_FETCH_ALL_PET_DATA_END_POINT:BASE_URL+ "/api/pets/docFetchAllPetDataPMS",
  USER_PMS_PET_DATA_FOR_USER_END_POINT:BASE_URL+ "/api/pets/userFetchPetDataForPet",
  FETCH_ANAMNESIS_DATA_FOR_PET:BASE_URL+ "/api/pets/docFetchAnamnesisData",
  DOC_FETCH_ACTIVE_USERS_PET_BY_ID:BASE_URL+ "/api/pets/docFindActivePet",
  DOC_PET:BASE_URL+ "/api/pets/doctor/pet",
  SEARCH_PETS:BASE_URL+ "/api/pets/searchPets",

  //-----------------------------------------------------------------------------------------
  //  REASONS --> Käyntisyyt
  //-----------------------------------------------------------------------------------------

  ADD_REASON_END_POINT: BASE_URL+ "/api/reasons/addReason",
  FIND_CLINIC_REASONS: BASE_URL+ "/api/reasons/docFindReasons",
  FIND_PATIENT_CLINIC_REASONS: BASE_URL+ "/api/reasons/patientFindReasons",
  REMOVE_REASON:BASE_URL+ "/api/reasons/removeReason",
  EDIT_REASON:BASE_URL+ "/api/reasons/editReason",
  ADMIN_REASONS:BASE_URL+ "/api/reasons/admin/reasons",

  //-----------------------------------------------------------------------------------------
  //  AUTH --> JWT
  //-----------------------------------------------------------------------------------------

  AUTH_END_POINT: BASE_URL+ "/api/auths/login",
  DOCTOR_AUTH_END_POINT: BASE_URL+ "/api/doctorauths/doctorLogin",


  //-----------------------------------------------------------------------------------------
  // OPEN
  //-----------------------------------------------------------------------------------------

  CHANGE_CLINIC_TIMES: BASE_URL+ "/api/opens/changeOpenTimes",
  FIND_CLINIC_TIMES: BASE_URL+ "/api/opens/findOpenTimes",
  FIND_CLINIC_OPEN: BASE_URL+ "/api/opens/findClinicOpen",

  //-----------------------------------------------------------------------------------------
  // VISITS
  //-----------------------------------------------------------------------------------------

  CREATE_VISIT_END_POINT:BASE_URL+ "/api/visits/createVisit",
  FETCH_ALL_VISITS_END_POINT:BASE_URL+ "/api/visits/fetchVisits",
  FIND_ONE_END_POINT:BASE_URL+ "/api/visits/findOneVisit",
  FETCH_VISIT_FOR_USER_END_POINT:BASE_URL+ "/api/visits/fetchVisitForUser", //käytetään pms ja doc-info-screenil
  FETCH_VISIT_FOR_PET_END_POINT:BASE_URL+ "/api/visits/fetchVisitForPet",
  FETCH_VISIT_FOR_DOCTOR_END_POINT:BASE_URL+ "/api/visits/fetchVisitForDoctor",
  FETCH_USER_VISITS_END_POINT:BASE_URL+ "/api/visits/fetchVisitForUserClient",
  USER_FETCH_VISIT_FOR_PET_END_POINT: BASE_URL+ "/api/visits/userFetchVisitForUserClient",
  FIND_ONE_VISIT_FOR_USER_END_POINT:BASE_URL+ "/api/visits/findOneVisitForUser",
  DOC_FETCH_VISITORS:BASE_URL+ "/api/visits/fetchAllVisitors",
  FETCH_USER_VISIT_DATA:BASE_URL+ "/api/visits/fetchVisitData",
  USER_FETCH_VISIT_DATA:BASE_URL+ "/api/visits/fetchUserVisitData",

  SEARCH_VISITS:BASE_URL+ "/api/visits/searchVisits",
  PUBLIC_NOTE:BASE_URL+ "/api/visits/visit/note/public",
  PRIVATE_NOTE:BASE_URL+ "/api/visits/visit/note/private",



  //-----------------------------------------------------------------------------------------
  // PRICELIST
  //-----------------------------------------------------------------------------------------

  FETCH_CONSULTATION_PACKAGES:BASE_URL+ "/api/pricelists/fetchGeneralSinglePackages",
  DOC_FETCH_CONSULTATION_PACKAGES:BASE_URL+ "/api/pricelists/docConsultationPackages",
  REMOVE_CONSULTATION_PACKAGE:BASE_URL+ "/api/pricelists/removeConsultationPackage",
  ADD_CONSULTATION_PACKAGE:BASE_URL+ "/api/pricelists/addNewProduct",
  FETCH_MY_ORGANIZATION_PACKAGES:BASE_URL+ "/api/pricelists/fetchMyOrganizationPackages",
  EDIT_PRODUCT:BASE_URL+ "/api/pricelists/editProduct",
  OPEN_PRODUCT_ENDPOINT:BASE_URL+ "/api/pricelists/open/product",

  //-----------------------------------------------------------------------------------------
  // CHATBOT
  //-----------------------------------------------------------------------------------------
  FIND_CHATBOT_MESSAGES:BASE_URL+ "/api/chatbotmessages/fetchChatBotMessages",

  //-----------------------------------------------------------------------------------------
  // FEEDBACK
  //-----------------------------------------------------------------------------------------

  ADD_FEEDBACK:BASE_URL+ "/api/feedbacks/addNewFeedback",
  FETCH_FEEDBACKS_REGISTER:BASE_URL+ "/api/feedbacks/fetchFeedbacks",

  //-----------------------------------------------------------------------------------------
  //  HASHTAGS
  //-----------------------------------------------------------------------------------------
  ADD_HASHTAG_END_POINT:BASE_URL+ "/api/hashtags/addNewHashtag",
  FIND_MY_HASHTAGS:BASE_URL+ "/api/hashtags/findMyHashTags",
  FIND_MY_SINGLE_HASHTAG:BASE_URL+ "/api/hashtags/findMySingleHashtag",
  EDIT_HASHTAG_END_POINT:BASE_URL+ "/api/hashtags/editHashtag",
  REMOVE_HASHTAG:BASE_URL+ "/api/hashtags/removeHashtag",
  FETCH_ALL_HASHTAGS:BASE_URL+ "/api/hashtags/findAllHashtags",
  SEARCH_HASHTAG:BASE_URL+ "/api/hashtags/searchHashtags",
  
  //-----------------------------------------------------------------------------------------
  //  ORGANIZATIONS
  //-----------------------------------------------------------------------------------------
  ADD_NEW_ORGANIZATION:BASE_URL+ "/api/organizations/addNewOrganization",
  FETCH_ALL_ORGANIZATIONS:BASE_URL+ "/api/organizations/fetchAllOrganizations",
  REDEEM_ORGANIZATION_MEMBERSHIP:BASE_URL+ "/api/organizations/redeemMembership",
  FETCH_MY_ORGANIZATION_DATA:BASE_URL+ "/api/organizations/fetchMyOrganizationData",

  //-----------------------------------------------------------------------------------------
  //  DIAGNOSIS
  //-----------------------------------------------------------------------------------------
  DOC_ALL_FETCH_DIAGNOSIS:BASE_URL+ "/api/diagnosis/fetchAllDiagnosis",

  //-----------------------------------------------------------------------------------------
  //  PROCEDURES
  //-----------------------------------------------------------------------------------------

  FETCH_ALL_PROCCEDURES:BASE_URL+ "/api/procedures/fetchAllProcedures",

  //-----------------------------------------------------------------------------------------
  //  ITEMS
  //-----------------------------------------------------------------------------------------

  FETCH_ALL_ITEMS:BASE_URL+ "/api/items/fetchAllItems",

  //-----------------------------------------------------------------------------------------
  //  ITEMS
  //-----------------------------------------------------------------------------------------

  FETCH_ALL_LABS:BASE_URL+ "/api/labs/fetchAllLabs",

  //-----------------------------------------------------------------------------------------
  //  BILLINGS
  //-----------------------------------------------------------------------------------------

  ADD_NEW_BILLING_FOR_USER:BASE_URL+ "/api/billings/addNewBillingForUser",

  //-----------------------------------------------------------------------------------------
  //  PAYMENTS
  //-----------------------------------------------------------------------------------------

  FETCH_MY_PAYMENTDATA:BASE_URL+ "/api/payments/fetchMyPaymentData",
  FETCH_PAYMENTS_FOR_REGISTER:BASE_URL+ "/api/payments/fetchPaymentDataForRegister",
  FETCH_ADMIN_PAYMENTS:BASE_URL+ "/api/payments/admin/payments",
  FETCH_ADMIN_PAYMENTS_BY_DATE:BASE_URL+ "/api/payments/admin/date",
  FETCH_PAYMENTS_BY_DATE:BASE_URL+ "/api/payments/date",

  //-----------------------------------------------------------------------------------------
  //  CALLBACKS
  //-----------------------------------------------------------------------------------------
  SEND_CALLBACK:BASE_URL+ "/api/callbacks/addNewCallback",

  //-----------------------------------------------------------------------------------------
  //  RECHAPTAS
  //-----------------------------------------------------------------------------------------

  FETCH_RANDOM_IMAGE: BASE_URL+ "/api/rechaptas/findRandomImage",
  CONFIRM_CHAPTA_ANSWER: BASE_URL+ "/api/rechaptas/findAnswer",

  //-----------------------------------------------------------------------------------------
  //  BREEDS
  //-----------------------------------------------------------------------------------------

  FETCH_BREED_BY_TERM: BASE_URL+ "/api/breeds/fetchBreedByTerm",
  DOC_FETCH_BREED_BY_TERM: BASE_URL+ "/api/breeds/doctor/breed/term",

  //-----------------------------------------------------------------------------------------
  //  SPECIES
  //-----------------------------------------------------------------------------------------

  FETCH_ALL_SPECIES: BASE_URL+ "/api/species/fetchAllSpecies",
  DOC_FETCH_SPECIES: BASE_URL+ "/api/species/doctor/species",

  //-----------------------------------------------------------------------------------------
  //  DISCOUNTS
  //-----------------------------------------------------------------------------------------

  ADD_DISCOUNT: BASE_URL+ "/api/discounts/addNewDiscountCode",
  FETCH_CODES: BASE_URL+ "/api/discounts/fetchAllCodes",
  FETCH_DISCOUNT_CODE:BASE_URL+ "/api/discounts/fetchSingleCode",
  REMOVE_DISCOUNT_CODE:BASE_URL+ "/api/discounts/removeDiscountCode",
  EDIT_DISCOUNT_CODE:BASE_URL+ "/api/discounts/editDiscountCode",

  //-----------------------------------------------------------------------------------------
  //  QUEUETIMES
  //-----------------------------------------------------------------------------------------
  FETCH_QUEUE_TIMES_REGISTER:BASE_URL+ "/api/queuetimes/fetchQueueTimes",

  //-----------------------------------------------------------------------------------------
  //  VIDEO
  //-----------------------------------------------------------------------------------------
  CREATE_VIDEO_ROOM:BASE_URL+ "/api/videoRouter/createRoom",
  REMOVE_VIDEO_ROOM:BASE_URL+ "/api/videoRouter/removeRoom",
  GET_VIDEO_ROOM_TOKEN:BASE_URL+ "/api/videoRouter/getToken",
  GET_VIDEO_USER_ROOM_TOKEN:BASE_URL+ "/api/videoRouter/getUserToken",

  //-----------------------------------------------------------------------------------------
  //  Statistics
  //-----------------------------------------------------------------------------------------

  STATISTIC:BASE_URL+ "/api/statistics/statistic",
  PAYMENT_STATISTICS:BASE_URL+ "/api/statistics/payments",
  REGISTERS_STATISTICS:BASE_URL+ "/api/statistics/registers",
  VISITS_STATISTICS:BASE_URL+ "/api/statistics/visits",
  FEEDBACKS_STATISTICS:BASE_URL+ "/api/statistics/feedbacks",

  //-----------------------------------------------------------------------------------------
  //  ADMINS
  //-----------------------------------------------------------------------------------------

  GOOGLE_ADMIN_LOGIN_VERIFICATION:BASE_URL+ "/api/admins/login",
  ADMIN_SMS_VALIDATION: BASE_URL + '/api/admins/admin/login/sms',
  VALIDATE_ADMIN_TEMP_AUTH: BASE_URL + '/api/admins/admin/validate/tempauth',

  //-----------------------------------------------------------------------------------------
  //  CLINIC
  //-----------------------------------------------------------------------------------------

  CLINIC:BASE_URL+ "/api/clinics/clinic",
  VALIDATE_CLINIC:BASE_URL+ "/api/clinics/clinic/validate",
  CLINIC_VISUALS:BASE_URL+'/api/clinics/clinic/openapi/colors',
  FETCH_ADMIN_CLINICS:BASE_URL+'/api/clinics/admin/clinics',
  FETCH_ADMIN_SINGLE_CLINIC:BASE_URL+'/api/clinics/admin/clinic',
  ADMIN_EDIT_CLINIC:BASE_URL+'/api/clinics/admin/clinic',
  DOCTOR_CLINIC:BASE_URL+'/api/clinics/doctor/clinic',

  //-----------------------------------------------------------------------------------------
  //  ATTACHMENTS
  //-----------------------------------------------------------------------------------------

  FILES_IMAGE:BASE_URL+ "/api/files/image",
  FILES_IMAGE_SM:BASE_URL+ "/api/files/image/sm",
  FILES_IMAGE_SM_PUBLIC:BASE_URL+ "/api/files/image/sm/public",
  FILES_IMAGE_PUBLIC:BASE_URL+ "/api/files/image/public",
  FILES_FILE:BASE_URL+ "/api/files/file/upload",
  FILES_VIDEO:BASE_URL+ "/api/files/video/upload",
  DELETE_ATTACHMENT:BASE_URL+ "/api/files/delete",
  FETCH_ATTACHMENT:BASE_URL+ "/api/files/file",
  UPLOAD_LT_VIDEO:BASE_URL+ "/api/files/file/folder/upload/lt",

  //-----------------------------------------------------------------------------------------
  //  QUESTIONS
  //-----------------------------------------------------------------------------------------

  QUESTION:BASE_URL+ "/api/questions/question",
  QUESTIONS:BASE_URL+ "/api/questions/questions",
  USER_QUESTIONS:BASE_URL+ "/api/questions/questions/user",

  //-----------------------------------------------------------------------------------------
  //  VIDEOS
  //-----------------------------------------------------------------------------------------


  VALIDATE_VIDEO_TOKEN:BASE_URL+ "/api/tempVideos/validate",

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
