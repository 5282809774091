import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHashtag'
})
export class SearchHashtagPipe implements PipeTransform {

  transform(itemsFinal: any[], searchTextHashtag: string, searchNumber:any): any[] {
    if(!itemsFinal) return [];

    if(searchTextHashtag && searchTextHashtag.charAt(0) == "#") {
    if(searchTextHashtag.length > 4) {

    //if(searchText == "") return [];
    if(!searchTextHashtag) return itemsFinal;
    searchTextHashtag = searchTextHashtag.toLowerCase();
    return itemsFinal.filter( data => {
      //Eläinlääkäri chatissa
      if(data.text == undefined) {
        return data.hashtag.toLowerCase().includes(searchTextHashtag)
      }
      //hashtag settingseissä
      else {
        return data.hashtag.toLowerCase().includes(searchTextHashtag) ||  data.text.toLowerCase().includes(searchTextHashtag);
      } 
    });
    }
    else {
      return itemsFinal;
    }
  }
  else {
    return itemsFinal;
  }
  }

}
