import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-queue-time-register',
  templateUrl: './queue-time-register.component.html',
  styleUrls: ['./queue-time-register.component.css']
})
export class QueueTimeRegisterComponent implements OnInit,AfterViewInit {

  currentUserId='';
  patientID: string = this.route.snapshot.params.id;
  times: any[];
  authToken = localStorage.getItem("auth");
  timesSum = 0;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
    ngOnInit() {
      this.myId();
    }
    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      console.log('iidee',decodedToken.result.id);
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
        this.logOutt();
  
      }
      this.fetchQueueTimes()
    }

    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('vastaus',res);
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }

    fetchQueueTimes(){

      this.http.get(environment.FETCH_QUEUE_TIMES_REGISTER, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.times = res.message;
            this.timesSum = 0;

            for (let i = 0; i < this.times.length; i++) {
              const time = this.times[i];

              this.timesSum = parseInt(time.queueTime) + this.timesSum;
              
            }

            this.colorService.setVetColors();

          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })

    }

}
