import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-edit-pet',
  templateUrl: './edit-pet.component.html',
  styleUrls: ['./edit-pet.component.css']
})
export class EditPetComponent implements OnInit,AfterViewInit {

  form: FormGroup;
  petID: string = this.route.snapshot.params.id;
  mID="";
  searchTextAll;
  currentUserId='';
  pets: any = [];
  speciesArr:any = [];
  breedArr: any = [];
  breedID;
  vetID;
  output;
  pet: '';
  authToken = localStorage.getItem('auth');
  formattedBday: string;
  errMessage;
  consentErr = false;
  petName;
  clinicID = this.authService.getTokenData().clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit() {

    this.form = this.fb.group({
      name: ['', Validators.required],
      sex: ['', Validators.required],
      birthDate: ['', Validators.required],
      species: ['', Validators.required],
      breed: ['', Validators.required],
      sterilized: ['', Validators.required],
      microCard: "",
      weight: ['', Validators.required],
      registerNumber: "",
      addInfo: "",
    });
    this.myId();
    this.getSpecies();
  }

  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);
    this.currentUserId= decodedToken.result.id;
    this.findPet(this.petID);
  }


  findPet(petID){
    if(petID.length == 24){
    var req = {
      id: petID,
      userID: this.currentUserId
    }
    this.http.post(environment.FIND_ONE_PET_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        res = res[0];
        this.pet = res;
        this.mID = res.mustiPetID;
        this.vetID = res.ownerVetevaID;
        this.breedID = res.breedID;
        this.petName = this.pet['name'];
        var sterilized = "false"
        if(res.sterilized == true){
          sterilized == "true"
        }

        

        let doo = new Date(res.birthDate);
        this.formattedBday = new Date( doo.getTime() - doo.getTimezoneOffset()).toISOString().split('T')[0];

        var formated_sex;

        if(res.sex){
          
          if(res.sex.toLowerCase() == "uros"){
            formated_sex = "Male";
          }
          else if(res.sex.toLowerCase() == "naaras"){
            formated_sex = "Female";
          }
          else{
            formated_sex = res.sex;
          }
        }
        else{
          console.log('Nullia')
        }
        
        this.form = this.fb.group({
          name: [res.name, Validators.required],
          sex: [formated_sex, Validators.required],
          birthDate: [this.formattedBday, Validators.required],
          species: [res.species, Validators.required],
          breed: [res.breed, Validators.required],
          sterilized: [sterilized, Validators.required],
          microCard: [res.microCard],
          weight: [res.weight, Validators.required],
          registerNumber:[res.registerNumber],
          addInfo: [res.addInfo]
        });

        this.pets = res[0];
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
    }
      
  }

  get name() { return this.form.get('name') };
  get sex() { return this.form.get('sex') };
  get birthDate() { return this.form.get('birthDate') };
  get species() { return this.form.get('species') };
  get breed() { return this.form.get('breed') };
  get microCard() { return this.form.get('microCard') };
  get addInfo() { return this.form.get('addInfo') };
  get sterilized() { return this.form.get('sterilized') };
  get weight() { return this.form.get('weight') };

  onSubmit() {
    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }

    this.errMessage = false;
    var elm = document.getElementById('add-pet-form');
     elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block"; 

    var istrue = false
    if(this.form.get('sterilized').value == "true") {
      istrue = true;
    }

    var request = {
      name: this.form.get('name').value,
      sex: this.form.get('sex').value,
      birthDate: this.form.get('birthDate').value,
      species: this.form.get('species').value,
      breed: this.form.get('breed').value,
      microCard: this.form.get('microCard').value,
      addInfo: this.form.get('addInfo').value,
      sterilized: istrue,
      weight: this.form.get('weight').value,
      userID: this.currentUserId,
      petID:this.petID,
      mustiID:this.mID,
      breedID:this.breedID,
      ownerVetevaID: this.vetID,
    }


    this.http.post(environment.EDIT_PET_END_POINT, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Register success",res);
        if(res.status == 200 ){
          //localStorage.removeItem('pet-image');
          this.router.navigate(['/'+this.clinicID+'/home']);
        }
        else {
          document.getElementById('loader').style.display="none";
          elm.classList.remove("reg-form-success");
        }

      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  }


  handleFiles(e) {
    var canvas= <HTMLCanvasElement>document.getElementById("canvas");
    var rr= <HTMLCanvasElement> canvas;
    var ctx = rr.getContext("2d");
    var cw=64
    var ch=64
    var maxW=64;
    var maxH=64;
    this.output =""
    var input = document.getElementById('input');
//var output = <HTMLCanvasElement> document.getElementById('file_output');
    var img = new Image;
    img.onload = function updateImage() {
      var iw=img.width;
      var ih=img.height;
      var scale=Math.min((maxW/iw),(maxH/ih));
      var iwScaled=iw*scale;
      var ihScaled=ih*scale;
      canvas.width=iwScaled;
      canvas.height=ihScaled;
      ctx.drawImage(img,0,0,iwScaled,ihScaled);
      var value = canvas.toDataURL("image/jpeg",0.5);
      localStorage.setItem('pet-image',value);
      
      return value;
    };
    
    img.src = URL.createObjectURL(e.target.files[0]);

    
  }

  getSpecies() {
    this.http.get(environment.FETCH_ALL_SPECIES, this.httpOptions).subscribe(
      (res: any) => {
        this.speciesArr = res.message
        var result1 = [], result2 = [];
        for (var i = 0; i < this.speciesArr.length; i++) {
          if (this.speciesArr[i].term != "") {
            result1.push(this.speciesArr[i]);
          } else {
            result2.push(this.speciesArr[i]);
            
          }
          if(i+1 == this.speciesArr.length){
            console.log(result1);
            result1.sort();
            result2.sort(function(a, b) {
              var textA = a.species.toUpperCase();
              var textB = b.species.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            result1.sort(function(a, b) {
              var textA = a.species.toUpperCase();
              var textB = b.species.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
          }
        }
        this.speciesArr = result1.concat(result2);


      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  }
  autoFetchRightBreed(e){    
    var value = (<HTMLInputElement>document.getElementById('species')).value;
    for(var i = 0; i < this.speciesArr.length; i++){
      //console.log(this.speciesArr[i]);
      var specie = this.speciesArr[i];

      if(specie.species == value){
        if(specie.term) {
          this.fetchBreedByTerm(specie.term);
          this.form.get('breed').setValue("");
          this.breedArr = [];
          var elm = document.getElementById('breed-modal');
          elm.click();
        }
        else {
          console.log('Ei ollut termiä');
          this.form.get('breed').setValue("muu");
          this.breedArr = [];
        }
      }
    }
    console.log(value);
  }


  fetchBreedByTerm(term){
    if(term != ""){
      var req = {
        term: term
      }
      this.http.post(environment.FETCH_BREED_BY_TERM, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200) {
            console.log(term, res.message);
            this.breedArr = res.message



            console.log(this.breedArr)
          }


        }, (err: any) => {
    
          console.log("Error while login :: ", err);
        })
      }
      else {
        this.breedArr = [];
      }
  }
  selectBreed(data){
    console.log(data);
    this.form.get('breed').setValue(data.breed);

    this.breedID = data.id;
    var elm = document.getElementById('close-breed-modal');
    elm.click();
  }

  logOut() {
    console.log("Request to logout");
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }

  setStatus(){

    var veteva = <HTMLInputElement>document.getElementById('veteva_terms');
    var musti = <HTMLInputElement>document.getElementById('musti_terms');
    var mustiPP =<HTMLInputElement>document.getElementById('musti_terms_pp');
    
    if(veteva.checked == false || musti.checked == false || mustiPP.checked == false){
      this.consentErr = true;
      document.getElementById('consent-modal').scrollTo(0,0);
      return;
    }

    var elm = document.getElementById('add-pet-form');
   document.getElementById('loader').style.display="block"; 


    this.http.get(environment.ACCEPT_MUSTI_TERMS_POPUP, this.httpOptions).subscribe(
      (res: any) => {

      if(res.status == 200) {
        elm.classList.remove("reg-form-success");
        document.getElementById('loader').style.display="none"; 
        document.getElementById('close-terms-modal').click();
        this.onSubmit();
        
      }
      else{
        // Ei toiminut
        elm.classList.remove("reg-form-success");
        document.getElementById('loader').style.display="none"; 
      }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }


}
