import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute,Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";



const helper = new JwtHelperService;

@Component({
  selector: 'app-docheader',
  templateUrl: './docheader.component.html',
  styleUrls: ['./docheader.component.css']
})
export class DocheaderComponent implements OnInit {
  message: string;
  currentUserId='';
  currentUserName ="";
  pets: any[];
  authToken = localStorage.getItem('auth');
  routeID: string = this.route.snapshot.routeConfig.path;
  isAdmin = false;
  clinicID = this.authService.getTokenData().clinicID;
  logo;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };


  constructor(
    private http: HttpClient,
    private authService: AuthService, 
    private router: Router,
    private route: ActivatedRoute,
  ){ }

  ngOnInit() {
    this.validate();
    this.myId();
    this.ActiveLink();
    console.log(this.routeID);
  }
  validate(){
    let req = {
      thing: 'sending'
    };

  this.http.post(environment.VALIDATE_DOCTOR_END_POINT, req, this.httpOptions).subscribe(
    (res: any) => {
      console.log('vastaus',res);
      if(res.status != 200){
        //this.logOut();
      }

    }, (err: any) => {
      //this.logOut();
      console.log("Error validating user :: ", err);
    })
}
logOut() {
  console.log("Request to logout");
  this.authService.doLogOut();
  this.router.navigate(['login']);
}
myId(){
  var token = localStorage.getItem('auth');
  var decodedToken = helper.decodeToken(token);

  this.currentUserId = decodedToken.result.userID;
  this.currentUserName = decodedToken.result.email;
  this.isAdmin = decodedToken.result.isAdmin;
  console.log(decodedToken.result);
  
}

logOutt() {
  console.log("Request to logout", this.currentUserId);
  let req = {
    userID: this.currentUserId
  };

  this.http.post(environment.DOC_SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
    (res: any) => {
      console.log('vastaus',res);
      if(res.status == 200){
        
        //this.router.navigate(['doctor-login']);
        console.log("log out success");
      }

    }, (err: any) => {
      
      console.log("Error validating user :: ", err);
    })
    this.authService.doLogOut();

}
ActiveLink() {

  var obj = JSON.parse(localStorage.getItem('colors'))
  console.warn(obj.mainColor);
  this.logo = obj.logoUrl;

  document.getElementById('main-nav').style.backgroundColor = obj.mainColor;
  console.log(document.getElementById('main-nav'))

  switch(this.routeID){
    case "doctorhome": {
      var elm = document.getElementById("doctorhome");
      this.setActiveLink(elm);
      break;
    }
    case "dashboard": {
      var elm = document.getElementById("dashboard");
      this.setActiveLink(elm);
      break;
    }
    case "users-register": {
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "visits-register": {
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "visits-register": {
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "pets-register": {
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "doctors-register": {
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "vet/doctor-detail/:id": {
      var elm = document.getElementById("rekisteri-page");
      if(elm){
        this.setActiveLink(elm);

      }
      break;
    }
    case "clinic-settings": {
      if(this.isAdmin){
        var elm = document.getElementById("clinic-settings");
        this.setActiveLink(elm);
        break;
      }
    }
    case "vet/user-detail/:id":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "vet/visit-detail/:id":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "vet/pet-detail/:id":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "feedback-register":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "payments-register":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "queue-time-register":{
      var elm = document.getElementById("rekisteri-page");
      this.setActiveLink(elm);
      break;
    }
    case "vet/hashtag-settings/:id":{
      var elm = document.getElementById("hashtag");
      this.setActiveLink(elm);
      break;
    }
    case "doctor-chat-history":{
      var elm = document.getElementById("doctor-chat-history");
      this.setActiveLink(elm);
      break;
    }
    case "clinic-settings":{
      var elm = document.getElementById("clinic-settings");
      if(elm.classList){
        this.setActiveLink(elm);
      }
      
      break;
    }
  }
}

setActiveLink(elm){
  console.log(elm);
  elm.classList.add("active-nav-item");
}

}
