
<!--Navbar-->
<!-- <nav class="navbar navbar-expand-lg navbar-light pb-0 height">

  <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" target="_blank" href="https://mustijamirri.fi"><img width="42%" src="./../../assets/images/Musti_ja_Mirri_logo.png"> </a>


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#basicExampleNav"
    aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse" id="basicExampleNav">

    <ul class="navbar-nav ms-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/login">Kirjaudu
          <span class="sr-only">(current)</span>
        </a>
      </li>
      <li class="nav-item">
        <a target="_blank" href="https://www.mustijamirri.fi/tilaukset/yhteystietoni/" class="nav-link">Rekisteröidy kanta-asiakkaaksi</a>
      </li>




    </ul>


  </div>


</nav> -->
<nav id="main-nav" class="navbar navbar-expand-lg original-nav navbar-light pb-0 height">
  <div class="container-fluid">
    <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" routerLink="/{{this.clinicID}}/login"><img width="180" src="{{this.logo}}"> </a>

    <!-- Collapse button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav"
      aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line3"></span>
    </button>
    <div class="collapse navbar-collapse" id="basicExampleNav">

      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/{{clinicID}}/login">Kirjaudu
            <span class="sr-only">(current)</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/{{clinicID}}/register" class="nav-link">Rekisteröidy </a>
        </li>
  
  
  
  
      </ul>
  
  
    </div>
  </div>
</nav>

