import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {

  status: string = this.route.snapshot.params.status;
  clinicID: string = this.route.snapshot.params.clinicID;


  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private route: ActivatedRoute,
    private authService: AuthService,
    private colorService: ColorServiceService) { 
      this.authService.validateClinic(this.clinicID)
    }

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };


  ngOnInit(): void {
    this.fetchVisuals();
  }

  fetchVisuals(){
    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();
          


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

}
