<app-admin-header></app-admin-header>

<div class="wrapperi pb-4 mb-4">
    <div class="container pb-4">

    <div class="col-12 row p-0 m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0">
      <div style="display: block;" id="register-form" >
            <form class="flex-row add-pet-form mt-4" [formGroup]="form"
        (ngSubmit)="onSubmit()">
        <!-- Klinikan nimi -->
        <h1>Klinikan tiedot</h1>
        <div class="form-group">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                <input  type="text" placeholder="Klinikan nimi" formControlName="clinicName" id="clinicName" class="form-control" [class.invalid-input]="clinicName.errors && clinicName.touched" required>
                <div class="my-alerts" *ngIf="clinicName.invalid && clinicName.touched">
                    <p class="mt-2" *ngIf="clinicName.errors.required">clinicName on pakollinen</p>
                    <p *ngIf="clinicName.errors.clinicName"> Vaaditaan kunnollinen clinicName</p>
                </div>
                </div>
            </div>
        </div>
        <!-- Klinikan ID -->
        <div class="form-group" title="Klinikan tunnistetta (id:tä) ei voi muuttaa">
            <div class=" col-12">
                <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                <input disabled type="number" placeholder="Klinikan ID" formControlName="clinicID" id="clinicID" class="form-control" [class.invalid-input]="clinicID.errors && clinicID.touched" required>
                <div class="my-alerts" *ngIf="clinicID.invalid && clinicID.touched">
                    <p class="mt-2" *ngIf="clinicID.errors.required">clinicID on pakollinen</p>
                    <p *ngIf="clinicID.errors.clinicID"> Vaaditaan kunnollinen clinicID</p>
                </div>
                </div>
            </div>
        </div>
        <!-- KKlinikan logo -->
          <div class="form-group">
              <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                  <input  type="text" placeholder="Klinikan logon url" formControlName="logoUrl" id="logoUrl" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
                  <div class="my-alerts" *ngIf="logoUrl.invalid && logoUrl.touched">
                      <p class="mt-2" *ngIf="logoUrl.errors.required">logoUrl on pakollinen</p>
                      <p *ngIf="logoUrl.errors.logoUrl"> Vaaditaan kunnollinen logoUrl</p>
                  </div>
                  </div>
              </div>
          </div>
            <!-- Klinikan pääväri -->
              <div class="form-group">
                <div class=" col-12">
                    <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                    <input  type="color" placeholder="Klinikan logon url" formControlName="mainColor" id="mainColor" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
                    <div class="my-alerts" *ngIf="mainColor.invalid && mainColor.touched">
                        <p class="mt-2" *ngIf="mainColor.errors.required">mainColor on pakollinen</p>
                        <p *ngIf="mainColor.errors.mainColor"> Vaaditaan kunnollinen mainColor</p>
                    </div>
                    </div>
                </div>
            </div>
          <!-- Klinikan toissijainen väri -->
          <div class="form-group">
              <div class=" col-12">
                  <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                  <input  type="color" placeholder="Klinikan logon url" formControlName="secondaryColor" id="secondaryColor" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
                  <div class="my-alerts" *ngIf="secondaryColor.invalid && secondaryColor.touched">
                      <p class="mt-2" *ngIf="secondaryColor.errors.required">secondaryColor on pakollinen</p>
                      <p *ngIf="secondaryColor.errors.secondaryColor"> Vaaditaan kunnollinen mainColor</p>
                  </div>
                  </div>
              </div>
          </div>
          <!-- Sähköpostiosoite -->
          <div class="form-group">
            <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                <input  type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                <div class="my-alerts" *ngIf="email.invalid && email.touched">
                  <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
                  <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Puhelinnumero -->
          <div class="form-group">
            <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 p-0">
                <input  type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
                  <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                  <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
                </div>
              </div>
            </div>
          </div>
            <!-- OVT -->
            <div class="form-group">
                <div class=" col-12">
                    <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  type="text" placeholder="OVT-tunnus" formControlName="ovt" id="ovt" class="form-control" [class.invalid-input]="ovt.errors && ovt.touched" required>
                    <div class="my-alerts" *ngIf="ovt.invalid && ovt.touched">
                        <p class="mt-2" *ngIf="ovt.errors.required">OVT on pakollinen</p>
                        <p *ngIf="ovt.errors.ovt"> Vaaditaan määrätty OVT</p>
                    </div>
                    </div>
                </div>
            </div>
            <!-- Operaattori -->
            <div class="form-group">
                <div class=" col-12">
                    <div class="form-group mb-2 flex-column col-12 p-0">
                    <input  type="text" placeholder="Operaattori" formControlName="operator" id="operator" class="form-control" [class.invalid-input]="operator.errors && operator.touched" required>
                    <div class="my-alerts" *ngIf="operator.invalid && operator.touched">
                        <p class="mt-2" *ngIf="operator.errors.required">operator on pakollinen</p>
                        <p *ngIf="operator.errors.operator"> Vaaditaan määrätty operator</p>
                    </div>
                    </div>
                </div>
            </div>
                    
                <button [disabled]="form.invalid" class=" login-btn btn-veteva-secondary">Muokkaa klinikkaa</button>
    
              </form>
      </div>
      <div class="col-3"></div>
        </div>
    
</div>
</div>