import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy,HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DoctorhomeComponent } from './doctorhome/doctorhome.component';
import { RegisterComponent } from './register/register.component';
import { DoctorRegisterComponent } from './doctor-register/doctor-register.component';
import { DoctorLoginComponent } from './doctor-login/doctor-login.component';
import { AddPetComponent } from './add-pet/add-pet.component';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { EditPetComponent } from './edit-pet/edit-pet.component';
import { ChathistoryComponent } from './chathistory/chathistory.component';
import { HeaderComponent } from './header/header.component';
import { DocheaderComponent } from './docheader/docheader.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetComponent } from './reset/reset.component';
import { ClinicSettingsComponent } from './clinic-settings/clinic-settings.component';
import { AddCheckupComponent } from './add-checkup/add-checkup.component';
import { EditUserDetailsComponent } from './edit-user-details/edit-user-details.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { PetRegisterComponent } from './pet-register/pet-register.component';
import { VisitRegisterComponent } from './visit-register/visit-register.component';
import { VisitDetailComponent } from './visit-detail/visit-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { VetPetDetailComponent } from './vet-pet-detail/vet-pet-detail.component';
import { DoctorsRegisterComponent } from './doctors-register/doctors-register.component';
import { DoctorDetailComponent } from './doctor-detail/doctor-detail.component';
import { UserVisitsComponent } from './user-visits/user-visits.component';
import { UserVisitDetailComponent } from './user-visit-detail/user-visit-detail.component';
import { NotAuthHeaderComponent } from './not-auth-header/not-auth-header.component';
import { AllPetsComponent } from './all-pets/all-pets.component';
import { HashtagSettingsComponent } from './hashtag-settings/hashtag-settings.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeVisitComponent } from './home-visit/home-visit.component';
import { OrganizationComponent } from './organization/organization.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';


import { ChatTimeIndicatorPipe } from './pipes/chat-time-indicator.pipe';
import { NewlineHtmlPipe } from './pipes/newline-html.pipe';
import { ProfilePicPipe } from './pipes/profile-pic.pipe';
import { CustomHttpInterceptor } from './interceptors/custom-http.interceptor';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SearchFilterFinalPipe } from './search-filter-final.pipe';
import { SearchFilterProcedurePipe } from './search-filter-procedure.pipe';
import { SearchFilterItemsPipe } from './search-filter-items.pipe';
import { SearchFilterLabsPipe } from './search-filter-labs.pipe';
import { SearchFilterBillingUserPipe } from './search-filter-billing-user.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ActivateDoctorComponent } from './activate-doctor/activate-doctor.component';
import { SearchAllPipe } from './search-all.pipe';
import { PformateDatePipe } from './pformate-date.pipe';
import { AcceptTermsOfServiceComponent } from './accept-terms-of-service/accept-terms-of-service.component';
import { VetSmsValidationComponent } from './vet-sms-validation/vet-sms-validation.component';
import { ChangeSpeciesPipe } from './change-species.pipe';
import { ChangeGenderPipe } from './change-gender.pipe';
import { SearchBreedPipe } from './search-breed.pipe';
import { GenerateLinkPipe } from './generate-link.pipe';
import { SearchHashtagPipe } from './search-hashtag.pipe';
import { QueueTimeRegisterComponent } from './queue-time-register/queue-time-register.component';
import { PaymentsRegisterComponent } from './payments-register/payments-register.component';
import { FeedbackRegisterComponent } from './feedback-register/feedback-register.component';
import { DoctorChatHistoryComponent } from './doctor-chat-history/doctor-chat-history.component';
import { StatisticsTodayComponent } from './statistics-today/statistics-today.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ModeratorLoginComponent } from './moderator-login/moderator-login.component';
import { AdminSmsValidationComponent } from './admin-sms-validation/admin-sms-validation.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AddClinicComponent } from './add-clinic/add-clinic.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminNotAuthHeaderComponent } from './admin-not-auth-header/admin-not-auth-header.component';
import { AdminPaymentsComponent } from './admin-payments/admin-payments.component';
import { AdminClinicsComponent } from './admin-clinics/admin-clinics.component';
import { AdminSingleClinicComponent } from './admin-single-clinic/admin-single-clinic.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DoctorAddPetComponent } from './doctor-add-pet/doctor-add-pet.component';
import { AddVisitComponent } from './add-visit/add-visit.component';
import { AdminGlobalSettingsComponent } from './admin-global-settings/admin-global-settings.component';
import { PublicPaymentComponent } from './public-payment/public-payment.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { ProductsRegisterComponent } from './products-register/products-register.component';
import { PublicVideoComponent } from './public-video/public-video.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ChatTimeIndicatorPipe,
    NewlineHtmlPipe,
    ProfilePicPipe,
    OrderByPipe,
    DoctorhomeComponent,
    RegisterComponent,
    DoctorRegisterComponent,
    DoctorLoginComponent,
    AddPetComponent,
    PatientDashboardComponent,
    EditPetComponent,
    HeaderComponent,
    ChathistoryComponent,
    DocheaderComponent,
    ForgotComponent,
    ResetComponent,
    ClinicSettingsComponent,
    AddCheckupComponent,
    EditUserDetailsComponent,
    UserRegisterComponent,
    PetRegisterComponent,
    VisitRegisterComponent,
    VisitDetailComponent,
    UserDetailComponent,
    VetPetDetailComponent,
    DoctorsRegisterComponent,
    DoctorDetailComponent,
    UserVisitsComponent,
    UserVisitDetailComponent,
    NotAuthHeaderComponent,
    AllPetsComponent,
    HashtagSettingsComponent,
    HomeVisitComponent,
    OrganizationComponent,
    ActivateUserComponent,
    SearchFilterPipe,
    SearchFilterFinalPipe,
    SearchFilterProcedurePipe,
    SearchFilterItemsPipe,
    SearchFilterLabsPipe,
    SearchFilterBillingUserPipe,
    TruncatePipe,
    CallbackComponent,
    ActivateDoctorComponent,
    SearchAllPipe,
    PformateDatePipe,
    AcceptTermsOfServiceComponent,
    VetSmsValidationComponent,
    ChangeSpeciesPipe,
    ChangeGenderPipe,
    SearchBreedPipe,
    GenerateLinkPipe,
    SearchHashtagPipe,
    QueueTimeRegisterComponent,
    PaymentsRegisterComponent,
    FeedbackRegisterComponent,
    DoctorChatHistoryComponent,
    StatisticsTodayComponent,
    QuestionAnswerComponent,
    ResetPasswordComponent,
    ModeratorLoginComponent,
    AdminSmsValidationComponent,
    AdminHomeComponent,
    AddClinicComponent,
    AdminHeaderComponent,
    AdminNotAuthHeaderComponent,
    AdminPaymentsComponent,
    AdminClinicsComponent,
    AdminSingleClinicComponent,
    AddUserComponent,
    DoctorAddPetComponent,
    AddVisitComponent,
    AdminGlobalSettingsComponent,
    PublicPaymentComponent,
    PaymentStatusComponent,
    ProductsRegisterComponent,
    PublicVideoComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    CustomHttpInterceptor,
    OrderByPipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
