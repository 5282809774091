import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css']
})
export class UserRegisterComponent implements OnInit, AfterViewInit {

  currentUserId='';
  users: any[];
  pets: any[];
  visits: any[];
  usersLen = 0;
  authToken = localStorage.getItem("auth");
  allUsers = [];
  clinicID = this.authService.getTokenData().clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
    ngOnInit() {
      this.myId();
    }
    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      console.log('iidee',decodedToken.result.id);
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
  
      }
      this.fetchAllUserData();
    }
  

    fetchAllUserData(){
  

      this.http.get(environment.DOC_FETCH_ALL_USER_DATA_END_POINT,this.httpOptions).subscribe(
        (res: any) => {
          this.users = res.message;
          this.allUsers = res.message;
          //PÄIVÄJÄRJESTYKSEEN 
          this.users = this.users.sort(function(a,b){
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });
          //PÄIVÄMÄÄRÄN FORMATOINTI
          this.colorService.waitStyleColor();

          this.usersLen = this.users.length;

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }

    routeToUserDetails(e){
      var userID = e.target.id;

      this.router.navigate(['/vet/user-detail/'+userID])
    }

    searchUsers(){
      var userInput = (<HTMLInputElement>document.getElementById('user-search')).value
      if(userInput.length >= 3){
        switch (userInput.toLocaleLowerCase()) {
          case 'normaali':
            userInput = "normal"
            break;
          case 'maksanut':
            userInput = "paid"
            break;
        
          default:userInput
            break;
        }
        this.http.get(environment.SEARCH_USERS+'/'+userInput,this.httpOptions).subscribe(
          (res: any) => {
            if(res.status == 200){
              this.users = res.message;
              this.colorService.waitStyleColor();

            }  
            else{
              alert('Hakuehdoilla ei löytynyt yhtään tulosta');
            }
  
          }, (err: any) => {
        
            console.log("Error while login :: ", err);
          })
      }
    }

    showData(){
      var userInput = (<HTMLInputElement>document.getElementById('user-search')).value

      if(userInput.length == 0){
        this.users = this.allUsers;
      }

    }


}
