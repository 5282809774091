<app-header></app-header>

<div class="header-page-container">

<h2 class=" pt-4 header-text">Usein kysyttyä </h2>
<p class="body2 text-center">Valitse kategoria johon kysymyksesi kuuluu</p>



<div class="row col-md-12 m-0">
<div class="col-md-1 d-flex"></div>
<div class="tab col-md-10 d-flex justify-content-center">
    <div class="col-md-2 no-mobile"></div>
    <button class="tablinks koti active col-md-2 body1b-b "  (click)="openCity($event, 'vet')">Eläinlääkäri</button>
    <button class="tablinks col-md-2 body1b-b " (click)="openCity($event,'payments')">Maksut</button>
    <button class="tablinks col-md-2 body1b-b " (click)="openCity($event,'messages')">Viestit</button>
    <button class="tablinks col-md-2 body1b-b " (click)="openCity($event,'others')">Muut</button>
    <div class="col-md-2 no-mobile"></div>
  </div>
  <div class="col-md-1 d-flex"></div>
</div>

</div>
<div class="row col-12">
    <div class="col-12 col-md-2"></div>
    <div class="col-12 col-md-8">
        <div style="display: block;" id="vet" class="tabcontent">
            <div class="col-md-12 row m-0 p-0">
                <div *ngFor="let q of vetq" class="accordion" id="accordionExample">
        
                    <div class="accordion-item mt-3">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          <p class="body1b-b">{{q.question}}</p>
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p class="body2b">{{q.text}}</p>
                            </div>
                      </div>
                    </div>
                
                  </div>
                </div>
            </div>
            <div style="display: none;" id="payments" class="tabcontent">
              <div class="col-md-12 row m-0 p-0">
                  <div *ngFor="let q of paymentsq;let i = index" class="accordion" id="accordionExample">
          
                      <div class="accordion-item mt-3">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="collapseOne">
                            <p class="body1b-b">{{q.question}}</p>
                          </button>
                        </h2>
                        <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                              <p class="body2b textarea-space">{{q.text}}</p>
                              </div>
                        </div>
                      </div>
                  
                    </div>
                  </div>
              </div>

                <div style="display: none;" id="messages" class="tabcontent">
                  <div class="col-md-12 row m-0 p-0">
                      <div *ngFor="let q of messagesq;let i = index" class="accordion" id="accordionExample">
              
                          <div class="accordion-item mt-3">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="collapseOne">
                                <p class="body1b-b">{{q.question}}</p>
                              </button>
                            </h2>
                            <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <p class="body2b textarea-space">{{q.text}}</p>
                                  </div>
                            </div>
                          </div>
                      
                        </div>
                      </div>
                  </div>

                <div style="display: none;" id="others" class="tabcontent">
                  <div class="col-md-12 row m-0 p-0">
                      <div *ngFor="let q of othersq;let i = index" class="accordion" id="accordionExample">
              
                          <div class="accordion-item mt-3">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="collapseOne">
                                <p class="body1b-b">{{q.question}}</p>
                              </button>
                            </h2>
                            <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                  <p class="body2b textarea-space">{{q.text}}</p>
                                  </div>
                            </div>
                          </div>
                      
                        </div>
                      </div>
                  </div>
        
    </div>
    <div class="col-12 col-md-2"></div>
</div>
