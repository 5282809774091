import { Injectable } from '@angular/core';
import { AuthService } from '../service/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ColorServiceService {

  constructor(
    private authService: AuthService
  ) { }

  setVetColors(){
    var obj = JSON.parse(localStorage.getItem('colors'))
    var mainColor = obj.mainColor;
    var secondaryColor = obj.secondaryColor;


    if(this.authService.getTokenData().designation != 'patient'){
      var btn_arr = document.getElementsByClassName('btn-veteva-secondary');
      
      if(btn_arr.length > 0){
        for (let i = 0; i < btn_arr.length; i++) {
          const btn = <HTMLElement>btn_arr[i];
          btn.style.backgroundColor = secondaryColor;
        }

      }

      var btn_arr_edit = document.getElementsByClassName('btn-veteva-secondary-edit');

      if(btn_arr_edit.length > 0){
        for (let i = 0; i < btn_arr_edit.length; i++) {
          const btn = <HTMLElement>btn_arr_edit[i];
          btn.style.backgroundColor = mainColor;
        }

      }

      var table_arr = document.getElementsByClassName('veteva-table-header');

      if(table_arr.length > 0){
        for (let i = 0; i < table_arr.length; i++) {
          const elm = <HTMLElement>table_arr[i];
          elm.style.backgroundColor = mainColor;
        }

      }

      var tab_arr = document.getElementsByClassName('active');

      if(tab_arr.length > 0){
        for (let i = 0; i < tab_arr.length; i++) {
          const elm = <HTMLElement>tab_arr[i];
          elm.style.borderBottomColor = secondaryColor;
        }

      }

      var tab_arr = document.getElementsByClassName('active');

      if(tab_arr.length > 0){
        for (let i = 0; i < tab_arr.length; i++) {
          const elm = <HTMLElement>tab_arr[i];
          elm.style.borderBottomColor = secondaryColor;
        }

      }

      var bubble_arr = document.getElementsByClassName('text-message-left');

      if(bubble_arr.length > 0){
        for (let i = 0; i < bubble_arr.length; i++) {
          const btn = <HTMLElement>bubble_arr[i];
          btn.style.backgroundColor = mainColor;
        }
  
      }

      var header_arr = document.getElementsByClassName('header-container');

      if(header_arr.length > 0){
        for (let i = 0; i < header_arr.length; i++) {
          const btn = <HTMLElement>header_arr[i];
          btn.style.backgroundColor = secondaryColor;
        }
  
      }

      var sendbtn = document.getElementsByClassName('send-btn-container');

      if(sendbtn.length > 0){
        for (let i = 0; i < sendbtn.length; i++) {
          const btn = <HTMLElement>sendbtn[i];
          btn.style.backgroundColor = secondaryColor;
        }
  
      }

      var image = document.getElementsByClassName('image-icon-wrapper');

      if(image.length > 0){
        for (let i = 0; i < image.length; i++) {
          const btn = <HTMLElement>image[i];
          btn.style.borderColor = secondaryColor;
        }
  
      }

      var icon_arr = document.getElementsByClassName('veteva-icon-main');

      if(icon_arr.length > 0){
        for (let i = 0; i < icon_arr.length; i++) {
          const btn = <HTMLElement>icon_arr[i];
          btn.style.color = secondaryColor;
        }
  
      }


      
    }
  }
  waitStyleColor() {
    var that = this;

    setTimeout(() =>{
      that.setVetColors();
    },10)

  }

  waitStyleColorUser() {
    var that = this;

    setTimeout(() =>{
      that.styleUser();
    },10)

  }

  waitStyleColorUserBubbles() {
    var that = this;

    setTimeout(() =>{
      that.styleUserChatBubbles();
    },10)

  }

  waitStyleColorVetBubbles() {
    var that = this;

    setTimeout(() =>{
      that.styleChatBubbles();
    },10)

  }
  styleChatBubbles(){
    var obj = JSON.parse(localStorage.getItem('colors'))
    var mainColor = obj.mainColor;
    var secondaryColor = obj.secondaryColor;

    var btn_arr = document.getElementsByClassName('text-message-left');

    if(btn_arr.length > 0){
      for (let i = 0; i < btn_arr.length; i++) {
        const btn = <HTMLElement>btn_arr[i];
        btn.style.backgroundColor = mainColor;
      }

    }
    var btns = document.getElementsByClassName('btn-veteva-secondary');

    if(btns.length > 0){
      for (let i = 0; i < btns.length; i++) {
        const btn = <HTMLElement>btns[i];
        btn.style.backgroundColor = secondaryColor;
      }

    }
    var r_arr = document.getElementsByClassName('round-wrapper');
    for (let i = 0; i < r_arr.length; i++) {
      const elm = <HTMLElement>r_arr[i];
      elm.style.backgroundColor = secondaryColor;
    }
  }

  styleUser(){



    var obj = JSON.parse(localStorage.getItem('colors'))
    var mainColor = obj.mainColor;
    var secondaryColor = obj.secondaryColor;

    var btn_arr = document.getElementsByClassName('btn-veteva-secondary');
    
    if(btn_arr.length > 0){
      for (let i = 0; i < btn_arr.length; i++) {
        const btn = <HTMLElement>btn_arr[i];
        btn.style.backgroundColor = secondaryColor;
      }

    }

    var btn_arr_edit = document.getElementsByClassName('btn-veteva-secondary-edit');

    if(btn_arr_edit.length > 0){
      for (let i = 0; i < btn_arr_edit.length; i++) {
        const btn = <HTMLElement>btn_arr_edit[i];
        btn.style.backgroundColor = mainColor;
      }

    }

    var link_style = document.getElementsByClassName('veteva-main-link');

    if(link_style.length > 0){
      for (let i = 0; i < link_style.length; i++) {
        const btn = <HTMLElement>link_style[i];
        btn.style.color = secondaryColor;
      }

    }

    var header_arr = document.getElementsByClassName('header-container');

    if(header_arr.length > 0){
      for (let i = 0; i < header_arr.length; i++) {
        const btn = <HTMLElement>header_arr[i];
        btn.style.backgroundColor = secondaryColor;
      }

    }

    var icon_arr = document.getElementsByClassName('veteva-icon-main');
    
    if(icon_arr.length > 0){
      for (let i = 0; i < icon_arr.length; i++) {
        const btn = <HTMLElement>icon_arr[i];
        btn.style.color = secondaryColor;
      }

    }

    var main_btn_arr = document.getElementsByClassName('btn-veteva-secondary');
    
    if(main_btn_arr.length > 0){
      for (let i = 0; i < main_btn_arr.length; i++) {
        const btn = <HTMLElement>main_btn_arr[i];
        btn.style.color = secondaryColor;
      }

    }

    var secondary_btn_arr = document.getElementsByClassName('btn-veteva-secondary-edit');
    
    if(secondary_btn_arr.length > 0){
      for (let i = 0; i < secondary_btn_arr.length; i++) {
        const btn = <HTMLElement>secondary_btn_arr[i];
        btn.style.color = mainColor;
      }

    }

    var tab_arr = document.getElementsByClassName('active');

    if(tab_arr.length > 0){
      for (let i = 0; i < tab_arr.length; i++) {
        const elm = <HTMLElement>tab_arr[i];
        elm.style.borderBottomColor = secondaryColor;
      }

    }

    var image = document.getElementsByClassName('image-icon-wrapper');

    if(image.length > 0){
      for (let i = 0; i < image.length; i++) {
        const btn = <HTMLElement>image[i];
        btn.style.borderColor = secondaryColor;
      }

    }

    var sendbtn = document.getElementsByClassName('send-btn-container');

    if(sendbtn.length > 0){
      for (let i = 0; i < sendbtn.length; i++) {
        const btn = <HTMLElement>sendbtn[i];
        btn.style.backgroundColor = secondaryColor;
      }

    }

    var table_arr = document.getElementsByClassName('veteva-table-header');
    console.log(table_arr, table_arr.length)
      for (let i = 0; i < table_arr.length; i++) {
        const elm = <HTMLElement>table_arr[i];
        elm.style.backgroundColor = mainColor;
        console.log(elm)
      }
  }
  styleLoader(){
    var obj = JSON.parse(localStorage.getItem('colors'))
    var mainColor = obj.mainColor;
    var secondaryColor = obj.secondaryColor;

    var loader = document.getElementsByClassName('loader');

    if(loader.length > 0){
      for (let i = 0; i < loader.length; i++) {
        const load = <HTMLElement>loader[i];
        load.style.borderTopColor = mainColor;
      }

    }
  }

  styleUserChatBubbles(){
    var obj = JSON.parse(localStorage.getItem('colors'))
    var mainColor = obj.mainColor;
    var secondaryColor = obj.secondaryColor;

    var btn_arr = document.getElementsByClassName('text-message-right');

    if(btn_arr.length > 0){
      for (let i = 0; i < btn_arr.length; i++) {
        const btn = <HTMLElement>btn_arr[i];
        btn.style.backgroundColor = mainColor;
      }

    }

    var main_btn_arr = document.getElementsByClassName('btn-veteva-secondary');
    
    if(main_btn_arr.length > 0){
      for (let i = 0; i < main_btn_arr.length; i++) {
        const btn = <HTMLElement>main_btn_arr[i];
        btn.style.backgroundColor = secondaryColor;
      }

    }

    var chekbox_arr = document.getElementsByClassName('sq-lable');
    
    if(chekbox_arr.length > 0){
      for (let i = 0; i < chekbox_arr.length; i++) {
        const btn = <HTMLElement>chekbox_arr[i];
        btn.style.backgroundColor = mainColor;
      }

    }


  }
}
