import { Injectable, EventEmitter } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {


  private socket$: WebSocketSubject<any>;
  public messages: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  connect() {


    console.log('Tehty eka', this.messages)

    if(this.messages == undefined){
      this.messages = new EventEmitter<string>();
    }
    console.log('Tehty toka', this.messages);
    
    console.log("Request to connect to web socket");
    this.socket$ = new WebSocketSubject<any>({
      url: environment.WS_END_POINT,
      deserializer: function (e) {
        console.log(e)
        return e.data;
      }
    });
    this.socket$
      .subscribe(
        (message) => {
          console.log("Message received ", message);
          this.messages.next(message);
        },
        (err) => {
          console.log(err);
          var obj = {
            task: 'CONNECTION_CLOSED',
          }
    
          this.messages.next(JSON.stringify(obj));
        }
           ,
        () => console.warn('Completed!')
      );
    

  }

  sendMessage(message) {
    if(this.socket$){
      this.socket$.next(message);
    }
  }

  disconnect() {
    console.log("Request to disconnect to web socket");
    console.log(this.socket$);
    if(this.socket$ != undefined) {
      var obj = {
        task: 'CONNECTION_CLOSED',
        message: 'Successfully disconnected'
      }
      this.messages.next(JSON.stringify(obj));
      delete this.messages
      console.log('Tehty', this.socket$);
      this.socket$.complete();
      this.socket$.unsubscribe();
      console.log('compleetattu',this.messages)

    }

  }
  


}
