import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-vet-pet-detail',
  templateUrl: './vet-pet-detail.component.html',
  styleUrls: ['./vet-pet-detail.component.css']
})
export class VetPetDetailComponent implements OnInit, AfterViewInit {

currentUserId='';
  patientID: string = this.route.snapshot.params.id;
  users: any = [];
  pets: any = [];
  visits: any = [];
  date="";
  diagnosis="";
  petsName="";
  petName="";
  petsOwnerId="";
  userFirstName = "";
  userLastName=""
  authToken = localStorage.getItem("auth");
  clinicID = this.authService.getTokenData().clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
    ngOnInit() {
      this.myId();
    }

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      console.log('iidee',decodedToken.result.id);
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
        this.logOutt();
  
      }
      
      this.fetchPetData();
      this.fetchRegisterDataForPet();
    }
  
    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('vastaus',res);
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }
  
  
     fetchUserData(owner){
  
      var req = {
        patientID:owner
      }
  
      this.http.post(environment.DOC_PMS_USER_DATA_END_POINT, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200) {
            this.users = res.message[0];

            this.userFirstName = res.message[0][0].firstName
            this.userLastName = res.message[0][0].lastName
            this.colorService.waitStyleColor();
        }
        else {
          this.users = [];
        }
        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    } 

    fetchPetData(){
  
      var req = {
        patientID:this.patientID
      }
  
      this.http.post(environment.DOC_PMS_PET_DATA_FOR_PET_END_POINT, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if(res.status == 200) {
          this.pets = res.message;

          this.petName = res.message[0].name;
          this.petsOwnerId = res.message[0].owner;
          this.fetchUserData(this.petsOwnerId);
        }
        else {
          //lemmikki poistettu
          this.pets = [];
        }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }
    
     fetchRegisterDataForPet(){
      var req = {
        patientID:this.patientID
      }

    this.http.post(environment.FETCH_VISIT_FOR_PET_END_POINT,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);

        if(res.status == 200) {
        this.visits = res.message;

      //PÄIVÄJÄRJESTYKSEEN 
      this.visits = this.visits.sort(function(a,b){
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
      });

      this.colorService.waitStyleColor();
        console.log(this.visits)
    }
    else {
      this.visits = [];
      this.colorService.waitStyleColor();
    }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  } 

  

}
