<app-header></app-header>


  <div class="header-page-container pb-2">
    <h2 class=" pt-4 d-none d-md-block pb-4 header-text text-center">Profiili </h2>
    <div class="col-12 first-row d-block d-md-none">
      <div class="btn-wrapper d-flex justify-content-between pb-3 ps-3 pe-3">
        <button class="btn btn-veteva-secondary-edit mb-2 no-print" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
        <button class="btn btn-veteva-secondary mb-2 ms-4 no-print" (click)="printPage()"><i class="fas fa-print pe-2"></i>Tulosta</button>
      </div>
    </div>
    <div class="d-block d-md-none">
      <div class="col-12 row m-0">
        <div class="col-12 p-0">
          <p *ngIf="visits" class="body2b-date mb-2 mt-2 ms-3">{{visits[0].createdDate | pformateDate}}</p>
        </div>
      </div>
      <div class="col-12 pe-0 row m-0">
        <div class="col-2">
          <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">

        </div>
      
      <div class=" col-9 pe-0  d-flex flex-column">
        <div class="p-0">
          
          <p *ngIf="visits" class=" mb-0 petname-text body2b-b"> {{ visits[0].petName }}</p>
        </div>
        <div class=" p-0">
          <p *ngIf="visits" class=" body2bb">{{visits[0].petBreed}}</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div class="col-12 row me-0 ms-0 mt-4 ps-3 pe-3">
    <div class="col-12 col-md-3"></div>
    <div class="col-12 p-0 col-md-6">
      <div class="d-none d-md-block">
        <div class="btn-wrapper d-flex justify-content-between pb-3">
          <button class="btn btn-veteva-secondary-edit mb-2 no-print" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
          <button class="btn btn-veteva-secondary mb-2 ms-4 no-print" (click)="printPage()"><i class="fas fa-print pe-2"></i>Tulosta</button>
        </div>
      </div>
      <div class="col-12 p-0">
      <div class="col-12 p-0 pt-2 pb-4">
        <div class="b-wrap">
          <p class="body2 mb-1">Eläinlääkäri</p>
          <p *ngIf="visits" class="body2b-b">{{visits[0].doctorFullName}}</p>
        </div>
        <p class="body2 mb-0 pt-3">Diagnoosit</p>
        <ul class="list-group dif-diag" id="menu">
        <li class="list-group-item list-group-item-link pe-0 pt-0 pb-3 ps-0 ">
          <div class="list-wrap-closed d-inline-flex col-12 pe-0">
            <div class="col-11 p-0 row">
                  <p class=" mb-0 petname-text body2b-b">Vaihtoehtoinen diagnoosi</p>
            </div>
            <div class="col-1 p-0 d-flex justify-content-end ">
              <i class=" fas fa-angle-down light-gray-icon expand-icon"  id="expand-icon-{{visitID}}" (click)="openExpandBox($event)"></i>
              <i class=" fas fa-angle-up light-gray-icon close-icon poista" style="display: none;" id="close-icon-{{visitID}}" (click)="closeExpandBox($event)"></i>
            </div>
           
          </div>
      </li>
      <ul class="list-group list-group-flush list p-0" id="{{visitID}}" >
        <li id="info-screen-visits-{{visitID}}" class="p-0 list-group-item list-group-item-link list-group-show pointer">
           <p class="body2">{{diffDiagnosis}}</p>
        </li>

         <li class="list-group-item list-group-item-link list-group-show">
          <div class="">
              </div>
         </li>
      </ul>
    </ul>
    <ul class="list-group dif-diag" id="menu">
      <li class="list-group-item list-group-item-link pe-0 ps-0 pt-0 pb-3 ">
        <div class="list-wrap-closed d-inline-flex col-12 pe-0  pt-3">
          <div class="col-11 p-0 row">
                <p class=" mb-0 petname-text body2b-b">Lopullinen diagnoosi</p>
          </div>
          <div *ngIf="visits" class="col-1 p-0 d-flex justify-content-end ">
            <i class=" fas fa-angle-down light-gray-icon  expand-icon"  id="expand-icon-{{visits[0].petsID}}" (click)="openExpandBox2($event)"></i>
            <i class=" fas fa-angle-up light-gray-icon  close-icon poista" style="display: none;" id="close-icon-{{visits[0].petsID}}" (click)="closeExpandBox2($event)"></i>
          </div>
         
        </div>
    </li>
    <ul *ngIf="visits" class="list-group list-group-flush list p-0" id="{{visits[0].petsID}}" >
      <li id="info-screen-visits-{{visits[0].petsID}}" class="p-0 list-group-item list-group-item-link list-group-show pointer">
         <p class="body2">{{finalDiagosis}}</p>
      </li>

       <li class="list-group-item list-group-item-link list-group-show">
       </li>
    </ul>
  </ul>

    <p class="body2b-b pt-3 mb-1">Hoito-ohje</p>
    <div class="brake body2  mb-4" id="my-tag"></div>
      </div>
    </div>
  <div class="col-3"></div>
  </div>
  </div>
  

