import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-public-payment',
  templateUrl: './public-payment.component.html',
  styleUrls: ['./public-payment.component.css']
})
export class PublicPaymentComponent implements OnInit {

  clinicID: string = this.route.snapshot.params.clinicID;
  productID: string = this.route.snapshot.params.productID;
  doctorID: string = this.route.snapshot.params.doctorID;
  product;
  selectedMethod;
  paymentMethods = [];
  allMethods = [];
  paymentForm: FormGroup;


  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private route: ActivatedRoute,
    private authService: AuthService,
    private colorService: ColorServiceService) { 
      this.authService.validateClinic(this.clinicID)
    }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  ngOnInit(): void {

    this.paymentForm = this.fb.group({
      email: ['', [
 
        Validators.required,
        Validators.email, 

      ]],
      privacy: ["",Validators.required],
      marketing_allowed: [""]
    })

    this.fetchProductDetails();
    this.fetchVisuals();
    this.fetchPaymentMethods();
    this.addBlinker()
  }

  addBlinker(){
    var elm = document.getElementById('email');

    elm.classList.add('blink_me');
  }

  clearBlinker(){
    var email = document.getElementById('email');
    var emailvalue = (<HTMLInputElement>document.getElementById('email')).value;

    if(emailvalue.length == 0){
      if(email.classList.contains('blink_me')){

      }
      else{
        email.classList.add('blink_me');
      }
    }
    else{
      if(email.classList.contains('blink_me')){
          email.classList.remove('blink_me')
      }
    }
  }

  fetchVisuals(){
    var req = {
      clinicID: this.clinicID,
    }
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();
          


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

  fetchProductDetails(){
    var req = {
      clinicID: this.clinicID,
      productID: this.productID,
    }
    this.http.post(environment.OPEN_PRODUCT_ENDPOINT, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          this.product = res.message[0];
        }
        else{
          alert('Virheellinen linkki pyydä eläinlääkäriä lähettämään uusi');
        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

  fetchPaymentMethods(){

    this.http.get(environment.GET_PAYMET_METHODS, this.httpOptions).subscribe(
      (res: any) => {
        
          this.paymentMethods = res; 
          this.allMethods = res;      
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

  get email() { return this.paymentForm.get('email') };
  get privacy() { return this.paymentForm.get('privacy') };
  get marketing_allowed() { return this.paymentForm.get('marketing_allowed') };
  onSubmit(){


    var req = {
      email: this.paymentForm.get('email').value,
      marketing_allowed: this.paymentForm.get('marketing_allowed').value,
      doctorID: this.doctorID,
      clinicID: this.clinicID,
      productName: this.product['name'],
      productID: this.productID,
      bankName: this.selectedMethod['selected_value'],
      
    }

    this.http.post(environment.GET_PUBLIC_PAYMENT, req,this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200){
          var str_url = JSON.parse(res.message);
          var url = str_url.name;
          window.location.href = url;
        } 
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })

  }

  selectPaymentMethod(method){
    var elements = document.getElementsByClassName('payment-row');
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if(element.classList.contains('payment-row-active')){
        element.classList.remove('payment-row-active');
      
      }
      
    }
    this.selectedMethod = method;
    document.getElementById('method-'+method.name).classList.add('payment-row-active');
    this.paymentMethods = [method];
  }

  showAllPaymentMethods(){
    this.paymentMethods = this.allMethods;
  }








}
