import { Component, OnInit, OnDestroy, ViewChild, AfterContentInit, AfterViewInit, ElementRef, AfterViewChecked } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { WebsocketService } from '../service/websocket.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { JwtHelperService } from "@auth0/angular-jwt";
import { connect,createLocalVideoTrack,RemoteTrackPublication,RemoteDataTrack, RemoteParticipant } from 'twilio-video';
import { ColorServiceService } from '../service/color-service.service';
import { FileUploaderService } from '../service/file-uploader.service';


declare var $:any;

const helper = new JwtHelperService;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit,AfterViewChecked {

  doctorList: any = []; // Doctor list
  globalMessages: any = []; // Map of doctor and message
  currentActiveMessageList: any = []; // Current Acitve message list
  defaultDocterName = 'patient';
  bamboraPaymentMethods: any = []; // bambora maksutavat
  currentUserRole ='';//nykyisen käyttäjän rooli
  currentUserId=''; 
  currentUserName = ''; // nykyisen käyttäjän käyttäjänimi
  currentUserCredit= 0; // alku credit oletus --> tämä tarkastetaan CheckMyCredit func, kutsuttu nginit
  lastMessage=''; //viimeisin toisen käyttäjän lähettämä viesti
  listUserRole=''; //Listassa olevan käyttäjän rooli
  queueList = [];
  pets=[];
  activeDoctors:any=[];
  packagePrice;
  movedToQueue=false;
  buyPackage:any = {}
  feePackages:any = [];
  openTimes: any = [];
  bamboraPaymentUrl = "";
  clinicOpen:any = [];
  imgOutput = "";
  connErr = false;
  today;
  sessionStatus = "";
  activeConsultantData = {};
  reasonsArr=[];
  visitHistory: any = [];
  currentActiveDoctorName: string = "Autan alkuun"; // Current Acitve message list
  authToken = localStorage.getItem('auth');
  showReplyBox: boolean = false;
  HELPER_NAME = 'Autan alkuun'
  DR_ASSISTANT_NAME: string = '';
  anList = document.getElementById('kotielaimet');
  packageDescription: any;
  packageCredit:any;
  clinicStatus = false;
  discountSuccess = false;
  typingHandler = true;
  muted = false;
  videoShow = true;
  discountCode: any = {};
  rdCode = Math.floor(100000 + Math.random() * 900000);
  todayIdx;
  roomData;
  videoToken;
  userVideoDeviceWidth;
  currentRoom;
  localTrack;
  cameraOptions;
  selectedCamera;
  videoAiring = false;
  customerNPSRange = -1;
  petDataErr = [];
  clinicID: string = this.authService.getTokenData().clinicID;
  clinicName: string = this.authService.getClinicData().clinicName;
  fileURL = environment.FETCH_ATTACHMENT;
  attachmentObj;
  userDetailErr = [];
  userDetails = [];
  enlargerImageKey;

  currentTemplate = "";

  clicked = false;
  checkCreatedMsg="";
  discountErr= false;

  currentProduct: any = {};

  infoToPetScreen:any = [];

  myPaymentBtn = <HTMLInputElement>(document.getElementById('myBamboraPaymentBtn'));;

  messageSuccess="";
  messageErr="";

  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  replyMessage: string = '';

  addFeedback: FormGroup;


  @ViewChild("chatMessageContainer", { static: true }) chatMessageContainer: ElementRef;
  @ViewChild('openModal', { static: true }) openModal:ElementRef;
  @ViewChild('product-info-modal') productInfoModal:ElementRef;
  @ViewChild('start-consultation-modal') startConsultationModal:ElementRef;
  @ViewChild("discountCodeInput") discountCodeInput: ElementRef;

  disableScrollDown: boolean = false;
  bamboraPaymentButtonVisible: boolean = false;
  finalAmount: number = 1;
  selectedMessage;


  constructor(
     private router: Router,
     private authService: AuthService, 
     private webSocketService: WebsocketService, 
     private http: HttpClient, 
     private fb: FormBuilder,
     private route: ActivatedRoute,
     private colorService: ColorServiceService,
     private fileUploaderService: FileUploaderService,
     private orderByPipe: OrderByPipe) {
    this.authService.validateLogin();
    this.authService.validateClinic(this.clinicID);
  }

  ngOnInit() {
    this.colorService.styleUser();
    this.getAllCameras();
    this.LoadMessageList();
    this.drAssitantToId(); // 1
    this.tick(); // ei väliä
    this.webSocketService.connect(); //2
    this.InitWebSocketMessageReceiver(); // 3
    this.myId();
    this.CheckMyCredit();
    this.getReasons();
    this.fetchConsultationPackages();
    this.fetchUserDetails();


    this.addFeedback = this.fb.group({
      npsRange: "",
      npsText: "",
      acceptedContact: "",
    });
    }

  ngOnDestroy() {
    this.webSocketService.disconnect();
  }
  ngAfterContentInit(){
    this.waitSession()
  }

  openOpenHours(){
    document.getElementById('open-hour-list').style.display = "block";
    document.getElementById('close-open-hours').style.display = "block";
    document.getElementById('show-open-hours').style.display = "none";
  }
  closeOpenHours(){
    document.getElementById('open-hour-list').style.display = "none";
    document.getElementById('close-open-hours').style.display = "none";
    document.getElementById('show-open-hours').style.display = "block";
  }

  startScreen(){
    if(this.activeDoctors.length > 0) {
      if(this.pets.length > 0) {
        var arr = ['docs-helping', 'active-docs','open-hours','clinic-price','pet-added','geeli'];
      }
      else{
        var arr = ['docs-helping', 'active-docs','open-hours','clinic-price','start-create-pet','geeli'];
      }
    }
    else {
      if(this.pets.length > 0){
      var arr = ['open-hours','pet-added'];
      }
      else {
        var arr = ['open-hours','start-create-pet'];
      }
    }
    var i = -1;
    var that = this;     
    function myLoop() {        
      setTimeout(function() {     
        i++;                    
        if (i < arr.length) {
          if(document.getElementById(arr[i]) != null || document.getElementById(arr[i]) != undefined) {
          document.getElementById(arr[i]).classList.add('open-bubbles');
          document.getElementById(arr[i]).classList.remove('close-bubbles');
          that.scrollBottomOfChatContainer();
          that.colorService.styleUser();
          }      
          myLoop();              
        }                       
      }, 900)
    }
    
    myLoop();
  }

  UserTypingMessage() {


    var that = this;
    if(this.typingHandler == true) {
      that.typingHandler = false;
      that.webSocketService.sendMessage({
        task: 'USER_TYPING_MESSAGE',
        to: that.currentActiveDoctorName,
        from: that.currentUserId
      });
      setTimeout(function () {
        that.typingHandler = true;
      }, 2000);
    }
  }



  InitWebSocketMessageReceiver() {
    this.webSocketService.messages.subscribe(
      (message: any) => {
        try {
          console.log(message);
          let messageJson = JSON.parse(message);
          this.messageHandler(messageJson);
        } catch (e) {
          console.log(e);
        }


      }, (err: any) => {
        console.log("Error while getting new message ", err);
      })
  }

  openMultipleWindows(who){
    document.getElementById('multiple-windows-modal').click();
    throw new Error("Lopetetaan lataaminen");
    return false;
  }
  closeWindow(){
    window.location.reload()
  }

  connectDisconnectedConnection() {
    this.webSocketService.disconnect();
    this.LoadMessageList();
    this.webSocketService.connect();
    
    this.connErr = false;
  }
  

//----------------------------------------------------------------------------------------------------------
// Get all pets
//----------------------------------------------------------------------------------------------------------

getPets(){

  
  var req = {
    id: this.currentUserId
  }
  this.http.post(environment.FIND_PET_END_POINT, req, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == "Failed"){
        this.pets = [];
        this.openModal.nativeElement.click();
        this.colorService.waitStyleColorUser();
      }
      else {
      this.pets = res;
      this.colorService.waitStyleColorUser();
      }
    }, (err: any) => {
  
      console.log("Error while login :: ", err);
    })
}


  fetchNewMessageForUser(userName) {
    if(userName == "Dr. Assistant"){
      userName = this.currentActiveDoctorName;
    }
    if(userName.name != this.HELPER_NAME || userName.name != this.DR_ASSISTANT_NAME){
    var lastMessageId = 0;
    if((this.currentActiveDoctorName == this.DR_ASSISTANT_NAME || this.currentActiveDoctorName == this.HELPER_NAME )){
      this.showReplyBox = false;
    }
    else{
      this.showReplyBox = true;
    }

    if (this.globalMessages[userName] != null) {
      lastMessageId = this.globalMessages[userName].messageList[this.globalMessages[userName].messageList.length - 1].id;
      
    }

    //MUUTOS KOSKA LATASI JOKA KERTA
     if(lastMessageId == 0){
     this.LoadMessageList();
      return;
    }  
    
    let reqBody = {
      lastMessageId: lastMessageId,
      forUserName: userName
    };


    this.http.post(environment.MESSAGE_LIST_BY_USER_END_POINT, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        this.colorService.styleUserChatBubbles();
        this.clicked = false;

        if(res[0] != undefined){

          //Tarkastetaan löytyykö linkkejä viesteistä
          if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME && res[0].template == "TEMPLATE_14"){
          res[0].actMessage = this.replaceURLWithHTMLLinks(res[0].actMessage);
          }
        
        }
        if (res.length == 0) {
          return;
        }

        if (this.globalMessages[userName] == null) {
          this.globalMessages[userName] = {
            messageList: res

          };


          
        } else {

          

          if (userName == this.DR_ASSISTANT_NAME) {
            var localMessageList = this.globalMessages[userName].messageList;

            for (var i = localMessageList.length - 1; i >= 0; i--) {
              var localMessage = localMessageList[i];
              if (localMessage.oldMessage === true) {
                break;
              }
              localMessage.oldMessage = true;
            }
            
          }
          for (var i = 0; i < res.length; i++) {
            this.globalMessages[userName].messageList.push(res[i]);
            
            this.currentTemplate = res[i].template;

            if(i+1 == res.length){
              this.waitScrollSm();
              //Tarkastetaan että jos feedback tai ell lopetti keskustelun
              if(this.currentTemplate == 'TEMPLATE_19' || this.currentTemplate == 'TEMPLATE_15' ||this.currentActiveDoctorName == this.DR_ASSISTANT_NAME ){
                this.showReplyBox = false;
              }
              else{
                this.showReplyBox = true
              }
              if(this.currentTemplate == 'TEMPLATE_17'){
                this.UserToQueue();
              }
            }
          }
          this.colorService.waitStyleColorUserBubbles();
          this.closeSendingModal();

        }

      }, (err: any) => {
        console.log("Error occured while finding extra data from server", err);
      })
    }
  }

  replaceURLWithHTMLLinks(text)
  {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(exp,'<a class="my-link"  target="_blank" href="$1">$1</a>'); 


  }

  setMaxToCalendar(){
    var d = new Date();
    var month = d.getMonth()+1;
    var day = d.getDate();
    var y = d.getFullYear();

    var a = month.toString();
    var b = day.toString();
    var c = y.toString();

    var full = c + '-' +a+'-'+b;

    this.today = full;
  }

  fetchActiveConsultatData(userName){

    var req = {
      name: userName
    }

   this.http.post(environment.FETCH_CONSULTANT_DATA, req, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200){
        this.activeConsultantData = {};
        this.activeConsultantData = res.message;
        console.log('Konsultti', this.activeConsultantData);
        this.scrollBottomOfChatContainer();
        this.waitHeightSm();
        this.colorService.waitStyleColorUserBubbles();
        return res.status;
      }
      
    }, (err: any) => {
      console.log("Error occured while finding designation for the user : ListData");
    }) 

  }

  fetchConsultationPackages(){

    //käännä postiksi
   this.http.get(environment.FETCH_CONSULTATION_PACKAGES, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200){
        this.feePackages = res.message;
      }
      
    }, (err: any) => {
      console.log("Error occured while finding designation for the user : ListData");
    }) 

  }



  //Asiakkaalla 10min aikaa laittaa palaute ennen kuin automaattiheitto laukeaa
  throwToStart(){
    var that = this;
    setTimeout(function () {
      that.changeSessionStatus();
    }, 600000);
  }

//----------------------------------------------------------------------------------------------------------
// User kirjoittaa
//----------------------------------------------------------------------------------------------------------
  setUserTypingMessage(user) {
    if (this.globalMessages[user] != null && this.globalMessages[user] != undefined) {
      this.globalMessages[user].typing = true;
      var that = this;
      setTimeout(function () {
        that.globalMessages[user].typing = false;
      }, 1500);
    }
  }

//----------------------------------------------------------------------------------------------------------
// mahdollisuus tällä funktiolla lääkärin poistaa näkyvistä listasta käyttäjä (ei atm käytössä)
//----------------------------------------------------------------------------------------------------------

  setOutUser(doctor) {
    var currUser = this.currentActiveDoctorName;
    var element = document.getElementById(currUser)
    element.parentNode.removeChild(element);
  
  }

  //----------------------------------------------------------------------------------------------------------
  // Viestien käsittelijä // messagehandler
  //----------------------------------------------------------------------------------------------------------
  
  messageHandler(messageJson) {
    let task = messageJson.task;
    switch (task) {
      case 'NEW_MESSAGE_AVAILABLE': this.fetchNewMessageForUser(messageJson.from);
        break;
      case 'USER_TYPING_MESSAGE': this.setUserTypingMessage(messageJson.who);
        break;
      case 'REMOVE_CHATBAR': this.removeUserChatbar(messageJson.who);
        break;
      case 'DOC_LOGINS': this.docLogins();
        break;
      case 'AUTO_OPEN': this.autoPushToQueue(messageJson.from,messageJson.who);
        break;
      case 'PING': this.ping(messageJson.who);
        break;
      case 'MULTIPLE_TABS': this.openMultipleWindows(messageJson.from);
        break;
      case 'REFRESH_REQUIRED': this.refreshPage(messageJson.from);
        break;
      case 'VIDEO_INVITATION': this.handleVideoInvitation(messageJson);
        break;
      case 'CONNECTION_CLOSED': this.connectionClosed('e');
        break;
      case 'DEVICE_COMPABILITY': this.handleDeviceCompabilityQuestion();
        break;
      case 'SEND_BACK_TO_QUEUE': this.userCameBackToQueue(messageJson.who);
      default: console.log("Implementation for task not available :: ", task);
        break;
    }
  }

  connectionClosed(e){
    this.connErr = true;
  }

  handleVideoInvitation(messageJson){


    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((res) => {
      this.openVideoBox();
      this.sendDeviceWidth();
      this.roomData = messageJson.msg;
      this.userVideoDeviceWidth = messageJson.width;
      var pre = document.getElementById('preview-window');
      if(pre.classList.contains('d-none')){
        pre.classList.remove('d-none');
      }
    });

  }

  handleDeviceCompabilityQuestion(){

      var result = navigator.userAgent.indexOf('CriOS') >= 0;
      
        this.webSocketService.sendMessage({
          task: 'DEVICE_COMPABILITY_ALERT',
          from: this.currentUserId,
          to: this.currentActiveDoctorName,
          msg: navigator.userAgent,
          compProblems: result
        })
      
  }

  sendDeviceWidth(){

    var width = document.documentElement.clientWidth;
    var height = document.documentElement.clientHeight;
    this.webSocketService.sendMessage({
      task: 'DEVICE_WIDTH',
      from: this.currentUserId,
      to: this.currentActiveDoctorName,
      width: width,
      height:height,
    })
  }

  refreshPage(who){
    location.reload();
  }

userCameBackToQueue(user){
  this.showReplyBox=false;
}

docLogins() {
  this.findAllActiveDoctors();
  this.startScreen();
}

  removeUserChatbar(user){
    if (this.globalMessages[user] != null && this.globalMessages[user] != undefined) {
      this.showReplyBox = false;
    }
    localStorage.setItem('ACTIVE_USER',this.HELPER_NAME);
    this.currentActiveDoctorName = this.HELPER_NAME; 
    this.findMySessionStatus();
  }

  ping(poo){

  }

  tick() {

    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.webSocketService.sendMessage({
        task: 'PING',
      })
      this.tick();
      }, 35000);
 
  }
 

  async autoPushToQueue(userName,sender){
    

    localStorage.setItem('ACTIVE_USER', sender);
    this.currentActiveDoctorName = sender;
    this.fetchNewMessageForUser(this.currentActiveDoctorName);
    this.fetchActiveConsultatData(sender);

    this.OnSelectDoctor({ name: sender });
    
    this.showReplyBox = true;

  }
  
//----------------------------------------------------------------------------------------------------------
// viestilista
//----------------------------------------------------------------------------------------------------------

  LoadMessageList() {
    if(this.currentActiveDoctorName != this.HELPER_NAME){
    this.http.get(environment.MESSAGE_LIST_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        if (res == null) {
          this.globalMessages = {};
        } else {
          console.log('MessageList', res)
          this.globalMessages = res;
          this.prepareMessageList();
          this.setLinksForMessages(res);
          this.ngAfterViewInit();
          this.setMaxToCalendar();
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
    }
  }

  setLinksForMessages(msgObj) {
    if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME ){
      if(msgObj[this.currentActiveDoctorName] != undefined) {
        var msgList = msgObj[this.currentActiveDoctorName]['messageList'];
        
        for(var i = 0; i < msgList.length; i++ ) {
          if(msgList[i].template == 'TEMPLATE_14') {
            msgList[i].actMessage = this.replaceURLWithHTMLLinks(msgList[i].actMessage);
            
          }
        }
      }
    }
  }


  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);
    console.warn(decodedToken.result);
    this.currentUserId = decodedToken.result.id;
    this.currentUserName = decodedToken.result.email;

    this.getPets();
  }
//----------------------------------------------------------------------------------------------------------
// Viestilista
//----------------------------------------------------------------------------------------------------------


  prepareMessageList() {
    this.ngAfterViewInit();


    if(this.currentActiveDoctorName != this.HELPER_NAME){
    var localDoctorList = [];
    var doctorNames = Object.keys(this.globalMessages);
    for (var i = 0; i < doctorNames.length; i++) {
      var doctorName = doctorNames[i];
      if (this.globalMessages[doctorName].designation == undefined) {

      }       

      var doctorDetail = {
        name: doctorName,
        shortMessage: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage,
        time: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].createdDate
      }
      localDoctorList.push(doctorDetail);

    }
    
    this.doctorList = this.orderByPipe.transform(localDoctorList, 'time', false);
    if (this.doctorList.length > 0) {
      var activeUserNameFromLocalStorage = localStorage.getItem('ACTIVE_USER');
      if (activeUserNameFromLocalStorage == null ||activeUserNameFromLocalStorage == this.defaultDocterName) {
        this.currentActiveDoctorName = this.doctorList[0].name;
        localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);
        
      } else {
        this.currentActiveDoctorName = activeUserNameFromLocalStorage;
      }
      if(this.currentActiveDoctorName != this.HELPER_NAME || this.currentActiveDoctorName != undefined){
        if(this.globalMessages[this.currentActiveDoctorName].messageList){
          this.currentActiveMessageList = this.globalMessages[this.currentActiveDoctorName].messageList;
          if(this.currentActiveDoctorName == this.DR_ASSISTANT_NAME){
            for (let i = 0; i < this.currentActiveMessageList.length; i++) {
              const msg = this.currentActiveMessageList[i];
              if(msg.template == 'TEMPLATE_8' && msg.answer != null){
              }
              if(msg.template == 'TEMPLATE_17' && msg.actMessage != null){
              }
              
            }

          }

        }
        else{
          this.LoadMessageList();
        }
      }
    }

    if (this.currentActiveDoctorName != this.defaultDocterName && this.currentActiveDoctorName != this.DR_ASSISTANT_NAME && this.movedToQueue == false) {

      //Tarkastetaan että jos feedback tai ell lopetti keskustelun
      if(this.currentTemplate == 'TEMPLATE_19' || this.currentTemplate == 'TEMPLATE_15'){
        this.showReplyBox = false;
      }
      else{
        this.showReplyBox = true
      }
    }
    this.fetchActiveConsultatData(this.currentActiveDoctorName);
  }
  else {
    this.currentActiveMessageList = [];
  }
}

checkPetData(){
  this.petDataErr = [];
  for (let i = 0; i < this.pets.length; i++) {
    const pet = this.pets[i];
    var arr = [];

    console.log(pet.species);

    if(pet.name == (undefined || null)){
      arr.push('Lemmikin nimi puuttuu')
    }
    if(pet.sex == (undefined || null)){
      arr.push('Lemmikin "'+pet.name+'" sukupuoli puuttuu')
    }
    if(pet.species == (undefined || null)){
      arr.push('Lemmikin "'+pet.name+'" laji puuttuu')
    }
    if(pet.breed == (undefined || null)){
      arr.push('Lemmikin "'+pet.name+'" rotu puuttuu')
    }
    if(pet.birthDate == (undefined || null)){
      arr.push('Lemmikin "'+pet.name+'" syntymäpäivä puuttuu')
    }



    if(arr.length > 0){
      var obj = {
        petID: pet._id,
        petErrors:arr,
        petName: pet.name
      }
      this.petDataErr.push(obj);
    }

    if(i+1 == this.pets.length){
      if(this.petDataErr.length > 0 ){
        console.log(this.petDataErr);
        document.getElementById('check-close-btn').click();
        document.getElementById('open-data-error-modal').click();
      }
      else{
        this.checkUserDetails();
        //this.StartANewConsultation();
      }
    }
    
  }
}

fetchUserDetails(){
  this.http.get(environment.FIND_ONE_USER_END_POINT, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200 ) {
        this.userDetails = res.data;
        console.log(this.userDetails, 'userDetails')
     }
      else {
        console.log('Käyttäjän tietojen etsiminen epäonnistui');
      }
      
    }, (err: any) => {

      console.log("Error while finding status :: ", err);
    })
}

checkUserDetails(){
  this.userDetailErr = [];
  console.log(this.userDetails, 'userDetails');
  var user = this.userDetails[0];

  if(user.firstName == (undefined || null || "")){
    this.userDetailErr.push('Etunimi puuttuu')
  }
  if(user.lastName == (undefined || null || "")){
    this.userDetailErr.push('Sukunimi puuttuu')
  }
  if(user.addressCity == (undefined || null || "")){
    this.userDetailErr.push('Kaupunki puuttuu')
  }
  if(user.addressZip == (undefined || null || "")){
    this.userDetailErr.push('Postinumero puuttuu')
  }
  if(user.addressStreet == (undefined || null || "")){
    this.userDetailErr.push('Katuosoite puuttuu')
  }
  if(user.phone == (undefined || null || "")){
    this.userDetailErr.push('Puhelinnumero puutuu')
  }
  if(user.email == (undefined || null || "")){
    this.userDetailErr.push('Sähköpostiosoite puuttuu')
  }

  if(this.userDetailErr.length > 0 ){
    console.log(this.userDetailErr);
    document.getElementById('check-close-btn').click();
    document.getElementById('open-user-data-error-modal').click();
  }
  else{
    this.StartANewConsultation();
  }


}

routeToEditUser(){
  document.getElementById('close-err-data').click();
  
}

routeToEdit(petID){
  document.getElementById('close-err-data').click();
  this.router.navigate(['/pets/edit-pet/'+petID]);
}

//----------------------------------------------------------------------------------------------------------
// Nappula josta patient avaa keskustelun
//----------------------------------------------------------------------------------------------------------
  

  StartANewConsultation() {

    if(this.clinicStatus) {


    //Suljetaan info-screenit
    var petData = document.getElementById('active-user-data-info');
    petData.style.display="none";
    var visitData = document.getElementById('visit-data-info');
    visitData.style.display="none";

    //Suljetaan navigaatio

    this.closeNav();

    //Avataan chatikkuna
    var chat = document.getElementById('main-chat-container');
    chat.style.display="block";

    
    this.bamboraPaymentButtonVisible = false;


      this.webSocketService.sendMessage({
        task: 'START_NEW_CONSULTATION'
      });


      var myCheckClose = document.getElementById('check-close-btn');
      myCheckClose.click();


      localStorage.setItem('ACTIVE_USER',this.DR_ASSISTANT_NAME);
      this.currentActiveDoctorName = this.DR_ASSISTANT_NAME;


      this.LoadMessageList();
      this.waitHeightSm();
      

      this.OnSelectDoctor({ name: this.DR_ASSISTANT_NAME }); 

    }
    else {
      var btn = document.getElementById('clinic-closed-btn');
      btn.click();
    }
  }

  findMySessionStatus() {

    this.http.get(environment.FIND_USER_SESSION_STATUS, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200 ) {
        
        this.sessionStatus = res.message;
        this.userStartedSession();
        this.closeCriticalButtonsOnConsultation();      }
      else {
        console.log('Statuksen löytäminen epäonnistui');
      }
      
    }, (err: any) => {

      console.log("Error while finding status :: ", err);
    })
  }
  

  userStartedSession() {
    
    if(this.sessionStatus == "paid") {
      this.OnSelectHelper({ name: this.DR_ASSISTANT_NAME });
      localStorage.setItem('ACTIVE_USER', this.DR_ASSISTANT_NAME)
      this.waitScroll();
      this.waitHeight();
      if(document.getElementById('chat-window')){
        document.getElementById('chat-window').classList.add('chat-window-main-container-no-replybox');
      }
      
    }

    else if(this.sessionStatus == null || this.sessionStatus == "normal") {
      this.currentActiveDoctorName = this.HELPER_NAME;
      this.OnSelectHelper({ name: this.HELPER_NAME });
      this.prepareMessageList();
      this.waitHeight();
      if(document.getElementById('chat-window')){
        document.getElementById('chat-window').classList.add('chat-window-main-container-no-replybox');
      }
    }

    else {
      localStorage.setItem('ACTIVE_USER', this.sessionStatus)
      var ls = localStorage.getItem('ACTIVE_USER')
      this.OnSelectHelper({ name: ls });
      this.waitHeightSm()
    }

    this.findAllActiveDoctors();
    this.fetchClinicOpenTimes();
  }


  waitHeight() {

    setTimeout(() =>{
      if(document.getElementById('chat-window')){
         document.getElementById('chat-window').classList.add('chat-window-main-container-no-replybox');
      }
    },2000)

  }


  waitHeightSm() {

    if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME) {

      setTimeout(() =>{
        if(document.getElementById('chat-window') && document.getElementById('chat-window').classList.contains('chat-window-main-container-no-replybox')) {
          document.getElementById('chat-window').classList.remove('chat-window-main-container-no-replybox');
        }
        else{
        }
      },1000)

    }
  }


  waitScroll() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfChatContainer();
    },1000)

  }

  waitScrollSm() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfChatContainer();
    },100)

  }

  waitSession() {
    var that = this;

    setTimeout(() =>{
      that.findMySessionStatus();
    },1500)

  }

  waitSessionLong() {
    var that = this;

    setTimeout(() =>{
      that.findMySessionStatus();
    },12000)

  }


  logOut() {
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }


  ngAfterViewInit(){
    //this.findMySessionStatus()
    this.waitScroll();
  }


  ngAfterViewChecked() {


    if(this.connErr == true){
      window.location.reload();
    }

  }

  onScroll() {
    let element = this.chatMessageContainer.nativeElement;
    let currentWindowPos = element.scrollHeight - element.scrollTop;
    let diff = currentWindowPos - element.clientHeight;
    diff = Math.abs(diff);
    if (this.disableScrollDown && diff <= 1) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
  }

  private scrollToBottom(): void {


    if (this.disableScrollDown) {
      return
      
    }
    try {
      //smoothscroll.polyfill();
      this.chatMessageContainer.nativeElement.scrollTo({ top: this.chatMessageContainer.nativeElement.scrollHeight, behavior: 'smooth' })
      //this.SmoothVerticalScrolling(this.chatMessageContainer, 300, "bottom")
      // this.myScrollContainer.nativeElement.scroll
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }


  setAnswer(message, answer) {
    message.answer = answer;

    if(message.answer == 'vastattu'){
      this.AddFeedBack();
    }
    return true;

  }


  OnAnswerSelected(message) {
    let reqBody = {
      id: message.id,
      answer: message.answer
    } 

    if (reqBody.answer == null || reqBody.answer == undefined ) {
      message.oldMessage = false;
      this.LoadMessageList();
      alert('Unhodit lisätä vastauksen!');
      message.oldMessage = false;
      return;
    }

    if(reqBody.answer != false){
      if(!reqBody.answer){
        message.oldMessage = false;
        this.LoadMessageList();
        alert('Vastaus tulee olla');
        message.oldMessage = false;
        return;
      }
    }

    this.http.post(environment.ANSWER_SELECTED_END_POINT, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        //this.scrollToBottom(); 23.6
      }, (err: any) => {
        console.log("Error while answer selected : ", err);
      }
    )
  }


  getReasons(){
    var userData = {
      data:this.currentUserId
    }
    this.http.post(environment.FIND_PATIENT_CLINIC_REASONS,userData, this.httpOptions).subscribe(
    (res: any) => {
      this.reasonsArr = res
      if(this.reasonsArr.length > 0) {
        this.reasonsArr.sort(function(a, b) {
          var textA = a.reason.toUpperCase();
          var textB = b.reason.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
    }, (err: any) => {

      console.log("Error while login :: ", err);
    })

     }


  OnSelectDoctor(doctor) {
    
    if (this.globalMessages[doctor.name] == null || this.globalMessages[doctor.name] == undefined) {
      return;
    }
    this.currentActiveDoctorName = doctor.name;
    this.lastMessage = doctor.shortMessage;
    localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);
    this.currentActiveMessageList = this.globalMessages[doctor.name].messageList;
    if (this.currentActiveDoctorName != this.DR_ASSISTANT_NAME && this.movedToQueue == false) {
      if(this.currentUserCredit >= 1){
        if(this.currentTemplate == "TEMPLATE_19" || this.currentTemplate == "TEMPLATE_15" || this.currentActiveDoctorName == this.DR_ASSISTANT_NAME) {
          this.showReplyBox = false;
        }
        else{
          this.showReplyBox = true;
        }

      }
    }

    this.scrollToBottom();
    
  }


  OnSelectHelper(doctor) {

    this.currentActiveDoctorName = doctor.name;
    this.lastMessage = doctor.shortMessage;
    localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);

    if(doctor != "Dr.Assistant") {
    this.fetchNewMessageForUser(doctor.name);
    }
    
  }

  OnSendReply(e) {

    var textarea = document.getElementById("comment");

    textarea.setAttribute('style', 'height:41px!important')

    e.preventDefault();
    let userMessage = this.replyMessage;

    var strlen = userMessage.length;
    this.replyMessage = '';

    if (userMessage == null || userMessage == undefined || userMessage.trim() == '' || strlen > 4000) {
      alert('Viesti ei voi olla tyhjä tai pidempi kuin 4000 merkkiä.');
      return;
    }
    this.webSocketService.sendMessage({
      task: 'TEXT_MESSAGE',
      from: this.currentUserId,
      to: this.currentActiveDoctorName,
      msg: userMessage
    })
  }

//----------------------------------------------------------------------------------------------------------
// Seuraavat kolme käsittelee kuvan lähetystä, inject funktiota kutsutaan kun painetaan 
// "valitse" painiketta UI:ssä, katsotaan että otetaan vain ensimmäinen lähetetty tiedosto, 
// getBase64 muuttaa img base64 formaattiin OnSendReplyFile lähettää kyseisen kuvan serverille
//----------------------------------------------------------------------------------------------------------

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  inject(){ 
    var file = document.querySelector('input[type=file]')['files'][0];
    this.getBase64(file).then(
      data => {  this.OnSendReplyFile(data);}
    
    ); 
  }

  OnSendReplyFile(data) {

    this.replyMessage = '';

    this.webSocketService.sendMessage({
      task: 'TEXT_MESSAGE',
      from: this.currentUserId,
      to: this.currentActiveDoctorName,
      msg: data
    }) 
  }




//----------------------------------------------------------------------------------------------------------
// reaaliajassa käyttäjä viedään 
//----------------------------------------------------------------------------------------------------------

   UserToQueue() {
    
    for(var i = 0; i< this.activeDoctors.length; i++){

      this.webSocketService.sendMessage({
        task: 'NEW_PERSON_IN_QUEUE',
        to: this.activeDoctors[i]._id,
        from: this.currentUserId
      });
    }

  }

 
  RemoveUserFromQueueDoctorView(userID) {
    var deletetableElm = document.getElementById(userID);
    deletetableElm.parentNode.removeChild(deletetableElm);
  }


//----------------------------------------------------------------------------------------------------------
// käytössä
//----------------------------------------------------------------------------------------------------------


  findAllActiveDoctors(){
    this.http.get(environment.FIND_ALL_ACTIVE_DOCTORS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.activeDoctors = res.message;
          this.startScreen();
        }
        else {
          this.activeDoctors = [];
          this.startScreen();
        }

    }, (err: any) => {
      console.log('Virhe ', err);
    }
  );
  }
  // haetaan serveriltä users.js tiedostosta nykyisen käyttäjän credit, tulee str muodossa, 
  // muutetaan int luvuksi funktiota kutsutaan nginit aliohjelmassa
  CheckMyCredit() {
    this.http.get(environment.CHECK_CREDIT_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        var intCredit = parseInt(res)
        this.currentUserCredit = intCredit;
        
        if(intCredit >= 1 && this.movedToQueue == false )
        {
          this.showReplyBox = true;
        }

        else
        {
          this.showReplyBox = false;
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      }
    )
  }


  payConsultationFee(message, feePackage) {
    var a = [];
    a.push(feePackage);
    this.feePackages = a;
    this.bamboraPaymentButtonVisible = true;
    this.http.get(environment.GET_PAYMET_METHODS, this.httpOptions).subscribe(
      (res: any) => {
        if (res == null) {
          this.bamboraPaymentMethods = {};

        } else {
          this.bamboraPaymentMethods = res;

            this.waitScrollSm();
        }

      }, (err: any) => {
        console.log('Tuli virhe..');
        this.bamboraPaymentMethods = {};
      }
    )

    message.selectedPackage = feePackage;
    this.selectedMessage = message;
    this.finalAmount = feePackage.price;
  }


  makeBamboraPayment(bank) {
    
    if(this.discountSuccess && bank == 'joustoraha') {
      alert('Valitettavasti alennuskoodia ei voi käyttää joustorahan kanssa. Ole hyvä ja valitse jokin toinen maksuvaihtoehto');
      return;
    }

     this.buyPackage = "";
     this.packagePrice = "";
     this.packageDescription = "";
     this.packageCredit = "";
     this.buyPackage = this.selectedMessage.selectedPackage.name;
     this.packageDescription = this.selectedMessage.selectedPackage.productDescription;
     this.packagePrice = this.selectedMessage.selectedPackage.price;
     this.packageCredit = this.selectedMessage.selectedPackage.productCredit;
     
     const userName = this.currentActiveDoctorName;

     // otetaan uniikki id:n pohjaksi message id
     const lastMessageId = this.globalMessages[userName].messageList[this.globalMessages[userName].messageList.length - 1].id;
     document.getElementById('open-buy-modal').click();
     
     const reqBody = {
       bankName: bank,
       selectedPackage: this.buyPackage,
       tranId: lastMessageId,
       discountCode: this.discountCode['code'],
       discountPercent: this.discountCode['discount_percent'],
     };

       this.http.post(environment.GET_PAYMET, reqBody, this.httpOptions).subscribe(
         (res: any) => {
         this.bamboraPaymentUrl = "";
         this.bamboraPaymentUrl = res.name;
         

         var anElm = document.getElementsByClassName('hidableelm') as HTMLCollectionOf<HTMLElement>;
         for(var i = 0; i < anElm.length; i++){
          anElm[i].style.display = "none" ;
         }
         this.colorService.waitStyleColorUserBubbles();
         if (res == null) {
         } else {
         }
 
       }, (err: any) => {
         console.log('Tuli virhe..');
       }
     );
 
   } 


   sendToPay(){
    var elm = <HTMLInputElement>document.getElementById('terms-check');
    this.colorService.styleUserChatBubbles();
     if(elm.checked == false) {
      alert('Hyväksy ensiksi käyttöehdot'); 
      return;
     }
     else {
      window.location.href=this.bamboraPaymentUrl
     }

   }


  openNav() {

      document.getElementById("mySidenav").classList.add('kolmonen');
      document.getElementById("main-nav").classList.add('d-none');
      document.getElementById("mySidenav").classList.remove('korkeus');
      document.getElementById("main-chat-container").classList.add('disable-scroll');
      document.getElementById("section-two-container").classList.add('overlay-sidenav');
      document.getElementById("mySidenav").classList.add('kolmonen-active');
      document.getElementById("mySidenav").classList.remove('kolmonen');
  }


  closeNav() {
    document.getElementById("mySidenav").classList.remove('kolmonen-active');
    document.getElementById("mySidenav").classList.remove('kolmonen');
    document.getElementById("main-nav").classList.remove('d-none');
    document.getElementById("mySidenav").classList.add('korkeus');
    document.getElementById("main-chat-container").classList.remove('disable-scroll');
    document.getElementById("section-two-container").classList.remove('overlay-sidenav');
  }


  openCity(evt, cityName) {
  // Declare all variables
  var i, tabcontent, tablinks;


  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";

  } 


  openExpandBox(e){

    var elmID = e.target.id;
    var petID = elmID.substring(12)

    var menu = document.getElementById(petID);
    var icon = document.getElementById(elmID);

    var iconMinus = document.getElementById('close-icon-'+petID)
    menu.classList.add('ease-in');

    iconMinus.style.display="block";
    icon.style.display="none";

  }

  closeExpandBox(e){
  var elmID = e.target.id;
  var petID = elmID.substring(11)

  var iconMinus = document.getElementById(elmID);
  var icon = document.getElementById('expand-icon-'+petID);
  var menu = document.getElementById(petID);
  menu.classList.add('ease-out');
  menu.classList.remove('ease-in');
  iconMinus.style.display="none";
  icon.style.display="block";

  }

/*   handleFiles(e) {
    var canvas= <HTMLCanvasElement>document.getElementById("canvas");
    var rr= <HTMLCanvasElement> canvas;
    var ctx = rr.getContext("2d");
    var maxW=1200;
    var maxH=1200;
    this.imgOutput =""
    var wss = this.webSocketService;
    var sender = this.currentUserId;
    var reciever = this.currentActiveDoctorName;
    var img = new Image;
    img.onload = function updateImage() {
      var iw=img.width;
      var ih=img.height;
      var scale=Math.min((maxW/iw),(maxH/ih));
      var iwScaled=iw*scale;
      var ihScaled=ih*scale;
      canvas.width=iwScaled;
      canvas.height=ihScaled;
      ctx.drawImage(img,0,0,iwScaled,ihScaled);
      var value = canvas.toDataURL("image/jpeg",1.8);
      if(value != null || value != ""){
      wss.sendMessage({
        task: 'TEXT_MESSAGE',
        from: sender,
        to: reciever,
        msg: value
      })
    }
    else {
      alert('Kuvan lähettäminen epäonnistui')
    }
      return value;
    };
    img.src = URL.createObjectURL(e.target.files[0]);
  }
 */

  fetchClinicOpenTimes(){

    this.http.get(environment.FIND_CLINIC_OPEN, this.httpOptions).subscribe(
      (res: any) => {
        this.clinicOpen = res.message.opens

        var date = new Date();

        var weekDay = date.getDay();
        var hours = date.getHours();
        var mins = date.getMinutes();
        
        var genStr = hours.toString() + ":" +mins.toString() + ":00";

        var genDate = new Date('1/1/1999 ' + genStr);


        for (let i = 0; i < this.clinicOpen.length; i++) {
          const day = this.clinicOpen[i];

          if(day.idx == weekDay){

            this.todayIdx = day.idx;

            var start = new Date('1/1/1999 ' + day.start);
            var end = new Date('1/1/1999 ' + day.end);


            if(genDate > start && genDate < end){
              this.clinicStatus = true;
            }
            else {
              this.clinicStatus = false;
              var btn = document.getElementById('clinic-closed-btn');
              btn.click();
            }
          }
          
        }
  
      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  
  }


  get npsRange() { return this.addFeedback.get('npsRange') }
  get npsText() { return this.addFeedback.get('npsText') }
  get acceptedContact() { return this.addFeedback.get('acceptedContact') }


  AddFeedBack(){
    var npsText = this.addFeedback.get('npsText').value;
    var len = this.currentActiveMessageList.length;
    var last = this.currentActiveMessageList[len-1];
    var lastID = last.id;
    var npsRange = (<HTMLInputElement>document.getElementById('npsRange-'+lastID)).value

    var acceptedContact = (<HTMLInputElement>document.getElementById('acceptedContact-'+lastID)).value

    var accept;

    if(this.customerNPSRange == -1 && npsText != ""){
      this.notificationService("Unohdit muutaa arvosanasi. Olehyvä ja iu'uta liukuria.", 4000);
      return;
    }

    if(document.getElementById('acceptedContact-'+lastID) != undefined || document.getElementById('acceptedContact-'+lastID) != null) {
      if((<HTMLInputElement>document.getElementById('acceptedContact-'+lastID)).checked){
        accept = true;
      } 
      else {
        accept = false;
      }
    }

    else {
      this.changeSessionStatus();
    }

    var request = {
      npsRange: parseInt(npsRange),
      npsText: npsText,
      acceptedContact: accept,
    }
    
    var feedbackCont = document.getElementById('feedback-container');
    var afterFeedback = document.getElementById('after-feedback-container');

    if(npsRange == "" || npsRange == undefined || npsRange == null) {
        this.changeSessionStatus();
        return;
    }

    this.http.post(environment.ADD_FEEDBACK, request, this.httpOptions).subscribe(
      (res: any) => {
          if (res.status == 200) {
          this.messageSuccess="";
          this.messageErr="";
          this.messageSuccess="Lähettäminen onnistui"
          feedbackCont.style.display="none";
          afterFeedback.style.display="block";
          this.addFeedback.reset();
          this.waitAlert();

          var anElm = document.getElementsByClassName('feedback-container') as HTMLCollectionOf<HTMLElement>;

          for(var i = 0; i < anElm.length; i++){
            anElm[i].style.display = "none" ;
          }

          var elm = document.getElementsByClassName('redirect-info') as HTMLCollectionOf<HTMLElement>;

          for(var i = 0; i < elm.length; i++){
            elm[i].style.display = "block" ;
          }
   
          var that = this;
          setTimeout(function () {
            that.changeSessionStatus();
          }, 10000);

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          window.scrollTo(0,0)

        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);

      }

    )}


  changeSessionStatus(){

    var userData = {
      userID: this.currentUserId
    }

    this.http.post(environment.USER_CHANGE_USER_SESSION_STATUS_TO_NORMAL,userData, this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200 ) {
          this.sessionStatus = res.message;
          this.findMySessionStatus();
          this.closeCriticalButtonsOnConsultation();
        }

        else {
          console.log('Statuksen löytäminen epäonnistui');
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })

  }


  scrollBottomOfChatContainer() {
    var objDiv = document.getElementById("main-chat-container");
    //objDiv.scrollTop = objDiv.scrollHeight;'


    if(objDiv){
      objDiv.scroll({
        top: objDiv.scrollHeight,
        behavior: 'smooth'
      });
    }
  }


  drAssitantToId(){

    this.http.get(environment.CHANGE_ASSISTANT_NAME_TO_ID, this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200){
          this.DR_ASSISTANT_NAME = res.message;
          this.currentActiveDoctorName = res.message;

          this.findMySessionStatus();

        }
  
      },(err: any) => {
        console.log("Erroori tapahtui findFullPetDatassa : ", err);
      })

  }

  CloseInfoScreen(e,id){
    var hidElm = document.getElementById(id);

    var showElm = document.getElementById('main-chat-container');

    hidElm.style.display = "none";
    showElm.style.display = "block";
  }


  openInfoScreenActiveUserData(e,pet){

    var chat = document.getElementById('main-chat-container');
    chat.style.display = "none";

    this.infoToPetScreen = pet;

    var info = document.getElementById('active-user-data-info');
    info.style.display = "block";
    this.waitCritical(pet);

  }

  openInfoScreenVisits(e) {


    var chat = document.getElementById('main-chat-container');
    var userData = document.getElementById('active-user-data-info');

    chat.style.display = "none";
    userData.style.display = "none";

    var info = document.getElementById('visit-data-info');
    info.style.display = "block";


    var petID = e;

    //Return if same pet
    if(this.visitHistory.length > 0){
      if(this.visitHistory[0].petsID == petID){
        return;
      }
    }
    var req = {
      petID: petID,
    }

    this.http.post(environment.USER_FETCH_VISIT_FOR_PET_END_POINT,req, this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200){
          this.visitHistory = [];
          this.visitHistory = res.message;

          this.visitHistory = this.visitHistory.sort(function(a,b){

            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });

        }
        else if(res.status == 201){
          this.visitHistory = null;
          this.visitHistory = [];
        }
  
      },(err: any) => {
        console.log("Erroori tapahtui findFullPetDatassa : ", err);
      })
  }


  openProductInfoModal(e,pack){

    this.currentProduct = pack;

    var elm = document.getElementById('open-product-info');
    elm.click();

  }


  deletePet(petID){

    var e;
    var req = {
      id: petID,
      userID: this.currentUserId
    }
    this.http.post(environment.DELETE_ONE_PET_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          //Suljetaan
          var btn = document.getElementById('delete-pet-modal-close');
          btn.click();
          //Haetaan kaikki lemmikit
          this.getPets();
          //Suljetaan infoscreen
          this.CloseInfoScreen(e,'active-user-data-info')

        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

  closeCriticalButtonsOnConsultation(){

    if(this.sessionStatus == this.currentActiveDoctorName || this.sessionStatus == "paid"){

      //suljetaan aloita chat painikkeet

      for(var i = 0; i < this.pets.length; i++) {
        var petID = this.pets[i]._id;

        var elm = document.getElementById('start-consultation-first-'+petID);
        var anElm = document.getElementById('start-consultation-second-'+petID);
        if(elm && anElm){
          elm.style.display="none";
          anElm.style.display="none";
        }
      }
    }
    else{
      for(var i = 0; i < this.pets.length; i++) {
        var petID = this.pets[i]._id;

        var elm = document.getElementById('start-consultation-first-'+petID);
        var anElm = document.getElementById('start-consultation-second-'+petID);
        if(elm && anElm){
          elm.style.display="block";
          anElm.style.display="block";
        }
      }
    }
  }


  waitCritical(pet){
    var that = this;
    setTimeout(function () {
      that.closeCriticalBtnsInfoScreen(pet);
    }, 500);
  }


  closeCriticalBtnsInfoScreen(pet){
    var petID = pet._id;
    
    if(this.sessionStatus == this.currentActiveDoctorName || this.sessionStatus == "paid"){

      var edit = document.getElementById('edit-'+petID);
      var deleteBtn = document.getElementById('delete-'+petID);

        if(edit && deleteBtn){
          edit.style.display="none";
          deleteBtn.style.display="none";
        }
    }
    else {

      var elm = document.getElementById('start-consultation-first-'+petID);
      var anElm = document.getElementById('start-consultation-second-'+petID);

      if(elm && anElm){
        elm.style.display="block";
        anElm.style.display="block";
      }
    }
  }


  formateDate(date){
    date = date.substring(0,10);
    date = new Date(date);
    var date2 =  date.getMonth() + 1
    if (!isNaN(date.getTime())) {
        return date =   date.getDate() +'.'+ date2  +'.' + date.getFullYear();
    }
  }


  waitAlert() {
    var that = this;

    setTimeout(() =>{
      that.messageSuccess = "";
      that.messageErr = "";
    },5000)

  }


  autoTextarea(e){
    var textarea = document.getElementById("comment");
    var heightLimit = 500; /* Maximum height: 200px */

    textarea.oninput = function() {
      textarea.style.height = ""; /* Reset the height*/
      textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
      textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important')
    };
  }


  openShopTerms() {
    document.getElementById('shop-terms').style.display = "block";
    document.getElementById('close-shop-terms-link').style.display = "block";
  }


  closeShopTerms() {
    document.getElementById('shop-terms').style.display = "none";
    document.getElementById('close-shop-terms-link').style.display = "none";
  }


  fetchDiscountCode(){
    this.discountSuccess = false;
    this.discountErr = false;
    var len = this.currentActiveMessageList.length;
    var last = this.currentActiveMessageList[len-1];
    var lastID = last.id;
    var code = (<HTMLInputElement>document.getElementById('eelik-'+lastID)).value;

    var req = {
      code: code,
    }
    
    this.http.post(environment.FETCH_DISCOUNT_CODE, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          //Suljetaan
          this.discountCode = res.message[0];
          this.discountSuccess = true;
          this.discountErr = false;

        }
        if(res.status == 201){
          this.discountErr = true;
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
    })
  }

  getVideoToken(roomDatas){

    var req = {
      room: roomDatas.videoRoomName
    }

    this.http.post(environment.GET_VIDEO_USER_ROOM_TOKEN, req, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200) {

        this.videoToken = res.message;

        this.joinRoom(this.videoToken, roomDatas);
          
      }
      else {

      }
    }, (err: any) => {

      console.log("Error while login :: ", err);
    })

    }

    joinRoom(token, rooms){
      connect(token, { name:rooms.videoRoomName,audio: true, video:  {width: document.documentElement.clientWidth, height:(document.documentElement.clientHeight *0.8),deviceId:{exact:this.selectedCamera}} , }).then(room => {
        this.notificationService('Olet liittynyt huoneeseen. Aloita puhuminen.',2500);
        this.currentRoom = room;
        this.getAllCameras();
        this.localChange();
        

        if(this.localTrack == undefined){
          this.localTrack = undefined;
          this.detachCameraVideo();
          this.unmuteAudioVideo();
        }
        else {
          this.localTrack.mediaStreamTrack.stop();
          this.localTrack = undefined;
          this.unmuteAudioVideo();
          this.attachCameraVideo();
        }

        room.on('trackUnpublished', room => {
          console.log('Track poistettu');
        })

        room.on('trackPublished', room => {
          console.log('Track lisättys');
        })

        var full_audio_track;
        var full_video_track;


        //Kun asiakas liittyy ensiksi
        room.participants.forEach(participant => {
        
         participant.on('trackSubscribed', track => {
           console.log('track subscribed');
          var d = document.getElementById('videokkas');
          var img = document.getElementById('video-placeholder-img');
          if(track.kind == "video") {
            full_video_track = track;
            if(!d && track.isEnabled){
              if(img){
                img.remove()
              }
                var g = document.getElementById('remote-media-div').appendChild(track.attach());
                g.setAttribute('id', "videokkas")
                g.setAttribute('class', "videokkas");
                g.setAttribute('width', "100%");
                g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")

            }
            else if(!d && !track.isEnabled){
              if(!img){
                const image = <HTMLImageElement>document.createElement('img');
                if(this.activeConsultantData['wantedImage']){
                  image.setAttribute('src',this.fileURL + '/'+this.activeConsultantData['wantedImage']['key'] );
                }
                else{
                  image.setAttribute('id','../assets/images/wellness_some.png' );
                }
                image.setAttribute('id','video-placeholder-img' );
                image.setAttribute('style', "height: calc(100vh - 88px - 69px)!important; background-color:black;");
  
                document.getElementById('remote-media-div').appendChild(image);
              }
            }
            else if(d && track.isEnabled){
              d.remove();
              var g = document.getElementById('remote-media-div').appendChild(track.attach());
              g.setAttribute('id', "videokkas")
              g.setAttribute('class', "videokkas");
              g.setAttribute('width', "100%");
              g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")


            }
          }
           else if(track.kind == "audio"){
            full_video_track = track;


            var f = document.getElementById('remote-media-div').appendChild(track.attach());
            f.setAttribute('id', "video-audio-track")
            

           }

          });
        });

        room.on('trackDisabled', track => {

          if(track.kind == "video"){
            var video = document.getElementById('videokkas');
            if(video){
              video.remove();
            }
          }
          else if(track.kind == "audio"){
            //document.getElementById('video-audio-track').remove();
          }


            //Lisätään placeholder kuva
            if(track.kind == "video"){
              var i = document.getElementById('video-placeholder-img');

              //Add image if not images already
              if(!i){
                const image = <HTMLImageElement>document.createElement('img');
                if(this.activeConsultantData['wantedImage']){
                  image.setAttribute('src',this.fileURL + '/'+this.activeConsultantData['wantedImage']['key'] );
                }
                else{
                  image.setAttribute('id','../assets/images/wellness_some.png' );
                }
                image.setAttribute('id','video-placeholder-img' );
                image.setAttribute('style', "height: calc(100vh - 88px - 69px)!important; background-color:black;");
                document.getElementById('remote-media-div').appendChild(image);
              }
              
              if(video){
                video.remove();
              }



              this.notificationService('Eläinlääkäri sammutti kameransa.', 1500);
            }
            else if(track.kind == "audio"){
              this.notificationService('Eläinlääkäri sammutti mikrofoninsa.', 1500);
            }


        })

      room.on('participantDisconnected', participant => {
        this.notificationService('Eläinlääkäri sulki videokeskustelun.', 1500);
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable();
          publication.unpublish();
          publication.track.detach();
          publication.track.stop();
        });
        if(this.localTrack){
          this.localTrack.stop();
          this.localTrack.mediaStreamTrack.stop()
        }
        this.closeUserVideoConsultation();

        
       })

       room.on('disconnected', room => {

        this.notificationService('Eläinlääkäri sulki videokeskustelun.', 1500);
        // Detach the local media elements
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.stop();
          publication.unpublish();
          this.localTrack.stop();
          room.disconnect();
        });
        this.closeUserVideoConsultation();
          room.disconnect();
      });

      room.on('disconnected', room => {
        if(this.localTrack){
          this.localTrack.mediaStreamTrack.stop()
          this.localTrack.stop();
        }
        this.closeUserVideoConsultation();
        // Detach the local media elements
        room.localParticipant.videoTracks.forEach(publication => {
          publication.track.disable();
          publication.unpublish();
          publication.track.detach();
          publication.track.stop();
          var video = document.getElementById('videokkas');
          if(video){
            document.getElementById('remote-media-div').removeChild(video);
          }
        });
        room.localParticipant.audioTracks.forEach(publication => {
          publication.unpublish();
          publication.track.disable();
          publication.track.detach();
          publication.track.stop();
        });          
        if(this.videoToken){
          room.disconnect();
        }
      });
       

            //Kun ell laittaa videon auki
            room.on('trackEnabled', (track, participant) => {

              console.log(track,'lisätty track')
              //Suljetaan odotus

                //Lisätään video
                if(track.kind == "video"){
                var d = document.getElementById('videokkas');
                if(!d){
                  var g = document.getElementById('remote-media-div').appendChild(full_video_track.attach());
                  g.setAttribute('id', "videokkas")
                  g.setAttribute('class', "videokkas");
                  g.setAttribute('width', "100%");
                  g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")
                }

                var image = document.getElementById('video-placeholder-img');
                if(image){
                  document.getElementById('remote-media-div').removeChild(image);
                }
                }
                else if(track.kind == "audio"){
                  var x =  document.getElementById('video-audio-track');
                  this.notificationService('Eläinlääkäri avasi mikrofoninsa.', 1500);
                  if(!x){
                   document.getElementById('remote-media-div').appendChild(full_audio_track.attach());
                  x.setAttribute('id', 'video-audio-track')
                  }

                }
              
            })

      }, error => {
        console.error(`Unable to connect to Room: ${error.message}`);
      });
      }

      openVideoBox(){
        var chat = document.getElementById('chat-wrap');
        chat.classList.add('chat-wrap-none');

        var video = document.getElementById('video-cover');
        video.classList.add('video-cover-active');

        if(!this.videoToken){
          this.waitShow()
          this.getAllCameras()
        }

      }

      waitShow(){

        setTimeout(() =>{

          this.showVideoPreview();

        },500)
      }

      acceptCloseVideoConsultation(){
        document.getElementById('close-video-modal-btn').click();
      }
      
      customerDisconnect(){

        if(this.videoToken){
          this.currentRoom.disconnect();
        }
        else{
          if(this.localTrack != undefined){
            this.localTrack.mediaStreamTrack.stop()
            this.localTrack.stop();
            this.localTrack = undefined;
            this.currentRoom = null;
            this.roomData = null;
            document.getElementById('local-video').remove();
            this.closeVideoBox();
            document.getElementById('close-video-modal-btn').click();
          }
          else{
            this.closeVideoBox();
            document.getElementById('close-video-modal-btn').click();
          }
        }
      }

      closeVideoBox(){
        var chat = document.getElementById('chat-wrap');
        chat.classList.remove('chat-wrap-none');

        var video = document.getElementById('video-cover');
        video.classList.remove('video-cover-active');


        if(this.localTrack){
          if(document.getElementById('local-video')){
            document.getElementById('local-video').remove()
          }
        }

        if(this.videoToken){
          this.notificationService('Pääset takaisin videonäkymään painamalla vilkkuvaa videokuvaketta.',3000)
        }
      }

      joinToVideoChat(){


        var pre = document.getElementById('preview-window');
        pre.classList.add('d-none');

        this.getVideoToken(this.roomData);

      }

      showVideoPreview(){
        if(this.localTrack){
          this.localTrack.stop();
          this.localTrack.mediaStreamTrack.stop()
        }
        
        createLocalVideoTrack({deviceId:{exact:this.selectedCamera}} ).then(track => {
          this.localTrack = track;
          var g = document.getElementById('local-media').appendChild(track.attach());
          g.setAttribute('width', "100%");
          g.setAttribute('id', "local-video");
          g.setAttribute('style', "max-height: calc(100vh - 350px - 100px)!important")

          this.getAllCameras();
          });
        }

        localChange(){

          if(this.localTrack){
            this.localTrack.stop();
            this.localTrack.mediaStreamTrack.stop()
          }
        
          createLocalVideoTrack({deviceId:{exact:this.selectedCamera}} ).then(track => {
            this.localTrack = track;
            this.currentRoom.localParticipant.publishTrack(track);

            //Remove preview window
            if(document.getElementById('local-video-sm')){
              document.getElementById('local-video-sm').remove();
            }


            var d = document.getElementById('local-media-sm').appendChild(track.attach());
            d.setAttribute('width', "25%");
            d.setAttribute('id', "local-video-sm");
            d.setAttribute('style', "border-radius: 4px; z-index:99;")
  
              return track;
            });
          }

          muteAudioVideo(){
            if(this.videoToken){
              this.currentRoom.localParticipant.audioTracks.forEach(publication => {
                publication.track.disable();
              });
              this.muted = true;
              this.notificationService('Mikrofoni mykistetty.', 1500);
              }
              else{
                this.notificationService('Mikrofonin voit mykistää vasta liityttyäsi keskusteluun.', 2500);
    
              }
    
            
          }

          detachCameraVideo(){
            if(this.videoToken){
            this.currentRoom.localParticipant.videoTracks.forEach(publication => {
              publication.track.disable();
              this.videoAiring = false;
            });
            }
            else{
              if(this.localTrack != undefined) {
                this.localTrack.mediaStreamTrack.stop()
                this.localTrack.stop();
                this.localTrack = undefined;
                if(document.getElementById('local-video')){
                  document.getElementById('local-video').remove()
                }
              }
              
            }
            this.notificationService('Olet sammuttanut kamerasi.',2000);
            
            this.videoShow = false;
    
          }

          unmuteAudioVideo(){
            this.currentRoom.localParticipant.audioTracks.forEach(publication => {
              publication.track.enable();
              
            });
    
            this.notificationService('Mikrofoni on käytössäsi.',2000);
    
            this.muted = false;
          }

          attachCameraVideo(){
            if(this.videoToken){
              this.currentRoom.localParticipant.videoTracks.forEach(publication => {
                publication.track.enable();
                this.videoAiring = true;
              });
              }
              else{
                this.showVideoPreview();
              }
    
            this.notificationService('Kamerasi on käytössä.',2000);
    
            this.videoShow = true;
          }

      notificationService(text,delay){
        var elm = document.getElementById('toaster');
        elm.setAttribute('data-delay',delay);
        document.getElementById('toast-text').innerHTML = "";
        document.getElementById('toast-text').innerHTML = text;
        $('.toast').toast('show');
        var g = document.getElementById('toaster')
        g.classList.remove('d-none');
        this.waitToast(g,delay);
      }

      waitToast(g,delay) {
        setTimeout(() =>{
          g.classList.add('d-none');
          //that.ErrMessage = false;
        },delay)
      }

      closeUserVideoConsultation(){
        this.detachCameraVideo();
        this.closeVideoBox();
        document.getElementById('close-video-close-modal').click();
        this.videoShow = true;
        this.roomData = null;
        this.currentRoom = null;
        this.videoToken = null;
        this.notificationService('Keskustelu suljettu onnistuneesti. Kamera ja mikrofoni kytketty pois päältä.', 3000)
        if(document.getElementById("videokkas")){
          document.getElementById("videokkas").remove();
        }
        else if(document.getElementById("video-placeholder-img")){
          document.getElementById("video-placeholder-img").remove();
        }

        if(document.getElementById("local-video-sm")){
          document.getElementById("local-video-sm").remove();
        }

      }

      /**
       * Function gets all users cameras with datatype videoinput
       */
      getAllCameras(){
        
        this.cameraOptions = [];
        if(navigator.mediaDevices){
        navigator.mediaDevices.enumerateDevices().then((result) => {
          
          for (let i = 0; i < result.length; i++) {
            const device = result[i];
            
            if(device.kind == "videoinput"){
              this.cameraOptions.push(device);
            }
            
          }
          //If no cameras selected before just get the first one
          if(!this.selectedCamera){
            this.selectedCamera = this.cameraOptions[0].deviceId;
          }
        });
      }
      }

      /**
       * Function switches cameras
       * 
       */

      changeCamera(){
        if(this.cameraOptions.length == 1){
          this.notificationService("Sinulla on vain yksi kamera. Kameraa ei käännetty.", 2000);

          
        }
        else if(this.cameraOptions.length == 2){

          for (let i = 0; i < this.cameraOptions.length; i++) {
            const opt = this.cameraOptions[i];

              //Check if video is already started
              if(this.videoToken) {
                
                if(opt.deviceId.toString() != this.selectedCamera.toString()){
                this.selectedCamera = opt.deviceId;
                
                //Unpublish track due to twilio sdk
                this.currentRoom.localParticipant.videoTracks.forEach(publication => {
                  //Start publishing after 1 sec
                  publication.unpublish();
                  publication.track.disable();

                  if(this.currentRoom.localParticipant.videoTracks.size == 0){
                    this.waitSec()
                  }

                });
                return;


              }
                    
              }
              else {

                //Switch camera
                if(opt.deviceId.toString() != this.selectedCamera.toString()){
                this.selectedCamera = opt.deviceId;
                
                //Stop media track
                this.localTrack.mediaStreamTrack.stop()
                this.localTrack.stop();
                document.getElementById('local-video').remove();
                  
                //Start again
                this.showVideoPreview();
                return;
                }
              }

          }

        }
        // Alert user if only one camera aviable
        else if(this.cameraOptions.lenght == 0){
          this.notificationService('Ei kameraa saatavilla', 2000);

        }
        // TODO: check if more than 2 camera, now lets just alert
        else if(this.cameraOptions.length > 2){
          this.notificationService('Kameroita yli kaksi, mikäli mahdollista asenna kameroiden kokonaismäärä kahteen',2000);
        }
      }

      /**
       * Function waits 1 sec before publushing new track
       */

  waitSec(){

    setTimeout(() =>{

      //pulish new track with new camera
      this.localChange();

      this.notificationService("Kamera käännetty", 1500);

    },500)
  }

  changeNpsRangeValue(){
    this.customerNPSRange = this.addFeedback.get('npsRange').value;
  }

  instructionsNext(){
    var arr = ['start-screen-instructions','payment-screen-instructions','anamnesis-screen-instructions','chat-screen-instructions']
    var current;
    var btnp = document.getElementById('instruction-previous-btn');
    btnp.style.display = 'block';
    for (let i = 0; i < arr.length; i++) {
      const z = arr[i];
      var elm = document.getElementById(z);
      if(elm.classList.contains('instruction-show')){
        current = z;
      }
    }
    var currIdx = arr.indexOf(current);
    var currElm = document.getElementById(current);
    console.log(current,currIdx);
    if((currIdx + 1) < arr.length) {
      currElm.classList.replace('instruction-show','instruction-hidden');

      var nxt = document.getElementById(arr[currIdx+1]);
      console.log(nxt)
      nxt.classList.replace('instruction-hidden','instruction-show')
      console.log(arr.indexOf(arr[currIdx+1]));
      if((arr.indexOf(arr[currIdx+1]) + 1) == arr.length){
        var btn = document.getElementById('instruction-next-btn');
        btn.style.display = 'none';
      }
    }
    else{
      var btn = document.getElementById('instruction-next-btn');
      btn.style.display = 'none';
    }
    
  }

  instructionsPrevious(){
    var arr = ['start-screen-instructions','payment-screen-instructions','anamnesis-screen-instructions','chat-screen-instructions'];
    var btnp = document.getElementById('instruction-next-btn');
    btnp.style.display = 'block';
    var current;
    for (let i = 0; i < arr.length; i++) {
      const z = arr[i];
      var elm = document.getElementById(z);
      if(elm.classList.contains('instruction-show')){
        current = z;
      }
    }
    var currIdx = arr.indexOf(current);
    var currElm = document.getElementById(current);
    if((currIdx - 1) >= 0) {
      currElm.classList.replace('instruction-show','instruction-hidden');

      var nxt = document.getElementById(arr[currIdx-1]);
      nxt.classList.replace('instruction-hidden','instruction-show')
      if((arr.indexOf(arr[currIdx-1]) - 1) == arr.length){
        var btn = document.getElementById('instruction-previous-btn');
        btn.style.display = 'none';
      }
    }
    else{
      var btn = document.getElementById('instruction-previous-btn');
      btn.style.display = 'none';
    }
    
  }

  closeFunc(){
        //Suljetaan info-screenit
        var petData = document.getElementById('active-user-data-info');
        petData.style.display="none";
        var visitData = document.getElementById('visit-data-info');
        visitData.style.display="none";
    
        //Suljetaan navigaatio
    
        this.closeNav();
  }

  clearInstructions(){
    var arr = ['start-screen-instructions','payment-screen-instructions','anamnesis-screen-instructions','chat-screen-instructions'];

    //Open btn
    var btn = document.getElementById('instruction-next-btn');
    btn.style.display = 'block';

    var btnp = document.getElementById('instruction-previous-btn');
    btnp.style.display = 'none';

    for (let i = 0; i < arr.length; i++) {
      const z = arr[i];
      var elm = document.getElementById(z);
      if(z == 'start-screen-instructions'){
        if(elm.classList.contains('instruction-hidden')){
          elm.classList.replace('instruction-hidden','instruction-show');
        }
      }
      else{
        if(elm.classList.contains('instruction-show')){
          elm.classList.replace('instruction-show','instruction-hidden');
        }
      }
    }
  }

  closeInstructionModal(){
    document.getElementById('instructions-close-btn').click();
  }

  handleFiles(event: Event): void {
    this.attahchmentLoader();
    const attachment = (event.target as HTMLInputElement).files[0];
    this.attachmentObj = attachment;
    if(this.attachmentObj.size > 100000000){
      alert('Tiedoston maksimi koko on 100mb');
      this.closeSendingModal();
      return;
    }
    
    this.fileUploaderService.uploadImage(this.attachmentObj,this.currentUserId,false,(err,result) => {
    
      if(result == false){
        alert('Kuvaa ei voitu lisätä');
      }
      else{
        var sender = this.currentUserId;
        var reciever = this.currentActiveDoctorName;
        this.webSocketService.sendMessage({
          task: 'IMAGE_MESSAGE',
          from: sender,
          to: reciever,
          msg: result,
        })
        }
      })
  }

  handleVideos(event: Event): void {
    this.attahchmentLoader();
    const attachment = (event.target as HTMLInputElement).files[0];
    this.attachmentObj = attachment;
    if(this.attachmentObj.size > 100000000){
      alert('Tiedoston maksimi koko on 100mb');
      this.closeSendingModal();
      return;
    }
    console.log(this.attachmentObj);


    this.fileUploaderService.uploadVideo(this.attachmentObj,this.currentUserId,(err,result) => {
    
      if(result == false){
        alert('Videota ei voitu lisätä');
      }
      else{
        var sender = this.currentUserId;
        var reciever = this.currentActiveDoctorName;
        this.webSocketService.sendMessage({
          task: 'VIDEO_MESSAGE',
          from: sender,
          to: reciever,
          msg: result,
        })
        }
      })
      
  }

  attahchmentLoader(){
    document.getElementById('attachment-loader-btn').click();
    this.colorService.styleLoader();
  }

  closeSendingModal(){
    document.getElementById('close-sending-modal').click();
  }

  openImageEnlarger(imageKey){
    this.enlargerImageKey = imageKey;


    var cont = document.getElementById('image-enlarger');
    cont.classList.add('image-enlarger-active');

  }

  closeImageEnlarger(){
    var cont = document.getElementById('image-enlarger');
    cont.classList.remove('image-enlarger-active');
  }



      
    

  }




  
  


