import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-add-pet',
  templateUrl: './add-pet.component.html',
  styleUrls: ['./add-pet.component.css']
})
export class AddPetComponent implements OnInit,AfterViewInit {

  currentUserId='';
  searchTextAll:string;
  output:any;
  speciesArr: any = [];
  breedArr: any = [];
  authToken = localStorage.getItem('auth');
  errMessage = false;
  breedID;
  consentErr = false;
  breedTrue;
  clinicID = this.authService.getTokenData().clinicID;

  form: FormGroup;
  messageErr: string;
  messageSuccess: string;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private colorService: ColorServiceService,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID);
     }

    ngAfterViewInit(){
      this.colorService.styleUser();
    }


  ngOnInit() {
    this.getSpecies();
    this.myId();
    
    this.form = this.fb.group({
      name: ['', Validators.required],
      sex: ['', Validators.required],
      birthDate: ['', Validators.required],
      species: ['', Validators.required],
      breed: ['', Validators.required],
      sterilized: ['', Validators.required],
      weight: ['', Validators.required],
      microCard: "",
      registerNumber: "",
      addInfo: "",
      insuranceNumber: "",
      insuranceCompany: "",
    });

  }
  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);
    this.currentUserId= decodedToken.result.id;
  }


  
  get name() { return this.form.get('name') };
  get weight() { return this.form.get('weight') };
  get sex() { return this.form.get('sex') };
  get sterilized() { return this.form.get('sterilized') };
  get birthDate() { return this.form.get('birthDate') };
  get species() { return this.form.get('species') };
  get breed() { return this.form.get('breed') };
  get microCard() { return this.form.get('microCard') };
  get registerNumber() { return this.form.get('registerNumber') };
  get addInfo() { return this.form.get('addInfo') };
  get insuranceNumber() { return this.form.get('insuranceNumber') };
  get insuranceCompany() { return this.form.get('insuranceCompany') };


  onSubmit() {
    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      var invalidElm = document.getElementsByClassName('ng-invalid');
      console.log(invalidElm);
      return;
    }

    var istrue = false;
    if(this.form.get('sterilized').value == "true"){
      istrue = true;
    }

    this.errMessage = false;
    var elm = document.getElementById('add-pet-form');
    elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block"; 

    var request = {
      name: this.form.get('name').value,
      weight: this.form.get('weight').value,
      sex: this.form.get('sex').value,
      birthDate: this.form.get('birthDate').value,
      sterilized: istrue,
      species: this.form.get('species').value,
      breed: this.form.get('breed').value,
      breedID:this.breedID,
      microCard: this.form.get('microCard').value,
      registerNumber: this.form.get('registerNumber').value,
      addInfo: this.form.get('addInfo').value,
      insuranceNumber: this.form.get('insuranceNumber').value,
      insuranceCompany: this.form.get('insuranceCompany').value,
      image: localStorage.getItem('pet-image'),
      clinicID: this.clinicID,
    }

    this.http.post(environment.ADDPET_END_POINT, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Register success");
        if (res.status == 200) {
          localStorage.removeItem('pet-image');
          this.messageSuccess = res.message;
          this.router.navigate(['/'+this.clinicID+'/home']);
        }
         else {
          this.errMessage = true; 
          document.getElementById('loader').style.display="none";
          elm.classList.remove("reg-form-success");
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  }
  openConsent(){
    document.getElementById('consentModal').click();
  }
  handleFiles(e) {
    var canvas= <HTMLCanvasElement>document.getElementById("canvas");
    var rr= <HTMLCanvasElement> canvas;
    var ctx = rr.getContext("2d");
    var cw=64
    var ch=64
    var maxW=64;
    var maxH=64;
    this.output =""
    var input = document.getElementById('input');
//var output = <HTMLCanvasElement> document.getElementById('file_output');
    var img = new Image;
    img.onload = function updateImage() {
      var iw=img.width;
      var ih=img.height;
      var scale=Math.min((maxW/iw),(maxH/ih));
      var iwScaled=iw*scale;
      var ihScaled=ih*scale;
      canvas.width=iwScaled;
      canvas.height=ihScaled;
      ctx.drawImage(img,0,0,iwScaled,ihScaled);
      var value = canvas.toDataURL("image/jpeg",0.5);
      localStorage.setItem('pet-image',value);
      
      return value;
    };
    
    img.src = URL.createObjectURL(e.target.files[0]);

    
  }

  getSpecies() {
    this.http.get(environment.FETCH_ALL_SPECIES, this.httpOptions).subscribe(
      (res: any) => {
        this.speciesArr = res.message
        console.log(this.speciesArr)
        var result1 = [], result2 = [];
          for (var i = 0; i < this.speciesArr.length; i++) {
            if (this.speciesArr[i].term != "") {
              result1.push(this.speciesArr[i]);
            } else {
              result2.push(this.speciesArr[i]);
              
            }
            if(i+1 == this.speciesArr.length){
              console.log(result1);
              result1.sort();
              result2.sort(function(a, b) {
                var textA = a.species.toUpperCase();
                var textB = b.species.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });

              result1.sort(function(a, b) {
                var textA = a.species.toUpperCase();
                var textB = b.species.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });
            }
          }
          this.speciesArr = result1.concat(result2);


      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  }
  autoFetchRightBreed(e){

    var value = (<HTMLInputElement>document.getElementById('species')).value;
    for(var i = 0; i < this.speciesArr.length; i++){
      //console.log(this.speciesArr[i]);
      var specie = this.speciesArr[i];

      if(specie.species == value){
        if(specie.term) {
          this.fetchBreedByTerm(specie.term);
          this.form.get('breed').setValue("");
          this.breedArr = [];
          var elm = document.getElementById('breed-modal');
          elm.click();
        }
        else {
          console.log('Ei ollut termiä');
          this.form.get('breed').setValue("muu");
          this.breedArr = [];
          this.breedValidate()
        }
      }
    }
    console.log(value);
  }

  fetchBreedByTerm(term){

    if(term != ""){
      var req = {
        term: term
      }
      this.http.post(environment.FETCH_BREED_BY_TERM, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200) {
            console.log(term, res.message);
            this.breedArr = res.message

            this.breedArr.sort(function(a, b) {
              var textA = a.breed.toUpperCase();
              var textB = b.breed.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });



            console.log(this.breedArr)
          }


        }, (err: any) => {
    
          console.log("Error while login :: ", err);
        })
      }
      else {
        this.breedArr = [];
      }
  }


  selectBreed(data){
    console.log(data);
    this.form.get('breed').setValue(data.breed);
    this.breedValidate()
    this.breedID = data.id;
    var elm = document.getElementById('close-breed-modal');
    elm.click();
  }


  breedValidate(){

    var breedValue = (<HTMLInputElement>document.getElementById('breed')).value;
    var breed = document.getElementById('breed');
    if(breedValue.length > 1){
      breed.classList.add('form-control-success');
      this.breedTrue = true;
      this.validateForm();
    }
    else{
      breed.classList.remove('form-control-success');
      this.breedTrue = false;
      this.validateForm();
    }
  }
  validateForm(){


  }
}
