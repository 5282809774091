

<!--   <app-not-auth-header></app-not-auth-header>
<div class="wrapperi">
	<div class="wrap">
	<div class="d-flex flex-row justify-content-center">
		<div class="flex-wrap mt-4">
			<img src="../../assets/images/Musti_ja_Mirri_logo.png" alt="chat2vet logo" width="350px">
      <h4 class="header3 mt-4">Unohditko salasanasi?</h4>
      
			<p class="body2b activation-text">Uuden salasanan aktivointilinkki tulee sähköpostiisi. 
        Uusithan salasanan 15min sisällä aktivoinnista tai aktivointilinkki erääntyy.</p>

        <div id="loader" class="loader-container" style="display:none">
          <div class="loader mx-auto"></div>
          </div>

      <div class="alert alert-danger" *ngIf="errMessage != '' ">
        <p>{{errMessage}}</p>
      </div>
      <div class="alert alert-success" *ngIf="successMessage != '' ">
        <p class="text-center">{{successMessage}}</p>
      </div>
		</div>
	</div>
 
	

	<div class="d-flex flex-row justify-content-center ms-4" id="forgot-form">
		<form class="flex-row" #loginForm="ngForm" [formGroup]="forgotForm"
		(ngSubmit)="forgotPassword()">
      <div id="form-hide-success">
        <div class="form-inline ">
          <div class="form-group mb-2">
            <label type="text" readonly class="body2b-b form-control-plaintext" id="staticEmail2" value="Sähköpostiosoiffte">Sähköpostiosoite</label>
          </div>
          <div class="form-group mx-sm-3 mb-2 even-lines">
            <input class="form-control" formControlName="userName" type="text" id="userName" placeholder="Sähköpostiosoite">
          </div><br>
          </div>
          <div class="form-inline mt-2">
            <div class="form-group mb-2 flex-row">
              <button [disabled]="forgotForm.invalid" class="btn btn-secondary">Lähetä aktivointikoodi</button>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div> -->

  <app-not-auth-header></app-not-auth-header>


<div class="wrapperi">
	<div class="container h-75">
		<div class="wrap">

			<div class="col-12 m-0 row">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0 mt-4">
					<div class="col-12 text-center p-0">
						<h4 class="header-text mt-4">Unohditko salasanasi?</h4>
						<p class="desc-text">Syötä sähköpostiosoitteesi, josta saat aktivointilinkin. <span class="desc-text-bold">Aktivointilinkki on voimassa 15min</span></p>
            <div class="alert alert-success" *ngIf="successMessage != '' ">
              <p class="text-center">{{successMessage}}</p>
            </div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="col-12 p-0 row m-0">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0">
					<div class="col-12 p-0 ">
						<div class="col-12 login-form-bg">
							<div id="loader" class="loader-container mt-4 mb-4" style="display:none">
								<div class="loader mx-auto"></div>
								<p class="body2b-b text-center mt-4 mb-4">Pieni hetki linkkiä lähetetään...</p>
							</div>
							<form class="flex-row " id="forgot-form" [formGroup]="forgotForm"
							(ngSubmit)="forgotPassword()">
							<div class="col-12 row m-0 pl-0 pr-0">

								<div class="form-group mb-2 flex-column col-12 p-0">
									<input  class="form-control" formControlName="userName" type="email" id="userName" placeholder="Sähköpostiosoite">
								</div>
							</div>
							<div class=" mx-auto mt-4">
								<div class="text-center" >
									<button  [disabled]="forgotForm.invalid" class="btn btn-veteva-secondary"   >Aktivoi</button>
								</div>
							</div>
						</form>
					
				</div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="d-flex justify-content-center flex-row ">
				<div class="link-container">

				</div>
			</div>
			</div>
		</div>
	</div>