<app-header></app-header>
<div class="wrapperi">

  <div class="header-container-page">

    <h2 class=" pt-4 header-text-page pb-4">Minun käyntini </h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    
    </div>

<div class="container">

  <div class="mt-4" *ngIf="visits.length > 0;else no_visits_content">
 

    <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">PVM</th>
              <th scope="col">Kutsumanimi</th>
              <th class="no-mobile" scope="col">Tulosyy</th>
              <th class="no-mobile" scope="col">Lääkäri</th>
              <th scope="col">Yhteenveto</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let visit of visits" >
                <td><strong>{{visit.createdDate | pformateDate}}</strong></td>
                <td>{{visit.petName}}</td>
                <td class="no-mobile">
                  <span *ngIf="visit.anamnesis;else no_anamnesis">{{visit.anamnesis.reason}}</span>
                  <ng-template #no_anamesis>
                    <span> - </span>
                  </ng-template>
                  </td>
                <td class="no-mobile">{{visit.doctorFullName}}</td>
                <td><button class="btn btn-veteva-secondary mb-2" routerLink="/{{clinicID}}/user/visit-detail/{{visit._id}}">Käynti</button></td>
              </tr>

          </tbody>
        </table>
      </div>
    </div>
    <ng-template #no_visits_content>
      <div class="col-md-10 mx-auto pt-4">
        <div class="alert alert-warning">
          <p class="text-center">Et ole vielä käynyt eläinlääkärin palvelussa. Haluatko eläinlääkäriin?</p>
          <div class="text-center">
            <button class="btn btn-veteva-secondary me-auto" routerLink="/home">Eläinlääkäriin</button>
          </div>
        </div>
      </div>
    </ng-template>
    </div>
  </div>