import { AfterContentInit, Component, OnInit, } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";


const helper = new JwtHelperService;

@Component({
  selector: 'app-admin-payments',
  templateUrl: './admin-payments.component.html',
  styleUrls: ['./admin-payments.component.css']
})
export class AdminPaymentsComponent implements OnInit {

  currentUserId='';
  payments: any[];
  userData: any = {};
  currentDetails: any = {};
  authToken = localStorage.getItem("auth");
  numSuccess;
  numFailed;
  numSending;
  totalSum;
  isAdmin = false;
  clinics:any = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService) { }

    ngOnInit() {
      this.myId();
      this.fetchClinics();

    }

    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      this.currentUserId = decodedToken.result.id;

  
  
      if(decodedToken.result.designation == 'patient'){
        this.authService.doLogOut();
        this.logOutt();
  
      }
      else{
        this.fetchPayments()
      }
      
    }

    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }

    fetchPayments(){

    

      this.http.get(environment.FETCH_ADMIN_PAYMENTS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.payments = res.message.payments;
            this.numSuccess = res.message.numSuccess;
            this.numFailed = res.message.numFailed;
            this.numSending = res.message.numSending;
            this.totalSum = res.message.sum;
            this.waitColor();

          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })

    }

    fetchClinics(){
      
      
      this.http.get(environment.FETCH_ADMIN_CLINICS, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            this.clinics = res.message;


          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    }

    fetchPaymentsByDate(){

      var startDate = (<HTMLInputElement>document.getElementById('startDate')).value;
      var endDate = (<HTMLInputElement>document.getElementById('endDate')).value;
      var clinic = (<HTMLInputElement>document.getElementById('clinicSelect')).value;
      
      if(!startDate ||!endDate || !clinic ){
        alert('Lisää alku -ja loppupäivä sekä klinikka');
        return;
      }
      
      this.http.get(environment.FETCH_ADMIN_PAYMENTS_BY_DATE+'/'+startDate+'/'+endDate+'/'+clinic, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.payments = res.message.payments;
            this.numSuccess = res.message.numSuccess;
            this.numFailed = res.message.numFailed;
            this.numSending = res.message.numSending;
            this.totalSum = res.message.sum;
            this.waitColor();

          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    }

    waitColor() {

      setTimeout(() =>{
        this.colorPayment()
      },500)
  
    }
    colorPayment(){
      for (let i = 0; i < this.payments.length; i++) {
        const payment = this.payments[i];
        var row = document.getElementById('payment-row-'+payment._id)

        switch (payment.payment_status.toLowerCase()) {
          case 'ok':
            //row.classList.add('table-success');
          break;
          case 'sending':
            row.classList.add('table-warning');
          break;
          case 'failed':
            row.classList.add('table-danger');
          break;
        
          default:
            break;
        }
        
      }
    }
    openCheckModal(details){
      document.getElementById('open-modal').click();

      this.currentDetails = {};
      this.currentDetails = details;


      this.http.get(environment.ADMIN_FETCH_USER_DETAILS+'/'+details.userID, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.userData = [];
            this.userData = res.message;

          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    

    }

}
