import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute,Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";



const helper = new JwtHelperService;


@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {

  message: string;
  currentUserId='';
  currentUserName ="";
  pets: any[];
  authToken = localStorage.getItem('auth');
  routeID: string = this.route.snapshot.routeConfig.path;
  isAdmin = false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService, 
    private router: Router,
    private route: ActivatedRoute,
  ){ }

  ngOnInit(): void {
  }

  validate(){
    let req = {
      thing: 'sending'
    };

  this.http.post(environment.VALIDATE_DOCTOR_END_POINT, req, this.httpOptions).subscribe(
    (res: any) => {
      console.log('vastaus',res);
      if(res.status != 200){
        //this.logOut();
      }

    }, (err: any) => {
      //this.logOut();
      console.log("Error validating user :: ", err);
    })
}

logOutt() {
  console.log("Request to logout");
  this.authService.doLogOut();
  this.router.navigate(['login']);
}

}
