import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WebsocketService } from '../service/websocket.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-hashtag-settings',
  templateUrl: './hashtag-settings.component.html',
  styleUrls: ['./hashtag-settings.component.css']
})
export class HashtagSettingsComponent implements OnInit, AfterViewInit {

  currentUserRole ='';
  currentUserId = '';
  hashtagArr:any = [];
  messageSuccess="";
  messageErr="";
  editableId="";
  searchText:string;
  hashtag: FormGroup;
  editHashtag: FormGroup;
  authToken = localStorage.getItem('auth');
  isAdmin;
  myName = '';
  routeID: string = this.route.snapshot.params.id;
  hashtagReady = false;
  myHashtags: any = [];
  clinicHashtags:any = [];
  allHashtags:any = [];
  clinicID = this.authService.getTokenData().clinicID;


  ErrMessage = false;
  SuccessMessage = false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };
  


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private colorService: ColorServiceService,
    private fb: FormBuilder) {
    //this.authService.validateLogin();
  }
  ngOnInit() {
    this.myId();
    this.isAdmin = this.authService.isDocAdmin()

    this.hashtag = this.fb.group({
      newHashtag: ['', Validators.required],
      text: ['', Validators.required],
      forClinic: "",
    });

    if(this.isAdmin){
      this.hashtag.get('forClinic').setValue("true");
    }

    this.editHashtag = this.fb.group({
      editableHashtagName: ['', Validators.required],
      editableHashtagText: ['', Validators.required],
      editForClinic: "",
    });
  }
  ngAfterViewInit(){
    this.colorService.setVetColors();

  }

  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    this.currentUserId= decodedToken.result.userID;
    console.log(decodedToken);
    this.currentUserRole = decodedToken.result.designation;
    this.getHashtags();
  }

  getHashtags(){

    this.http.get(environment.FIND_MY_HASHTAGS, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200){

      console.log(res);
      this.allHashtags =res.message;

      this.myHashtags = res.message.doctors;
      this.clinicHashtags = res.message.clinics;
      this.hashtagReady = true;
      this.colorService.waitStyleColor();
      }
      else{
        this.hashtagArr = [];
        this.hashtagReady = true;
        this.colorService.waitStyleColor();
      }

    }, (err: any) => {

      console.log("Error while login :: ", err);
    }
  )

      }
      get newHashtag() { return this.hashtag.get('newHashtag') };
      get text() { return this.hashtag.get('text') };
      get forClinic() { return this.hashtag.get('forClinic') };

  addHashtag() {

    if (!this.hashtag.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      return;
    }
    console.log('Form valid');

    if(this.hashtag.get('newHashtag').value.charAt(0) != "#"){
      alert('Unohdit lisätä # merkinnän hashtagin alkuun. Korjaa valinta. ');
      return;
    }

    var toClinic = true
    if(this.isAdmin){
      if(this.hashtag.get('forClinic').value == 'true'){
        toClinic = true;
      }
      else{
        toClinic = false;
      }  
    }
    else{
      toClinic = false;
    }

    const request = {
      hashtag: this.hashtag.get('newHashtag').value,
      text: this.hashtag.get('text').value,
      forClinic: toClinic
    }

      this.http.post(environment.ADD_HASHTAG_END_POINT, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          if (res.status == 200) {
            this.messageSuccess="Päivittäminen onnistui, päivitä sivu nähdäksesi muutokset."
            this.getHashtags();

            var btn = document.getElementById('close-add-hashtag');
            btn.click();

            this.messageSuccess = "Hashtagin lisääminen onnistui."

            this.SuccessMessage = true;
            this.waitAlert();


            window.scrollTo(0,0)
            

          } else {
            this.messageErr= res.message;

            this.ErrMessage = true;
            this.waitAlert();

            window.scrollTo(0,0)
            //loginError = true;
          }
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )}

    waitAlert() {
      var that = this;
  
      setTimeout(() =>{
        that.SuccessMessage = false;
        that.messageSuccess = "";
        that.messageErr = "";
        that.ErrMessage = false;
        console.log('mennaa')
      },5000)
  
    }

    openEditHashtagmodal(tag){
      

      var myBtn = document.getElementById('btn-edit');

      var forClinic = 'true';
      if(tag.forClinic == true){
        forClinic = 'true';
      }
      else{
        forClinic = 'false';
      }

      this.editableId = "";
      this.editHashtag.get('editableHashtagName').setValue("");
      this.editHashtag.get('editableHashtagText').setValue("");
      this.editHashtag.get('editForClinic').setValue("");

      this.editHashtag.get('editableHashtagName').setValue(tag.hashtag);
      this.editHashtag.get('editableHashtagText').setValue(tag.text);
      this.editHashtag.get('editForClinic').setValue(forClinic);

      this.editableId = tag._id;

      myBtn.click();
    }

    get editableHashtagName() { return this.editHashtag.get('editableHashtagName') };
    get editableHashtagText() { return this.editHashtag.get('editableHashtagText') };
    get editForClinic() { return this.editHashtag.get('editForClinic') };

    editHashtagFunc(){

      var eClinic = true
      if(this.editHashtag.get('editForClinic').value == "true" ){
        eClinic = true;
      }
      else{
        eClinic = false;
      }


      var request = {
        hashtag: this.editHashtag.get('editableHashtagName').value,
        text: this.editHashtag.get('editableHashtagText').value,
        forClinic: eClinic,
        hashtagID: this.editableId,
      }

    this.http.post(environment.EDIT_HASHTAG_END_POINT, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 200) {
          this.messageSuccess="Päivittäminen onnistui, päivitä sivu nähdäksesi muutokset."
          this.getHashtags();

          this.messageSuccess = "Hashtagin muokkaminen onnistui."

          document.getElementById('close-edit-hashtag').click();

          this.SuccessMessage = true;
          this.waitAlert();


          window.scrollTo(0,0)
          

        } else {
          this.messageErr= res.message;

          this.ErrMessage = true;
          this.waitAlert();

          window.scrollTo(0,0)
          //loginError = true;
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      })
    }

    destroyForm(){
      var head = document.getElementById('editableHashtagName');
      var text = document.getElementById('editableHashtagText');

      console.log((<HTMLInputElement>head).value);

      (<HTMLInputElement>head).value = "";
      (<HTMLInputElement>text).value ="";
    }

    removeHashtag(id){

      var req = {
        hashtagID: id
      }

      this.http.post(environment.REMOVE_HASHTAG, req,this.httpOptions).subscribe(
        (res: any) => {
          console.log(res, 'Konsultaatio paketit ovat.:::');
          if(res.status == 200){
            this.getHashtags();
          }
          
          console.log(res);
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 
    }

    searchHashtag(){
      var userInput = (<HTMLInputElement>document.getElementById('hashtag-search')).value
      if(userInput.length >= 3){

        userInput = userInput.replace('#', '$');
        this.http.get(environment.SEARCH_HASHTAG+'/'+userInput,this.httpOptions).subscribe(
          (res: any) => {
            if(res.status == 200){
              this.myHashtags = res.message.doctors;
              this.clinicHashtags = res.message.clinics;

              if(this.myHashtags.length == 0 && this.clinicHashtags.length > 0){
                console.log('click')
                document.getElementById('clinics-hashtags').click();
              }

              if(this.clinicHashtags.length == 0 && this.myHashtags.length > 0){
                document.getElementById('own-hashtags').click();

              }

              console.log(res,'vastaus',this.myHashtags.length);
            }  
            else{
              alert('Höh, hakuehdoilla ei löytynyt yhtään tulosta');

            }
  
          }, (err: any) => {
        
            console.log("Error while login :: ", err);
          })
      }
    }

    showData(){
      var userInput = (<HTMLInputElement>document.getElementById('hashtag-search')).value

      if(userInput.length == 0){


        this.myHashtags = this.allHashtags.doctors;
        this.clinicHashtags = this.allHashtags.clinics;
      }

    }

    openCity(evt, cityName) {
      // Declare all variables
      var i, tabcontent, tablinks;
      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
    
      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
    
      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    
    
      }



}
