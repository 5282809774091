import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import { FileUploaderService } from '../service/file-uploader.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-chathistory',
  templateUrl: './chathistory.component.html',
  styleUrls: ['./chathistory.component.css']
})
export class ChathistoryComponent implements OnInit, OnDestroy, AfterViewInit {

  doctorList: any = []; // Doctor list
  globalMessages: any = []; // Map of doctor and message
  currentActiveMessageList: any = []; // Current Acitve message list
  defaultDocterName = 'patient';
  bamboraPaymentMethods: any = []; // bambora maksutavat
  currentUserRole ='';//nykyisen käyttäjän rooli
  currentUserId=''; 
  currentUserName = ''; // nykyisen käyttäjän käyttäjänimi
  currentUserCredit= 0; // alku credit oletus --> tämä tarkastetaan CheckMyCredit func, kutsuttu nginit
  lastMessage=''; //viimeisin toisen käyttäjän lähettämä viesti
  listUserRole=''; //Listassa olevan käyttäjän rooli
  queueList = [];
  pets=[];
  activeDoctors:any=[];
  packagePrice="";
  movedToQueue=false;
  buyPackage:any = {}
  feePackages:any = [];
  openTimes: any = [];
  bamboraPaymentUrl = "";
  clinicOpen = "";
  imgOutput = "";
  sessionStatus = "";
  activeConsultantData = {};
  reasonsArr=[];
  currentActiveDoctorName: string = "Autan alkuun"; // Current Acitve message list
  allDoctorUsernames = [];
  authToken = localStorage.getItem('auth');
  showReplyBox: boolean = false;
  HELPER_NAME = 'Autan alkuun'
  DR_ASSISTANT_NAME: string = '';
  anList = document.getElementById('kotielaimet');
  listData =  [];
  userVisits = [];
  clinicID = this.authService.getTokenData().clinicID;
  fileURL = environment.FETCH_ATTACHMENT;

  noDocs=false;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  replyMessage: string = '';

  addFeedback: FormGroup;

  

  @ViewChild("chatMessageContainer", { static: true }) chatMessageContainer: ElementRef;
  disableScrollDown: boolean = false;


  selectedMessage;

  
    


  constructor(
    private router: Router, 
    private authService: AuthService, 
    private http: HttpClient, 
    private fb: FormBuilder,
    private colorService: ColorServiceService,
    private fileUploaderService: FileUploaderService,
    private orderByPipe: OrderByPipe) {
  }

  ngOnInit() {
    this.drAssitantToId()
    this.currentActiveDoctorName = localStorage.getItem('USER');
    this.myId();

    this.fetchConsultationPackages();
    this.fetchUserVisitDetails();
    this.getPets();

    this.addFeedback = this.fb.group({
      npsRange: "",
      npsText: ""
    });

    }

  ngOnDestroy() {
    
  }
  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  fetchUserVisitDetails(){
    this.userVisits = [];

    this.http.get(environment.USER_FETCH_VISIT_DATA, this.httpOptions).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userVisits = res.message;
          if(this.userVisits.length == 1){
            this.fetchUserMessagesByDate();
          }
          else{
            if(document.documentElement.clientWidth < 400){
              this.openNav()
            }
          }
        } else {
          this.userVisits = [];
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
      
  }

  selectDate(){

    var selectedVisit;
    var handler = false;

    for (let i = 0; i < this.userVisits.length; i++) {
      const visit = this.userVisits[i];
      

      if(visit._id == (<HTMLSelectElement>document.getElementById('user-select')).value){
        selectedVisit = visit;
        handler = true
      }

      if(i+1 == this.userVisits.length){
        if(!handler){
          alert('Käyntitietoja ei voitu toimittaa.')

        }
      }
      
    }

    var req = {
      date: selectedVisit.createdDate
    }

    this.http.post(environment.USER_MESSAGE_LIST_END_POINT_BY_DATE,req, this.httpOptions).subscribe(
      (res: any) => {
        if (res.status == 201) {
          this.globalMessages = {};
          alert('Viestejä ei löytynyt.');
        } else {
          this.globalMessages = res.message;
          if(window.innerWidth < 400){
            this.closeNav()
          }
          this.colorService.waitStyleColorUserBubbles()
          this.currentActiveMessageList = this.globalMessages;
          this.fetchActiveConsultatData(selectedVisit.doctorID);
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }

  fetchUserMessagesByDate(){

    var selectedVisit;
    var handler = false;


    var req = {
      date: this.userVisits[0].createdDate
    }

    this.http.post(environment.USER_MESSAGE_LIST_END_POINT_BY_DATE,req, this.httpOptions).subscribe(
      (res: any) => {
        
        if (res.status == 201) {
          this.globalMessages = {};
          alert('Viestejä ei löytynyt.');
        } else {
          this.globalMessages = res.message;
          this.currentActiveMessageList = this.globalMessages;
          this.fetchActiveConsultatData(this.userVisits[0].doctorID);
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }



//----------------------------------------------------------------------------------------------------------
// uudet viestit
//----------------------------------------------------------------------------------------------------------

get npsRange() { return this.addFeedback.get('npsRange') }
get npsText() { return this.addFeedback.get('npsText') }

myId(){
  var token = localStorage.getItem('auth');
  var decodedToken = helper.decodeToken(token);

  this.currentUserId = decodedToken.result.id;
  this.currentUserName = decodedToken.result.email;
}


openNav() {
    var width = window.innerWidth;

      document.getElementById("mySidenav").style.width = "90%";

  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  AddFeedBack(){

  }


  logOut() {
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }


  onScroll() {
    let element = this.chatMessageContainer.nativeElement;
    let currentWindowPos = element.scrollHeight - element.scrollTop;
    let diff = currentWindowPos - element.clientHeight;
    diff = Math.abs(diff);
    if (this.disableScrollDown && diff <= 1) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
    //console.log(" On scroll called :: disableScroll down : ", this.disableScrollDown);
  }

  private scrollToBottom(): void {
    //console.log("Scroll to bottom disabled :: ", this.disableScrollDown);
    if (this.disableScrollDown) {
      return
    }
    try {
      this.chatMessageContainer.nativeElement.scrollTo({ top: this.chatMessageContainer.nativeElement.scrollHeight, behavior: 'smooth' })
      // this.myScrollContainer.nativeElement.scroll
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  setAnswer(message, answer) {
    message.answer = answer;
    return;
  }

  OnAnswerSelected(message) {
    let reqBody = {
      id: message.id,
      answer: message.answer
    }
    if (reqBody.answer == null || reqBody.answer == undefined || reqBody.answer.trim() == '') {
      return;
    }

  }

  getPets(){
    var req = {
      id: this.currentUserId
    }
    this.http.post(environment.FIND_PET_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == "Failed"){

          this.pets = [];

        }
        else {
        this.pets = res;
        
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }
  fetchConsultationPackages(){

   this.http.get(environment.FETCH_CONSULTATION_PACKAGES, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200){
        this.feePackages = res.message;
      }
      
      console.log(res);
    }, (err: any) => {
      console.log("Error occured while finding designation for the user : ListData");
    }) 

  }

  drAssitantToId(){

    this.http.get(environment.CHANGE_ASSISTANT_NAME_TO_ID, this.httpOptions).subscribe(
      (res: any) => {

        if(res.status == 200){
          this.DR_ASSISTANT_NAME = res.message;
          this.currentActiveDoctorName = res.message;
          localStorage.setItem('USER', this.currentActiveDoctorName);

        }
        else if(res.status == 201){

        }
  
      },(err: any) => {
        console.log("Erroori tapahtui findFullPetDatassa : ", err);
      })

  }

  fetchActiveConsultatData(userName){

    var req = {
      name: userName
    }

   this.http.post(environment.FETCH_CONSULTANT_DATA, req, this.httpOptions).subscribe(
    (res: any) => {
      if(res.status == 200){
        this.colorService.waitStyleColorUserBubbles();
        this.activeConsultantData = {};
        this.activeConsultantData = res.message;
        return res.status;
      }
      
    }, (err: any) => {
      console.log("Error occured while finding designation for the user : ListData");
    }) 

  }
 

  }
  


