import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-doctor-add-pet',
  templateUrl: './doctor-add-pet.component.html',
  styleUrls: ['./doctor-add-pet.component.css']
})
export class DoctorAddPetComponent implements OnInit, AfterViewInit {

  clinicID = this.authService.getTokenData().clinicID;
  currentUserId='';
  userID: string = this.route.snapshot.params.userID;
  searchTextAll:string;
  output:any;
  speciesArr: any = [];
  breedArr: any = [];
  authToken = localStorage.getItem('auth');
  errMessage = false;
  breedID;
  consentErr = false;
  breedTrue;
  users:any = [];
  form: FormGroup;
  owner = "";
  selectedUser;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private colorService: ColorServiceService,
    private route: ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID);
     }

  ngOnInit(): void {
    this.getSpecies();

    this.form = this.fb.group({
      name: ['', Validators.required],
      sex: ['', Validators.required],
      birthDate: ['', Validators.required],
      species: ['', Validators.required],
      owner: ['', Validators.required],
      breed: ['', Validators.required],
      sterilized: ['', Validators.required],
      weight: ['', Validators.required],
      microCard: "",
      registerNumber: "",
      addInfo: "",
    });
  }

  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  get name() { return this.form.get('name') };
  get weight() { return this.form.get('weight') };
  get sex() { return this.form.get('sex') };
  get sterilized() { return this.form.get('sterilized') };
  get birthDate() { return this.form.get('birthDate') };
  get species() { return this.form.get('species') };
  get breed() { return this.form.get('breed') };
  get microCard() { return this.form.get('microCard') };
  get registerNumber() { return this.form.get('registerNumber') };
  get addInfo() { return this.form.get('addInfo') };


  getSpecies() {
    this.http.get(environment.DOC_FETCH_SPECIES, this.httpOptions).subscribe(
      (res: any) => {
        this.speciesArr = res.message
        console.log(this.speciesArr)
        var result1 = [], result2 = [];
          for (var i = 0; i < this.speciesArr.length; i++) {
            if (this.speciesArr[i].term != "") {
              result1.push(this.speciesArr[i]);
            } else {
              result2.push(this.speciesArr[i]);
              
            }
            if(i+1 == this.speciesArr.length){
              console.log(result1);
              result1.sort();
              result2.sort(function(a, b) {
                var textA = a.species.toUpperCase();
                var textB = b.species.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });

              result1.sort(function(a, b) {
                var textA = a.species.toUpperCase();
                var textB = b.species.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });
            }
          }
          this.speciesArr = result1.concat(result2);


      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  }

  autoFetchRightBreed(e){

    var value = (<HTMLInputElement>document.getElementById('species')).value;
    for(var i = 0; i < this.speciesArr.length; i++){
      //console.log(this.speciesArr[i]);
      var specie = this.speciesArr[i];

      if(specie.species == value){
        if(specie.term) {
          this.fetchBreedByTerm(specie.term);
          this.form.get('breed').setValue("");
          this.breedArr = [];
          var elm = document.getElementById('breed-modal');
          elm.click();
        }
        else {
          console.log('Ei ollut termiä');
          this.form.get('breed').setValue("muu");
          this.breedArr = [];
          this.breedValidate()
        }
      }
    }
    console.log(value);
  }

  fetchBreedByTerm(term){

    if(term != ""){
      this.http.get(environment.DOC_FETCH_BREED_BY_TERM+'/'+term, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200) {
            console.log(term, res.message);
            this.breedArr = res.message

            this.breedArr.sort(function(a, b) {
              var textA = a.breed.toUpperCase();
              var textB = b.breed.toUpperCase();
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });



            console.log(this.breedArr)
          }


        }, (err: any) => {
    
          console.log("Error while login :: ", err);
        })
      }
      else {
        this.breedArr = [];
      }
  }

  breedValidate(){

    var breedValue = (<HTMLInputElement>document.getElementById('breed')).value;
    var breed = document.getElementById('breed');
    if(breedValue.length > 1){
      breed.classList.add('form-control-success');
      this.breedTrue = true;
      
    }
    else{
      breed.classList.remove('form-control-success');
      this.breedTrue = false;
  
    }
  }

  selectBreed(data){
    console.log(data);
    this.form.get('breed').setValue(data.breed);
    this.breedValidate()
    this.breedID = data.id;
    var elm = document.getElementById('close-breed-modal');
    elm.click();
  }

  searchUsers(){
    var userInput = (<HTMLInputElement>document.getElementById('user-search')).value
    if(userInput.length >= 3){
      switch (userInput.toLocaleLowerCase()) {
        case 'normaali':
          userInput = "normal"
          break;
        case 'maksanut':
          userInput = "paid"
          break;
      
        default:userInput
          break;
      }
      this.http.get(environment.SEARCH_USERS+'/'+userInput,this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            this.users = res.message;
            this.colorService.waitStyleColor();

          }  
          else{
            alert('Hakuehdoilla ei löytynyt yhtään tulosta');
          }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }
  }

  showData(){
    var userInput = (<HTMLInputElement>document.getElementById('user-search')).value

    if(userInput.length == 0){
      this.users = [];
    }

  }

  selectUser(user){
    this.selectedUser = user;
    this.owner = user._id;

    this.form.get('owner').setValue(user.firstName +' '+user.lastName );
    document.getElementById('close-owner-modal').click();

  }

  onSubmit() {
    console.log('Submitting');
    if (!this.form.valid) {
      console.log('Form not valid. Please check that fields are correctly filled in');
      var invalidElm = document.getElementsByClassName('ng-invalid');
      console.log(invalidElm);
      return;
    }

    var istrue = false;
    if(this.form.get('sterilized').value == "true"){
      istrue = true;
    }

    this.errMessage = false;
    var elm = document.getElementById('add-pet-form');
    elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block"; 

    var request = {
      name: this.form.get('name').value,
      weight: this.form.get('weight').value,
      sex: this.form.get('sex').value,
      birthDate: this.form.get('birthDate').value,
      sterilized: istrue,
      species: this.form.get('species').value,
      breed: this.form.get('breed').value,
      breedID:this.breedID,
      microCard: this.form.get('microCard').value,
      registerNumber: this.form.get('registerNumber').value,
      addInfo: this.form.get('addInfo').value,
      owner: this.userID,
    }

    this.http.post(environment.DOC_PET, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Register success");
        if (res.status == 200) {
          alert('Lemmikki lisätty onnistuneesti');
          localStorage.removeItem('pet-image');
          this.router.navigate(['/'+this.clinicID+'/pets-register']);
        }
         else {
          this.errMessage = true; 
          alert('Lemmikki lisätty onnistuneesti');
          document.getElementById('loader').style.display="none";
          elm.classList.remove("reg-form-success");
        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  }

}
