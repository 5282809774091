import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked, AfterContentInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WebsocketService } from '../service/websocket.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { JwtHelperService } from "@auth0/angular-jwt";
import { connect,createLocalVideoTrack, Track  } from 'twilio-video';
import { fromEvent, Observable, Subscription } from "rxjs";
import { ColorServiceService } from '../service/color-service.service';
import { FileUploaderService } from '../service/file-uploader.service';

declare var $:any;

const helper = new JwtHelperService;


@Component({
  selector: 'app-doctorhome',
  templateUrl: './doctorhome.component.html',
  styleUrls: ['./doctorhome.component.css']
})
export class DoctorhomeComponent implements OnInit, OnDestroy,AfterViewChecked,AfterContentInit {

  doctorList: any = []; // Doctor list
  globalMessages: any = {};
  listData: any = []; // Map of doctor and message
  currentActiveMessageList: any = []; // Current Acitve message list
  defaultDocterName = 'noUser';
  connErr = false;
  currentUserName = this.authService.getTokenData().email; // nykyisen käyttäjän käyttäjänimi
  currentActiveUserCredit = 0; // alku credit oletus --> tämä tarkastetaan CheckMyCredit func, kutsuttu nginit
  lastMessage=''; //viimeisin toisen käyttäjän lähettämä viesti
  queueList = []; //jono
  createdDiffArr = []; // luotujen diffien arr
  searchTextFinal:string; // haku
  createdFinalArr= []; // finaalien arr
  searchText:string; // haku
  currentActivePatientData: any = []; //nykyisen eläimen data
  diagnosisArr:any = []; // kaikki diagnoosit
  currentActiveUserData:any =[]; //nykyisen käyttäjän data
  inQueueMsg=""; //jonossa viesti
  inQueueErrMsg=""; // jonossa virheviestu
  messageSuccess="";
  messageErr="";
  userPetData = [];
  currentUserId = this.authService.getTokenData().userID;
  clinicID = this.authService.getTokenData().clinicID;
  currentUserFullName = "";
  myImage="";
  currentUserDesignation= this.authService.getTokenData().designation;
  ActivePetId = '';
  creditMsg='';
  consultationClosed = false;
  newMessage:any = [];
  imgOutput='';
  visitHistory:any = [];
  anamnesisData: any = [];
  selectedLinks:any = [];
  visitReady = true;
  hashtagArr:any = [];
  searchTextHashtag;
  currentHashtag: any = {};
  typingHandler = true;
  textAreaHeight = 40;
  roomData: any;
  videoToken: any;
  userDeviceWidth;
  videoReady = false;
  userOnline = false
  currentRoom;
  muted = false;
  videoShow = true;
  localTrack;
  videoAiring;
  selectedCamera;
  cameraOptions;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  allHashtags:any = [];
  attachmentObj;
  fileURL = environment.FETCH_ATTACHMENT;
  enlargerImageKey;


  patientName = "";
  patientAnimal = "";

  currentQuerInfoScreen:any = {};

  infoToPetScreen = [];

  activePatientArr = 0;
  closedPatientArrLength = 0;
  authToken = localStorage.getItem('auth');
  currentActiveDoctorName: string = this.defaultDocterName; // Current Acitve message list

  showReplyBox: boolean = true;
  DR_ASSISTANT_NAME: string = 'Dr. Assistant';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  replyMessage: string = '';
  
  mail: FormGroup;
  ticket: FormGroup;
  endConsultation: FormGroup;

  @ViewChild("chatMessageContainer", { static: true }) chatMessageContainer: ElementRef;
  @ViewChild("searchDiagnosis") searchDiagnosis: ElementRef;
  @ViewChild("diffArr", { static: true }) diffArr: ElementRef;
  disableScrollDown: boolean = false;

  finalAmount: number = 1;
  selectedMessage;

  constructor(
    private router: Router, 
    private fb: FormBuilder,
    private authService: AuthService, 
    private webSocketService: WebsocketService, 
    private http: HttpClient,
    private colorService: ColorServiceService,
    private fileUploaderService: FileUploaderService,
    private orderByPipe: OrderByPipe)
     {
    this.authService.validateLogin();
  }

  ngOnInit() {
    console.log(this.httpOptions.headers.get('auth'),'douken')
    this.getAllCameras();
    this.myId();
    this.drAssitantToId();
    this.LoadMessageList();
    this.webSocketService.connect();
    this.InitWebSocketMessageReceiver();
    this.fetchMyImage();
    this.GetAllInQueue();
    this.tick();
    this.fetchAllHashtags();
    this.notifyMe('po','po')
    this.windowListener();

    this.mail = this.fb.group({
      mail: ['', Validators.required],
      header: ['', Validators.required]
    });
    this.ticket = this.fb.group({
      ticket: ['', Validators.required],
      ticketHeader: ['', Validators.required]
    });
    this.endConsultation = this.fb.group({
      endConsultation: ['', Validators.required],
      vetPrivateNotes: '',
      vetPublicNotes: '',
    });
    }

    ngAfterViewChecked() {

      if(this.connErr == true){
        window.location.reload();
      }
  
    }

  ngAfterContentInit(){
    this.colorService.setVetColors();
  }

  ngOnDestroy() {
    this.webSocketService.disconnect();
    this.resizeSubscription$.unsubscribe();
  }

  windowListener(){
    this.resizeObservable$ = fromEvent(window, 'resize')
    var that = this;
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {

      console.log(window.innerWidth);
      if(window.innerWidth == 990 ){
        that.closeNav('mySidenav')
      }
      else if(window.innerWidth == 991){
        that.closeNav('mySidenav')
      }
    })
  }
  myId(){

    var token = localStorage.getItem('auth');

    if(token == null){
      this.logOutt();
    }
    var decodedToken = helper.decodeToken(token);
    console.log(decodedToken.result);

    this.currentUserId = decodedToken.result.userID;
    this.currentUserName = decodedToken.result.email;
    this.currentUserDesignation = decodedToken.result.designation;

    if(decodedToken.result.designation == 'patient'){
      this.logOutt();

    }
  }

  fetchAllHashtags(){
    this.http.get(environment.FETCH_ALL_HASHTAGS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          var arr = [];
          arr = res.message;
          this.hashtagArr = arr
          this.allHashtags = arr;
          this.colorService.waitStyleColor();
        }
        else{
          this.colorService.waitStyleColor();
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  listenHashtag(e){


    //Open hashtag window if first letter is === '#'
    var userMessage = (<HTMLTextAreaElement>document.getElementById('comment')).value;
     if(userMessage.substring(1,0) === '#'){
       document.getElementById('hashtag-ready').classList.remove('hashtag-overlay-disabled');
       document.getElementById('hashtag-ready').classList.add('hashtag-overlay-active');

       //if no messages written erease everything
        if(userMessage.length == 1){
         //Update list
          if(this.hashtagArr.lenght != this.allHashtags.lenght){
            this.hashtagArr = this.allHashtags;
          }
        }
 
     }
     else{
       //Update hashtag list to full if length dont match
       if(this.hashtagArr.lenght != this.allHashtags.lenght){
        this.hashtagArr = this.allHashtags;
       }
       document.getElementById('hashtag-ready').classList.add('hashtag-overlay-disabled');
       document.getElementById('hashtag-ready').classList.remove('hashtag-overlay-active');
     }
   }

  logOutt() {
    let req = {
      userID: this.currentUserId
    };
  
    this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })

  
  }

  /**
   * Open left sidebar
   */
  sidebar(){

    var potilaat = document.querySelector('.potilaat');
    potilaat.classList.toggle('potilaat-active');

  }

  /**
   * Close right sidebar
   */
  sidebarRight(){

    var potilaat = document.querySelector('.kolmonen');
    potilaat.classList.toggle('kolmonen-active');

  }


  /**
   * Aubscribe websocket messages and pass those to messageHandler function
   */
  InitWebSocketMessageReceiver() {
    this.webSocketService.messages.subscribe(
      (message: any) => {
        console.log("New Message received from server :: ", message);
        try {
          let messageJson = JSON.parse(message);
          this.messageHandler(messageJson);
        } catch (e) {
          console.log("Not able to parse message to json", e);
        }


      }, (err: any) => {
        console.log("Error while getting new message ", err);
      })
  }

//----------------------------------------------------------------------------------------------------------
// New messages notification bubbles
//----------------------------------------------------------------------------------------------------------
    notifications(){

      for(var i = 0; i < this.newMessage.length; i++){
        var name = this.newMessage[i];
          if(name != this.currentActiveDoctorName){
          var elm = document.getElementById('notification-'+name);
            if(elm != null){
              elm.classList.add('visible');
              elm.classList.remove('reg-form-success');
            }
          }
      }
    }

  
  /**
   * 
   * @param userName users email got from UI
   * clears notification bubbles
   */
  clearNotification(userName){

    for(var i = 0; i < this.newMessage.length; i++){
      for (let j = 0; j < this.doctorList.length; j++) {
        const doctor = this.doctorList[j];

        if(doctor._id == userName) {

            userName = doctor.name;
          
            var name = this.newMessage[i];

            if(name == doctor._id){
              var elm = document.getElementById('notification-'+name);
              var ind = this.newMessage.indexOf(doctor._id);
              this.newMessage.splice(ind, 1);
              elm.classList.add('reg-form-success');
              elm.classList.remove('visible');
            }
            else {
              if(elm != null){
                elm.classList.add('visible');
                elm.classList.remove('reg-form-success');
              }
            }
        }
      }
    }
  } 
  

  openNav(e, elm) {
      document.getElementById(elm).style.width = "90%";
  }

  closeNav(elm) {
    if(elm){
      if(window.innerWidth <= 990){
        console.log(document.getElementById(elm))
        document.getElementById(elm).style.width = "0px";
      }
      else{
        if(window.innerWidth >= 991){
          if(elm){
            console.log(elm);
            document.getElementById(elm).style.width = "25%";
          }
        }
      }
    }
  }


  waitScroll() {
    var that = this;

    setTimeout(() =>{
      that.scrollBottomOfChatContainer();
    },100)

  }

  scrollBottomOfChatContainer() {
    var objDiv = document.getElementById("chat-container");
    console.log(objDiv);

    if(objDiv){
      objDiv.scroll({
        top: objDiv.scrollHeight,
        behavior: 'smooth'
      });
    }
  }

  fetchNewMessageForUser(messageJson) {

    var userName = messageJson.from;
    console.log("Request to fetch the new message for user ", userName);
    var lastMessageId = 0;

    if(this.globalMessages[userName]) {
      if(this.globalMessages[userName].length == 2)
      this.prepareMessageList()
    }


    if (this.globalMessages[userName] != null) {
      lastMessageId = this.globalMessages[userName].messageList[this.globalMessages[userName].messageList.length - 1].id;
    }

    let reqBody = {
      lastMessageId: lastMessageId,
      forUserName: userName
    };


    var handler = false;
    if(this.newMessage.length == 0){
      if(this.currentActiveDoctorName != messageJson.from){
        this.newMessage.push(messageJson.from);
        console.log(this.newMessage);
        this.notifications();
      }

    }
    else{
      for (let i = 0; i < this.newMessage.length; i++) {
        const user = this.newMessage[i];
        console.log(this.newMessage, messageJson.from);

        if(this.currentActiveDoctorName == messageJson.from){
          handler = true;
        }

        if(handler == false){
          if(i+1 >= this.newMessage.lenght){
            this.newMessage.push(messageJson.from);
            console.log(this.newMessage);
            this.notifications();
          }
        }
      }
    }


    this.http.post(environment.DOC_MESSAGE_LIST_BY_USER_END_POINT, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Extra data found from server : ", res);
        if (res.length == 0) {
          return;
        }

        if(messageJson.to != userName) {
          this.showNotification('Uusi viesti', res[0].actMessage);
          this.colorService.waitStyleColorVetBubbles();
        }
        else{
          this.colorService.waitStyleColorVetBubbles();
        }

        //global message siis spesifin käyttäjän ja käyttäjän viestilistan 
        if (this.globalMessages[userName] == null) {
          this.globalMessages[userName] = {
            messageList: res

          };


        } else {
          console.log("In else part");


          if (userName == this.DR_ASSISTANT_NAME) {
            var localMessageList = this.globalMessages[userName].messageList;

            for (var i = localMessageList.length - 1; i >= 0; i--) {
              var localMessage = localMessageList[i];
              console.log('localMessage', localMessage);
              if (localMessage.oldMessage === true) {
                break;
              }
              localMessage.oldMessage = true;
            }
          }
          for (var i = 0; i < res.length; i++) {
            this.globalMessages[userName].messageList.push(res[i]);
          }
           this.updateShortMessageAndTime(userName);
           this.waitScroll();
        }

        this.closeSendingModal();

      }, (err: any) => {
        console.log("Error occured while finding extra data from server", err);
      })

  }

  sigleUserList(userID,callback) {
    var err;
    console.log('userr3')

    var userName = userID;

    let reqBody = {
      forUserName: userName
    };

    this.http.post(environment.DOC_LOAD_SINGLE_MESSAGE_LIST, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Extra data found from server SINGLE : ", res);
        if (res.length == 0) {
          return;
        }

        else {

          if(this.globalMessages[userName]){
            if(this.globalMessages[userName].messageList.length > 1) {

              console.log('userr41',this.globalMessages);
              Object.assign(this.globalMessages,{[userName]:{
                messageList:res,
                fullName: this.currentActiveUserData.firstName + " "+this.currentActiveUserData.lastName,
                firstName: this.currentActiveUserData.firstName,
                lastName: this.currentActiveUserData.lastName,
                consultationCredit: this.currentActiveUserData.consultationCredit,
                id: userID,
                consultationStartTime: this.currentActiveUserData.consultationStartTime,
                status: this.currentActiveUserData.status,
                _id: userID,
                userID:userID,
                activePetID: this.currentActivePatientData['_id'],
                activePetName: this.currentActivePatientData['name'],
                activePetSpecies: this.currentActivePatientData['species'],
                activePetBreed: this.currentActivePatientData['breed'],
                name: this.currentActiveUserData.email,
                userName: this.currentActiveUserData.email,
              }})
              this.prepareMessageList()


              console.log('userr42',this.globalMessages)
            }
            else{
              console.log('Mitta oli nolla')
              this.globalMessages[userName].messageList = res;
            }
          }
          else{
            console.log('userr4')
            //Tapaus asiakas täysin uusi
            Object.assign(this.globalMessages,{[userName]:{
              messageList:res,
              fullName: this.currentActiveUserData.firstName + " "+this.currentActiveUserData.lastName,
              consultationCredit: this.currentActiveUserData.consultationCredit,
              id: userID,
              consultationStartTime: this.currentActiveUserData.consultationStartTime,
              status: this.currentActiveUserData.status,
              _id: userID,
              userID:userID,
              activePetID: this.currentActivePatientData['_id'],
              activePetName: this.currentActivePatientData['name'],
              activePetSpecies: this.currentActivePatientData['species'],
              activePetBreed: this.currentActivePatientData['breed'],
              name: this.currentActiveUserData.email,
              userName: this.currentActiveUserData.email,
            }})


            this.prepareMessageList();
          
          } 
           this.updateShortMessageAndTime(userName);
           this.OnSelectDoctor(this.globalMessages[userName]);
           this.waitScroll();
           callback(err,true)
        }

      }, (err: any) => {
        console.log("Error occured while finding extra data from server", err);
      })

  }

//----------------------------------------------------------------------------------------------------------
// päivitetään aika ja viesti listaan
//----------------------------------------------------------------------------------------------------------
  updateShortMessageAndTime(doctorName) {

    for (var i = 0; i < this.doctorList.length; i++) {
      var doctor = this.doctorList[i];
      if (doctor.name == doctorName) {

        console.log("Doctor detail matched :: ", doctor);
        console.log("GLobal message for doctor :: ", this.globalMessages[doctorName]);

        doctor.shortMessage = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage;

        doctor.time = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].createdDate


      }

    }
    this.onScroll();
    this.disableScrollDown = false;
    this.scrollToBottom();

    if(doctorName != undefined && this.globalMessages[doctorName] != undefined){
      console.log(this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage)
      this.scrollBottomOfChatContainer();
      if(this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage = "")
      this.OnSelectUserByUserName(doctorName);
      }

    this.doctorList = this.orderByPipe.transform(this.doctorList, 'time', false);
    
  }

//----------------------------------------------------------------------------------------------------------
// User kirjoittaa
//----------------------------------------------------------------------------------------------------------
  setUserTypingMessage(user) {
    if (this.globalMessages[user] != null && this.globalMessages[user] != undefined) {
      this.globalMessages[user].typing = true;
      var that = this;
      setTimeout(function () {
        that.globalMessages[user].typing = false;
      }, 1000);
    }
  }

//----------------------------------------------------------------------------------------------------------
// mahdollisuus tällä funktiolla lääkärin poistaa näkyvistä listasta käyttäjä (ei atm käytössä)
//----------------------------------------------------------------------------------------------------------

  setOutUser(doctor) {
    var currUser = this.currentActiveDoctorName;
    var element = document.getElementById(currUser)
    element.parentNode.removeChild(element);
  
  }

  //----------------------------------------------------------------------------------------------------------
  // Viestien käsittelijä
  //----------------------------------------------------------------------------------------------------------
  
  messageHandler(messageJson) {
    console.log(messageJson)
    let task = messageJson.task;
    switch (task) {
      case 'NEW_MESSAGE_AVAILABLE': this.fetchNewMessageForUser(messageJson);
        break;
      case 'USER_TYPING_MESSAGE': this.setUserTypingMessage(messageJson.who);
        break;
      case 'REMOVE_CHATBAR': this.removeUserChatbar(messageJson.who);
        break;
      case 'NEW_PERSON_IN_QUEUE': this.pushUserToQueueArr(messageJson.msg);
        break;
      case 'PING': this.ping(messageJson.who);
        break;
      case 'USER_TO_QUEUE': this.pushUserToQueueArr2(messageJson.msg);
        break;
      case 'REMOVE_USER_FROM_QUEUE': this.removeFromQueueAll(messageJson.msg);
        break;
      case 'UPDATE_QUEUE_LIST': this.updateQueueList(messageJson.to);
        break;
      case 'MULTIPLE_TABS': this.openMultipleWindows(messageJson.from);
        break;
      case 'REFRESH_REQUIRED': this.refreshPage(messageJson.from);
        break;
      case 'CONNECTION_CLOSED': this.connectionClosed('e');
        break;
      case 'DEVICE_WIDTH': this.getVideoDeviceWidth(messageJson);
        break;
      case 'DEVICE_COMPABILITY_ALERT':this.alertUserDevice(messageJson);
        break;
      default: console.log("Implementation for task not available :: ", task);
        break;
    }
  }

//----------------------------------------------------------------------------------------------------------
// piilota chat bar
//----------------------------------------------------------------------------------------------------------

alertUserDevice(msg){
  console.log(msg.compProblems);
  this.userOnline = true;
  if(msg.compProblems){
    alert('Käyttäjällä päätelaitteen kanssa havaittu yhteensopivuus ongelmia. Tutustu ennen valitsemista. Agentti: '+msg.msg);
    (<HTMLButtonElement>document.getElementById('join-video-btn')).disabled = false;
    return;
  }
  else{
    (<HTMLButtonElement>document.getElementById('join-video-btn')).disabled = false;
  }
}
getVideoDeviceWidth(msg){

  this.userDeviceWidth = {
    width:msg.width,
    height:msg.height,
  };


  console.log(msg);

  this.getVideoToken();

  this.videoReady = true;
}

refreshPage(who){
  location.reload();
}

connectionClosed(e){
  this.connErr = true;
}


connectDisconnectedConnection() {
  this.webSocketService.disconnect();
  this.LoadMessageList();
  this.webSocketService.connect(); //2
  console.log(document.getElementById('close-conn-err-modal'))
  
  this.connErr = false;
}

updateQueueList(userName){
  this.GetAllInQueue();
}

removeFromQueueAll(userID){
  console.log(userID);
  var tag = userID.userID.toString();
  var deletetableElm = document.getElementById(tag);
  console.log('poistetaan',tag)
  deletetableElm.style.display="none";
  deletetableElm.parentNode.removeChild(deletetableElm);

}

  removeUserChatbar(user){
    if (this.globalMessages[user] != null && this.globalMessages[user] != undefined) {
      this.showReplyBox = false;
    }
  }

  ping(poo){
  }

  tick() {

    setTimeout(()=>{   
      this.webSocketService.sendMessage({
        task: 'PING',
      })
      this.tick()
      }, 35000);

    console.log('Tick '); 
  }
//----------------------------------------------------------------------------------------------------------
// 
//----------------------------------------------------------------------------------------------------------
  pushUserToQueueArr(msg) {
    this.GetAllInQueue();
  }
  pushUserToQueueArr2(msg) {
    this.GetAllInQueue();
  }

//----------------------------------------------------------------------------------------------------------
// viestilista
//----------------------------------------------------------------------------------------------------------

getListData() {

  var req = {
    userNameArr: Object.keys(this.globalMessages)
  }

  if(req.userNameArr.length != 0) { 
    this.http.post(environment.DOC_LIST_DATA_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {

        console.log(Object.keys(this.globalMessages)[0]);
        var newArr = res;
        var names = Object.keys(this.globalMessages)

    

        for(var i = 0; i < names.length; i++){
          var name = names[i];
          console.log(this.globalMessages[name]['messageList'][0]['from'],'Mitäää')
          for(var j = 0; j < newArr.length; j++){
            if(this.globalMessages[name]['messageList'][0]['from'].toString() == newArr[j].userID.toString()){
            
              var eeli = Object.assign(this.globalMessages[name],newArr[j])
              } 

          }
        }

        this.prepareMessageList();

        
        console.log(res);
      }, (err: any) => {
        console.log("Error occured while finding designation for the user : ListData");
      }) 
  }
}

  LoadMessageList() {
    this.http.get(environment.DOC_MESSAGE_LIST_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Message list found :: ", res);
        if (res == null || res == undefined) {
          this.globalMessages = {};

        } else {
          this.globalMessages = res;
          this.listData = Object.keys(res);
          this.getListData();

        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }

  LoadMessageListForSingleUser(userID) {

    var req = {
      forUseranme:userID
    }

    this.http.post(environment.DOC_LOAD_SINGLE_MESSAGE_LIST,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Single Message list found :: ", res);
        if (res == null || res == undefined) {
          this.globalMessages = {};

        } else {
          this.globalMessages = res;
          this.listData = Object.keys(res);
          this.getListData();

        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }

//----------------------------------------------------------------------------------------------------------
// viestilista
//----------------------------------------------------------------------------------------------------------


//tähän kytketty myös käyttäjän kokonimi, joka syötetään visitteihin
fetchMyImage() {
  this.http.get(environment.DOC_IMAGE_END_POINT, this.httpOptions).subscribe(
    (res: any) => {
      console.log("Image found :: ", res);
      if (res.status == 200) {
        this.myImage = "";
        this.myImage = res.message.wantedImage.key;
        this.currentUserFullName = res.message.fullName;


        
      }else {
        console.log('Err happened')
      }

    }, (err: any) => {
      console.log("Error occured while finding message list for user");
    })
}


  
//----------------------------------------------------------------------------------------------------------
// Haetaan aktiivisen käyttäjän,eläimen data modaalia varten
//----------------------------------------------------------------------------------------------------------
  
findPetData(userID) {
  //console.log("Request to update user designation : ", userName);
  console.log('nyt on käyttis ', userID);
  var dude = localStorage.getItem("ACTIVE_USER");
  if(dude != this.defaultDocterName || dude != "" ||dude != undefined || dude != null ||  userID != this.defaultDocterName){
  this.http.post(environment.DOC_FETCH_ACTIVE_USERS_PET, { userID: userID }, this.httpOptions).subscribe(
    (res: any) => {
      console.log('fetchattu data',res);
      this.currentActivePatientData = [];
      this.ActivePetId ='';
      if(res.status == 200){
      this.currentActivePatientData = res.result[0];
      this.countPetAge();
      }
      else{
        console.log('virhe haettaessa aktiivisen lemmikin tietoja')
      }
      console.log("Meitsi on :: ", res.designation);

    },(err: any) => {
      console.log("Erroori tapahtui findFullPetDatassa : ", err);
    })
  }
}

findPetDataCB(userID,callback) {
  //console.log("Request to update user designation : ", userName);
  var err;
  this.http.post(environment.DOC_FETCH_ACTIVE_USERS_PET, { userID: userID }, this.httpOptions).subscribe(
    (res: any) => {
      console.log('fetchattu data',res);
      this.currentActivePatientData = [];
      this.ActivePetId ='';
      if(res.status == 200){
      this.currentActivePatientData = res.result[0];
      this.countPetAge();
      callback(err,true);
      }
      else{
        console.log('virhe haettaessa aktiivisen lemmikin tietoja');
        callback(err,false);
      }
      console.log("Meitsi on :: ", res.designation);

    },(err: any) => {
      console.log("Erroori tapahtui findFullPetDatassa : ", err);
    })
}

countPetAge(){
  var sdt = new Date(this.currentActivePatientData['birthDate']);
  var ntd = new Date();
  var diff = Math.floor(ntd.getTime() - sdt.getTime());
  var day = 1000 * 60 * 60 * 24;

  var days = Math.floor(diff/day);
  var months = Math.floor(days/31);
  var ey = Math.floor(months/12)

  var dy = months / 12;
  var my = dy % 1;
  var ry = (my * 12).toFixed(0);

  var msg = ey + " vuotta "+ry+" kk" 

  Object.assign(this.currentActivePatientData, {age: msg})

}


//----------------------------------------------------------------------------------------------------------
// Haetaan aktiivisen käyttäjän,eläimen data modaalia varten
//----------------------------------------------------------------------------------------------------------
  
findUserData(userID) {
  this.http.post(environment.DOC_FETCH_ACTIVE_USER_DATA, { userID: userID }, this.httpOptions).subscribe(
    (res: any) => {
      console.log('fetchattu data täällä',res);
      this.currentActiveUserData = [];
      this.userPetData = [];
      if(res.status == 200){
      this.currentActiveUserData = res.result;

      this.styleActiveDoctor(this.currentActiveUserData.email);
      this.userPetData = res.petData;
      }
      else{
        alert('virhe käyttäjä dataa haettaessa');
      }
      console.log("Meitsi on :: ", res.designation);

    })
}

findUserDataCB(userID,callback) {
  var err;
  //console.log("Request to update user designation : ", userName);
  this.http.post(environment.DOC_FETCH_ACTIVE_USER_DATA, { userID: userID }, this.httpOptions).subscribe(
    (res: any) => {
      console.log('fetchattu data täällä',res);
      this.currentActiveUserData = [];
      this.userPetData = [];
      if(res.status == 200){
      this.currentActiveUserData = res.result;
      console.log('userr212', this.currentActiveUserData);
      callback(err,true);

      this.styleActiveDoctor(this.currentActiveUserData.email);
      this.userPetData = res.petData;
      }
      else{
        alert('virhe käyttäjä dataa haettaessa');
        callback(err,false);

      }
      console.log("Meitsi on :: ", res.designation);

    })
}


//----------------------------------------------------------------------------------------------------------
// Viestilista
//----------------------------------------------------------------------------------------------------------


  prepareMessageList() {
    this.activePatientArr = 0;
    this.closedPatientArrLength = 0;
    var localDoctorList = [];
    var oliIf = false;
    var oliUser="";
    var doctorNames = Object.keys(this.globalMessages);
    console.log('tuli tänne', doctorNames)
    for (var i = 0; i < doctorNames.length; i++) {
      var doctorName = doctorNames[i];
      this.consultationTimer(doctorName);
            
      if(this.globalMessages[doctorName].firstName == undefined || this.globalMessages[doctorName].firstName == "undefined"){
        console.log('Tänne tuli iffiin',doctorName);

        
        oliIf = true;
        oliUser = doctorName;
          
         var doctorDetail = {
          fullName: doctorName,
          consultationCredit: this.globalMessages[doctorName].consultationCredit,
          id: doctorName,
          shortMessage: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage,
          status: this.globalMessages[doctorName].status,
          consultationStartTime: this.globalMessages[doctorName].consultationStartTime,
          _id: this.globalMessages[doctorName].userID,
          activePetID: this.globalMessages[doctorName].activePetID,
          activePetName: this.globalMessages[doctorName].activePetName,
          activePetSpecies: this.globalMessages[doctorName].activePetSpecies,
          activePetBreed: this.globalMessages[doctorName].activePetBreed,
          time: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].createdDate,
          name: this.globalMessages[doctorName].userLoggIn,
        }

      
      }
      else {
      var doctorDetail = {
        fullName: this.globalMessages[doctorName].firstName + " "+this.globalMessages[doctorName].lastName,
        consultationCredit: this.globalMessages[doctorName].consultationCredit,
        id: doctorName,
        consultationStartTime: this.globalMessages[doctorName].consultationStartTime,
        shortMessage: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage,
        status: this.globalMessages[doctorName].status,
        _id: this.globalMessages[doctorName].userID,
        activePetID: this.globalMessages[doctorName].activePetID,
        activePetName: this.globalMessages[doctorName].activePetName,
        activePetSpecies: this.globalMessages[doctorName].activePetSpecies,
        activePetBreed: this.globalMessages[doctorName].activePetBreed,
        time: this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].createdDate,
        name: this.globalMessages[doctorName].userLoggIn,


      }


      if(this.currentUserId == doctorDetail.status){
        this.activePatientArr++;
      }
      else{
        this.closedPatientArrLength++;
      }

      
    }
      localDoctorList.push(doctorDetail);
      

      if(doctorName == this.currentActiveDoctorName){
        this.waitActive(this.globalMessages[doctorName].userLoggIn)
      }

      if(this.doctorList.length == i){
        this.colorService.waitStyleColor();
        if(oliIf == true){
          this.LoadMessageList();
          this.currentActiveDoctorName = oliUser;
          localStorage.setItem('ACTIVE_USER', oliUser);
          console.log(localStorage.getItem('ACTIVE_USER'))
        }
      }

    }
    
  
    
    this.doctorList = this.orderByPipe.transform(localDoctorList, 'time', false);
    this.setTextareaSaveReady();

     if (this.doctorList.length > 0) {
      var activeUserNameFromLocalStorage = localStorage.getItem('ACTIVE_USER');
      if (activeUserNameFromLocalStorage == null || this.currentActiveDoctorName == null || activeUserNameFromLocalStorage == this.defaultDocterName) {
        this.currentActiveDoctorName = this.doctorList[0].id;
        this.OnSelectUserByUserNameOnLoad(this.currentActiveDoctorName)

        localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);
      } 
      else if(oliIf == true){
        this.currentActiveDoctorName = oliUser;
        localStorage.setItem('ACTIVE_USER', oliUser);
        
      }
      else {
        this.currentActiveDoctorName = activeUserNameFromLocalStorage;
        this.OnSelectUserByUserNameOnLoad(this.currentActiveDoctorName)
        
      }

    } 
    console.log(this.activePatientArr, 'GEELII');


  }


  logOut() {
    console.log("Request to logout");
    //this.authService.doLogOut();
    this.router.navigate(['doctor-login']);
  }




  onScroll() {
    let element = this.chatMessageContainer.nativeElement;
    let currentWindowPos = element.scrollHeight - element.scrollTop;
    let diff = currentWindowPos - element.clientHeight;
    diff = Math.abs(diff);
    if (this.disableScrollDown && diff <= 1) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
    //console.log(" On scroll called :: disableScroll down : ", this.disableScrollDown);
  }

  private scrollToBottom(): void {
     //console.log("Scroll to bottom disabled :: ", this.disableScrollDown);
    if (this.disableScrollDown) {
      return
    }
    try {
      this.chatMessageContainer.nativeElement.scrollTo({ top: this.chatMessageContainer.nativeElement.scrollHeight, behavior: 'smooth' })
      // this.myScrollContainer.nativeElement.scroll
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { } 


  }


  setAnswer(message, answer) {
    message.answer = answer;
    return true;
  }



  OnAnswerSelected(message) {
    console.log("Answer selected for :: ", message);
    let reqBody = {
      id: message.id,
      answer: message.answer
    }
    if (reqBody.answer == null || reqBody.answer == undefined || reqBody.answer.trim() == '') {
      console.log("valid answer not available");
      return;
    }

    this.http.post(environment.DOC_ANSWER_SELECTED_END_POINT, reqBody, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Answer selected response : ", res);
      }, (err: any) => {
        console.log("Error while answer selected : ", err);
      }
    )
  }
  OnSelectUserByUserNameOnLoad(userName){

      //console.log('oli vurrentti ::', userName);
      for(var i = 0; i <= this.doctorList.length; i++ ){
        var doctor = this.doctorList[i];


          if(doctor == undefined){
            console.log('userName on undefined')
            this.OnSelectDoctor(userName)
          }
          else if(doctor.id == userName){
            this.OnSelectDoctorNoLoad(doctor)
        }
      
  }

  }

  OnSelectUserByUserName(userName){

    if(userName != this.currentActiveDoctorName ){
      //console.log('oli vurrentti ::', userName);
      for(var i = 0; i <= this.doctorList.length; i++ ){
        var doctor = this.doctorList[i];
        if(doctor != undefined){
        console.log('username on ::', userName);
        if(doctor.name != undefined){
        console.log(doctor.name, 'usern maani on')
        }
          if(doctor == undefined){
            console.log('userName on undefined')
            this.OnSelectDoctor(userName)
            
          }
          else if(doctor.name == userName){
            console.log(doctor, "tää on mätsi");
            this.OnSelectDoctor(doctor)
          this.autoOpenUser(userName);
        }
    
      }
  }
}
  }

  OnSelectUserById(userID, doctor,querUserName){

        for(var i = 0; i< this.doctorList.length; i++ ){
          doctor = this.doctorList[i];
         
          if(doctor.name == querUserName){
            console.log('opjekti',doctor);
            this.OnSelectDoctor(doctor) 
          }
    
        }

        this.autoOpenUser(userID);
  }

  OnSelectDoctor(doctor) {

    var chat = document.getElementById('chat-container');
    var patient = document.getElementById('active-patient-data');
    var visits = document.getElementById('visit-data-info');
    var userData = document.getElementById('active-user-data-info');
    var anamnesis = document.getElementById('anamnesis-data-info');
    var info = document.getElementById('quer-data-info-screen');

    patient.style.display = "none";
    anamnesis.style.display = "none";
    visits.style.display = "none";
    userData.style.display = "none";
    info.style.display = "none";

    chat.style.display = "block";

    
    this.scrollToBottom();
    if(doctor.fullName != undefined){
      this.patientName = doctor.fullName;
      if(doctor.activePetName != undefined){
        this.patientAnimal = '"' + doctor.activePetName + '" ' + doctor.activePetBreed;
      }
      else {
        this.patientAnimal = "*Ei aktiivista lemmikkiä"
      }
      
    }



    if (this.globalMessages[doctor.id] == null || this.globalMessages[doctor.id] == undefined) {
      console.log("Detail for doctor not available");
      return;
    } 

    if(this.currentActiveDoctorName != doctor.id){
      this.currentActiveDoctorName = doctor.id;
      this.lastMessage = doctor.shortMessage;
      localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);
      this.currentActiveMessageList = this.globalMessages[doctor.id].messageList;
      this.showReplyBox = false;
      if (this.currentActiveDoctorName != this.DR_ASSISTANT_NAME) {
        this.showReplyBox = true;
      }

      this.styleActiveDoctor(doctor.name);

      this.findUserData(this.currentActiveDoctorName);
      this.findPetData(this.currentActiveDoctorName);

      this.setTextBoxForUser(doctor.id,'replyBox');

  }

  var box = document.getElementById('reply-box');
  var redClose = document.getElementById('red-close-btn');
  var dotClose = document.getElementById('dot-close-chat');
  var queBtn = document.getElementById('add-queue-btn');

  if(doctor.status != this.currentUserId){
    box.classList.add("reg-form-success");
    if(redClose){
      redClose.classList.add("reg-form-success");
    }
    dotClose.classList.add("reg-form-success");
    queBtn.classList.add("reg-form-success");
  }
  else {
    if(box.classList.contains("reg-form-success")){
      box.classList.remove("reg-form-success");
      redClose.classList.remove("reg-form-success");
      dotClose.classList.remove("reg-form-success");
      queBtn.classList.remove("reg-form-success");
    }

  }

  this.colorService.waitStyleColorVetBubbles();

  }

  styleActiveDoctor(email){

    if(this.doctorList.length > 0) {
      
        for(var i = 0; i < this.doctorList.length; i ++){
          var nDoc = this.doctorList[i];
          var nDocElm = document.getElementById(nDoc.name);

            if(nDocElm){
              if(nDocElm.classList.contains('activeDoctor')){
                nDocElm.classList.remove('activeDoctor');
              }
            }
            if (nDoc.name == email){
              var active = document.getElementById(email);
              if(active){
                active.classList.add('activeDoctor');
              }
            }
        
      }
      }

  }

  OnSelectDoctorNoLoad(doctor) {

    if(this.currentActiveUserData.length == 0){
      this.findUserData(doctor.id);
      this.findPetData(doctor.id);
      this.waitScroll();
    }

    this.waitScroll();
    if(doctor.fullName != undefined){
      this.patientName = doctor.fullName;
      console.log('Kitus', this.patientName)
      if(doctor.activePetName != undefined){
        this.patientAnimal = '"' + doctor.activePetName + '" ' + doctor.activePetBreed;
      }
      else {
        this.patientAnimal = "*Ei aktiivista lemmikkiä"
      }

    }


    console.log("Request to select doctor :: ", doctor.status);
    var box = document.getElementById('reply-box');
    var redClose = document.getElementById('red-close-btn');
    var dotClose = document.getElementById('dot-close-chat');
    var queBtn = document.getElementById('add-queue-btn');

    if(doctor.status != this.currentUserId){
      if(box){
        box.classList.add("reg-form-success");
      }
      if(redClose){
        redClose.classList.add("reg-form-success");
      }
      dotClose.classList.add("reg-form-success");
      queBtn.classList.add("reg-form-success");
    }
    else {
      if(box){
        if(box.classList.contains("reg-form-success")){
          box.classList.remove("reg-form-success");
          if(redClose){
            redClose.classList.remove("reg-form-success");
          }
          dotClose.classList.remove("reg-form-success");
          queBtn.classList.remove("reg-form-success");
        }
      }

    }


    this.setTextBoxForUser(doctor.id,'replyBox');

    if (this.globalMessages[doctor.id] == null || this.globalMessages[doctor.id] == undefined) {
      console.log("Detail for doctor not available");
      return;
    } 
    this.currentActiveDoctorName = doctor.id;
    this.lastMessage = doctor.shortMessage;
    localStorage.setItem('ACTIVE_USER', this.currentActiveDoctorName);
    this.currentActiveMessageList = this.globalMessages[doctor.id].messageList;
    this.showReplyBox = false;
    if (this.currentActiveDoctorName != this.DR_ASSISTANT_NAME) {
      this.showReplyBox = true;
    }
  
  }



  endConsultationMessage() {
    console.log("on end consultation :: ", this.replyMessage);


    this.webSocketService.sendMessage({
      task: 'END_CONSULTATION',
      from: this.currentUserId,
      to: this.currentActiveDoctorName,
      msg: 'loppu'
    })
  }

  sendUserBackToQueueMessage() {
    console.log("on send back to queue :: ", this.replyMessage);

    this.webSocketService.sendMessage({
      task: 'SEND_BACK_TO_QUEUE',
      from: this.currentUserId,
      to: this.currentActiveDoctorName,
      msg: 'Jonoon'
    })


  }


  OnSendReply(e) {


    var textarea = document.getElementById("comment");
    document.getElementById('chat-container').style.marginTop = "0px";

    textarea.setAttribute('style', 'height:40px!important')

    let userMessage = this.replyMessage;

    if(userMessage.substring(1,0) === '#'){
      console.log(userMessage);
      this.fetchMyHashtag(userMessage,true);
    }
    else { 

    console.log("on send reply called :: ", this.replyMessage);
    e.preventDefault();
    this.replyMessage = '';

    (<HTMLTextAreaElement>document.getElementById("comment")).value = "";

    if (userMessage == null || userMessage == undefined || userMessage.trim() == '') {
      return;
    }
    // Sanitaatio
    if(userMessage.includes('script')){
      alert("Käytät kiellettyjä merkkejä, ole hyvä ja korjaa merkintäsi. Kiellettyjä ovat, 'script'");
      return;
    }
    else {
    if(this.currentActiveDoctorName != this.DR_ASSISTANT_NAME){
      this.webSocketService.sendMessage({
        task: 'TEXT_MESSAGE',
        from: this.currentUserId,
        to: this.currentActiveDoctorName,
        msg: userMessage
      })
      this.clearMessageFromLocalStorage();
    }
    else{
      alert('Wopsaa, eipäs lähetetä viestiä tyhjälle käyttäjälle.');
      return;
    }
  }
  } 
  }

//----------------------------------------------------------------------------------------------------------
// Seuraavat kolme käsittelee kuvan lähetystä, inject funktiota kutsutaan kun painetaan 
// "valitse" painiketta UI:ssä, katsotaan että otetaan vain ensimmäinen lähetetty tiedosto, 
// getBase64 muuttaa img base64 formaattiin OnSendReplyFile lähettää kyseisen kuvan serverille
//----------------------------------------------------------------------------------------------------------

fetchMyHashtag(userMessage, value){


  var req = {
    hashtag: userMessage
  }

    this.http.post(environment.FIND_MY_SINGLE_HASHTAG, req, this.httpOptions).subscribe(
    (res: any) => {
      if( res.status == 200 ) {
        if(value == true){
          
          this.hashtagTextArea(res.message)
        }
        else {
          console.log(this.currentHashtag, 'JOI')
          this.currentHashtag = {};
          Object.assign(this.currentHashtag, {hashtag: userMessage, text:res.message});
        }
      }

      else {
        console.log('Virhe haettaessa hashtagia')
      }
     
    }, (err: any) => {

      console.log("Error while login :: ", err);
    }
  )
}

searchHashtag(e){
  console.log(e)
  var userInput = (<HTMLInputElement>document.getElementById('comment')).value
  if(userInput.substring(1,0) === '#'){
    if(userInput.length >= 4){
      //console.log(userInput);
      userInput = userInput.replace('#', '$');
      //console.log(environment.SEARCH_HASHTAG+'/'+userInput);


      if(e) { // IE                  
        var keynum = e.keyCode;
      } else if(e){ // Netscape/Firefox/Opera                 
        var keynum = e.which;
      }
      console.log(e)
      if(keynum != 8){

      this.http.get(environment.SEARCH_HASHTAG+'/'+userInput,this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            var arr = res.message.doctors.concat(res.message.clinics)
            this.hashtagArr = arr;
            console.log(res, arr);
          }  
          else{
            console.log('No match');
          }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
      }
    }
  }
  if(userInput.length == 0){
    this.hashtagArr = this.allHashtags;
  }
}

notifyMe(po, title) {
  // Let's check if the browser supports notifications
   //console.log(Notification.permission);
 // Let's check if the browser supports notifications
 if (!("Notification" in window)) {
   //this.notificationService("Selain ei ole yhteensopiva ilmoitusten kanssa. Yhteensopivuus Google Chromen kanssa", 4000);
   return;
 }
 
 // Let's check whether notification permissions have already been granted
 else if (Notification.permission === "granted") {
 }
 
 // Otherwise, we need to ask the user for permission
 else if (Notification.permission !== "denied") {
   Notification.requestPermission().then(function (permission) {
     // If the user accepts, let's create a notification
     if (permission === "granted") {
 /*       const notification = new Notification("Ilmoitusasetukset otettu käyttöön", {
         body:"Voit jättää tämän huomioimatta"
       }) */
     }
     else{
       this.notificationService('Et ole antanut lupaa ilmoituksille! Ole hyvä ja korjaa selainasetuksesi', 5000)
     }
   });
 }
 }

showNotification(title, message){
  if (("Notification" in window)) {
    const notification = new Notification(title, {
      body:message
    })
  }
}

hashtagTextArea(msg){

  var hashtag = document.getElementById('hashtag-ready');

  if(hashtag.classList.contains('hashtag-overlay-active')) {
    hashtag.classList.remove('hashtag-overlay-active');
    hashtag.classList.add('hashtag-overlay-disabled');
  }
  (<HTMLInputElement>document.getElementById('comment')).value = msg;
  this.textareaToAmount();
  this.replyMessage = msg;
}

    getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  inject(){ 
    var file = document.querySelector('input[type=file]')['files'][0];
    this.getBase64(file).then(
      data => {console.log(data);  this.OnSendReplyFile(data);}
    
    ); 
  }

  OnSendReplyFile(data) {
    console.log('eka',data);
    console.log("on send reply called :: ", this.replyMessage);

  this.replyMessage = '';

  this.webSocketService.sendMessage({
    task: 'TEXT_MESSAGE',
    from: this.currentUserId,
    to: this.currentActiveDoctorName,
    msg: data
  }) 
  }

  UserTypingMessage() {
    var that = this;
    if(this.typingHandler == true) {
      that.typingHandler = false;
      that.webSocketService.sendMessage({
        task: 'USER_TYPING_MESSAGE',
        to: that.currentActiveDoctorName,
        from: that.currentUserId
      });
      setTimeout(function () {
        that.typingHandler = true;
      }, 1500);
    }
  }

  onResize(e){
    
  }
  
  CloseInfoScreen(e,id){
    console.log('auki')
    var hidElm = document.getElementById(id);

    var showElm = document.getElementById('chat-container');

    hidElm.style.display = "none";
    showElm.style.display = "block";
  }

  RemoveChatBar() {
    console.log("Chatbar removed");
    
    this.http.post(environment.DECREASE_CONSULTATION_CREDIT, {userID: this.currentActiveDoctorName}, this.httpOptions).subscribe(
      (res: any) => {
        this.creditMsg = '';
        if(res.status == 200){
          this.creditMsg = res.message;
          this.changePetActiveStatusFalse();
          var doctorNames = Object.keys(this.globalMessages);
          console.log(this.globalMessages[this.currentActiveDoctorName].consultationCredit, 'crediitti')
          this.globalMessages[this.currentActiveDoctorName].consultationCredit = 0;
          console.log(this.globalMessages[this.currentActiveDoctorName].consultationCredit, 'crediitti2')
          this.addVistiToVisits();
          this.waitAlert();
          
          

          this.webSocketService.sendMessage({
            task: 'REMOVE_CHATBAR',
            to: this.currentActiveDoctorName,
            from: this.currentUserId
          });

        }
        else{
          this.creditMsg = res.message
        }

    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
  );
    
  }


  changePetActiveStatusFalse(){

    var req = {
      petID: this.currentActivePatientData._id
    }

    this.http.post(environment.DOC_SET_PET_STATUS_FALSE_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
          console.log('Lemmikki statuksen kääntäminen onnistui');
        }
    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
  );

  }


  RemoveUserFromQueueDoctorView(userID) {

    var req = {
      userID: userID
    }
    this.http.post(environment.DOC_FIND_ALL_DOCTORS, req, this.httpOptions).subscribe(
      (res: any) => {

           this.webSocketService.sendMessage({
            task:'REMOVE_USER_FROM_QUEUE',
            to: res,
            data: req
          })

        this.colorService.waitStyleColor();
    

    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
    )
    var deletetableElm = document.getElementById(userID);
    deletetableElm.parentNode.removeChild(deletetableElm);

  }

//----------------------------------------------------------------------------------------------------------
// käytössä
//----------------------------------------------------------------------------------------------------------

  // punaista X painiketta painamalla poistetaan käyttäjän Credit
  decreaseConsultationCreditbyOne() {
    this.http.post(environment.DECREASE_CONSULTATION_CREDIT, {userName: this.currentActiveDoctorName}, this.httpOptions).subscribe(
      (res: any) => {
    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
  );
  }

  // haetaan serveriltä users.js tiedostosta nykyisen käyttäjän credit, tulee str muodossa, 
  // muutetaan int luvuksi funktiota kutsutaan nginit aliohjelmassa
  CheckMyCredit() {
    var dude = localStorage.getItem("ACTIVE_USER");
    if(dude != this.defaultDocterName || dude != "" ||dude != undefined || dude != null || this.currentActiveDoctorName != "" || this.currentActiveDoctorName != undefined || this.currentActiveDoctorName != null ){
      console.log(this.currentActiveDoctorName, 'TARKISTA')
    this.http.post(environment.DOC_GET_CREDIT_END_POINT,{userID: this.currentActiveDoctorName}, this.httpOptions).subscribe(
      (res: any) => {
        var dotClose = document.getElementById('dot-close-chat');
        var redClose = document.getElementById('red-close-btn');
        this.notifications();

        console.log('vastaus on', res);

        if(res.status == 200) {
        var intCredit = parseInt(res.message)
        this.currentActiveUserCredit = intCredit;
        
        if(redClose && dotClose){
          if(this.currentActiveUserCredit == 0) {
            redClose.style.display="none";
            dotClose.style.display="none";
          }
          else{
            redClose.style.display="block";
            dotClose.style.display="block";
          }
        }

        }
        if(res.status == 201){
          this.currentActiveUserCredit = 0;
          if(redClose){
            redClose.style.display="none";

          }
          if(dotClose){
            dotClose.style.display="none";

          }

        }

      }, (err: any) => {
        console.log("Error occured while Credit",err);
      }
    )
    }
  }



  GetAllInQueue() {
    this.http.get(environment.ALL_IN_QUEUE_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          this.queueList = res.message;
          this.queueTimer();
          document.getElementById('queue-btn').style.backgroundColor = "inherit";
          this.colorService.waitStyleColorVetBubbles()
        }
        else if(res.status == 201) {
          this.queueList = [];
          document.getElementById('queue-btn').style.backgroundColor = "#fff";
        }
        else{
          alert(res.message);
        }

      }, (err: any) => {
        console.log("Kaikki jonossa");
      }
    )
  }

  queueTimer(){

          // Update the count down every 1 second
          var that = this;
          var first = false
          var second = false
          var x = setInterval(function() {
            if(that.queueList.length > 0 ){
              for (let i = 0; i < that.queueList.length; i++) {
                const quer = that.queueList[i];
                var countDownDate = new Date(quer.queStartTime).getTime();
            // Get today's date and time
            var now = Math.floor(new Date().getTime());

            // Find the distance between now and the count down date
            var distance = Math.floor(now - countDownDate);

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            if(document.getElementById("quer-timer-"+quer._id) != null || document.getElementById("quer-timer-"+quer._id) != undefined){
              document.getElementById("quer-timer-"+quer._id).innerHTML =  hours + "h "
              + minutes + "m " + seconds + "s ";

              if(distance > 180000 && distance < 360000) {
                if(first == false){
                  first = true;
                  that.showNotification('Jonottanut yli 3 min', "Asiakas on jonottanut yli 3min ilmoitus 2/3")
                }
                document.getElementById('queue-wrap-'+quer._id).style.backgroundColor = "#FFF3CD";
                //this.paintQueueText();
                if(document.getElementById('queue-btn').style.backgroundColor != "#F8D7DA") {
                  document.getElementById('queue-btn').style.backgroundColor = "#FFF3CD";
                }
                
              }
              else if( distance > 360000) {
                if(second == false){
                  second = true;
                  that.showNotification('Jonottanut yli 6 min', "Asiakas on jonottanut yli 6min ilmoitus 3/3")
                }
                document.getElementById('queue-wrap-'+quer._id).style.backgroundColor = "#F8D7DA";
                //this.paintQueueText();
                document.getElementById('queue-btn').style.backgroundColor = "#F8D7DA";
              }
              document.getElementById("quer-front-timer-"+quer._id).innerHTML =  hours + "h "
              + minutes + "m " + seconds + "s ";
            }
          }
        }

          }, 1000);

  }



  consultationTimer(doctorName){

        var countDownDate = Math.floor(new Date(this.globalMessages[doctorName].consultationStartTime).getTime());

          // Update the count down every 1 second
          var x = setInterval(function() {

            // Get today's date and time
            var now = new Date().getTime();
            // Find the distance between now and the count down date
            var distance = Math.floor(now) - Math.floor(countDownDate);

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            if(document.getElementById("consultation-timer-"+doctorName) != null || document.getElementById("consultation-timer-"+doctorName) != undefined){
              document.getElementById("consultation-timer-"+doctorName).innerHTML = days + "d " + hours + "h "
              + minutes + "m " + seconds + "s ";
            }

          }, 1000);
      }    

//----------------------------------------------------------------------------------------------------------
// tarkastetaan toisen käyttäjän credit
//----------------------------------------------------------------------------------------------------------
  
CheckPatientCredit() {
    this.http.post(environment.CHECK_CREDIT_END_POINT,{userName: this.currentActiveDoctorName}, this.httpOptions).subscribe(
      (res: any) => {
        var intCredit = parseInt(res)
        console.log("Crediitti :: ",intCredit );
      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      }
    )
  }

  /**
   * Asiakkaan lisääminen konsultioon
   * 1) lähetetään lääkärin data asiakkalle
   * 2) Poistetaan jonosta
   * 3) Valitaan kyseinen käyttäjä
   * 4) Vaihdetaan käyttäjän status "paid" => lääkärin id:ksi
   * 5) Päivitetään jono
   * 6) Haetaan konsultaatioon tulleen käyttäjän data
   * 7) Haetaan konsultaatioon tulleen lemmikin data
   * 8) Vaihdetaan jononäkymä => aktiiviseen näkymään
   * 9) Haetaan viestit ja päivitetään viestilista
   * 10) Haetaan lähetetään kaikille lääkäreille wss että potilas valittu
   */

  takePatientToConsultation(e) {

    var userID = e.target.id;

    this.addUserToLs(userID)

    this.sendDoctorDataToPatient(userID);
  
  
  }

  switchDataBetweenPatientAndDoc(userID){
    var waitTime;
    

    var full_user; 
    for (let i  = 0; i < this.queueList.length; i++) {
      const quer = this.queueList[i];

      if(userID == quer._id){
        full_user = quer;
        waitTime = quer.queStartTime
        var querUserName = quer.email;
        this.RemoveUserFromQueueDoctorView(userID);
      }
      
    }
    var waitedTime = new Date().getTime() - waitTime;
    this.currentActiveDoctorName = userID;
    localStorage.setItem('ACTIVE_USER', userID);




    var req =  {
      _id: userID,
      queuedTime: waitedTime
    }
    
    this.http.post(environment.PATIENT_TAKEN, req, this.httpOptions).subscribe(
      (res: any) => {
      this.currentActiveDoctorName = userID;
      this.findPetData(userID);

      this.updateUserStatusToConsult(userID,(err, consultResult) => {
        if(consultResult == true){

          document.getElementById('active-btn').click();
          this.findUserDataCB(userID, (err,userResult) => {
            if(userResult == true){
              this.sigleUserList(userID,(err,listResult) => {
                if(listResult == true){
                  this.autoOpenUser(userID);
                  this.docFindAllDoctors()
                  this.GetAllInQueue();
                  this.waitActive(querUserName);
                }
              });
            }
          })


        }
        else{ 
          window.location.reload();
        }
      })

    }, (err: any) => {
      console.log('Virhe patient taken', err);
    });
  }
  



  findUserByUserID(userID){

    var req = {
      _id: userID
    }
    this.http.post(environment.DOC_ID_TO_USERNAME, req, this.httpOptions).subscribe(
      (res: any) => {
      console.log('Vastaus? Käyttis', res);
      var username = res['data']
      //this.CheckPatientCredit();
    }, (err: any) => {
      console.log('NSET_Virhe patient taken', err);
    }
  ); 

  }

  updateUserStatusToConsult(userID,callback){
    var err;
    var req = {
      userID: userID, 
      consultantName: this.currentUserId
    }

    this.http.post(environment.UPDATE_CONSULTATION_STATUS, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
          //this.OnSelectUserByUserNameOnLoad(userID);
          callback(err, true);
        }
        else{
          console.log('Konsultaatiota päivittäessä tapahtui jokin virhe')
          callback(err,false)
        }

    }, (err: any) => {
      console.log('Virhe tapahtui konsultaatiota päivittäessä', err);
    }); 
  
  }

  autoOpenUser(userName){

        console.log("AutoOpening starting...");
          this.webSocketService.sendMessage({
            task: 'AUTO_OPEN',
            to: userName,
            from: this.currentUserId
          });

  }
  //id:nä lähetetään lääkärin kt ja userin id
  sendDoctorDataToPatient(userID){
    var decoded = helper.decodeToken(this.authToken);

    let request = {
      _id: userID,
      doctorUserName: decoded.result.userID,
    }

    this.http.post(environment.DOCTOR_PICKED_USER, request, this.httpOptions).subscribe(
      (res: any) => {
      this.switchDataBetweenPatientAndDoc(userID);

    }, (err: any) => {
      console.log('NSET_Virhe doctor picked ', err);
    }
  ); 

  }
  sendPatientBackToQueue() {
    var req = {
      userID: this.currentActiveUserData
    }
    this.http.post(environment.BACK_TO_QUEUE_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
      if(res.status == 200){
        console.log('onnistui')
        this.inQueueMsg = "";
        this.inQueueMsg = res.message
        this.docFindAllDoctors();
        this.sendUserBackToQueueMessage();
        this.changeUserStatusToPaid();
        var zz;
        this.openCity(null,'queue');
      }
      else{
        this.inQueueErrMsg = "";
        this.inQueueErrMsg = res.message;
      }
    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
  );
  }

  changeUserStatusToPaid(){
    var req = {
      userID: this.currentActiveUserData._id
    }
    this.http.post(environment.DOC_CHANGE_USER_STATUS_TO_PAID, req, this.httpOptions).subscribe(
      (res: any) => {
      if(res.status == 200){
        console.log('user status paid onnistui')
        this.LoadMessageList();
      }

    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    })
  }


  // korjaa tää funktio, tarkoituksena lähettää wss kun poistetaan eläin jonosta sekä mahdollisesti lisätään
  docFindAllDoctors() {
    var req = {
      userID: this.currentActiveUserData
    }
    this.http.post(environment.DOC_FIND_ALL_DOCTORS, req, this.httpOptions).subscribe(
      (res: any) => {

        console.log(res, 'vastaus');

           this.webSocketService.sendMessage({
            task:'USER_TO_QUEUE',
            to: res
          })
    

    }, (err: any) => {
      console.log('NSET_Virhe decrease ', err);
    }
  );
  }

  openMultipleWindows(who){
    document.getElementById('multiple-windows-modal').click();
    return false;
  }


  get emailText() { return this.mail.get('mail') }
  get emailHeader() { return this.mail.get('header') }
  sendEmailToPatient(){

    if(this.mail.get('mail').value == "" || this.mail.get('header').value == "") {
      alert('Kenttiä tyhjänä! Täytä pyydetyt kentät');
      return;
    }

    const request = {
      userName: this.currentActiveUserData.email,
      emailText: this.mail.get('mail').value,
      emailHeader: this.mail.get('header').value,
    }
    this.http.post(environment.SEND_EMAIL_TO_USER, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.messageSuccess="";
          this.messageErr="";
          this.messageSuccess="Lähettäminen onnistui";

          var elm = document.getElementById('close-modal-email');
          elm.click();

          this.mail.get('mail').setValue("");
          this.mail.get('header').setValue("");
          this.waitAlert();
          
          

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          window.scrollTo(0,0)

        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )

  

  }

  get emailTextTicket() { return this.ticket.get('ticket') }
  get emailHeaderTicket() { return this.ticket.get('ticketHeader') }
  sendTicketToNastaset(){

    const request = {
      userName: this.currentUserName,
      emailTextTicket: this.ticket.get('ticket').value,
      emailHeaderTicket: this.ticket.get('ticketHeader').value,
    }
    this.http.post(environment.SEND_EMAIL_TO_ADMIN, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.messageSuccess="";
          this.messageErr="";
          this.messageSuccess="Lähetys onnistui onnistui"
          window.scrollTo(0,0)
          

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          window.scrollTo(0,0)
          //loginError = true;
        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )
  }

  get regimen() { return this.endConsultation.get('endConsultation') }
  get vetPrivateNotes() { return this.endConsultation.get('vetPrivateNotes') }
  get vetPublicNotes() { return this.endConsultation.get('endConsvetPublicNotesultation') }
  //Lisätään käyntikertomus asiakkaantietoihin
addVistiToVisits(){

  var consultationTime = new Date().getTime() - this.currentActiveUserData.consultationStartTime;

  if(this.visitReady == true) {
    this.visitReady = false;
  

  var btn = document.getElementById('deleteBtn-'+this.currentActiveUserData._id);
  btn.style.display="none";



  var elm = document.getElementById('close-consultation-form');
  elm.classList.add("reg-form-success");
  console.log(elm);
  document.getElementById('loader-close-consultation').style.display="block";

    const request = {

      //DIAGNOOSI
      regimen: this.endConsultation.get('endConsultation').value,
      vetPrivateNotes: this.endConsultation.get('vetPrivateNotes').value,
      vetPublicNotes: this.endConsultation.get('vetPublicNotes').value,
      diagnosisDiff: this.createdDiffArr,
      diagnosisFinal: this.createdFinalArr,
      doctorID: this.currentUserId,
      doctorFullName: this.currentUserFullName,
      doctorDesignation: this.currentUserDesignation,


      //PET DATA
      petsName: this.currentActivePatientData.name,
      petsSpecies:this.currentActivePatientData.species,
      petsBreed: this.currentActivePatientData.breed,
      petsMicroCard: this.currentActivePatientData.microCard,
      petsRegisterNumber: this.currentActivePatientData.registerNumber,
      petsID:this.currentActivePatientData._id,
      petsSex:this.currentActivePatientData.sex,
      petsInsuranceCompany:this.currentActivePatientData.insuranceCompany,
      petsInsuranceNumber:this.currentActivePatientData.insuranceNumber,
      petsBirthDate:this.currentActivePatientData.birthDate,
      petsAnamnesis: this.currentActivePatientData.anamnesis,


      //USER DATA
      userID: this.currentActiveUserData._id,
      messagesFrom: this.currentUserName,
      userPhone: this.currentActiveUserData.phone,
      firstName: this.currentActiveUserData.firstName,
      lastName: this.currentActiveUserData.lastName,
      queuedTime: this.currentActiveUserData.queuedTime,
      recommendations: this.selectedLinks,
      consultationTime: consultationTime,
      userEmail: this.currentActiveUserData.email

    }

    this.clearLocalStorageUser(this.currentActiveUserData._id)


    this.http.post(environment.CREATE_VISIT_END_POINT, request, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.endConsultationMessage();

          var us = this.currentActiveDoctorName;
          this.openFeedbackBox(us);
          this.visitReady = true;
          
          btn.style.display="block";

          window.scrollTo(0,0)
          this.changePatientStatusToNormal();
          this.endConsultation.reset();
          this.createdFinalArr=[];
          this.createdDiffArr=[];
          this.selectedLinks = [];
          this.currentActiveUserData = [];
          this.currentActivePatientData = [];
          document.getElementById('loader-close-consultation').style.display="none";
          elm.classList.remove("reg-form-success");
          var elmClose = document.getElementById('close-close-consultation');
          console.log(elmClose);
          elmClose.click();
          
        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"

          elm.classList.remove("reg-form-success");
          window.scrollTo(0,0)
          //loginError = true;
        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      })
  }

  }


  sendHelp() {

    var req = {
      userName: this.currentUserName
    }

    this.http.post(environment.SEND_HELP_MAIL, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.messageSuccess="";
          this.messageErr="";
          this.messageSuccess="Lähetys onnistui onnistui"

          var close = document.getElementById('close-ask-help');
          close.click();
          window.scrollTo(0,0)
          

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          window.scrollTo(0,0)
          //loginError = true;
        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      })
  }

  clearMessages() {
    this.messageSuccess = "";
    this.messageErr = "";
  }

  openFeedbackBox(us){

    this.webSocketService.sendMessage({
      task: 'OPEN_FEEDBACK_QUESTIONNAIRE',
      from: this.currentUserId,
      to: us,
      msg: 'Annathan palautteen'
    })
  }

  changePatientStatusToNormal(){

    console.log(this.currentActiveUserData._id, 'iidee olis');

    var req = {
      userID: this.currentActiveUserData._id,
    }

    this.http.post(environment.DOC_CHANGE_USER_STATUS_NORMAL, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.messageSuccess="";
          this.messageErr="";
          this.messageSuccess="Lähetys onnistui"
          this.getListData();

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          console.log('Böö');
          //loginError = true;
        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      })

  }
  fetchDiagnosis(e) {


    this.CheckMyCredit();

    if(this.diagnosisArr.length == 0) {
    this.http.get(environment.DOC_ALL_FETCH_DIAGNOSIS, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
         if (res.status == 200) {
          this.diagnosisArr = res.message;
          

        } else {
          this.messageSuccess="";
          this.messageErr="";
          this.messageErr="Lähettäminen epäonnistui, ole hyvä ja yritä uudelleen"
          console.log('Böö');
          //loginError = true;
        } 
      }, (err: any) => {

        console.log("Error while login :: ", err);
      })
    }
  }

  createDiffArr(data){
    console.log(data);

    for(var i = 0; i < this.createdDiffArr.length; i++){
      var item = this.createdDiffArr[i];

      if(data.term == item){
        alert('Termi on jo lisätty listaan. Ole hyvä ja tarkista lista uudelleen.');
        return;
      }
    }
    this.createdDiffArr.push(data.term);

    var elm = document.getElementById('term-'+data.id);

    (<HTMLInputElement>document.getElementById('diffArr')).value = '';
    this.searchText = '';
    console.log(this.searchText);
  }
  removeItemFormDiffArr(term){
    for(var i = 0; i < this.createdDiffArr.length; i++){
      if(this.createdDiffArr[i] === term) {
        this.createdDiffArr.splice(i,1); 
        i-1;
      }
    }
  }
  createFinalArr(data){

    for(var i = 0; i < this.createdFinalArr.length; i++){
      var item = this.createdFinalArr[i];

      if(data.term == item){
        alert('Termi on jo lisätty listaan. Ole hyvä ja tarkista lista uudelleen.');
        return;
      }
    }
    var elm = document.getElementById('term-'+data.id);

    this.createdFinalArr.push(data.term);

    (<HTMLInputElement>document.getElementById('finalArr')).value = '';
    this.searchTextFinal = '';
    console.log(this.searchTextFinal);


  }
  removeItemFormFinalArr(term) {
    for(var i = 0; i < this.createdFinalArr.length; i++){
      if(this.createdFinalArr[i] === term) {
        this.createdFinalArr.splice(i,1); 
        i-1;
      }
    }
  }

  openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;
  
  
    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    if(evt != null){
    evt.currentTarget.className += " active";
    }
    else if(cityName == "queue") {
      var elm = document.getElementById('queue-btn');
      elm.classList.add('active');
    }
    else if(cityName == "active"){
    var elm = document.getElementById('active-btn');
    elm.classList.add('active');
    }

}
    openData(evt, cityName) {
      // Declare all variables
      var i, tabcontent, tablinks;
    
      console.log(cityName)
    
      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontentData");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
    
      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinksData");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
    
      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    
    
  }

    //KÄYTETÄÄN EXPANDBOXIEN AVAAMISEEN
    openExpandBox(e){


      var elmID = e.target.id;
      var petID = elmID.substring(12)
  
      var menu = document.getElementById(petID);
      console.log(menu);
      var icon = document.getElementById(elmID);
      var iconMinus = document.getElementById('close-icon-'+petID)
      menu.classList.add('ease-in');
      iconMinus.style.display="block";
      icon.style.display="none";
  
    }
  //KÄYTETÄÄN EXPANDBOXIEN SULKEMISEEN
    closeExpandBox(e){
    var elmID = e.target.id;
    var petID = elmID.substring(11)
  
    var iconMinus = document.getElementById(elmID);
    var icon = document.getElementById('expand-icon-'+petID);
    var menu = document.getElementById(petID);
    menu.classList.add('ease-out');
    menu.classList.remove('ease-in');
    iconMinus.style.display="none";
    icon.style.display="block";
  
    }

    handleFiles(event: Event): void {
      this.attahchmentLoader();
      const attachment = (event.target as HTMLInputElement).files[0];
      this.attachmentObj = attachment;
      if(this.attachmentObj.size > 100000000){
        alert('Tiedoston maksimi koko on 100mb');
        this.closeSendingModal();
        return;
      }
      this.fileUploaderService.uploadImage(this.attachmentObj,this.currentActiveDoctorName,false,(err,result) => {
      
        if(result == false){
          alert('Kuvaa ei voitu lisätä');
        }
        else{
          var sender = this.currentUserId;
          var reciever = this.currentActiveDoctorName;
          this.webSocketService.sendMessage({
            task: 'IMAGE_MESSAGE',
            from: sender,
            to: reciever,
            msg: result,
          })
          }
        })
    }

    handleVideos(event: Event): void {
      this.attahchmentLoader();
      const attachment = (event.target as HTMLInputElement).files[0];
      this.attachmentObj = attachment;
      console.log(this.attachmentObj);

      //Limit size to 100mb
      if(this.attachmentObj.size > 100000000){
        alert('Tiedoston maksimi koko on 100mb');
        this.closeSendingModal();
        return;
      }

      this.fileUploaderService.uploadVideo(this.attachmentObj,this.currentActiveDoctorName,(err,result) => {
      
        if(result == false){
          alert('Videota ei voitu lisätä');
        }
        else{
          var sender = this.currentUserId;
          var reciever = this.currentActiveDoctorName;
          this.webSocketService.sendMessage({
            task: 'VIDEO_MESSAGE',
            from: sender,
            to: reciever,
            msg: result,
          })
          }
        })
        
    }

    handleAttachment(event: Event): void {
      this.attahchmentLoader();
      const attachment = (event.target as HTMLInputElement).files[0];
      this.attachmentObj = attachment;
      if(this.attachmentObj.size > 100000000){
        alert('Tiedoston maksimi koko on 100mb');
        this.closeSendingModal();
        return;
      }
      console.log(this.attachmentObj);


      this.fileUploaderService.uploadAttachment(this.attachmentObj,this.currentActiveDoctorName,(err,result) => {
      
        if(result == false){
          alert('Liitetiedostoa ei voitu lisätä');
        }
        else{
          var sender = this.currentUserId;
          var reciever = this.currentActiveDoctorName;
          this.webSocketService.sendMessage({
            task: 'ATTACHMENT_MESSAGE',
            from: sender,
            to: reciever,
            msg: result,
          })
          }
        })
        
    }

    //Spinner to load attachment
    attahchmentLoader(){
      document.getElementById('attachment-loader-btn').click();
      this.colorService.styleLoader();
    }

    //Closes attachment
    closeSendingModal(){
      document.getElementById('close-sending-modal').click();
    }

    
    autoTextarea(){
      var textarea = document.getElementById("comment");
      var heightLimit = 400; /* Maximum height: 200px */

      var that = this;
      
        textarea.style.height = "40px"; /* Reset the height*/
        textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
        textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important');
        document.getElementById('chat-container').style.marginTop = "0px";
        document.getElementById('chat-container').style.marginTop = "-" + (document.getElementById('comment').clientHeight - 40).toString() + "px";
        
      
    }
    autoTextareaCare(id){
      var textarea = document.getElementById(id);
      var heightLimit = 500; /* Maximum height: 200px */

      textarea.oninput = function() {
        textarea.style.height = ""; /* Reset the height*/
        textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
        textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important')
      };
    }

    textareaToAmount(){
      var heightLimit = 400;
      var textarea = document.getElementById("comment");
      textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important')
      textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + "px";
      textarea.setAttribute('style', 'height:' +Math.min(textarea.scrollHeight, heightLimit)+'px!important')
      document.getElementById('chat-container').style.marginTop = "0px";
      document.getElementById('chat-container').style.marginTop = "-" + (document.getElementById('comment').clientHeight - 40).toString() + "px";
    }
    DropdownExpand(e){
      console.log('tuli');
      document.getElementById("myDropdown").classList.toggle("show-dropdown");
    
    
      if (!e.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show-dropdown')) {
            openDropdown.classList.remove('show-dropdown');
          }
        }
      
    }
  }
  openInfoScreen(e){

    var userElm = e.target.id;

    var chat = document.getElementById('chat-container');
    var visits = document.getElementById('visit-data-info');
    var anamnesis = document.getElementById('anamnesis-data-info');
    var userData = document.getElementById('active-user-data-info');

    anamnesis.style.display = "none";
    visits.style.display = "none";
    chat.style.display = "none";
    userData.style.display = "none";

    var info = document.getElementById('active-patient-data');
    info.style.display = "block";


    var userID = userElm.substring(12);
    this.http.post(environment.DOC_FETCH_ACTIVE_USERS_PET_BY_ID, { userID: userID }, this.httpOptions).subscribe(
      (res: any) => {
        this.infoToPetScreen = [];
        console.log('fetchattu data',res);

        if(res.status == 200){
          this.infoToPetScreen = res.message[0];

          var sdt = new Date(this.infoToPetScreen['birthDate']);
          var ntd = new Date();
          var diff = Math.floor(ntd.getTime() - sdt.getTime());
          var day = 1000 * 60 * 60 * 24;
      
          var days = Math.floor(diff/day);
          var months = Math.floor(days/31);
          var ey = Math.floor(months/12)

          var dy = months / 12;
          var my = dy % 1;
          var ry = (my * 12).toFixed(0);

          var msg = ey + " vuotta "+ry+" kk" 

          Object.assign(this.infoToPetScreen, {age: msg})
      

        }
        else{
          console.log('Active pet data not found');
        }
  
      },(err: any) => {
        console.log("Erroori tapahtui findFullPetDatassa : ", err);
      })
    }

    openInfoScreenVisits(e){
    var userElm = e.target.id;

    var chat = document.getElementById('chat-container');
    var visits = document.getElementById('active-patient-data');
    var anamnesis = document.getElementById('anamnesis-data-info');
    var userData = document.getElementById('active-user-data-info');
    var info = document.getElementById('quer-data-info-screen');

    anamnesis.style.display = "none";
    info.style.display = "none";
    visits.style.display = "none";
    chat.style.display = "none";
    userData.style.display = "none";

    var info = document.getElementById('visit-data-info');
    info.style.display = "block";


    var userID = userElm.substring(19);

    console.log(userID);

    var req = {
      patientID: userID
    }

    this.http.post(environment.FETCH_VISIT_FOR_PET_END_POINT,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log('fetchattu data',res);

        if(res.status == 200){
          this.visitHistory = [];
          this.visitHistory = res.message;

        }
        else if(res.status == 201){
          this.visitHistory = null;
          this.visitHistory = [];
        }
  
      },(err: any) => {
        console.log("Erroori tapahtui findFullPetDatassa : ", err);
      })
    }

    openInfoScreenAnamnesis(e){
      var userElm = e.target.id;
  
      var chat = document.getElementById('chat-container');
      var patient = document.getElementById('active-patient-data');
      var visits = document.getElementById('visit-data-info');
      var userData = document.getElementById('active-user-data-info');
      var info = document.getElementById('quer-data-info-screen');
      
  
      patient.style.display = "none";
      chat.style.display = "none";
      visits.style.display = "none";
      userData.style.display = "none";
      info.style.display = "none";
  
      var anamnesis = document.getElementById('anamnesis-data-info');
      anamnesis.style.display = "block";
  
  
      var userID = userElm.substring(22);
  
  
      var req = {
        userID: userID
      }
  
       this.http.post(environment.FETCH_ANAMNESIS_DATA_FOR_PET,req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('fetchattu data',res);
  
          if(res.status == 200){
            this.anamnesisData = [];
            this.anamnesisData = res.message[0];
             
            console.log('fetchattu anamneesi',this.anamnesisData);
  
  
  
          }
          else if(res.status == 201){
            this.visitHistory = null;
            this.visitHistory = [];
          }
    
        },(err: any) => {
          console.log("Erroori tapahtui findFullPetDatassa : ", err);
        }) 
      }

      openInfoScreenVisitsForUser(e){
        var userElm = e.target.id;

        var chat = document.getElementById('chat-container');
        var visits = document.getElementById('active-patient-data');
        var anamnesis = document.getElementById('anamnesis-data-info');
    
        anamnesis.style.display = "none";
        visits.style.display = "none";
        chat.style.display = "none";
    
        var info = document.getElementById('visit-data-info');
        info.style.display = "block";
    
    
        var userID = userElm.substring(19);
    
        console.log(userID);
    
        var req = {
          patientID: userID
        }
  
       this.http.post(environment.FETCH_VISIT_FOR_USER_END_POINT,req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('fetchattu data',res);
  
          if(res.status == 200){
            this.visitHistory = [];
            this.visitHistory = res.message;

            this.visitHistory = this.visitHistory.sort(function(a,b){
              return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            });

            for(var i = 0; i < this.visitHistory.length; i++){
              var date = this.visitHistory[i].createdDate;
              console.log('EPEE',this.visitHistory,'EPE');
            }
  
  
            console.log('fetchattu anamneesi',this.anamnesisData);
  
  
  
          }
          else if(res.status == 201){
            this.visitHistory = null;
            this.visitHistory = [];
          }
    
        },(err: any) => {
          console.log("Erroori tapahtui findFullPetDatassa : ", err);
        }) 
      }
      openInfoScreenActiveUserData(e){
        

        var chat = document.getElementById('chat-container');
        var visits = document.getElementById('active-patient-data');
        var anamnesis = document.getElementById('anamnesis-data-info');
        var visits = document.getElementById('visit-data-info');
    
        anamnesis.style.display = "none";
        visits.style.display = "none";
        chat.style.display = "none";
        visits.style.display = "none";
    
        var info = document.getElementById('active-user-data-info');
        info.style.display = "block";
    
      }

      openInfoScreenQuerData(e,quer){
        
        this.currentQuerInfoScreen = quer;

        console.log(this.currentQuerInfoScreen)

        var chat = document.getElementById('chat-container');
        var visits = document.getElementById('active-patient-data');
        var anamnesis = document.getElementById('anamnesis-data-info');
        var visits = document.getElementById('visit-data-info');
        var info = document.getElementById('active-user-data-info');


        //Kytketään pois
        anamnesis.style.display = "none";
        visits.style.display = "none";
        chat.style.display = "none";
        visits.style.display = "none";
        info.style.display = "none";
    
        var querData = document.getElementById('quer-data-info-screen');
        querData.style.display = "block";
    
      }


    drAssitantToId(){

      this.http.get(environment.CHANGE_ASSISTANT_NAME_TO_ID, this.httpOptions).subscribe(
        (res: any) => {
          console.log('fetchattu data',res);
  
          if(res.status == 200){
            this.currentActiveDoctorName = res.message;
            this.DR_ASSISTANT_NAME = res.message;
  
            console.log('fetchattu geeli',res.messge);
  
          }
          else if(res.status == 201){

          }
    
        },(err: any) => {
          console.log("Erroori tapahtui findFullPetDatassa : ", err);
        })

    }


    waitAlert() {
      var that = this;
  
      setTimeout(() =>{
        //that.SuccessMessage = false;
        that.messageSuccess = "";
        that.messageErr = "";
        that.creditMsg = "";
        
        //that.ErrMessage = false;
      },5000)
  
    }
    waitActive(email) {
      var that = this;
  
      setTimeout(() =>{
        //that.SuccessMessage = false;
        that.styleActiveDoctor(email);
        
      },600)
  
    }

    fetchUrls(){
      var text = (<HTMLTextAreaElement>document.getElementById('endConsultation')).value;

      var urlRegex = /(https?:\/\/[^\s]+)/g;
      var arr =[];
      var my = text.replace(urlRegex, function(url) {
        console.log(url);
        if(url.includes("https://www.mustijamirri.fi") || url.includes("https://mustijamirri.fi") || url.includes("https://petenkoiratarvike.com") || url.includes("https://www.petenkoiratarvike.com")){
          var strLen = url.length;
          console.log(url.charAt(strLen -1 ));
          if((url.charAt(strLen -1) == ".")){
            url = url.substring(0, url.length - 1);
            arr.push(url);
          }
          else{
            arr.push(url);
          }
        }
        return url;
      })
      this.selectedLinks = arr;


      


    }
    removeLink(link){
      for(var i = 0; i < this.selectedLinks.length; i++){
        if(this.selectedLinks[i] === link) {
          this.selectedLinks.splice(i,1); 
          i-1;
        }
      }
    }

    checkPaidCustomers(){

      this.http.get(environment.CHECK_PAID_CUSTOMERS, this.httpOptions).subscribe(
        (res: any) => {
  
          if(res.status == 200){
            alert('Seuraavat asiakkaat ovat maksaneita (luettelo sähköpostiosoitteista): ' +res.message);
          }
          else if(res.status == 201){

          }
    
        },(err: any) => {
          console.log("Erroori tapahtui findFullPetDatassa : ", err);
        })

    }

    askPermissionToUseCameraAudio(){

      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((res) => {
        this.notificationService("Odota...", 3000);
        this.getAllCameras();
        this.waitShow();
      })
    }

    waitShow(){

      setTimeout(() =>{

        this.createRoom();

      },500)
    }

    createRoom(){


      var pre = document.getElementById('preview-window');
      if(pre.classList.contains('d-none')){
        pre.classList.remove('d-none');
      }


      var chat = document.getElementById('chat-wrap');
      chat.classList.add('chat-wrap-none');

      var video = document.getElementById('video-cover');
      video.classList.add('video-cover-active');

      this.showVideoPreview(); 

      this.checkUserDeviceCompability()

      
      var req = {
        discountID: 'e'
      }
  
      this.http.post(environment.CREATE_VIDEO_ROOM,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {

          var obj = {
            videoRoomName: res.message.uniqueName,
            customer:this.currentActiveDoctorName,
            sid: res.message.sid
          }
  
          this.roomData = obj;
        
            
        }
        else {

        }
      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
  
      }

      joinExistingRoom(){

        console.log(this.roomData)

        if(this.currentActiveDoctorName.toString() == this.roomData.customer.toString()) {
          var chat = document.getElementById('chat-wrap');
          chat.classList.add('chat-wrap-none');
    
          var video = document.getElementById('video-cover');
          video.classList.add('video-cover-active');

        }
        else{
          alert('Huone ei ole auki tämän potilaan kanssa.');
          return;
        }
      }
  
        getVideoToken(){
  
          var req = {
            room: this.roomData.videoRoomName
          }
      
          this.http.post(environment.GET_VIDEO_ROOM_TOKEN, req, this.httpOptions).subscribe(
          (res: any) => {
            if(res.status == 200) {
              console.log(res);
      
              this.videoToken = res.message;
              
  
              
              this.joinRoom(this.videoToken, this.roomData.videoRoomName)
                
            }
            else {
            }
          }, (err: any) => {
      
            console.log("Error while login :: ", err);
          })
      
          }

          showVideoPreview(){

            if(this.userOnline == false){
              (<HTMLButtonElement>document.getElementById('join-video-btn')).disabled = true;
            }
            console.log(this.selectedCamera);
            createLocalVideoTrack({deviceId:{exact:this.selectedCamera}} ).then(track => {
              this.localTrack = track;
              var g = document.getElementById('local-media').appendChild(track.attach());
              g.setAttribute('width', "100%");
              g.setAttribute('id', "local-video");
              g.setAttribute('style', "max-height: calc(100vh - 300px - 60px)!important")

              this.getAllCameras();
            }).catch((err) => {
              console.error(err);
            });
          }
           handleMediaError(error) {
            console.error('Failed to acquire media:', error.name, error.message);
          }
          localChange(){
            if(this.currentRoom.localParticipant.videoTracks.size == 0 || this.currentRoom.localParticipant.videoTracks.size == undefined){
              if(this.localTrack){
                this.localTrack.stop();
                this.localTrack.mediaStreamTrack.stop()
              }
            createLocalVideoTrack({deviceId:{exact:this.selectedCamera}} ).then(track => {
              this.localTrack = track;
              this.currentRoom.localParticipant.publishTrack(track);
  
              //Remove preview window
              if(document.getElementById('local-video-sm')){
                document.getElementById('local-video-sm').remove();
              }
              
              var d = document.getElementById('local-media-sm').appendChild(track.attach());
              d.setAttribute('width', "25%");
              d.setAttribute('id', "local-video-sm");
              d.setAttribute('style', "border-radius: 4px; z-index:99;")
              
    
                return track;
              });
            }
            else if(!document.getElementById('local-video-sm')){
              console.log(this.localTrack);
              var d = document.getElementById('local-media-sm').appendChild(this.localTrack.attach());
              d.setAttribute('width', "25%");
              d.setAttribute('id', "local-video-sm");
              d.setAttribute('style', "border-radius: 4px; z-index:99;")

            }
            
            }

          acceptJoiningRoom(){
            if(!this.roomData.oldRoom){
              this.sendUserVideoInvite()
            }
            else{
              this.getVideoToken();
            }
            //this.joinRoom(this.videoToken, this.roomData.uniqueName);
            this.joinToVideoChat()
          }

          sendUserVideoInvite(){
            var width = document.documentElement.clientWidth;
            var height = document.documentElement.clientHeight;
            this.webSocketService.sendMessage({
              task: 'VIDEO_INVITATION',
              from: this.currentUserId,
              to: this.currentActiveDoctorName,
              msg: this.roomData,
              width:width,
              height:height,
            })
          }
          
          checkUserDeviceCompability(){

            this.webSocketService.sendMessage({
              task: 'DEVICE_COMPABILITY',
              from: this.currentUserId,
              to: this.currentActiveDoctorName,
            })
          } 


          joinRoom(token, rooms){
            connect(token, { name:rooms,audio: true,  video:{width: document.documentElement.clientWidth, height:(document.documentElement.clientHeight *0.8),deviceId:{exact:this.selectedCamera}}, }).then(room => {
              console.log(`Successfully joined a Room: ${room}`);
              this.currentRoom = room;

              var d = document.getElementById('local-media-sm').appendChild(this.localTrack.attach());
              d.setAttribute('width', "25%");
              d.setAttribute('id', "local-video-sm");
              d.setAttribute('style', "border-radius: 4px; z-index:99;")
              
              this.getAllCameras();
              this.videoAiring = false;
  
              if(document.getElementById('wait-cont').classList.contains('d-none')){
                document.getElementById('wait-cont').classList.remove('d-none');
              }
  
              this.notificationService('Huone avattu onnistuneesti, asiakkaalle avattu liittymisikkuna.', 3000);
  
              this.addDoctorVideoRoom();
              this.addUserVideoRoom();
  
  
              if(this.localTrack == undefined){
                this.detachCameraVideo();
                this.muteAudioVideo()
              }
              else {
                //this.localTrack.mediaStreamTrack.stop();
                this.detachCameraVideo();
                this.muteAudioVideo()
              }

              var full_video_track;
              var full_audio_track;
  
              room.on('trackDisabled', track => {

                if(track.kind == "video"){
                  var video = document.getElementById('videokkas');
                  if(video){
                    //video.remove();
                  }
                  }
                else if(track.kind == "audio"){
                  document.getElementById('video-audio-track').remove();
                }

                  //Add wait image
                  if(!document.getElementById('wait-cont').classList.contains('d-none')){
                    document.getElementById('wait-cont').classList.add('d-none');
                  }
  
                  //Add placeholder image
                  if(track.kind == "video"){
                    var img = document.getElementById('video-placeholder-img')

                    if(!img){
                      const image = <HTMLImageElement>document.createElement('img');
                      image.setAttribute('src','../assets/images/chat2Vet_some.png' );
                      image.setAttribute('id','video-placeholder-img' );
                      image.setAttribute('style', "height: calc(100vh - 88px - 69px)!important; max-width: 80%!important; background-color:black;");
                      document.getElementById('remote-media-div').appendChild(image);
                    }
  
                    if(video){
                      //video.remove();
                    }
  
                    this.notificationService('Asiakas sammutti kameransa.', 1500);
                  }
                  else if(track.kind == "audio"){
                    this.notificationService('Asiakas sammutti mikrofoninsa.', 1500);
                  }
  
  
              })

  
              //Kun asiaks laittaa videon auki
              room.on('trackEnabled', (track, participant) => {

                console.log('Enabloitu');

                //Suljetaan odotus
                if(!document.getElementById('wait-cont').classList.contains('d-none')){
                  document.getElementById('wait-cont').classList.add('d-none');
                }  
                  //Lisätään video
                  if(track.kind == "video"){

                  var v = document.getElementById('videokkas');

                  //Check if video is not already there
                  if(!v){
                    var g = document.getElementById('remote-media-div').appendChild(full_video_track.attach());
                    g.setAttribute('id', "videokkas")
                    g.setAttribute('class', "videokkas");
                    g.setAttribute('width', "100%");
                    g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")
                  }
                  
                  //Remve image
                  var image = document.getElementById('video-placeholder-img');
                  if(image){
                    document.getElementById('remote-media-div').removeChild(image);
                  }

                  }
                  else if(track.kind == "audio"){
                    var x =  document.getElementById('video-audio-track');
                    this.notificationService('Asiakas avasi mikrofoninsa.', 1500);

                    if(!x) {
                      document.getElementById('remote-media-div').appendChild(full_audio_track.attach());
                      x.setAttribute('id', 'video-audio-track')
                    }
                  }
                
              })
  
              //Customer disconnected video
              room.on('participantDisconnected', participant => {
                this.unmuteAudioVideo();
                this.notificationService('Asiakkas poistui huoneesta onnistuneesti.', 1500);
                const image = <HTMLImageElement>document.createElement('img');
                image.setAttribute('src','../assets/images/chat2Vet_some.png' );
                image.setAttribute('id','video-placeholder-img' );
                image.setAttribute('style', "height: calc(100vh - 88px - 69px)!important; background-color:black;");
                document.getElementById('remote-media-div').appendChild(image);
                
  
                var video = document.getElementById('videokkas');
                document.getElementById('remote-media-div').removeChild(video);
               })
              
              //Customer connected video
              room.on('participantConnected', participant => {
                this.notificationService('Asiakas liittyi huoneeseen.', 1500);
                
               })
  
               room.on('disconnected', room => {
                if(this.localTrack){
                  this.localTrack.mediaStreamTrack.stop()
                  this.localTrack.stop();
                }
                // Detach the local media elements
                room.localParticipant.tracks.forEach(function(track) { 
                  track.unpublish()
                });

                var video = document.getElementById('videokkas');
                if(video){
                  document.getElementById('remote-media-div').removeChild(video);
                }

              });

              room.on('trackUnpublished', room => {
                console.log('Track poistettu');
              })

              
              room.on('trackPublished', room => {
                console.log('Track lisätty');
              })
  
              room.on('participantConnected', participant => {
              console.log(`A remote Participant connected: ${participant}`);
  
            //Suljetaan odotus
            if(!document.getElementById('wait-cont').classList.contains('d-none')){
              document.getElementById('wait-cont').classList.add('d-none');

            }
  
            //Kun asiakas liittyy ensiksi
            room.participants.forEach(participant => {
              participant.tracks.forEach(publication => {
                if (publication.track) {
                  console.log('jotain hassusti')
                }
              });
            
             participant.on('trackSubscribed', track => {
              var d = document.getElementById('videokkas');

               if(track.kind == "video") {
                 full_video_track = track;
                 if(!d){
                  var g = document.getElementById('remote-media-div').appendChild(track.attach());
                  g.setAttribute('id', "videokkas")
                  g.setAttribute('class', "videokkas");
                  g.setAttribute('width', "100%");
                  g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")
                 }
                 else{
                  d.remove();
                  var g = document.getElementById('remote-media-div').appendChild(track.attach());
                  g.setAttribute('id', "videokkas")
                  g.setAttribute('class', "videokkas");
                  g.setAttribute('width', "100%");
                  g.setAttribute('style', "max-height: calc(100vh - 88px - 69px)!important")
  
                 }
              }
              else if(track.kind == "audio"){
                full_audio_track = track;
                var f = document.getElementById('remote-media-div').appendChild(track.attach());
                f.setAttribute('id', "video-audio-track")

              }
            });
  
            
              participant.on('trackAdded', track => {
                document.getElementById('remote-media-div').appendChild(track.attach());
              });
            });
  
  
  
              });
  
              
            }, error => {
              console.error(`Unable to connect to Room: ${error.message}`);
            });
            }

        waitInvitation(){
          var that = this;
  
          setTimeout(() =>{
            //that.SuccessMessage = false;
            /* that.sendUserVideoInvite() */
            
          },5000)
        }

        joinToVideoChat(){


          var pre = document.getElementById('preview-window');
          pre.classList.add('d-none');
        }
    
        muteAudioVideo(){
    
          if(this.videoToken){
          this.currentRoom.localParticipant.audioTracks.forEach(publication => {
            publication.track.disable();
          });
    
          this.muted = true;
          this.notificationService('Mikrofoni mykistetty.', 1500);
          }
          else{
            this.notificationService('Mikrofonin voit mykistää vasta huoneessa.', 1500);
          }
          
          
        }

        detachCameraVideo(){

          if( this.videoToken){
            this.currentRoom.localParticipant.videoTracks.forEach(publication => {
              publication.track.disable();
              this.videoAiring = false;
              if(this.localTrack){
              this.localTrack.disable();
              }
              if(document.getElementById('local-video-sm')){
                document.getElementById('local-video-sm').remove()
              }
            });
            }
            else{
              if(this.localTrack != undefined) {
                this.localTrack.mediaStreamTrack.stop()
                this.localTrack.stop();
                if(document.getElementById('local-video')){
                  document.getElementById('local-video').remove()
                }
              }
    
            }
    
            this.notificationService('Kamera suljettu.', 1500);
            this.videoShow = false;
    
        }

        unmuteAudioVideo(){
          this.currentRoom.localParticipant.audioTracks.forEach(publication => {
            publication.track.enable();
          });
    
          this.muted = false;
        }

        attachCameraVideo(){

          if(this.videoToken){
            this.currentRoom.localParticipant.videoTracks.forEach(publication => {
              publication.track.enable();
              this.videoAiring = true;
              this.localChange();
              if(this.localTrack){
                this.localTrack.enable();
                }
            });
            }
            else{
              if(this.localTrack){
                this.localTrack.stop();
                this.localTrack.mediaStreamTrack.stop()
              }
              this.showVideoPreview();
    
            }
    
    
          this.videoShow = true;
        }

        addUserVideoRoom(){

          var req = {
            room: this.roomData.uniqueName,
            userID: this.currentActiveDoctorName
          }
          
          this.http.post(environment.ADD_ROOM_DETAILS, req, this.httpOptions).subscribe(
            (res: any) => {
              if(res.status == 200){
    
              }
              if(res.status == 201){
                
              }
            }, (err: any) => {
          
              console.log("Error while login :: ", err);
            })
          
        }

    addDoctorVideoRoom(){

      var req = {
        room: this.roomData,
        userID: this.currentActiveDoctorName
      }
      
      this.http.post(environment.ADD_ROOM_DETAILS_DOCTOR, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){

            console.log('Lisätty ell')


          }
          if(res.status == 201){
            
          }
        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
      
    }

    openVideoBox(){
      var chat = document.getElementById('chat-wrap');
      chat.classList.add('chat-wrap-none');

      var video = document.getElementById('video-cover');
      video.classList.add('video-cover-active');

      if(!this.videoToken){
        if(this.localTrack){
          this.localTrack.stop();
          this.localTrack.mediaStreamTrack.stop()
        }
        this.showVideoPreview();
      }

    }

    closeVideoBox(){
      var chat = document.getElementById('chat-wrap');
      chat.classList.remove('chat-wrap-none');

      var video = document.getElementById('video-cover');
      video.classList.remove('video-cover-active');


      if(this.localTrack){
        this.detachCameraVideo();
      }
    }

    acceptCloseVideoConsultation(){
      document.getElementById('close-video-modal-btn').click();
    }

    removeRoom(){

      if(this.videoToken){
      this.currentRoom.disconnect();

      //Remove video-container
      var video = document.getElementById('videokkas');
      if(video){
        video.remove();
      }


      var req = {
        roomName: this.roomData.videoRoomName,
        sid: this.roomData.sid
      }

  
      this.http.post(environment.REMOVE_VIDEO_ROOM,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
          console.log(res);
            document.getElementById('close-video-close-modal').click();
            this.detachCameraVideo();
            this.closeVideoBox();
            this.videoShow = true;
            this.roomData = null;
            this.currentRoom = null;
            this.videoToken = null;
            this.notificationService('Huone suljettu onnistuneesti. Kamera ja mikrofoni kytketty pois päältä.', 3000);
            if(document.getElementById("videokkas")){
              document.getElementById("videokkas").remove();
            }
            else if(document.getElementById("video-placeholder-img")){
              document.getElementById("video-placeholder-img").remove();
            }
    
            if(document.getElementById("local-video")){
              document.getElementById("local-video").remove();
            }
            if(document.getElementById("local-video-sm")){
              document.getElementById("local-video-sm").remove();
            }
        }
        else {
          alert('Huoneen sulkemisessa tekninen häiriö');
        }
      }, (err: any) => {
  
        console.log("Error while login :: ", err);
      })
      }
      else{
            
            document.getElementById('close-video-close-modal').click();
            this.detachCameraVideo();
            this.closeVideoBox();
            this.videoShow = true;
            this.roomData = null;
            this.currentRoom = null;
            this.notificationService('Huone suljettu onnistuneesti. Kamera ja mikrofoni kytketty pois päältä.', 3000);
            if(document.getElementById("videokkas")){
              document.getElementById("videokkas").remove();
            }
            else if(document.getElementById("video-placeholder-img")){
              document.getElementById("video-placeholder-img").remove();
            }
      }
  
      }

      notificationService(text,delay){
        var elm = document.getElementById('toaster');
        elm.setAttribute('data-delay',delay);
        document.getElementById('toast-text').innerHTML = "";
        document.getElementById('toast-text').innerHTML = text;
        $('.toast').toast('show');
        var g = document.getElementById('toaster')
        g.classList.remove('d-none');
        this.waitToast(g,delay);
      }

      waitToast(g,delay) {
        setTimeout(() =>{
          g.classList.add('d-none');
          //that.ErrMessage = false;
        },delay)
      }

      changeCamera(){
        if(this.cameraOptions.length == 1){
          this.notificationService("Sinulla on vain yksi kamera. Kameraa ei käännetty.", 2000);

          
        }
        else if(this.cameraOptions.length == 2){

          for (let i = 0; i < this.cameraOptions.length; i++) {
            const opt = this.cameraOptions[i];

              //Check if video is already started
              if(this.videoToken) {
                
                if(opt.deviceId.toString() != this.selectedCamera.toString()){
                this.selectedCamera = opt.deviceId;
                
                //Unpublish track due to twilio sdk
                this.currentRoom.localParticipant.videoTracks.forEach(publication => {
                  //Start publishing after 1 sec
                  publication.unpublish();
                  publication.track.disable();
                  if(this.currentRoom.localParticipant.videoTracks.size == 0){
                    this.waitSec()
                  }
                });
                return;


              }
                    
              }
              else {
                //Switch camera
                if(opt.deviceId.toString() != this.selectedCamera.toString()){
                this.selectedCamera = opt.deviceId;
                
                //Stop media track
                this.localTrack.mediaStreamTrack.stop()
                this.localTrack.stop();
                document.getElementById('local-video').remove();
                  
                //Start again
                this.showVideoPreview();
                return;
                }
              }

          }

        }
      }
        waitSec(){

          setTimeout(() =>{
  
            //pulish new track with new camera
            this.localChange();
  
            this.notificationService("Kamera käännetty", 1500);
  
          },500)
        }
      /**
 * Function gets all users cameras with datatype videoinput
 */
        getAllCameras(){
          
        this.cameraOptions = [];

        if(navigator.mediaDevices){
          navigator.mediaDevices.enumerateDevices().then((result) => {
            
            for (let i = 0; i < result.length; i++) {
              const device = result[i];
              
              if(device.kind == "videoinput"){
                this.cameraOptions.push(device);
              }
              
            }
            //If no cameras selected before just get the first one
            if(!this.selectedCamera){
              this.selectedCamera = this.cameraOptions[0].deviceId;
            }
          })
        }
        
      }
      setTextareaSaveReady(){
        console.log(this.doctorList,'select');
        var ls = localStorage.getItem('activeUsers');
        console.log('avattu',ls)
        console.log(ls);
        if(ls != 'undefined'){
          console.log('läpi')
          var userList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var userList;
        }
        
        var arr = [];
        for (let i = 0; i < this.doctorList.length; i++) {
          const user = this.doctorList[i];

          if(userList && userList.length > 0){
            for (let j = 0; j < userList.length; j++) {
              const ls_user = userList[j];

              var no_update = false;

              if(user.id == ls_user.userID){
                //Do nothing
                var ss = {
                  userID: ls_user.userID,
                  replyBox: ls_user.replyBox.toString(),
                  consultationClose: ls_user.consultationClose.toString(),
                  vetPrivateNotes: ls_user.vetPrivateNotes.toString(),
                  vetPublicNotes: ls_user.vetPublicNotes.toString(),
                }
                arr.push(ss);
                no_update = true;
              }
            }
          
          }
          else {

            if(user.status == this.currentUserId){
              var obj = {
                userID: user.id,
                replyBox: "",
                consultationClose: "",
                vetPrivateNotes: "",
                vetPublicNotes: "",
              }
  
              arr.push(obj);
              var jsonArr = JSON.stringify(arr);
            }  
          }


          if(i+1 == this.doctorList.length){
            var jsonArr = JSON.stringify(arr);
            localStorage.setItem('activeUsers',jsonArr);
          }
          
        }
      }
      saveWrittenToLocalStorage(type){

        
        var ls = localStorage.getItem('activeUsers');
        if(ls){
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var test_arr = [];
          var json_arr = JSON.stringify(test_arr);
          localStorage.setItem('activeUsers',json_arr);
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
          for (let i = 0; i < userTextList.length; i++) {
            const user = userTextList[i];
            
            
            if(user.userID == this.currentActiveDoctorName){
              if(type == "replyBox"){
                user.replyBox = (<HTMLTextAreaElement>document.getElementById('comment')).value;
              }
              else if(type == "vetPublicNotes"){
                user.vetPublicNotes = (<HTMLTextAreaElement>document.getElementById('vetPublicNotes')).value;
              }
              else if(type == "consultationClose"){
                user.consultationClose = (<HTMLTextAreaElement>document.getElementById('endConsultation')).value;
              }
              else if(type == "vetPrivateNotes"){
                user.vetPrivateNotes = (<HTMLTextAreaElement>document.getElementById('vetPrivateNotes')).value;
              }
            }

            if(i+1 == userTextList.length){
              var jsonArr = JSON.stringify(userTextList);
              localStorage.setItem('activeUsers',jsonArr)
            }
          }

      }

      setTextBoxForUser(userID,type){
        var ls = localStorage.getItem('activeUsers');
        if(ls){
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var userTextList;
        }
        if(userTextList != undefined && userTextList.length > 0){
          for (let i = 0; i < userTextList.length; i++) {
            const user = userTextList[i];
            
            
            if(user.userID == userID){
                if(type == 'replyBox'){
                  (<HTMLTextAreaElement>document.getElementById('comment')).value = user.replyBox;
                  this.replyMessage = user.replyBox;
                  this.autoTextarea();
                }
                if(type == 'consultationClose'){
                (<HTMLTextAreaElement>document.getElementById('endConsultation')).value = user.consultationClose;
                this.endConsultation.get('endConsultation').setValue(user.consultationClose);
                }
                if(type == 'vetPrivateNotes'){
                  (<HTMLTextAreaElement>document.getElementById('vetPrivateNotes')).value = user.vetPrivateNotes;
                  this.endConsultation.get('vetPrivateNotes').setValue(user.vetPrivateNotes);
                }
                if(type == 'vetPublicNotes'){
                  (<HTMLTextAreaElement>document.getElementById('vetPublicNotes')).value = user.vetPublicNotes;
                  this.endConsultation.get('vetPublicNotes').setValue(user.vetPublicNotes);
                }
              }
              

          }
        }
      }
      clearLocalStorageUser(endUser){
        var ls = localStorage.getItem('activeUsers');
        if(ls){
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var userTextList;
        }

        for (let i = 0; i < userTextList.length; i++) {
          const user = userTextList[i];

          if(user.userID == endUser){
              userTextList.splice(i,1); 
              i-1;
          }
        }
        var jsonArr = JSON.stringify(userTextList);
        localStorage.setItem('activeUsers',jsonArr)
      }

      clearMessageFromLocalStorage(){
        var ls = localStorage.getItem('activeUsers');
        if(ls){
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var userTextList;
        }
        for (let i = 0; i < userTextList.length; i++) {
          const user = userTextList[i];

          if(user.userID == this.currentActiveDoctorName){
            user.replyBox = "";
          }
          
        }

        var jsonArr = JSON.stringify(userTextList);
        localStorage.setItem('activeUsers',jsonArr)
      }

      addUserToLs(userID){
        //let check if user already in ls

        //Get list
        var e = [];
        var ls = localStorage.getItem('activeUsers');
        if(ls){
          var userTextList = JSON.parse(localStorage.getItem('activeUsers'));
        }
        else {
          var userTextList:any = [];
        }

        //Loop through list and find user
        var isIn = false;

        if(userTextList.lenght > 0){
          for (let i = 0; i < userTextList.length; i++) {
            const user = userTextList[i];

            if(user.userID == userID){
              isIn = true
            }

            if(i+1 == userTextList.length) {
              if(isIn == true){
                //Do nothing because everything ok
              }
              else{
                //Lets add user
                var obj = {
                  userID: userID,
                  replyBox: "",
                  consultationClose: "",
                  vetPrivateNotes: "",
                  vetPublicNotes: "",
                }

                userTextList.push(obj);
                var jsonArr = JSON.stringify(userTextList);
                localStorage.setItem('activeUsers',jsonArr)


              }
            }
            
          }
        }
        else{
          var obj = {
            userID: userID,
            replyBox: "",
            consultationClose: "",
            vetPrivateNotes: "",
            vetPublicNotes: "",
          }

          userTextList.push(obj);
          var jsonArr = JSON.stringify(userTextList);
          localStorage.setItem('activeUsers',jsonArr)
        }
      }
    
      generateRegimenFromChat(){
        var user = this.currentActiveUserData._id;
        var messageDetails = this.globalMessages[this.currentActiveUserData._id];
        var messageList = messageDetails.messageList;
        console.log(messageDetails);

        var msgStr = "";



        for (let i = 0; i < messageList.length; i++) {
          const msg = messageList[i];

          if(msg.actMessage != undefined ){

            if(msg.template == 'TEMPLATE_14' || msg.template == 'TEMPLATE_13'){
              if(!msg.actMessage.startsWith('Esitiedot: \n\nHei')){

                if(msg.template == 'TEMPLATE_14'){
                  msgStr = msgStr + '\n' + 'Eläinlääkäri '+ this.currentUserFullName + ':';
                }
                else {
                  msgStr = msgStr + '\n' + this.currentActiveUserData.firstName + ' ' +this.currentActiveUserData.lastName + ':';
                }

                msgStr = msgStr + '\n' + msg.actMessage + '\n';
              }
            }
            //If doc sent image
            else if(msg.template == "TEMPLATE_33"){
              msgStr = msgStr + '\n' + 'Eläinlääkäri '+ this.currentUserFullName + ' (kuva):';
              msgStr = /* msgStr + '\n' + msg.actMessage.location + '\n'; */ 'Lähetti kuvan'
            }
            //If user sent image
            else if(msg.template == "TEMPLATE_34"){
              msgStr = msgStr = msgStr + '\n' + this.currentActiveUserData.firstName + ' ' +this.currentActiveUserData.lastName + ' (kuva):';
              msgStr = /* msgStr + '\n' + msg.actMessage.location + '\n'; */ 'Lähetti kuvan'
            }
            //If doc sent video
            else if(msg.template == "TEMPLATE_43"){
              msgStr = msgStr + '\n' + 'Eläinlääkäri '+ this.currentUserFullName + ' (video):';
              msgStr = /* msgStr + '\n' + msg.actMessage.location + '\n'; */ 'Lähetti videon'
            }
            //If user sent video
            else if(msg.template == "TEMPLATE_44"){
              msgStr = msgStr = msgStr + '\n' + this.currentActiveUserData.firstName + ' ' +this.currentActiveUserData.lastName + ' (video):';
              msgStr = /* msgStr + '\n' + msg.actMessage.location + '\n'; */ 'Lähetti videon'
            }
            //If DOC SENT ATTACHMENT
            else if(msg.template == "TEMPLATE_53"){
              msgStr = msgStr + '\n' + 'Eläinlääkäri '+ this.currentUserFullName + ' (liitetiedosto):';
              msgStr = /* msgStr + '\n' + msg.actMessage.location + '\n'; */ 'Lähetti liitetiedoston'
            }
          }
        }

        (<HTMLTextAreaElement>document.getElementById('endConsultation')).value = msgStr;
        (<HTMLTextAreaElement>document.getElementById('endConsultation')).style.height = '400px'
        this.saveWrittenToLocalStorage('consultationClose');

        console.log(msgStr);
        
      }

      openImageEnlarger(imageKey){
        this.enlargerImageKey = imageKey;
    
    
        var cont = document.getElementById('image-enlarger');
        cont.classList.add('image-enlarger-active');
    
      }
    
      closeImageEnlarger(){
        var cont = document.getElementById('image-enlarger');
        cont.classList.remove('image-enlarger-active');
      }









  }
