import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-user-visits',
  templateUrl: './user-visits.component.html',
  styleUrls: ['./user-visits.component.css']
})
export class UserVisitsComponent implements OnInit,AfterViewInit {

  currentUserId='';
  visits: any = [];
  pets: any = [];
  authToken = localStorage.getItem("auth");
  errMessage = false;
  clinicID: string = this.route.snapshot.params.clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }


  ngOnInit() {
    this.myId();
  }
  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  myId(){

    var token = localStorage.getItem('auth');

    if(token == null){
      this.logOutt();
      this.authService.doLogOut();
    }
    var decodedToken = helper.decodeToken(token);

    this.currentUserId = decodedToken.result.id;


    var activeuser = localStorage.getItem('ACTIVE_USER');


    console.log('totta',decodedToken.result.designation)

    this.fetchRegisterData();
  }

  logOutt() {
    console.log("Request to logout", this.currentUserId);
    let req = {
      userID: this.currentUserId
    };
  
    this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log('vastaus',res);
        if(res.status == 200){
          
          //this.router.navigate(['login']);
          console.log("log out success");
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
      this.authService.doLogOut();
  
  }
  fetchRegisterData(){

    var req = {
      userID: this.currentUserId
    }

    this.http.post(environment.FETCH_USER_VISITS_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if(res.status == 200){
          this.visits = res.message;

        //PÄIVÄJÄRJESTYKSEEN 
        this.visits = this.visits.sort(function(a,b){
          return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
        });

        this.colorService.waitStyleColorUser();

        }else {
          this.visits = [];
          this.errMessage = true;
          this.colorService.waitStyleColorUser();

        }
       } ,(err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }



}
