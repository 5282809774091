<app-docheader></app-docheader>

<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Tuoterekisteri ({{productsLen}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button><br>
  <button class="btn btn-veteva-secondary mt-3 mb-4" type="button" routerLink="/{{clinicID}}/doctor-register"><i class="fas fa-plus-circle pe-2"></i>Lisää uusi ell</button><br>
  <button class="btn btn-veteva-secondary mb-4" type="button" data-bs-toggle="modal" data-bs-target="#check-modal"><i class="fas fa-download pe-2"></i>Lataa excel</button><br>

  

  <div class="table-container">

    <!-- <input type="date" class="form-control" id="user-date-fiter" ng-ngModel> -->

      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr class="w-100">
            <th scope="col" width="60%">Nimi</th>
            <th scope="col" width="40%">Avoin linkki</th>
          </tr>
        </thead>
        <tbody>

           <tr *ngFor="let data of products" >
              <td><strong> {{data.name}}</strong></td>
              <td><button class="btn btn-veteva-secondary" (click)="generatePaymentLink(data)">Luo linkki</button></td>
            </tr> 

        </tbody>
      </table>
    </div>
    </div>

    <button class="btn btn-veteva-secondary" id="get-link" data-bs-toggle="modal" data-bs-target="#edit-code-modal" style="display: none;">Lisää alekoodi</button>
<div class="modal fade" id="edit-code-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Avoin maksu</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <textarea type="text" class="form-control" disabled style="height: 125px;" id="link-input"></textarea>
        <div class="col-12 text-center mt-4">
            <button type="button" class="btn btn-veteva-secondary" (click)="copyPaymentLink()">
                <span *ngIf="!copied">Kopioi linkki</span>
                <span *ngIf="copied"><i class="far fa-check-circle me-2"></i>Kopioitu</span>
            </button>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>


  <div class="modal fade" id="check-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="text-center pt-4 pb-3">Luo excel</h5>
        <div id="check-modal-body">
          <p class="text-center">Valitse eläinlääkärit listasta</p>
          <div class="search-diff-container w-100">
            <table class="table">
              <thead class="veteva-table-header">
                <tr >
                  <td>Etunimi</td>
                  <td>Sukunimi</td>
                  <td>+</td>
                </tr>
              </thead>
              <tbody class="table-striped">
                <tr (click)="addDocToList(data)" class="w-100 pointer" id="term-{{data.id}}" *ngFor="let data of doctors">
                  <td>{{data.firstName}}</td>
                  <td>{{data.lastName}}</td>
                  <td><i class="fas fa-plus-circle"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <div *ngIf="selectedDocs.length != 0;else">
            <br>
            <p class="body1 mb-0"><strong>Valitsemani eläinlääkärit:</strong></p>
            <div *ngFor="let data of selectedDocs">
              <p class="selected-diagnosis mb-0">{{data.firstName}} {{data.lastName}}<i class="fas fa-trash-alt" (click)="removeDoc(data)"></i></p>
            </div>
            <br>
          </div>
        <div class="col-12 row pb-4 mt-3">
          <div class="col-12 text-center ">
            <button class="btn btn-veteva-secondary" (click)="generateExel()"><i class="fas fa-arrow-right me-2"></i>Generoi</button>
          </div>
          <div class="col-12 text-center mt-3 pointer">
            <a  id="check-close-btn" class="veteva-main-link" data-bs-dismiss="modal">Sulje</a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>


<table id="doc-table" style="display: none;" *ngIf="selectedDocs && selectedDocs.length > 0">
  <tr>
    <td>vet</td>
    <ng-container *ngFor="let pr of products">
      <td>{{pr.name}}</td>
    </ng-container>
  </tr>
  <tr *ngFor="let d of selectedDocs">
    <td>{{d.firstName}} {{d.lastName}}</td>
    <ng-container *ngFor="let pr of products">
      <td>https://app.vetsy.vet/#/{{clinicID}}/public-payment/{{pr._id}}/{{d.userID}}</td>
    </ng-container>
  </tr>
</table>

