import { AfterContentInit, Component, OnInit, } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-admin-clinics',
  templateUrl: './admin-clinics.component.html',
  styleUrls: ['./admin-clinics.component.css']
})
export class AdminClinicsComponent implements OnInit {

  currentUserId='';
  payments: any[];
  userData: any = {};
  currentDetails: any = {};
  authToken = localStorage.getItem("auth");
  clinics:any = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.fetchClinics()
  }

  fetchClinics(){

    this.http.get(environment.FETCH_ADMIN_CLINICS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          
          this.clinics = res.message;

        }
        else {
          this.clinics = [];
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })

  }

}
