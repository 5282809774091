import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';
import {ActivatedRoute, Router } from '@angular/router';

const helper = new JwtHelperService;

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  authToken = localStorage.getItem('auth');
  clinicID: string = this.route.snapshot.params.clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
    

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ["", Validators.required],
      phone: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      addressStreet: ["", Validators.required],
      addressCity: ["", Validators.required],
      addressZip: ["", Validators.required],
      organization: "",
    });
  }

  get email() { return this.form.get('email') };
  get phone() { return this.form.get('phone') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get addressStreet() { return this.form.get('addressStreet') };
  get addressCity() { return this.form.get('addressCity') };
  get addressZip() { return this.form.get('addressZip') };

  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  onSubmit(){

    var req = {
      email: this.form.get('email').value,
      phone: this.form.get('phone').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      addressCity: this.form.get('addressCity').value,
      addressStreet: this.form.get('addressStreet').value,
      addressZip: this.form.get('addressZip').value,
      clinicID: this.form.get('email').value,
    }

    console.log(req);
    

    this.http.post(environment.DOCTOR_REGISTER_USER, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ){
          alert('Käyttäjä luotu onnistuneesti');
          this.router.navigate(['/'+this.clinicID+'/users-register']);
          return;
        }

        else {
          alert('Käyttäjä on jo olemassa');
          return;



          window.scrollTo(0,0);
        }

      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )
    }


  

  

}
