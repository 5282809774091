	<app-not-auth-header></app-not-auth-header>
	<script src="https://www.google.com/recaptcha/api.js"></script>
	
	<div class="wrapperi h-100">
  <div class="container">
	  <div class="wrap">
        <div class="col-12 m-0 row">
			<div class="col-3"></div>
			<div class="col-12 col-md-6 p-0 mt-4">
			  <div class="col-12 text-center p-0">
				<h4 class="header-text mt-4">Heippa eläinlääkäri!</h4>
				<p class="desc-text">Syötä käyttäjätunnuksesi ja salasanasi. <span class="desc-text-bold">Eläinklinikan pääkäyttäjä saa ilmoituksen kirjautumisestasi.</span></p>
				<div class="alert alert-danger" *ngIf="errMessage">
				  <p class="text-center">Käyttäjätunnuksesi tai salasanasi on väärin</p>
				</div>
			  </div>
			</div>
			<div class="col-3"></div>
	
		  </div>
	
  

		  <div class="col-12 p-0 row m-0">
			  <div class="col-3"></div>
			  <div class="col-12 col-md-6 p-0">
				  <div class="col-12 p-0 ">
					  <div class="col-12 login-form-bg">
						  <div class="col-8 col-md-6 mx-auto" *ngIf="errMessage">
							  <div class="alert alert-warning">
								  <p class="text-center">
									  Käyttäjätunnus tai salasana on väärin
								  </p>
							  </div>
						  </div>
						  <div class="col-8 col-md-6 mx-auto" *ngIf="lockErr">
							<div class="alert alert-warning">
								<p class="text-center">
									Käyttäjätili on lukittu
								</p>
							</div>
						</div>
						  <div class="col-8 col-md-6 mx-auto" *ngIf="botErr">
							<div class="alert alert-danger">
								<p class="text-center">
									Järjestelmä tunnisti sinut botiksi. Pääsy evätty. Ole hyvä ja yritä uudelleen.
								</p>
							</div>
						</div>
						  <div id="loader" class="loader-container mt-4 mb-4" style="display:none">
							  <div class="loader mx-auto"></div>
							  <p class="body2b-b text-center mt-4 mb-4">Pieni hetki kirjaudutaan sisään...</p>
						  </div>
  
  
						  <form class="flex-row " id="login-form" [formGroup]="loginForm"
						  (ngSubmit)="renderReCaptch()">
						  <div class="col-12 row m-0 ps-0 pe-0">
							  <div class="form-group mb-2 flex-column col-12 p-0">
								  <input class="form-control" formControlName="userName" type="email" id="userName" placeholder="Sähköpostiosoite">
							  </div>
						  </div>
						  <div class="col-12 row m-0 ps-0 pe-0">
							  <div class="form-group mb-2 flex-column col-12 p-0">
								  <input class="form-control" formControlName="password" autocomplete="on" type="password" placeholder="Salasana" id="password">
								  </div>
						  </div>
					  <div class=" mx-auto mt-4">
						  <div class="text-center" >
							  <button #recaptcha id="recaptcha" [disabled]="loginForm.invalid" class="btn login-btn btn-veteva-secondary"   >Kirjaudu</button>
						  </div>
					  </div>
					  </form>
				  
			  </div>
				  </div>
			  </div>
			  <div class="col-3"></div>
  
		  </div>
	  </div>