
<!--Navbar-->
<nav id="main-nav" class="navbar navbar-expand-lg navbar-dark pb-0 height">
    <div class="container-fluid">
    <!-- Navbar brand -->
    <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0"><img width="180" src="../../assets/images/chat2vetLogoWebWhite.png"> </a>
  
    <!-- Collapse button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line3"></span>
    </button>
  
    <!-- Collapsible content -->
    <div class="collapse navbar-collapse" id="basicExampleNav">
  
      <!-- Links -->
      <ul class="navbar-nav ms-auto">
        <li class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/admin-home" id="doctorhome">KOTI</a>
        </li>
  
  
        <!-- Dropdown -->
         <ul class="nav-item single-item p-2 dropdown">
          <a id="rekisteri-page" class="nav-link dropdown-toggle" id="ddbtne" data-bs-toggle="dropdown"
            aria-expanded="false">REKISTERI</a>
          <ul class="dropdown-menu" aria-labelledby="ddbtn">
            
            <li><a class="dropdown-item" routerLink="/admin/clinics">KLINIKAT</a></li>
            <li><a  class="dropdown-item" routerLink="/admin/payments">MAKSUT</a></li>
          </ul>
        </ul>
        <li  class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/admin/global-settings" id="clinic-settings">ASETUKSET</a>
        </li>
  <!--       <li  class="nav-item single-item p-2" *ngIf="isAdmin == true">
          <a class="nav-link" routerLink="/clinic/statistics/today" id="statistics">STATISTIIKKA</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link lopeta-link mt-2" href="#" (click)="logOutt()">LOPETA</a>
        </li>
  
      </ul>
      <!-- Links -->
  
    </div>
    <!-- Collapsible content -->
  </div>
  </nav>
  
  
  