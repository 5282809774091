
<!--Navbar-->
<nav id="main-nav" class="navbar navbar-expand-lg navbar-dark pb-0 height">
    <div class="container-fluid">
    <!-- Navbar brand -->
    <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0"><img width="180" src="../../assets/images/chat2vetLogoWebWhite.png"> </a>
  
    <!-- Collapse button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line3"></span>
    </button>
  
    <!-- Collapsible content -->
    <div class="collapse navbar-collapse" id="basicExampleNav">
  
      <!-- Links -->
      <ul class="navbar-nav ms-auto">
<!--         <li class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/doctorhome" id="doctorhome">K</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/vet/doctor-detail/" id="profile">PROFIILI</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/vet/hashtag-settings/" id="hashtag">HASHTAGIT</a>
        </li>
        <li class="nav-item single-item p-2">
          <a class="nav-link" routerLink="/doctor-chat-history" id="doctor-chat-history">HISTORIA</a>
        </li> -->
  
  


  
      </ul>
      <!-- Links -->
  
    </div>
    <!-- Collapsible content -->
  </div>
  </nav>
  
  
  