import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchBreed'
})
export class SearchBreedPipe implements PipeTransform {

  transform(items: any[], searchTextAll: string, searchNumber:any): any[] {
    if(!items) return [];
    if(searchTextAll == "") return items;
    if(!searchTextAll) return items;
    searchTextAll = searchTextAll.toLowerCase();
    return items.filter( data => {
    return data.name_fi.toLowerCase().includes(searchTextAll);
    });
    }

}
