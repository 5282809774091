import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { ActivatedRoute,Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";


const helper = new JwtHelperService;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  message: string;
  currentUserId='';
  currentUserName = '';
  authToken = localStorage.getItem('auth');
  routeID: string = this.route.snapshot.routeConfig.path;
  clinicID;
  logo;
  

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };


  constructor(
    private http: HttpClient,
    private authService: AuthService, 
    private router: Router,
    private route: ActivatedRoute,
    
  ){
    this.currentUserId = this.authService.getTokenData().id;
    this.clinicID = this.authService.getTokenData().clinicID;
  }

ngOnInit(){
this.ActiveLink();
}

logOutt() {
  this.authService.doLogOut();
}

ActiveLink() {

  var obj = JSON.parse(localStorage.getItem('colors'))
  this.logo = obj.logoUrl;

  document.getElementById('main-nav').style.backgroundColor = obj.mainColor;
  console.log(document.getElementById('main-nav'))


  switch(this.routeID){
    case ":clinicID/home": {
      var elm = document.getElementById("home")
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      this.setActiveLink(elm);
      break;
    }
    case ":clinicID/chathistory": {
      var elm = document.getElementById("home")
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      this.setActiveLink(elm);
      break;
    }
    case ":clinicID/users/edit-details/:id": {
      var elm = document.getElementById("profile")
      this.setActiveLink(elm);
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      break;
    }
    case ":clinicID/user/visit-register": {
      var elm = document.getElementById("visits")
      this.setActiveLink(elm);
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      break;
    }
    case ":clinicID/user/visit-detail/:id": {
      var elm = document.getElementById("visits")
      this.setActiveLink(elm);
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      break;
    }
    case ":clinicID/add-pet": {
      var elm = document.getElementById("visits")
      this.setActiveLink(elm);
      break;
    }
    case ":clinicID/question-answer": {
      var elm = document.getElementById("q-a")
      this.setActiveLink(elm);
/*       document.getElementById('main-nav').classList.remove('original-nav');
      document.getElementById('main-nav').classList.add('change-nav');
 */      break;
    }
  }
}

setActiveLink(elm){
  var obj = JSON.parse(localStorage.getItem('colors'))

  elm.classList.add("active-nav-item");
  elm.style.backgroundColor = obj.secondaryColor;

}



  


}
