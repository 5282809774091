import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import { FileUploaderService } from '../service/file-uploader.service';

const helper = new JwtHelperService;



@Component({
  selector: 'app-doctor-chat-history',
  templateUrl: './doctor-chat-history.component.html',
  styleUrls: ['./doctor-chat-history.component.css']
})
export class DoctorChatHistoryComponent implements OnInit,AfterViewInit {

  doctorList: any = []; // Doctor list
  globalMessages: any = []; // Map of doctor and message
  currentActiveMessageList: any = []; // Current Acitve message list
  defaultDocterName = 'patient';
  currentUserRole ='';//nykyisen käyttäjän rooli
  currentUserId=''; 
  currentUserName = ''; // nykyisen käyttäjän käyttäjänimi
  lastMessage=''; //viimeisin toisen käyttäjän lähettämä viesti
  queueList = [];
  activeDoctors:any=[];
  activeConsultantData = {};
  currentActiveDoctorName: string = "Autan alkuun"; // Current Acitve message list
  authToken = localStorage.getItem('auth');
  showReplyBox: boolean = false;
  DR_ASSISTANT_NAME: string = '';
  listData =  [];
  visitors = [];
  noDocs = false;
  userVisits = [];
  selectedVisitor = {};
  selectedVisit;
  fileURL = environment.FETCH_ATTACHMENT;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  replyMessage: string = '';

  addFeedback: FormGroup;

  

  @ViewChild("chatMessageContainer", { static: true }) chatMessageContainer: ElementRef;
  disableScrollDown: boolean = false;

  paypalButtonVisible: boolean = false;

  selectedMessage;

  
    


  constructor(
    private router: Router, 
    private authService: AuthService,
    private colorService: ColorServiceService,
    private fileUploaderService: FileUploaderService,
    private http: HttpClient, 
    private fb: FormBuilder,
    private orderByPipe: OrderByPipe) {
  }

  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  ngOnInit() {
    this.currentActiveDoctorName = localStorage.getItem('USER');
    this.myId();
    this.fetchVisitorNames();
    console.log(this.selectedVisitor)


    this.addFeedback = this.fb.group({
      npsRange: "",
      npsText: ""
    });

    }

  ngOnDestroy() {
    
  }
  //----------------------------------------------------------------------------------------------------------
// uudet viestit
//----------------------------------------------------------------------------------------------------------

get npsRange() { return this.addFeedback.get('npsRange') }
get npsText() { return this.addFeedback.get('npsText') }

myId(){
  var token = localStorage.getItem('auth');
  var decodedToken = helper.decodeToken(token);

  this.currentUserId = decodedToken.result.id;
  this.currentUserName = decodedToken.result.email;
}


//----------------------------------------------------------------------------------------------------------
// päivitetään aika ja viesti listaan
//----------------------------------------------------------------------------------------------------------
updateShortMessageAndTime(doctorName) {
  for (var i = 0; i < this.doctorList.length; i++) {
    var doctor = this.doctorList[i];

    if (doctor.name == doctorName) {

      this.disableScrollDown = false;

      doctor.shortMessage = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].shortMessage;
      var newMessage = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 2].shortMessage;
      var scMes = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length-1].shortMessage;


      if(newMessage.includes("Eläinlääkäri on ottanut teidät nyt vastaan.")){
        localStorage.setItem('USER', doctorName);

      }
      doctor.time = this.globalMessages[doctorName]['messageList'][this.globalMessages[doctorName].messageList.length - 1].createdDate

      console.log("now doctor detail :: ", doctor);
      this.OnSelectDoctor({ name: doctor.name })


      if(doctor.shortMessage == 'Konsultaatio eläinlääkärin kanssa on ny loppu.'){
        this.showReplyBox = false
      }

      
    }

  }
  this.disableScrollDown = false;
  this.doctorList = this.orderByPipe.transform(this.doctorList, 'time', false);
}


  //----------------------------------------------------------------------------------------------------------
  // Viestien käsittelijä // messagehandler
  //----------------------------------------------------------------------------------------------------------
  openNav(e) {
    var width = window.innerWidth;

      document.getElementById("mySidenav").style.width = "90%";

  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  AddFeedBack(){

  }

//----------------------------------------------------------------------------------------------------------
// viestilista
//----------------------------------------------------------------------------------------------------------


  fetchVisitorNames(){
    this.http.get(environment.DOC_FETCH_VISITORS, this.httpOptions).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.visitors = res.message;
          console.log(this.visitors);
        } else {
          this.visitors = [];
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }

  fetchUserVisitDetails(){
    var user = (<HTMLSelectElement>document.getElementById('user-select')).value

    //Clear data
    this.selectedVisitor = {};
    this.userVisits = [];

    var req = {
      userID: user
    }

    //Get username from loop
    for (let o = 0; o < this.visitors.length; o++) {
      const v = this.visitors[o];

      if(v.owner == user){
        Object.assign(this.selectedVisitor, {
          name: v.firstName + " " + v.lastName
        })
      }
    }

    this.http.post(environment.FETCH_USER_VISIT_DATA, req, this.httpOptions).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userVisits = res.message;
          console.log(this.userVisits);
          if(this.userVisits.length == 1){
            this.fetchUserMessagesByDate();
          }
        } else {
          this.userVisits = [];
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
      
  }

  fetchUserMessagesByDate(){

    var visit;

    if(!document.getElementById('user-date-select') ||(<HTMLSelectElement>document.getElementById('user-date-select')).value  == null){
      visit = this.userVisits[0];
      Object.assign(this.selectedVisitor, {
        petName: visit.petName,
        petBreed: visit.petBreed,
        image: null,
      })
    }
    else{
      var visitID = (<HTMLSelectElement>document.getElementById('user-date-select')).value
      for (let i = 0; i < this.userVisits.length; i++) {
        const v = this.userVisits[i];

        if(visitID == v._id){
          visit = v;
          Object.assign(this.selectedVisitor, {
            petName: v.petName,
            petBreed: v.petBreed,
            image: null,
          })
        }

        
      }
    }

    this.selectedVisit = visit;
    console.log(this.selectedVisit)


    console.log(visit);
    var req = {
      date:visit.createdDate,
      owner:visit.owner,
    }

    this.http.post(environment.DOC_MESSAGE_LIST_END_POINT_BY_DATE,req, this.httpOptions).subscribe(
      (res: any) => {
        console.log("Message list found :: ", res);
        if (res.status == 201) {
          this.globalMessages = {};
          alert('Viestejä ei löytynyt.');
        } else {
          this.globalMessages = res.message;
          this.currentActiveMessageList = this.globalMessages;
          this.colorService.waitStyleColorUserBubbles()
        }

      }, (err: any) => {
        console.log("Error occured while finding message list for user");
      })
  }


  OnSelectUserByUserNameOnLoad(userName){

    for(var i = 0; i <= this.doctorList.length; i++ ){
      var doctor = this.doctorList[i];


        if(doctor == undefined){
          this.OnSelectDoctor(userName)
        }
        else if(doctor.name == userName){
          this.OnSelectDoctor(doctor)
      }
  
    
}

}


//----------------------------------------------------------------------------------------------------------
// Nappula josta patient avaa keskustelun
//----------------------------------------------------------------------------------------------------------


  logOut() {
    console.log("Request to logout");
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }



  onScroll() {
    let element = this.chatMessageContainer.nativeElement;
    let currentWindowPos = element.scrollHeight - element.scrollTop;
    let diff = currentWindowPos - element.clientHeight;
    diff = Math.abs(diff);
    if (this.disableScrollDown && diff <= 1) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
    //console.log(" On scroll called :: disableScroll down : ", this.disableScrollDown);
  }

  private scrollToBottom(): void {
    //console.log("Scroll to bottom disabled :: ", this.disableScrollDown);
    if (this.disableScrollDown) {
      return
    }
    try {
      this.chatMessageContainer.nativeElement.scrollTo({ top: this.chatMessageContainer.nativeElement.scrollHeight, behavior: 'smooth' })
      // this.myScrollContainer.nativeElement.scroll
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }





  OnSelectDoctor(doctor) {
    this.scrollToBottom();
    //this.CheckPatientCredit();

    console.log("Request to select doctor :: ", doctor.name);

    if (this.globalMessages[doctor.name] == null || this.globalMessages[doctor.name] == undefined) {
      console.log("Detail for doctor not available");
      return;
    }
    this.currentActiveDoctorName = doctor.name;
    this.lastMessage = doctor.shortMessage;
    localStorage.setItem('USER', this.currentActiveDoctorName);
    this.currentActiveMessageList = this.globalMessages[doctor.name].messageList;

    
  }






//----------------------------------------------------------------------------------------------------------
// käytössä
//----------------------------------------------------------------------------------------------------------

}
