import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService;

@Component({
  selector: 'app-admin-sms-validation',
  templateUrl: './admin-sms-validation.component.html',
  styleUrls: ['./admin-sms-validation.component.css']
})
export class AdminSmsValidationComponent implements OnInit {

  authToken = localStorage.getItem('TEMP_ADMIN_AUTH');
  currentUserId;
  routeID: string = this.route.snapshot.params.id;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService) {
      
     }


  ngOnInit(): void {
    this.myId();
    this.validateSMStoken()
  }

  myId(){
    var token = localStorage.getItem('TEMP_ADMIN_AUTH');
    var decodedToken = helper.decodeToken(token);

    if(!token) {
      this.router.navigate(['/login'])
    }

    this.currentUserId = decodedToken.data.id;

  }

  validateSMStoken(){

    var req = {
      token: this.routeID,
    }

    this.http.post(environment.VALIDATE_ADMIN_TEMP_AUTH, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res, 'Tarkasta!');
      if(res.status == 200) {

      }
      else {
        localStorage.removeItem('TEMP_ADMIN_AUTH');
        this.router.navigate(['/login']);
        alert('Token väärä');

      }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  
  }

  setStatus(){

    var number = (<HTMLInputElement>document.getElementById('sms')).value

    var req = {
      num: number.toString(),
      ID:this.currentUserId
    }

    this.http.post(environment.ADMIN_SMS_VALIDATION, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res, 'Tarkasta!');
      if(res.status == 200) {
        localStorage.removeItem('TEMP_DOC_AUTH');
        this.authService.adminAuth(res.token);

        console.log(res.message);
      }
      else {
        localStorage.removeItem('TEMP_DOC_AUTH');
        this.router.navigate(['/login']);
        alert('Sms-koodi väärin');
      }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

}
