import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { DoctorhomeComponent } from './doctorhome/doctorhome.component';
import { RegisterComponent } from './register/register.component';
import { DoctorRegisterComponent } from './doctor-register/doctor-register.component';
import { DoctorLoginComponent } from './doctor-login/doctor-login.component';
import { AddPetComponent } from './add-pet/add-pet.component';
import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { EditPetComponent } from './edit-pet/edit-pet.component';
import { ChathistoryComponent } from './chathistory/chathistory.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetComponent } from './reset/reset.component';
import { ClinicSettingsComponent } from './clinic-settings/clinic-settings.component';
import { EditUserDetailsComponent } from './edit-user-details/edit-user-details.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { PetRegisterComponent } from './pet-register/pet-register.component';
import { VisitRegisterComponent } from './visit-register/visit-register.component';
import { VisitDetailComponent } from './visit-detail/visit-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { VetPetDetailComponent } from './vet-pet-detail/vet-pet-detail.component';
import { DoctorsRegisterComponent } from './doctors-register/doctors-register.component';
import { DoctorDetailComponent } from './doctor-detail/doctor-detail.component';
import { UserVisitsComponent } from './user-visits/user-visits.component';
import { UserVisitDetailComponent } from './user-visit-detail/user-visit-detail.component';
import { AllPetsComponent } from './all-pets/all-pets.component';
import { HashtagSettingsComponent } from './hashtag-settings/hashtag-settings.component';
import { OrganizationComponent } from './organization/organization.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { HomeVisitComponent } from './home-visit/home-visit.component';
import { CallbackComponent } from './callback/callback.component';
import { ActivateDoctorComponent } from './activate-doctor/activate-doctor.component';

import { NotAuthenticatedGuard } from './guards/not-authenticated.guard';
import { AlwaysAuthenticatedGuard } from './guards/always-authenticated.guard';
import { AcceptTermsOfServiceComponent } from './accept-terms-of-service/accept-terms-of-service.component';
import { VetSmsValidationComponent } from './vet-sms-validation/vet-sms-validation.component';
import { QueueTimeRegisterComponent } from './queue-time-register/queue-time-register.component';
import { PaymentsRegisterComponent } from './payments-register/payments-register.component';
import { FeedbackRegisterComponent } from './feedback-register/feedback-register.component';
import { DoctorChatHistoryComponent } from './doctor-chat-history/doctor-chat-history.component';
import { StatisticsTodayComponent } from './statistics-today/statistics-today.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ModeratorLoginComponent } from './moderator-login/moderator-login.component';
import { AdminSmsValidationComponent } from './admin-sms-validation/admin-sms-validation.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AddClinicComponent } from './add-clinic/add-clinic.component';
import { AdminPaymentsComponent } from './admin-payments/admin-payments.component';
import { AdminClinicsComponent } from './admin-clinics/admin-clinics.component';
import { AdminSingleClinicComponent } from './admin-single-clinic/admin-single-clinic.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DoctorAddPetComponent } from './doctor-add-pet/doctor-add-pet.component';
import { AddVisitComponent } from './add-visit/add-visit.component';
import { AdminGlobalSettingsComponent } from './admin-global-settings/admin-global-settings.component';
import { PublicPaymentComponent } from './public-payment/public-payment.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { ProductsRegisterComponent } from './products-register/products-register.component';
import { ModeratorGuard } from './guards/moderator.guard';
import { PublicVideoComponent } from './public-video/public-video.component';


const routes: Routes = [
  {
    path: ':clinicID/login',
    component: LoginComponent,
  },
/*   {
    path: 'accept-terms-of-service/:id',
    component: AcceptTermsOfServiceComponent,
    canActivate: [NotAuthenticatedGuard]
  }, */
  {
    path: ':clinicID/question-answer',
    component: QuestionAnswerComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/register',
    component: RegisterComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':clinicID/public-payment/:productID/:doctorID',
    component: PublicPaymentComponent,
  },
  {
    path: ':clinicID/publicVideo',
    component: PublicVideoComponent,
  },
  {
    path: ':clinicID/payment-status/:status',
    component: PaymentStatusComponent,

  },
  {
    path: ':clinicID/forgot',
    component: ForgotComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':clinicID/reset-password/:id',
    component: ResetPasswordComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctor-register',
    component: DoctorRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctor-add-pet/:userID',
    component: DoctorAddPetComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/add-visit/:userID/:petID',
    component: AddVisitComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctor-register',
    component: DoctorRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/products-register',
    component: ProductsRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/clinic-settings',
    component: ClinicSettingsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: 'admin/add-clinic',
    component: AddClinicComponent,
    canActivate: [ModeratorGuard]
  },
  {
    path: 'admin/clinics',
    component: AdminClinicsComponent,
    canActivate: [ModeratorGuard]
  },
  {
    path: 'admin/clinic/:clinicID/:id',
    component: AdminSingleClinicComponent,
    canActivate: [ModeratorGuard]
  },
  {
    path: 'admin/payments',
    component: AdminPaymentsComponent,
    canActivate: [ModeratorGuard]
  },
  {
    path: 'admin/global-settings',
    component: AdminGlobalSettingsComponent,
    canActivate: [ModeratorGuard]
  },
  {
    path: 'clinic/statistics/today',
    component: StatisticsTodayComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctor-chat-history',
    component: DoctorChatHistoryComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctor-login',
    component: DoctorLoginComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'moderator-login',
    component: ModeratorLoginComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':clinicID/activate-user/:token',
    component: ActivateUserComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: ':clinicID/activate-doctor/:token',
    component: ActivateDoctorComponent,
    canActivate: [NotAuthenticatedGuard]
  },   
  {
    path: ':clinicID/home',
    component: HomeComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/chathistory',
    component: ChathistoryComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/pets-register',
    component: PetRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: 'all-pets',
    component: AllPetsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/doctors-register',
    component: DoctorsRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/add-user',
    component: AddUserComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/queue-time-register',
    component: QueueTimeRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clincID/payments-register',
    component: PaymentsRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/feedback-register',
    component: FeedbackRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/vet/visit-detail/:id',
    component: VisitDetailComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/vet/user-detail/:id',
    component: UserDetailComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/vet/pet-detail/:id',
    component: VetPetDetailComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/vet/hashtag-settings/:id',
    component: HashtagSettingsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
/*   {
    path: 'vet/home-visit',
    component: HomeVisitComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  }, */
  {
    path: ':clinicID/vet/doctor-detail/:id',
    component: DoctorDetailComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/visits-register',
    component: VisitRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/users-register',
    component: UserRegisterComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/user/visit-register',
    component: UserVisitsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
/*   {
    path: 'organization/:id',
    component: OrganizationComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },   */

  {
    path: ':clinicID/user/visit-detail/:id',
    component: UserVisitDetailComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/add-pet',
    component: AddPetComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/pets/edit-pet/:id',
    component: EditPetComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/users/edit-details/:id',
    component: EditUserDetailsComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
/*   {
    path: 'patientdash',
    component: PatientDashboardComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  }, */
  {
    path: ':clinicID/doctorhome',
    component: DoctorhomeComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: 'admin-home',
    component: AdminHomeComponent,
    canActivate: [AlwaysAuthenticatedGuard]
  },
  {
    path: ':clinicID/vet-validation/:id',
    component: VetSmsValidationComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: 'admin-sms-validation/:id',
    component: AdminSmsValidationComponent,
    canActivate: [NotAuthenticatedGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

