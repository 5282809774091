import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-admin-global-settings',
  templateUrl: './admin-global-settings.component.html',
  styleUrls: ['./admin-global-settings.component.css']
})
export class AdminGlobalSettingsComponent implements OnInit {

  reasonsArr= [];
  reason: FormGroup;
  SuccessMessage = false;
  ErrMessage = false;
  reasonForm: FormGroup;
  messageSuccess="";
  messageErr="";
  authToken = localStorage.getItem('auth');
  currentReason;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.reason = this.fb.group({
      reason: ['', Validators.required]
    });

    this.reasonForm = this.fb.group({
      e_reason: ['', Validators.required]
    });

    this.getReasons();

  }

  getReasons(){

    this.http.get(environment.ADMIN_REASONS, this.httpOptions).subscribe(
    (res: any) => {

      if(res.status == 200){
        this.reasonsArr = res.message;
        console.log(this.reasonsArr)
        
      }
      else {
        alert('Käyntisyiden hakemisessa tapahtui virhe')
      }

    }, (err: any) => {

      console.log("Error while login :: ", err);
    })

  }

  get cameReason() { return this.reason.get('reason') };

    addReason() {

          if (!this.reason.valid) {
            return;
          }
         console.log('Form valid');
      
          const request = {
            cameReason: this.reason.get('reason').value,
          }
            this.http.post(environment.ADD_REASON_END_POINT, request, this.httpOptions).subscribe(
              (res: any) => {
                console.log(res);
                if (res.status == "success") {
                  this.messageSuccess="";
                  this.messageErr="";

                  this.reason.reset();
                  this.getReasons();

                  this.messageSuccess = "Käyntisyyn lisääminen onnistui."

                  this.SuccessMessage = true;
                  this.waitAlert();
                  

                } else {
                  this.messageErr = "Käyntisyyn lisääminen onnistui."

                  this.ErrMessage = true;

                  this.waitAlert();
                  

                }
              }, (err: any) => {
      
                console.log("Error while login :: ", err);
              }
            )
      
          
        }
    deleteReason(syy){



      var req = {
        reasonID: syy
      }
      console.log(syy);
      this.http.post(environment.REMOVE_REASON, req,this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.messageSuccess="";
            this.messageErr="";
            this.getReasons()
            

          } else {
            this.messageSuccess="";
            this.messageErr="";
            this.messageErr="Päivittäminen epäonnistui, ole hyvä ja yritä uudelleen"
            window.scrollTo(0,0)
            //loginError = true;
          }
        }, (err: any) => {
          console.log("Error occured while finding designation for the user : ListData",err);
        }) 

    }

    get e_reason() { return this.reasonForm.get('e_reason') };

    editReason(reason){
      this.currentReason = reason;
      console.log(this.currentReason);
      console.log(reason['reason'])
      this.reasonForm.get('e_reason').setValue(reason['reason']);
      document.getElementById('edit-reason-btn').click();
    }

    editSelectedReason(){
      const req = {
        reasonID: this.currentReason['_id'],
        reason: this.reasonForm.get('e_reason').value
      }

        this.http.post(environment.EDIT_REASON, req, this.httpOptions).subscribe(
          (res: any) => {
            if (res.status == 200) {
              this.messageSuccess="Käyntisyyn muokkaaminen onnistui";
              this.messageErr="";
  
              this.reasonForm.reset();
              this.getReasons();
  
              this.SuccessMessage = true;
              this.waitAlert();
              
  
            } else {
              this.messageErr = "Käyntisyyn muokkaaminen epäonnistui: "+ res.message;
              this.ErrMessage = true;
              this.waitAlert();
            }
          }, (err: any) => {
  
            console.log("Error while login :: ", err);
          }
        )
    }

    openCity(evt, cityName) {
      // Declare all variables
      var i, tabcontent, tablinks;
    
    
      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
    
      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
    
      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    
    
      }

      waitAlert() {
        var that = this;
    
        setTimeout(() =>{
          that.SuccessMessage = false;
          that.messageSuccess = "";
          that.messageErr = "";
          that.ErrMessage = false;
        },5000)
    
      }

}
