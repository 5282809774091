<app-docheader></app-docheader>
<div class="header-page-container pb-4" *ngIf="isAdmin">

  <h2 class=" pt-4 header-text text-center pb-4">Lisää eläinlääkäri</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>
<div class="wrapperi">
<div class="container">
	<div class="wrap">
	<div class="d-flex flex-row justify-content-center">
		<div class="flex-wrap mt-4">
      <div *ngIf="passwordErr != ''">
        <div class="alert alert-warning">
          <p>{{passwordErr}}</p>
        </div>
      </div>
      <div *ngIf="messageErr">
        <div class="alert alert-warning">
          <p>{{messageErr}}</p>
        </div>
      </div>
		</div>
  </div>
  <div style="display: none;" id="activation-alert" class="alert alert-success text-center">
    <p class="body2b">Hienoa! Rekisteröityminen onnistui! Sait sähköpostiisi aktivointilinkin, jonka avulla pystyt aktivoimaan käyttäjätilisi.</p>
    <button class="btn btn-secondary" routerLink="/login">Kirjaudu sisään</button>
  </div>

      
  </div>
  
  <div class="col-12 row">
    <div class="col-3"></div>
    <div class="col-12 col-md-6">
      <form class="add-pet-form" [formGroup]="form"
      (ngSubmit)="checkMobileNumeber()">
      

      <!-- Sähköpostiosoite -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
            <div class="my-alerts" *ngIf="email.invalid && email.touched">
              <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
              <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Puhelinnumero -->
      <div class="form-group">
        <div class="col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
            <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
              <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
              <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Etunimi -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input type="text" placeholder="Etunimi" formControlName="firstName" id="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
            <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
              <p class="mt-2" *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
              <p *ngIf="firstName.errors.firstName"> Vaaditaan määrätty etunimi</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Sukunimi -->
      <div class="form-group">
        <div class="col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
            <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
              <p class="mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
              <p *ngIf="lastName.errors.lastName"> Vaaditaan määrätty sukunimi</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Lääkärin merkki -->
      <div class="form-group">
        <div class="col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input type="text" placeholder="Titteli" formControlName="designation" id="designation" class="form-control" [class.invalid-input]="designation.errors && designation.touched" required>
            <div class="my-alerts" *ngIf="designation.invalid && designation.touched">
              <p class="mt-2" *ngIf="designation.errors.required">Titteli on pakollinen</p>
              <p *ngIf="designation.errors.designation"> Vaaditaan määrätty titeli</p>
            </div>
          </div>
        </div>
      </div>

        <!-- Eläinlääkärin tyyppi -->
        <div class="form-group">
          <div class="col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <select type="text" placeholder="Eläinlääkärin tyyppi" formControlName="isAdmin" id="isAdmin" class="form-control" [class.invalid-input]="isAdmin.errors && isAdmin.touched" required>
                <option></option>
                <option value="true">Johtava eläinlääkäri</option>
                <option value="false">Eläinlääkäri</option>
              </select>
              <div class="my-alerts" *ngIf="isAdmin.invalid && isAdmin.touched">
                  <p class="mt-2" *ngIf="isAdmin.errors.required">Tieto on pakollinen</p>
                  <p *ngIf="isAdmin.errors.isAdmin"> Vaaditaan määrätty tieto</p>
              </div>
              </div>
          </div>
          </div>

      <button class="btn btn-veteva-secondary"
          [disabled]="form.invalid">Rekisteröi</button>
  </form>
    </div>
    <div class="col-3"></div>
  </div>
  <div style="display: block;" id="register-form" class="d-flex flex-row justify-content-center ms-4">
    


</div>
</div>