import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-activate-doctor',
  templateUrl: './activate-doctor.component.html',
  styleUrls: ['./activate-doctor.component.css']
})
export class ActivateDoctorComponent implements OnInit {

  errMessage = "";
  successMessage = "";
  form: FormGroup;
  myAccessToken:string = this.route.snapshot.params.token;
  clinicID: string = this.route.snapshot.params.clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private fb: FormBuilder,
    private colorService: ColorServiceService,
    private authService: AuthService) { }


  ngOnInit() {
    this.activateUser();
    this.setColors();

    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8)
      ]],
      password2: ['', [
        Validators.required,
        Validators.minLength(8)
      ]]
    });
  }

  setColors(){
    var ls = localStorage.getItem('colors');
    console.log(ls);
    if(ls){
      this.colorService.styleUser();
    }
    else{
    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
    }
  }

  activateUser() {
    var req = {
      activationToken: this.myAccessToken
    }

    this.http.post(environment.VALIDATE_DOC_ACTIVATION_TOKEN,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          console.log('käyttäjä on nyt aktivoitu');

        }
        else {
          this.router.navigate(['/'+this.clinicID+'/login']);
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })
  }

  get password() { return this.form.get('password').value };
  get password2() { return this.form.get('password2').value };

  changePassword(){
    if(this.password == this.password2){

      var elm = document.getElementById('new-pwd-form');
      elm.classList.add("reg-form-success");
      document.getElementById('loader').style.display="block";

      var req = {
        password: this.password,
        token: this.myAccessToken
      }

      this.http.post(environment.ADD_PWD_FOR_DOCTOR, req, this.httpOptions).subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.successMessage = "";
            this.successMessage = res.message;
            console.log('Validi token')
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";
            alert('Salasanan asettaminen onnistui')
            this.router.navigate(['/'+this.clinicID+'/doctor-login'])
          } else {
            this.errMessage = ""
            this.errMessage = res.message;
            
            
          }
          
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }
    else {
      this.errMessage = "Salasanat eivät täsmää, ole hyvä ja kokeile uudestaan."
      console.log('salasanat eivät täsmää')

      var elm = document.getElementById('new-pwd-form');
      elm.classList.remove("reg-form-success");
      document.getElementById('loader').style.display="none";
      this.form.reset();

    }


  }

}
