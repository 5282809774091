
<app-header></app-header>

<div class="header-page-container pb-4 mb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Muokkaa Lemmikkiä {{this.petName}}</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="wrapperi">
<div class="container">
	<div class="wrap">
  
  <div id="loader" class="loader-container" style="display: none;">
    <div class="loader mx-auto"></div>
    <p class="text-center body2b-b mt-4 mb-4">Pieni hetki lemmikin "{{this.petName}}" tietoja päivitetään...</p>
    </div>
    

    <div class="col-12 row p-0 m-0">
      <div class="col-3"></div>
      <div class="col-12 col-md-6 p-0">
        <div class="addpet-form" id="add-pet-form">
          <form class="flex-row add-pet-form" [formGroup]="form"
          (ngSubmit)="onSubmit()">
            <!-- NIMI -->
        <p class="label-text">Nimi</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-md-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" id="staticEmail2" value="Nimi">Kutsumanimi*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input class="" type="name" placeholder="Nimi" formControlName="name" class="form-control" [class.invalid-input]="name.errors && name.touched" required>
              <div class="my-alerts" *ngIf="name.invalid && name.touched">
                <p class="body2b-w mt-2" *ngIf="name.errors.required">Nimi on pakollinen</p>
                <p *ngIf="name.errors.name"> Vaaditaan kunnollinen nimi</p>
              </div>
            </div>
          </div>
        </div>
        <!-- SUKUPUOLI -->
        <p class="label-text">Sukupuoli</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-md-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Sukupuoli*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <select formControlName="sex" id="sex" class="label-text form-control" [class.invalid-input]="sex.errors && sex.touched" required>
              <option></option>
              <option value="Male">Uros</option>
              <option value="Female">Naaras</option>
              </select>
              <div class="my-alerts" *ngIf="sex.invalid && sex.touched">
                <p class="mt-2 body2b-w" *ngIf="sex.errors.required">Sukupuoli on pakollinen</p>
                <p *ngIf="sex.errors.sex"> Vaaditaan määrätty sukupuoli</p>
              </div>
            </div>
          </div>
        </div>
        <!-- SUKUPUOLI -->
        <p class="label-text">Leikattu</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-md-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Leikattu*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <select formControlName="sterilized" class="label-text form-control" [class.invalid-input]="sterilized.errors && sterilized.touched" required>
              <option></option>
              <option value="false">Ei</option>
              <option value="true">Kyllä</option>
              </select>
              <div class="my-alerts" *ngIf="sterilized.invalid && sterilized.touched">
                <p class="mt-2 body2b-w" *ngIf="sterilized.errors.required">Sukupuoli on pakollinen</p>
                <p *ngIf="sterilized.errors.sterilized"> Vaaditaan määrätty sukupuoli</p>
              </div>
            </div>
          </div>
        </div>
        <!-- SYNTYMÄPÄIVÄ -->
        <p class="label-text">Syntymäpäivä</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-md-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Syntymäpäivä*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input type="date" formControlName="birthDate" class="form-control" [class.invalid-input]="birthDate.errors && birthDate.touched" required>
              <div class="my-alerts" *ngIf="birthDate.invalid && birthDate.touched">
                <p class="mt-2 body2b-w" *ngIf="birthDate.errors.required">Syntymäpäivä on pakollinen</p>
                <p *ngIf="birthDate.errors.birthDate"> Vaaditaan määrätty syntymäpäivä</p>
              </div>
            </div>
          </div>
        </div>
        <!-- LAJI -->
        <p class="label-text">Laji</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Laji*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 p-0">
              <select  formControlName="species" (change)="autoFetchRightBreed(species)" id="species" class="form-control" [class.invalid-input]="species.errors && species.touched" required>
                <option value="" disabled selected>Laji</option>
                <option *ngFor="let species of speciesArr">{{species.species}}</option>              </select>
              <div class="my-alerts" *ngIf="species.invalid && species.touched">
                <p class="mt-2 body2b-w" *ngIf="species.errors.required">Laji on pakollinen</p>
                <p *ngIf="species.errors.species"> Vaaditaan määrätty laji</p>
              </div>
            </div>
          </div>
        </div>
        <!-- ROTU -->
        <p class="label-text">Rotu</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-1 col-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0 col" value="sukupuoli">Rotu*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 p-0">
              <input  disabled list="breed-list" placeholder="Rotu" formControlName="breed" id="breed"  class="form-control" [class.invalid-input]="breed.errors && breed.touched" required>
              <p *ngIf="this.breedArr.length > 0" type="text" data-toggle="modal" data-target="#breed-open-modal" class="small-link m-0 pointer text-center" value="sukupuoli"><a >Avaa rotuvalikko</a></p>
  
              <div class="my-alerts" *ngIf="breed.invalid && breed.touched">
                <p class="mt-2 body2b-w" *ngIf="breed.errors.required">Rotu on pakollinen</p>
                <p *ngIf="breed.errors.breed"> Vaaditaan määrätty rotu</p>
              </div>
            </div>
          </div>
        </div>
        <!-- PAINO -->
        <p class="label-text">Paino</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Paino*</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input type="number" placeholder="Paino" formControlName="weight"  class="form-control" [class.invalid-input]="weight.errors && weight.touched" required>
              <div class="my-alerts" *ngIf="weight.invalid && weight.touched">
                <p class="body2b-w mt-2" *ngIf="weight.errors.required">Paino on pakollinen</p>
                <p *ngIf="weight.errors.weight"> Vaaditaan kunnollinen paino</p>
              </div>
            </div>
          </div>
        </div>
        <!-- REKISTERINUMEO -->
        <p class="label-text">Rekisterinumero</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Rekisterinumero</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input type="text" placeholder="Rekisterinumero" formControlName="registerNumber"  class="form-control">
            </div>
          </div>
        </div>
        <!-- MIKROSIRU -->
        <p class="label-text">Mikrosiru</p>
        <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="sukupuoli">Mikrosiru</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input type="text" placeholder="Mikrosiru" formControlName="microCard"  class="form-control">
            </div>
          </div>
        </div>
        <!-- Lisätietoja -->
        <p class="label-text">Lisätietoja</p>
       <div class="form-group">
          <div class="row col-12 m-0 p-0">
<!--             <div class="form-group mb-2 col-6 p-0">
              <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="lisätietoja">Lisätietoja</label>
            </div> -->
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <textarea style="min-height: 70px;" type="text" placeholder="Kirjoita tähän lisätietoja" formControlName="addInfo"  class="form-control"></textarea>
            </div>
          </div>
        </div> 
            <!-- Kuva -->
      <!--      <div class="form-group">
            <div class="row col-12 m-0 p-0">
              <div class="form-group mb-2 col-6 p-0">
                <label type="text" readonly class="label-text form-control-plaintext m-0 p-0" value="lisätietoja">Kuva</label>
              </div>
              <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                <label for="input"><p class="btn btn-secondary" for="input">Lisää kuva</p></label>
                <input style="display: none;" (change)="handleFiles($event)" type="file" accept=".jpg, .jpeg, .png" id="input"/>
                <p class="body2b-b">Kuva:</p>
                <canvas id="canvas" width=64 height=64></canvas>
              </div>
            </div>
          </div> -->
                  
              <div class=" mb-4 mt-2">
                <div class="form-group">
                  <button [disabled]="form.invalid" id="ckDemo" class="btn login-btn btn-veteva-secondary mb-4">Muokkaa lemmikkiä</button>
                </div>
              </div>
            </form>
      
          </div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>

  <button id="breed-modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#breed-open-modal"></button>
<div class="modal fade" id="breed-open-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-breed">
      <div class="modal-header">
        <h5 class="modal-title header3" id="exampleModalLongTitle">Valitse rotu</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">

        <div id="loader" class="loader-container" *ngIf="breedArr.length == 0">
          <div class="loader mx-auto"></div>
          <p class="text-center body2b-b mt-4 mb-4">Pieni hetki haetaan rotutietoja...</p>
        </div>

        <p class="body1">Valitse listalta oikea rotu</p>
        <input type="search" class="form-control mb-2" id="finalArr" [(ngModel)]="searchTextAll" placeholder="Hae rotua...">
        <div class="search-diff-container w-100">
          <table class="table table-striped">
            <tr class="veteva-table-header">
              <td class="body3-w"><p class="body2-w mb-0">Rotu</p></td>
            </tr>
            <tr class="w-100 pointer" id="term-{{data.id}}" (click)="selectBreed(data)" *ngFor="let data of breedArr | searchBreed: searchTextAll ">
              <td><p class="body2b-b mb-0">{{data.breed}}</p></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12 row pb-4">
        <div class="col-6 text-center">
          <button class="btn btn-secondary-gray" style="display: none;" id="close-breed-modal" data-bs-dismiss="modal">Peruuta</button>
        </div>
        <div class="col-6 text-center">
          <button class="btn btn-secondary" style="display: none;" routerLink="/add-pet" data-bs-dismiss="modal">Lisää eläin</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

