import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import {ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {

  user = {
    userName: '',
  };
  errMessage = "";
  successMessage = "";
  myAccessToken:string = this.route.snapshot.params.token;
  clinicID: string = this.route.snapshot.params.clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private route:ActivatedRoute,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID)

     }

  ngOnInit() {
    this.activateUser();
  }

  activateUser() {
    var req = {
      activationToken: this.myAccessToken
    }

    this.http.post(environment.ACTIVATE_USER_AFTER_REGISTER,req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ) {
          var elm = document.getElementById('added');
          elm.style.display="block";

        }
        else {
          var anElm = document.getElementById('notadded');
          anElm.style.display="block";
          console.log('ei ole kyseistä käyttäjää')
        }
        
      }, (err: any) => {
  
        console.log("Error while finding status :: ", err);
      })


  }

}
