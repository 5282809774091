import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-pet-register',
  templateUrl: './pet-register.component.html',
  styleUrls: ['./pet-register.component.css']
})
export class PetRegisterComponent implements OnInit, AfterViewInit {

  currentUserId='';
  pets: any[];
  authToken = localStorage.getItem("auth");
  allPets: any = [];
  clinicID = this.authService.getTokenData().clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

    ngOnInit() {
      this.myId();
    }

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
        this.logOutt();
  
      }
      this.fetchAllPetRegisterData();
    }
  
    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('vastaus',res);
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }

    fetchAllPetRegisterData(){
      this.http.get(environment.DOC_FETCH_ALL_PET_DATA_END_POINT, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          this.pets = res.message;
          this.allPets = res.message;

          //PÄIVÄJÄRJESTYKSEEN 
          this.pets = this.pets.sort(function(a,b){
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });


        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })

    }


    searchPets(){
      var userInput = (<HTMLInputElement>document.getElementById('pet-search')).value
      if(userInput.length >= 3){

        switch (userInput.toLocaleLowerCase()) {
          case 'pieneläin':
            userInput = "Small Animal"
            break;
          case 'naaras':
            userInput = "Female"
            break;
          case 'uros':
            userInput = "Male"
            break;
          case 'koira':
            userInput = "Dog"
            break;
          case 'kissa':
            userInput = "Cat"
            break;
        
          default:userInput
            break;
        }
        this.http.get(environment.SEARCH_PETS+'/'+userInput,this.httpOptions).subscribe(
          (res: any) => {
            if(res.status == 200){
              this.pets = res.message;
            }  
            else{
              alert('Hakuehdoilla ei löytynyt tuloksia');
            }
  
          }, (err: any) => {
        
            console.log("Error while login :: ", err);
          })
      }
      else{
        alert('Vaaditaan vähintään kolme merkkiä');
      }
    }
    showData(){
      var userInput = (<HTMLInputElement>document.getElementById('pet-search')).value
  
      if(userInput.length == 0){
        this.pets = this.allPets;
      }
    }


  

}
