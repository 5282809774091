import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-not-auth-header',
  templateUrl: './not-auth-header.component.html',
  styleUrls: ['./not-auth-header.component.css']
})
export class NotAuthHeaderComponent implements OnInit {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  logo;
  clinicID: string = this.route.snapshot.params.clinicID;


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ){ }

  ngOnInit() {
    this.fetchVisuals();
  }

  fetchVisuals(){
    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          this.logo = res.message.logoUrl;
          this.addVisuals(res.message)

        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

  addVisuals(obj){

  console.log(obj);
    document.getElementById('main-nav').style.backgroundColor = obj.mainColor;
    console.log(document.getElementById('main-nav'))
  }

}
