<app-not-auth-header></app-not-auth-header>

<div class="wrapperi pb-4 mb-4">
    <div class="container pb-4">
        <div class="wrap pb-4">
          <div class="container">
        <div *ngIf="sendVideoScreen" id="check-bought" class="d-flex flex-row justify-content-center">
            <div class="col-12  flex-wrap mt-4">
          <div class="col-12 row p-0 m-0">
            <div class="col-3"></div>
            <div class="col-12 col-md-6 pl-2 pl-md-3">
              <h4 class="header3 mt-4">Lataa videosi tästä</h4>
              <p class="body2b mt-4 mb-0">Tervetuloa Vetsyyn. Tällä linkillä voit ladata yhden videon.</p>
              <div *ngIf="!loading" class="">
                <div *ngIf="tokenIsValid; else other_content" class="">
                  <div class="" *ngIf="tokenType == 'video'">
                    <p class="body2b">Lataa videosi painamalla alla olevaa painiketta. Voit syöttää ainoastaan videotiedostoja.</p>
                    <label class="btn btn-veteva-secondary" for="uploadBtn" id="uploadLabel" style="display: block" >Lataa video</label>
                    <input style="display:none" (change)="uploadFolderImage($event)" accept="video/mp4,video/x-m4v,video/*" type="file" id="uploadBtn"/>
                  </div>
                  <div class="" *ngIf="tokenType == 'image'">
                    <p class="body2b">Lataa kuvasi painamalla alla olevaa painiketta. Voit syöttää ainoastaan kuvatiedostoja.</p>
                    <label class="btn btn-veteva-secondary" for="uploadBtn" id="uploadLabel" style="display: block" >Lataa kuva</label>
                    <input style="display:none" (change)="uploadFolderImage($event)" accept="image/*" type="file" id="uploadBtn"/>
                    
                  </div>                
                </div>

                <ng-template #other_content>
                    <div class="alert alert-warning mt-4">
                        <p class="body2b">Linkki on vanhentunut. Ole hyvä ja ota yhteyttä eläinlääkäriisi.</p>
                    </div>
                </ng-template>
              </div>
              <div class="" *ngIf="loading">
                <div id="loader" class="loader-container">
                    <div class="loader mx-auto"></div>
                  </div>
              </div>
            </div>
        <div class="col-3"></div>
        </div>
    </div>
      </div>
      <div *ngIf="sendVideoSuccess" id="check-bough1t" class="text-center">
        <div class="col-12 text-center mt-4">
            <i class="fas fa-check-circle success-icon"></i>
    
        </div>
        <div class="col-12 p-4">
            <p class="body2b text-center">Videon lähettäminen onnistui. Voit sulkea tämän välilehden ja palata etäklinikalle.</p>
        </div>
    </div>
    <div *ngIf="sendVideoFailed" id="check-bough2t" class="text-center">
        <div class="col-12 text-center mt-4">
            <i class="fas fa-times-circle failed-icon"></i>
    
        </div>
        <div class="col-12 p-4">
            <p class="body2b text-center">Liitteen lähettäminen epäonnistui. Ole hyvä ja yritä uudelleen päittämällä sivu.</p>
            <button (click)="refreshPage()" class="btn-veteva-secondary">Päivitä sivu</button>
        </div>
</div>


 
          </div>
          </div>
          </div>