<app-admin-not-auth-header></app-admin-not-auth-header>
<div class="wrapperi">
  <div class="container">
	<div class="wrap">

    <div class="col-12 m-0 row">
      <div class="col-3"></div>
      <div class="col-12 col-md-6 p-0 mt-4">
        <div class="col-12 text-center p-0">
          <h4 class="header-text mt-4">Tervetuloa etäeläinlääkäriin!</h4>
          <p class="desc-text">Koodi on voimassa <span class="desc-text-bold">15min</span></p>
        </div>
      </div>
      <div class="col-3"></div>

    </div>


  <div class="col-12 row mt-4">
    <div class="col-3"></div>
    <div class="col-12 col-md-6">
      <div class="col-md-12 mx-auto row">
        <input  type="number" min="0" inputmode="decimal" (keydown.enter)="setStatus()" pattern="[0-9]*" id="sms" class="form-control">
      </div>

    </div>
    <div class="col-3"></div>
  </div>
  <div class="col-12 row mt-4">
    <div class="col-3"></div>
    <div class="col-6 text-center pb-4">
      <button class="btn btn-veteva-secondary btn-veteva-admin" (click)="setStatus()">Varmenna</button>
    </div>
    <div class="col-3"></div>
  </div>
  
 


    </div>
    </div>

    </div>
