
<!--Navbar-->
<nav id="main-nav" class="navbar navbar-expand-lg navbar-dark pb-0 height">
  <div class="container-fluid">
  <!-- Navbar brand -->
  <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" routerLink="/doctorhome"><img width="180" src="{{logo}}"> </a>

  <!-- Collapse button -->
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line3"></span>
  </button>

  <!-- Collapsible content -->
  <div class="collapse navbar-collapse" id="basicExampleNav">

    <!-- Links -->
    <ul class="navbar-nav ms-auto">
      <li class="nav-item single-item p-2">
        <a class="nav-link" routerLink="/{{clinicID}}/doctorhome" id="doctorhome">CHAT</a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" routerLink="/{{clinicID}}/vet/doctor-detail/{{currentUserId}}" id="profile">PROFIILI</a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" routerLink="/{{clinicID}}/vet/hashtag-settings/{{currentUserId}}" id="hashtag">HASHTAGIT</a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" routerLink="/{{clinicID}}/doctor-chat-history" id="doctor-chat-history">HISTORIA</a>
      </li>
      <li  class="nav-item single-item p-2" *ngIf="isAdmin == true">
        <a class="nav-link" routerLink="/{{clinicID}}/clinic-settings" id="clinic-settings">ASETUKSET</a>
      </li>


      <!-- Dropdown -->
       <ul class="nav-item single-item p-2 dropdown">
        <a id="rekisteri-page" class="nav-link dropdown-toggle" id="ddbtne" data-bs-toggle="dropdown"
          aria-expanded="false">REKISTERI</a>
        <ul class="dropdown-menu" aria-labelledby="ddbtn">
          
          <li><a class="dropdown-item" routerLink="/{{clinicID}}/users-register">ASIAKKAAT</a></li>
          <li><a class="dropdown-item" routerLink="/{{clinicID}}/visits-register">KÄYNNIT</a></li>
          <li><a class="dropdown-item" routerLink="/{{clinicID}}/pets-register">LEMMIKIT</a></li>
          <li><a class="dropdown-item" routerLink="/{{clinicID}}//doctors-register">ELÄINLÄÄKÄRIT</a></li>
          <li><a class="dropdown-item" routerLink="/{{clinicID}}//products-register">TUOTTEET</a></li>
          <li><a *ngIf="isAdmin" class="dropdown-item" routerLink="/{{clinicID}}/queue-time-register">JONOAJAT</a></li>
          <li><a *ngIf="isAdmin" class="dropdown-item" routerLink="/{{clinicID}}/payments-register">MAKSUT</a></li>
          <li><a *ngIf="isAdmin" class="dropdown-item" routerLink="/{{clinicID}}/feedback-register">PALAUTTEET</a></li>
        </ul>
      </ul>
<!--       <li  class="nav-item single-item p-2" *ngIf="isAdmin == true">
        <a class="nav-link" routerLink="/clinic/statistics/today" id="statistics">STATISTIIKKA</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link lopeta-link mt-2" href="#" (click)="logOutt()">LOPETA</a>
      </li>

    </ul>
    <!-- Links -->

  </div>
  <!-- Collapsible content -->
</div>
</nav>


