import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FileUploaderService } from '../service/file-uploader.service';

@Component({
  selector: 'app-add-clinic',
  templateUrl: './add-clinic.component.html',
  styleUrls: ['./add-clinic.component.css']
})
export class AddClinicComponent implements OnInit {

  form: FormGroup;
  messageErr: string;
  messageSuccess: string;
  passwordErr:string="";
  chaptaQuestion = "";
  chaptaImage = "";
  chaptaID ="";
  shortPwd
  authToken = localStorage.getItem('auth');
  emailTrue = false;
  phoneTrue = false;
  lastNameTrue = false;
  firstNameTrue = false;
  passwordTrue = false;
  password2True = false;
  termsTrue = false;
  attachmentObj;
  token = this.authService.getTokenData();



  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  httpAttachmentOptions = {
    withCredentials: true,
    headers: new HttpHeaders({ 'responseType': 'blob' }),
  };

  constructor( 
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private fileService: FileUploaderService,
    private authService: AuthService) { }



  ngOnInit(): void {
    console.log(this.token);
    this.form = this.fb.group({
      clinicName: ['',
      Validators.required],
      clinicID: ['',
      Validators.required],
      email: ['', [
        Validators.email, 
        Validators.required,
        Validators.maxLength(100)
      ]],
      designation: ['', [
        Validators.required,
      ]],
      phone: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],

      firstName: ['',
      Validators.required],
      lastName: ['', 
      Validators.required],
      ovt: ['', 
      Validators.required],
      operator: ['', 
      Validators.required],
      mainColor: ['', 
      Validators.required],
      secondaryColor: ['', 
      Validators.required],
      docEmail: ['', 
      Validators.required],
      docPhone: ['', 
      Validators.required],

    });
  }

  get email() { return this.form.get('email') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get phone() { return this.form.get('phone') };
  get clinicName() { return this.form.get('clinicName') };
  get clinicID() { return this.form.get('clinicID') };
  get ovt() { return this.form.get('ovt') };
  get operator() { return this.form.get('operator') };
  get designation() { return this.form.get('designation') };
  get mainColor() { return this.form.get('mainColor') };
  get secondaryColor() { return this.form.get('secondaryColor') };
  get docEmail() { return this.form.get('docEmail') };
  get docPhone() { return this.form.get('docPhone') };

  onSubmit(file) {
    
        var email = this.form.get('email').value;
        var firstName = this.form.get('firstName').value;
        var lastName = this.form.get('lastName').value;
        var phone = this.form.get('phone').value;

    
        var safeArr = [];
        safeArr.push(email, firstName, lastName, phone);
    
        for (let i = 0; i < safeArr.length; i++) {
          const str = safeArr[i];
          if(str.includes(' ') || str.includes('<')){
            console.log('form is not valid')
            console.log(str, 'sisälsi')
            return;
          }
        }

        if (!this.form.valid) {
          return;
        }

        var formAc = document.getElementById('register-form');
        formAc.classList.add("reg-form-success");
        document.getElementById('loader').style.display="block";
    
        this.passwordErr="";
        this.messageErr="";
    
        const request = {
          email: this.form.get('email').value,
          clinicName: this.form.get('clinicName').value,
          clinicID: this.form.get('clinicID').value,
          designation: this.form.get('designation').value,
          ovt: this.form.get('ovt').value,
          operator: this.form.get('operator').value,
          firstName: this.form.get('firstName').value,
          lastName: this.form.get('lastName').value,
          phone: this.form.get('phone').value,
          mainColor: this.form.get('mainColor').value,
          secondaryColor: this.form.get('secondaryColor').value,
          docEmail: this.form.get('docEmail').value,
          docPhone: this.form.get('docPhone').value,
          files:{
            logo:file
          },
          logoUrl: file.location,
          
        }

        console.log(request);

    
           this.http.post(environment.CLINIC, request, this.httpOptions).subscribe(
            (res: any) => {
              if (res.status == 200) {
                this.messageSuccess = res.messgage;
                document.getElementById('loader').style.display="none";
                document.getElementById('register-form').style.display="block";
    
                var actAlert = document.getElementById('activation-alert');
                actAlert.style.display = "block";
                alert('Klinikan rekisteriöinti onnistui');
                this.router.navigate(['/admin/clinics']);
                //window.location.replace("/home");
              } else {
                this.messageErr = res.message;
                document.getElementById('loader').style.display="none";
                formAc.classList.remove("reg-form-success");
    
                //loginError = true;
              }
            }, (err: any) => {
    
              console.log("Error while login :: ", err);
            }
          )
    
        }

        gotFile(event: Event): void {
          const attachment = (event.target as HTMLInputElement).files[0];
          this.attachmentObj = attachment;

          console.log(attachment);
         }

         submitRouter(){

             this.fileService.uploadFullyPublicImage(this.attachmentObj, this.token.userID, true,(err, result) => {

              this.onSubmit(result)
             }) 
           
         }





        checkMobileNumeber() {
          var phone = this.form.get('phone').value;
          var phoneErr;
          if(phone[0] != "+"){
            phoneErr = true;
          }
    
          else if(phone.length < 5) {
            phoneErr = true;
          }
    
          else if(phone.length > 17) {
            phoneErr = true;
          }
          else if(phone.substring(0,5) == "+3580"){
              phoneErr = true;
          }
          else {
            phoneErr = false;
          }

          if(phoneErr){
            alert('Puhelinnumerossa vaaditaan +358 alku');
            return;
          }

          this.submitRouter();
        }


}
