import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  myAccessToken:string = this.route.snapshot.params.id;
  form: FormGroup;
  errMessage = "";
  successMessage = "";
  clinicID: string = this.route.snapshot.params.clinicID;
  


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private colorService: ColorServiceService,
    private authService: AuthService) { 
    }
    ngOnInit() {

      this.setColors();
      this.validate();
      
      //this.validate();
      this.form = this.fb.group({
        password: ['', [
          Validators.required,
          Validators.minLength(8)
        ]],
        password2: ['', [
          Validators.required,
          Validators.minLength(8)
        ]]
      });
    }

    setColors(){
      var ls = localStorage.getItem('colors');
      console.log(ls);
      if(ls){
        this.colorService.styleUser();
      }
      else{
      var req = {
        clinicID: this.clinicID,
      }
      console.log(this.clinicID);
      this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
        (res: any) => {
          
          if(res.status == 200){
            var str = JSON.stringify(res.message);
  
            localStorage.setItem('colors',str);
  
            this.colorService.styleUser();
  
  
          }
    
        }, (err: any) => {
          //this.logOut();
          console.log("Error validating user :: ", err);
        })
      }
    }
    get password() { return this.form.get('password').value };
    get password2() { return this.form.get('password2').value };

    changePassword(){
      if(this.password == this.password2){
  
        var elm = document.getElementById('new-pwd-form');
        elm.classList.add("reg-form-success");
        document.getElementById('loader').style.display="block";
  
        var userData = {
          password: this.password,
          password2: this.password2,
          accessToken: this.myAccessToken,
          clinicID: this.clinicID
        }
  

        this.http.post(environment.CHANGE_ON_RESET_PASSWORD_END_POINT, userData, this.httpOptions).subscribe(
          (res: any) => {
            console.log("Login success");
            if (res.status == 200) {
              this.successMessage = "";
              this.successMessage = 'Salasana vaihdettu onnistuneesti, voit ny kirjautua sisään uudella salasanalla';
              console.log('Validi token')
              elm.classList.remove("reg-form-success");
              document.getElementById('loader').style.display="none";
              this.form.get('password').setValue('');
              this.form.get('password2').setValue('');
              //this.router.navigate(['/login'])
            } else if(res.status == 203) {
              this.errMessage = ""
              this.successMessage = 'Salasana vaihdettu onnistuneesti, voit ny kirjautua sisään uudella salasanalla';
              console.log('Tuli läpi ja epäonnistu')
              
            }
            else if(res.status == 202){
              this.errMessage = "Aktivointilinkki on vanhentunut. Ole hyvä ja pyydä linkki uudelleen. Aktivointilinkki on aina voimassa 15 min"
            }
            else if(res.status == 201){
              this.errMessage = "Yhtään käyttäjää ei löytynyt."
            }
            
          }, (err: any) => {
  
            console.log("Error while login :: ", err);
          }
        )
  
      }
      else {
        this.errMessage = "Salasanat eivät täsmää, ole hyvä ja kokeile uudestaan."
        console.log('salasanat eivät täsmää')
  
      }
  
  
    }
  
    validate() {
  
      console.log(this.myAccessToken)
  
        var userData = {
          accessToken: this.myAccessToken,
          clinicID: this.clinicID,
        }
         
        this.http.post(environment.RESET_PASSWORD_END_POINT, userData, this.httpOptions).subscribe(
          (res: any) => {
            console.log("Login success");
            if (res.status == "success") {
              console.log('Validi token')
            } else {
              console.log('Tuli läpi')
              //this.router.navigate(['/login'])
            }
            
          }, (err: any) => {
  
            console.log("Error while login :: ", err);
          }
        )
  
      
    }

}
