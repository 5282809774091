import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-visit-register',
  templateUrl: './visit-register.component.html',
  styleUrls: ['./visit-register.component.css']
})
export class VisitRegisterComponent implements OnInit, AfterViewInit {

  currentUserId='';
  visits: any = [];
  pets: any[];
  authToken = localStorage.getItem("auth");
  allVisits: any = [];
  clinicID = this.authService.getTokenData().clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }


  ngOnInit() {
    this.myId();
  }
  ngAfterViewInit(){
    this.colorService.setVetColors();
  }

  myId(){

    var token = localStorage.getItem('auth');

    if(token == null){
      this.authService.doLogOut()
    }
    var decodedToken = helper.decodeToken(token);

    this.currentUserId = decodedToken.result.id;


    if(decodedToken.result.designation == 'patient'){
      this.authService.doLogOut();

    }
    else{
      this.fetchRegisterData();

    }
  }

  fetchRegisterData(){

    this.http.get(environment.FETCH_ALL_VISITS_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
          this.visits = res.message;
          this.allVisits = res.message;
          console.log(this.visits);

          //PÄIVÄJÄRJESTYKSEEN 
          this.visits = this.visits.sort(function(a,b){
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });
          this.colorService.waitStyleColor();
        }
        else {
          this.visits = [];
          console.log('Väärä määrä');
        }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

  
  searchVisit(){
    var userInput = (<HTMLInputElement>document.getElementById('visit-search')).value
    if(userInput.length >= 3){
      switch (userInput.toLocaleLowerCase()) {
        case 'pieneläin':
          userInput = "Small Animal"
          break;
        case 'naaras':
          userInput = "Female"
          break;
        case 'uros':
          userInput = "Male"
          break;
        case 'koira':
          userInput = "Dog"
          break;
        case 'kissa':
          userInput = "Cat"
          break;
      
        default:userInput
          break;
      }
      this.http.get(environment.SEARCH_VISITS+'/'+userInput,this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            this.visits = res.message;
            this.colorService.waitStyleColor();
          }  
          else{
            alert('Hakuehdoilla ei löytynyt tuloksia');
          }

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    }
    else{
      alert('Vaaditaan vähintään kolme merkkiä');
    }
  }
  showData(){
    var userInput = (<HTMLInputElement>document.getElementById('visit-search')).value

    if(userInput.length == 0){
      this.visits = this.allVisits;
    }
  }


}
