import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-feedback-register',
  templateUrl: './feedback-register.component.html',
  styleUrls: ['./feedback-register.component.css']
})
export class FeedbackRegisterComponent implements OnInit {

  currentUserId='';
  patientID: string = this.route.snapshot.params.id;
  feedbacks: any[];
  currentDetails: any = {};
  authToken = localStorage.getItem("auth");
  averageNPS;
  NPSScore;
  numFeedbacks;
  numSending;
  totalSum;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

    ngOnInit() {
      this.myId();
    }

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      console.log('iidee',decodedToken.result.id);
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      console.log('totta',decodedToken.result.designation)
      if(decodedToken.result.designation == 'patient'){
        console.log('totta')
        this.authService.doLogOut();
        this.logOutt();
  
      }
      this.fetchFeedbacks()
    }

    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          console.log('vastaus',res);
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }

    fetchFeedbacks(){

      this.http.get(environment.FETCH_FEEDBACKS_REGISTER, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){

            this.feedbacks = res.message.feedbacks;
            
            this.averageNPS = res.message.averageNPS;
            this.NPSScore = res.message.NPSScore;
            this.totalSum = res.message.sum;

          }
          else {
            this.feedbacks = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })

    }


}
