import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from "@auth0/angular-jwt";
import {ActivatedRoute, Router } from '@angular/router';
import { ColorServiceService } from '../service/color-service.service';
import { FileUploaderService } from '../service/file-uploader.service';


@Component({
  selector: 'app-public-video',
  templateUrl: './public-video.component.html',
  styleUrls: ['./public-video.component.css']
})
export class PublicVideoComponent implements OnInit {

  clinicID: string = this.route.snapshot.params.clinicID;

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private zone: NgZone,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fileUploaderService: FileUploaderService,
    private colorService: ColorServiceService) { 
      this.authService.validateClinic(this.clinicID)
      this.validateToken();
    }

    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
    };
    attachmentObj: any;
    loading: boolean = false;
    sendVideoScreen: boolean = true;
    sendVideoFailed: boolean = false;
    sendVideoSuccess: boolean = false;
    tokenIsValid: boolean = false;
    tokenType: string = 'video';

  ngOnInit(): void {
    this.fetchVisuals();
  }

  validateToken(){
    //Get token from query
    var token = this.route.snapshot.queryParams.token;
    if(token){
      //Validate token
      this.http.get(environment.VALIDATE_VIDEO_TOKEN + '/'+token, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            //Set token in local storage
            this.tokenIsValid = true;
            this.tokenType = res.message.type;
            setTimeout(() => {
              console.log(document.getElementById('uploadLabel'))
              document.getElementById('uploadLabel').style.display = 'block';
              this.colorService.styleUser();
            },400)

          }
          else{
            
          }
        }
      )
    }
  }

  fetchVisuals(){
    
    var req = {
      clinicID: this.clinicID,
    }

    this.http.post(environment.CLINIC_VISUALS , req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);
          this.colorService.styleUser();
          


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
  }

  uploadFolderImage(event){
    this.loading = true;
    const attachment = (event.target as HTMLInputElement).files[0];
    this.attachmentObj = attachment;
    if(this.attachmentObj.size > 100000000){
      alert('Tiedoston maksimi koko on 100mb');
      this.loading = false;
      return;
    }

    console.log(this.attachmentObj);
    var token = this.route.snapshot.queryParams.token;

    this.fileUploaderService.uploadItemToFolder(this.attachmentObj,token,(err,results) => {
      console.log(results);
      this.loading = false;
      if(results != false){
        this.sendVideoScreen = false;
        this.sendVideoSuccess = true;
        this.sendVideoFailed = false;
      }
      else{
        this.sendVideoScreen = false;
        this.sendVideoSuccess = false;
        this.sendVideoFailed = true;
      }
    })
  }

  refreshPage(){
    window.location.reload();
  }


}
