import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  waitAlert5000(message, callback) {
    var err;
    setTimeout(() =>{
      message = false;
      callback(err,message);
    },5000)

  }
}

