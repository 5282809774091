
  <div (window:resize)="onResize($event)" class="chat-window-container h-100">
  <app-docheader></app-docheader>
  <div class="row m-0 three-sec-wrap row-container" id="chat-wrap">
    <script src="//sdk.twilio.com/js/video/releases/2.8.0/twilio-video.min.js"></script>
    <!--Section one :: started-->
    <div class="col-md-8 col-lg-3 section-one color-white korkeus p-0 section sidebar-sec-one sidenav" id="mySidenav">
      
      <div class="tab col-12 row m-0">
        <i class="fa fa-times fa-icon burger burger-left closeNav col-1" (click)="closeNav('mySidenav')" aria-hidden="true"></i>
        <!-- JONO -->

          <button class="tablinks active col-5 col-md-5 " id="queue-btn"  (click)="openCity($event, 'queue')"><i class="fas fa-user-clock pe-2"></i>JONOSSA</button><span *ngIf="queueList.length > 0" class="notification-tab">{{this.queueList.length}}</span>

        <!-- AKTIIVISET -->
          <button class="tablinks col-5" id="active-btn" (click)="openCity($event,'active')"><i class="fas fa-user-check pe-2"></i>AKTIIVISET</button><span *ngIf="this.activePatientArr > 0" class="notification-tab">{{this.activePatientArr}}</span>

        <!-- SULJETUT -->
          <!-- <button class="tablinks col-md-4"  (click)="openCity($event,'closed')">SULJETUT</button><span *ngIf="this.closedPatientArrLength > 0" class="notification-tab">{{this.closedPatientArrLength}}</span> -->
      </div>

      <!-- JONO -->
      <div style="display: block;" id="queue" class="tabcontent">
        <div class="text-center">
          <button class="btn btn-veteva-secondary" (click)="GetAllInQueue()"><i class="fas fa-sync-alt pe-2"></i>Päivitä jono</button>
        </div>
        <div class="mt-4 row m-0 col-12 d-flex ps-0 pe-0 ps-md-2 justify-content-center">
          <div class="col-12 col-lg-12 col-xl-11 p-0">
          <ul *ngIf="queueList.length != 0;else no_people_content" class="list-group" id="menu">
            <div *ngFor="let quer of queueList" class="full-wrap mb-2" id="queue-wrap-{{quer._id}}">
                <li class="list-group-item list-wrap-item pe-0 list-group-item-link m-0">
                  <div class="list-wrap-closed col-12 row pe-0">
                    <div class="img-cont" *ngIf="quer.image != null;else standard_image">
                      <img class="small-pic-pet" src="{{quer.image}}" alt="lemmikkisi">
                    </div>
                    <ng-template #standard_image>
                      <img class=" p-0 small-pic-pet col-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                    </ng-template>
                    <div class="col-9 pe-0  d-flex flex-column">
                      <div class="p-0">
                        <p  class="mb-0 body2b-b"> {{ quer.firstName }} {{quer.lastName}}</p>
                      </div>
                      <div class=" p-0">
                        <p id="quer-front-timer-{{quer._id}}" class="mb-0 body214"></p>
                      </div>
                    </div>
                    <div class="col-1 p-0 d-flex justify-content-end ">
                      <i class="fas fa-angle-down eteva-icon-main fa-2x float-right expand-icon ms-2 mt-2 me-lg-3 me-xl-0"  id="expand-icon-{{quer.petsID}}" (click)="openExpandBox($event)"></i>
                      <i class="fa fa-angle-up fa-2x eteva-icon-main float-right close-icon ms-2 mt-2 me-lg-3 me-xl-0 poista" style="display: none;" id="close-icon-{{quer.petsID}}" (click)="closeExpandBox($event)"></i>  
                    </div>
                    <div class="col-12 text-center justify-content-center row m-0">
                      <button class="mt-4 btn btn-veteva-secondary text-center justify-content-center" (click)="takePatientToConsultation($event)" id="{{quer._id}}">Aloita</button>

                    </div>
                  </div>
                
              </li>
              <ul class="list-group list-group-flush list" id="{{quer.petsID}}" >
                <li  class="list-group-item list-group-item-link list-group-show">
                  <div class=" d-inline-flex">
                    <i class="far fa-2x fas fas fa-user-clock veteva-icon-main" aria-hidden="true"></i>
                    <p class="ms-2 mt-1" id="quer-timer-{{quer._id}}"></p>
                  </div>
                 </li>
                <li class="list-group-item list-group-item-link list-group-show">
                  <div class="col-12 p-0 row">
                    <div class="col-12 d-inline-flex">
                      <i class="fa fa-2x fa-info-circle veteva-icon-main"></i>
                      <p class="ms-2 mt-1">Tiedot</p>
                      <div class="ms-auto wrapper">
                        <i id="info-screen-visits-{{quer._id}}" (click)="[openInfoScreenQuerData($event,quer), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="list-group-item list-group-item-link list-group-show">
                  <div class="col-12 p-0 row">
                    <div class="col-12 d-inline-flex">
                      <i class="fa fa-2x fa-calendar veteva-icon-main" aria-hidden="true"></i>
                      <p  class="ms-2 mt-1">Käynnit</p>
                      <div class="ms-auto wrapper">
                        <i id="info-screen-visits-{{quer.petsID}}" (click)="[openInfoScreenVisits($event,quer), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                      </div>
                    </div>
                  </div>
                 </li>
                 <li class="list-group-item list-group-item-link list-group-show">
                  <div class="col-12 p-0 row">
                    <div class="col-12 d-inline-flex">
                      <i class="far fa-2x fa-sticky-note veteva-icon-main" aria-hidden="true"></i>
                      <p class="ms-2 mt-1">Esitiedot</p>
                      <div class="ms-auto wrapper">
                        <i  id="info-screen-anamnesis-{{quer._id}}" (click)="[openInfoScreenAnamnesis($event,quer), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                      </div>
                    </div>
                  </div>
                 </li>
                 <li *ngIf="quer.wantedDoctor" class="list-group-item list-group-item-link list-group-show">
                  <div class="col-12 p-0 row">
                    <div class="col-12 d-inline-flex">
                    <i class="far fa-2x fas fa-user-md veteva-icon-main" aria-hidden="true"></i>
                    <p class="ms-2 mt-1">{{quer.wantedDoctor}}</p>
                    </div>
                  </div>
                 </li>
                 <li class="list-group-item list-group-item-link list-group-show">
                  <div class="">
                        <button class="btn btn-veteva-secondary" id="{{quer._id}}" (click)="takePatientToConsultation($event)">Aloita chat</button>
                      </div>
                 </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
      <ng-template #no_people_content>
        <div class="alert alert-warning col-12">
          <p class="text-center">Jonossa ei ole asiakkaita.</p>
        </div>
      </ng-template>
      </div>
      <!-- AKTIIVISET POTILAAT -->
      <div id="active" class="tabcontent pe-0 ps-0">
        <div *ngIf="this.activePatientArr != 0;else no_active_content">
          <div class="mt-4 row m-0 col-12 d-flex ps-0 pe-0 ps-2 justify-content-center">
            <div class="col-12 col-lg-12 col-xl-11 ps-0 pe-0 ps-md-2 pe-md-2">
            <div *ngFor="let doctor of doctorList" id="{{doctor.name}}" class="full-wrap mb-2">
              <div *ngIf="doctor.status == this.currentUserId">
              <li class="list-group-item list-wrap-item pe-0 list-group-item-link m-0">
                <div class="list-wrap-closed col-12 row pe-0 m-0">
                  <div class="img-cont" *ngIf="doctor.image != null;else standard_image">
                    <img class="small-pic-pet" src="{{doctor.image}}" alt="lemmikkisi">
                  </div>
                  <ng-template #standard_image>
                    <div class="img-cont col-1 p-0">
                      <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi"><p class="notification reg-form-success" id="notification-{{doctor.id}}">1</p>
                    </div>
                  </ng-template>
                  <div class="col-9 pe-0  d-flex flex-column">
                    <div class="p-0">
                      <p  class="mb-0 body2b-b"> {{ doctor.fullName}}</p>
                    </div>
                    <div class=" p-0">
                      <p class="mb-0 body214">"{{doctor.activePetName}}" {{doctor.activePetSpecies }} </p>
                    </div>
                    <div class=" p-0">
                      <p class="mb-0 body214">{{doctor.activePetBreed}} </p>
                    </div>
                  </div>
                <div class="col-1 p-0 d-flex justify-content-end ">
                  <i class="fas fa-angle-down eteva-icon-main expand-icon mt-1 me-lg-3 me-xl-0"  id="expand-icon-{{doctor._id}}" (click)="openExpandBox($event)"></i>
                  <i class="fas fa-angle-up eteva-icon-main close-icon me-lg-3 mt-1 me-xl-0 poista" style="display: none;" id="close-icon-{{doctor._id}}" (click)="closeExpandBox($event)"></i>
                </div>
              <div class="col-12 text-center m-0">
                <button class="mt-4 btn btn-veteva-secondary" (click)="[OnSelectDoctor(doctor), closeNav('mySidenav'), clearNotification(doctor._id),waitScroll()]"><i class="far fa-comments pe-2"></i>Chat</button>
              </div>
            </div>
          </li>
              
            <ul class="list-group list-group-flush list" id="{{doctor._id}}" >
              <li  class="list-group-item list-group-item-link list-group-show">
                <div class=" d-inline-flex">
                  <i class="far fa-2x fas fas fa-user-clock veteva-icon-main" aria-hidden="true"></i>
                  <p class="ms-2 mt-1" id="consultation-timer-{{doctor._id}}"></p>
                </div>
               </li>
              <li class="list-group-item list-group-item-link list-group-show">
               <div class="col-12 p-0 row">
                 <div class="col-12 d-inline-flex">
                    <i class="fa fa-2x fa-info-circle veteva-icon-main"></i>
                    <p  class="ms-2 mt-1">Tiedot</p>
                    <div class="ms-auto wrapper">
                      <i id="info-screen-{{doctor._id}}" (click)="[openInfoScreen($event), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                    </div>
                  </div>
              </div>
              </li>
              <li class="list-group-item list-group-item-link list-group-show">
                <div class="col-12 p-0 row">
                  <div class="col-12 d-inline-flex">
                    <i class="fa fa-2x fa-calendar veteva-icon-main" aria-hidden="true"></i>
                    <p  class="ms-2 mt-1">Käynnit</p>
                    <div class="ms-auto wrapper">
                      <i id="info-screen-visits-{{doctor.activePetID}}" (click)="[openInfoScreenVisits($event), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                    </div>
                  </div>
                </div>
               </li>
               <li class="list-group-item list-group-item-link list-group-show">
                <div class="col-12 p-0 row">
                  <div class="col-12 d-inline-flex">
                    <i class="far fa-2x fa-sticky-note veteva-icon-main" aria-hidden="true"></i>
                    <p  class="ms-2 mt-1">Esitiedot</p>
                    <div class="ms-auto wrapper">
                      <i id="info-screen-anamnesis-{{doctor._id}}" (click)="[openInfoScreenAnamnesis($event), closeNav('mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                    </div>
                  </div>
                </div>
               </li>

            </ul>
          </div>
        </div>
          </div>
          </div>
        </div>
          <ng-template #no_active_content>
            <div class="col-12 row me-0 mt-4">
            <div class="col-1  p-0"></div>
            <div class="col-10 me-0 p-0">
              <div class="alert alert-warning">
                <p class="text-center">Aktiivisia konsultaatioita ei ole.</p>
              </div>
           </div>
           <div class="col-1 p-0"></div>
          </div>
          </ng-template>
        </div>

        <!-- SULJETUT -->
<!--         <div id="closed" class="tabcontent">
          <div *ngIf="this.closedPatientArrLength != 0;else no_closed_content">
            <div class="content-row profile-container">
              <div class="profile-lists">
                <div *ngFor="let doctor of doctorList" id="{{doctor.name}}" class="full-wrap mb-2">
                  <div *ngIf="doctor.status !== this.currentUserId">
                  <li class="list-group-item list-group-item-link col-5 pt-xl-3 pe-lg-0 row d-inline-flex col-md-8 pe-lg-0">
                    <div class="list-wrap-closed d-inline-flex">
                      <div class="img-cont" *ngIf="doctor.image != null;else standard_image">
                        <img class="small-pic-pet" src="{{doctor.image}}" alt="lemmikkisi">
                      </div>
                      <ng-template #standard_image>
                        <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                      </ng-template>
                      <div class="d-flex flex-column">
                        <div class="p-0">
                          <p  class="ms-3 mb-0 petname-text"> {{ doctor.fullName}}</p>
                        </div>
                        <div class=" p-0">
                          <p class="ms-3 mb-0 body3 sideBar-message">{{doctor.shortMessage.substr(0,10)}}</p>
                        </div>
                      </div>
                      <i class="fa fa-plus float-right expand-icon ms-2 mt-2 me-lg-3 me-xl-0"  id="expand-icon-{{doctor._id}}" (click)="openExpandBox($event)"></i>
                      <i class="fa fa-minus float-right close-icon ms-2 mt-2 me-lg-3 me-xl-0 poista" style="display: none;" id="close-icon-{{doctor._id}}" (click)="closeExpandBox($event)"></i>
                    </div>
                  
                </li>
                <div class="float-right pull-right col-4 mt-3 p-lg-0">
                  <button class="btn pick-product-btn me-lg-4" (click)="OnSelectDoctor(doctor)">Chat</button>
               </div>
                <ul class="list-group list-group-flush list" id="{{doctor._id}}" >
                  <li class="list-group-item list-group-item-link list-group-show">
                   <div class="expanded-wrap d-inline-flex">
                    <i class="fa fa-2x fa-info-circle light-gray-icon"></i>
                     <p  class="ms-2 mt-1">Tiedot</p>
                   </div>
                   <div class="pull-right wrapper">
                    <i id="info-screen-visits-{{doctor._id}}" (click)="[openInfoScreenActiveUserData($event), closeNav($event, 'mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                  </div>
                  </li>
                  <li class="list-group-item list-group-item-link list-group-show">
                    <div class="expanded-wrap d-inline-flex">
                      <i class="fa fa-2x fa-calendar light-gray-icon" aria-hidden="true"></i>
                      <p  class="ms-2 mt-1">Käynnit</p>
                    </div>
                    <div class="pull-right wrapper">
                      <i id="info-screen-visits-{{doctor._id}}" (click)="[openInfoScreenVisitsForUser($event), closeNav($event, 'mySidenav')]" class= "fa fa-angle-right fa-2x veteva-icon-main"></i>
                    </div>
                   </li>
                   <li class="list-group-item list-group-item-link list-group-show">
                    <div class="expanded-wrap">
                    
                        </div>
                   </li>
                </ul>
              </div>
            </div>
              </div>
              </div>
            </div>
              <ng-template #no_closed_content>
                <div class="d-inline-flex col-12 mt-4">
                <div class="col-1 d-flex p-0"></div>
                <div class="col-10 me-0 p-0 d-flex justify-content-center w-100">
                  <div class="alert alert-warning">
                    <p>Ei suljettuja.</p>
                  </div>
               </div>
               <div class="col-1 d-flex p-0"></div>
              </div>
              </ng-template>
          </div> -->
    </div>
    <!--Section one :: END-->

    <!--Section two :: started-->
    <div class="col-md-12 col-lg-6 section-two color-white korkeus kakkone p-0 section">

      <!-- header container : start-->
      <div class="header-container">
        <div class="row col-12 m-0">
          <i class="fa fa-users icon-white fa-icon icon-users burger burger-left sidebar-burger col-0-nset ms-2" (click)="openNav($event, 'mySidenav')" aria-hidden="true"></i>
          <div id="notification-mobile" *ngIf="newMessage == true">
            <span class="notification-tab">1</span>
          </div>
          <div class="col-1 col-md-0 me-md-4 p-0 mt-2 d-none d-md-block">
            <div class="avatar-icon-chat mt-1 sideBar-avatar">
                <div class="img-container">
                  <img src="./../../assets/images/chat2Vet_some.png">
                </div>
            </div>
          </div>
          <div class="col-6 col-md-8 p-0">
            <div class="container-for-dr-name d-flex flex-column mt-1">
              <p class="body1-w mb-0">{{this.patientName}}<span class="text-small-typing" *ngIf="globalMessages[currentActiveDoctorName]?.typing">kirjoittaa...</span></p>
              <p class="body3-w">{{ this.patientAnimal }}</p>
            </div>

          </div>
          <div class="col-3 col-md-2 p-0 pull-right">
            <div class="col-12 row m-0 p-0">
              <div class="col-4 m-0 p-0">
                <i *ngIf="roomData" (click)="joinExistingRoom()" class="fas fa-video video-icon-header mt-2 white-icon blinking pe-4"></i>

              </div>
              <div class="col-4 m-0 p-0" (click)="DropdownExpand($event)">
                <div class="dropdown float-right mt-2">
                  <i class="fas fa-ellipsis-h expand-icon-dropdown float-right dropbtn mt-1" id="dropdownMenuLink"></i>
      
                  <div id="myDropdown" class="dropdown-content mt-4">
                    <a class="dropdown-item text-center body3b" data-bs-toggle="modal" data-bs-target="#close-consultation-modal" id="dot-close-chat" (click)="[fetchDiagnosis($event),setTextBoxForUser(this.currentActiveDoctorName,'consultationClose'),setTextBoxForUser(this.currentActiveDoctorName,'vetPublicNotes'),setTextBoxForUser(this.currentActiveDoctorName,'vetPrivateNotes')]">SULJE CHAT</a>
                    <a class="dropdown-item text-center body3b" data-bs-toggle="modal" id="add-queue-btn" data-bs-target="#add-queue">LISÄÄ JONOON</a>
                    <a class="dropdown-item text-center body3b" data-bs-toggle="modal" data-bs-target="#more-doctors">PYYDÄ APUA</a>
                    <a class="dropdown-item text-center body3b" data-bs-toggle="modal" data-bs-target="#sendEmail">SÄHKÖPOSTI</a>
                    <a class="dropdown-item text-center body3b" data-bs-toggle="modal" data-bs-target="#ticket">TIKETTI</a>
                    <a class="dropdown-item text-center body3b" (click)="checkPaidCustomers()">TARKISTA MAKSANEET</a>
                    <a class="dropdown-item text-center body3b" (click)="askPermissionToUseCameraAudio()">VIDEO</a>
                    <a class="dropdown-item text-center body3b" href="https://meet.google.com/?hs=197&pli=1&authuser=1" target="popup" onclick="window.open('https:/'+'/meet.google.com/?hs=197&pli=1&authuser=1','name','width=600,height=400')">PUHELU</a>
                  </div>
                </div>
              </div>
              <div class="col-4 m-0 p-0">
                <i class="fa fa-bars fa-icon burger pull-right icon-white icon-bars-right" (click)="openNav($event, 'sidebarRight')" aria-hidden="true"></i>

              </div>
            </div>
          
          </div>
        </div>
      </div>
               <!--  Modal sulje chat -->
               <div class="modal fade" id="close-consultation-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title header3" id="exampleModalLabel">Sulje keskustelu</h5>
                      <button type="button" class="btn-close" (click)="clearMessages()" data-bs-dismiss="modal" aria-label="Close">
                        
                      </button>
                    </div>
                    <div class="modal-body">
                      <div id="loader-close-consultation" class="loader-container" style="display: none;">
                        <div class="loader mx-auto"></div>
                        <p class="text-center">Pieni hetki käyntitietoja tallennetaan...</p>
                        </div>
                        <div id="close-consultation-form">
                      <h3 class="text-center header3">
                        Olen sulkemassa keskustelua potilaan "{{this.currentActivePatientData.name}}" kanssa
                      </h3>
                      <p class="text-center body2b">Odota kunnes saat vahvistuksen onnistumisesta.</p>
                      <div *ngIf="currentActiveUserCredit <= 0">
                        <div class="alert alert-danger text-center">
                          HUOM! Käyttäjän Credit on jo nolla. Et voi tehdä käyntimerkintää.
                        </div>
                      </div>
                      <div *ngIf="creditMsg == 'Käyttäjän chatin sulkeminen epäonnistui'">
                        <div class="alert alert-warning">
                          <p class="text-center">{{creditMsg}}</p>
                        </div>
                      </div>
                      <div *ngIf="creditMsg == 'Käyttäjän chatin sulkeminen onnistui'">
                        <div class="alert alert-success">
                          <p class="text-center">{{creditMsg}}</p>
                        </div>
                      </div>
                      <p class="text-center body2b">Käyttäjän "{{this.currentActiveUserData.firstName }} {{this.currentActiveUserData.lastName}}" Credit on tällä hetkellä {{currentActiveUserCredit}} .</p>
    
    
                      <br>
                      <h3 class="text-center header3"><strong>Valitse vaihtoehtoiset diagnoosit</strong></h3>
                      <input type="search" class="form-control" id="diffArr" #diffArr [(ngModel)]="searchText" placeholder="Kirjoita termi tähän...">
                      <br>
                      <div class="search-diff-container w-100">
                        <table class="table">
                          <thead class="veteva-table-header">
                            <tr >
                              <td>ID</td>
                              <td>Termi</td>
                            </tr>
                          </thead>
                          <tbody class="table-striped">
                            <tr (click)="createDiffArr(data)" class="w-100 pointer" id="term-{{data.id}}" *ngFor="let data of diagnosisArr | searchFilter: searchText">
                              <td>{{data.id}}</td>
                              <td>{{data.term}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br>
                      <div *ngIf="createdDiffArr.length != 0;else">
                        <br>
                        <p class="body1 mb-0"><strong>Valitsemani vaihtoehtoiset diagnoosit:</strong></p>
                        <div *ngFor="let term of createdDiffArr">
                          <p class="selected-diagnosis mb-0">{{term}}<i class="fas fa-trash-alt" (click)="removeItemFormDiffArr(term)"></i></p>
                        </div>
                        <br>
                      </div>
      
                      <br>
                      <h3 class="text-center header3"><strong>Valitse lopulliset diagnoosit</strong></h3>
                      <br>

                      <!-- FINAALIT -->
                      <input type="search" class="form-control" id="finalArr" [(ngModel)]="searchTextFinal" placeholder="Kirjoita termi tähän...">
                      <br>
                      <div class="search-diff-container w-100">
                        <table class="table">
                          <thead class="veteva-table-header">
                            <tr >
                              <td >ID</td>
                              <td >Termi</td>
                            </tr>
                          </thead>
                          <tbody class="table-striped">
                            <tr (click)="createFinalArr(data)" class="w-100 pointer" id="term-{{data.id}}" *ngFor="let data of diagnosisArr | searchFilter: searchTextFinal">
                              <td>{{data.id}}</td>
                              <td>{{data.term}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <br>
                      <div *ngIf="createdFinalArr.length != 0;else">
                        <br>
                        <p class="body1 mb-0"><strong>Valitsemani lopulliset diagnoosit:</strong></p>
                        <div *ngFor="let term of createdFinalArr">
                          <p class="selected-diagnosis mb-0">{{term}}<i class="fas fa-trash-alt" (click)="removeItemFormFinalArr(term)"></i></p>
                        </div>
                        <br>
                      </div>
                    <form [formGroup]="endConsultation"
                    (ngSubmit)="RemoveChatBar()">
                    <div class="form-group">
                        <h3 class="header3"><strong>Käyntitiedot</strong></h3>
<!--                         <p class="btn btn-veteva-secondary mt-3 mb-3" (click)="fetchUrls()">Hae url osoitteet</p>
 -->                        <p class="btn btn-veteva-secondary mt-3 mb-3" (click)="generateRegimenFromChat()"><i class="fas fa-plus-circle me-2"></i>Kopioi chat</p>

                            <textarea (change)="autoTextareaCare('endConsultation')" (ngModelChange)="[saveWrittenToLocalStorage('consultationClose'),fetchUrls(),autoTextareaCare('endConsultation')]" (keydown)="[autoTextareaCare('endConsultation')]" type="text"
                                placeholder="Kirjoita tähän..."
                                formControlName="endConsultation"
                                id="endConsultation"
                                class="form-control"
                                required></textarea>
                                <h3 class="header3 mt-3"><strong>Yksityiset muistiinpanot</strong></h3> <p class="body2 ">Ei näy asiakkaalle</p>
                            <textarea (change)="autoTextareaCare('vetPrivateNotes')" (ngModelChange)="[saveWrittenToLocalStorage('vetPrivateNotes'),autoTextareaCare('vetPrivateNotes')]" (keydown)="[autoTextareaCare('vetPrivateNotes')]" type="text"
                                placeholder="Kirjoita tähän..."
                                formControlName="vetPrivateNotes"
                                id="vetPrivateNotes"
                                class="form-control"
                                >
                            </textarea>

                            <h3 class="header3 mt-3"><strong>Julkiset muistiinpanot</strong></h3> <p class="body2 ">Näkyy asiakkaalle</p>
                            <textarea (change)="autoTextareaCare('vetPublicNotes')" (ngModelChange)="[saveWrittenToLocalStorage('vetPublicNotes'),autoTextareaCare('vetPublicNotes')]" (keydown)="[autoTextareaCare('vetPublicNotes')]" type="text"
                                placeholder="Kirjoita tähän..."
                                formControlName="vetPublicNotes"
                                id="vetPublicNotes"
                                class="form-control"
                                >
                            </textarea>
                        
                    </div>
                    <div class="p-4 mustilinks" *ngIf="selectedLinks.length > 0">
                      <p class="m-0" *ngFor="let link of selectedLinks">{{link}} <i class="fas fa-trash-alt" (click)="removeLink(link)"></i></p>
                    </div>
                    <div *ngIf="this.currentActiveUserCredit > 0">                    
                    <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                        <div class="col-6 mt-4">
                            <button type="button" class="btn btn-veteva-secondary" id="close-close-consultation" (click)="clearMessages()" data-bs-dismiss="modal">Poistu</button>
                        </div>
                        <div class="col-6 mt-3 mt-md-4">
                          <div class="text-end">

                            <button type="submit"class="btn close-btn"  id="deleteBtn-{{currentActiveUserData._id}}" [disabled]="endConsultation.invalid">Lähetä ja sulje</button>
                          </div>
                        </div>
                    </div>
                  </div>
                  </form>
                        </div>
                    <div class="modal-footer">
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <!--  Modal takaisin jonoon -->
              <div class="modal fade" id="add-queue" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title header3" id="exampleModalLabel">Lisää jonoon</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="clearMessages()" aria-label="Close">
                      
                    </button>
                  </div>
                  <div class="modal-body">
                    <h3 class="text-center header3">Haluan lisätä henkilön takaisin jonoon.</h3>
                    <p class="text-center body2b">Odota kunnes saat vahvistuksen onnistumisesta.</p>
                    <div *ngIf="currentActiveUserCredit <= 0">
                      <div class="alert alert-warning">
                        HUOM! Olet lisäämässä henkilöä jonoon, jonka credit on 0. Kyseinen henkilö pääsee täten maksamatta ja ilman esitietoja sisään. Oletko varma, että haluat jatkaa?
                      </div>
                    </div>

                    <p class="text-center">Käyttäjän Credit on tällä hetkellä {{currentActiveUserCredit}} .</p><br>
                    <div *ngIf="inQueueMsg != ''">
                      <div class="alert alert-success">
                        {{inQueueMsg}}
                      </div>
                    </div>
                    <div *ngIf="inQueueMsg != ''">
                      <div class="alert alert-alert">
                        {{inQueueErrMsg}}
                      </div>
                    </div>
                    <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                      <div class="col-6 text-center">
                        <button class="btn btn-veteva-secondary-gray" data-bs-dismiss="modal">Peruuta</button>
                      </div>
                      <div class="col-6 text-center">
                        <button (click)="sendPatientBackToQueue()" id="deleteBtn" class="pull-right overflow-el btn btn-veteva-secondary" >Lisää jonoon</button>
      
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       <!-- MODAL LÄHETÄ SPOSTI -->
       <div class="modal fade" id="more-doctors" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title header3" id="exampleModalLabel">Pyydä apua</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <p class="body2b">Painamalla "Pyydä apua"- painiketta voit lähettää klinikkasi eläinlääkäreille apupyynnön. Onnistuneen pyynnön jälkeen näyttö suljetaan</p>
              <div class="alert alert-danger" *ngIf="messageErr">
                <p>{{messageErr}}</p>
              </div>
              <div class="alert alert-success" *ngIf="messageSuccess">
                <p>{{messageSuccess}}</p>
              </div>

              <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                <div class="col-6 text-center">
                  <button class="btn btn-veteva-secondary-gray" data-bs-dismiss="modal">Peruuta</button>
                </div>
                <div class="col-6 text-center">
                  <button (click)="sendHelp()" class="btn btn-veteva-secondary">Pyydä apua</button>

              </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- MODAL TIKETTI -->

      <!-- MODAL LÄHETÄ SPOSTI -->
      <div class="modal fade" id="sendEmail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title header3" id="exampleModalLabel">Lähetä sähköposti</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" *ngIf="messageErr">
                <p>{{messageErr}}</p>
              </div>
              <div class="alert alert-success" *ngIf="messageSuccess">
                <p>{{messageSuccess}}</p>
              </div>
        
              <form [formGroup]="mail"
                (ngSubmit)="sendEmailToPatient()">

                <div class="form-group">
                  <label class="body2b">Otsikko</label>
                      <input type="text"
                          placeholder="Kirjoita tähän..."
                          formControlName="header"
                          id="header"
                          class="form-control"
                          required>
                  
                </div>
                <div class="form-group">
                    <label for="mail">Viesti</label>
                        <textarea type="text"
                            placeholder="Kirjoita tähän..."
                            formControlName="mail"
                            id="mail"
                            class="form-control"
                            required></textarea>
                    
                </div>

                <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                  <div class="col-6 text-center">
                    <p class="btn btn-veteva-secondary-gray" id="close-modal-email" data-bs-dismiss="modal">Peruuta</p>
                  </div>
                  <div class="col-6 text-center">
                      <button class="btn btn-veteva-secondary" [disabled]="mail.invalid">Lähetä</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- MODAL TIKETTI -->
      <div class="modal fade" id="ticket" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title header3" id="exampleModalLabel">Lähetä korjaustiketti</h3>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                
              </button>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" *ngIf="messageErr">
                <p>{{messageErr}}</p>
              </div>
              <div class="alert alert-success" *ngIf="messageSuccess">
                <p>{{messageSuccess}}</p>
              </div>
        
              <form [formGroup]="ticket"
              (ngSubmit)="sendTicketToNastaset()">

                <div class="form-group">
                  <label class="body2b" for="ticketHeader">Otsikko</label>
                      <input type="text"
                        placeholder="Kirjoita tähän..."
                        formControlName="ticketHeader"
                        id="ticketHeader"
                        class="form-control"
                        required>
                  
                </div>
                <div class="form-group">
                  <label class="body2b" for="ticket">Viesti</label>
                    <textarea type="text"
                      placeholder="Kuvaile ongelmaa..."
                      formControlName="ticket"
                      id="ticket"
                      class="form-control"
                      required></textarea>
                    
                </div>

                    <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                      <div class="col-6 text-center">
                        <p class="btn btn-veteva-secondary-gray" data-bs-dismiss="modal">Peruuta</p>
                      </div>
                      <div class="col-6 text-center">
                        <button class="btn btn-veteva-secondary" [disabled]="ticket.invalid">Lähetä</button>                      </div>
                    </div>
                </form>              
            </div>
          </div>
        </div>
      </div>

                  <!-- Modal Asiakastiedot-->
                  <div class="modal fade" id="asiakastiedot" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Asiakastiedot</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            
                          </button>
                        </div>
                        <div class="modal-body">
                          <div *ngIf="currentActivePatientData != '' || currentActivePatientData != undefined" class="modal-body">
                            <table  class="table table-striped">
                   
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Tieto</th>
                                </tr>
                              </thead>
                              
                              <tbody >
                                  <tr>
                                    <th scope="row">Etunimi</th>
                                    <td > {{ currentActiveUserData.firstName }} </td><br>
                                  </tr>
                                  <tr>
                                    <th >Sukunimi</th>
                                    <td > {{ currentActiveUserData.lastName }} </td>
                                  </tr>
                                  <tr>
                                    <th >Email</th>
                                    <td > {{ currentActiveUserData.email }} </td>
                                  </tr>
                                  <tr>
                                    <th >Käyttäjätunnus</th>
                                    <td > {{ currentActiveUserData.userName }} </td>
                                  </tr>
                                  <tr>
                                    <th >Puhelinnumero</th>
                                    <td > {{ currentActiveUserData.phone }} </td>
                                  </tr>
                                  <tr>
                                    <th >Viime kirjautuminen</th>
                                    <td > {{ currentActiveUserData.currentLogin }} </td>
                                  </tr>
                                  <tr>
                                    <th >Kirjautunut</th>
                                    <td > <span *ngIf="currentActiveUserData.loggedIn == true">Kyllä</span>
                                          <span *ngIf="currentActiveUserData.loggedIn == false">Ei</span> 
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngFor="let pet of userPetData">
                            <table  class="table table-striped">
                   
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Lemmikki: </th>
                                </tr>
                              </thead>
                              
                              <tbody>
                                  <tr>
                                    <th scope="row">Kutsumanimi</th>
                                    <td > {{ pet.name }} </td><br>
                                  </tr>
                                  <tr>
                                    <th >Eläinlaji</th>
                                    <td > {{ pet.species }} </td>
                                  </tr>
                                  <tr>
                                    <th >Rotu</th>
                                    <td > {{ pet.breed }} </td>
                                  </tr>
                                  <tr>
                                    <th >Sukupuoli</th>
                                    <td > {{ pet.sex | changeGender}} </td>
                                  </tr>
                                  <tr>
                                    <th >Mikrosiru</th>
                                    <td > {{ pet.microCard }} </td>
                                  </tr>
                                  <tr>
                                    <th >Rekisterinumero</th>
                                    <td > {{ pet.registerNumber }} </td>
                                  </tr>
                                  <tr>
                                    <th >Lisätiedot</th>
                                    <td > {{ pet.addInfo }}</td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-veteva-secondary" data-bs-dismiss="modal">Sulje</button>
                        </div>
                      </div>
                    </div>
                  </div>
      
      <!-- header container : end-->


      <div class="chat-window-main-container" id="chat-window-main-container" [ngClass]="{'chat-window-main-container-no-replybox': !showReplyBox}">

        <div class="chat-messages-container" id="chat-container" #chatMessageContainer (scroll)="onScroll()">


          <div class="row m-0 message-container" *ngFor="let message of currentActiveMessageList">

            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_1' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b">Viestisi ovat luottamuksellisia.</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>

            <div class="col-md-12" *ngIf=" message.template == 'TEMPLATE_14' ">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img src="../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                  <p class = "body3b m-0">{{ currentActiveUserData.firstName }} {{ currentActiveUserData.lastName }}</p>
                  <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left whitespace-preline">
                      <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                        <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                    </div>
                    <ng-template #teksti_viesti>
                      <p class="break-word body2-w">{{message.actMessage | newlineHtml}}</p>
                    </ng-template>
                    </div>
                  </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-5"></div>
                <p class="text-message-time-left mt-2 ms-2 ps-3">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-md-12" *ngIf=" message.template == 'TEMPLATE_34' ">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img src="../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                  <p class = "body3b m-0">{{ currentActiveUserData.firstName }} {{ currentActiveUserData.lastName }}</p>
                  <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left whitespace-preline">
                      <div class="image-container">
                        <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                        <div class="overlay-image">
                          <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon" title="Lähennä kuvaa">
                            <i class="fas fa-search-plus"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-5"></div>
                <p class="text-message-time-left mt-2 ms-2 ps-3">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-md-12" *ngIf=" message.template == 'TEMPLATE_44' ">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img src="../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-6 p-0 m-1">
                  <p class = "body3b m-0">{{ currentActiveUserData.firstName }} {{ currentActiveUserData.lastName }}</p>
                  <div class="d-flex justify-content-start p-0">
                    <div class="text-message text-message-left whitespace-preline">
                      <div>
                        <video class="kuvaviesti" controls alt="">
                          <source src="{{message.actMessage.location}}#t=0.001">  
                        </video>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-5"></div>
                <p class="text-message-time-left mt-2 ms-2 ps-3">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_13' ">
              <div class="row m-0">
                <div class="col-md-6 col-1">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right whitespace-preline me-2">
                  <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                    <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                </div>
                <ng-template #teksti_viesti>
                  <p class="body3-b m-0 break-word">{{message.actMessage | newlineHtml}}</p>
                </ng-template>
                    <br />
                    
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                  </div>
                </div>

                <div class="col-0-nset p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="{{fileURL}}/{{myImage}}">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_33' ">
              <div class="row m-0">
                <div class="col-md-6 col-1">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right whitespace-preline me-2">
                    <div class="image-container">
                      <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                      <div class="overlay-image">
                        <a  (click)="openImageEnlarger(message.actMessage.key)" class="icon" title="Lähennä kuvaa">
                          <i class="fas fa-search-plus"></i>
                        </a>
                      </div>
                    </div>

                    <br />
                    
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                  </div>
                </div>

                <div class="col-0-nset p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="{{fileURL}}/{{myImage}}">
                  </div>
                </div>
              </div>
              </div>

              <div id="message-id-{{message._id}}" class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_43' ">
                <div class="row m-0">
                  <div class="col-md-6 col-1">
                    <p class="d-none">To take some space</p>
                  </div>
                  <div class="col-md-5 col-9 p-0">
                    <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                    <div>
                      <video class="kuvaviesti" controls alt="">
                        <source src="{{message.actMessage.location}}#t=0.001">  
                      </video>
  
                  </div>
  
                      <br />
                      
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                    </div>
                  </div>
  
                  <div class="col-0-nset p-0 d-flex align-items-end">
                    <div class="avatar-icon-chat">
                      <img src="{{fileURL}}/{{myImage}}">
                    </div>
                  </div>
                </div>
                </div>

                <div id="message-id-{{message._id}}" class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_53' ">
                  <div class="row m-0">
                    <div class="col-md-6 col-1">
                      <p class="d-none">To take some space</p>
                    </div>
                    <div class="col-md-5 col-9 p-0">
                      <div class="d-flex justify-content-end p-0">
                      <div class="text-message text-message-right whitespace-preline me-2">
                      <div class="d-flex justify-content-center">
                        <i *ngIf="message.actMessage.mimetype == 'application/pdf'" class="fas fa-file-pdf att-icon pdf-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word att-icon word-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel att-icon xls-icon"></i>
                        <i *ngIf="message.actMessage.mimetype == 'text/plain'" class="fas fa-file-alt att-icon icon-txt"></i>
                    </div>
                    <br>
                    <p class="body3-b">{{message.actMessage.originalname}}</p>
                    <div class="text-center">
                      <button class="btn btn-veteva-secondary" (click)="this.fileUploaderService.downloadAttachment(message.actMessage)"><i class="fas fa-download me-2"></i>Lataa</button>

                    </div>
    
                        <br />
                        
                        <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                      </div>
                      </div>
                    </div>
    
                    <div class="col-0-nset p-0 d-flex align-items-end">
                      <div class="avatar-icon-chat">
                        <img src="{{fileURL}}/{{myImage}}">
                      </div>
                    </div>
                  </div>
                  </div>

            


            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_15' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b">Eläinlääkäri lopetti keskustelun</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>

            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_16' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b">Asiakas on onnistuneesti lisätty takaisin jonoon</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>


          </div>

          <div class="hashtag-overlay-disabled col-12 col-md-6" id="hashtag-ready">
            <ul class="list-group" *ngFor="let data of hashtagArr | searchHashtag: searchTextHashtag">
              <li class="list-group-item p-md-0 item-hashtag">
                <div class="col-12 p-0 p-md-2 row m-0">
                  <div class="col-8 col-md-9 p-0 p-md-2">
                    <p class="body2b pointer mb-md-0" title="Valitse {{data.hashtag}}" (click)="fetchMyHashtag(data.hashtag,true)">
                    {{data.hashtag}}
                    </p>
                </div>
                <div class="col-4 col-md-3 p-0 text-end">
                  <button class="btn btn-veteva-secondary" (click)="fetchMyHashtag(data.hashtag, false)" data-bs-toggle="modal" data-bs-target="#read-hashtag-modal">Lue</button>
                </div>
              </div>
                
              </li>
            </ul>
          </div>

        </div><!-- chat message main container :: END -->


        <!-- AKTIIVISEN ELÄIMEN DATA INFO SCREENILLE -->
        <div style="display: none;" id="active-patient-data" class="info-screen">

          <p class="body1 ms-2 mt-2 pointer"(click)="CloseInfoScreen($event,'active-patient-data')">Takaisin<i class="fa fa-times ms-1" aria-hidden="true"></i></p>
          <table class="table w-100 w-md-50 active-pet-table mx-auto">

            <tbody>
              <tr>

                <td class="text-start">Nimi</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['name'] }}</p>
                </th>
              </tr>
              <tr>
                <td class="text-start">Eläinlaji</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['species'] }}</p>
                </th>
              </tr>
              <tr>
                <td class="text-start">Sukupuoli</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['sex'] | changeGender}}</p>
                </th>
              </tr>
              <tr>
                <td class="text-start">Syntymäpäivä</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['birthDate'] | pformateDate}}</p>
                </th>
              </tr>
              <tr>
                <td class="text-start">Ikä</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['age']}}</p>
                </th>
              </tr>
              <tr *ngIf="infoToPetScreen['microCard']">
                <td class="text-start">Mikrosiru</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['microCard'] }}</p>
                </th>
              </tr>
              <tr *ngIf="infoToPetScreen['registerNumber']">
                <td class="text-start">Rekisterinumero</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['registerNumber'] }}</p>
                </th>
              </tr>
              <tr *ngIf="infoToPetScreen['addInfo']">
                <td class="text-start">Lisätiedot</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ infoToPetScreen['addInfo'] }}</p>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- KÄYTNIDATA INFOSCREENILLE -->

        <div id="visit-data-info" style="display: none;" class="info-screen">
          <p class="body1 ms-2 mt-2 pointer"(click)="CloseInfoScreen($event,'visit-data-info')">Takaisin<i class="fa fa-times ms-1" aria-hidden="true"></i></p>


          <div *ngIf="this.visitHistory.length <= 0;else has_visits_content">
            <div class="alert alert-warning">
              <p>Asiakkaasta ei ole käyntitietoja</p>
            </div>
          </div>
          <ng-template #has_visits_content>
            <h4 class="text-center header3">Käynnit</h4>
            <div class="col-12 col-md-12 d-inline-flex">
              <div class="col-md-2 d-flex no-mobile"></div>
              <div class="col-12 col-md-12 col-lg-8">
              <ul class="list-group w-100" id="menu">
                  <div *ngFor="let visit of visitHistory" class="full-wrap mb-2 me-2 ms-2">
                      <div class="d-inline-flex col-md-12 ">
                          <div class="body1 d-none d-md-block col-md-2 d-flex mt-2">
                              <p>Käynti</p>
                          </div>
                          <div class="col-md-6"></div>
                          <div class="body1 col-md-4 mt-2 d-flex">
                              Tiedot
                          </div>
                      </div>
                      <li class="list-group-item list-group-item-link d-inline-flex col-12 col-md-12 ps-0 pe-0 pt-0">
                          <div class="col-2 col-md-1 p-0 d-flex"></div>
                        <div class="list-wrap-closed d-inline-flex row col-10 col-md-10 p-0">
                          <div class="img-cont col-2 col-md-2 p-0">
                            <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="käynti">
                          </div>
                          <div class="d-flex col-8 col-md-5 p-0 d-flex flex-column">
                              <div class="p-0">
                                  <p class=" mb-0 body2b-b text-start">{{visit.createdDate |pformateDate}}</p>
                              </div>
                              <div class="p-0">
                                  <p class="text-start body3">{{visit.regimen  | truncate : 100}} </p>
                              </div>
                          </div>
                          <div class="col-1 col-md-4">
                            <i class="fa fa-plus float-right expand-icon ms-2 mt-2 me-lg-3 me-xl-0"  id="expand-icon-{{visit._id}}" (click)="openExpandBox($event)"></i>
                            <i class="fa fa-minus float-right close-icon ms-2 mt-2 me-lg-3 me-xl-0  poista" style="display: none;" id="close-icon-{{visit._id}}" (click)="closeExpandBox($event)"></i>
                              <!-- <div class="btn btn-veteva-secondary" routerLink="/user/visit-detail/{{visit._id}}">Käynti</div> -->
                          </div>
                        </div>
                    </li>
                    <ul class="list-group list-group-flush list" id="{{visit._id}}" >
                      <li class="list-group-item list-group-item-link list-group-show">
                       <div class=" d-inline-flex">
                         <p class="ms-2 mt-1 body2 mb-0"><strong>Lopulliset diagnoosit:</strong></p>
                       </div>
                       <div class="final-diagnosis-wrap body3 ms-2 mb-0" *ngFor="let final of visit.diagnosisFinal">
                         <p>{{final}},</p>
                       </div>
                      </li>
                      <li class="list-group-item list-group-item-link list-group-show">
                      <div class=" d-inline-flex">
                        <p class="ms-2 mt-1 body2 mb-0"><strong>Vaihtoehtoiset diagnoosit:</strong></p>
                      </div>
                      <div class="diff-diagnosis-wrap body3 ms-2 mb-0" *ngFor="let diff of visit.diagnosisDiff">
                        <p>{{diff}}, </p>
                      </div>
                       </li>
                       <li class="list-group-item list-group-item-link list-group-show">
                        <div class=" d-inline-flex">
                          <p class="ms-2 mt-1 body2 mb-0"><strong>Käyntitiedot</strong></p>
                        </div>
                        <div class="diff-diagnosis-wrap body3 ms-2 mb-0">
                          <p>{{visit.regimen | truncate : 100}} </p>
                        </div>
                         </li>
                       <li class="list-group-item list-group-item-link list-group-show">
                        <div class=" d-inline-flex">
                          <p class="ms-2 mt-1 body2 mb-0"><strong>Eläinlääkäri:</strong></p>
                        </div>
                        <div class="diff-diagnosis-wrap body3 ms-2 mb-0">
                          <p>{{visit.doctorFullName}} </p>
                        </div>
                         </li>
                       <li class="list-group-item list-group-item-link list-group-show">
                        <div class="expanded-wrap">
                              <button class="btn btn-veteva-secondary" routerLink="/vet/visit-detail/{{visit._id}}">Käynti</button>
                            </div>
                       </li>
                    </ul>
                  </div>
                </ul>
              </div>
              <div class="col-md-2 no-mobile d-flex"></div>
              </div>
          </ng-template>


        </div>

        <!-- ANAMNEESIT INFOSCREENILLE -->

        <div id="anamnesis-data-info" style="display: none;" class="info-screen">
          <p class="body1 ms-2 mt-2 pointer"(click)="CloseInfoScreen($event,'anamnesis-data-info')">Takaisin<i class="fa fa-times ms-1" aria-hidden="true"></i></p>
          <table class="table w-50 active-pet-table mx-auto">

            <tbody>
              <tr *ngIf="currentActivePatientData.anamnesis">

                <td class="text-start">Kastroitu</td>
                <th class="text-end" scope="row">
                  <p class="m-0">
                    <span *ngIf="anamnesisData['cast'] == true">
                      Kyllä
                    </span>
                    <span *ngIf="anamnesisData['cast'] == false">
                      Ei
                    </span>
                  </p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Vaiva alkoi</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['started'] |pformateDate }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Vaivannut aikaisemmin</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['before'] }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Käyntisyy</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['reason'] }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Paino</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['weight'] }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Lääkitys</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['medicine'] }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Krooniset sairaudet</td>
                <th class="text-end" scope="row">
                  <p class="m-0">{{ anamnesisData['chronical'] }}</p>
                </th>
              </tr>
              <tr *ngIf="anamnesisData">
                <td class="text-start">Lisätiedot</td>
                <th class="text-end" scope="row">
                  <p class="m-0 table-word-wrap">{{ anamnesisData['addInfo'] }}</p>
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- AKTIIVISEN KÄYTTÄJÄN TIEDOT INFOSCREENILLE -->

        <div id="active-user-data-info" style="display: none;" class="info-screen">
          <p class="body1 ms-2 mt-2 pointer"(click)="CloseInfoScreen($event,'active-user-data-info')">Takaisin<i class="fa fa-times ms-1" aria-hidden="true"></i></p>
          <div *ngIf="currentActiveUserData.length != 0;else no_active_user_data ">
            <table class="table w-50 active-pet-table mx-auto">

              <tbody>
                <tr>

                  <td class="text-start">Nimi</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentActiveUserData.firstName }} {{ currentActiveUserData.lastName }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Email</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentActiveUserData.email }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Käyttäjätunnus</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentActiveUserData.userName }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Puhelinnumero</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentActiveUserData.phone }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Viimeisin kirjautuminen</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentActiveUserData.currentLogin | pformateDate}}</p>
                  </th>
                </tr>
                <tr>
                  <th class="text-start">Kirjautunut</th>
                  <td class="text-end"> <span *ngIf="currentActiveUserData.loggedIn == true">Kyllä</span>
                        <span *ngIf="currentActiveUserData.loggedIn == false">Ei</span> 
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <ng-template #no_active_user_data>
          <div class="alert alert-warning">
            <p>Aktiivista käyttäjää ei ole vielä klikattu</p>
          </div>
        </ng-template>
        </div>

        <!-- JONOSSA OLEVAN KÄYTTÄJÄN TIEDOT INFOSCREENILLE -->

        <div id="quer-data-info-screen" style="display: none;" class="info-screen">
          <p class="body1 ms-2 mt-2 pointer"(click)="CloseInfoScreen($event,'quer-data-info-screen')">Takaisin<i class="fa fa-times ms-1" aria-hidden="true"></i></p>
          <div *ngIf="queueList.length != 0;else no_active_user_queue ">
            <table class="table w-50 active-pet-table mx-auto">

              <tbody>
                <tr>

                  <td class="text-start">Omistaja</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.firstName }} {{ currentQuerInfoScreen.lastName }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Email</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.email }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Puhelinnumero</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.phone }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Lemmikin kutsumanimi</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsName }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Laji</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsSpecies }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Rotu</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsBreed }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Sukupuoli</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsSex | changeGender}}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Syntymäpäivä</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsBirthDate | pformateDate}}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Muut tiedot</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.petsAddInfo }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start">Credit</td>
                  <th class="text-end" scope="row">
                    <p class="m-0">{{ currentQuerInfoScreen.consultationCredit }}</p>
                  </th>
                </tr>
              </tbody>
            </table>
        </div>
        <ng-template #no_active_user_queue>
          <div class="alert alert-warning">
            <p>Jaa käyttäjää ei ole vielä klikattu</p>
          </div>
        </ng-template>
        </div>



        <!-- Reply Box -->

        <!-- Reply Box End -->


      </div> <!-- chat windeow main container :: END -->

      <div class="row reply me-0 ms-0 mt-4 col-12 p-0 pt-1 pb-1 ps-2" id="reply-box" *ngIf="showReplyBox">
        <div class="col-1 p-0 ps-md-2 me-3 me-md-0 text-center mt-2">
          <div class="image-icon-wrapper mt-1">
            <div class="btn-group dropup">
              <label type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" class="pull-right "><i class="fas fa-plus veteva-icon-main" aria-hidden="true" for="input"></i></label>

              <ul class="dropdown-menu">
                <li class="dropdown-item"><label class="pointer" for="files"><i class="fas fa-camera me-2"></i>Kuvatiedosto</label></li>
                <li class="dropdown-item"><label class="pointer" for="videos"><i class="fas fa-video me-2"></i>Videotiedosto</label></li>
                <li class="dropdown-item"><label class="pointer" for="attachments"><i class="fas fa-paperclip me-2"></i>Liitetiedosto</label></li>
              </ul>
            </div>
            <input style="display:none" (change)="handleFiles($event)" type="file" accept=".jpg, .jpeg, .png" id="files"/>
            <input style="display:none" (change)="handleVideos($event)" type="file" accept="video/mp4, video/quicktime, .mov, .mp4" id="videos"/>
            <input style="display:none" (change)="handleAttachment($event)" type="file" accept=".pdf,.docx,.doc,.xlsx,.txt" id="attachments"/>
          </div>
        </div>
        <div class="col-8 ps-0 reply-main ps-0 pe-0 mt-2">
          <textarea class="send-message-input" autocomplete="off" rows="1"  id="comment" [(ngModel)]="replyMessage" placeholder="Kirjoita tähän..."
            (keydown)="[UserTypingMessage(),searchHashtag($event)]"  (ngModelChange)="[listenHashtag(), saveWrittenToLocalStorage('replyBox'),autoTextarea()]" (keydown.enter)="[$event.stopPropagation(),OnSendReply($event)]"></textarea>
        </div>
        <div class="col-2 col-md-2 ps-2 pe-0 mt-1 mt-md-0">
<!--             <button class="send-btn" (click)="OnSendReply($event)">Lähetä</button>
-->            <!-- <i class="fa fa-send fa-2x" aria-hidden="true" (click)="OnSendReply($event)"></i> -->
          <div class="send-btn-container pointer float-end text-center mt-1 mt-md-0" (click)="OnSendReply($event)">
            <i class="fas fa-arrow-up white-icon send-icon"></i>
          </div>
        </div>
      </div>
      <p style="display:none">Kuva:</p>
      <canvas style="display:none" id="canvas" width="700px" height=700></canvas>



    </div>
    <!--Section two :: END-->

    <!--Section three :: started-->
     <div class="col-md-3 section-three color-white p-0 kolmonen section sidenav" id="sidebarRight">
       <div class="col-12 p-0">
<!--         <div class="header-container header-sec-three">
        </div> -->
        <div class="data-container">
        <div class="tabData col-12 row third-tab">
          <button class="tablinksData koti active col-5 col-md-6 "  (click)="openData($event, 'petinfo')"><i class="fas fa-paw pe-2"></i>LEMMIKKI</button>
          <button class="tablinksData col-6 " (click)="openData($event,'customerinfo')"><i class="fas fa-user-tie pe-2"></i>ASIAKAS</button>
          <i class="fa fa-times fa-icon burger burger-left closeNav me-0 close-icon col-1" (click)="closeNav('sidebarRight')" aria-hidden="true"></i>
        </div>

        <!-- LEMMIKKI -->
          <div style = "display: block;" id="petinfo" class="tabcontentData pet-data-wrap mt-4">
            <div *ngIf="currentActivePatientData.length != 0;else no_active_pets_content">
            <table class="table doctable">

              <tbody>
                <tr>

                  <td class="text-start body1">Nimi</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.name }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Eläinlaji</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.species }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Rotu</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.breed }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Sukupuoli</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.sex | changeGender}}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Syntymäpäivä</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.birthDate | pformateDate}}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Ikä</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.age}}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Kastroitu</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b"><span *ngIf="currentActivePatientData.anamnesis.cast == false">Ei</span>
                      <span *ngIf="currentActivePatientData.anamnesis.cast == true">Kyllä</span>
                    </p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Vaiva alkoi</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.started | pformateDate }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Vaivannut aikaisemmin</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.before }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Käyntisyy</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.reason }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Paino</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.weight }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Lääkitys</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.medicine }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.anamnesis">
                  <td class="text-start body1">Krooniset sairaudet</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.anamnesis.addInfo }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.microCard">
                  <td class="text-start body1">Mikrosiru</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.microCard }}</p>
                  </th>
                </tr>
                <tr *ngIf="currentActivePatientData.registerNumber">
                  <td class="text-start body1">Rekisterinumero</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.registerNumber }}</p>
                  </th>
                </tr>
                <tr>
                  <td class="text-start body1">Lisätiedot</td>
                  <th class="text-end" scope="row">
                    <p class="m-0 line-break body1b">{{ currentActivePatientData.addInfo }}</p>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #no_active_pets_content>
            <div class="alert alert-warning">
              <p class="text-center">Konsultaatio on päättynyt tai aktiivista potilasta ei ole.</p>
            </div>
          </ng-template>
          </div>
          <div id="customerinfo" class="tabcontentData w-100 pet-data-wrap mt-4">
            <div *ngIf="currentActiveUserData.length != 0;else no_active_user_data ">
              <table class="table doc-table ">

                <tbody>
                  <tr>

                    <td class="text-start body1">Nimi</td>
                    <th class="text-end " scope="row">
                      <p class="m-0 line-break body1b body1b">{{ currentActiveUserData.firstName }} {{ currentActiveUserData.lastName }}</p>
                    </th>
                  </tr>
                  <tr>
                    <td class="text-start body1">Email</td>
                    <th class="text-end" scope="row">
                      <p class="m-0 line-break body1b">{{ currentActiveUserData.email }}</p>
                    </th>
                  </tr>
                  <tr>
                    <td class="text-start body1">Puhelinnumero</td>
                    <th class="text-end" scope="row">
                      <p class="m-0 line-break body1b"> <a href="tel:{{currentActiveUserData.phone}}">{{ currentActiveUserData.phone }} </a></p>
                    </th>
                  </tr>
                  <tr>
                    <td class="text-start body1">Osoite</td>
                    <th class="text-end" scope="row">
                      <p class="m-0 line-break body1b">{{currentActiveUserData.addressStreet}}</p>
                    </th>
                  </tr>
                  <tr>
                    <td class="text-start body1">Postitoimipaikka</td>
                    <th class="text-end" scope="row">
                      <p class="m-0 line-break body1b">{{currentActiveUserData.addressZip}} {{currentActiveUserData.addressCity}}</p>
                    </th>
                  </tr>
                  <tr>
                    <td class="text-start body1">Viimeisin kirjautuminen</td>
                    <th class="text-end" scope="row">
                      <p class="m-0 line-break body1b">{{ currentActiveUserData.currentLogin }}</p>
                    </th>
                  </tr>
                  <tr>
                    <tr>
                      <td class="text-start body1">Kirjautunut</td>
                      <th class="text-end body1b"> <span *ngIf="currentActiveUserData.loggedIn == true">Kyllä</span>
                            <span *ngIf="currentActiveUserData.loggedIn == false">Ei</span> 
                      </th>
                  </tr>
                </tbody>
              </table>
          </div>
          <ng-template #no_active_user_data>
            <div class="alert alert-warning">
              <p>Aktiivista käyttäjää ei ole vielä klikattu</p>
            </div>
          </ng-template>
          </div>
          <div class="col-12 pt-4 row button-wrapper h-100  me-0 ms-0">
            <div class="d-flex justify-content-center">
              
              <button class="close-btn" id="btn btn-veteva-secondary-delete" data-bs-toggle="modal" (click)="[fetchDiagnosis($event),setTextBoxForUser(this.currentActiveDoctorName,'consultationClose'),setTextBoxForUser(this.currentActiveDoctorName,'vetPublicNotes'),setTextBoxForUser(this.currentActiveDoctorName,'vetPrivateNotes')]" data-bs-target="#close-consultation-modal"><i class="far fa-window-close pe-2"></i>Sulje keskustelu</button>
            </div>

          </div>
      </div>
    </div>
    </div> 
    <!--Section three :: END-->



  </div>

  <div id="video-cover" style="display: none;">
    <div class="col-12 row me-0 ms-0" id="preview-window">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="col-12 text-center">
          <h1 class="header3 text-center pt-4">Näytänkö hyvältä?</h1>
          <p class="body2bb text-center">Annathan selaimen luvan käyttää kameraa ja mikrofonia.</p>
          <button class="btn btn-veteva-secondary" id="join-video-btn" title="painike disabloituu jos käyttäjä ei ole paikalla" (click)="acceptJoiningRoom()">Liityn keskusteluun</button> 

  
        </div>
        <div class="col-12">
          <div id="local-media" class="text-center video-preview-container">
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="local-media-sm-container" id="local-media-sm">
    </div>
    <div class="change-camera-container" *ngIf="videoAiring || !videoToken" (click)="changeCamera()">
      <div class="change-camera-bg">
        <i class="p-3 fas fa-retweet white-icon change-camera-icon"></i>
      </div>
    </div>
    <div class="col-12 p-0">
      <div id="remote-media-div" class=" text-center">
        <div id="wait-cont" class="pt-4 waiting-container d-none">
          <img width="200" src="../../assets/images/chat2Vet_some.png">
          <p class="body2bb">Odotetaan asiakkaan liittymistä...</p>
        </div>
      </div>
      <div class="bottom-navbar col-12 p-0 row m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0">
          <div class="col-12 row m-0 p-0">
            <div class="col-3 text-center video-btn pt-2" (click)="muteAudioVideo()" *ngIf="!muted">
              <i class="fas fa-microphone-slash video-icon"></i><br>
              <p>Mykistä</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="unmuteAudioVideo()" *ngIf="muted">
              <i class="fas fa-microphone-slash video-icon exit-icon"></i><br>
              <p>Avaa</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="detachCameraVideo()" *ngIf="videoShow">
              <i class="fas fa-video video-icon"></i><br>
              <p class="body2bb">Sulje</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="attachCameraVideo()" *ngIf="!videoShow">
              <i class="fas fa-video video-icon exit-icon"></i><br>
              <p class="body2bb">Avaa</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="[closeVideoBox()]">
              <i class="fas fa-comment video-icon"></i><br>
              <p class="body2bb">Chat</p>
            </div>
            <div class="col-3 text-center video-btn pt-2" (click)="acceptCloseVideoConsultation()">
              <i class="fas fa-times video-icon exit-icon"></i><br>
              <p class="body2bb">Sulje</p>
            </div>
          </div>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>

  <div id="image-enlarger" style="display: none;" (click)="closeImageEnlarger()">
    <div class="col-12" (click)="closeImageEnlarger()">
      <div class="text-end">
        <p class="header3M-w pointer mt-2 mt-md-0 me-4">Sulje ikkuna<i class="fas fa-times ms-2"></i></p>
      </div>
    </div>
    <div class="image-container-enlarger text-center">
      <img class="kuvaviesti" src="{{fileURL}}/{{enlargerImageKey}}" alt="">
  </div>

  </div>

</div>

                      <!-- Poista eläin MODAALI -->
                      <button id="multiple-windows-modal" style="display: none;"
                      data-bs-toggle="modal" data-bs-target="#tab-modal"></button>
                      <div class="modal fade" id="tab-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title header3" id="exampleModalLongTitle">Toimia vaaditaan</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                
                              </button>
                            </div>
                            <div class="modal-body">
                              <h3 class="header3 mb-4 text-center">Sinulla on useita välilehtiä</h3>
                              <p class="body2b">Tarkista, että sinulla on vain yksi chatikkuna auki. Tämän jälkeen päivitä sivu (refresh).
                              </p>
                            <div class="col-12 row pb-4">
                              <div class="col-6">
                              </div>
                              <div class="col-6 text-center">
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        </div>
                              <!-- Poista eläin MODAALI -->
                              <button id="connection-error" style="display: none;" 
                              data-bs-toggle="modal" data-bs-target="#conn-err-modal"></button>
                              <div class="modal fade" id="conn-err-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title header3" id="exampleModalLongTitle">Yhteys katkennut</h5>
                                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <h3 class="header3 mb-4 text-center">Yhteytesi on katkennut</h3>
                                      <p class="body2b">Tarkista, että sinulla on vain yksi chatikkuna auki. Tämän jälkeen päivitä sivu (refresh). Mikäli tämäkään ei auta, kyseessä on todennäköisesti tekninen häiriö
                                      </p>
                                    <div class="col-12 row pb-4">
                                      <div class="col-6">
                                      </div>
                                      <div class="col-6 text-center">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                </div>
        <!-- MODAL LÄHETÄ SPOSTI -->
        <div class="modal fade" id="read-hashtag-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title header3" id="exampleModalLabel">{{this.currentHashtag['hashtag']}}</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  
                </button>
              </div>
              <div class="modal-body hashtag-edit">
                <p class="body2b-b brake break-word" id="current-hashtag">{{this.currentHashtag['text']}}</p>
  
                <div class="col-12 ps-0 ps-md-2 pe-0 pe-md-2 row pb-4">
                  <div class="col-6 text-center">
                    <button class="btn btn-veteva-secondary-gray" data-bs-dismiss="modal">Peruuta</button>
                  </div>
                  <div class="col-6 text-center">
                    <button (click)="hashtagTextArea(this.currentHashtag['text'])" data-bs-dismiss="modal" class="btn btn-veteva-secondary">Valitse</button>
  
                </div>
                </div>
  
              </div>
            </div>
          </div>
        </div>

                  <!-- Liity videokeskusteluun moodalli-->
                  <button id="close-video-modal-btn" style="display: none;"
                  data-bs-toggle="modal" data-bs-target="#close-video-modal"></button>
                  <div class="modal fade" id="close-video-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title header3" id="exampleModalLongTitle">Suljetaanko videokeskustelu?</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            
                          </button>
                        </div>
                        <div class="modal-body">
                          <p class="body2b">Videoyhteys suljetaan. Voit jatkaa chattia asiakkaan kanssa normaaliin tapaan.</p>

                          <button class="btn btn-veteva-secondary mt-4" (click)="removeRoom()">Sulje videokeskustelu</button> <br>
                          <button class="btn btn-veteva-secondary-gray mt-4" id="close-video-close-modal" data-bs-dismiss="modal">Peruuta</button>
                        </div>
                      </div>
                    </div>
                  </div>

                <!-- Ladataan kuvaa modaaali-->
                <button id="attachment-loader-btn" style="display: none;"
                data-bs-toggle="modal" data-bs-target="#attachment-loader-modal"></button>
                <div class="modal fade" id="attachment-loader-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title header3" id="exampleModalLongTitle">Ladataan...</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                          
                        </button>
                      </div>
                      <div class="modal-body">
                        <div  class="loader-container">
                          <div class="loader mx-auto"></div>
                          <p class="text-center">Pieni hetki tiedostoa ladataan...</p>
                          </div>

                        <button class="btn btn-veteva-secondary-gray mt-4" id="close-sending-modal" data-bs-dismiss="modal">Peruuta</button>
                      </div>
                    </div>
                  </div>
                </div>
                  

                  <div class="d-flex justify-content-center">
                    <div class="toast d-none" role="alert" id="toaster" style="position: absolute; top:20px" aria-live="assertive" aria-atomic="true" data-delay="2000">
                      <div class="toast-header">
                        <img src="../../assets/images/chat2Vet_some.png" width="20" class="rounded me-2" alt="...">
                        <strong class="me-auto">pet2vet ilmoitus</strong>
                        <button type="button" class="ms-2 mb-1 close" data-bs-dismiss="toast" aria-label="Close">
                          
                        </button>
                      </div>
                      <div class="toast-body">
                        <p class="body2bb" id="toast-text"></p>
                      </div>
                    </div>
                  </div>