import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-user-visit-detail',
  templateUrl: './user-visit-detail.component.html',
  styleUrls: ['./user-visit-detail.component.css']
})
export class UserVisitDetailComponent implements OnInit,AfterViewInit {

  currentUserId='';
  visitID: string = this.route.snapshot.params.id;
  visits: any[];
  pets: any[];
  finalDiagosis:any = [];
  diffDiagnosis: any = [];
  date="";
  regimen="";
  petsName="";
  authToken = localStorage.getItem("auth");
  clinicID: string = this.route.snapshot.params.clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }


  ngOnInit() {
    this.myId();
  }
  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  myId(){

    var token = localStorage.getItem('auth');

    if(token == null){
      this.logOutt();
      this.authService.doLogOut();
    }
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);
    this.currentUserId = decodedToken.result.id;


    var activeuser = localStorage.getItem('ACTIVE_USER');


    console.log('totta',decodedToken.result.designation)

    this.fetchVisitData();
  }

  openExpandBox(e){


    var elmID = e.target.id;
    var petID = elmID.substring(12)

    var menu = document.getElementById(petID);
    var icon = document.getElementById(elmID);
    var iconMinus = document.getElementById('close-icon-'+petID)
    menu.classList.add('ease-in');
    iconMinus.style.display="block";
    icon.style.display="none";

  }

  closeExpandBox(e){
  var elmID = e.target.id;
  var petID = elmID.substring(11)



  var iconMinus = document.getElementById(elmID);
  var icon = document.getElementById('expand-icon-'+petID);
  var menu = document.getElementById(petID);
  menu.classList.add('ease-out');
  menu.classList.remove('ease-in');
  iconMinus.style.display="none";
  icon.style.display="block";

  }

  openExpandBox2(e){


    var elmID = e.target.id;
    var petID = elmID.substring(12)

    var menu = document.getElementById(petID);
    var icon = document.getElementById(elmID);
    var iconMinus = document.getElementById('close-icon-'+petID)
    menu.classList.add('ease-in');
    iconMinus.style.display="block";
    icon.style.display="none";

  }

  closeExpandBox2(e){
  var elmID = e.target.id;
  var petID = elmID.substring(11)



  var iconMinus = document.getElementById(elmID);
  var icon = document.getElementById('expand-icon-'+petID);
  var menu = document.getElementById(petID);
  menu.classList.add('ease-out');
  menu.classList.remove('ease-in');
  iconMinus.style.display="none";
  icon.style.display="block";

  }


  logOutt() {
    console.log("Request to logout", this.currentUserId);
    let req = {
      userID: this.currentUserId
    };
  
    this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log('vastaus',res);
        if(res.status == 200){
          
          //this.router.navigate(['login']);
          console.log("log out success");
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
      this.authService.doLogOut();
  
  }


  fetchVisitData(){

    var req = {
      visitID:this.visitID,
      userID:this.currentUserId
    }

    this.http.post(environment.FIND_ONE_VISIT_FOR_USER_END_POINT, req,this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        this.visits = res.message;

        this.date = res.message[0]['createdDate'];


        this.petsName = res.message[0]['petName'];
        this.regimen = res.message[0]['regimen'];
        this.replaceURLWithHTMLLinks(this.regimen);
        this.finalDiagosis = res.message[0]['diagnosisFinal'];
        this.diffDiagnosis = res.message[0]['diagnosisDiff'];
        console.log(this.visits)
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }
  printPage(){
    window.print();
  }

  formateDate(date){
    date = date.substring(0,10);
    date = new Date(date);
    var date2 =  date.getMonth() + 1
    console.log(date2);
    if (!isNaN(date.getTime())) {
        return date =   date.getDate() +'.'+ date2  +'.' + date.getFullYear();
    }
  }

  replaceURLWithHTMLLinks(text)
  {
    var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    document.getElementById('my-tag').innerHTML = text.replace(exp,"<a target='_blank' href='$1'>$1</a>"); 
  }


}
