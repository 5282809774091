import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColorServiceService } from '../service/color-service.service';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  user = {
    userName: '',
  };
  errMessage = "";
  successMessage = "";
  forgotForm: FormGroup;
  clinicID: string = this.route.snapshot.params.clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit() {

    this.setColors();

    this.forgotForm = this.fb.group({
      userName: ['', [
 
        Validators.required,
        Validators.email, 

      ]]
    })
  }

  setColors(){
    var ls = localStorage.getItem('colors');
    console.log(ls);
    if(ls){
      this.colorService.styleUser();
    }
    else{
    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
    }
  }

  get userName() { return this.forgotForm.get('userName') };
  forgotPassword() {
    var elm = document.getElementById('forgot-form');
    elm.classList.add("reg-form-success");
    document.getElementById('loader').style.display="block";

    var userName = this.forgotForm.get('userName').value;

      var userData = {
        userName: userName,
        clinicID: this.clinicID,
      }

      
      this.http.post(environment.FORGOT_PASSWORD_END_POINT, userData, this.httpOptions).subscribe(
        (res: any) => {
          console.log("Login success");
          if (res.status == 200) {
            document.getElementById('loader').style.display="none";
            this.errMessage = "";
            this.successMessage = "";
            this.successMessage = "Onnistui, sähköpostissasi on nyt aktivointilinkki!";
            elm.classList.remove("reg-form-success");
            this.forgotForm.get('userName').setValue('');

            var toLoginBtn = document.getElementById('toLogin');
            
          } else {
            this.errMessage = "";
            this.successMessage = "";
            this.errMessage = res.message;

          }
          
        }, (err: any) => {

          console.log("Error while login :: ", err);
        }
      )

    }
  /* } */

}

