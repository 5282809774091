<app-docheader></app-docheader>
<div class="header-page-container pb-4">

    <h2 class=" pt-4 header-text text-center">Lisää käynti </h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="tab col-md-10 d-flex justify-content-center">
        <div class="col-md-2 no-mobile"></div>
        <div class="col-md-2 no-mobile"></div>
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    
</div>

    <div class="col-12 row">
        <div class="col-md-3"></div>
        <div class="col-12 col-md-6">
            <div class="col-md-12 row m-0 p-0">
                <form class="w-100"[formGroup]="form"
                (ngSubmit)="onSubmit()">

                <div class="col-12 p-0 mt-4 ">
                <h3 class="mb-4">Esitiedot</h3>
                <!-- Käyntisyy -->
                <p class="body2b-b mb-1">Käyntisyy</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                            <select  placeholder="Käyntisyy" formControlName="reason" id="reason" class="form-control" [class.invalid-input]="reason.errors && reason.touched" required>
                                <option></option>
                                <option *ngFor="let reason of reasons" value="{{reason.reason}}">{{reason.reason}}</option>
                            </select>
                            <div class="my-alerts" *ngIf="reason.invalid && reason.touched">
                                <p class="mt-2" *ngIf="reason.errors.required">Puhelinnumero on pakollinen</p>
                                <p *ngIf="reason.errors.reason"> Vaaditaan määrätty puhelinnumero</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Paino -->
                <p class="body2b-b mb-1">Koska vaiva alkoi?</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                            <input type="date" placeholder="Koska vaiva alkoi?" formControlName="started" id="started" class="form-control" [class.invalid-input]="started.errors && started.touched" required>
                            <div class="my-alerts" *ngIf="started.invalid && started.touched">
                                <p class="mt-2" *ngIf="started.errors.required">Alku on pakollinen</p>
                                <p *ngIf="started.errors.started"> Vaaditaan määrätty alku</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- VAIVANNUT AIKAISEMMIN -->
                <p class="body2b-b mb-1">Vaivannut aikaisemmin</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                            <select placeholder="Vaivannut aikaisemmin" formControlName="before" id="before" class="form-control" [class.invalid-input]="before.errors && before.touched" required>
                                <option></option>
                                <option value="Kyllä">Kyllä</option>
                                <option value="Ei">Ei</option>
                            </select>
                            <div class="my-alerts" *ngIf="before.invalid && before.touched">
                                <p class="mt-2" *ngIf="before.errors.required">Paino on pakollinen</p>
                                <p *ngIf="before.errors.before"> Vaaditaan määrätty paino</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Kroonisia sairauksia -->
                <p class="body2b-b mb-1">kroonisia sairauksia</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                            <select placeholder="Kroonisia sairauksia" formControlName="chronical" id="chronical" class="form-control" [class.invalid-input]="chronical.errors && chronical.touched" required>
                                <option></option>
                                <option value="Kyllä">Kyllä</option>
                                <option value="Ei">Ei</option>
                            </select>
                            <div class="my-alerts" *ngIf="chronical.invalid && chronical.touched">
                                <p class="mt-2" *ngIf="chronical.errors.required">Paino on pakollinen</p>
                                <p *ngIf="chronical.errors.chronical"> Vaaditaan määrätty paino</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Lääkitys -->
                <p class="body2b-b mb-1">Lääkitys</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                        <textarea placeholder="Lääkitys" formControlName="medicine" class="form-control"></textarea>
                        </div>
                    </div>
                </div>

                <!-- Lisätietoja -->
                <p class="body2b-b mb-1">Lisätietoja</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                        <textarea  placeholder="Lisätietoja" formControlName="addInfo" class="form-control" ></textarea>
                        </div>
                    </div>
                </div>

                <!-- Paino -->
                <p class="body2b-b mb-1">Paino</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 p-0">
                            <input type="number" placeholder="Paino" formControlName="weight" id="weight" class="form-control" [class.invalid-input]="weight.errors && weight.touched" required>
                            <div class="my-alerts" *ngIf="weight.invalid && weight.touched">
                                <p class="mt-2" *ngIf="weight.errors.required">Paino on pakollinen</p>
                                <p *ngIf="weight.errors.weight"> Vaaditaan määrätty paino</p>
                            </div>
                        </div>
                    </div>
                </div>

                  <!-- Hoito ohje -->
                  <p class="body2b-b mb-1">Käyntiyhteenveto</p>
                  <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                        <textarea  placeholder="Käyntiyhteenveto..." formControlName="regimen" class="form-control" [class.invalid-input]="regimen.errors && regimen.touched" required></textarea>
                        <div class="my-alerts" *ngIf="regimen.invalid && regimen.touched">
                            <p class="mt-2" *ngIf="regimen.errors.required">regimen on pakollinen</p>
                            <p *ngIf="regimen.errors.regimen"> Vaaditaan kunnollinen regimen</p>
                        </div>
                        </div>
                    </div>
                </div>

                <p class="body2b-b">Vaihtoehtoinen diagnoosi</p>
                <div class="col-12 row">
                    <div class="col-9">
                        <div class="search-diff-container w-100">
                            <table class="table table-striped">
                                <thead class="veteva-table-header">
                                <tr >
                                    <td >ID</td>
                                    <td >Termi</td>
                                </tr>
                                </thead>
                                <tbody class="table-striped">
                                <tr (click)="createDiffArr(data)" class="w-100 pointer" id="term-{{data.id}}" *ngFor="let data of diagnosisArr ">
                                    <td>{{data.id}}</td>
                                    <td>{{data.term}}</td>
                                </tr>
                                </tbody>
                            </table>
                          </div>
                    </div>
                    
                    <div class="col-3 bg-white" >
                        <p class="body2b-b text-center">Poista</p>
                        <div class="round-wrapper" id="round-{{item}}" *ngFor="let item of createdDiffArr" >
                            <p class="sm-text text-center">{{item}}</p>
                        </div>
                    </div>
                </div>

                <p class="body2b-b">Lopullinen diagnoosi</p>
                <div class="col-12 row">
                    <div class="col-9">
                        <div class="search-diff-container w-100">
                            <table class="table table-striped">
                                <thead class="veteva-table-header">
                                <tr >
                                    <td >ID</td>
                                    <td >Termi</td>
                                </tr>
                                </thead>
                                <tbody class="table-striped">
                                <tr (click)="createFinalArr(data)" class="w-100 pointer" id="term-{{data.id}}" *ngFor="let data of diagnosisArr ">
                                    <td>{{data.id}}</td>
                                    <td>{{data.term}}</td>
                                </tr>
                                </tbody>
                            </table>
                          </div>
                    </div>
                    <div class="col-3 bg-white" >
                        <p class="body2b-b text-center">Poista</p>
                        <div class="round-wrapper" *ngFor="let it of createdFinalArr" >
                            <p class="sm-text text-center">{{it}}</p>
                        </div>
                    </div>
                </div>


                  <!-- Julkiset muistiinpanot -->
                  <p class="body2b-b mb-1">Julkiset muistiinpanot</p>
                  <div class="form-group">
                    <div class=" col-12">
                      <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                        <textarea placeholder="Julkiset muistiinpanot" formControlName="vetPublicNotes" id="vetPublicNotes" class="form-control" ></textarea>

                      </div>
                    </div>
                  </div>
                <!-- Yksityiset muistiinpanot -->
                <p class="body2b-b mb-1">Yksityiset muistiinpanot</p>
                <div class="form-group">
                    <div class=" col-12">
                        <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                        <textarea placeholder="Yksityiset muistiinpanot" formControlName="vetPrivateNotes" id="vetPrivateNotes" class="form-control" ></textarea>
                        </div>
                    </div>
                </div>


                  <button [disabled]="form.invalid"  class="btn login-btn btn-veteva-secondary"
                  >Tallenna muutokset</button>
            </div>
          </form>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>