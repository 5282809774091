import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from '../service/auth.service';
import { ColorServiceService } from '../service/color-service.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-visit',
  templateUrl: './add-visit.component.html',
  styleUrls: ['./add-visit.component.css']
})
export class AddVisitComponent implements OnInit,AfterViewInit {

  form: FormGroup;
  authToken = localStorage.getItem('auth');
  clinicID: string = this.route.snapshot.params.clinicID;
  userID: string = this.route.snapshot.params.userID;
  petID: string = this.route.snapshot.params.petID;
  diagnosisArr = [];
  searchTextFinal:string;
  reasons = [];
  createdDiffArr = [];
  createdFinalArr = [];
  currentUserID = this.authService.getTokenData().userID
  doctor;
  pet;
  user;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

    ngOnInit(): void {
      console.log(this.userID)
      this.form = this.fb.group({
        regimen: ['',
        Validators.required],
        vetPublicNotes:'',
        vetPrivateNotes:'',
        reason: ['', [
          Validators.required,
        ]],
        started: ['', [
          Validators.required,
        ]],
        weight: ['', 
        Validators.required],
        before: ['', 
        Validators.required],
        chronical: ['', 
        Validators.required],
        addInfo: '',
        medicine: '',
      });
      this.fetchDiagnosis();
      this.fetchReasons();
      this.fetchPetData();
      this.fetchUserData();
      this.fetchDoctorData();
    }

  get vetPublicNotes() { return this.form.get('vetPublicNotes') };
  get vetPrivateNotes() { return this.form.get('vetPrivateNotes') };
  get regimen() { return this.form.get('regimen') };
  get reason() { return this.form.get('reason') };
  get started() { return this.form.get('started') };
  get weight() { return this.form.get('weight') };
  get before() { return this.form.get('before') };
  get chronical() { return this.form.get('chronical') };
  get addInfo() { return this.form.get('addInfo') };
  get medicine() { return this.form.get('medicine') };

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }

    onSubmit(){
      console.log(this.user.firstName);
      const request = {

        //DIAGNOOSI
        regimen: this.form.get('regimen').value,
        vetPrivateNotes: this.form.get('vetPrivateNotes').value,
        vetPublicNotes: this.form.get('vetPublicNotes').value,
        diagnosisDiff: this.createdDiffArr,
        diagnosisFinal: this.createdFinalArr,
        doctorID: this.currentUserID,
        doctorFullName: this.doctor.firstName + " " + this.doctor.lastName,
        doctorDesignation: this.doctor.designation,
        
  
        //PET DATA
        petsName: this.pet.name,
        petsSpecies:this.pet.species,
        petsBreed: this.pet.breed,
        petsMicroCard: this.pet.microCard,
        petsRegisterNumber: this.pet.registerNumber,
        petsID:this.pet._id,
        petsSex:this.pet.sex,
        petsInsuranceCompany:this.pet.insuranceCompany,
        petsInsuranceNumber:this.pet.insuranceNumber,
        petsBirthDate:this.pet.birthDate,

        //ANAMNESIS
        petsAnamnesis: {
          reason: this.form.get('reason').value,
          started: this.form.get('started').value,
          weight: this.form.get('weight').value,
          before: this.form.get('before').value,
          chronical: this.form.get('chronical').value,
          addInfo: this.form.get('addInfo').value,
          medicine: this.form.get('medicine').value,
        },
  
  
        //USER DATA
        userID: this.user._id,
        userPhone: this.user.phone,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        queuedTime: null,
        recommendations: [],
        consultationTime: null,
        userEmail: this.user.email
  
      }


      this.http.post(environment.CREATE_VISIT_END_POINT, request, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
           if (res.status == 200) {
            alert('Käynti luotu');
            this.router.navigate(['/'+this.clinicID+'/visits-register'])
           }
           else{
             alert('virhe käyntiä luodessa')
           }
          }, (err: any) => {

        })

    }

    fetchDoctorData() {

      this.http.get(environment.DOC_PMS_DOCTOR_DATA_END_POINT, this.httpOptions).subscribe(
        (res: any) => {
           if (res.status == 200) {
            this.doctor = res.message[0];
            
          } else {

            //loginError = true;
          } 
        }, (err: any) => {
  
          console.log("Error while login :: ", err);
        })
      }

      fetchUserData() {

        var req ={
          patientID: this.userID
        }

        this.http.post(environment.DOC_PMS_USER_DATA_END_POINT,req, this.httpOptions).subscribe(
          (res: any) => {
            console.log(res);
             if (res.status == 200) {
              this.user = res.message[0][0];
              console.log(this.user.firstName);
              
            } else {
  
              //loginError = true;
            } 
          }, (err: any) => {
    
            console.log("Error while login :: ", err);
          })
        }

      fetchPetData() {
        var req ={
          patientID: this.petID
        }
  
        this.http.post(environment.DOC_PMS_PET_DATA_FOR_PET_END_POINT,req, this.httpOptions).subscribe(
          (res: any) => {
            console.log(res);
             if (res.status == 200) {
              this.pet = res.message[0];
              console.log(this.pet);
              
            } else {
  
              //loginError = true;
            } 
          }, (err: any) => {
    
            console.log("Error while login :: ", err);
          })
        }
      


  

    fetchDiagnosis() {

  
      if(this.diagnosisArr.length == 0) {
      this.http.get(environment.DOC_ALL_FETCH_DIAGNOSIS, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
           if (res.status == 200) {
            this.diagnosisArr = res.message;
            
          } else {

            //loginError = true;
          } 
        }, (err: any) => {
  
          console.log("Error while login :: ", err);
        })
      }
    }

    fetchReasons() {

      this.http.get(environment.FIND_CLINIC_REASONS, this.httpOptions).subscribe(
        (res: any) => {

          console.log(res);
  
          if(res.status == 200){
            this.reasons = res.message
            console.log(this.reasons)
          }
          else {
            alert('Käyntisyiden hakemisessa tapahtui virhe')
          }
  
        }, (err: any) => {
    
          console.log("Error while login :: ", err);
        })
    }

    createDiffArr(data){
      console.log(data);
  
      for(var i = 0; i < this.createdDiffArr.length; i++){
        var item = this.createdDiffArr[i];
  
        if(data.term == item){
          alert('Termi on jo lisätty listaan. Ole hyvä ja tarkista lista uudelleen.');
          return;
        }
      }
      this.createdDiffArr.push(data.term);
      console.log(this.createdDiffArr);
      this.colorService.waitStyleColorVetBubbles();
  
      var elm = document.getElementById('term-'+data.id);
  

    }
    removeItemFormDiffArr(term){
      for(var i = 0; i < this.createdDiffArr.length; i++){
        if(this.createdDiffArr[i] === term) {
          this.createdDiffArr.splice(i,1); 
          i-1;
        }
      }
    }
    createFinalArr(data){
  
      for(var i = 0; i < this.createdFinalArr.length; i++){
        var item = this.createdFinalArr[i];
  
        if(data.term == item){
          alert('Termi on jo lisätty listaan. Ole hyvä ja tarkista lista uudelleen.');
          return;
        }
      }
      var elm = document.getElementById('term-'+data.id);
  
      this.createdFinalArr.push(data.term);
      this.colorService.waitStyleColorVetBubbles();

  
  
    }
    removeItemFormFinalArr(term) {
      for(var i = 0; i < this.createdFinalArr.length; i++){
        if(this.createdFinalArr[i] === term) {
          this.createdFinalArr.splice(i,1); 
          i-1;
        }
      }
    }
}
