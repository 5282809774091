<app-docheader></app-docheader>

<div class="header-container-page pb-4">

  <h2 class=" pt-4 header-text-page">Käynti {{visits[0].createdDate | pformateDate}}, {{petsName}}</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pb-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>

<div class="wrapperi">
<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
  <button class="btn btn-veteva-secondary mt-3 ms-3" (click)="printPage()"><i class="fas fa-print pe-2"></i>Tulosta</button>
  


    
    <br>

      <div class="table-container mt-4">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Aikaleima</th>
              <th scope="col">Lääkäri</th>
              <th scope="col">Kutsumanimi</th>
              <th scope="col">Laji</th>
              <th scope="col">Rotu</th>
              <th scope="col">Omistaja</th>
              <th scope="col">Jonossa</th>
              <th scope="col">Käynnillä</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let visit of visits" >
                <td id ="date">{{visit.createdDate | pformateDate}}</td>
                <td><a routerLink="/{{clinicID}}/vet/doctor-detail/{{visit.doctorID}}">{{visit.doctorFullName}}</a></td>
                <td><a routerLink="/{{clinicID}}/vet/pet-detail/{{visit.petsID}}">{{visit.petName}}</a></td>
                <td>{{visit.petsSpecies }}</td>
                <td>{{visit.petBreed}}</td>
                <td><a routerLink="/{{clinicID}}/vet/user-detail/{{visit.owner}}">{{visit.firstName}} {{visit.lastName}}</a></td>
                <td>{{queTime}} min</td>
                <td>{{consultationTime}} min</td>

                

              </tr> 

          </tbody>
        </table>
      </div>

      <div class="col-12 row mt-4">
        <div class="col-6">
          <table class="table table-striped ">
            <thead class="veteva-table-header">
              <tr>
                <th scope="col">Esitieto</th>
                <th scope="col">Tieto</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Käyntisyy</th>
                  <td>
                    {{anamnesis.reason}}
                  </td>
              </tr>
              <tr>
                <th>Vaiva alkoi</th>
                  <td>
                    {{anamnesis.started | pformateDate}}
                  </td>
              </tr>
              <tr>
                <th>Vaivannut aikaisemmin</th>
                  <td>
                    {{anamnesis.before}}
                  </td>
              </tr>
              <tr>
                <th>Krooninen sairaus</th>
                  <td>
                    {{anamnesis.chronical}}
                  </td>
              </tr>
              <tr>
                <th>Paino</th>
                <td>
                  {{anamnesis.weight}}
                </td>
              </tr>
              <tr>
                <th>Kastroitu</th>
                <td>
                  <span *ngIf="anamnesis.cast == false">Ei</span>
                  <span *ngIf="anamnesis.cast == true">Kyllä</span>
                </td>
              </tr>
              <tr>
                <th>Lääkitys</th>
                  <td>
                    {{anamnesis.medicine}}
                  </td>
              </tr>
              <tr>
                <th>Lisätiedot</th>
                  <td>
                    {{anamnesis.addInfo}}
                  </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="col-6">
          <div class="text-area-wrap">
            <div class="alert alert-success" *ngIf="alert != ''">
              <p class="text-center">{{alert}}</p>
            </div>
            <div class="alert alert-danger" *ngIf="alertErr != ''">
              <p class="text-center">{{alertErr}}</p>
            </div>
            <p class="body2b-b mb-0">Yksityiset muistiinpanot</p>
            <p>Ei näy asiakkaalle (pl.GDPR)</p>
            <textarea id="vetPrivateNotes" class="form-control"></textarea>
            <button (click)="savePrivateNotes()" class="btn btn-veteva-secondary mt-2"><i class="far fa-save me-2"></i>Tallenna</button> 
          </div>
          <div class="text-area-wrap">
            <p class="body2b-b mb-0 mt-4">Julkiset muistiinpanot</p>
            <p>Näkyy asiakkaalle</p>
            <textarea id="vetPublicNotes" class="form-control"></textarea>
            <button class="btn btn-veteva-secondary mt-2" (click)="savePublicNotes()"><i class="far fa-save me-2"></i>Tallenna</button>   
          </div>
        </div>
      </div>

      <div class="table-container mt-4">
        <table class="table table-striped ">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Diagnoosityypi</th>
              <th scope="col">Diagoosit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vaihtoehtoinen diagnoosi</td>
              <td>{{diffDiagnosis}}</td>
            </tr>
            <tr>
              <td>Lopullinen diagnoosi</td>
              <td>{{finalDiagosis}}</td>
            </tr>
          </tbody>
        </table>
      </div>
        <br>
        <br>
        <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Käytitiedot</th>
            </tr>
          </thead>
          <tbody>
            <td><p class="brake">{{regimen}}</p></td>
  
          </tbody>
        </table>
      </div>
    </div>
  </div>

