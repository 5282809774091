import { Component, OnInit, ElementRef, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment,env_data } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColorServiceService } from '../service/color-service.service';

@Component({
  selector: 'app-doctor-login',
  templateUrl: './doctor-login.component.html',
  styleUrls: ['./doctor-login.component.css']
})
export class DoctorLoginComponent implements OnInit,AfterViewInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  errMessage = false;
  lockErr = false;

  user = {
    loginError: false,
  }

  loginForm: FormGroup;
  botErr = false;
  clinicID: string = this.route.snapshot.params.clinicID;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private zone: NgZone,
    private colorService: ColorServiceService,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID)
     }

  ngOnInit() {
    this.createForm();
    this.addRecaptchaScript();
    
  }
  ngAfterViewInit(){
    this.setColors();
  }

  setColors(){

    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
    
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : env_data.google_site_key, //TUOT= 6LfXmE8aAAAAAPAi2nun0eTRgSOYm7uSDvrJl9zX, MUSTI_DEV = 6LcPEDUaAAAAAP3y86cC2MHX0dKoJ2dhqc707-Q9
      'callback': (response) => {
          this.sendGoogleVerification(response);
      }
    });
  }

  createForm(){
    this.loginForm = this.fb.group({
      userName: ['', [
 
        Validators.required,
        Validators.email, 

      ]],
      password:  ['',[
        Validators.required,
        Validators.minLength(5),

      ]]
    })
  }
 
  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }
  get userName() { return this.loginForm.get('userName') };
  get password() { return this.loginForm.get('password') };
  sendGoogleVerification(response) {
    var password = this.loginForm.get('password').value;
    var userName = this.loginForm.get('userName').value;

    if(password == "" || userName == "") {
      return;
    }
    if(password.length < 4) {
      return;
    }
    if(userName.lenght < 4 ) {
      return;
    }
    if(!userName.includes('@')) {
      return;
    }

    var req = {
      response:response,
      userName: userName,
      password: password,
      clinicID: parseInt(this.clinicID),
    }

    var elm = document.getElementById('login-form');
    elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block";
    this.errMessage=false;
    this.lockErr = false;

    this.http.post(environment.GOOGLE_DOCTOR_LOGIN_VERIFICATION, req, this.httpOptions).subscribe(
      (res: any) => { 
        console.log(res);



          if(res.status == 200) {
            localStorage.setItem('TEMP_DOC_AUTH', res.token);
            this.zone.run(() => {
              this.router.navigate(['/'+this.clinicID+'/vet-validation/'+res.token]);
             });
        }
        if(res.status == 400){
          this.botErr == true;
        }
        if(res.status == "failed"){

            
        
            if(res.message == "Käyttäjätili lukittu") {
              console.log('eeli')
              this.lockErr = true;
              elm.classList.remove("reg-form-success");
              document.getElementById('loader').style.display="none";
  
              this.loginForm.get('password').setValue("");
            }
            else {
            this.errMessage = true;
            elm.classList.remove("reg-form-success");
            document.getElementById('loader').style.display="none";

            this.loginForm.get('password').setValue("");
            }
          
        }
      
      }, (err: any) => {
          
        console.log("Error while login :: ", err);
      })

  }
Login(){
  
}

/*   Login() {

    var password = this.loginForm.get('password').value;
    var userName = this.loginForm.get('userName').value;

    var userData = {
      userName: userName,
      password: password
    }

    var elm = document.getElementById('login-form');
    elm.classList.add("reg-form-success");
    console.log(elm);
    document.getElementById('loader').style.display="block";
    this.errMessage=false;

          this.http.post(environment.DOCLOGIN_END_POINT, userData, this.httpOptions).subscribe(
            (res: any) => {
              console.log("Login success");
              if (res.status == "success") {
                console.log('mitäää',res);
                this.authService.Doclogin(userData);
                //this.authService.doLogin(form);
                
              } else if(res.status == "failed"){
                this.errMessage = true;
                elm.classList.remove("reg-form-success");
                document.getElementById('loader').style.display="none";

                this.loginForm.get('password').setValue("");
              }

              else if(res.status == 200) {
                localStorage.setItem('TEMP_DOC_AUTH', res.token);
                this.router.navigate(['/vet-validation/'+res.token]);
              }
            }, (err: any) => {
              this.user.loginError = true;

    
              console.log("Error while login :: ", err);
            }
          )
    
        } */


}
