<app-docheader></app-docheader>
<div *ngIf="isAdmin" class="header-container-page">

    <h2 class=" pt-4 header-text-page">Statistiikka </h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
        <button class="tablinks koti active col-6 col-md-2 body1b-b "  (click)="openCity($event, 'today')">Tänään</button>
        <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'payments')">Maksut</button>
        <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'registers')">Rekisteröitymiset</button>
        <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'visits')">Käynnit</button>
        <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'feedbacks')">Palautteet</button>
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    
    </div>
<div class="col-12 row me-0 ms-0 mt-4">
    <div class="tabcontent" id="today" style="display: block;" >
        <div class="col-md-12 row " *ngIf="dataError == false">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <h1 class="text-center">Yleiskatsaus</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-3 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Rekisteröityneitä asiakkaita</h3>
                        <h1 class="text-center stat-header">{{currentStat.users.total_registered_users}}</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Rekisteröityneitä lemmikkejä</h3>
                        <h1 class="text-center stat-header">{{currentStat.pets.total_number_of_pets}}</h1>
                    </div>
                    </div>
                    <div class="col-12 col-md-3 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Onnistuneita maksuja</h3>
                        <h1 class="text-center stat-header">{{currentStat.payments.total_successfull_number}}</h1>
                    </div>
                    </div>
                    <div class="col-12 col-md-3 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Käyntejä</h3>
                        <h1 class="text-center stat-header">{{currentStat.visits.total_registered_visits}}</h1>
                    </div>
                    </div>
                </div>

                <h1 class="text-center">Käynnit</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Sukupuolijakauma</h3>
                        <div [hidden]="!visitsGenderDiversion">
                            <canvas class=" w-100" id="visit-gender-diversion"  >{{visitsGenderDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Lajijakauma</h3>
                        <div [hidden]="!visitsSpeciesDiversion">
                            <canvas class=" w-100" id="visit-species-diversion"  >{{visitsSpeciesDiversion}}</canvas>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Diagnooseja annettu</h3>
                        <div [hidden]="!visitsDiagnosisDiversion">
                            <canvas class=" w-100" id="visit-diagnosis-diversion"  >{{visitsDiagnosisDiversion}}</canvas>
                        </div>
                    </div>
                    </div>
                </div>


                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-12 mb-4 mb-md-0 h-50">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Top 5 käyntisyyt</h3>
                        <div [hidden]="!top5reasons" height="200" style="height:200px">
                            <canvas class=" w-100" id="visit-top5reasons"  >{{top5reasons}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät kuinka paljon diagnosoitu">Top 5 vaihtoehtoiset</h3>
                        <div [hidden]="!top5DiffDiagnosis">
                            <canvas class=" w-100" id="visit-top5DiffDiagnosis"  >{{top5DiffDiagnosis}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center">Top 5 lopulliset</h3>
                            <div [hidden]="!top5FinalDiagnosis">
                                <canvas class=" w-100" id="visit-top5FinalDiagnosis"  >{{top5FinalDiagnosis}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Kuinka kauan asiakkaalla kestää tulla taudin alkamisesta klinikalle">Taudin alkaminen (päivää)</h3>
                        <h1 class="text-center stat-header">{{currentStat.visits.total_symptom_started_average_days.toFixed(0)}}</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Kuinka paljon suosituksia annettu yhteensä">Suositusten määrä</h3>
                        <h1 class="text-center stat-header">{{currentStat.visits.total_number_of_recommendations.toFixed(0)}}</h1>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Suhdeluku kuinka monella on ollut oireita aikaisemmin">Oireita aikaisemmin</h3>
                        <h1 class="text-center stat-header">{{((currentStat.visits.total_symptom_has_been_before / currentStat.visits.total_registered_visits) * 100).toFixed(0) }}%</h1>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Keskimääräinen aika vietetty konsultaatiossa">km. chat aika (min)</h3>
                        <h1 class="text-center stat-header">{{currentStat.visits.total_average_consultation_time.toFixed(0) }}</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Kuinka kauan asiakas on keskimäärin viettänyt jonossa aikaa">km. Jonoaika (min)</h3>
                        <h1 class="text-center stat-header">{{currentStat.visits.total_average_queue_time.toFixed(0)}}</h1>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Kuinka monta prosenttia asiakkaista on tullut uudelleen">Palaavia asiakkaita</h3>
                        <h1 class="text-center stat-header">{{((currentStat.visits.total_returning_customers.length / currentStat.visits.total_registered_visits) * 100).toFixed(0) }}%</h1>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso palanneet asiakkaat</button>
                        </div>
                    </div>
                    </div>
                </div>

                <h1 class="text-center">Koirien käynnit</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät kuinka paljon diagnosoitu">Top 5 koirien vaihtoehtoiset</h3>
                        <div [hidden]="!top5DiffDiagnosisVisitedDogs">
                            <canvas class=" w-100" id="visit-top5DiffDiagnosisVisitedDogs"  >{{top5DiffDiagnosisVisitedDogs}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center">Top 5 koirien lopulliset</h3>
                            <div [hidden]="!top5FinalDiagnosisDogs">
                                <canvas class=" w-100" id="visit-top5FinalDiagnosisDogs"  >{{top5FinalDiagnosisDogs}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät mitkä rodut käyneet eniten klinikalla">Top 5 koirien rodut</h3>
                        <div [hidden]="!top5VisitBreedsDogs">
                            <canvas class=" w-100" id="visit-top5VisitBreedsDogs"  >{{top5VisitBreedsDogs}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Suosituimmat käyntisyyt">Top 5 koirien käyntisyyt</h3>
                            <div [hidden]="!top5VisitReasonsDogs">
                                <canvas class=" w-100" id="visit-top5VisitReasonsDogs"  >{{top5VisitReasonsDogs}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Sukupuolijakauma">Koirien sukupuolijakauma</h3>
                        <div [hidden]="!dogvisitsGenderDiversion">
                            <canvas class=" w-100" id="visit-dogvisitsGenderDiversion"  >{{dogvisitsGenderDiversion}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Käyneiden koirien keskimääräinen ikä">Koirien keskimääräinen ikä (vuotta)</h3>
                            <h1 class="text-center stat-header">{{currentStat.visits.dogs.average_age.toFixed(1)}}</h1>

                        </div>
                    </div>
                </div>

                <h1 class="text-center">Kissojen käynnit</h1>
                
                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät kuinka paljon diagnosoitu">Top 5 kissojen vaihtoehtoiset</h3>
                        <div [hidden]="!top5DiffDiagnosisVisitedCats">
                            <canvas class=" w-100" id="visit-top5DiffDiagnosisVisitedCats"  >{{top5DiffDiagnosisVisitedCats}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center">Top 5 kissojen lopulliset</h3>
                            <div [hidden]="!top5FinalDiagnosisCats">
                                <canvas class=" w-100" id="visit-top5FinalDiagnosisCats"  >{{top5FinalDiagnosisCats}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät mitkä rodut käyneet eniten klinikalla">Top 5 kissojen rodut</h3>
                        <div [hidden]="!top5VisitBreedsCats">
                            <canvas class=" w-100" id="visit-top5VisitBreedsCats"  >{{top5VisitBreedsCats}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Suosituimmat käyntisyyt">Top 5 kissojen käyntisyyt</h3>
                            <div [hidden]="!top5VisitReasonsCats">
                                <canvas class=" w-100" id="visit-top5VisitReasonsCats"  >{{top5VisitReasonsCats}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Sukupuolijakauma">Kissojen sukupuolijakauma</h3>
                        <div [hidden]="!catvisitsGenderDiversion">
                            <canvas class=" w-100" id="visit-catvisitsGenderDiversion"  >{{catvisitsGenderDiversion}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Käyneiden koirien keskimääräinen ikä">Kissojen keskimääräinen ikä (vuotta)</h3>
                            <h1 class="text-center stat-header">{{currentStat.visits.cats.average_age.toFixed(1)}}</h1>

                        </div>
                    </div>
                </div>

                <h1 class="text-center">Pieneläimien käynnit</h1>
                
                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät kuinka paljon diagnosoitu">Top 5 pieneläimien vaihtoehtoiset</h3>
                        <div [hidden]="!top5DiffDiagnosisVisitedSmallAnimals">
                            <canvas class=" w-100" id="visit-top5DiffDiagnosisVisitedSmallAnimals"  >{{top5DiffDiagnosisVisitedSmallAnimals}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center">Top 5 pieneläimien lopulliset</h3>
                            <div [hidden]="!top5FinalDiagnosisSmallAnimals">
                                <canvas class=" w-100" id="visit-top5FinalDiagnosisSmallAnimals"  >{{top5FinalDiagnosisSmallAnimals}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät mitkä rodut käyneet eniten klinikalla">Top 5 pieneläimien rodut</h3>
                        <div [hidden]="!top5VisitBreedsSmallAnimals">
                            <canvas class=" w-100" id="visit-top5VisitBreedsSmallAnimals"  >{{top5VisitBreedsSmallAnimals}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Suosituimmat käyntisyyt">Top 5 pieneläimien käyntisyyt</h3>
                            <div [hidden]="!top5VisitReasonsSmallAnimals">
                                <canvas class=" w-100" id="visit-top5VisitReasonsSmallAnimals"  >{{top5VisitReasonsSmallAnimals}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Sukupuolijakauma">Pieneläimien sukupuolijakauma</h3>
                        <div [hidden]="!smallAnimalsvisitsGenderDiversion">
                            <canvas class=" w-100" id="visit-smallAnimalsvisitsGenderDiversion"  >{{smallAnimalsvisitsGenderDiversion}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Käyneiden koirien keskimääräinen ikä">Pieneläimien keskimääräinen ikä (vuotta)</h3>
                            <h1 class="text-center stat-header">{{currentStat.visits.small_animals.average_age.toFixed(1)}}</h1>

                        </div>
                    </div>
                </div>

                <h1 class="text-center">Rekisteröidyt lemmikit</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Sukupuolijakauma</h3>
                        <div [hidden]="!petsGenderDiversion">
                            <canvas class=" w-100" id="pets-petsGenderDiversion"  >{{petsGenderDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Lajijakauma</h3>
                        <div [hidden]="!petsSpeciesDiversion">
                            <canvas class=" w-100" id="pets-petsSpeciesDiversion"  >{{petsSpeciesDiversion}}</canvas>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Steriloituja</h3>
                        <div [hidden]="!petsSterilized">
                            <canvas class=" w-100" id="pets-petsSterilized"  >{{petsSterilized}}</canvas>
                        </div>
                    </div>
                    </div>
                </div>

                <h1 class="text-center">Rekisteröidyt koirat</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Sukupuolijakauma</h3>
                        <div [hidden]="!petsDogsGenderDiversion">
                            <canvas class=" w-100" id="pets-petsDogsGenderDiversion"  >{{petsDogsGenderDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Rekisteröidyimmät rodut</h3>
                        <div [hidden]="!petsDogsTop5Breeds">
                            <canvas class=" w-100" id="pets-petsDogsTop5Breeds"  >{{petsDogsTop5Breeds}}</canvas>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Keskimääräinen ikä</h3>
                        <h1 class="text-center stat-header">{{currentStat.pets.dogs.average_age.toFixed(1)}}</h1>
                    </div>
                    </div>
                </div>

                <h1 class="text-center">Rekisteröidyt kissat</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Sukupuolijakauma</h3>
                        <div [hidden]="!petsCatsGenderDiversion">
                            <canvas class=" w-100" id="pets-petsCatsGenderDiversion"  >{{petsCatsGenderDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Rekisteröidyimmät rodut</h3>
                        <div [hidden]="!petsCatsTop5Breeds">
                            <canvas class=" w-100" id="pets-petsCatsTop5Breeds"  >{{petsCatsTop5Breeds}}</canvas>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Keskimääräinen ikä</h3>
                        <h1 class="text-center stat-header">{{currentStat.pets.cats.average_age.toFixed(1)}}</h1>
                    </div>
                    </div>
                </div>

                <h1 class="text-center">Rekisteröidyt pieneläimet</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Sukupuolijakauma</h3>
                        <div [hidden]="!petsSmallAnimalsGenderDiversion">
                            <canvas class=" w-100" id="pets-petsSmallAnimalsGenderDiversion"  >{{petsSmallAnimalsGenderDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Rekisteröidyimmät rodut</h3>
                        <div [hidden]="!petsSmallAnimalsTop5Breeds">
                            <canvas class=" w-100" id="pets-petsSmallAnimalsTop5Breeds"  >{{petsSmallAnimalsTop5Breeds}}</canvas>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Keskimääräinen ikä</h3>
                        <h1 class="text-center stat-header">{{currentStat.pets.small_animals.average_age.toFixed(1)}}</h1>
                    </div>
                    </div>
                </div>
                
                <h1 class="text-center">Maksut</h1>

                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Määrät mitkä rodut käyneet eniten klinikalla">Top 5 ostetut tuotteet</h3>
                        <div [hidden]="!top5BoughtProducts">
                            <canvas class=" w-100" id="visit-top5BoughtProducts"  >{{top5BoughtProducts}}</canvas>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-veteva-secondary">Katso koko lista</button>

                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                            <h3 class="text-center" title="Suosituimmat käyntisyyt">Top 5 alekoodit</h3>
                            <div [hidden]="!top5UsedDiscountCodes">
                                <canvas class=" w-100" id="visit-top5UsedDiscountCodes"  >{{top5UsedDiscountCodes}}</canvas>
                            </div>
                            <div class="text-center">
                                <button class="btn btn-veteva-secondary">Katso koko lista</button>
            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 row me-0 ms-0 mt-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Maksujakauma</h3>
                        <div [hidden]="!paymentDiversion">
                            <canvas class=" w-100" id="visit-paymentDiversion"  >{{paymentDiversion}}</canvas>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Kokonaismyynti</h3>
                        <h1 class="text-center stat-header">{{currentStat.payments.total_sell.toFixed(2)}}€</h1>

                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center">Annetut alennukset</h3>
                        <h1 class="text-center stat-header">{{currentStat.payments.total_granted_discounts.toFixed(2)}}€</h1>
                    </div>
                    </div>
                </div>
                <h1 class="text-center">Palautteet</h1>

                <div class="col-12 row me-0 ms-0 mt-4 mb-4">
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Palautteita annettu">Palautteita annettu</h3>
                        <h1 class="text-center stat-header">{{currentStat.feedbacks.number_of_feedbacks.toFixed(0)}}</h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="Kuinka paljon suosituksia annettu yhteensä">Keskiarvo</h3>
                        <h1 class="text-center stat-header">{{(currentStat.feedbacks.total_average_grade).toFixed(0)}}</h1>
                    </div>
                    </div>
                    <div class="col-12 col-md-4 mb-4 mb-md-0">
                        <div class="container-box card-box p-3">
                        <h3 class="text-center" title="NPS">NPS-luku</h3>
                        <h1 class="text-center stat-header">{{(currentStat.feedbacks.total_average_nps).toFixed(0) }}</h1>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                </div>
                <div class="col-md-1"></div>
        </div>
    </div>
    <div id="payments" class="tabcontent col-12" style="display: none;">
        <h1 class="text-center">Maksut</h1>
        <div class="col-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div [hidden]="!paymentChart">
                    <canvas class=" w-100 mt-4" id="payment-chart" style="min-height:250px"  >{{paymentChart}}</canvas>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
    <div id="registers" class="tabcontent col-12" style="display: none;">
        <h1 class="text-center">Rekisteröitymiset</h1>
        <div class="col-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div [hidden]="!registerChart">
                    <canvas class=" w-100 mt-4" id="register-chart" style="min-height:250px"  >{{registerChart}}</canvas>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
    <div id="visits" class="tabcontent col-12" style="display: none;">
        <h1 class="text-center">Käynnit</h1>
        <div class="col-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div [hidden]="!visitChart">
                    <canvas class=" w-100 mt-4" id="visit-chart" style="min-height:250px"  >{{visitChart}}</canvas>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
    <div id="feedbacks" class="tabcontent col-12" style="display: none;">
        <h1 class="text-center">Palautteet</h1>
        <div class="col-12 row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div [hidden]="!feedbackChart">
                    <canvas class=" w-100 mt-4" id="feedback-chart" style="min-height:250px"  >{{feedbackChart}}</canvas>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</div>
