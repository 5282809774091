import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService;

@Component({
  selector: 'app-all-pets',
  templateUrl: './all-pets.component.html',
  styleUrls: ['./all-pets.component.css']
})
export class AllPetsComponent implements OnInit {

  message: string;
  currentUserId='';
  properMessage="";
  pets: any[];
  messagesArr: any[];
  authToken = localStorage.getItem('auth');

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    ) { }

  ngOnInit() {
    this.myId();
  }


  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    console.log('iidee',decodedToken.result.id);
    this.currentUserId= decodedToken.result.id;
    this.getPets();
  }

  getPets(){
    console.log(this.currentUserId,'nykyne iide');
    var req = {
      id: this.currentUserId
    }
    this.http.post(environment.FIND_PET_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == "Failed"){
          this.pets = [];
        }
        else {
        console.log(res);
        this.pets = res;
        console.log(this.pets);
        
        }
        this.getMessages();
        
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

  deleteMinus(){
    console.log('joo')
    var anElm = document.getElementsByClassName('poista') as HTMLCollectionOf<HTMLElement>;
    console.log(anElm.length)
    for(var i = 0; i < anElm.length; i++){
     anElm[i].style.display = "none" ;
    }
    
  }

  getMessages(){

    console.log('tiällä')

    this.http.get(environment.FIND_CHATBOT_MESSAGES, this.httpOptions).subscribe(
    (res: any) => {
      this.messagesArr = res
      console.log(this.messagesArr,'on arr')
      console.log(this.pets.length)

      if(this.pets.length > 0 ){
        console.log('totta');
        console.log(this.messagesArr['message'].length)
        for(var i = 0; i < this.messagesArr['message'].length; i++ ){
          console.log(this.messagesArr['message'][i].template);
          if(this.messagesArr['message'][i].template == 2){
            this.properMessage = this.messagesArr['message'][i].message
            console.log(this.properMessage);
          }
        }
      }
      else{
        for(var i = 0; i < this.messagesArr['message'].length; i++ ){
          console.log(this.messagesArr['message'][i].template);
          if(this.messagesArr['message'][i].template == 1){
            this.properMessage = this.messagesArr['message'][i].message
            console.log(this.properMessage,'mitä v')
            
          }
        }

      }
      this.deleteMinus();
    }, (err: any) => {

      console.log("Error while login :: ", err);
    }
  )

      }
  logOut() {
    console.log("Request to logout");
    this.authService.doLogOut();
    this.router.navigate(['login']);
  }

  routeToChat(){
    this.router.navigate(['/home']);
  }

  openExpandBox(e){


    var elmID = e.target.id;
    var petID = elmID.substring(12)

    console.log(petID,'sdöaöl')

    var menu = document.getElementById(petID);
    console.log(menu);
    var icon = document.getElementById(elmID);
    var iconMinus = document.getElementById('close-icon-'+petID)
    menu.classList.add('ease-in');
    iconMinus.style.display="block";
    icon.style.display="none";

  }

closeExpandBox(e){
  var elmID = e.target.id;
  var petID = elmID.substring(11)

  
  console.log(petID, 'dslka')

  var iconMinus = document.getElementById(elmID);
  var icon = document.getElementById('expand-icon-'+petID);
  var menu = document.getElementById(petID);
  menu.classList.add('ease-out');
  menu.classList.remove('ease-in');
  iconMinus.style.display="none";
  icon.style.display="block";

}

}

