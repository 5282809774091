import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';

const helper = new JwtHelperService;

@Component({
  selector: 'app-vet-sms-validation',
  templateUrl: './vet-sms-validation.component.html',
  styleUrls: ['./vet-sms-validation.component.css']
})
export class VetSmsValidationComponent implements OnInit, AfterViewInit {

  authToken = localStorage.getItem('TEMP_DOC_AUTH');
  currentUserId;
  routeID: string = this.route.snapshot.params.id;
  clinicID: string = this.route.snapshot.params.clinicID;

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) {
      this.authService.validateClinic(this.clinicID)
     }

     httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}` }),
      withCredentials: true,
    };

  ngOnInit() {
    this.myId();
    this.validateSMStoken()
  }
  ngAfterViewInit(){
    this.setColors();
  }

  myId(){
    var token = localStorage.getItem('TEMP_DOC_AUTH');
    var decodedToken = helper.decodeToken(token);

    if(!token) {
      this.router.navigate(['/login'])
    }

    this.currentUserId = decodedToken.data.id;

  }

  setColors(){

    var req = {
      clinicID: this.clinicID,
    }
    console.log(this.clinicID);
    this.http.post(environment.CLINIC_VISUALS, req, this.httpOptions).subscribe(
      (res: any) => {
        
        if(res.status == 200){
          var str = JSON.stringify(res.message);

          localStorage.setItem('colors',str);

          this.colorService.styleUser();


        }
  
      }, (err: any) => {
        //this.logOut();
        console.log("Error validating user :: ", err);
      })
    
  }

  validateSMStoken(){

    var req = {
      token: this.routeID,
    }

    this.http.post(environment.VALIDATE_DOC_TEMP_AUTH_TOKEN, req, this.httpOptions).subscribe(
      (res: any) => {
      if(res.status == 200) {

      }
      else {
        localStorage.removeItem('TEMP_DOC_AUTH');
        this.router.navigate(['/'+this.clinicID+'/login']);
        alert('Authentication token is not valid. Returning back to login page.');

      }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  
  }

  setStatus(){

    var number = (<HTMLInputElement>document.getElementById('sms')).value

    var req = {
      num: number.toString(),
      ID:this.currentUserId
    }

    this.http.post(environment.DOC_VALIDATE_SMS, req, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res, 'Tarkasta!');
      if(res.status == 200) {
        localStorage.removeItem('TEMP_DOC_AUTH');
        this.authService.docAuth(res.token,this.clinicID,res.colors);

        console.log(res.token);
      }
      else {
        localStorage.removeItem('TEMP_DOC_AUTH');
        this.router.navigate(['/'+this.clinicID+'/login']);
        alert('Sms-code is incorrect. Returning back to login page.');
      }

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }


}
