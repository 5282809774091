import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeSpecies'
})
export class ChangeSpeciesPipe implements PipeTransform {

  transform(specie: any, args?: any): any {
    if(specie == "cat" || specie == "Cat") {
      return "Kissa";
    }
    else if(specie == "small animal" || specie == "small_animal" || specie == "Small Animal") {
      return "Pieneläin";
    }
    else if (specie == "dog" || specie == "Dog") {
      return "Koira"
    }
  }

}
