import { Pipe, PipeTransform } from '@angular/core';
import { retry } from 'rxjs/operators';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(itemsDiff: any[], searchTextFinal: string, searchNumber:any): any[] {
    if(!itemsDiff) return [];
    if(searchTextFinal == undefined) return  [];
    if(searchTextFinal.length <= 2) return [];
    if(itemsDiff.length > 2 && searchTextFinal == undefined) return [];
    if(searchTextFinal == "") return [];
    if(!searchTextFinal) return itemsDiff;
    searchTextFinal = searchTextFinal.toLowerCase();
    return itemsDiff.filter( it => {

      if(it.hashtag) {return it.hashtag.toLowerCase().includes(searchTextFinal) || it.text.toLowerCase().includes(searchTextFinal);}
    else if(it.term){return it.term.toLowerCase().includes(searchTextFinal)}
    });
    }

}
