<app-docheader></app-docheader>

<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Eläinlääkärirekisteri ({{doctors.length}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
  <br>

  <br>

    
      <h4>Eläinlääkäritiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th class="no-mobile" scope="col">Luotu</th>
              <th scope="col">Nimi</th>
              <th class="no-mobile" scope="col">Titteli</th>
              <th class="no-mobile" scope="col">Sähköposti</th>
              <th class="no-mobile" scope="col">Puhelin</th>
              <th  scope="col">Kirjautunut</th>
              <th scope="col">Tiedot</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doctor of doctors" >
              <td class="no-mobile"><strong>{{doctor.createdDate | pformateDate}}</strong></td>
              <td><a routerLink="/{{clinicID}}/vet/doctor-detail/{{doctor.userID}}">{{doctor.firstName}} {{doctor.lastName}}</a></td>
              <td class="no-mobile">{{doctor.designation}}</td>
              <td class="no-mobile">{{doctor.email}}</td>
              <td class="no-mobile">{{doctor.phone}}</td>
              <td><span *ngIf="doctor.loggedIn == true">Kyllä</span><span *ngIf="doctor.loggedIn == false">Ei</span></td>
              <td><button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/vet/doctor-detail/{{doctor.userID}}">Tiedot</button></td>

              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
            </tr>

          </tbody>
        </table>
      </div>


    </div>

