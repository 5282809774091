<app-admin-header></app-admin-header>

<div class="header-container-page pb-4">

  <h2 class=" pt-4 header-text-page">Maksurekisteri</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pb-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>

<div class="container">
  <button class="btn btn-veteva-secondary btn-veteva-edit btn-veteva-admin-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>

  <h5 class="mt-3">Valitse aikaväli:</h5>
  <div class="col-12 row mb-3">
    <div class="col-md-2 pe-0">
      <p class="mb-0">Alku</p>
      <input type="date" id="startDate" class="form-control"> 
    </div>
    <div class="col-md-1 p-0">
      <h3 class="mt-4 text-center"><strong>-</strong></h3>
    </div>
    <div class="col-md-2 ps-0">
      <p class="mb-0">Loppu</p>
      <input type="date" id="endDate" class="form-control"> 
    </div>
    <div class="col-md-3 ps-0">
      <p class="mb-0">Klinikka</p>
      <select class="form-control" id="clinicSelect">
        <option></option>
        <option *ngFor="let clinic of clinics" value="{{clinic.clinicID}}">{{clinic.clinicName}}</option>
      </select>
    </div>
    <div class="col-md-2 ps-0 mt-4">
      <button class="btn btn-veteva-secondary mt-1" (click)="fetchPaymentsByDate()"><i class="fas fa-search me-2"></i>Hae</button>
    </div>
  </div>
  <p class="mb-0">Onnistuneita: <strong>{{numSuccess}} kpl</strong></p>
  <p class="mb-0">Sending: <strong>{{numSending}} kpl</strong></p>
  <p >Epäonnistuneita: <strong>{{numFailed}} kpl</strong></p>
  <p >Myynnit yhteensä: <strong>{{totalSum}}€</strong></p>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Aika</th>
              <th scope="col">Tuote</th>
              <th class="no-mobile" scope="col">Status</th>
              <th class="no-mobile" scope="col">Maksutapa</th>
              <th class="no-mobile" scope="col">Alekoodi</th>
              <th class="no-mobile" scope="col">Klinikka</th>
              <th scope="col">Summa</th>
              <th scope="col">Tiedot</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payment of payments" id="payment-row-{{payment._id}}">
              <td> <strong><span *ngIf="payment.create_time">{{payment.create_time | pformateDate}}</span><span *ngIf="!payment.create_time">Ei leimattu</span></strong></td>
              <td>{{payment.itemPackage}}</td>
              <td class="no-mobile">{{payment.payment_status}}</td>
              <td class="no-mobile">{{payment.payment_method}}</td>
              <td class="no-mobile">{{payment.discountCode}}</td>
              <td class="no-mobile">{{payment.clinicID}}</td>
              <td>{{payment.total}}€</td>
              <td> <button class="btn btn-veteva-secondary btn-veteva-admin" (click)="openCheckModal(payment)">Tiedot</button></td>

              <!-- <td><button class="btn btn-success" id="{{package._id}}" (click)="deletePackage($event)">Poista</button></td> -->
            </tr>

          </tbody>
        </table>
      </div>

      <button id="open-modal" [hidden]="true"
      data-bs-toggle="modal" data-bs-target="#open-check-modal"></button>
        <div class="modal fade" id="open-check-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title header3" id="exampleModalLongTitle">Maksutiedot</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                </button>
              </div>
              <div class="modal-body">
                <p class="body1"> </p>
                <div class="input-wrap">
                  <div class="col-12">
                    <p class="mb-0"><strong>Asiakas:</strong> {{userData.firstName}} {{userData.lastName}}</p>
                    <p class="mb-0"><strong>Osoite:</strong> {{userData.addressStreet}}</p>
                    <p class=""><strong>Postitoimipaikka:</strong> {{userData.addressZip}} {{userData.addressCity}}</p>

                    <p class="mb-0"><strong>Tuote:</strong> {{currentDetails.itemPackage}}</p>
                    <p class="mb-0" *ngIf="currentDetails.discountPercent"><strong>Alennusprosentti:</strong> {{currentDetails.discountPercent}}%</p>
                    <p class="mb-0" *ngIf="currentDetails.discountCode"><strong>Alennuskoodi:</strong> {{currentDetails.discountCode}}</p>
                    <p class="mb-0" *ngIf="currentDetails.total"><strong>Lopullinen hinta:</strong> {{currentDetails.total}}</p>
                    <p class="mb-0" *ngIf="currentDetails.credit"><strong>Käyntejä:</strong> {{currentDetails.credit}}</p>
                    <p class="mb-0" *ngIf="currentDetails.payment_method"><strong>Maksutapa:</strong> {{currentDetails.payment_method}}</p>
                    <p class="" *ngIf="currentDetails.payment_status"><strong>Maksustatus:</strong> {{currentDetails.payment_status}}</p>

                    <p class="" *ngIf="currentDetails.productDesc"><strong>Tuotekuvaus:</strong><br> {{currentDetails.productDesc
                    }}</p>

                  </div>
                </div>
              </div>
              <div class="col-12 row pb-4">
                <div class="col-6">
                  <button class="btn btn-secondary" id="close-edit-credit" data-bs-dismiss="modal">Sulje</button>
                </div>
                <div class="col-6 text-center">
                </div>
              </div>
            </div>
          </div>
        </div>
</div>

        <ng-template #no_admin>
          <div class="alert alert-warning">
            <p class="text-center">Tämä sisältö on vain pääkäyttäjälle</p>
          </div>
        </ng-template>