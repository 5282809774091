<app-admin-header></app-admin-header>
<div class="header-container-page">

    <h2 class=" pt-4 header-text-page">Asetukset </h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
        <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'kayntisyyt')">Käyntisyyt</button>
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    <div class="row col-md-12 m-0">
      <div class="col-md-1 d-flex"></div>

        <div class="col-md-1 d-flex"></div>
      </div>
    
    </div>
<div class="container">
  <!-- KÄYNTISYYT -->
    <div style="display: block;" id="kayntisyyt" class="tabcontent ">
        <h2>Käyntisyyt</h2>
        <button class="btn btn-veteva-secondary mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#addReason"><i class="fas fa-plus-circle pe-2"></i>Lisää Käyntisyy</button>
        <table class="table table-striped">
            <thead class="veteva-table-header">
                <tr>
                <th scope="col">Käyntisyy</th>
                <th scope="col">Muokkaa</th>
                <th scope="col">Poista</th>
                </tr>
            </thead>
            <tbody>
            
                <tr *ngFor="let syy of reasonsArr">
                    <td><strong>{{syy.reason}}</strong></td>
                    <td><button class="btn btn-veteva-secondary" (click)="editReason(syy)"><i class="far fa-edit pe-2"></i>Muokkaa</button></td>
                    <td><button class="btn btn-veteva-secondary-delete" id="{{syy._id}}" (click)="deleteReason(syy._id)"><i class="far fa-trash-alt pe-2"></i>Poista</button></td>
            
                </tr>
            
            </tbody>
        </table>
    </div>
</div>

    <!-- MODAL LISÄÄ KÄYNTISYY -->
<div class="modal fade" id="addReason" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Lisää käyntisyy</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="messageErr">
            <p>{{messageErr}}</p>
          </div>
          <div class="alert alert-success" *ngIf="messageSuccess">
            <p>{{messageSuccess}}</p>
          </div>
    
          <form [formGroup]="reason"
        (ngSubmit)="addReason()">
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
  
            <div class="form-group">
                <label for="reason">Käyntisyy</label>
                    <div class="my-alerts"
                        *ngIf="reason.invalid && reason.touched">
                        <p *ngIf="reason.errors.required">Käyntisyy on pakollinen</p>
                    </div>
                    <input type="text"
                        placeholder="Lisää käyntisyy"
                        formControlName="reason"
                        id="reason"
                        class="form-control"
                        [class.invalid-input]="reason.errors && reason.touched"
                        required>
  
            </div>
  
            <button class="btn btn-veteva-secondary w-100 mt-4"
                [disabled]="reason.invalid"><i class="fas fa-plus-circle pe-2"></i>Lisää käyntisyy</button>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL LISÄÄ KÄYNTISYY -->
<button class="btn btn-veteva-secondary" data-bs-toggle="modal" id="edit-reason-btn" data-bs-target="#edit-reason-modal" style="display: none;" >Lisää Käyntisyy</button>
<div class="modal fade" id="edit-reason-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Muokkaa käyntisyytä</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
  
        <form [formGroup]="reasonForm"
      (ngSubmit)="editSelectedReason()">
      <div class="alert alert-danger" *ngIf="messageErr">
        <p>{{messageErr}}</p>
      </div>
      <div class="alert alert-success" *ngIf="messageSuccess">
        <p>{{messageSuccess}}</p>
      </div>


          <div class="form-group">
              <label for="e_reason">Käyntisyy</label>
                  <input type="text"
                      placeholder="Muokkaa käyntisyytä"
                      formControlName="e_reason"
                      class="form-control"
                      [class.invalid-input]="e_reason.errors && e_reason.touched"
                      required>

          </div>

          <button class="btn btn-veteva-secondary mt-4"
              [disabled]="reasonForm.invalid">Muokkaa käyntisyytä</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>
