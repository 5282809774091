<app-docheader></app-docheader>

<div class="header-container-page pb-4">

  <h2 class=" pt-4 header-text-page">Lemmikin {{petName}} tiedot:</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pb-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3 me-4" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>
  <button class="btn btn-veteva-secondary mt-3" data-bs-toggle="modal" data-bs-target="#select-pet-modal" type="button" ><i class="fas fa-plus-circle pe-2"></i>Lisää käynti</button>

    
    <h4>Omistajatiedot:</h4><br>
    <div class="table-container"> 
      <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Etunimi</th>
              <th scope="col">Sukunimi</th>
              <th scope="col">Email</th>
              <th scope="col">Puh</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngFor="let user of users" >
                <td ><strong>{{user.createdDate | pformateDate}}</strong></td>
                <td>{{user.firstName}}</td>
                <td>{{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{user.phone}}</td>

              </tr> 

          </tbody>
        </table>
      </div> 
      <h4>Lemmikkitiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Nimi</th>
              <th scope="col">Sukupuoli</th>
              <th scope="col">Syntymäpäivä</th>
              <th scope="col">Laji</th>
              <th scope="col">Rotu</th>
              <th scope="col">Mikrosiru</th>
              <th scope="col">Rekisterinumero</th>
                        </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pet of pets" >
              <td><strong>{{pet.createdDate | pformateDate}}</strong></td>
              <td>{{pet.name}}</td>
              <td>{{pet.sex | changeGender}}</td>
              <td>{{pet.birthDate | pformateDate}}</td>
              <td>{{pet.species }}</td>
              <td>{{pet.breed}}</td>
              <td>{{pet.microCard}}</td>
              <td>{{pet.registerNumber}}</td>
            </tr>

          </tbody>
        </table>
      </div>

      <h4>Käyntitiedot:</h4><br>
      <div class="table-container">
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <tr>
                <th scope="col">PVM</th>
                <th scope="col">Lääkäri</th>
                <th scope="col">Kutsumanimi</th>
                <th scope="col">Laji</th>
                <th scope="col">Rotu</th>
                <th scope="col">Omistaja</th>
                <th scope="col">Mikrosiru</th>
                <th scope="col">Puh</th>
                <th scope="col">Koko käynti</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let visit of visits" >
              <td ><strong>{{visit.createdDate | pformateDate}}</strong></td>
              <td><a routerLink="/{{clinicID}}/vet/doctor-detail/{{visit.doctorID}}">{{visit.doctorFullName}}</a></td>
              <td>{{visit.petName}}</td>
              <td>{{visit.petsSpecies }}</td>
              <td>{{visit.petBreed}}</td>
              <td><a routerLink="/{{clinicID}}/vet/user-detail/{{visit.owner}}">{{visit.firstName}} {{visit.lastName}}</a></td>
              <td>{{visit.petMicroCard}}</td>
              <td><a href="tel:{{visit.usersPhone}}">{{visit.usersPhone}}</a></td>
              <td><button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/vet/visit-detail/{{visit._id}}" >Käynti</button></td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <button id="breed-modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#select-pet-modal"></button>
    <div class="modal fade" id="select-pet-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-breed">
          <div class="modal-header">
            <h5 class="modal-title header3" id="exampleModalLongTitle">Valitse rotu</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              
            </button>
          </div>
          <div class="modal-body">
            <p class="body1">Valitse lemmikki jolle haluat käynnin</p>
    
            <div class="search-diff-container w-100">
              <table class="table table-striped">
                <tr class="veteva-table-header">
                  <td class="body3-w"><p class="body2-w mb-0">Nimi</p></td>
                  <td class="body3-w"><p class="body2-w mb-0">Rotu</p></td>
                  <td class="body3-w"><p class="body2-w mb-0">Valitse</p></td>
                </tr>
                <tr class="w-100 pointer" id="term-{{pet._id}}" *ngFor="let pet of pets">
                  <td><p class=" mb-0">{{pet.name}}</p></td>
                  <td><p class=" mb-0">{{pet.breed}}</p></td>
                  <td>
                    <button *ngIf="users.length > 0" class="btn btn-veteva-secondary mt-1"  type="button" data-bs-dismiss="modal" routerLink="/{{clinicID}}/add-visit/{{users[0]._id}}/{{pet._id}}"><i class="fas fa-plus-circle me-2"></i>Valitse</button>
                  </td>

                </tr>
              </table>
            </div>
          </div>
          <div class="col-12 row pb-4">
            <div class="col-6 text-center">
              <button class="btn btn-veteva-secondary-edit" style="display: none;" id="close-breed-modal" data-bs-dismiss="modal">Peruuta</button>
            </div>
            <div class="col-6 text-center">
              <button class="btn btn-veteva-secondary" style="display: none;" routerLink="/add-pet" data-bs-dismiss="modal">Lisää eläin</button>
            </div>
          </div>
        </div>
      </div>
    </div>