import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ColorServiceService } from '../service/color-service.service';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})
export class QuestionAnswerComponent implements OnInit, AfterViewInit {

  constructor(
    private colorService: ColorServiceService,
    private http: HttpClient,
  ) { }

  questions:any = [];
  messageErr;
  messageSuccess;
  authToken = localStorage.getItem('auth');
  vetq = [];
  paymentsq = [];
  messagesq = [];
  othersq = [];


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`}),
    withCredentials: true,
  };

  ngAfterViewInit(){
    this.colorService.styleUser();
  }

  ngOnInit(): void {
    this.fetchQuestions();
  }

  fetchQuestions(){

    this.http.get(environment.USER_QUESTIONS, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 200) {
          this.questions = res.message;

          for (let i = 0; i < res.message.length; i++) {
            const q = res.message[i];

            switch (q.category) {
              case 'vet':
                this.vetq.push(q);
              break;
              case 'payments':
                this.paymentsq.push(q);
              break;
              case 'messages':
                this.messagesq.push(q);
              break;
              case 'others':
                this.othersq.push(q);
              break;
            
              default:
                break;
            }
            
          }

          console.log(this.othersq)

          this.colorService.waitStyleColor();

        } else {
          this.messageErr = "Hakeminen epäonnistui"

        }
      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )
}

  openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;
  
    this.colorService.waitStyleColorUser();
  
    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  
  
    }

}
