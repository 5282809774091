
<!--Navbar-->
<nav id="main-nav" class="navbar navbar-expand-lg original-nav navbar-light pb-0 height">
  <div class="container-fluid">
  <!-- Navbar brand -->
  <a class="navbar-brand ps-md-4 me-auto pb-0 pt-0" routerLink="/home"><img width="25%" src="{{this.logo}}"> </a>

  <!-- Collapse button -->
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#basicExampleNav"
    aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line3"></span>
  </button>

  <!-- Collapsible content -->

  <div class="collapse navbar-collapse " id="basicExampleNav">

    <!-- Links -->
    <ul class="navbar-nav ms-auto">
      <li class="nav-item single-item p-2">
        <a class="nav-link" id="home" routerLink="/{{this.clinicID}}/home">Koti
        </a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" id="profile" routerLink="/{{clinicID}}/users/edit-details/{{this.currentUserId}}">Profiili</a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" id="visits" routerLink="/{{clinicID}}/user/visit-register">Käynnit</a>
      </li>
      <li class="nav-item single-item p-2">
        <a class="nav-link" id="q-a" routerLink="/{{this.clinicID}}question-answer">Q&A</a>
      </li>
      <li class="nav-item single-item p-2 ms-0 ms-md-4">
        <a class="nav-link ms-0 ms-md-4 logout-btn" id="logout-btn" (click)="logOutt()">Kirjaudu ulos</a>
      </li>

    </ul>
    <!-- Links -->

  </div>
  <!-- Collapsible content -->
  </div>
</nav>


