<app-not-auth-header></app-not-auth-header>
<div class="wrapperi">
  <div class="container h-75">
	<div class="wrap">
	<div class="d-flex flex-row justify-content-center">
		<div class="flex-wrap mt-4 text-center">
      <h4 class="header3 mt-4">Käyttäjän aktivointi</h4>
      
			<p class="activation-text body2b-b">Uuden salasanan aktivointilinkki tulee sähköpostiisi. 
        Uusithan salasanan yhden tunnin sisällä aktivoinnista tai aktivointilinkki erääntyy.</p>
      <div class="alert alert-danger" *ngIf="errMessage != '' ">
        <p>{{errMessage}}</p>
      </div>
      <div class="alert alert-success" *ngIf="successMessage != '' ">
        <p>{{successMessage}}</p>
      </div>
		</div>
  </div>

  <div style="display:none" class="doc-login" id="added">
    <div class="alert alert-success pt-3">
      <p class="body2b-b text-center">
        Käyttäjän aktivointi onnistui. Voit nyt kirjautua sisään.
      </p>
      <div class="text-center pb-4">
        <button class="btn btn-secondary" routerLink="/{{clinicID}}/login">Kirjaudu sisään</button>
      </div>
    </div>
  </div>

  <div style="display:none" class="doc-login" id="notadded">
    <div class="alert alert-warning pt-3">
      <p class="body2b-b text-center">
       Käyttäjän aktivointi epäonnistui. Käyttäjä voi olla aktivoituna tai käyttäjää ei ole rekisteröity. Suosittelemme kokeilla kirjautua sisään. Tämän jälkeen kokeile lähettää aktivoitilinkki
       uudelleen.
      </p>
      <div class="text-center pb-4">
        <button class="btn btn-secondary" routerLink="/{{clinicID}}/login">Kirjaudu sisään</button>
      </div>
    </div>
  </div>
 
	



    </div>
    </div>

    </div>