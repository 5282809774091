<app-docheader></app-docheader>
<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Lemmikkirekisteri ({{pets.length}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3 " type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button><br>


      <div class="table-container" *ngIf="pets.length > 0">
        <div class="col-12 row mb-2">
          <div class="col-10">
            <input placeholder="Kirjoita hakuehto tähän..." (keydown.enter)="searchPets()" (keyup)="showData()" type="search" class="form-control" id="pet-search">
    
          </div>
          <div class="col-2 text-end">
            <button (click)="searchPets()" class="btn btn-veteva-secondary">Hae</button>
          </div>
        </div>
        <table class="table table-striped">
          <thead class="veteva-table-header">
            <tr>
              <th scope="col">Luotu</th>
              <th scope="col">Nimi</th>
              <th class="no-mobile" scope="col">Sukupuoli</th>
              <th class="no-mobile" scope="col">Syntymäpäivä</th>
              <th scope="col">Laji</th>
              <th class="no-mobile" scope="col">Rotu</th>
              <th scope="col">Tiedot</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pet of pets" >
              <td><strong>{{pet.createdDate | pformateDate}}</strong></td>
              <td><a routerLink="/{{clinicID}}/vet/pet-detail/{{pet._id}}">{{pet.name}}</a></td>
              <td class="no-mobile">{{pet.sex | changeGender}}</td>
              <td class="no-mobile">{{pet.birthDate | pformateDate}}</td>
              <td>{{pet.species}}</td>
              <td class="no-mobile">{{pet.breed}}</td>
              <td><button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/vet/pet-detail/{{pet._id}}">Tiedot</button></td>
            </tr>

          </tbody>
        </table>
      </div>


    </div>

