<app-docheader></app-docheader>
<div class="header-page-container">

    <h2 class=" pt-4 header-text text-center">Lisää asiakas </h2>
    
    
    
    <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="tab col-md-10 d-flex justify-content-center">
        <div class="col-md-2 no-mobile"></div>
        <div class="col-md-2 no-mobile"></div>
      </div>
      <div class="col-md-1 d-flex"></div>
    </div>
    
    </div>

    <div class="col-12 row">
        <div class="col-md-3"></div>
        <div class="col-12 col-md-6">
            <div class="col-md-12 row m-0 p-0">
                <form class="w-100"[formGroup]="form"
                (ngSubmit)="onSubmit()">

                <div class="col-12 p-0 mt-4 ">

                  <!-- Etunimi -->
                  <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2 col-md-6 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Etunimi</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                        <input type="text" placeholder="Etunimi" formControlName="firstName" id="firstName"
                        class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
                        <div class="my-alerts"
                          *ngIf="firstName.invalid && firstName.touched">
                          <p *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Sukunimi -->
                  <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2 col-md-6 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Sukunimi</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                        <input type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName"
                        class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
                        <div class="my-alerts"
                          *ngIf="lastName.invalid && lastName.touched">
                          <p *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Sukunimi -->
                  <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2 col-md-6 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Katuosoite</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                        <input type="text" placeholder="Katuosoite" formControlName="addressStreet" id="addressStreet"
                        class="form-control" [class.invalid-input]="addressStreet.errors && addressStreet.touched" required>
                        <div class="my-alerts"
                          *ngIf="addressStreet.invalid && addressStreet.touched">
                          <p *ngIf="addressStreet.errors.required">Sukunimi on pakollinen</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Postinumerot -->
                  <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2 col-4 ps-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Postinumero</label>
                        <input type="text" placeholder="Postinumero" formControlName="addressZip" id="addressZip"
                        class="form-control" [class.invalid-input]="addressZip.errors && addressZip.touched" required>
                      </div>
                      <div class="form-group mb-2 col-8 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Postitoimipaikka</label>
                        <input type="text" placeholder="Postitoimipaikka" formControlName="addressCity" id="addressCity"
                        class="form-control" [class.invalid-input]="addressCity.errors && addressCity.touched" required>
                      </div>
                      <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                      </div>
                    </div>
                  </div>

                  <!-- Puhelinnumero -->
                  <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                      <div class="form-group mb-2 col-md-6 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Puhelinnumero</label>
                      </div>
                      <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                        <input type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone"
                        class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
                        <div class="my-alerts"
                          *ngIf="phone.invalid && phone.touched">
                          <p *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- Email -->
                <div class="form-group">
                    <div class="row col-12 m-0 p-0">
                        <div class="form-group mb-2 col-md-6 p-0">
                        <label type="text" readonly class="body2 m-0 p-0" id="staticEmail2" value="Nimi">Sähköpostiosoite</label>
                        </div>
                        <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
                        <input type="tel" placeholder="Sähköpostiosoite" formControlName="email" id="email"
                        class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                        <div class="my-alerts"
                            *ngIf="email.invalid && email.touched">
                            <p *ngIf="email.errors.required">Puhelinnumero on pakollinen</p>
                        </div>
                        </div>
                    </div>
                    </div>

                  <button [disabled]="form.invalid"  class="btn login-btn btn-veteva-secondary"
                  >Tallenna muutokset</button>
            </div>
          </form>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
