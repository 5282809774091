<app-docheader></app-docheader>

<div class="header-container-page pb-4">

  <h2 class=" pt-4 header-text-page">Kaikki käynnit ({{visits.length}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pb-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>
<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3 mb-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button>




  <div *ngIf="visits.length != 0; else no_visits_content">

    <div class="table-container">

      <div class="col-12 row mb-2">
        <div class="col-10">
          <input placeholder="Kirjoita hakuehto tähän..." (keydown.enter)="searchVisit()" (keyup)="showData()" type="search" class="form-control" id="visit-search">
  
        </div>
        <div class="col-2 text-end">
          <button (click)="searchVisit()" class="btn btn-veteva-secondary"><i class="fas fa-search me-2"></i>Hae</button>
        </div>
      </div>
      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr>
            <th scope="col">PVM</th>
            <th class="no-mobile" scope="col">Käyntisyy</th>
            <th scope="col">Kutsumanimi</th>
            <th scope="col">Laji</th>
            <th class="no-mobile" scope="col">Rotu</th>
            <th class="no-mobile" scope="col">Omistaja</th>
            <th scope="col">Yhteenveto</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let visit of visits" >
              <td><strong>{{visit.createdDate | pformateDate}}</strong></td>
              <td class="no-mobile">
                <span *ngIf="visit.anamnesis && visit.anamnesis.reason;else no_anamnesis">{{visit.anamnesis.reason}}
                </span>
                <ng-template #no_anamnesis>
                  <span>Ei leimattu</span>
                </ng-template>
              </td>
              <td ><a routerLink="/{{clinicID}}/vet/pet-detail/{{visit.petsID}}">{{visit.petName}}</a></td>
              <td>{{visit.petsSpecies}}</td>
              <td class="no-mobile">{{visit.petBreed}}</td>
              <td class="no-mobile"><a routerLink="/{{clinicID}}/vet/user-detail/{{visit.owner}}">{{visit.firstName}} {{visit.lastName}}</a></td>
              <td><button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/vet/visit-detail/{{visit._id}}">Käynti</button></td>
            </tr>

        </tbody>
      </table>
    </div>
    </div>
    <ng-template #no_visits_content>
      <div class=" col-12 alert alert-warning">
        <p class="text-center">Klinikalla ei ole käyntejä</p>
      </div>
    </ng-template>
    </div>
