<app-docheader></app-docheader>

<div class="header-page-container pb-4">

  <h2 class=" pt-4 header-text text-center pb-4">Asiakasrekisteri ({{usersLen}} kpl)</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="tab col-md-10 d-flex justify-content-center">
      <div class="col-md-2 no-mobile"></div>
      <div class="col-md-2 no-mobile"></div>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
</div>

<div class="container">
  <button class="btn btn-veteva-secondary-edit mt-3" type="button" onclick="history.back()"><i class="fas fa-arrow-left pe-2"></i>Takaisin</button><br>
  <button class="btn btn-veteva-secondary mt-3 mb-4" type="button" routerLink="/{{clinicID}}/add-user"><i class="fas fa-plus-circle pe-2"></i>Lisää uusi käyttäjä</button>

  

  <div class="table-container">
    <div class="col-12 row mb-2">
      <div class="col-10">
        <input placeholder="Kirjoita hakuehto tähän..." (keydown.enter)="searchUsers()" (keyup)="showData()" type="search" class="form-control" id="user-search">

      </div>
      <div class="col-2 text-end">
        <button (click)="searchUsers()" class="btn btn-veteva-secondary">Hae</button>
      </div>
    </div>
    <!-- <input type="date" class="form-control" id="user-date-fiter" ng-ngModel> -->

      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr class="w-100">
            <th scope="col">Luotu</th>
            <th scope="col">Etunimi</th>
            <th scope="col">Sukunimi</th>
            <th scope="col">Email</th>
            <th scope="col">Puh</th>
            <th scope="col">Kaikki Tiedot</th>
          </tr>
        </thead>
        <tbody>

           <tr *ngFor="let user of users" >
              <td><strong> {{user.createdDate | pformateDate}}</strong></td>
              <td>{{user.firstName}}</td>
              <td>{{user.lastName}}</td>
              <td>{{user.email}}</td>
              <td>{{user.phone}}</td>
              <td><button class="btn btn-veteva-secondary" routerLink="/{{clinicID}}/vet/user-detail/{{user._id}}"> Tiedot</button></td>
            </tr> 

        </tbody>
      </table>
    </div>
    </div>

