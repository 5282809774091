
<div class="container-fluid chat-window-container h-100">
  <app-header></app-header>
  <div class="row m-0 row-container">
    <!--Section one :: started-->
    <div class="col-md-8 col-lg-4 section-one color-white  p-0 section sidebar-sec-one sidenav" id="mySidenav">

        <div class="bg-white">
          <div class="inline-flex bg-white">
            <i class="fa fa-times fa-icon close-fa burger-left closeNav" (click)="closeNav()" aria-hidden="true"></i>
  
          </div>

          <div class="tab tab-container d-flex justify-content-center bg-white">
            <button class="tablinks koti active body1b-b">Chathistoria</button>
            <button class="tablinks body1b-b" routerLink="/{{clinicID}}/home">Koti</button>
          </div>
          </div>


          <p class="header3 text-center mt-4">Minun käyntini</p>
          <p class="body2 text-center">Valitse käyntisi vetolaatikosta</p>
          <div class="content-row profile-container">

            <div class="alert mx-auto alert-warning col-10" *ngIf="this.globalMessages.length == 0">
              <p class="text-center">Valitse käyntisi nähdäksesi viestit</p>
            </div>

            <div class="col-12 row m-0">
              <div class="col-1"></div>
              <div class="col-10">
                <select (change)="selectDate()" id="user-select" class="form-control col-10 mx-auto">
                  <option></option>
                  <option *ngFor="let visit of userVisits" value="{{visit._id}}">{{visit.createdDate | pformateDate}}, {{visit.petName}} </option>
                </select>
    
              <div class="col-md-10 mx-auto" *ngIf="noDocs == true">
                <div class="alert alert-warning">
                  <p class="text-center">Et ole vielä käynyt eläinlääkärin palvelussa. Haluatko eläinlääkäriin?</p>
                  <button class="btn btn-secondary mx-auto" routerLink="/home">Eläinlääkäriin</button>
                </div>
              </div>
              </div>
              <div class="col-1"></div>
            </div>


        <div class="profile-lists p-4 ms-4 me-4">

          <div *ngFor="let doctor of doctorList" id="{{doctor.name}}" class="full-wrap mb-2">
            <div>
            <li class="list-group-item list-group-item-link col-5 pt-xl-3 pe-lg-0 row d-inline-flex col-6 col-md-8 pe-lg-0">
              <div class="list-wrap-closed d-inline-flex">
                <div class="img-cont" *ngIf="doctor.image != null;else standard_image">
                  <img class="small-pic-pet" src="{{doctor.image}}" alt="lemmikkisi">
                </div>
                <ng-template #standard_image>
                  <img class="small-pic-pet" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                </ng-template>
                <div class="d-flex flex-column">
                  <div class="p-0">
                    <p  class="ms-3 mb-0 petname-text body1"> {{ doctor.fullName}}</p>
                  </div>
                </div>
              </div>
            
          </li>
          <div class="float-right pull-right col-6 col-md-4 mt-3 p-lg-0">
            <button class="btn pick-product-btn me-lg-4">Chat</button>
         </div>
        </div>
      </div>
        </div> 
      </div> 
    </div>
    <!--Section one :: END-->

    <!--Section two :: started-->
    <div class="col-md-12 col-lg-8 section-two color-white korkeus h-100 p-0 section main-container">
  
      <!-- header container : start-->
      <div class="header-container">
        <div class="row col-12 m-0">   
          <div class="col-1 col-md-0 me-md-4 p-0 mt-2">
            <i class="fa fa-bars fa-icon burger burger-left sidebar-burger" (click)="openNav()" aria-hidden="true"></i>
            <div class="avatar-icon-chat mt-1 sideBar-avatar">
              <div class="img-container" *ngIf="!(currentActiveDoctorName === DR_ASSISTANT_NAME ||currentActiveDoctorName === HELPER_NAME)">
                <img src="{{this.activeConsultantData['wantedImage']}}">
            </div>
            <div class="img-container" *ngIf="currentActiveDoctorName == DR_ASSISTANT_NAME">
              <img src="./../../assets/images/wellness_some.png">
            </div>
            <div class="img-container" *ngIf="currentActiveDoctorName == HELPER_NAME">
              <img src="./../../assets/images/wellness_some.png">
            </div>

            </div>
            </div>
            <div class="col-8 col-md-9 ms-4 ms-md-0">
              <div class="container-for-dr-name d-flex flex-column mt-2" *ngIf="currentActiveDoctorName !==  HELPER_NAME;else assistant_text_template ">
                <p *ngIf="this.activeConsultantData['firstName']" class="body1-w mb-0">Eläinlääkäri {{this.activeConsultantData['firstName']}}<span class="body3-b" *ngIf="globalMessages[currentActiveDoctorName]?.typing">  kirjoittaa...</span></p>
                <p class="body3-w mb-0">{{this.activeConsultantData['designation']}}</p>
              </div>
              <ng-template class="p-2" #assistant_text_template>
                <p class="body1-w mb-0 mt-2">
                  <span class="text-small-typing" *ngIf="globalMessages[currentActiveDoctorName]?.typing">kirjoittaa...</span>
                </p>
                <p class="body3-w">
                  <span *ngIf="currentActiveDoctorName == DR_ASSISTANT_NAME">Kyselen esitiedot</span>
                  </p>
              </ng-template>
            </div>
            <div class="col-3 col-md-2 p-0">
              <div class="col-12 row m-0 p-0">
                <div class="col-6 p-0">

                </div>
                <div class="col-6 p-0 text-end">
                  <i (click)="openNav()" class="fas fa-ellipsis-v burger burger-left sidebar-burger"></i>

                </div>
              </div>
            </div>

            </div>
        </div>

      <!-- header container : end-->


      <div id="chat-window" class=" chat-window-main-container w-100 col-md-12 ps-1 pe-1" [ngClass]="{'chat-window-main-container-no-replybox': !showReplyBox}">
  
  
  

        <div class="chat-messages-container pb-4 m-0" id="main-chat-container" #chatMessageContainer (scroll)="onScroll()">

          <div id="docs-helping" class="close-bubbles" *ngIf="activeDoctors.length != 0">
          <div class=" no-padding start-row starter-message ps-0" *ngIf=" this.currentActiveDoctorName == HELPER_NAME">
            <div class="row m-0">
              <div class="col-6 col-md-5 col-lg-4 col-xl-2 ms-2 mt-1 p-0">
              <p class = "body3b mb-1">Autan sinut alkuun</p>
              <div class="text-message text-message-left helper-message whitespace-preline">
                <p class="body2 m-0">Eläinlääkärimme <br> palvelemassa sinua: </p>
              </div>
              </div>
            </div>
          </div>
        </div>

          <div class=" no-padding start-row starter-message" *ngIf=" this.currentActiveDoctorName == HELPER_NAME">

          <div id="active-docs" class="close-bubbles" *ngIf="activeDoctors.length != 0; else no_doctors_content">
            <div class="" *ngFor="let activeDoctor of activeDoctors">

                <div class="row col-md-12 ps-0 m-0">
                  <div class=" col-6 col-md-5 col-lg-4 col-xl-2 p-0 ms-2 mt-1">
                  <div class="text-message text-message-left helper-message whitespace-preline">
                    <div class="profile-cont col p-0" *ngIf="activeDoctor.image != null;else standard_image">
                      <img class="profile-cont mt-1" src="{{activeDoctor.image}}" alt="{{activeDoctor.name}}">
                    </div>
                    <ng-template #standard_image>
                      <img class="small-pic-doc mt-1" src="../../assets/images/wellness_some.png" alt="{{activeDoctor.name}}">
                    </ng-template>
                    <p class="body2b-b mb-0 mt-2">{{activeDoctor.name}} </p>
                    <p class="body2">{{activeDoctor.designation}} </p>
  
                  </div>
                  </div>
                </div>
              </div>
          </div>
          <ng-template #no_doctors_content>
            <div class=" col-md-12 m-0 close-bubbles">
              <p class = "body3b mb-1">Autan sinut alkuun</p>
              <div class="row ps-0  ">
                <div class=" col-6 col-md-5 col-lg-4 col-xl-3 p-0 ms-2 mt-1">
                <div class="text-message text-message-left helper-message whitespace-preline">
                  <div class="alert alert-warning m-0">
                    <p class="body2b-b">Eikä! Yhtään eläinlääkäriä ei ole paikalla</p>
                  </div>

                </div>
                </div>
              </div>
            </div>
          </ng-template>
          </div>

 

              <div id="clinic-price" class="col-md-12 no-padding close-bubbles" *ngIf="this.currentActiveDoctorName == HELPER_NAME">
                <div class="row m-0">
                  <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">Autan sinut alkuun</p>
                    <div class="text-message text-message-left helper-message whitespace-preline">
                    <p class="body2" >Eläinlääkäripalvelun hinta nyt:</p>

                    <div >
                        <div class="col" *ngFor="let p of feePackages">
                          <div class="d-flex justify-content-between">
                              <p class=" body214bb  me-4 mb-1">{{p.name}}</p>
                              <p class=" mb-1  body214bb ">{{p.price}}€</p>
                          </div>
                          
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>


              <div id="start-create-pet" class="close-bubbles col-md-12 no-padding start-row" *ngIf=" this.currentActiveDoctorName == HELPER_NAME && this.pets.length <= 0;else has_pets ">
                  <div class="row m-0">
<!--                       <div class="p-0">
                      <div class="avatar-icon-chat mt-4">
                        <img src="./../../assets/images/wellness_some.png">
                        
                      </div>
                    </div> -->
                    <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                    <p class = "body3b mb-1">Autan sinut alkuun</p>
                    <div class="text-message text-message-left helper-message whitespace-preline">
                      <p class="body1">Aloittaaksesi sinun tulee lisätä lemmikki: </p>
  
                      <div class="">
                          <button class="btn btn-secondary" routerLink="/add-pet">Lisää lemmikki</button>
                        </div>
                    </div>
                      <br />
                    </div>
                  </div>
                </div>
          

          <ng-template #has_pets>


            <!-- ALKAA -->
            <div id="pet-added" class="col-md-12 no-padding close-bubbles" *ngIf="this.currentActiveDoctorName == HELPER_NAME">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/wellness_some.png">
                    
                  </div>
                </div> -->
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">Autan sinut alkuun</p>
                  <div class="text-message text-message-left helper-message whitespace-preline">
                    <p class="body2">Hienoa! Olet lisännyt itsellesi jo lemmikin! Pyydämme sinua täyttämään esitietokyselyn, jotta eläinlääkäri voi palvella teitä paremmin.</p>

                    <div class="">
                        <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#check-modal">Aloita chat</button>
                      </div>
                  </div>
                    <br />
                  </div>
                </div>
              </div>
            <!-- LOPPUU -->


          </ng-template>
          


          <div class="row m-0 message-container" *ngFor="let message of currentActiveMessageList">

            <!-- ALOITUS LÄÄKÄRIT -->


            <!-- ALOITUS AUKIOLO -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_101' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body2b mb-1">Eläinlääkärimme palvelemasssa sinua</p>
                  <div class="text-message text-message-left whitespace-preline">
                    <ul *ngIf="activeDoctors != []" class="list-group" id="menu">
                      <div class="message-button-group" *ngFor="let activeDoctor of activeDoctors">

                            <li class="list-group-item list-group-item-link pt-3">
                              <div class="list-wrap-closed d-inline-flex ">
                                <div class="img-cont col-1" *ngIf="activeDoctor.image != null;else standard_image">
                                  <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                </div>
                                <ng-template #standard_image>
                                  <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                                </ng-template>
                                <p class="body2b-b"> {{ activeDoctor.name }}</p>
                                <p class="body2"> {{ activeDoctor.designation }}</p>
                              </div>
                          </li>
                        </div>
                      </ul>
                    
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>

                <div class="col-sm-1 col-2 p-0">
                  <div class="avatar-icon-sm">
                    <img src="./../../assets/images/docter_color.png">
                  </div>
                </div>
              </div>
            </div>


            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_1' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2">Viestisi ovat luottamuksellisia</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>




            <div class="col-md-12 " *ngIf=" message.template == 'TEMPLATE_2' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
                    <div class="col-12 ps-0 pe-0 ps-md-2 pe-md-2">

                      <div id="discount-wrap" class="mb-3" >
                        <p class="body2 mb-1">Onko sinulla alekoodi?</p>
                        <div class="col-12 p-0 m-0 row">
                          <div class="col-12 col-md-8 p-0">
                            <input [disabled]="message.oldMessage" class="form-control" id="eelik-{{message.id}}">
                          </div>
                          <div class="col-12 col-md-4 p-0 text-start text-md-end">
                            <button [disabled]="message.oldMessage" class="btn btn-secondary mt-3 mt-md-0" >Lunasta</button>
                          </div>
                        </div>
                      </div>

                    
                    </div>

                    <p class="body2">Valitse konsultaatiovaihtoehto (klikkaa):</p>
                      <ul class="list-group" id="menu">
                        <div *ngFor="let p of feePackages" id="package-{{p._id}}" class="full-wrap mb-3 p-0 col-12 ">
                            <button [disabled]="message.oldMessage"  class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2">
                              <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                <div class="col-9 pe-3 ps-3">
                                  <p class="ms-2 body214bb text-start">{{p.name}}</p>
                                </div>
                                <div class="col-2 p-0">
<!--                                     <i class="fas fa-info-circle product-info-icon float-right expand-icon" (click)="openProductInfoModal($event,p)"></i> -->
                                  <div class="col-12 pe-0 ps-0">
                                    <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 " [ngClass]="{'selected-button':  message.selectedPackage?.id === p.id }">
                                      <div class="col-12">
                                      <p  class="body214bb text-end">{{p.price}}€</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
<!--                                 <div class="col-12 pe-0 ps-0">
                                <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 " [ngClass]="{'selected-button':  message.selectedPackage?.id === p.id }">
                                  <div class="col-12">
                                  <p *ngIf="!discountCode['discount_percent']" class="body2 text-end">{{p.price}}€</p>
                                  </div>
                                  <div class="col-12">
                                  <p *ngIf="discountCode['discount_percent']" class="body2 text-end"><span class="old-price">{{p.price}}€</span> {{(p.price * (1-discountCode.discount_percent)).toFixed(2)}}€</p>
                                </div>
                                </div>
                            </div> -->
                            
                            
                          </button>
                      </div>
                      </ul>
                      <ul class="list-group" id = "bambora-checkout-btn" *ngIf="message.oldMessage != true">
                        <div *ngFor="let method of bamboraPaymentMethods" class="full-wrap mb-3">
                            <button class="w-100 full-wrap list-group-item list-group-item-link pt-3 ps-1 pe-1" id="{{method.selected_value}}-{{message.id}}" >
                              <div class="list-wrap-closed d-inline-flex col-12 p-0">
                                <img src="{{method.img}}" class="icon-image"> 
                                <p class="body2 mt-md-2 body2">{{method.name}}</p>
                              </div>
                            
                          </button>
                        </div>
                      </ul>
                      
                  </div>
                  <div class="col-1 col-lg-5 col-xl-6"></div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                
              </div>
        
            </div>

            <!-- TEMPLATE 2 ANSWER OSTOKSEN YHTEENVETO -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_2' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-6 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-6 col-12">
                  <div class="d-flex justify-content-end">
                  <div class="text-message text-message-right me-2 pe-2">
                    <p class="body2-w">Ostokseni:</p>
                    <ul class="list-group p-list">
                    <li class="list-group-item  package-list-item list-group-item-link pt-3 col-12 ps-0 pe-4 ">
                      <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                        <div class="col-9 pe-3 ps-3">
                          <p class="ms-2 body214bb text-start">{{message.answer['name']}}</p>
                        </div>
                        <div class="col-2 ">
                          <div class="col-12 pe-0 ps-0">
                            <div  class="package-price row me-0 ms-0 ms-xl-4 me-xl-4 ">
                              <div class="col-12 p-0">
                              <p class="body214bb text-end">{{message.answer['price']}}€</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                  
                    
                       </li>
                    </ul>
                    </div>
                  </div>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>

              </div>
            </div>

<!--                 <div class="col-md-12 " (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_3' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="p-0">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div>

                <div class="col-10 col-md-5 p-0 m-2">
                  <p class = "doctor-name-text ms-2 pb-2"style="color: black;">{{this.currentActiveDoctorName}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body3"> {{message.actMessage | newlineHtml}}</p>
  
                  </div>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>
 -->

 

            <!-- TEMPLATE 4 ELÄIMEN VALITSEMINEN -->
            <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_21' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Millä lemmikillä haluat etäeläinlääkärille?</p>

                    <ul *ngIf="pets!= [];else no_pets" class="list-group" id="menu">
                      <div class="p-list" *ngFor="let pet of pets">
  
<!--                               <button [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,pet._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                            [ngClass]="{selectedButton: message.answer == pet._id }" class="list-group-item list-group-item-link ps-0 pe-0 ps-md-2 pe-md-2 pt-3 mb-2 active-doctors-wrap ">
                              <div class="list-wrap-closed d-inline-flex pe-0 ps-0 me-md-0 ps-md-0 pe-md-2 col-7 ">
                                <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                                  <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="lemmikkisi">
                                </div>
                                <ng-template #standard_image>
                                  <img class="small-pic-doc mt-1 me-1 ms-1 " src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                </ng-template>
                                <div class="d-flex flex-column p-0">
                                  <div class="doc-name pe-0 ps-1 ps-md-2 pe-md-2 col">
                                    <p class=" doc-show-text body1"> {{ pet.name }}</p>
                                  </div>
                                  <div class="doc-designation pe-0 ps-1 ps-md-2 pe-md-2 col">
                                    <p class=" doc-show-text body3">{{ pet.breed }}</p>
                                  </div>
                                </div>
                              </div>
                            </button> -->

                            <button [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,pet._id) && OnAnswerSelected(message) ; message.oldMessage = true"
                            [ngClass]="{selectedButton: message.answer == pet._id }" class="list-group-item package-list-item list-group-item-link pt-3 col-12 ps-0 pe-0 pe-md-2 mb-2">
                              <div class=" ps-0 pe-0 list-wrap-closed list-package-name row m-0  ">
                                <div class="col-12 pe-1 ps-1 row m-0">
                                  <div class="col-2 col-md-1 pe-0 ps-0 ps-md-2 pe-md-2">
                                    <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                                      <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="eläinlääkäri">
                                    </div>
                                    <ng-template #standard_image>
                                      <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                                    </ng-template>
                                  </div>
                                  <div class="col-9 ps-0 ps-md-2 text-start">
                                    <div class="d-flex flex-column">
                                      <div class="doc-name">
                                        <p class="ms-2 doc-show-text body214bb"> {{ pet.name }}</p>
                                      </div>
                                      <div class="doc-designation">
                                        <p class="ms-2 doc-show-text body2"> {{ pet.breed }}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
    
                              </div>
                            
                            
                          </button>
                        </div>
                      </ul>

                      
                      <ng-template #no_pets>
                        <div class="alert alert-danger">
                          <p>Sinun tulee lisätä lemmikki ennen kuin voit mennä eteenpäin</p>
                        </div>
                      </ng-template>
  
                  </div>
                  <p class="text-message-time-left mt-2 m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 4 ANSWER PET CHOISE -->
            <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_21' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="text-message text-message-right me-md-2">
                    <p class="body2-w">Valitsemani lemmikki:</p>
                    <div  *ngFor="let pet of pets">
                      <div *ngIf="pet._id == message.answer">
                        <li class="list-group-item list-group-item-link pt-3 mb-2 active-doctors-wrap ">
                          <div class="list-wrap-closed d-inline-flex ">
                            <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                              <img class="small-pic-doc mt-1" src="{{pet.image}}" alt="lemmikkisi">
                            </div>
                            <ng-template #standard_image>
                              <img class="small-pic-doc mt-1" src="../../assets/images/chat2Vet_some.png" alt="lemmikkisi">
                            </ng-template>
                            <div class="d-flex flex-column">
                              <div class="doc-name">
                                <p class="ms-2 doc-show-text body214bb"> {{ pet.name }}</p>
                              </div>
                              <div class="doc-designation">
                                <p class="ms-2 doc-show-text body2"> {{ pet.breed }}</p>
                              </div>
                            </div>
                          </div>
                      </li>
                      </div>
                    </div>

                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
              </div>
            </div>


            <!-- TEMPLATE 5 WEIGHT -->
            <div class="col-md-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_5' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body1">Syötä lemmikkisi paino / kg:</p>
                        <div class="list-wrap-closed weight-row p-0 ps-md-2 pe-md-2">
                            <div class="doc-name col-12 ps-0 ps-md-2 pe-md-2">
                              <input type="number" min="0" inputmode="decimal" pattern="[0-9]*" class="form-control weight-input pt-2 pb-2 w-100" placeholder="Kirjoita paino tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"><br>
                            </div>
                            <div class="col-4 p-0 ps-md-2 pe-md-2">
                              <button class="btn btn-secondary " [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valmis</button>
                            </div>
                        </div>   
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 5 ANSWER WEIGHT -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_5' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}} kg</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>

           
            <!-- TEMPLATE 6 REASON FOR VISIT -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_6' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Valitse listalta vaivaa parhaiten kuvaava vaihtoehto</p>
                    
<!--                         <input list="reasons-arr-data" required class="message-select p-2""> -->
                        <select  id="reasons-arr-data" class="p-2 mb-2 me-4 w-100"[(ngModel)]="message.answer" [disabled]="message.oldMessage">
                        <option disabled="true"></option> 
                        <option *ngFor="let syy of reasonsArr" >{{syy.reason}}</option>
                      </select> 

                    
                      <button class="btn btn-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valitse</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 6 ANSWER REASON FOR VISIT -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_6' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- TEMPLATE 7 BEFORE? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_7' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Onko vaivaa ollut aikaisemmin?</p>
                    
                    <div class="d-flex justify-content-between">
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Kyllä') && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei') && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                    </div>
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 7 ANSWER FOR BEFORE? -->
            <div class="col-md-12 p-0"  *ngIf=" message.template == 'TEMPLATE_7' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>

            <!-- TEMPLATE 8 WHEN STARTED -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_8'" [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="col-0 p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Koska vaiva alkoi?</p>
                    

                      <input type="date" min='1899-01-01' placeholder="dd-mm-yyyy" max='2000-13-13' id="birthdate" class="form-control eeli" [(ngModel)]="message.answer" [readonly]="message.oldMessage" required><br>
                    
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valitse</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE ANSWER 8 WHEN STARTED? -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_8' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w">{{message.answer | pformateDate}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                  </div>
                </div>

<!--                   <div class="p-0 d-flex align-items-end">
                  <div class="avatar-icon-chat">
                    <img src="./../../assets/images/chat2Vet_some.png">
                  </div>
                </div> -->
              </div>
            </div>


            <!-- TEMPLATE 9 TELL MORE -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_9' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">
                    <p class="body2">Kerro lisää vaivasta</p>
                    
                      <textarea type="text" class="form-control more-symptom-txt whitespace-preline" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                      <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Valmis</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            <!-- TEMPLATE ANSWER 9 TELL MORE -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_9' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="text-message text-message-right me-2">
                    <p class="body2-w break-word">{{message.answer}}</p>
                    <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>

              </div>
            </div>

             <!-- TEMPLATE 10 CRONICAL ILLS -->
             <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_10' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Onko lemmikilläsi kroonisia sairauksia?</p>
                    
                    <div class="d-flex justify-content-between">
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Kyllä') && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei') && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                    </div>
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p> 
                  </div>
              </div>
            </div>

            <!-- TEMPLATE 10 ANSWER CHRONICAL ILLS  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_10' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9">
                  <div class="d-flex justify-content-end">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- TEMPLATE 11 Lääkitys -->
            <div class="col-md-12 pb-2 no-padding"  *ngIf=" message.template == 'TEMPLATE_11' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
   
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
  
                    <p class="body2">Onko lemmikilläsi joku lääkitys?</p>                   
                    <textarea type="text" class="form-control more-symptom-txt" placeholder="Kirjoita tähän..." [(ngModel)]="message.answer" [readonly]="message.oldMessage"></textarea><br>
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,'Ei ole lääkitystä') && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == 'Ei ole lääkitystä' }">Ei ole lääkitystä</button><br><br>
                    <button class="btn btn-secondary" [disabled]="message.oldMessage" (click)="!message.oldMessage && OnAnswerSelected(message) ; message.oldMessage = true">Lähetä</button>
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>  
                  </div>
              </div>
            </div>
            
  
            <!-- TEMPLATE 11 ANSWER CHRONICAL ILLS  -->
            <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_11' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9">
                  <div class="d-flex justify-content-end">
                    <div class="text-message text-message-right me-2">
                      <p class="body2-w">{{message.answer}}</p>
                      <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <!-- TEMPLATE 12 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_12' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">
                    <p class="body3">{{message.actMessage | newlineHtml}}</p>
                      <br />
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
  
                  </div>
              </div>
            </div>


            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_13' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                        <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                      </div>
                      <ng-template #teksti_viesti>
                        <p class="body2-w mb-1 break-word">{{message.actMessage | newlineHtml}}</p>
                      </ng-template>
                      <p class="text-message-time text-end">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>
            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_33' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">

                      
                      <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>

            <div class="col-md-12 p-0 mb-2" *ngIf=" message.template == 'TEMPLATE_43' ">
              <div class="row m-0">
                <div class="col-md-7 col-3">
                  <p class="d-none">To take some space</p>
                </div>
                <div class="col-md-5 col-9 p-0">
                  <div class="d-flex justify-content-end p-0">
                    <div class="text-message text-message-right whitespace-preline me-2">
                      <video class="kuvaviesti" controls alt="">
                        <source src="{{fileURL}}/{{message.actMessage.key}}">  
                      </video>

                      
                      <p class="text-message-time text-end mb-1 mt-2">{{message.createdDate | chatTimeIndicator}}</p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 m-0 ">
            </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_14' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div *ngIf="message.actMessage.substring(0,10) == 'data:image';else teksti_viesti">
                      <img class="kuvaviesti" src="{{message.actMessage}}" alt="">
                  </div>
                  <ng-template #teksti_viesti>
                    <div [innerHtml]="message.actMessage" class="body2 break-word"></div>
                  </ng-template>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
            <div class="col-12 p-0 m-0 row ps-4">

              <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

            </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_34' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <div>
                      
                    </div>
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div >
                      <img class="kuvaviesti" src="{{fileURL}}/{{message.actMessage.key}}" alt="">
                  </div>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_44' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div >
                      <video class="kuvaviesti" controls alt="">
                        <source src="{{fileURL}}/{{message.actMessage.key}}">  
                      </video>
                  </div>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
            </div>

            <div class="col-12 no-padding" (scroll)="onScroll()" *ngIf=" message.template == 'TEMPLATE_54' ">
              <div class="col-md-12 p-0">
              <div class="row m-0">
                <div class="p-0 mt-4 col-0-nset">
                  <div class="avatar-icon-chat">
                    <img *ngIf="this.activeConsultantData.wantedImage && this.activeConsultantData.wantedImage.key" src="{{fileURL}}/{{this.activeConsultantData.wantedImage.key}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 mt-2 ms-2 me-2 mb-0">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="d-flex justify-content-start p-0">
                  <div class="text-message text-message-left-doctor whitespace-preline">
                    <div class="d-flex justify-content-center">
                      <i *ngIf="message.actMessage.mimetype == 'application/pdf'" class="fas fa-file-pdf att-icon pdf-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="fas fa-file-word att-icon word-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="fas fa-file-excel att-icon xls-icon"></i>
                      <i *ngIf="message.actMessage.mimetype == 'text/plain'" class="fas fa-file-alt att-icon icon-txt"></i>
                  </div>
                  <br>
                  <p class="body3-b">{{message.actMessage.originalname}}</p>
                  <div class="text-center">
                    <button class="btn btn-veteva-secondary" (click)="this.fileUploaderService.downloadAttachment(message.actMessage)"><i class="fas fa-download me-2"></i>Lataa</button>

                  </div>
                  <p class="text-message-time-left mt-2 ms-4 mb-0">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
                </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
              <div class="col-12 p-0 m-0 row ps-4">

                

              </div>
            </div>

<!--               <div class="col-12 no-padding" *ngIf="globalMessages[currentActiveDoctorName]?.typing">
              <div class="col-md-12 p-0">
              <div class="row m-0 ">
                <div class="p-0 mt-4">
                  <div class="avatar-icon-chat">
                    <img src="{{this.activeConsultantData['wantedImage']}}">
                    
                  </div>
                </div>

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">Eläinlääkäri {{this.activeConsultantData['firstName']}}</p>
                  <div class="text-message text-message-left-doctor whitespace-preline">

                    <div class="body1 break-word">Kirjottaa..</div>
                  </div>
                </div>
                <div class="col-1 col-lg-5 col-xl-6"></div>
              </div>
            </div>
            <div class="col-12 p-0 m-0 row ps-4">

              <p class="text-message-time-left mt-2 ms-4">{{message.createdDate | chatTimeIndicator}}</p>

            </div>
            </div> -->

              


            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_15' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2">Eläinlääkäri lopetti keskustelun</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>

            <div class="message-template-1 row col-12" *ngIf=" message.template == 'TEMPLATE_16' ">
              <div class="col-3"><hr class="hr-chat"></div>
              <div class="col-6"><p class="mt-1 mb-0 body2b-b">Eläinlääkäri siirsi sinut takaisin jonoon</p></div>
              <div class="col-3"><hr class="hr-chat"></div>
            </div>


            <!-- TEMPLATE 20 Credit usage? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_20' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <!-- <p class = "doctor-desigantion-text"style="color: black;">{{this.listUserRole}}</p> -->
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">{{message.actMessage}}</p>
                    
                    
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == true }">Käytetään maksettua käyntiä</button>

                    <button class="btn btn-secondary mt-3" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message, false) && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == false }">Haluan ostaa uuden käynnin</button>
  
                  </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

          <!-- TEMPLATE 20 ANSWER Which credit to use  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_20' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9">
                <div class="d-flex justify-content-end p-0">
                  <div class="text-message text-message-right me-2">
                    <div class="col p-0">
                  <div *ngIf="message.answer == true;else not_true">
                    <p class="body2-w">Käytetään maksettua käyntiä</p>
                  </div>
                  <ng-template #not_true>
                    <p class="body2-w">Haluan ostaa uuden käynnin</p>
                  </ng-template>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
                </div>
                </div>
              </div>

            </div>
          </div>

         <!-- TEMPLATE 23 Lemmikki kastroitu -->
         <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_23' " [ngClass]="{'disabled-input': message.oldMessage}">
          <div class="row m-0">
<!--                 <div class="p-0">
              <div class="avatar-icon-chat mt-4">
                <img src="./../../assets/images/chat2Vet_some.png">
                
              </div>
            </div> -->

            <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
              <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
              <div class="text-message text-message-left whitespace-preline">

                <p class="body2">Onko lemmikki kastroitu tai steriloitu?</p>
                
                <div class="d-flex justify-content-between">
                <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                [ngClass]="{selectedButton: message.answer == 'Kyllä' }">Kyllä</button>

                <button class="btn btn-secondary mt-0" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                  [ngClass]="{selectedButton: message.answer == 'Ei' }">Ei</button>
                </div>

              </div>
              <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>

              </div>
          </div>
        </div>

      <!-- TEMPLATE 21 ANSWER agreement  -->
      <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_23' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
        <div class="row m-0">
          <div class="col-md-7 col-3">
            <p class="d-none">To take some space</p>
          </div>
          <div class="col-md-5 col-9 p-0">
            <div class="d-flex justify-content-end p-0">
            <div class="text-message text-message-right me-2">
              <div *ngIf="message.answer == true;else not_true">
                <p class="body2-w">Kyllä</p>
              </div>
              <ng-template #not_true>
                <p class="body2-w">Ei</p>
              </ng-template>
              <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
            </div>
            </div>
          </div>

<!--               <div class="p-0 d-flex align-items-end">
            <div class="avatar-icon-chat">
              <img src="./../../assets/images/chat2Vet_some.png">
            </div>
          </div> -->
        </div>
      </div>

            <!-- TEMPLATE 21 Agreement with another doctor? -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_4' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->

                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left whitespace-preline">

                    <p class="body2">Olitteko sopineet ajasta eläinlääkärin kanssa?</p>
                    
                    
                    <button class="btn btn-secondary me-4" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,true) && OnAnswerSelected(message) ; message.oldMessage = true"
                    [ngClass]="{selectedButton: message.answer == true }">Kyllä olimme</button>

                    <button class="btn btn-secondary mt-2 mt-md-0" [disabled]="message.oldMessage" (click)="!message.oldMessage && setAnswer(message,false) && OnAnswerSelected(message) ; message.oldMessage = true"
                      [ngClass]="{selectedButton: message.answer == false }">Emme ole sopineet</button>
                    
  
                  </div>
                  <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>

                  </div>
              </div>
            </div>

          <!-- TEMPLATE 21 ANSWER agreement  -->
          <div class="col-md-12 p-0" *ngIf=" message.template == 'TEMPLATE_4' && message.answer != null && message.oldMessage == true  " [ngClass]="{'disabled-input': message.oldMessage}">
            <div class="row m-0">
              <div class="col-md-7 col-3">
                <p class="d-none">To take some space</p>
              </div>
              <div class="col-md-5 col-9 p-0">
                <div class="d-flex justify-content-end p-0">
                <div class="text-message text-message-right me-2">
                  <div *ngIf="message.answer == true;else not_true">
                    <p class="body2-w">Kyllä olimme</p>
                  </div>
                  <ng-template #not_true>
                    <p class="body2-w">Emme ole sopineet</p>
                  </ng-template>
                  <p class="text-message-time m-0">{{message.createdDate | chatTimeIndicator}}</p>
                </div>
              </div>
              </div>
            </div>
          </div>


            <!-- TEMPLATE 17 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left break-word w-100">
                    <p class="body2">Esitietokysely on nyt suoritettu. Alla yhteenveto vastaamistasi asoista.</p>
                    
                    <table class= "table-clinic-times">
                        
                      <tr scope="row" class="sumup-row pb-2">
                        <td class="clinic-bought-content body3 pt-2">Kastroitu</td>
                        <td class="clinic-bought-content-right pt-2 body3b"><span *ngIf="message.actMessage['cast']">Kyllä</span><span *ngIf="message.actMessage['cast'] == false">Ei</span></td>
                      </tr>
                      <tr class="sumup-row pb-2">
                        <td class="clinic-bought-content body3">Lemmikki</td>
                        <div *ngFor="let pet of pets">
                          <div *ngIf="pet._id == message.actMessage['pet']">
                            <td class="clinic-bought-content-right body3b">{{pet.name}}</td>
                          </div>
                        </div>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Paino</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['weight']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Käyntisyy</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['reason']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Vaivaa aikaisemmin</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['before']}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Vaiva alkoi</td>
                        <td class="clinic-bought-content-right body3b">{{message.actMessage['started'] | pformateDate}}</td>
                      </tr>
                      <tr class="sumup-row pb-4">
                        <td class="clinic-bought-content body3">Lisäkertomus</td>
                        <td class="clinic-bought-content-right body3b table-word-wrap break-word">{{message.actMessage['addInfo']}}</td>
                      </tr>
                      <tr class="sumup-row pb-2">
                        <td class="clinic-bought-content body3 pb-2">Krooniset sairaudet</td>
                        <td class="clinic-bought-content-right pb-2 body3b">{{message.actMessage['chronical']}}</td>
                      </tr>
                      <tr>
                        <td class="clinic-bought-content body3 pb-2">Lääkitys</td>
                        <td class="clinic-bought-content-right pb-2 body3b break-word">{{message.actMessage['medicine']}}</td>
                      </tr>
                    
                    </table>
                                          
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>
            <!-- TEMPLATE 17 Basic -->
            <div class="col-md-12 no-padding" *ngIf=" message.template == 'TEMPLATE_17' " [ngClass]="{'disabled-input': message.oldMessage}">
              <div class="row m-0">
<!--                   <div class="p-0">
                  <div class="avatar-icon-chat mt-4">
                    <img src="./../../assets/images/chat2Vet_some.png">
                    
                  </div>
                </div> -->
  
                <div class="col-10 col-md-6 col-lg-6 col-xl-5 p-0 m-2">
                  <p class = "body3b mb-1">{{this.activeConsultantData['firstName']}} {{this.activeConsultantData['lastName']}}</p>
                  <div class="text-message text-message-left break-word w-100">
                    <p class="body2">Eläinlääkäri ottaa sinut tutkimushuoneeseen tuota pikaa.</p>
                                          
                    </div>
                    <p class="text-message-time-left mt-2 ">{{message.createdDate | chatTimeIndicator}}</p>
                  </div>
              </div>
            </div>

            
          
          </div>
        </div>

        </div>






    </div>
    <!--Section two :: END-->

    <!--Section two :: started-->


</div>
