<app-not-auth-header></app-not-auth-header>


<div class="wrapperi">
	<div class="container h-75">
		<div class="wrap">

			<div class="col-12 m-0 row">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0 mt-4">
					<div class="col-12 text-center p-0">
						<h4 class="header-text mt-4">Tervetuloa etäeläinlääkäriin!</h4>
						<p class="desc-text">Kirjaudu sisään tunnuksillasi. <span class="desc-text-bold">Palveluun kirjautuminen sekä rekisteröityminen on ilmaista.</span></p>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="col-12 p-0 row m-0">
				<div class="col-3"></div>
				<div class="col-12 col-md-6 p-0">
					<div class="col-12 p-0 ">
						<div class="col-12 login-form-bg">
							<div class="alert alert-warning mt-3 mb-3" id="login-err" style="display: none;" >
								<p class="body2b-b">Käyttäjätunnus tai salasana on väärin</p>
							</div>
							<div class="alert alert-warning mt-3 mb-3" id="service-open" style="display: none;" >
								<p class="body2b-b text-center">Eikä! Joudut valitettavasti vielä hetken odottelemaan, sillä avaamme palvelun 1.2 klo 08:00. Nähdään silloin!</p>
							</div>
							<div class="alert alert-danger" id="lock-err" style="display: none;" >
								<p class="body2b-b">Käyttäjätili lukittu 15min ajaksi</p>
							</div>
							<div id="loader" class="loader-container mt-4 mb-4" style="display:none">
								<div class="loader mx-auto"></div>
								<p class="body2b-b text-center mt-4 mb-4">Pieni hetki kirjaudutaan sisään...</p>
							</div>
							<form class="flex-row " id="login-form" [formGroup]="loginFormi"
							(ngSubmit)="renderReCaptch()">
							<div class="col-12 row m-0 pl-0 pr-0">

								<div class="form-group mb-2 flex-column col-12 p-0">
									<input  class="form-control" formControlName="userName" type="email" id="userName" placeholder="Sähköpostiosoite">
								</div>
							</div>
							<div class="col-12 row m-0 pl-0 pr-0">

								<div class="form-group mb-2 flex-column col-12 p-0">
									<input  class="form-control" formControlName="password" autocomplete="on" type="password" placeholder="Salasana" id="password">
									</div>
							</div>
							<div class=" mx-auto mt-4">
								<div class="text-center" >
									<button #recaptcha [disabled]="loginFormi.invalid" class="btn btn-veteva-secondary"   >Kirjaudu</button>
								</div>
								<div class=" text-center mt-3">
									<a  class="veteva-main-link mb-0" routerLink="/{{this.clinicID}}/register" >Rekisteröidy</a>
								</div>
							</div>
						</form>
					
				</div>
					</div>
				</div>
				<div class="col-3"></div>

			</div>

			<div class="d-flex justify-content-center flex-row ">
				<div class="link-container">
					<p class="mt-4  ">Oletko unohtanut salasanasi? Uusi se 
						<a class="veteva-main-link" routerLink="/{{clinicID}}/forgot">tästä</a>
					</p>
					<p class="mt-2 ">Jatkamalla hyväksyt palvelun 
						<a class="veteva-main-link" target="_blank" href="https://veteva.vet/kayttoehdot">Käyttöehdot</a> 
						sekä 
						<a target="_blank" class="veteva-main-link" href="https://veteva.vet/tietosuojaseloste">tietosuojaselosteen</a>
					</p>
				</div>
			</div>
			</div>
		</div>
	</div>