<app-not-auth-header></app-not-auth-header>

<div class="col-12 mt-4" *ngIf="status == 'successfull'">
    <div class="col-12 text-center">
        <i class="fas fa-check-circle success-icon"></i>

    </div>
    <div class="col-12 p-4">
        <p class="body2b text-center">Maksusi onnistui. Voit sulkea tämän välilehden ja palata etäklinikalle.</p>
    </div>
</div>

<div class="col-12 mt-4" *ngIf="status == 'failed'">
    <div class="col-12 text-center">
        <i class="fas fa-times-circle failed-icon"></i>

    </div>
    <div class="col-12 p-4">
        <p class="body2b text-center">Maksusi epäonnistui. Ole hyvä ja yritä uudelleen. Joskus maksuissa saattaa ollaa viiveitä, jonka takia onnistunut maksu näkyy epäonnistuneena. Kysy eläinlääkäriltäsi lisätietoja.</p>
    </div>
</div>