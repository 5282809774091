<app-header></app-header>

<div class="container">
  <div class="d-flex flex-row justify-content-center">
    <div class="flex-wrap mt-4">
      <img src="../../assets/images/chat2VetLogoWeb.png" alt="chat2vet logo" width="350px">
  </div>
</div>  

  <div class="d-flex justify-content-center mt-4">
    <div class="col-md-6 w-100">
      <div class="mini-pet-container">
        <div class="mini-pet-header-container d-flex">
          <div class="mini-pet-wrap">
            <div class="avatar-icon-sm">
              <img class="avatar-icon-sm" src="../../assets/images/patient.png" alt="ukko">
            </div>
            <p class="mini-chat-header-text vet-p-blod">Minun lemmikkini</p>
          </div>
        </div>

        <div class="pet-window-container w-100">
          <ul *ngIf="pets != []" class="list-group" id="menu">
            <div *ngFor="let pet of pets" class="full-wrap">
                <li class="list-group-item list-group-item-link bg-light mt-2">
                  <div class="list-wrap-closed d-inline-flex ">
                    <div class="img-cont" *ngIf="pet.image != null;else standard_image">
                      <img class="small-pic-pet" src="{{pet.image}}" alt="lemmikkisi">
                    </div>
                    <ng-template #standard_image>
                      <img class="small-pic-pet" src="../../assets/images/docter_color.png" alt="lemmikkisi">
                    </ng-template>
                    <p routerLink="/pets/{{ pet._id }}" class="ml-2"> "{{ pet.name }}" {{pet.species}}</p>
                  </div>
                  <div class="icon-right pull-right">
                    <i class="fa fa-plus fa-2x float-right expand-icon" id="expand-icon-{{pet._id}}" (click)="openExpandBox($event)"></i>
                    <i class="fa fa-minus fa-2x float-right close-icon poista" id="close-icon-{{pet._id}}" (click)="closeExpandBox($event)"></i>
                 </div>
              </li>
              <ul class="list-group list-group-flush list" id="{{pet._id}}" >
                <li class="list-group-item list-group-item-link bg-light">
                 <div class="expanded-wrap d-inline-flex">
                  <i class="fa fa-2x fa-info-circle"></i>
                   <p routerLink="/pets/{{ pet._id }}" class="ml-2">Tiedot</p>
                 </div>
                </li>
                <li class="list-group-item list-group-item-link bg-light">
                  <div class="expanded-wrap d-inline-flex">
                    <i class="fa fa-2x fa-calendar" aria-hidden="true"></i>
                    <p routerLink="/user/pet-detail-register/{{pet._id}}" class="ml-2">Käynnit</p>
                  </div>
                 </li>
              </ul>
            </div>
          </ul>
        </div>
        <button class="btn btn-secondary" routerLink="/add-pet">Lisää lemmikki</button>
        <p class="mt-2">Voit lisätä itsellesi useamman lemmikin.</p>
      </div>
    </div>
  </div>
</div>