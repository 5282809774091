<app-docheader></app-docheader>

<div class="header-container-page">

  <h2 class=" pt-4 header-text-page">Hashtagit</h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
      <button class="tablinks koti active col-6 col-md-2 body1b-b " id="own-hashtags"  (click)="openCity($event, 'own')">Omat</button><span *ngIf="this.myHashtags.length > 0" class="notification-tab"><p class="margin-sm">{{this.myHashtags.length}}</p></span>
      <button class="tablinks col-6 col-md-2 body1b-b " id="clinics-hashtags" (click)="openCity($event,'clinics')">Klinikka</button><span *ngIf="this.clinicHashtags.length > 0" class="notification-tab mb-0 text-center"><p class="margin-sm">{{this.clinicHashtags.length}}</p></span>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
  
  </div>


<div class="container">
  <div class="" *ngIf="hashtagReady == false;else hashtag_ready">
    <div id="loader" class="loader-container mt-4" style="display: block;">
      <div class="loader mx-auto"></div>
      <p class="text-center">Hashtageja ladataan...</p>
      </div>
  </div>

  <ng-template #hashtag_ready>
    <button class="btn btn-veteva-secondary mt-3 mb-3" data-bs-toggle="modal" data-bs-target="#addhashtag"><i class="fas fa-plus-circle pe-2"></i>Lisää Hashtag</button>
    <div class="col-12 row mb-2">
      <div class="col-10">
        <input placeholder="Kirjoita hakuehto tähän..." (keydown.enter)="searchHashtag()" (keyup)="showData()" type="search" class="form-control" id="hashtag-search">
  
      </div>
      <div class="col-2 text-end">
        <button (click)="searchHashtag()" class="btn btn-veteva-secondary"><i class="fas fa-search me-2"></i>Hae</button>
      </div>
    </div>
  <div class="tabcontent" style="display: block;" id="own">
    <div *ngIf="myHashtags.length != 0;else no_hashtags">

    <table class="table table-striped">
      <thead class="veteva-table-header">
        <tr>
          <th scope="col">Hastag</th>
          <th scope="col">Tieto</th>
          <th scope="col">Muokkaa</th>
          <th scope="col">Poista</th>
        </tr>
      </thead>
      
      <tbody>

        <tr *ngFor="let it of myHashtags">
            <td class="text-column"><strong>{{it.hashtag}}</strong></td>
            <td class="p-column"><p class="brake body2b-b table-row">{{it.text}}</p></td>
            <td><button class="btn btn-veteva-secondary" (click)="openEditHashtagmodal(it)"><i class="fas fa-edit pe-2"></i>Muokkaa</button></td>
            <td><button class="btn btn-veteva-secondary-delete" (click)="removeHashtag(it._id)"><i class="fas fa-trash-alt"></i></button></td>
          </tr>
        
      </tbody>
    
    </table>
  </div> 
    <ng-template #no_hashtags>
      <div class="alert alert-warning mt-4">
        <p class="text-center">
          Et ole vielä lisännyt yhtään omaa hashtagiä
        </p>
      </div>
    </ng-template>
  </div>

  
  <div class="tabcontent" style="display: none;" id="clinics">
      <div *ngIf="clinicHashtags.length != 0;else no_clinic_hashtags">
  

      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr>
            <th scope="col">Hastag</th>
            <th scope="col">Tieto</th>
            <th *ngIf="isAdmin" scope="col">Muokkaa</th>
            <th *ngIf="isAdmin" scope="col">Poista</th>
          </tr>
        </thead>
        
        <tbody>
  
          <tr *ngFor="let it of clinicHashtags">
              <td class="text-column"><strong>{{it.hashtag}}</strong></td>
              <td class="p-column"><p class="brake body2b-b table-row">{{it.text}}</p></td>
              <td *ngIf="isAdmin" ><button class="btn btn-veteva-secondary" (click)="openEditHashtagmodal(it)"><i class="fas fa-edit pe-2"></i>Muokkaa</button></td>
              <td *ngIf="isAdmin"><button class="btn btn-veteva-secondary-delete" (click)="removeHashtag(it._id)"><i class="fas fa-trash-alt"></i></button></td>
            </tr>
          
        </tbody>
      
      </table>
    </div> 
      <ng-template #no_clinic_hashtags>
        <div class="alert alert-warning mt-4">
          <p class="text-center">
            Klinikkasi ei ole lisännyt yhtään hashtagia
          </p>
        </div>
      </ng-template>
  </div>
</ng-template>
</div>


  <!-- MODAL LISÄÄ KÄYNTISYY -->
<div class="modal fade" id="addhashtag" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Lisää hashtag</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
    
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="ErrMessage == true">
          <p class="text-center">{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="SuccessMessage == true">
          <p class="text-center">{{messageSuccess}}</p>
        </div>
  
      <form [formGroup]="hashtag"
      (ngSubmit)="addHashtag()">

          <div class="form-group">
              <label for="newHashtag">Hashtag</label>
                  <div class="my-alerts"
                      *ngIf="newHashtag.invalid && newHashtag.touched">
                      <p *ngIf="newHashtag.errors.required">Hashtag on pakollinen</p>
                  </div>
                  <input type="text"
                      placeholder="Lisää hashtag"
                      formControlName="newHashtag"
                      id="newHashtag"
                      class="form-control"
                      [class.invalid-input]="newHashtag.errors && newHashtag.touched"
                      required>

          </div>
          <div class="form-group">
            <label for="reason">Selite</label>
                <div class="my-alerts"
                    *ngIf="text.invalid">
                    <p *ngIf="text.errors.required">Selite on pakollinen</p>
                </div>
                <textarea type="text"
                    placeholder="Lisää selite"
                    formControlName="text"
                    id="text"
                    class="form-control"
                    [class.invalid-input]="text.errors"
                    required></textarea>

        </div>
        <div class="form-group" *ngIf="isAdmin">
          <label >Näkyvyys</label>
                  <select formControlName="forClinic" id="forClinic" class="label-text form-control">
                    <option value="true">Klinikalle</option>
                    <option value="false">Vain minulle</option>
            </select>
        </div>

          <button class="btn btn-veteva-secondary mt-4"
              [disabled]="hashtag.invalid"><i class="fas fa-plus-circle pe-2"></i>Lisää hashtag</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" id="close-add-hashtag" data-bs-dismiss="modal">Peruuta</button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-success" id="btn-edit" data-bs-toggle="modal" data-bs-target="#edit-hashtag" style="display: none;"></button>
<!-- MODAL LISÄÄ KÄYNTISYY -->
<div class="modal fade" id="edit-hashtag" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" id="exampleModalLabel">Muokkaa hashtagia</h3>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
  
      </button>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger text-center" *ngIf="ErrMessage == true">
        <p>{{messageErr}}</p>
      </div>
      <div class="alert alert-success text-center" *ngIf="SuccessMessage == true">
        <p>{{messageSuccess}}</p>
      </div>

    <form [formGroup]="editHashtag"
    (ngSubmit)="editHashtagFunc()">

        <div class="form-group">
            <label for="editableHashtagName">Hashtag</label>
                <div class="my-alerts"
                    *ngIf="editableHashtagName.invalid">
                    <p *ngIf="editableHashtagName.errors.required">Hashtag on pakollinen</p>
                </div>
                <input type="text"
                    placeholder="Lisää käyntisyy"
                    formControlName="editableHashtagName"
                    id="editableHashtagName"
                    class="form-control"
                    [class.invalid-input]="editableHashtagName.errors"
                    required>

        </div>
        <div class="form-group">
          <label for="reason">Selite</label>
              <div class="my-alerts"
                  *ngIf="editableHashtagText.invalid">
                  <p *ngIf="editableHashtagText.errors.required">Selite on pakollinen</p>
              </div>
              <textarea type="text"
                  placeholder="Lisää selite"
                  formControlName="editableHashtagText"
                  id="editableHashtagText"
                  class="form-control"
                  [class.invalid-input]="editableHashtagText.errors"
                  required>
              </textarea>
              <div class="form-group" *ngIf="isAdmin">
                <label >Näkyvyys</label>
                    <select formControlName="editForClinic"  class="label-text form-control">
                        <option value="true">Klinikalle</option>
                        <option value="false">Vain minulle</option>
                  </select>
              </div>

      </div>

        <button class="w-100 mt-4 btn btn-veteva-secondary"
            [disabled]="editHashtag.invalid">Muokkaa hashtagia</button>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" id="close-edit-hashtag" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Peruuta</button>
    </div>
  </div>
</div>

</div>
