import { AfterContentInit, Component, OnInit, } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


const helper = new JwtHelperService;


@Component({
  selector: 'app-admin-single-clinic',
  templateUrl: './admin-single-clinic.component.html',
  styleUrls: ['./admin-single-clinic.component.css']
})
export class AdminSingleClinicComponent implements OnInit {

  currentUserId='';
  form: FormGroup;
  payments: any[];
  userData: any = {};
  currentDetails: any = {};
  authToken = localStorage.getItem("auth");
  clinic:any = [];
  clinicsID: string = this.route.snapshot.params.clinicID;
  ID: string = this.route.snapshot.params.ID;
  messageErr:string ="";
  messageSuccess:string ="";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.fetchClinicDetails()

    this.form = this.fb.group({
      clinicName: ['',
      Validators.required],
      clinicID: ['',
      Validators.required],
      email: ['', [
        Validators.email, 
        Validators.required,
        Validators.maxLength(100)
      ]],
      phone: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],
      ovt: ['', 
      Validators.required],
      operator: ['', 
      Validators.required],
      mainColor: ['', 
      Validators.required],
      secondaryColor: ['', 
      Validators.required],
      logoUrl: ['', 
      Validators.required], })
  }

  get email() { return this.form.get('email') };
  get phone() { return this.form.get('phone') };
  get clinicName() { return this.form.get('clinicName') };
  get clinicID() { return this.form.get('clinicID') };
  get ovt() { return this.form.get('ovt') };
  get operator() { return this.form.get('operator') };
  get mainColor() { return this.form.get('mainColor') };
  get secondaryColor() { return this.form.get('secondaryColor') };
  get logoUrl() { return this.form.get('logoUrl') };



  fetchClinicDetails(){



    this.http.get(environment.FETCH_ADMIN_SINGLE_CLINIC+'/'+this.clinicsID, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200){
          
          var c  = res.message[0];
          console.log(this.clinic);
          this.form = this.fb.group({
            clinicName: [c.clinicName, Validators.required],
            phone: [c.phone, Validators.required],
            email: [c.email, Validators.required],
            ovt: [c.ovt, Validators.required],
            operator: [c.operator, Validators.required],
            logoUrl: [c.logoUrl, Validators.required],
            mainColor: [c.mainColor, Validators.required],
            secondaryColor: [c.secondaryColor, Validators.required],
            clinicID: [c.clinicID, Validators.required],
          })


        }
        else {
          this.clinic = [];
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })

  }
  
  onSubmit() {
    var req = {
      clinicName: this.form.get('clinicName').value,
      phone: this.form.get('phone').value,
      email: this.form.get('email').value,
      ovt: this.form.get('ovt').value,
      operator: this.form.get('operator').value,
      logoUrl: this.form.get('logoUrl').value,
      mainColor: this.form.get('mainColor').value,
      secondaryColor: this.form.get('secondaryColor').value,
    }
  

  this.http.put(environment.ADMIN_EDIT_CLINIC+'/'+this.clinicsID, req, this.httpOptions).subscribe(
    (res: any) => {
      console.log("Register success",res);
      if(res.status == 200 ){
        alert('Päivittäminen onnistui');
      }
      else {  
        alert('Päivittäminen epäonnistui')
      }

    }, (err: any) => {

      console.log("Error while login :: ", err);
    }
  )
  }
}
