import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;

@Component({
  selector: 'app-edit-user-details',
  templateUrl: './edit-user-details.component.html',
  styleUrls: ['./edit-user-details.component.css']
})
export class EditUserDetailsComponent implements OnInit,AfterViewInit {

  form: FormGroup;
  membership: FormGroup;
  currentUserId='';
  pets: any[];
  pet: '';
  authToken = localStorage.getItem('auth');
  formattedBday: string;
  successMessage = "";
  errorMessage = "";
  myOrganization="";
  paymentMsg= "";
  phoneErr = false;
  payments:any = [];
  visits:any = [];
  routeID: string = this.route.snapshot.params.id;
  allOrganizations:any = [];
  consentErr = false;
  editSuccess = false;
  clinicID: string = this.route.snapshot.params.clinicID;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) {
      
    }

  ngOnInit() {

    this.myId();
    this.fetchRegisterData();
    this.fetchPaymentData();


    this.membership = this.fb.group({
      organizations: ["", Validators.required],
      memberCode: ["", Validators.required],
    });

    this.form = this.fb.group({
      email: ["", Validators.required],
      phone: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      addressStreet: ["", Validators.required],
      addressCity: ["", Validators.required],
      addressZip: ["", Validators.required],
      organization: "",
    });

  }
  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    this.currentUserId= decodedToken.result.id;
    this.findUser();
    this.validateRoute();
  }

  ngAfterViewInit(){
    this.colorService.styleUser();
  }
  validateRoute(){
    if(this.routeID != this.currentUserId){
      this.router.navigate(['/home'])
    }
  }

  get email() { return this.form.get('email') };
  get phone() { return this.form.get('phone') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get addressStreet() { return this.form.get('addressStreet') };
  get addressCity() { return this.form.get('addressCity') };
  get addressZip() { return this.form.get('addressZip') };

  findUser(){


    this.http.get(environment.FIND_ONE_USER_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        res = res['data'][0];
        
        this.form = this.fb.group({
          email: [res.email, Validators.required],
          phone: [res.phone, Validators.required],
          firstName: [res.firstName, Validators.required],
          lastName: [res.lastName, Validators.required],
          addressStreet: [res.addressStreet, Validators.required],
          addressCity: [res.addressCity, Validators.required],
          addressZip: [res.addressZip, Validators.required],

        });

      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  }

  onSubmit(){
    this.checkMobileNumeber()
    if(!this.phoneErr) {

      document.getElementById('loader').style.display="block"; 
      document.getElementById('check-modal-data').classList.add('reg-form-success');

    var req = {
      email: this.form.get('email').value,
      phone: this.form.get('phone').value,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      addressCity: this.form.get('addressCity').value,
      addressStreet: this.form.get('addressStreet').value,
      addressZip: this.form.get('addressZip').value,
      userID: this.currentUserId
    }

    this.http.post(environment.EDIT_USER_END_POINT, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ){
          this.editSuccess = true;
          this.setClose();
          document.getElementById('loader').style.display="none";
          document.getElementById('close-edit-modal').click();
          document.getElementById('check-modal-data').classList.remove('reg-form-success');
          this.findUser();

        }
        else if(res.status == 203){
          //Avaa consent
          var elm = document.getElementById('close-edit-modal');
          document.getElementById('loader').style.display="none";
          document.getElementById('check-modal-data').classList.remove('reg-form-success');
          elm.click();

          this.openConsent();
        }
        else if(res.status == 204 ){
          var elm = document.getElementById('close-edit-modal');
          document.getElementById('loader').style.display="none";
          document.getElementById('check-modal-data').classList.remove('reg-form-success');
          elm.click();
          this.errorMessage = "Puhelinnumero on jo rekisteröity"
          
          window.scrollTo(0,0);
        }
        else {
          var elm = document.getElementById('close-edit-modal');
          document.getElementById('loader').style.display="none";
          document.getElementById('check-modal-data').classList.remove('reg-form-success');
          elm.click();

          this.errorMessage = res.message;

          window.scrollTo(0,0);
        }

      }, (err: any) => {

        console.log("Error while login :: ", err);
      }
    )
    }
    else {
      window.scrollTo(0,0);

    }

  }

  setClose(){
    var that = this;
    setTimeout(function () {
      that.editSuccess = false;
    }, 4000);
  }
  openConsent(){
    document.getElementById('consentModal').click();
  }

  forgotPassword(){

    this.router.navigate(['/forgot'])
    this.authService.doLogOut();

  }

/*   fetchAllOrganizations(){
    this.http.get(environment.FETCH_ALL_ORGANIZATIONS, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ){
          this.allOrganizations = res.message;

        }
        else {
          this.successMessage = "";
          this.errorMessage = "";
          this.errorMessage = res.message
        }

      }, (err: any) => {

      }
    )
  } */


/*   get organizations() { return this.membership.get('organizations') };
  get memberCode() { return this.membership.get('memberCode') };
  redeemMembership(){

    var req = {
      organizationName:this.membership.get('organizations').value,
      memberCode:this.membership.get('memberCode').value
    }

    this.http.post(environment.REDEEM_ORGANIZATION_MEMBERSHIP, req, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200 ){
          this.successMessage = "";
          this.errorMessage = "";
          this.successMessage = res.message;

        }
        else {
          this.successMessage = "";
          this.errorMessage = "";
          this.errorMessage = res.message
        }

      }, (err: any) => {

      }
    )}
 */
/*     fetchMyOrganization(){

      this.http.get(environment.FETCH_MY_ORGANIZATION, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200 ){
            this.myOrganization = res;
            if(this.myOrganization.length == 0){
              var elm = document.getElementById('not-member');
              elm.style.display="block";
            }
            else {

            }
  
          }
          else {
            this.successMessage = "";
            this.errorMessage = "";
            this.errorMessage = res.message
          }
  
        }, (err: any) => {
  
        }
      )} */

      openCity(evt, cityName) {
        // Declare all variables
        var i, tabcontent, tablinks;
      
      
        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
      
        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
      
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className += " active";
      
        this.colorService.styleUser();
        }
        fetchRegisterData(){

          var req = {
            userID: this.currentUserId
          }
      
          this.http.post(environment.FETCH_USER_VISITS_END_POINT, req, this.httpOptions).subscribe(
            (res: any) => {
              if(res.status == 200){
                this.visits = res.message;
                console.log(this.visits);

                this.visits = this.visits.sort(function(a,b){
                  return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
                });


              }

            }, (err: any) => {
          
              console.log("Error while login :: ", err);
            })
        }
        fetchPaymentData(){

          var req = {
            userID: this.routeID
          }
      
          this.http.post(environment.FETCH_MY_PAYMENTDATA, req, this.httpOptions).subscribe(
            (res: any) => {
              if(res.status == 200){
                var paymentArr = res.message;

                //OSTOT AIKAJÄRJESTYKSEEN
                paymentArr = paymentArr.sort(function(a,b){
                  return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
                });


                for (let i = 0; i < paymentArr.length; i++) {
                  const e = paymentArr[i];
                  
                  if(e.payment_status != "sending"){
                    this.payments.push(e);
                  }
                }

              }
              else {
                this.paymentMsg = res.message;
              }

            }, (err: any) => {
          
              console.log("Error while login :: ", err);
            })
    }

    checkMobileNumeber() {
      var phone = this.form.get('phone').value;

      if(phone[0] != "+"){
        this.phoneErr = true;
      }

      else if(phone.length < 5) {
        this.phoneErr = true;
      }

      else if(phone.length > 17) {
        this.phoneErr = true;
      }
      else if(phone.substring(0,5) == "+3580"){
          this.phoneErr = true;
      }
      else {
        this.phoneErr = false;
      }
    }





}
