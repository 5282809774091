import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { FileUploaderService } from '../service/file-uploader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


const helper = new JwtHelperService;

@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['./doctor-detail.component.css']
})
export class DoctorDetailComponent implements OnInit, AfterViewInit {

  currentUserId='';
  doctorID: string = this.route.snapshot.params.id;
  doctor: any = {};
  pets: any[];
  visits: any = [];
  date="";
  diagnosis="";
  petsName="";
  petName="";
  petsOwnerId="";
  doctorFirstName = "";
  doctorLastName = "";
  authToken = localStorage.getItem("auth");
  isAdmin = false;
  clinicID = this.authService.getTokenData().clinicID;
  form: FormGroup;
  attachmentObj;
  imageRoute = environment.FETCH_ATTACHMENT;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  //saas

  get email() { return this.form.get('email') };
  get firstName() { return this.form.get('firstName') };
  get lastName() { return this.form.get('lastName') };
  get designation() { return this.form.get('designation') };
  get phone() { return this.form.get('phone') };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private colorService: ColorServiceService,
    private fileUploaderService: FileUploaderService,
    private authService: AuthService) { }

    ngOnInit() {
      this.myId();

      this.form = this.fb.group({
        email: ['', [
          Validators.required
        ]],
        phone: ['', [
          Validators.required
        ]],
  
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        designation: ['', Validators.required],
  
      });
    }

    ngAfterViewInit(){
      this.colorService.setVetColors();
    }
  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      this.currentUserId = decodedToken.result.id;
      this.isAdmin = decodedToken.result.isAdmin;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      if(decodedToken.result.designation == 'patient'){
        this.authService.doLogOut();
        
  
      }
      
      this.fetchDoctorData()
      this.fetchRegisterDataForDoc()
    }

    onSubmit(){
      const req = {
        doctorID: this.doctorID,
        email: this.form.get('email').value,
        userName: this.form.get('email').value,
        firstName: this.form.get('firstName').value,
        lastName: this.form.get('lastName').value,
        designation: this.form.get('designation').value,
        phone: this.form.get('phone').value,
      }

      this.http.post(environment.UPDATE_DOCTOR,req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            alert('Tietojen päivittäminen onnistui');
            return;
          }
          else{
            alert('Tietojen päivittämien epäonnistui syy: '+res.message);
            return;
          }
 

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })


    }
  

  
     fetchDoctorData(){
  

      this.http.get(environment.DOC_PMS_DOCTOR_DATA_END_POINT, this.httpOptions).subscribe(
        (res: any) => {
          console.log(res);
          this.doctor = res.message[0];
          console.log(this.doctor);
          var d = res.message[0];

          this.form = this.fb.group({
            email: [d.email, Validators.required],
            firstName: [d.firstName, Validators.required],
            lastName: [d.lastName, Validators.required],
            designation: [d.designation, Validators.required],
            phone: [d.phone, Validators.required],
          })
          
          this.doctorFirstName = res.message[0].firstName
          this.doctorLastName = res.message[0].lastName

        }, (err: any) => {
      
          console.log("Error while login :: ", err);
        })
    } 

    
    fetchRegisterDataForDoc(){

    this.http.get(environment.FETCH_VISIT_FOR_DOCTOR_END_POINT, this.httpOptions).subscribe(
      (res: any) => {
        console.log(res);
        if(res.status == 200) {
          this.visits = res.message;
          this.visits = this.visits.sort(function(a,b){
            return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          });

          this.colorService.waitStyleColor()

        }
        else if(res.status == 201){
          console.log('Ei käyntejä');
        }
        else {
          console.log("muu virhe");
        }
      }, (err: any) => {
    
        console.log("Error while login :: ", err);
      })
  } 

  

  renewPassword(){

    if(this.isAdmin){
  
    var req = {
      doctorID:this.doctorID
    }

    this.http.post(environment.RENEW_DOCTOR_PASSWORD, req,this.httpOptions).subscribe(
      (res: any) => {
        if(res.status == 200) {
        alert('Salasanan uusiminen onnistui!');
        document.getElementById('close-renew-password').click();

        }
        else{
          alert('Salasanan usimisessa ongelma');
        }


      }, (err: any) => {
    
        console.log("Error while login :: ", err);

      })
    }
  } 

  openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;
  
  
    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  
  
    }

    addProfilePic(){

      this.fileUploaderService.uploadImage(this.attachmentObj,this.currentUserId, true,(err,result) => {
      
        if(result == false){

        }
        else{
          Object.assign(result,{doctorID: this.doctorID});
          var req = result;
          this.http.put(environment.DOCTOR_PROFILE_IMAGE, req,this.httpOptions).subscribe(
            (res: any) => {
              if(res.status == 200) {
              alert('Kuvan päivittäminen onnistui');
              this.fetchDoctorData();
              }
              else{
                alert('Kuvan lisäämisessä tapahtui virhe');
              }
      
      
            }, (err: any) => {
          
              console.log("Error while login :: ", err);
      
            })
        }
      })
    }

    gotFile(event: Event): void {
      if(this.doctorID == this.currentUserId || this.isAdmin){
      const attachment = (event.target as HTMLInputElement).files[0];
      this.attachmentObj = attachment;
      this.addProfilePic();
      console.log(attachment);
      }
     }

     changePicture(event: Event): void {
      if(this.doctorID == this.currentUserId || this.isAdmin){
      const attachment = (event.target as HTMLInputElement).files[0];
      this.attachmentObj = attachment;

      if(this.attachmentObj.key){
      this.fileUploaderService.deleteFile(this.doctor.image,(err,result) => {
        if(result == true){
          this.addProfilePic();
        }
        else{
          alert('Kuvan poistamisessa ilmeni virhe');
          return;
        }
      })
      }
      else{
        this.addProfilePic();
      }
    }
      else{
        alert('Sinulla ei ole oikeuksia muutaa eläinlääkärin tietoja.')
      }
     }

     checkMobileNumeber() {
      var phone = this.form.get('phone').value;
      var phoneErr;
      if(phone[0] != "+"){
        phoneErr = true;
      }

      else if(phone.length < 5) {
        phoneErr = true;
      }

      else if(phone.length > 17) {
        phoneErr = true;
      }
      else if(phone.substring(0,5) == "+3580"){
          phoneErr = true;
      }
      else {
        phoneErr = false;
      }

      if(phoneErr){
        alert('Puhelinnumerossa vaaditaan +358 alku');
        return;
      }

      this.onSubmit();
    }






}
