<app-docheader></app-docheader>
<div *ngIf="isAdmin" class="header-container-page">

  <h2 class=" pt-4 header-text-page">Asetukset </h2>
  
  
  
  <div class="row col-md-12 m-0">
  <div class="col-md-1 d-flex"></div>
  <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
      <button class="tablinks koti active col-6 col-md-2 body1b-b "  (click)="openCity($event, 'aukiolo')">Aukiolo</button>
      <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'codes')">Alennuskoodit</button>
      <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'tuotepaketit')">Tuotepaketit</button>
      <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'kayntisyyt')">Käyntisyyt</button>
      <button class="tablinks col-6 col-md-2 body1b-b " (click)="openCity($event,'clinic')">Klinikan asetukset</button>
      <button class="tablinks koti col-6 col-md-2 body1b-b "  (click)="openCity($event, 'questions')">Kysymykset</button>
    </div>
    <div class="col-md-1 d-flex"></div>
  </div>
<!--   <div class="row col-md-12 m-0">
    <div class="col-md-1 d-flex"></div>
    <div class="pl-4 pl-md-0 pr-4 pr-md-0 col-12 w-100 col-md-12 text-center  d-inline-flex tab tab-section  pb-2 mobile-scroll">
        <button class="tablinks koti col-6 col-md-2 body1b-b "  (click)="openCity($event, 'questions')">Kysymykset</button>
      </div>
      <div class="col-md-1 d-flex"></div>
    </div> -->
  
  </div>

<div class="container" *ngIf="isAdmin; else no_admin">



<br>

<div style="display: block;" id="aukiolo" class="tabcontent">

<form [formGroup]="form"
  (ngSubmit)="editTimes()">

  <h2>Aukiolo</h2>
  <section id="alerts">
    <div class="alert alert-danger" *ngIf="messageErr">
      <p>{{messageErr}}</p>
    </div>
    <div class="alert alert-success" *ngIf="messageSuccess">
      <p>{{messageSuccess}}</p>
    </div>
  </section>


  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Maanantai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="monStart" id="mon" class="form-control" [class.invalid-input]="monStart.errors && monStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="monEnd" id="mon" class="form-control" [class.invalid-input]="monEnd.errors && monEnd.touched" required>

      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Tiistai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="tueStart" id="mon" class="form-control" [class.invalid-input]="tueStart.errors && tueStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="tueEnd" id="mon" class="form-control" [class.invalid-input]="tueEnd.errors && tueEnd.touched" required>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Keskiviikko</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="wedStart" id="mon" class="form-control" [class.invalid-input]="wedStart.errors && wedStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="wedEnd" id="mon" class="form-control" [class.invalid-input]="wedEnd.errors && wedEnd.touched" required>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Torstai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="thuStart" id="mon" class="form-control" [class.invalid-input]="thuStart.errors && thuStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="thuEnd" id="mon" class="form-control" [class.invalid-input]="thuEnd.errors && thuEnd.touched" required>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Perjantai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="friStart" id="mon" class="form-control" [class.invalid-input]="friStart.errors && friStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="friEnd" id="mon" class="form-control" [class.invalid-input]="friEnd.errors && friEnd.touched" required>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Lauantai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="satStart" id="mon" class="form-control" [class.invalid-input]="satStart.errors && satStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="satEnd" id="mon" class="form-control" [class.invalid-input]="satEnd.errors && satEnd.touched" required>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="col-12">
      <div class="col-6">
        <h3 class="text-start">Sunnuntai</h3>
      </div>
    </div>
    <div class="col-12 row">
      <div class="col-6">
        <p class="text-start m-0">Alku klo</p>
        <input type="time" formControlName="sunStart" id="mon" class="form-control" [class.invalid-input]="sunStart.errors && sunStart.touched" required>
      </div>
      <div class="col-6">
        <p class="text-start m-0">Loppu klo</p>
        <input type="time" formControlName="sunEnd" id="mon" class="form-control" [class.invalid-input]="sunEnd.errors && sunEnd.touched" required>
      </div>
    </div>
  </div>
  
  <button class="btn btn-veteva-secondary mt-4 mb-4"
      [disabled]="form.invalid"><i class="far fa-edit pe-2"></i>Päivitä ajat</button>
</form>
</div>

<div id="codes" class="tabcontent ">

<h2>Alennuskoodit</h2>

<button class="btn btn-veteva-secondary mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#addCode"><i class="fas fa-plus-circle pe-2"></i>Lisää alekoodi</button>

<table class="table table-striped">
  <thead class="veteva-table-header">
    <tr>
      <th scope="col">Alennuskoodi</th>
      <th scope="col">Alennus / %</th>
      <th scope="col">Käytetty / kpl</th>
      <th scope="col">Max. käyttömäärä / kpl</th>
      <th scope="col">Voimassa</th>
      <th scope="col">Muokkaa</th>
      <th scope="col">Poista</th>
    </tr>
  </thead>
  <tbody>

    <tr id="discount-{{discount._id}}" class="e " *ngFor="let discount of codesArr">
        <td><strong>{{discount.code}}</strong></td>
        <td>{{discount.discount_percent * 100}}</td>
        <td>{{discount.times_used}}</td>
        <td>{{discount.max_usage}}</td>
        <td>{{discount.valid_until | pformateDate}}</td>
        <td><button class="btn btn-veteva-secondary" (click)="editDiscountCode(discount)"><i class="far fa-edit pe-2"></i>Muokkaa</button></td>
        <td><button class="btn btn-veteva-secondary-delete" id="{{discount._id}}" (click)="removeCode(discount)"><i class="far fa-trash-alt pe-2"></i>Poista</button></td>

      </tr>

  </tbody>
</table>
</div>

<div id="clinic" class="tabcontent ">

  <h2>Klinikan asetukset</h2>
  
  <form class="flex-row add-pet-form mt-4 mb-4" *ngIf="sClinic.length > 0" [formGroup]="clinicForm"
  (ngSubmit)="onSubmitClinic()">
  <!-- Klinikan nimi -->
  <div class="form-group">
      <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
          <input  type="text" placeholder="Klinikan nimi" formControlName="clinicName" id="clinicName" class="form-control" [class.invalid-input]="clinicName.errors && clinicName.touched"  required>
          <div class="my-alerts" *ngIf="clinicName.invalid && clinicName.touched">
              <p class="mt-2" *ngIf="clinicName.errors.required">clinicName on pakollinen</p>
              <p *ngIf="clinicName.errors.clinicName"> Vaaditaan kunnollinen clinicName</p>
          </div>
          </div>
      </div>
  </div>
  <!-- Klinikan ID -->
  <div class="form-group" title="Klinikan tunnistetta (id:tä) ei voi muuttaa">
      <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
          <input disabled type="number" placeholder="Klinikan ID" formControlName="clinicsID"  class="form-control" [class.invalid-input]="clinicsID.errors && clinicsID.touched" required>
          <div class="my-alerts" *ngIf="clinicsID.invalid && clinicsID.touched">
              <p class="mt-2" *ngIf="clinicsID.errors.required">clinicsID on pakollinen</p>
              <p *ngIf="clinicsID.errors.clinicsID"> Vaaditaan kunnollinen clinicsID</p>
          </div>
          </div>
      </div>
  </div>
  <!-- KKlinikan logo -->
    <div class="form-group">
        <div class=" col-12">
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="text" placeholder="Klinikan logon url" formControlName="logoUrl" id="logoUrl" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
            <div class="my-alerts" *ngIf="logoUrl.invalid && logoUrl.touched">
                <p class="mt-2" *ngIf="logoUrl.errors.required">logoUrl on pakollinen</p>
                <p *ngIf="logoUrl.errors.logoUrl"> Vaaditaan kunnollinen logoUrl</p>
            </div>
            </div>
        </div>
    </div>
      <!-- Klinikan pääväri -->
        <div class="form-group">
          <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
              <input  type="color" placeholder="Klinikan logon url" formControlName="mainColor" id="mainColor" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
              <div class="my-alerts" *ngIf="mainColor.invalid && mainColor.touched">
                  <p class="mt-2" *ngIf="mainColor.errors.required">mainColor on pakollinen</p>
                  <p *ngIf="mainColor.errors.mainColor"> Vaaditaan kunnollinen mainColor</p>
              </div>
              </div>
          </div>
      </div>
    <!-- Klinikan toissijainen väri -->
    <div class="form-group">
        <div class=" col-12">
            <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input  type="color" placeholder="Klinikan logon url" formControlName="secondaryColor" id="secondaryColor" class="form-control" [class.invalid-input]="logoUrl.errors && logoUrl.touched" required>
            <div class="my-alerts" *ngIf="secondaryColor.invalid && secondaryColor.touched">
                <p class="mt-2" *ngIf="secondaryColor.errors.required">secondaryColor on pakollinen</p>
                <p *ngIf="secondaryColor.errors.secondaryColor"> Vaaditaan kunnollinen mainColor</p>
            </div>
            </div>
        </div>
    </div>
    <!-- Sähköpostiosoite -->
    <div class="form-group">
      <div class=" col-12">
        <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
          <input  type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
          <div class="my-alerts" *ngIf="email.invalid && email.touched">
            <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
            <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Puhelinnumero -->
    <div class="form-group">
      <div class=" col-12">
        <div class="form-group mb-2 flex-column col-12 p-0">
          <input  type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
          <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
            <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
            <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
          </div>
        </div>
      </div>
    </div>
      <!-- OVT -->
      <div class="form-group">
          <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 p-0">
              <input  type="text" placeholder="OVT-tunnus" formControlName="ovt" id="ovt" class="form-control" [class.invalid-input]="ovt.errors && ovt.touched" required>
              <div class="my-alerts" *ngIf="ovt.invalid && ovt.touched">
                  <p class="mt-2" *ngIf="ovt.errors.required">OVT on pakollinen</p>
                  <p *ngIf="ovt.errors.ovt"> Vaaditaan määrätty OVT</p>
              </div>
              </div>
          </div>
      </div>
      <!-- Operaattori -->
      <div class="form-group">
          <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 p-0">
              <input  type="text" placeholder="Operaattori" formControlName="operator" id="operator" class="form-control" [class.invalid-input]="operator.errors && operator.touched" required>
              <div class="my-alerts" *ngIf="operator.invalid && operator.touched">
                  <p class="mt-2" *ngIf="operator.errors.required">operator on pakollinen</p>
                  <p *ngIf="operator.errors.operator"> Vaaditaan määrätty operator</p>
              </div>
              </div>
          </div>
      </div>
              
          <button [disabled]="form.invalid" class=" login-btn btn-veteva-secondary">Muokkaa klinikkaa</button>

        </form>
  </div>
<!-- MODAL lisää Alekoodi -->
<div class="modal fade" id="addCode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Lisää alekoodi</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="messageSuccess">
          <p class="text-center">{{messageSuccess}}</p>
        </div>
  
        <form [formGroup]="addCode"
          (ngSubmit)="addNewCode()">

          <div class="form-group pt-2">
            <label for="code">Alennuskoodin nimi</label>
            <p class="text-center pointer"><a routerLink="/{{clinicID}}/clinic-settings" (click)="generateCode(7)">Generoi koodi</a></p>
                <input type="text"
                    placeholder="Kirjoita tähän..."
                    formControlName="code"
                    class="form-control"
                    required>
            
        </div>
        <div class="form-group pt-2">
          <label for="valid_until">Päättymispäivämäärä</label>
              <input type="date"
                  placeholder="Kirjoita tähän..."
                  formControlName="valid_until"
                  class="form-control"
                  required>
          
        </div>
        <div class="form-group pt-2">
          <label for="discount_percent">Alennusprosentti % (1-99)</label>
              <input (change)="alertDiscount()" type="number" min="1" max="99"
                  placeholder="Kirjoita tähän..."
                  formControlName="discount_percent"
                  class="form-control"
                  required>
          
        </div>
        <div class="form-group pt-2">
          <label for="max_usage">Max. käyttömäärä (tyhjä on rajoittamaton)</label>
              <input type="number"
                  placeholder="Kirjoita tähän..."
                  formControlName="max_usage"
                  class="form-control"
                  >
          
        </div>

          <button class="btn btn-veteva-secondary mt-4" [disabled]="addCode.invalid">Lisää koodi</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL eidtoi Alekoodi -->
<button class="btn btn-veteva-secondary" id="edit-code-btn" data-bs-toggle="modal" data-bs-target="#edit-code-modal" style="display: none;">Lisää alekoodi</button>
<div class="modal fade" id="edit-code-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div *ngIf="this.currentCode" class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Muokkaa alekoodia {{this.currentCode['code']}}</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="SuccessMessage == true">
          <div class="alert alert-success">
            <p class="text-center">{{messageSuccess}}</p>
          </div>
        </div>
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="messageSuccess">
          <p>{{messageSuccess}}</p>
        </div>
  
        <form [formGroup]="editCode"
          (ngSubmit)="editSelectedDiscountCode()">

        <div class="form-group">
          <label for="valid_until">Päättymispäivämäärä</label>
              <input type="date"
                  placeholder="Kirjoita tähän..."
                  formControlName="e_valid_until"
                  class="form-control"
                  required>
          
        </div>
        <div class="form-group">
          <label for="discount_percent">Alennusprosentti % (1-99)</label>
              <input (change)="alertEditDiscount()" type="number" min="1" max="99"
                  placeholder="Kirjoita tähän..."
                  formControlName="e_discount_percent"
                  class="form-control"
                  required>
          
        </div>
        <div class="form-group">
          <label for="max_usage">Max. käyttömäärä (tyhjä on rajoittamaton)</label>
              <input type="number"
                  placeholder="Kirjoita tähän..."
                  formControlName="e_max_usage"
                  class="form-control"
                  >
          
        </div>

          <button class="btn btn-veteva-secondary" [disabled]="editCode.invalid">Muokkaa alekoodia</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>


<div id="tuotepaketit" class="tabcontent ">

  <h2>Tuotepaketit</h2>

  <button class="btn btn-veteva-secondary mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#addPackage"><i class="fas fa-plus-circle pe-2"></i>Lisää tuote</button>

  
    <table class="table table-striped">
      <thead class="veteva-table-header">
        <tr>
          <th scope="col">Tuotepaketti</th>
          <th scope="col">Aukiolo</th>
          <th scope="col">Päivät</th>
          <th scope="col">Hinta</th>
          <th scope="col">Tuote ID</th>
          <th scope="col">Muokkaa</th>
          <th scope="col">Poista</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let package of feePackages">
            <td><strong>{{package.name}}</strong></td>
            <td>{{package.productStart}}-{{package.productEnd}}</td>
            <td>
              <div class="text-start" *ngFor="let day of package.productDays">
                <span *ngIf="day == 1">maanantai </span>
                <span *ngIf="day == 2">tiistai </span>
                <span *ngIf="day == 3">keskiviikko </span>
                <span *ngIf="day == 4">torstai </span>
                <span *ngIf="day == 5">perjantai </span>
                <span *ngIf="day == 6">lauantai </span>
                <span *ngIf="day == 0">sunnuntai </span>
              </div>
            </td>
            <td>{{package.price}} €</td>
            <td>{{package.productID}}</td>
            <td><button class="btn btn-veteva-secondary" (click)="editProduct(package)"><i class="far fa-edit pe-2"></i>Muokkaa</button></td>
            <td><button class="btn btn-veteva-secondary-delete" id="{{package._id}}" (click)="deletePackage($event)"><i class="far fa-trash-alt pe-2"></i>Poista</button></td>

          </tr>

      </tbody>
    </table>
  </div>

  <div id="questions" class="tabcontent ">

    <h2>Kysymykset ja vastaukset</h2>
  
    <button class="btn btn-veteva-secondary mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#addQuestion"><i class="fas fa-plus-circle pe-2"></i>Lisää kysymys</button>
  
    
      <table class="table table-striped">
        <thead class="veteva-table-header">
          <tr>
            <th scope="col">Kysymys</th>
            <th scope="col">Teksti</th>
            <th scope="col">Kategoria</th>
            <th scope="col">Muokkaa</th>
            <th scope="col">Poista</th>
          </tr>
        </thead>
        <tbody>
  
          <tr *ngFor="let question of questions">
              <td><strong>{{question.question}}</strong></td>
              <td>{{question.text}}</td>
              <td>{{question.category}}</td>
              <td><button class="btn btn-veteva-secondary" (click)="editThisQuestion(question)"><i class="far fa-edit pe-2"></i>Muokkaa</button></td>
              <td><button class="btn btn-veteva-secondary-delete"  (click)="deleteQuestion(question)"><i class="far fa-trash-alt pe-2"></i>Poista</button></td>
  
            </tr>
  
        </tbody>
      </table>
    </div>

  <!-- MODAL LISÄÄ TUOTE -->
  <div class="modal fade" id="addPackage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Lisää tuote</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="SuccessMessage == true">
            <div class="alert alert-success">
              <p class="text-center">{{messageSuccess}}</p>
            </div>
          </div>
          <div class="alert alert-warning">
            <p>Huom! Tuotteen hinnassa ei saa olla pilkkuja eikä valuuttamerkkejä. Mikäli lisäät näitä, asiakas ei pysty ostamaan tuotteita.<strong> Anna hinta muodossa: 29.99</strong></p>
          </div>
          <div class="alert alert-danger" *ngIf="messageErr">
            <p>{{messageErr}}</p>
          </div>
          <div class="alert alert-success" *ngIf="messageSuccess">
            <p>{{messageSuccess}}</p>
          </div>
    
          <form [formGroup]="addProduct"
            (ngSubmit)="addNewProduct()">

            <div class="form-group">
              <label for="productName">Tuotteen nimi</label>
                  <input type="text"
                      placeholder="Kirjoita tähän..."
                      formControlName="productName"
                      id="productName"
                      class="form-control"
                      required>
              
            </div>
            <div class="form-group">
                <label for="productPrice">Tuotteen hinta</label>
                    <input type="number"
                        placeholder="Kirjoita tähän..."
                        formControlName="productPrice"
                        id="productPrice"
                        class="form-control"
                        required>
                
            </div>

            <div class="form-group">
              <label for="productCredit">Creditin määrä</label>
                  <input type="number"
                      placeholder="Kirjoita tähän..."
                      formControlName="productCredit"
                      id="productCredit"
                      class="form-control"
                      required>
              
            </div>
            <div class="form-group">
              <label for="productDescription">Tuotekuvaus</label>
                  <textarea type="number"
                      placeholder="Kirjoita tähän..."
                      formControlName="productDescription"
                      id="productDescription"
                      class="form-control"
                      required></textarea>
              
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-6">
                  <p class="text-start m-0">Alku klo</p>
                  <input type="time" formControlName="productStart" class="form-control" [class.invalid-input]="monStart.errors && monStart.touched" required>
                </div>
                <div class="col-6">
                  <p class="text-start m-0">Loppu klo</p>
                  <input type="time" formControlName="productEnd" class="form-control" [class.invalid-input]="monEnd.errors && monEnd.touched" required>
                </div>
              </div>
            </div>

            <p class="text-start"><strong>Valitse voimassaolopäivät:</strong></p>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prMon">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Maanantai</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prTue">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Tiistai</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prWed">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Keskiviikko</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prThu">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Torstai</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prFri">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Perjantai</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prSat">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Lauantai</p>
                </div>
              </div>
            </div>

            <div class="form-group ps-2">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="prSun">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Sunnuntai</p>
                </div>
              </div>
            </div>

            <label for="chatOnly">Myytävissä vain kesken chatin</label>
            <select formControlName="chatOnly" class="form-control mb-4" required>
              <option value="false">Ei</option>
              <option value="true">Kyllä</option>
            </select>

           <button class="btn btn-veteva-secondary" [disabled]="addProduct.invalid"><i class="fas fa-plus-circle pe-2"></i>Lisää tuote</button>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-veteva-secondary" data-bs-toggle="modal" id="edit-product-btn" data-bs-target="#edit-product-modal" style="display: none;">Muokkaa tuotetta</button>
  <!-- MODAL LISÄÄ TUOTE -->
  <div class="modal fade" id="edit-product-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 *ngIf="this.currentProduct" class="modal-title" id="exampleModalLabel">Muokkaa tuotetta "{{this.currentProduct['name']}}"</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="SuccessMessage == true">
            <div class="alert alert-success">
              <p class="text-center">{{messageSuccess}}</p>
            </div>
          </div>
          <div class="alert alert-warning">
            <p>Huom! Tuotteen hinnassa ei saa olla pilkkuja eikä valuuttamerkkejä. Mikäli lisäät näitä, asiakas ei pysty ostamaan tuotteita.<strong> Anna hinta muodossa: 29.99</strong></p>
          </div>
          <div class="alert alert-danger" *ngIf="messageErr">
            <p>{{messageErr}}</p>
          </div>
          <div class="alert alert-success" *ngIf="messageSuccess">
            <p>{{messageSuccess}}</p>
          </div>
    
          <form [formGroup]="editProductForm"
            (ngSubmit)="sendEditProduct()">

            <div class="form-group">
              <label for="productName">Tuotteen nimi</label>
                  <input type="text"
                      placeholder="Kirjoita tähän..."
                      formControlName="e_productName"
                      class="form-control"
                      required>
              
            </div>
            <div class="form-group">
                <label for="productPrice">Tuotteen hinta</label>
                    <input type="number"
                        placeholder="Kirjoita tähän..."
                        formControlName="e_productPrice"
                        class="form-control"
                        required>
                
            </div>

            <div class="form-group">
              <label for="productCredit">Creditin määrä</label>
                  <input type="number"
                      placeholder="Kirjoita tähän..."
                      formControlName="e_productCredit"
                      class="form-control"
                      required>
              
            </div>
            <div class="form-group">
              <label for="productDescription">Tuotekuvaus</label>
                  <textarea type="number"
                      placeholder="Kirjoita tähän..."
                      formControlName="e_productDescription"
                      class="form-control"
                      required></textarea>
              
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-6">
                  <p class="text-start m-0">Alku klo</p>
                  <input type="time" formControlName="e_productStart" class="form-control" [class.invalid-input]="monStart.errors && monStart.touched" required>
                </div>
                <div class="col-6">
                  <p class="text-start m-0">Loppu klo</p>
                  <input type="time" formControlName="e_productEnd" class="form-control" [class.invalid-input]="monEnd.errors && monEnd.touched" required>
                </div>
              </div>
            </div>

            <p class="text-start"><strong>Valitse voimassaolopäivät:</strong></p>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prMon">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Maanantai</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prTue">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Tiistai</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prWed">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Keskiviikko</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prThu">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Torstai</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prFri">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Perjantai</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prSat">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Lauantai</p>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-12 row">
                <div class="col-1 p-0">
                  <input type="checkbox" id="e_prSun">
                </div>
                <div class="col-9">
                  <p class="text-start m-0">Sunnuntai</p>
                </div>
              </div>
            </div>

            <label for="chatOnly">Myytävissä vain kesken chatin</label>
            <select formControlName="e_chatOnly" class="form-control mb-4" required>
              <option value="false">Ei</option>
              <option value="true">Kyllä</option>
            </select>

            <button class="btn btn-veteva-secondary" [disabled]="editProductForm.invalid">Muokkaa tuotetta</button>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
        </div>
      </div>
    </div>
  </div>


  <!-- KÄYNTISYYT -->
  <div id="kayntisyyt" class="tabcontent ">
<h2>Käyntisyyt</h2>
<button class="btn btn-veteva-secondary mt-4 mb-4" data-bs-toggle="modal" data-bs-target="#addReason"><i class="fas fa-plus-circle pe-2"></i>Lisää Käyntisyy</button>
<table class="table table-striped">
  <thead class="veteva-table-header">
    <tr>
      <th scope="col">Käyntisyy</th>

    </tr>
  </thead>
  <tbody>

    <tr *ngFor="let syy of reasonsArr">
        <td><strong>{{syy.reason}}</strong></td>
<!--         <td><button class="btn btn-veteva-secondary" (click)="editReason(syy)"><i class="far fa-edit pe-2"></i>Muokkaa</button></td>
        <td><button class="btn btn-veteva-secondary-delete" id="{{syy._id}}" (click)="deleteReason(syy._id)"><i class="far fa-trash-alt pe-2"></i>Poista</button></td> -->

      </tr>

  </tbody>
</table>
</div>
<!-- MODAL LISÄÄ KÄYNTISYY -->
<div class="modal fade" id="addReason" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Lisää käyntisyy</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="messageSuccess">
          <p>{{messageSuccess}}</p>
        </div>
  
        <form [formGroup]="reason"
      (ngSubmit)="addReason()">
      <div class="alert alert-danger" *ngIf="messageErr">
        <p>{{messageErr}}</p>
      </div>

          <div class="form-group">
              <label for="reason">Käyntisyy</label>
                  <div class="my-alerts"
                      *ngIf="reason.invalid && reason.touched">
                      <p *ngIf="reason.errors.required">Käyntisyy on pakollinen</p>
                  </div>
                  <input type="text"
                      placeholder="Lisää käyntisyy"
                      formControlName="reason"
                      id="reason"
                      class="form-control"
                      [class.invalid-input]="reason.errors && reason.touched"
                      required>

          </div>

          <button class="btn btn-veteva-secondary w-100 mt-4"
              [disabled]="form.invalid"><i class="fas fa-plus-circle pe-2"></i>Lisää käyntisyy</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL LISÄÄ KYSYMYS -->
<div class="modal fade" id="addQuestion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Lisää kysymys</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="messageSuccess">
          <p>{{messageSuccess}}</p>
        </div>
  
        <form [formGroup]="question"
      (ngSubmit)="submitNewQuestion()">
      <div class="alert alert-danger" *ngIf="messageErr != ''">
        <p>{{messageErr}}</p>
      </div>


          <div class="form-group mt-3">
              <label for="question">Kysymys</label>

                  <input type="text"
                      placeholder="Lisää Kysymys"
                      formControlName="question"
                      class="form-control"
                      [class.invalid-input]="question.errors && question.touched"
                      required>

          </div>
          <div class="form-group mt-3">
            <label for="q_category">Kategoria</label>

                <select
                    formControlName="q_category"
                    class="form-control"
                    required>
                    <option></option>
                    <option value="vet">Eläinlääkäri</option>
                    <option value="payments">Maksut</option>
                    <option value="messages">Viestit</option>
                    <option value="others">Muut</option>
                </select>

        </div>

        <div class="form-group mt-3">
          <label for="q_text">Selite</label>

              <textarea
                  placeholder="Lisää selite"
                  formControlName="q_text"
                  id="q_text"
                  class="form-control"
                  required>
              </textarea>

      </div>

          <button class="btn btn-veteva-secondary w-100 mt-4"
              [disabled]="question.invalid"><i class="fas fa-plus-circle pe-2"></i>Lisää kysymys</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL LISÄÄ KYSYMYS -->
<button id="open-edit-question" class="btn btn-veteva-secondary mt-4 mb-4" style="display: none;" data-bs-toggle="modal" data-bs-target="#edit-question-modal"><i class="fas fa-plus-circle pe-2"></i>Lisää kysymys</button>

<div class="modal fade" id="edit-question-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Muokkaa kysymystä</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-danger" *ngIf="messageErr">
          <p>{{messageErr}}</p>
        </div>
        <div class="alert alert-success" *ngIf="messageSuccess">
          <p>{{messageSuccess}}</p>
        </div>
  
        <form [formGroup]="editQuestion"
      (ngSubmit)="submitEditQuestion()">
      <div class="alert alert-danger" *ngIf="messageErr != ''">
        <p>{{messageErr}}</p>
      </div>
      <div class="alert alert-success" *ngIf="messageSuccess != ''">
        <p>{{messageSuccess}}</p>
      </div>


          <div class="form-group mt-3">
              <label for="editQuestions">Kysymys</label>

                  <input type="text"
                      placeholder="Lisää Kysymys"
                      formControlName="editQuestions"
                      class="form-control"
                      [class.invalid-input]="editQuestions.errors && editQuestions.touched"
                      required>

          </div>
          <div class="form-group mt-3">
            <label for="editCategory">Kategoria</label>

                <select
                    formControlName="editCategory"
                    class="form-control"
                    required>
                    <option></option>
                    <option value="vet">Eläinlääkäri</option>
                    <option value="payments">Maksut</option>
                    <option value="messages">Viestit</option>
                    <option value="others">Muut</option>
                </select>

        </div>

        <div class="form-group mt-3">
          <label for="editText">Selite</label>

              <textarea
                  placeholder="Lisää selite"
                  formControlName="editText"
                  id="editText"
                  class="form-control"
                  required>
              </textarea>

      </div>

          <button class="btn btn-veteva-secondary w-100 mt-4"
              [disabled]="editQuestion.invalid"><i class="fas fa-plus-circle pe-2"></i>Muokkaa kysymys</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>

<!-- MODAL LISÄÄ KÄYNTISYY -->
<button class="btn btn-veteva-secondary" data-bs-toggle="modal" id="edit-reason-btn" data-bs-target="#edit-reason-modal" style="display: none;" >Lisää Käyntisyy</button>
<div class="modal fade" id="edit-reason-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Muokkaa käyntisyytä</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
  
        <form [formGroup]="reasonForm"
      (ngSubmit)="editSelectedReason()">
      <div class="alert alert-danger" *ngIf="messageErr">
        <p>{{messageErr}}</p>
      </div>
      <div class="alert alert-success" *ngIf="messageSuccess">
        <p>{{messageSuccess}}</p>
      </div>


          <div class="form-group">
              <label for="e_reason">Käyntisyy</label>
                  <input type="text"
                      placeholder="Muokkaa käyntisyytä"
                      formControlName="e_reason"
                      class="form-control"
                      [class.invalid-input]="e_reason.errors && e_reason.touched"
                      required>

          </div>

          <button class="btn btn-veteva-secondary mt-4"
              [disabled]="reasonForm.invalid">Muokkaa käyntisyytä</button>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
      </div>
    </div>
  </div>
</div>

<!--    <h2>Organisaatiot</h2>
   <p>Ei käytössä</p>
  <button class="btn btn-veteva-secondary" data-bs-toggle="modal" data-bs-target="#addOrganization">Lisää organisaatio</button>

    <div class="modal fade" id="addOrganization" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Lisää organisaatio</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning">
              <p>Huom! Ohjeet<strong> Anna Organisaation ID, Tuote ID, Jäsenkoodi ilman erikoismerkkejä sekä kirjaimia. Vain numeroita.</strong></p>
            </div>
            <div class="alert alert-danger" *ngIf="messageErr">
              <p>{{messageErr}}</p>
            </div>
            <div class="alert alert-success" *ngIf="messageSuccess">
              <p>{{messageSuccess}}</p>
            </div>
      
            <form [formGroup]="addOrganization"
              (ngSubmit)="addNewOrganization()">
  
              <div class="form-group">
                <label for="organizationName">Organisaation nimi</label>
                    <input type="text"
                        placeholder="Kirjoita tähän..."
                        formControlName="organizationName"
                        id="organizationName"
                        class="form-control"
                        required>
                
              </div>
              <div class="form-group">
                  <label for="organizationID">Organisaation ID</label>
                      <input type="number"
                          placeholder="Kirjoita tähän..."
                          formControlName="organizationID"
                          id="organizationID"
                          class="form-control"
                          required>
                  
              </div>
              <div class="form-group">
                <label for="organizationProductID">Tuote ID organisaatiolle</label>
                    <input type="number"
                        placeholder="Kirjoita tähän..."
                        formControlName="organizationProductID"
                        id="organizationProductID"
                        class="form-control"
                        required>
                
            </div>

            <div class="form-group">
              <label for="organizationMemberCode">Jäsenkoodi</label>
                  <input type="number"
                      placeholder="Kirjoita tähän..."
                      formControlName="organizationMemberCode"
                      id="organizationMemberCode"
                      class="form-control"
                      required>
              
            </div>
  
              <button class="btn btn-veteva-secondary" [disabled]="addOrganization.invalid">Lisää tuote</button>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-veteva-secondary-edit" data-bs-dismiss="modal">Sulje</button>
          </div>
        </div>
      </div>
    </div> -->

</div>

<ng-template #no_admin>
  <div class="alert alert-warning">
    <p class="text-center">Tämä sisältö on vain pääkäyttäjälle</p>
  </div>
</ng-template>