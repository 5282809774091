<app-not-auth-header></app-not-auth-header>

<div class="wrapperi pb-4 mb-4">
    <div class="container pb-4">
        <div class="wrap pb-4">
          <div class="container">
        <div id="check-bought" class="d-flex flex-row justify-content-center">
            <div class="col-12  flex-wrap mt-4">
          <div class="col-12 row p-0 m-0">
            <div class="col-3"></div>
            <div class="col-12 col-md-6 pl-2 pl-md-3">
              <h4 class="header3 mt-4" *ngIf="product">Olet ostamassa tuotetta {{product['name']}}</h4>
              <p class="body2b mt-4 mb-4">Syötä sähköpostiosoitteesi ja valitse maksutapa</p>
              <table class="table table-striped" *ngIf="product">
                <thead class="veteva-table-header">
                  <tr>
                    <th scope="col">Tuotteen nimi</th>
                    <th scope="col">Hinta</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td><strong>{{product['name']}}</strong><br>{{product['productDescription']}}</td>
                      <td>{{product['price']}}€</td>
                    </tr>
                    <tr>
                        <td><i>Yhteensä:</i></td>
                        <td>{{product['price']}}€</td>
                      </tr>
                </tbody>
              </table>
            </div>
        <div class="col-3"></div>
        </div>
            </div>
      </div>

    
      <div id="loader" class="loader-container" style="display:none">
        <div class="loader mx-auto"></div>
      </div>
      <div class="col-12 row p-0 m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 p-0">
      <div style="display: block;" id="register-form" class="d-flex flex-row justify-content-center pl-2">
            <form class="flex-row add-pet-form mt-4" [formGroup]="paymentForm"
        (ngSubmit)="onSubmit()">
        <p class="body2b mt-4 mb-2">Syötä sähköpostiosoitteesi</p>
          <!-- Sähköpostiosoite -->
          <div class="form-group">
            <div class=" col-12">
              <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
                <input (ngModelChange)="clearBlinker()" type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
                <div class="my-alerts" *ngIf="email.invalid && email.touched">
                  <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
                  <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
                </div>
              </div>
            </div>
          </div>
          <p class="body2b mt-4 mb-2">Valitse haluamasi maksutapa:</p>
        <a href="https://app.vetsy.vet/#/{{clinicID}}/public-payment/{{productID}}/{{doctorID}}" (click)="showAllPaymentMethods()" *ngIf="paymentMethods.length == 1">Haluatko vaihtaa maksutapaa? Klikkaa tästä</a>
        <div class="col-12 payment-row row mt-4 me-0 ms-0" *ngFor="let method of paymentMethods" id="method-{{method.name}}">
            <div class="col-3">
                <img src="{{method.img}}" height="70">
            </div>
            <div class="col-5 mt-3">
                <p class="body2b ms-2">{{method.name}}</p>
            </div>
            <div class="col-4 mt-3">
                <p class="btn-veteva-secondary text-center" (click)="selectPaymentMethod(method)">Valitse</p>
            </div>
        </div>
        <!-- Ehtojen hyväksyminen -->
        <div class="form-group mt-4">
            <div class=" col-12 d-inline-flex">
                <div class="form-group privacy-text-wrap mb-2 col-9 p-0">
                <label type="text" readonly class="body2b form-control-plaintext m-0 p-0 privacy-text">Olen lukenut palvelun <a class="veteva-main-link" target="_blank" href="https://vetsy.vet/vetsy-tietosuojaseloste-lt/"> tietosuojaselosteen </a>sekä <a class="veteva-main-link" href="https://vetsy.vet/puhelinreseptin-toimitus-ja-maksuehdot/">toimitus -ja maksuehdot</a> ja hyväksyn ne*</label>
                </div>
                <div class="form-group mx-sm-3 mb-2 even-lines flex-column col-2 p-0">

                    <div class="round text-right">
                      <input formControlName="privacy" type="checkbox" id="privacy" [class.invalid-input]="privacy.errors && privacy.touched" required/>
                      <label for="privacy"></label>
                    </div>
                
                <div class="my-alerts" *ngIf="privacy.invalid && privacy.touched">
                    <p class="mt-2" *ngIf="privacy.errors.required">Käyttöehdot on hyväksyttävä</p>
                    <p *ngIf="privacy.errors.privacy"> Vaaditaan määrätty käyttöehdot</p>
                </div>
                </div>
            </div>
            </div>

            <div class="form-group mt-4 mb-4">
              <div class=" col-12 d-inline-flex">
                  <div class="form-group privacy-text-wrap mb-2 col-9 p-0">
                  <label type="text" readonly class="body2b form-control-plaintext m-0 p-0 privacy-text">Haluan saada ajantasaisia ilmoituksia ja liittyä markkinoitirekisteriimme </label>
                  </div>
                  <div class="form-group mx-sm-3 mb-2 even-lines flex-column col-2 p-0">
  
                      <div class="round text-right">
                        <input formControlName="marketing_allowed" type="checkbox" id="marketing_allowed" />
                        <label for="marketing_allowed"></label>
                      </div>
                  
                  </div>
              </div>
              </div>
      
            
            <button [disabled]="paymentForm.invalid" class="btn login-btn btn-veteva-secondary">Maksa</button>
          </form>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>