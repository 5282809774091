<app-not-auth-header></app-not-auth-header>




<div class="wrapperi pb-4 mb-4">
<div class="container pb-4">
	<div class="wrap pb-4">
	<div class="d-flex flex-row justify-content-center">
		<div class="col-12  flex-wrap mt-4">
      <div class="col-12 row p-0 m-0">
        <div class="col-3"></div>
        <div class="col-12 col-md-6 pl-2 pl-md-3">
          <h4 class="header3 mt-4">Rekisteröidy käyttäjäksi!</h4>
          <p class="body2b mt-4 mb-4">Täytä alla oleva lomake. </p>
          <div *ngIf="passwordErr != ''">
            <div class="alert alert-warning">
              <p>{{passwordErr}}</p>
            </div>
          </div>
          <div *ngIf="messageErr">
            <div class="alert alert-warning">
              <p>{{messageErr}}</p>
            </div>
          </div>
        </div>
    <div class="col-3"></div>
    </div>
		</div>
  </div>
  <div style="display: none;" id="activation-alert" class="alert alert-success text-center">
    <p>Hienoa! Rekisteröityminen onnistui! Sait sähköpostiisi aktivointilinkin, jonka avulla pystyt aktivoimaan käyttäjätilisi.</p>
    <button class="btn btn-secondary" routerLink="/login">Kirjaudu sisään</button>
  </div>

  <div id="loader" class="loader-container" style="display:none">
    <div class="loader mx-auto"></div>
  </div>
  <div class="col-12 row p-0 m-0">
    <div class="col-3"></div>
    <div class="col-12 col-md-6 p-0">
  <div style="display: block;" id="register-form" class="d-flex flex-row justify-content-center pl-2">
		<form class="flex-row add-pet-form mt-4" [formGroup]="form"
    (ngSubmit)="onSubmit()">
      <!-- Sähköpostiosoite -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 col-md-12 p-0">
            <input (change)="emailValidate()" type="email" placeholder="Sähköpostiosoite" formControlName="email" id="email" class="form-control" [class.invalid-input]="email.errors && email.touched" required>
            <div class="my-alerts" *ngIf="email.invalid && email.touched">
              <p class="mt-2" *ngIf="email.errors.required">Email on pakollinen</p>
              <p *ngIf="email.errors.email"> Vaaditaan kunnollinen email</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Puhelinnumero -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input (change)="phoneValidate()" type="tel" placeholder="Puhelinnumero" formControlName="phone" id="phone" class="form-control" [class.invalid-input]="phone.errors && phone.touched" required>
            <div class="my-alerts" *ngIf="phone.invalid && phone.touched">
              <p class="mt-2" *ngIf="phone.errors.required">Puhelinnumero on pakollinen</p>
              <p *ngIf="phone.errors.phone"> Vaaditaan määrätty puhelinnumero</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Etunimi -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input (change)="firstNameValidate()" type="text" placeholder="Etunimi" formControlName="firstName" id="firstName" class="form-control" [class.invalid-input]="firstName.errors && firstName.touched" required>
            <div class="my-alerts" *ngIf="firstName.invalid && firstName.touched">
              <p class="mt-2" *ngIf="firstName.errors.required">Etunimi on pakollinen</p>
              <p *ngIf="firstName.errors.firstName"> Vaaditaan määrätty etunimi</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Sukunimi -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input (change)="lastNameValidate()" type="text" placeholder="Sukunimi" formControlName="lastName" id="lastName" class="form-control" [class.invalid-input]="lastName.errors && lastName.touched" required>
            <div class="my-alerts" *ngIf="lastName.invalid && lastName.touched">
              <p class="mt-2" *ngIf="lastName.errors.required">Sukunimi on pakollinen</p>
              <p *ngIf="lastName.errors.lastName"> Vaaditaan määrätty sukunimi</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Salasana -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input (change)="passwordValidate()" type="password" (ngModelChange)="listenPWD()" placeholder="Salasana" formControlName="password" id="password" class="form-control" [class.invalid-input]="password.errors && password.touched" required>
            <div class="my-alerts" *ngIf="password.invalid && password.touched">
              <p class="mt-2" *ngIf="password.errors.required">Salasana on pakollinen</p>
              <p *ngIf="password.errors.password"> Vaaditaan määrätty salasana</p>
            </div>
            <div class="my-alerts" *ngIf="shortPwd">
              <p class="mt-2" >Salasana on liian lyhyt (8)</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Salasana 2 -->
      <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 flex-column col-12 p-0">
            <input (change)="password2Validate()" type="password" placeholder="Salasana uudelleen" formControlName="password2" id="password2" class="form-control" [class.invalid-input]="password2.errors && password2.touched" required>
            <div class="my-alerts" *ngIf="password2.invalid && password2.touched">
                <p class="mt-2" *ngIf="password2.errors.required">Salasana on kirjoitettava uudelleen</p>
              <p *ngIf="password2.errors.password2"> Vaaditaan määrätty salasana</p>
            </div>
          </div>
        </div>
      </div>
      <!-- RECHAPTA -->
<!--       <div class="form-group">
        <div class=" col-12">
          <div class="form-group mb-2 col-6 p-0">
            <label type="text" readonly class="body2b-b form-control-plaintext m-0 p-0">{{this.chaptaQuestion}}</label>
          </div>
          <div class="form-group mb-2 flex-column col-12 col-md-6 p-0">
            <img class="mb-1"src="{{this.chaptaImage}}">
            <input type="number" placeholder="Määrä" formControlName="chapta" id="chapta" class="form-control" [class.invalid-input]="chapta.errors && chapta.touched" required>
            <div class="my-alerts" *ngIf="chapta.invalid && chapta.touched">
                <p class="mt-2" *ngIf="chapta.errors.required">Määrä on kirjoitettava uudelleen</p>
              <p *ngIf="chapta.errors.chapta"> Vaaditaan määrätty numero</p>
            </div>
          </div>
        </div>
      </div> -->
        <!-- Ehtojen hyväksyminen -->
        <div class="form-group">
            <div class=" col-12 d-inline-flex">
                <div class="form-group privacy-text-wrap mb-2 col-9 p-0">
                <label type="text" readonly class="body2b form-control-plaintext m-0 p-0 privacy-text">Olen lukenut palvelun <a class="veteva-main-link" routerLink="/termsofservice"> käyttöehdot </a>sekä hyväksyn <a class="veteva-main-link" routerLink="/privacypolicy">tietosuojaselosteen</a>*</label>
                </div>
                <div class="form-group mx-sm-3 mb-2 even-lines flex-column col-2 p-0">

                    <div class="round text-right">
                      <input formControlName="privacy" type="checkbox" id="privacy" [class.invalid-input]="privacy.errors && privacy.touched" required/>
                      <label for="privacy"></label>
                    </div>
                
                <div class="my-alerts" *ngIf="privacy.invalid && privacy.touched">
                    <p class="mt-2" *ngIf="privacy.errors.required">Käyttöehdot on hyväksyttävä</p>
                    <p *ngIf="privacy.errors.privacy"> Vaaditaan määrätty käyttöehdot</p>
                </div>
                </div>
            </div>
            </div>
      
            
            <button [disabled]="form.invalid" class="btn login-btn btn-veteva-secondary">Rekisteröidy</button>

		  </form>
  </div>
  <div class="col-3"></div>
    </div>

    </div>
  </div>