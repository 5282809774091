import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const helper = new JwtHelperService;

@Component({
  selector: 'app-statistics-today',
  templateUrl: './statistics-today.component.html',
  styleUrls: ['./statistics-today.component.css']
})
export class StatisticsTodayComponent implements OnInit {

  currentUserId;
  currentStat: any = {};
  authToken = localStorage.getItem('auth');
  dataError;
  chart;
  visitsGenderDiversion;
  visitsSpeciesDiversion;
  visitsDiagnosisDiversion;
  top5reasons;
  top5FinalDiagnosis;
  top5DiffDiagnosis;
  top5DiffDiagnosisVisitedDogs;
  top5FinalDiagnosisDogs;
  top5VisitBreedsDogs;
  top5VisitReasonsDogs;
  dogvisitsGenderDiversion;

  top5DiffDiagnosisVisitedCats;
  top5FinalDiagnosisCats;
  top5VisitBreedsCats;
  top5VisitReasonsCats;
  catvisitsGenderDiversion;

  top5DiffDiagnosisVisitedSmallAnimals;
  top5FinalDiagnosisSmallAnimals;
  top5VisitBreedsSmallAnimals;
  top5VisitReasonsSmallAnimals;
  smallAnimalsvisitsGenderDiversion;

  petsGenderDiversion;
  petsSpeciesDiversion;
  petsSterilized;

  petsDogsGenderDiversion;
  petsDogsTop5Breeds;

  petsCatsGenderDiversion;
  petsCatsTop5Breeds;

  petsSmallAnimalsGenderDiversion;
  petsSmallAnimalsTop5Breeds;

  top5BoughtProducts;
  top5UsedDiscountCodes;
  paymentDiversion;

  isAdmin;
  paymentStats:any = [];

  registerStats:any = [];
  paymentChart;
  registerChart;

  visitChart;
  visitStats:any = [];

  feedbackChart;
  feedbackStats: any = [];


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'auth': `${this.authToken}`  }),
    withCredentials: true,
  };

    constructor(
      private router: Router,
      private authService: AuthService, 
      private http: HttpClient) {
    this.authService.validateLogin();
  }

  ngOnInit(): void {
    this.myId();
    
  }

  myId(){
    var token = localStorage.getItem('auth');
    var decodedToken = helper.decodeToken(token);

    this.currentUserId = decodedToken.result.id;
    this.isAdmin = decodedToken.result.isAdmin;

    if(this.isAdmin){
      this.fetchDailyStat();
      this.fetchPaymentStatData(3);
      this.fetchRegisterStatData(3);
      this.fetchVisitStatData(3);
      this.fetchFeedbackStatData(3);
    }
    
  }

  fetchPaymentStatData(months){

    this.http.get(environment.PAYMENT_STATISTICS+'/'+months, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200){
          this.paymentStats = res.message;
          this.generatePaymentChart();

          console.log(this.paymentStats);

          
        }
        else{
          this.dataError = true;
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  fetchRegisterStatData(months){

    this.http.get(environment.REGISTERS_STATISTICS+'/'+months, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200){
          this.registerStats = res.message;
          this.generateRegisterChart()

          console.log(this.registerStats);

          
        }
        else{
          this.dataError = true;
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  fetchFeedbackStatData(months){

    this.http.get(environment.FEEDBACKS_STATISTICS+'/'+months, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200){
          this.feedbackStats = res.message;
          this.generateFeedbackChart()

          console.log(this.feedbackStats);

          
        }
        else{
          this.dataError = true;
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  fetchVisitStatData(months){

    this.http.get(environment.VISITS_STATISTICS+'/'+months, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200){
          this.visitStats = res.message;
          this.generateVisitsChart()

          console.log(this.visitStats);

          
        }
        else{
          this.dataError = true;
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  fetchDailyStat(){

    this.http.get(environment.STATISTIC, this.httpOptions).subscribe(
      (res: any) => {
        if(res.status = 200){
          this.currentStat = res.message[0];
          this.dataError = false;
          console.log(this.currentStat);

          this.waitShow();

          
        }
        else{
          this.dataError = true;
        }
  
      }, (err: any) => {
        
        console.log("Error validating user :: ", err);
      })
  }

  waitShow(){

    setTimeout(() =>{

      this.generateVisitGenderDiversion();
      this.generateVisitSpeciesDiversion();
      this.generateVisitDiagnosisDiversion();
      this.generateVisittop5reasons();
      this.generateVisittop5FinalDiagnosis(); 
      this.generateVisittop5DiffDiagnosis();

      this.generateVisittop5DiffDiagnosisVisitedDogs();
      this.generateVisittop5FinalDiagnosisDogs();
      this.generateVisittop5VisitBreedsDogs();
      this.generateVisittop5VisitReasonsDogs();
      this.generateVisitdogvisitsGenderDiversion();

      this.generateVisittop5DiffDiagnosisVisitedCats();
      this.generateVisittop5FinalDiagnosisCats();
      this.generateVisittop5VisitBreedsCats(); 
      this.generateVisittop5VisitReasonsCats();
      this.generateVisitcatvisitsGenderDiversion();

      this.generateVisittop5DiffDiagnosisVisitedSmallAnimals();
      this.generateVisittop5FinalDiagnosisSmallAnimals();
      this.generateVisittop5VisitBreedsSmallAnimals(); 
      this.generateVisittop5VisitReasonsSmallAnimals();
      this.generateVisitSmallAnimalsvisitsGenderDiversion();

      this.generatePetsGenderDiversion();
      this.generatePetsSpeciesDiversion();
      this.generatePetsSterilized()

      this.generatePetsDogsGenderDiversion();
      this.generatepetsDogsTop5Breeds();

      this.generatePetsCatsGenderDiversion();
      this.generatepetsCatsTop5Breeds();

      this.generatePetsSmallAnimalsGenderDiversion();
      this.generatepetsSmallAnimalsTop5Breeds();

      this.generatetop5BoughtProducts();
      this.generatetop5UsedDiscountCodes();

      this.generatepaymentDiversion();
      console.log(this.currentStat.visits.total_number_of_females)

    },500)
  }

  generateVisitGenderDiversion(){

    

    var g = <HTMLCanvasElement>document.getElementById('visit-gender-diversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.visitsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Keskihinta €/pvm',
              data: [this.currentStat.visits.total_number_of_females, this.currentStat.visits.total_number_of_males],
              backgroundColor: [
                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          }
        ],
          
          
      },
      options:options
      
  });

  }

  generateVisitSpeciesDiversion(){

    var g = <HTMLCanvasElement>document.getElementById('visit-species-diversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.visitsSpeciesDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Koira', 'Kissa', 'Pieneläin'],
          datasets: [{
              label: 'Keskihinta €/pvm',
              data: [this.currentStat.visits.total_number_of_dogs, this.currentStat.visits.total_number_of_cats,this.currentStat.visits.total_number_of_small_animals],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          }
        ],
          
          
      },
      options:options,
      
  });

  }

  generateVisitDiagnosisDiversion(){

    var g = <HTMLCanvasElement>document.getElementById('visit-diagnosis-diversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 12,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.visitsDiagnosisDiversion = new Chart(g, {
      type: 'bar',
      data: {
          labels:['Lopulliset', 'Vaihtoehtoiset'],
          datasets: [{
              label: 'Määrät',
              data: [this.currentStat.visits.total_number_of_diagnosisFinal, this.currentStat.visits.total_number_of_diagnosisDiff],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options

      
  });

  }

  generateVisittop5reasons(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.total_visit_reason.length; i++) {
      const visit = this.currentStat.visits.total_visit_reason[i];

      if(i <= 4){
        labels.push(visit.visit_reason);
        values.push(visit.number_of_visit_reason_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5reasons');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";


    this.top5reasons = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyntisyyt',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5FinalDiagnosis(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.total_diagnosisFinal.length; i++) {
      const visit = this.currentStat.visits.total_diagnosisFinal[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5FinalDiagnosis');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5FinalDiagnosis = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5DiffDiagnosis(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.total_diagnosisDiff.length; i++) {
      const visit = this.currentStat.visits.total_diagnosisDiff[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5DiffDiagnosis');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5DiffDiagnosis = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generateVisittop5DiffDiagnosisVisitedDogs(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.dogs.diagnosisDiff.length; i++) {
      const visit = this.currentStat.visits.dogs.diagnosisDiff[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5DiffDiagnosisVisitedDogs');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };


    this.top5DiffDiagnosisVisitedDogs = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 vaihtoehtoiset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5FinalDiagnosisDogs(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.dogs.diagnosisFinal.length; i++) {
      const visit = this.currentStat.visits.dogs.diagnosisFinal[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5FinalDiagnosisDogs');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };


    this.top5FinalDiagnosisDogs = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }
  generateVisittop5VisitBreedsDogs(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.dogs.visited_breeds.length; i++) {
      const visit = this.currentStat.visits.dogs.visited_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_visited_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitBreedsDogs');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };


    this.top5VisitBreedsDogs = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyneet rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generateVisittop5VisitReasonsDogs(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.dogs.visit_reason.length; i++) {
      const visit = this.currentStat.visits.dogs.visit_reason[i];

      if(i <= 4){
        labels.push(visit.reason);
        values.push(visit.number_of_reasons_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitReasonsDogs');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };


    this.top5VisitReasonsDogs = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyntisyyt',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generateVisitdogvisitsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('visit-dogvisitsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,

  };


    this.dogvisitsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Koirien sukupuolijakauma',
              data: [this.currentStat.visits.dogs.number_of_females, this.currentStat.visits.dogs.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generateVisittop5DiffDiagnosisVisitedCats(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.cats.diagnosisDiff.length; i++) {
      const visit = this.currentStat.visits.cats.diagnosisDiff[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5DiffDiagnosisVisitedCats');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5DiffDiagnosisVisitedCats = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 vaihtoehtoiset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5FinalDiagnosisCats(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.dogs.diagnosisFinal.length; i++) {
      const visit = this.currentStat.visits.dogs.diagnosisFinal[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5FinalDiagnosisCats');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5FinalDiagnosisCats = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5VisitBreedsCats(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.cats.visited_breeds.length; i++) {
      const visit = this.currentStat.visits.cats.visited_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_visited_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitBreedsCats');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5VisitBreedsCats = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyneet rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5VisitReasonsCats(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.cats.visit_reason.length; i++) {
      const visit = this.currentStat.visits.cats.visit_reason[i];

      if(i <= 4){
        labels.push(visit.reason);
        values.push(visit.number_of_reasons_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitReasonsCats');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5VisitReasonsCats = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyntisyyt',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisitcatvisitsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('visit-catvisitsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.catvisitsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Koirien sukupuolijakauma',
              data: [this.currentStat.visits.cats.number_of_females, this.currentStat.visits.cats.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5DiffDiagnosisVisitedSmallAnimals(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.small_animals.diagnosisDiff.length; i++) {
      const visit = this.currentStat.visits.small_animals.diagnosisDiff[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5DiffDiagnosisVisitedSmallAnimals');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5DiffDiagnosisVisitedSmallAnimals = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 vaihtoehtoiset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5FinalDiagnosisSmallAnimals(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.small_animals.diagnosisFinal.length; i++) {
      const visit = this.currentStat.visits.small_animals.diagnosisFinal[i];

      if(i <= 4){
        labels.push(visit.diagnosis);
        values.push(visit.number_of_diagnosis_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5FinalDiagnosisSmallAnimals');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5FinalDiagnosisSmallAnimals = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5VisitBreedsSmallAnimals(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.small_animals.visited_breeds.length; i++) {
      const visit = this.currentStat.visits.small_animals.visited_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_visited_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitBreedsSmallAnimals');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5VisitBreedsSmallAnimals = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyneet rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisittop5VisitReasonsSmallAnimals(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.visits.small_animals.visit_reason.length; i++) {
      const visit = this.currentStat.visits.small_animals.visit_reason[i];

      if(i <= 4){
        labels.push(visit.reason);
        values.push(visit.number_of_reasons_used);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5VisitReasonsSmallAnimals');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5VisitReasonsSmallAnimals = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 käyntisyyt',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generateVisitSmallAnimalsvisitsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('visit-smallAnimalsvisitsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.smallAnimalsvisitsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Pieneläimien sukupuolijakauma',
              data: [this.currentStat.visits.small_animals.number_of_females, this.currentStat.visits.small_animals.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  
  generatePetsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.petsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Sukupuolijakauma',
              data: [this.currentStat.pets.total_number_of_females, this.currentStat.pets.total_number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }


  generatePetsSpeciesDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsSpeciesDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,

  };



    this.petsSpeciesDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Koira', 'Kissa','Pieneläin'],
          datasets: [{
              label: 'Lajijakauma',
              data: [this.currentStat.pets.total_number_of_dogs, this.currentStat.pets.total_number_of_cats, this.currentStat.pets.total_number_of_small_animals ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatePetsSterilized(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsSterilized');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.petsSterilized = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Koira', 'Kissa','Pieneläin'],
          datasets: [{
              label: 'Steriloituja',
              data: [this.currentStat.pets.dogs.number_of_sterilized, this.currentStat.pets.cats.number_of_sterilized, this.currentStat.pets.small_animals.number_of_sterilized ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatePetsDogsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsDogsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.petsDogsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Sukupuolijakauma',
              data: [this.currentStat.pets.dogs.number_of_females, this.currentStat.pets.dogs.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatepetsDogsTop5Breeds(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.pets.dogs.registered_breeds.length; i++) {
      const visit = this.currentStat.pets.dogs.registered_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_registered_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('pets-petsDogsTop5Breeds');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.petsDogsTop5Breeds = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 rekisteröidyt rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generatePetsCatsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsCatsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,

  };



    this.petsCatsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Sukupuolijakauma',
              data: [this.currentStat.pets.cats.number_of_females, this.currentStat.pets.cats.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generatepetsCatsTop5Breeds(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.pets.cats.registered_breeds.length; i++) {
      const visit = this.currentStat.pets.cats.registered_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_registered_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('pets-petsCatsTop5Breeds');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.petsCatsTop5Breeds = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 rekisteröidyt rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatePetsSmallAnimalsGenderDiversion(){


    var g = <HTMLCanvasElement>document.getElementById('pets-petsSmallAnimalsGenderDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.petsSmallAnimalsGenderDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Naaras', 'Uros'],
          datasets: [{
              label: 'Sukupuolijakauma',
              data: [this.currentStat.pets.small_animals.number_of_females, this.currentStat.pets.small_animals.number_of_males ],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
  });

  }

  generatepetsSmallAnimalsTop5Breeds(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.pets.small_animals.registered_breeds.length; i++) {
      const visit = this.currentStat.pets.small_animals.registered_breeds[i];

      if(i <= 4){
        labels.push(visit.breed);
        values.push(visit.number_of_registered_breed);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('pets-petsSmallAnimalsTop5Breeds');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.petsSmallAnimalsTop5Breeds = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 rekisteröidyt rodut',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatetop5BoughtProducts(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.payments.total_products_bought.length; i++) {
      const visit = this.currentStat.payments.total_products_bought[i];

      if(i <= 4){
        labels.push(visit.product_name);
        values.push(visit.number_of_bought_product);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5BoughtProducts');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5BoughtProducts = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 ostetut tuotteet',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options,
  });

  }

  generatetop5UsedDiscountCodes(){

    var labels = [];
    var values = [];

    for (let i = 0; i < this.currentStat.payments.total_coupons_used.length; i++) {
      const visit = this.currentStat.payments.total_coupons_used[i];

      if(i <= 4){
        labels.push(visit.discount_code);
        values.push(visit.number_of_usage);
      }
  
    }

    var g = <HTMLCanvasElement>document.getElementById('visit-top5UsedDiscountCodes');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
      scales: {
          x: {
              ticks: {
                  font: {
                      size: 8,
                  },
                  autoSkip: true,
                  maxRotation: 0,
                  minRotation: 0
          }
      }
    }
  };



    this.top5UsedDiscountCodes = new Chart(g, {
      type: 'bar',
      data: {
          labels:labels,
          datasets: [{
              label: 'Top 5 lopulliset diagnoosit',
              data: values,
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          },
          
        ],
          
          
      },
      options:options
      
  });

  }

  generatepaymentDiversion(){

    

    var g = <HTMLCanvasElement>document.getElementById('visit-paymentDiversion');
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width > 1024){
      g.height = 200;
    }
    else{
      g.height = 200;
    }
    
    Chart.defaults.font.size = 16;
    Chart.defaults.color ="black";

    var options = {
      maintainAspectRatio: false,
  };



    this.paymentDiversion = new Chart(g, {
      type: 'pie',
      data: {
          labels:['Onnistunut', 'Epäonnistunut','Lähetetty'],
          datasets: [{
              label: 'Keskihinta €/pvm',
              data: [this.currentStat.payments.total_successfull_number, this.currentStat.payments.total_failed_number,this.currentStat.payments.total_sending_number],
              backgroundColor: [
                                "#2B3E91",
                "#458ADB",
                "#2C2E45",
                "#F25E61",
                "#F5CD21",
                "#459ADB",
                "#C13764"
              ],
              borderColor: 'gray',
              borderWidth:1,
              
              
          }
        ],
          
          
      },
      options:options
      
  });

  }

  openCity(evt, cityName) {
    // Declare all variables
    var i, tabcontent, tablinks;
  
  
    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
      console.log(tabcontent[i]);
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  
  
    }

    generatePaymentChart(){

      var g = <HTMLCanvasElement>document.getElementById('payment-chart');
      var width = document.documentElement.clientWidth;
      if(width > 1024){
        g.height = 75;
      }
      else{
        g.height = 500;
      }
      
      Chart.defaults.font.size = 16;
      Chart.defaults.color ="black";
      const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);
  
  
  
      
      var options = {
        scales: {
          x: {
            grid:{
              display:false
            },
          }
      },
  
  
      }
  
      this.paymentChart = new Chart(g, {
        type: 'line',
        data: {
            labels:this.paymentStats.dates ,
            datasets: [{
                label: 'Epäonnistuneet (kpl)',
                spanGaps:true,
                data: nullDupes(this.paymentStats.total_failed_number),
                backgroundColor:'#2B3E91',
                borderColor: '#2B3E91',
                pointBackgroundColor:'white',
                pointBorderColor :'#2B3E91',
                tension: 0.5,
                borderWidth:2,
                
                
            },
            {
              label: 'Onnistuneet (kpl)',
              spanGaps:true,
              data: nullDupes(this.paymentStats.total_successfull_number),
              backgroundColor:'#C13764',
              borderColor: '#C13764',
              pointBackgroundColor:'white',
              pointBorderColor :'#C13764',
              tension: 0.5,
              borderWidth:2,
              
              
          },
          {
            label: 'Lähetetyt (kpl)',
            spanGaps:true,
            data: nullDupes(this.paymentStats.total_sending_number),
            backgroundColor:'#2C2E45',
            borderColor: '#2C2E45',
            pointBackgroundColor:'white',
            pointBorderColor :'#2C2E45',
            tension: 0.5,
            borderWidth:2,
            
        },
        {
          label: 'Tuotot (€)',
          spanGaps:true,
          data: nullDupes(this.paymentStats.total_sell),
          backgroundColor:'#F25E61',
          borderColor: '#F25E61',
          pointBackgroundColor:'white',
          pointBorderColor :'#F25E61',
          tension: 0.5,
          borderWidth:2,        
        },
        {
          label: 'Alennukset (€)',
          spanGaps:true,
          data: nullDupes(this.paymentStats.total_granted_discounts),
          backgroundColor:'#931EA7',
          borderColor: '#931EA7',
          pointBackgroundColor:'white',
          pointBorderColor :'#931EA7',
          tension: 0.5,
          borderWidth:2,        
        },
  
          ],
            
            
        },
        options: options,
    });
    }


    generateRegisterChart(){

      var g = <HTMLCanvasElement>document.getElementById('register-chart');
      var width = document.documentElement.clientWidth;
      if(width > 1024){
        g.height = 75;
      }
      else{
        g.height = 500;
      }
      
      Chart.defaults.font.size = 16;
      Chart.defaults.color ="black";
      const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);
    
    
    
      
      var options = {
        scales: {
          x: {
            grid:{
              display:false
            },
          }
      },
    
    
      }
    
      this.registerChart = new Chart(g, {
        type: 'line',
        data: {
            labels:this.registerStats.dates ,
            datasets: [{
                label: 'Konsultaatiot (kpl)',
                spanGaps:true,
                data: nullDupes(this.registerStats.total_registered_visits),
                backgroundColor:'#2B3E91',
                borderColor: '#2B3E91',
                pointBackgroundColor:'white',
                pointBorderColor :'#2B3E91',
                tension: 0.5,
                borderWidth:2,
                
                
            },
            {
              label: 'Käyttäjät (kpl)',
              spanGaps:true,
              data: nullDupes(this.registerStats.total_registered_users),
              backgroundColor:'#C13764',
              borderColor: '#C13764',
              pointBackgroundColor:'white',
              pointBorderColor :'#C13764',
              tension: 0.5,
              borderWidth:2,
              
              
          },
          {
            label: 'Koirat (kpl)',
            spanGaps:true,
            data: nullDupes(this.registerStats.total_number_of_dogs),
            backgroundColor:'#2C2E45',
            borderColor: '#2C2E45',
            pointBackgroundColor:'white',
            pointBorderColor :'#2C2E45',
            tension: 0.5,
            borderWidth:2,
            
        },
        {
          label: 'Kissat (kpl)',
          spanGaps:true,
          data: nullDupes(this.registerStats.total_number_of_cats),
          backgroundColor:'#F25E61',
          borderColor: '#F25E61',
          pointBackgroundColor:'white',
          pointBorderColor :'#F25E61',
          tension: 0.5,
          borderWidth:2,        
        },
        {
          label: 'Pieneläimet (kpl)',
          spanGaps:true,
          data: nullDupes(this.registerStats.total_number_of_small_animals),
          backgroundColor:'#931EA7',
          borderColor: '#931EA7',
          pointBackgroundColor:'white',
          pointBorderColor :'#931EA7',
          tension: 0.5,
          borderWidth:2,
          hidden:true,         
        },
        {
          label: 'Urokset (kpl)',
          spanGaps:true,
          data: nullDupes(this.registerStats.total_number_of_males),
          backgroundColor:'#27AE61',
          borderColor: '#27AE61',
          pointBackgroundColor:'white',
          pointBorderColor :'#27AE61',
          tension: 0.5,
          borderWidth:2,
          hidden:true,         
        },
        {
          label: 'Naaraat (kpl)',
          spanGaps:true,
          data: nullDupes(this.registerStats.total_number_of_females),
          backgroundColor:'#16A086',
          borderColor: '#16A086',
          pointBackgroundColor:'white',
          pointBorderColor :'#16A086',
          tension: 0.5,
          borderWidth:2,
          hidden:true,       
        },
        {
          label: 'Palaavat asiakkaat (kpl)',
          spanGaps:true,
          data: nullDupes(this.registerStats.total_returning_customers),
          backgroundColor:'#576572',
          borderColor: '#576572',
          pointBackgroundColor:'white',
          pointBorderColor :'#576572',
          tension: 0.5,
          borderWidth:2,
          hidden:true,        
        },
    
          ],
            
            
        },
        options: options,
    });
    }

    generateVisitsChart(){

      var g = <HTMLCanvasElement>document.getElementById('visit-chart');
      var width = document.documentElement.clientWidth;
      if(width > 1024){
        g.height = 75;
      }
      else{
        g.height = 500;
      }
      
      Chart.defaults.font.size = 16;
      Chart.defaults.color ="black";
      const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);
    
    
    
      
      var options = {
        scales: {
          x: {
            grid:{
              display:false
            },
          }
      },
    
    
      }
    
      this.visitChart = new Chart(g, {
        type: 'line',
        data: {
            labels:this.visitStats.dates ,
            datasets: [{
                label: 'Konsultaatio aika (min)',
                spanGaps:true,
                data: nullDupes(this.visitStats.total_average_consultation_time),
                backgroundColor:'#2B3E91',
                borderColor: '#2B3E91',
                pointBackgroundColor:'white',
                pointBorderColor :'#2B3E91',
                tension: 0.5,
                borderWidth:2,
                hidden:true,
                
                
            },
            {
              label: 'Jonoaika (min)',
              spanGaps:true,
              data: nullDupes(this.visitStats.total_average_queue_time),
              backgroundColor:'#C13764',
              borderColor: '#C13764',
              pointBackgroundColor:'white',
              pointBorderColor :'#C13764',
              tension: 0.5,
              borderWidth:2,
              hidden:true,
              
              
          },
          {
            label: 'Koirat (kpl)',
            spanGaps:true,
            data: nullDupes(this.visitStats.total_number_of_dogs),
            backgroundColor:'#2C2E45',
            borderColor: '#2C2E45',
            pointBackgroundColor:'white',
            pointBorderColor :'#2C2E45',
            tension: 0.5,
            borderWidth:2,
            
        },
        {
          label: 'Kissat (kpl)',
          spanGaps:true,
          data: nullDupes(this.visitStats.total_number_of_cats),
          backgroundColor:'#F25E61',
          borderColor: '#F25E61',
          pointBackgroundColor:'white',
          pointBorderColor :'#F25E61',
          tension: 0.5,
          borderWidth:2,        
        },
        {
          label: 'Pieneläimet (kpl)',
          spanGaps:true,
          data: nullDupes(this.visitStats.total_number_of_small_animals),
          backgroundColor:'#931EA7',
          borderColor: '#931EA7',
          pointBackgroundColor:'white',
          pointBorderColor :'#931EA7',
          tension: 0.5,
          borderWidth:2,
          hidden:true,         
        },
        {
          label: 'Vaihtoehtoiset diagnoosit (kpl)',
          spanGaps:true,
          data: nullDupes(this.visitStats.total_number_of_diagnosisDiff),
          backgroundColor:'#27AE61',
          borderColor: '#27AE61',
          pointBackgroundColor:'white',
          pointBorderColor :'#27AE61',
          tension: 0.5,
          borderWidth:2,        
        },
        {
          label: 'Lopulliset diagnoosit (kpl)',
          spanGaps:true,
          data: nullDupes(this.visitStats.total_number_of_diagnosisFinal),
          backgroundColor:'#16A086',
          borderColor: '#16A086',
          pointBackgroundColor:'white',
          pointBorderColor :'#16A086',
          tension: 0.5,
          borderWidth:2,      
        },
        {
          label: 'Suositukset (kpl)',
          spanGaps:true,
          data: nullDupes(this.visitStats.total_number_of_recommendations),
          backgroundColor:'#576572',
          borderColor: '#576572',
          pointBackgroundColor:'white',
          pointBorderColor :'#576572',
          tension: 0.5,
          borderWidth:2,        
        },
    
          ],
            
            
        },
        options: options,
    });
    }

    generateFeedbackChart(){

      var g = <HTMLCanvasElement>document.getElementById('feedback-chart');
      var width = document.documentElement.clientWidth;
      if(width > 1024){
        g.height = 75;
      }
      else{
        g.height = 500;
      }
      
      Chart.defaults.font.size = 16;
      Chart.defaults.color ="black";
      const nullDupes = data => data.map((x, i) => data[i - 1] === x ? null : x);
    
    
    
      
      var options = {
        scales: {
          x: {
            grid:{
              display:false
            },
          }
      },
    
    
      }
    
      this.feedbackChart = new Chart(g, {
        type: 'line',
        data: {
            labels:this.feedbackStats.dates ,
            datasets: [{
                label: 'Palautteet (kpl)',
                spanGaps:true,
                data: nullDupes(this.feedbackStats.number_of_feedbacks),
                backgroundColor:'#2B3E91',
                borderColor: '#2B3E91',
                pointBackgroundColor:'white',
                pointBorderColor :'#2B3E91',
                tension: 0.5,
                borderWidth:2,
                
                
            },
            {
              label: 'Keskiarvo (1-10)',
              spanGaps:true,
              data: nullDupes(this.feedbackStats.total_average_grade),
              backgroundColor:'#C13764',
              borderColor: '#C13764',
              pointBackgroundColor:'white',
              pointBorderColor :'#C13764',
              tension: 0.5,
              borderWidth:2,
              
              
          },
          {
            label: 'NPS',
            spanGaps:true,
            data: nullDupes(this.feedbackStats.total_average_nps),
            backgroundColor:'#2C2E45',
            borderColor: '#2C2E45',
            pointBackgroundColor:'white',
            pointBorderColor :'#2C2E45',
            tension: 0.5,
            borderWidth:2,
            
        },
        
    
          ],
            
            
        },
        options: options,
    });
    }







}
