import { AfterContentInit, Component, OnInit, } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ColorServiceService } from '../service/color-service.service';


const helper = new JwtHelperService;


@Component({
  selector: 'app-payments-register',
  templateUrl: './payments-register.component.html',
  styleUrls: ['./payments-register.component.css']
})
export class PaymentsRegisterComponent implements OnInit {

  currentUserId='';
  patientID: string = this.route.snapshot.params.id;
  payments: any[];
  userData: any = {};
  currentDetails: any = {};
  authToken = localStorage.getItem("auth");
  numSuccess;
  numFailed;
  numSending;
  totalSum;
  isAdmin = false;
  clinicID = this.authService.getTokenData().clinicID;
  doctors= [];


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','auth': `${this.authToken}` }),
    withCredentials: true,
  };

  constructor( 
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private colorService: ColorServiceService,
    private authService: AuthService) { }
    ngOnInit() {
      this.myId();

    }
    ngAfterViewInit(){
      this.colorService.setVetColors();
    }

  
    myId(){
  
      var token = localStorage.getItem('auth');
  
      if(token == null){
        this.logOutt();
        this.authService.doLogOut();
      }
      var decodedToken = helper.decodeToken(token);
  
      this.currentUserId = decodedToken.result.id;
  
  
      var activeuser = localStorage.getItem('ACTIVE_USER');
  
  
      if(decodedToken.result.designation == 'patient'){
        this.authService.doLogOut();
        this.logOutt();
  
      }
      this.isAdmin = decodedToken.result.isAdmin;

      if(this.isAdmin){
      this.fetchPayments()
      this.getDoctors()
      }
    }

    logOutt() {
      console.log("Request to logout", this.currentUserId);
      let req = {
        userID: this.currentUserId
      };
    
      this.http.post(environment.SET_LOG_OUT_END_POINT, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            //this.router.navigate(['login']);
            console.log("log out success");
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
        this.authService.doLogOut();
    
    }

    getDoctors() {

      this.http.get(environment.DOC_FETCH_ALL_DOCTORS_DATA_END_POINT, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            this.doctors = res.message;
            console.log(this.doctors);
          }
          else{
  
          }
      
  
      }, (err: any) => {
        console.log('NSET_Virhe decrease ', err);
      }
      )
  
    }

    fetchPayments(){

      this.http.get(environment.FETCH_PAYMENTS_FOR_REGISTER, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.payments = res.message.payments;
            this.numSuccess = res.message.numSuccess;
            this.numFailed = res.message.numFailed;
            this.numSending = res.message.numSending;
            this.totalSum = res.message.sum;
            this.colorService.waitStyleColor();
            this.waitColor();

          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    }

    fetchPaymentsByDate(){

      var startDate = (<HTMLInputElement>document.getElementById('startDate')).value;
      var endDate = (<HTMLInputElement>document.getElementById('endDate')).value;
      var vet = (<HTMLSelectElement>document.getElementById('vet-selection')).value;

      if(!startDate || !endDate){
        alert('Lisää alku ja loppupäivä');
        return;
      }
      
      this.http.get(environment.FETCH_PAYMENTS_BY_DATE+'/'+startDate+'/'+endDate+'/'+vet, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.payments = res.message.payments;
            this.numSuccess = res.message.numSuccess;
            this.numFailed = res.message.numFailed;
            this.numSending = res.message.numSending;
            this.totalSum = res.message.sum;
            this.colorService.waitStyleColor();
            this.waitColor();

          }
          else {
            this.payments = [];
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    }

    waitColor() {

      setTimeout(() =>{
        this.colorPayment()
      },500)
  
    }
    colorPayment(){
      for (let i = 0; i < this.payments.length; i++) {
        const payment = this.payments[i];
        var row = document.getElementById('payment-row-'+payment._id)

        switch (payment.payment_status.toLowerCase()) {
          case 'ok':
            //row.classList.add('table-success');
          break;
          case 'sending':
            row.classList.add('table-warning');
          break;
          case 'failed':
            row.classList.add('table-danger');
          break;
        
          default:
            break;
        }
        
      }
    }
    openCheckModal(details){
      document.getElementById('open-modal').click();

      this.currentDetails = {};
      this.currentDetails = details;

      var req = {
        userID: details.userID,
      }

      this.http.post(environment.DOC_FETCH_MEANING_USER_DATA, req, this.httpOptions).subscribe(
        (res: any) => {
          if(res.status == 200){
            
            this.userData = [];
            this.userData = res.message;

          }
          else {
            
          }
    
        }, (err: any) => {
          
          console.log("Error validating user :: ", err);
        })
    

    }

}
